 
 
<nav class="navbar navbar-expand-lg navbar-dark bg-black t-nav nav fixed-top">
  <div class="container">
    <a href="/">
    <div class="logo-class d-flex mr-md-5" >
      <!-- <img src="../../assets/images/logo-navbar.png" class="header-logo mr-1 mt-4" /> -->
       
        <p>Summarizly AI</p>
      
    </div>
    </a>
      <a  href="/feed">
    <div class="logo-class d-flex ml-3 pointer">
   
        <img src="../../assets/images/feed.png" class="header-logo mr-md-2 mt-md-3" />
       
        <h2><a  routerLink="/feed">Feed</a></h2>
       
    </div>
    </a>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarNavAltMarkup"
      aria-controls="navbarNavAltMarkup"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
       <!-- <div class="logo-class d-flex ml-3 pointer">
     
        <img src="../../assets/images/feed.svg" class="header-logo mt-1 mr-1" />
       
        <h2><a (click)="summary()" >Summary feed</a></h2>
      
    </div> -->
      <div class="navbar-nav ml-auto mr-3">
        
        <!-- <div class="singin-c mt-md-1 mr-2">
   <button class="linkProduct" (click)="Singin()">

      Y Combinator AI Blog
        </button>
         </div>     -->
      </div>
      
      
    
      <div class="navbar-nav ml-auto">
        
        <!-- <div class="link-btn mt-md-1">
   <button class="linkProduct" (click)="publisherLogin()">

    Become Publisher
        </button>
         </div>     -->
      </div>
       </div>
  </div>
</nav>