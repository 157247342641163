import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SharelinkComponent } from '../sharelink/sharelink.component';
import { LoginService } from '../_services/login.service';
declare var clevertap: any;
@Component({
  selector: 'app-usernavbar',
  templateUrl: './usernavbar.component.html',
  styleUrls: ['./usernavbar.component.css'],
})
export class UsernavbarComponent implements OnInit {
  token: any;
  userName: string | null;
  constructor(
    private router: Router,
    private loginService: LoginService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    console.log('clevertap initialized');
    // clevertap.init('6ZW-454-496Z');

    this.token = localStorage.getItem('token');
    this.userName = localStorage.getItem('userName');
    // console.log('usernavbar');
    // console.log('usernavbar');
    // console.log('usernavbar');
    // console.log('usernavbar');
  }
  Singin() {
    this.router.navigate(['/@ycombinator']);

    if (environment.CLEVERTAP) {
      clevertap.event.push('YCSummaries', {
        YCSummariesClicks: 'YC Summaries Succefully',
        YCSummaries: 'YC Summaries',
      });
    }
  }
  logout() {
    localStorage.clear();
    this.router.navigate(['/feed']);
    setTimeout(() => {
      window.location.reload();
    });
  }
  share() {
    this.dialog.open(SharelinkComponent, {
      data: {},
      disableClose: true,
    });
  }
  feed() {
    this.router.navigate(['/feed']);
  }
  discountCodes() {
    this.router.navigate(['user/discountCodes']);
  }
  bookmarkCollection() {
    this.router.navigate(['user/bookmark']);
  }
  bookmarkPublishers() {
    this.router.navigate(['user/publishers']);
  }
  bookmarkTopics() {
    this.router.navigate(['user/topics']);
  }
  editProfile() {
    this.router.navigate(['user/edit-profile']);
  }
  bookmarkProduct() {
    this.router.navigate(['user/productBookmark']);
  }
}
