import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-publisher-signup-modal',
  templateUrl: './publisher-signup-modal.component.html',
  styleUrls: ['./publisher-signup-modal.component.css'],
})
export class PublisherSignupModalComponent implements OnInit {
  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {}
  close() {
    this.dialog.closeAll();
  }
}
