import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { Router } from '@angular/router';
import { faSleigh } from '@fortawesome/free-solid-svg-icons';
import { environment } from 'src/environments/environment';
import { ShareproductLinkDialogComponent } from '../shareproduct-link-dialog/shareproduct-link-dialog.component';
import { CollectionService } from '../_services/collection.service';

@Component({
  selector: 'app-bulk-product',
  templateUrl: './bulk-product.component.html',
  styleUrls: ['./bulk-product.component.css'],
})
export class BulkProductComponent implements OnInit {
  createCollections: boolean = true;
  createProducts: boolean = false;

  loaderPreview: boolean = false;
  submittedSourceLink: boolean = false;
  submittedProduct: boolean = false;
  updatedsuccefully: boolean = false;
  createPowerCollectionEmail: FormGroup;
  createBulkProducts: FormGroup;
  params: { key: string; q: any };
  productImageUrl: any;
  linkMypreview: string;
  loaderPreviewProduct: boolean = false;
  category: string;
  categoryList: any;
  showDiscount: boolean = false;
  collectiontitle: any = [];
  selectedFile: File;
  fileName: any;
  producturl: any;
  productInfoId: any = [];
  productTitle: any;
  ProdcutINFOid: any = [];
  productErroMsg: any;
  collectionurl: null;
  navigateLink: any;
  title: any;
  id: any;
  shortUrl: any;
  private _id: any;
  name: any;
  ChannelName: any;
  channelNameSetValue: any;
  loaderPreview2: boolean = false;
  loaderPreview3: boolean = false;
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private dialog: MatDialog,
    public collectionServices: CollectionService
  ) {
    this.createPowerCollectionEmail = this.fb.group({
      title: ['', [Validators.required]],
    });
    this.createBulkProducts = this.fb.group({
      productTitle: ['', [Validators.required]],
      channelName: [''],
      description: [''],
      productImage: [''],
      affiliatePartnerName: [''],
      AffiliateLink: ['', [Validators.required]],
      price: [''],
      categoryId: ['', [Validators.required]],
      couponCode: [''],
      Keywords: [''],
      tag: [''],
      Summery: [''],
    });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.createPowerCollectionEmail.controls;
  }
  get p(): { [key: string]: AbstractControl } {
    return this.createBulkProducts.controls;
  }

  ngOnInit(): void {
    this.collectionServices.caterogoryList().subscribe((res) => {
      console.log(res);
      this.categoryList = res.data;
    });
    this.collectionServices.creatorChannel().subscribe((res) => {
      this.ChannelName = res.data;
      console.log(this.ChannelName);
      this.channelNameSetValue = res.data[0]?.name;
      this.createBulkProducts.controls['channelName'].setValue(
        res.data[0]?.name
      );
    });
    this.collectionServices.userBoarding().subscribe((res) => {
      console.log(res);
      this._id = res.data._id;
    });
    this.collectionServices.userBoardingChannel().subscribe((res) => {
      console.log(res);
      this.name = res.data[0].name;
    });
  }

  createCollectionBtn() {
    if (this.createPowerCollectionEmail.invalid) {
      this.submittedSourceLink = true;
    } else {
      this.submittedSourceLink = false;
      this.createCollections = false;
      this.createProducts = true;
    }
  }
  fetchProductDetail() {
    if (this.createBulkProducts.controls['AffiliateLink'].invalid) {
      this.submittedProduct = true;
    } else {
      this.submittedProduct = false;
      this.loaderPreview2 = true;

      this.params = {
        key: '8ae171ef2257d9fbc1a5ae031cf06980',
        q: this.createBulkProducts.value.AffiliateLink,
      };
      this.collectionServices.linkPreviewDetails(this.params).subscribe(
        (res) => {
          console.log('productDetails', res);
          this.productImageUrl = res.image;
          // this.loaderPreviewProduct = false;
          this.loaderPreview2 = false;

          this.createBulkProducts.controls['productTitle'].setValue(res.title);
          this.createBulkProducts.controls['description'].setValue(
            res.description
          );
          this.createBulkProducts.controls['affiliatePartnerName'].setValue(
            res.site_name
          );
        },
        (error) => {
          console.log(error);
          this.linkMypreview = `${error.error.description} with the actual response code we got from the remote server`;

          setTimeout(() => {
            this.loaderPreviewProduct = false;
            this.linkMypreview = '';
          }, 6000);
        }
      );
    }
  }
  categoryEvent(event: any) {
    if (event.value != '') {
      this.category = JSON.stringify(event.value);
    } else {
      this.category = '';
    }
    console.log(event.value);
  }
  changeRoute(event: MatRadioChange) {
    console.log(event.value);
    if (event.value === '1') {
      this.showDiscount = true;
    }
    if (event.value === '0') {
      this.showDiscount = false;
    }
  }
  deleteImage() {
    this.productImageUrl = null;
  }
  onFileSelectedProduct(event: any) {
    const file = event.target.files && event.target.files[0];
    this.selectedFile = <File>event.target.files[0];
    this.fileName = event.target.files[0].name;

    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.createBulkProducts.controls['productImage'].setValue(file);
    }
    var reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = (event) => {
      this.producturl = (<FileReader>event.target).result;
      console.log(this.producturl);
    };
  }
  addProductDetails() {
    if (this.createBulkProducts.invalid) {
      this.submittedProduct = true;
    } else {
      this.loaderPreview3 = true;
      this.submittedProduct = false;
      if (this.createBulkProducts.value.couponCode == null) {
        this.createBulkProducts.value.couponCode = '';
      }
      if (this.createBulkProducts.value.affiliatePartnerName == null) {
        this.createBulkProducts.value.affiliatePartnerName = '';
      }
      if (this.createBulkProducts.value.price == null) {
        this.createBulkProducts.value.price = '';
      }
      if (this.productImageUrl == null) {
        this.productImageUrl = '';
      }
      console.log(this.createBulkProducts.value.productTitle);

      const formData = new FormData();
      formData.append(
        'productImage',
        this.createBulkProducts.controls['productImage'].value
      );
      formData.append(
        'productTitle',
        this.createBulkProducts.value.productTitle.replace(
          /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
          ''
        )
      );
      formData.append('channelName', this.createBulkProducts.value.channelName);
      formData.append('image', this.productImageUrl);
      formData.append('description', this.createBulkProducts.value.description);
      formData.append(
        'affiliatePartnerName',
        this.createBulkProducts.value.affiliatePartnerName
      );
      formData.append('price', this.createBulkProducts.value.price);
      formData.append(
        'categoryId',
        JSON.stringify(this.createBulkProducts.value.categoryId)
      );
      formData.append(
        'AffiliateLink',
        this.createBulkProducts.value.AffiliateLink
      );
      formData.append('couponCode', this.createBulkProducts.value.couponCode);
      formData.append('Summery', this.createBulkProducts.value.Summery);

      this.collectionServices.createProductCollection(formData).subscribe(
        (response) => {
          this.productInfoId = response._id;
          this.productTitle = this.createBulkProducts.value.productTitle;
          let obj = {
            id: response._id,
            title: this.createBulkProducts.value.productTitle,
          };
          this.collectiontitle.push(obj);
          this.loaderPreview3 = false;

          console.log(this.collectiontitle);
          this.ProdcutINFOid.push(this.productInfoId);

          console.log('product info', this.ProdcutINFOid);
          this.collectionServices.summary = '';

          setTimeout(() => {
            //  this.loaderShowProduct = false;
            this.createBulkProducts.controls['productTitle'].reset();
            this.createBulkProducts.controls['description'].reset();
            this.createBulkProducts.controls['AffiliateLink'].reset();
            this.createBulkProducts.controls['categoryId'].reset();

            //  this.collectionsAdd = true;
            this.collectionurl = null;
            this.productImageUrl = null;
            this.producturl = null;
          });
        },
        (error) => {
          console.log(error.error.message);
          this.productErroMsg = error.error.message;

          setTimeout(() => {
            this.productErroMsg = '';
          }, 3000);
        }
      );

      console.log(this.createBulkProducts);
    }
  }
  saveProducts() {
    if (this.ProdcutINFOid == '') {
      this.submittedProduct = true;
    } else {
      let obj = {
        title: this.createPowerCollectionEmail.value.title,
        productId: this.ProdcutINFOid,
        creatorId: this._id,
        channelName: this.name,
      };

      this.collectionServices.creatorBulkProducts(obj).subscribe((res) => {
        console.log(res);
        this.updatedsuccefully = true;
        this.createProducts = false;
        this.title = this.name;
        this.collectiontitle = this.createPowerCollectionEmail.value.title;
        this.id = res.data._id;

        this.navigateLink = `${environment.URL}/${this.name}/${res.data.title
          .split(' ')
          ?.join('-')}/id-${res.data._id}`;

        let obj = {
          longURL: this.navigateLink,
          BulkProductId: this.id,
        };
        this.collectionServices.creatorShortlinks(obj).subscribe((res) => {
          console.log(res);
          this.shortUrl = res.data.shortLink;
        });
        console.log('link', this.navigateLink);
      });
    }
  }
  copyLink(e: any, text: any) {
    e.textContent = text;
  }
  share() {
    this.dialog.open(ShareproductLinkDialogComponent, {
      data: {
        title: this.navigateLink,
        disableClose: true,
      },
    });
  }
  deleteProduct(id: any, i: any) {
    console.log('ID', id);
    // console.log('ProductID', this.ProdcutINFOid);
    // console.log('ID', this.ProdcutINFOid);

    this.collectionServices.DeleteProduct(id).subscribe((res) => {
      console.log(res);
      const index = this.ProdcutINFOid.indexOf(id);
      this.ProdcutINFOid.splice(index, 1);
      console.log('ID', this.ProdcutINFOid);
      if (i !== -1) {
        this.collectiontitle.splice(i, 1);
      }
    });
  }
}
