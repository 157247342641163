<app-usernavbar></app-usernavbar>

<app-loader *ngIf="loader"></app-loader>
<div class="user_followed_p container">
  <div class="header_p">
    <h5>Followed Publishers</h5>

    <div class="d-flex border-p" *ngFor="let item of data">
      <div class="">
        <img *ngIf="item?.profile != null" src="{{ item?.profile }}" />
        <img
          *ngIf="item?.profile == null"
          src="../../assets/images/user-1.png"
          width="40px"
        />
      </div>
      <div class="name_publisher">
        <h5>{{ item?.name ? item.name : "N/A" }}</h5>
        <p>
          Summaries count : <span>{{ item.summaryCount }}</span>
        </p>
      </div>
      <div class="ml-auto follow">
        <a (click)="unfollow(item)"><span>following</span></a>
      </div>
    </div>
  </div>
</div>
