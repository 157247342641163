<app-homenavbar></app-homenavbar>
<div class="container-fluid">
  <div class="main-section ml-lg-5">
    <div class="catergory-section pt-5">
      <div class="catergorys">
        <button *ngFor="let item of caterorySection" (click)="navClick(item)" class="c-button">{{item}}</button>
      </div>
      <div class="summary-section mt-md-3">
        <div class="row">
          <div class="col-lg-8 col-md-7">
            <div class="summary card mb-5" id="fullSummary">
              <div class="card-body">
                <div class="d-flex summary-p">
                  
                  <div class="summary-header ml-md-4">
                    <p>Published by <span class="channel-name">Thinkschol</span>
                      <span class="subscribe"> Subscribe</span>
                    </p>
                    <div class="summary-start">
                      <h3>Top five beautytips for women that works</h3>
                      <div class="summary-paragrph">
                        <h5 *ngFor="let summary of summaryFeed">{{summary}}</h5>
                      </div>
                     
                    </div>
                  </div>
                </div>

                <div class="channel-card">
                  <div class="d-flex">
                    <div class="channel-avatar-section mr-5">
                        <div class="d-flex">
                            <div class="avartar">
                                <img src="../../../assets/images/user-1.png" class="user-img">
                            </div>
                            <div class="channel-details">
                                <h4>Think school</h4>
                                <div class="d-flex details-tube">
                                    <img src="../../../assets/images/youtube-1.png" class="youtube-img">
                                    <p>2M+</p>
                                </div>
                                    <a>Subscribe</a>
                            </div>
                        </div>
                    </div>
                    <div class='category-follow mt-2'>
                        <div class="d-flex">
                            <div class="catergy-img">
                               <img src='../../../assets/images/b-1.png' class="mr-2">
                            </div>
                            <div class="catergory-details">
                                <h6>beauty</h6>
                                <a>Follow</a>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
                 <div class="share-section py-3">   
                        <div class="d-flex justify-content-md-around media-screen-flex">
                            <div class="thumps-up-image pointer">
                    <button class="upVote" (click)="
                FollowChannelName($event.target,'Upvoted')">
                      <img src="../../../assets/images/arrrow-1.png">
                      <p id="upvote">Upvote</p>
                    </button>
                  </div>
                          <div class="comment">
                            <img src="../../../assets/images/bubble-chat.png">
                            <p>comment</p>
                          </div>
                          <div class="comment">
                            <img src="../../../assets/images/share-1.png">
                            <p>share</p>
                          </div>
                          <div class="comment">
                            <img src="../../../assets/images/bookmark (2).png">
                            <p>bookmark</p>
                          </div>
                        
                        </div>
                      </div>
              </div>
            </div>
            <div class="summary card  mb-3">
              <div class="card-body">
                <div class="d-flex">
                  <!-- <div class="thumps-up-image pointer">
                    <button class="upVote" (click)="
                upVotedBtn($event.target,'Upvoted')">
                      <img src="../../../assets/images/arrrow-1.png">
                      <p id="upvote1">Upvote</p>
                    </button>
                  </div> -->
                  <div class="summary-header ml-md-4">
                    <p>Published by <span class="channel-name">Thinkschool</span>
                      <span class="subscribe"> Subscribe</span>
                    </p>
                    <div class="summary-start">
                      <h3>Top five beautytips for women that works</h3>
                      <div class="summary-paragrph">
                        <h5 *ngFor="let summary of summaryFeed">{{summary}}</h5>
                        <span class="hidetext" [class.show-text]="this.showtext == true">
                          <div class="full-s">
                            <ul *ngFor="let item of fullsummary">
                              {{item.title}}
                              <li>
                                {{item.paragraph.split('\n')}}
                              </li>
                            </ul>
                          </div>
                        </span>
                        <div class="my-4 read-s">
                          <a *ngIf="readMore" class="anchor-tag" (click)="readmore()">Read full summary</a>
                          <a *ngIf="readLess" class="anchor-tag" (click)="readLessBtn()">Read less</a>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                 
                </div>
                  <div class="channel-card">
                  <div class="d-flex">
                    <div class="channel-avatar-section mr-5">
                        <div class="d-flex">
                            <div class="avartar">
                                <img src="../../../assets/images/user-1.png" class="user-img">
                            </div>
                            <div class="channel-details">
                                <h4>Think school</h4>
                                <div class="d-flex details-tube">
                                    <img src="../../../assets/images/youtube-1.png" class="youtube-img">
                                    <p>2M+</p>
                                </div>
                                    <a>Subscribe</a>
                            </div>
                        </div>
                    </div>
                    <div class='category-follow mt-2'>
                        <div class="d-flex">
                            <div class="catergy-img">
                               <img src='../../../assets/images/b-1.png' class="mr-2">
                            </div>
                            <div class="catergory-details">
                                <h6>beauty</h6>
                                <a>Follow</a>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
                 <div class="share-section py-3">   
                        <div class="d-flex justify-content-md-around media-screen-flex">
                            <div class="thumps-up-image pointer">
                    <button class="upVote" (click)="
                FollowChannelName($event.target,'Upvoted')">
                      <img src="../../../assets/images/arrrow-1.png">
                      <p id="upvote">Upvote</p>
                    </button>
                  </div>
                          <div class="comment">
                            <img src="../../../assets/images/bubble-chat.png">
                            <p>comment</p>
                          </div>
                          <div class="comment">
                            <img src="../../../assets/images/share-1.png">
                            <p>share</p>
                          </div>
                          <div class="comment">
                            <img src="../../../assets/images/bookmark (2).png">
                            <p>bookmark</p>
                          </div>
                        
                        </div>
                      </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-5">
            <div class="realted-collection">
              <div class="d-flex py-2" *ngFor="let card of collectionRealtedData">
                <div class="related-c-img pointer">
                  <img src="{{
                              card.thumbnail
                            }}">
                </div>
                <div class="related-c-header product_details_Paragraph ml-md-3">
                  <h4 class="mb-md-1">{{card.title}}
                  </h4>
                  <p class="c-name mt-md-2 pointer">
                    <a>{{card.channelname}}</a>
                  </p>
                  <h2 class="c-name mt-md-2">Video views:{{card.count}}</h2>
                  <!-- <p>Products and Discount codes shared by the creator in this video</p> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer ></app-footer>