import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NoAuthSignupComponent } from 'src/app/no-auth-signup/no-auth-signup.component';
import { CollectionService } from 'src/app/_services/collection.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-creator-viewlinks',
  templateUrl: './creator-viewlinks.component.html',
  styleUrls: ['./creator-viewlinks.component.css'],
})
export class CreatorViewlinksComponent implements OnInit {
  collectionCreatedByCreator: any;
  productTitle: any;
  productId: any;
  productError: any;
  userType: any;
  activeRouteParams: any;
  params: { bulkProductId: any };
  id: any;
  url: any;
  sessionType: any;
  title: any;
  channelName: any;

  channelTitle: any = [
    {
      title: 'five best produts to reduce hair fall for women',
      cretor: '@Vogue',
      links: '5 products links',
    },
    {
      title: 'five best produts to reduce hair fall for women',
      cretor: '@Vogue',
      links: '5 products links',
    },
    {
      title: 'five best produts to reduce hair fall for women',
      cretor: '@Vogue',
      links: '5 products links',
    },
    {
      title: 'five best produts to reduce hair fall for women',
      cretor: '@Vogue',
      links: '5 products links',
    },
    {
      title: 'five best produts to reduce hair fall for women',
      cretor: '@Vogue',
      links: '5 products links',
    },
    {
      title: 'five best produts to reduce hair fall for women',
      cretor: '@Vogue',
      links: '5 products links',
    },
    {
      title: 'five best produts to reduce hair fall for women',
      cretor: '@Vogue',
      links: '5 products links',
    },
  ];
  links: any;

  constructor(
    private service: CollectionService,
    private router: Router,
    private dialog: MatDialog,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    if (this.service.getToken() == '') {
      console.log('not-login');
      this.sessionType = 'home';
    }
    if (this.service.getToken() != '') {
      this.service.loginUserDetails().subscribe((result) => {
        this.sessionType = result.data.userType;
        console.log('homepage', this.sessionType);
      });
    }
    this.service.creatorBulkProductsLinks().subscribe((result) => {
      console.log(result);
      this.links = result.data;
    });
  }

  productDetails(title: any, id: any) {
    this.productTitle = title;
    this.productId = id;
    this.router.navigate([
      `/p/${this.productTitle.split(' ').join('-')}/id-${this.productId}`,
    ]);
  }
  // saveForlater(id: any) {
  //   console.log('p', id);
  //   if (this.service.getToken() == '') {
  //     this.dialog.open(NoAuthSignupComponent, {
  //       data: {
  //         title: 'Bookmark this product',
  //         disableClose: true,
  //       },
  //     });
  //   } else {
  //     console.log('saveProductBookmark', id);
  //     let obj = {
  //       productId: id,
  //     };
  //     this.service.userProductBookmark(obj).subscribe(
  //       (result) => {
  //         this.saved = true;
  //         setTimeout(() => {
  //           this.saved = false;
  //         }, 3000);
  //       },
  //       (error) => {
  //         this.productError = error.error.message;
  //         setTimeout(() => {
  //           this.productError = '';
  //         }, 3000);
  //       }
  //     );
  //   }
  // }
}
