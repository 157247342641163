import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';

// import { LoginComponent } from '../login/login.component';

import { catchError, retry } from 'rxjs/operators';
import { CollectionService } from 'src/app/_services/collection.service';
import { LoginService } from 'src/app/_services/login.service';
import { MixPanelService } from 'src/app/_services/mix-panel.service';
import { environment } from 'src/environments/environment';
var emailPhonePattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$';
import { ClevertapService } from 'src/app/_services/clevertap.service';
// import clevertap from 'clevertap-web-sdk';
import { SignupComponent } from '../../login-page/signup/signup.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
declare var clevertap: any;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  loginPage: boolean = true;
  resetPassword: boolean = false;
  submitted: boolean = false;
  confirmPassword: boolean = false;
  passwordResetLink: boolean = false;
  passwordReset: boolean = true;
  passwordChangedSuccesfully: boolean = false;
  loginLoader: boolean = false;
  submittedPassword: boolean = false;
  loginFormGroup: FormGroup;
  forGotPasswordForm: FormGroup;
  errorMessage: any;
  userName: any;
  loginErrorMessage: any;
  userType: any;
  url: any = `${environment.USER}user/google`;

  isLoggedin: any = true;
  id: any;
  redirectionLink: any;
  uniqueId: string;
  imageUrl: string;
  latitude: any;
  longitude: any;
  reset: boolean;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private loginService: LoginService,
    private collectionservices: CollectionService,
    private mixPanelService: MixPanelService,
    private cleverTapService: ClevertapService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<LoginComponent>
  ) {
    this.loginFormGroup = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });
    this.forGotPasswordForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  ngOnInit(): void {
    clevertap.init('6ZW-454-496Z');

    //
    // console.log('clevertap initialized');
    if (localStorage.getItem('token') != null) {
      this.router.navigate(['/feed']);
    }
    this.getlocation();
  }

  getlocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.latitude = position.coords.latitude;
          this.longitude = position.coords.longitude;
          console.log('geolocation', this.latitude, this.longitude);
        },
        (error) => {
          console.log('geolocation', error);
        }
      );
    } else {
      console.log('geolocation not  available');
    }
  }
  close() {
    this.dialog.closeAll();
  }
  login() {
    if (this.loginFormGroup.invalid) {
      this.submitted = true;
    } else {
      // this.sendUserData();

      this.submitted = false;
      this.loginLoader = true;
      let obj = {
        email: this.loginFormGroup.value.email,
        password: this.loginFormGroup.value.password,
      };

      if (environment.CLEVERTAP) {
        clevertap.event.push('ReadersLogicCta', {
          readersLogicEmail: this.loginFormGroup.value.email,
        });
      }

      this.loginService.loginUser(obj).subscribe(
        (res) => {
          this.loginLoader = false;

          if (environment.CLEVERTAP) {
            clevertap.event.push('ReadersLogicSuccess', {
              ReadersLogicSuccess: 'login Succesfully',
            });
          }

          localStorage.setItem('token', res.session.token);
          // this.router.navigate(['/feed']);

          this.redirectionLink = localStorage.getItem('shareLink');
          console.log('rediredtion', this.redirectionLink);
          if (
            this.redirectionLink == null ||
            this.redirectionLink == undefined
          ) {
            this.router.navigate(['/feed']);
            console.log('redirection', 'feed');
          } else {
            this.router.navigate([`${this.redirectionLink}`]);
            console.log('redirection', 'link-correct');
          }
          // this.mixPanelService.track('user-login-details');
          // this.mixPanelService.setPeople({
          //   email: this.loginFormGroup.value.email,
          //   password: this.loginFormGroup.value.password,
          // });
          this.collectionservices.loginUserDetails().subscribe((result) => {
            this.userType = result.data.userType;
            this.id = result.data._id;
            localStorage.setItem('userName', result.data.name);
            localStorage.setItem('userType', this.userType);
            localStorage.setItem('ID', this.id);
            localStorage.removeItem('shareLink');

            this.dialog.closeAll();
            this.collectionservices.refresh();
            localStorage.setItem('refresh', 'true');
            // window.location.reload();
          });
        },
        (err) => {
          console.log(err.error.message);

          this.loginErrorMessage = err.error.message;
          setTimeout(() => {
            this.loginErrorMessage = '';
          }, 3000);
        }
      );

      console.log(this.loginFormGroup.value);
    }
  }
  handleError(handleError: any): any {
    throw new Error('Method not implemented.');
  }

  get f(): { [key: string]: AbstractControl } {
    return this.loginFormGroup.controls;
  }
  get r(): { [key: string]: AbstractControl } {
    return this.forGotPasswordForm.controls;
  }
  signUp() {
    // this.router.navigate(['user/Signup']);
    this.dialogRef.close();
    this.dialog.open(SignupComponent, {
      data: {
        disableClose: true,
      },
    });
  }
  home() {
    this.dialog.closeAll();
  }

  forGotPassword() {
    this.resetPassword = true;
    this.loginPage = false;
  }
  resend() {
    this.reset = true;

    let obj = {
      email: this.forGotPasswordForm.value.email,
    };
    this.loginService.forgotPasswordUser(obj).subscribe(
      (res) => {
        this.passwordResetLink = true;
        this.reset = false;

        this.passwordReset = false;
      },
      (error) => {
        console.error(error.error);
        this.reset = false;

        this.errorMessage = error.error.message;
        setTimeout(() => {
          this.errorMessage = '';
        }, 3000);
      }
    );
  }
  resetPasswordBtn() {
    if (this.forGotPasswordForm.invalid) {
      this.submittedPassword = true;
    } else {
      this.submittedPassword = false;
      this.submitted = false;
      this.reset = true;
      let obj = {
        email: this.forGotPasswordForm.value.email,
      };
      this.loginService.forgotPasswordUser(obj).subscribe(
        (res) => {
          this.passwordChangedSuccesfully = true;
          this.resetPassword = false;
          this.reset = false;
        },
        (error) => {
          console.error(error.error);
          this.reset = false;
          this.errorMessage = error.error.message;
          setTimeout(() => {
            this.errorMessage = '';
          }, 3000);
        }
      );
    }
  }
  confirmPasswordBtn() {
    this.passwordChangedSuccesfully = true;
    this.confirmPassword = false;
  }
  loginNow() {
    this.loginPage = true;
    this.passwordChangedSuccesfully = false;
  }
}
