import { Component, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import { AdminService } from 'src/app/_services/admin.service';
@Component({
  selector: 'app-publisher-analytics',
  templateUrl: './publisher-analytics.component.html',
  styleUrls: ['./publisher-analytics.component.css'],
})
export class PublisherAnalyticsComponent implements OnInit {
  activeState: any = 'all';
  languageButtons: any = [
    { name: 'ALL', value: 'all' },
    { name: 'ENGLISH', value: 'english' },
    { name: 'HINDI', value: 'hindi' },
    { name: 'CHINESE', value: 'chinese' },
    { name: 'GERMAN', value: 'german' },
    { name: 'RUSSIAN', value: 'russian' },
    { name: 'SPANISH', value: 'spanish' },
    { name: 'TAMIL', value: 'tamil' },
    { name: 'ARABIC', value: 'arabic' },
    { name: 'MALAYALAM', value: 'malayalam' },
    { name: 'TELUGU', value: 'telugu' },
  ];
  Language: any = ['month', 'year'];
  selectedValue: any = 'month';
  highcharts = Highcharts;
  chartOptions: any;
  chartOptionsBar: any;
  chartOptionsRevered: any;
  topPerformanceCount: any = [];
  topPerformanceNames: any = [];
  data: any;
  items: any[];
  language: any;
  params: any;
  subscriberData: any;
  subscriberDataMonth: any = [];
  subscriberDataCount: any = [];
  subscribers: any;
  loader: boolean = false;
  keywordsLoaderPostionChange: string = 'key_words_loader_postion_change_css';
  totalSummary: any;
  constructor(private adminService: AdminService) {}

  ngOnInit() {
    this.totalSummary = localStorage.getItem('publisherBlogsCount');
    this.selectedValue = 'month';
    this.loader = true;
    this.params = {
      type: 'month',
    };
    this.adminService
      .PublisherAnalytics(this.params)
      .subscribe((chatData: any) => {
        console.log(chatData);
        this.subscribers = chatData.TotalSubscriber;
        // this.loader = false;

        this.items = [
          { name: 'Total Summaries', number: chatData.totalSummaryCount },
          { name: 'Subscribers', number: chatData.TotalSubscriber },
          { name: 'Reads', number: chatData.totalreads },
          { name: 'Comments', number: chatData.summaryCommentCount },
        ];
        this.data = chatData.topPerfromingCategories;
        this.subscriberData = chatData.SubscriberData;

        this.subscriberData.forEach((item: any) => {
          this.subscriberDataMonth.push(item.month);
          this.subscriberDataCount.push(item.count);
        });
        this.chartOptionsBar = {
          chart: {
            type: 'column',
          },
          title: {
            text: '',
          },
          credits: {
            enabled: true,
          },
          legend: { enabled: false },
          //  subtitle: {
          //     text: "Source: WorldClimate.com"
          //  },
          xAxis: {
            gridLineWidth: 0,
            minorGridLineWidth: 0,
            lineWidth: 0,
            lineColor: 'transparent',
            title: {
              text: '',
            },
            labels: {
              style: {
                color: '#5A657F',
                fontSize: 12,
                fontWeight: 600,
                fontFamily: 'Inter',
              },
            },
            categories: this.subscriberDataMonth,
          },
          yAxis: {
            gridLineWidth: 0,
            minorGridLineWidth: 0,
            title: {
              text: '',
            },
            labels: {
              format: '{value}',
              style: {
                color: '#425466',
                fontSize: 12,
                fontWeight: 600,
                fontFamily: 'Inter',
              },
            },
          },
          //  tooltip: {
          //     valueSuffix:" °C"
          //  },
          plotOptions: {
            column: {
              dataLabels: {
                enabled: true,
              },
            },
          },

          colors: ['#0a090a', ''],
          series: [
            {
              name: '',
              data: this.subscriberDataCount,
            },
          ],
        };
        this.data.forEach((item: any) => {
          this.topPerformanceCount.push(item.count);
          this.topPerformanceNames.push(item.category);
        });
        this.chartOptionsRevered = {
          chart: {
            type: 'bar',
          },
          title: {
            text: 'Topics',
          },
          credits: {
            enabled: true,
          },

          xAxis: {
            categories: this.topPerformanceNames,
          },
          yAxis: {
            min: 0,
            title: {
              text: 'Goals',
            },
          },
          legend: {
            reversed: true,
          },
          colors: ['#076611ab'],
          series: [
            {
              name: '',
              data: this.topPerformanceCount,
            },
          ],
        };
      });
    this.chartOptions = {
      chart: {
        type: 'spline',
      },
      title: {
        text: 'page views',
      },
      credits: {
        enabled: true,
      },
      legend: { enabled: false },
      //  subtitle: {
      //     text: "Source: WorldClimate.com"
      //  },
      xAxis: {
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        lineWidth: 0,
        lineColor: 'transparent',
        title: {
          text: '',
        },
        labels: {
          style: {
            color: '#5A657F',
            fontSize: 12,
            fontWeight: 600,
            fontFamily: 'Inter',
          },
        },
        categories: ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5'],
      },
      yAxis: {
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        title: {
          text: '',
        },
        labels: {
          format: '{value}',
          style: {
            color: '#425466',
            fontSize: 12,
            fontWeight: 600,
            fontFamily: 'Inter',
          },
        },
      },
      //  tooltip: {
      //     valueSuffix:" °C"
      //  },
      plotOptions: {
        series: {
          marker: {
            enabled: false,
          },
        },
      },

      colors: ['#0DBC67', '#FF4242'],
      series: [
        {
          name: '',
          data: [5000, 20000, 35000, 40000],
        },
      ],
    };
  }
  changelanguage(item: any) {
    console.log('changelanguage', item);
    this.activeState = item;
    this.subscriberDataMonth = [''];
    this.topPerformanceCount = [''];
    this.topPerformanceNames = [''];
    this.subscriberDataCount = [''];
    this.params = {
      language: item,
      type: 'month',
    };
    this.adminService
      .PublisherAnalytics(this.params)
      .subscribe((chatData: any) => {
        console.log(chatData);
        this.subscribers = chatData.TotalSubscriber;
        // this.loader = false;

        this.items = [
          { name: 'Total Summaries', number: chatData.totalSummaryCount },
          { name: 'Subscribers', number: chatData.TotalSubscriber },
          { name: 'Reads', number: chatData.totalreads },
          { name: 'Comments', number: chatData.summaryCommentCount },
        ];
        this.data = chatData.topPerfromingCategories;
        this.subscriberData = chatData.SubscriberData;

        /* subscriber chart data */
        this.subscriberData.forEach((item: any) => {
          this.subscriberDataMonth.push(item.name);
          this.subscriberDataCount.push(item.count);
        });
        this.chartOptionsBar = {
          chart: {
            type: 'column',
          },
          title: {
            text: '',
          },
          credits: {
            enabled: true,
          },
          legend: { enabled: false },
          //  subtitle: {
          //     text: "Source: WorldClimate.com"
          //  },
          xAxis: {
            gridLineWidth: 0,
            minorGridLineWidth: 0,
            lineWidth: 0,
            lineColor: 'transparent',
            title: {
              text: '',
            },
            labels: {
              style: {
                color: '#5A657F',
                fontSize: 12,
                fontWeight: 600,
                fontFamily: 'Inter',
              },
            },
            categories: this.subscriberDataMonth,
          },
          yAxis: {
            gridLineWidth: 0,
            minorGridLineWidth: 0,
            title: {
              text: '',
            },
            labels: {
              format: '{value}',
              style: {
                color: '#425466',
                fontSize: 12,
                fontWeight: 600,
                fontFamily: 'Inter',
              },
            },
          },
          //  tooltip: {
          //     valueSuffix:" °C"
          //  },
          plotOptions: {
            column: {
              dataLabels: {
                enabled: true,
              },
            },
          },

          colors: ['#0a090a', ''],
          series: [
            {
              name: '',
              data: this.subscriberDataCount,
            },
          ],
        };

        /* top-performance chart data */
        this.data.forEach((item: any) => {
          this.topPerformanceCount.push(item.count);
          this.topPerformanceNames.push(item.category);
        });
        this.chartOptionsRevered = {
          chart: {
            type: 'bar',
          },
          title: {
            text: 'Blogs',
          },
          credits: {
            enabled: true,
          },

          xAxis: {
            categories: this.topPerformanceNames,
          },
          yAxis: {
            min: 0,
            title: {
              text: 'Goals',
            },
          },
          legend: {
            reversed: true,
          },
          colors: ['#076611ab'],
          series: [
            {
              name: '',
              data: this.topPerformanceCount,
            },
          ],
        };
      });
  }
  dateChange(e: any) {
    this.language = e.target.value;
    this.loader = true;
    this.subscriberDataMonth = [''];
    this.subscriberDataCount = [''];
    this.params = {
      type: this.language,
    };
    this.adminService
      .PublisherAnalytics(this.params)
      .subscribe((chatData: any) => {
        console.log(chatData);
        this.loader = false;

        this.subscribers = chatData.TotalSubscriber;
        this.items = [
          { name: 'Total Summaries', number: chatData.totalSummaryCount },
          { name: 'Subscribers', number: chatData.TotalSubscriber },
          { name: 'Reads', number: chatData.totalreads },
          { name: 'Comments', number: chatData.summaryCommentCount },
        ];
        this.subscriberData = chatData.SubscriberData;
        this.subscriberData.forEach((item: any) => {
          if (this.language == 'month') {
            this.subscriberDataMonth.push(item.month);
            this.subscriberDataCount.push(item.count);
          } else {
            this.subscriberDataMonth.push(item.year);
            this.subscriberDataCount.push(item.count);
          }
        });
        this.chartOptionsBar = {
          chart: {
            type: 'column',
          },
          title: {
            text: '',
          },
          credits: {
            enabled: true,
          },
          legend: { enabled: false },
          //  subtitle: {
          //     text: "Source: WorldClimate.com"
          //  },
          xAxis: {
            gridLineWidth: 0,
            minorGridLineWidth: 0,
            lineWidth: 0,
            lineColor: 'transparent',
            title: {
              text: '',
            },
            labels: {
              style: {
                color: '#5A657F',
                fontSize: 12,
                fontWeight: 600,
                fontFamily: 'Inter',
              },
            },
            categories: this.subscriberDataMonth,
          },
          yAxis: {
            gridLineWidth: 0,
            minorGridLineWidth: 0,
            title: {
              text: '',
            },
            labels: {
              format: '{value}',
              style: {
                color: '#425466',
                fontSize: 12,
                fontWeight: 600,
                fontFamily: 'Inter',
              },
            },
          },
          //  tooltip: {
          //     valueSuffix:" °C"
          //  },
          plotOptions: {
            column: {
              dataLabels: {
                enabled: true,
              },
            },
          },

          colors: ['#0a090a', ''],
          series: [
            {
              name: 'subscribers',
              data: this.subscriberDataCount,
            },
          ],
        };
      });
  }
}
