import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { setupTestingRouter } from '@angular/router/testing';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { DynamicShareButtonDialogComponent } from '../dynamic-share-button-dialog/dynamic-share-button-dialog.component';
import { NoAuthSignupFollowComponent } from '../no-auth-signup-follow/no-auth-signup-follow.component';
import { NoAuthSignupComponent } from '../no-auth-signup/no-auth-signup.component';
import { ShareproductLinkDialogComponent } from '../shareproduct-link-dialog/shareproduct-link-dialog.component';
import { CollectionService } from '../_services/collection.service';
import { InviteDialogComponent } from './invite-dialog/invite-dialog.component';
import { InviteSuccefullyComponent } from './invite-succefully/invite-succefully.component';
import { CommentSectionDialogComponent } from '../homepage/comment-section-dialog/comment-section-dialog.component';
import { SuccessdialoghomeComponent } from '../homepage/successdialoghome/successdialoghome.component';
import { ReportDialogComponent } from '../homepage/report-dialog/report-dialog.component';
declare var clevertap: any;

@Component({
  selector: 'app-fliterwithchannelname',
  templateUrl: './fliterwithchannelname.component.html',
  styleUrls: ['./fliterwithchannelname.component.css'],
})
export class FliterwithchannelnameComponent implements OnInit {
  activeState: any;
  productName: any;
  username: any;
  queryParms: any;
  channelName: any;
  productList: any;
  type: any = 'product';
  collectionList: any;
  activeProduct: any = true;
  productShow: any = true;
  collectionShow: any = false;
  activeCollection: any = false;
  selectedTab: number;
  unfollowBtnShow: boolean = false;
  followBtnShow: boolean = true;
  token: string | null;
  userType: string | null;
  userId: string | null;
  channelFollowList: any;
  userID: any;
  productType: any;
  productTypeDetails: any;
  params: any;
  ChannelName: any;
  profile: any;
  profileImg: any;
  Collectiontype: any = 'collection';
  totalFollowers: any;
  totalProducts: any;
  sessionType: string;
  channelNames: any = [];
  productTitle: any;
  productId: any;
  productError: any;
  ChannelNameUrl: any;
  tabindex: number;
  userTypeId: any = '';
  loading: boolean = false;
  footerShow: boolean = false;
  language: any = '';
  languageParams: { channelName: any; type: any; language: any };
  collectionLanguageList: any;
  collectionLoad: boolean = false;
  channeldetails: any;
  channelParams: any;
  channelTitle: any;
  channelDesc: any;
  channelImage: any;
  shareLink: any;
  userApiCalled: boolean = false;
  channelUrl: any;
  channelDetails: any;
  channelLoop: any = [];
  collectionParams: any;
  pageSize: any = 10;
  currentPage: any = 1;
  pageSizeOptions: number[] = [5, 10, 20, 40, 100];
  currentLimit: number;
  noOfRecords: any;
  catetoryLimit: number;
  activeParams: any;
  queryParamValue: any;
  shareDataLink: string;
  userName: any;
  isBrowser: any;
  commentForm!: FormGroup;
  editCommentForm!: FormGroup;
  replyCommentForm!: FormGroup;
  replyEditCommentForm!: FormGroup;
  showButtons = false;
  inputActive: boolean = false;
  commentBtnEvent: boolean = true;
  commentBtnEventReply: boolean = true;
  readonly: boolean = true;
  replyCommentShow: boolean = false;
  showButtonsReply: boolean = false;
  inputValue: any = '';
  commentUser: any;
  commentsValue: any;
  errorLength: boolean = false;
  comment: any;
  ReplycommentUser: any = [];
  Replycomment: any;
  replyShow: boolean = false;
  hideTabs: boolean = false;
  commentId: any;
  showReplys: boolean = false;
  totalComments: any;
  showEditForm: boolean = false;
  showEditButtons: boolean = false;
  editActive: boolean = false;
  showReplyEditButtons: boolean = false;
  collectionUserId: any;
  CollectionID: any;

  lottieimage: boolean = false;
  summaryId: any;
  showComments: boolean = false;
  showCommentLoader: boolean = false;
  commentsLoaderPostion: string = 'comments_loader_postion_change_css';
  languageChange: any = '';
  success: boolean = true;
  url: any = `${environment.URL}`;
  channelTopic: any;
  Language: any = [
    'English',
    'Chinese',
    'Hindi',
    'Spanish',
    'Arabic',
    'French',
    'Telugu',
    'Russian',
    'German',
    'Tamil',
    'Malayalam',
  ];
  topicName: any = '';
  topicNames: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private productService: CollectionService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private metaTagService: Meta,
    private TitleService: Title,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.commentForm = this.fb.group({
      title: ['', [Validators.required, Validators.maxLength(1000)]],
    });
    this.replyCommentForm = this.fb.group({
      reply: ['', Validators.required],
    });
    this.editCommentForm = this.fb.group({
      edit: ['', Validators.required],
    });
    this.replyEditCommentForm = this.fb.group({
      replyEdit: ['', Validators.required],
    });
    this.isBrowser = isPlatformBrowser(platformId);
    this.channelName = this.router.url.split('/');
    this.channelParams = {
      publisherName: this.channelName[1],
    };

    this.productService.ChannelDetails(this.channelParams).subscribe((res) => {
      console.log(res);
      this.channelDetails = res.data;
      if (this.channelDetails == '' || this.channelDetails == null) {
        this.router.navigate(['/404-page-not-found']);
      }

      this.productService.topicNames(this.channelParams).subscribe((res) => {
        this.topicNames = res.data[0]?.topics;
      });
      this.channelLoop.push(this.channelDetails);
      this.channelTitle = res.data.title;
      this.channelDesc = res.data.description;
      this.channelImage = res.data.profile;
      this.channelTopic = res.data.topic;
      this.channelUrl = res.data.url;
      this.shareLink = `${environment.URL}/${res.data.name}`;
      this.metaTagService.updateTag({
        name: 'og:title',
        property: 'content',
        content: this.channelTitle,
      });

      this.TitleService.setTitle(this.channelTitle);
      this.metaTagService.updateTag({
        name: 'og:description',
        property: 'content',
        content: this.channelDesc,
      });
      this.metaTagService.updateTag({
        name: 'og:image',
        property: 'content',
        content: this.channelImage,
      });
      this.metaTagService.updateTag({
        name: 'og:image:width',
        property: 'content',
        content: '250',
      });
      this.metaTagService.updateTag({
        name: 'og:image:height',
        property: 'content',
        content: '250',
      });
      this.metaTagService.updateTag({
        name: 'og:url',
        property: 'content',
        content: this.shareLink,
      });
    });
  }

  ngOnInit(): void {
    clevertap.init('6ZW-454-496Z');

    this.activeParams = this.route.queryParams.subscribe((params) => {
      this.queryParamValue = params['active']; // Access the 'id' query parameter
      if (params['active'] === 'true') {
        //
        this.dialog.open(InviteSuccefullyComponent, {
          data: {
            name: this.channelName[1],
            disableClose: true,
          },
        });
      }
    });
    this.loading = true;
    this.channelName = this.router.url.split('/');
    // console.log(this.channelName);

    // if (this.channelName[2] == '') {
    //   this.languageChange = 'english';
    // }

    // if (this.channelName[2] == 'language') {
    //   if (this.channelName[3] != '') {
    //     this.languageChange = this.channelName[3];
    //     console.log('langage', this.languageChange);
    //   }
    // }
    if (this.productService.getToken() == '') {
      // console.log('not-login');
      this.sessionType = 'home';
    }
    if (this.productService.getToken() != '') {
      this.productService.loginUserDetails().subscribe((result) => {
        this.sessionType = result.data.userType;
        this.userTypeId = result.data._id;
        this.userName = result.data.name;
        // console.log(this.userTypeId);
        this.queryParms = {
          publisherName: this.channelName[1],
          id: this.userTypeId,
          language: this.languageChange,
          pageLimit: this.pageSize ? this.pageSize : '',
          pageNumber: this.currentPage,
        };
        // this.productService
        //   .fliterbyChannelNameProductCollection(this.queryParms)
        //   .subscribe((res: any) => {
        //     this.productList = res.productData;
        //     this.collectionList = res.collectionData;
        //     this.collectionLanguageList = res.collectionData;
        //     this.noOfRecords = res.totalCategoryRecord;

        //     this.loading = false;
        //     this.footerShow = true;

        //     // console.log(this.productList);

        //     this.collectionList?.forEach((item: any) => {
        //       this.userID = item.userId;
        //     });
        //     this.productList.forEach((item: any) => {
        //       this.userID = item.userId;
        //     });
        //     this.totalProducts = this.productList.filter(
        //       (item: any) => item
        //     ).length;
        //     // console.log('total', this.totalProducts);
        //     // console.log('USERID', this.userID);
        //   });
      });
    }
    setTimeout(() => {
      if (this.productService.getToken() != '') {
        this.productService.ChannelFollowList().subscribe((res: any) => {
          // console.log('followers', res);
          this.channelFollowList = res.data;
          this.totalFollowers = res.totalFollowers;
          this.channelFollowList.forEach((item: any) => {
            this.channelNames.push(item.publisherName);
          });
          let channel = this.channelNames.includes(this.channelName[1]);
          // console.log('channel', channel);
          if (channel) {
            // console.log('follow match');
            this.unfollowBtnShow = true;
            this.followBtnShow = false;
          } else {
            // console.log(' follow not match');
            this.followBtnShow = true;
            this.unfollowBtnShow = false;
          }
        });
      }
    }, 2000);
    const languageToTabMapping: any = {
      hindi: 2,
      chinese: 1,
      spanish: 3,
      arabic: 4,
      telugu: 5,
      russian: 6,
      german: 7,
      tamil: 8,
      malayalam: 9,
      Hindi: 2, // Include uppercase variants if needed
      Chinese: 1,
      Spanish: 3,
      Arabic: 4,
      Telugu: 5,
      Russian: 6,
      German: 7,
      Tamil: 8,
      Malayalam: 9, // If 'ma' maps to 9 for 'malayalam'
    };
    if (this.languageChange in languageToTabMapping) {
      this.selectedTab = languageToTabMapping[this.languageChange];
    } else {
      // Handle the case when the language is not found in the mapping
      // You can set a default value or handle it as needed.
    }

    if (this.productService.getToken() == '') {
      this.queryParms = {
        publisherName: this.channelName[1],
        language: this.language,
        topicName: this.topicName,
        pageLimit: this.pageSize ? this.pageSize : '',
        pageNumber: this.currentPage,
      };
      this.productService
        .fliterbyChannelNameProductCollection(this.queryParms)
        .subscribe((res: any) => {
          this.productList = res.productData;
          this.noOfRecords = res.totalCategoryRecord;

          this.loading = false;
          this.footerShow = true;

          this.collectionList = res.collectionData;
          this.collectionLanguageList = res.collectionData;

          // console.log(this.productList);

          this.collectionList?.forEach((item: any) => {
            this.userID = item.userId;
          });
          this.productList.forEach((item: any) => {
            this.userID = item.userId;
          });
          this.totalProducts = this.productList.filter(
            (item: any) => item
          ).length;
          // console.log('total', this.totalProducts);
          // console.log('USERID', this.userID);
        });
    }
  }
  onScrollDown(ev: any) {
    this.currentLimit = this.noOfRecords / 10;
    console.log('limmit', this.currentLimit);
    console.log('records', this.noOfRecords);
    this.catetoryLimit = this.noOfRecords / 10;
    if (this.success) {
      if (Math.round(this.catetoryLimit) >= this.currentPage) {
        // this.appendItems();
      }
    }
  }
  link() {
    this.dialog.open(SuccessdialoghomeComponent, {
      data: {
        title: 'collection.name',
        view: 'collection',
        topic: false,
        link: true,
        publisher: false,
        bookmark: false,
        disableClose: true,
      },
    });
  }
  report(collections: any) {
    // console.log(text);
    if (this.productService.getToken() == '') {
      this.readonly = true;
      this.dialog.open(NoAuthSignupComponent, {
        data: {
          title: `to report`,
          ShareLink: `/c/${collections.summaryName.split(' ').join('-')}/id-${
            collections._id
          }`,
          disableClose: true,
        },
      });
    } else {
      this.dialog.open(ReportDialogComponent, {
        data: {
          title: collections._id,
          disableClose: true,
        },
      });

      // let obj = {
      //   report_type: text,
      // };
      // this.collectionService
      //   .reportSummary(collections._id, obj)
      //   .subscribe((result) => {
      //     Swal.fire({
      //       title:
      //         'Thanks for reporting.Our team will review your feedback and take necessary actions',
      //       icon: 'success', // You can change the icon type
      //       timer: 4000, // Set the timer to 3000 milliseconds (3 seconds)
      //       showConfirmButton: false, // Hide the "OK" button
      //     });
      //     console.log(result);
      //   });
    }
  }
  UnfollowBtn(collection: any, boolean: any) {
    // console.log(id);
    if (this.productService.getToken() == '') {
      this.dialog.open(NoAuthSignupFollowComponent, {
        data: {
          title: 'to follow  the channel',
          disableClose: true,
        },
      });
    } else {
      let obj = {
        publisherId: collection.publisherId,
      };
      this.productService
        .ChannelUnFollowList(collection.publisherId)
        .subscribe((res) => {
          // this.following = false;
          // this.follow = true;
          collection.followPublisher = false;
          this.dialog.open(SuccessdialoghomeComponent, {
            data: {
              title: collection.name,
              view: collection,
              topic: false,
              publisher: true,
              bookmark: false,
              disableClose: true,
            },
          });
          // this.followPublisher = false;
        });
    }
  }
  dislikeSummary(collections: any, likes: Boolean, id: any) {
    // console.log('clevertap succefully event', clevertap.event);

    if (this.productService.getToken() == '') {
      //clevertap intergation event
      if (this.isBrowser) {
        if (environment.CLEVERTAP) {
          clevertap.event.push('PreLoginBlinks', {
            PreLoginBlinks: 'PreLogin Blink click',
            PreLoginBlinksSummaryName: collections.summaryName,
            PreLoginBlinksId: collections._id,
          });
        }
      }
      // this.collectionsClick(collections._id);
      //clevertap intergation event
      this.dialog.open(NoAuthSignupComponent, {
        data: {
          title: 'unBlinkcollection',
          ShareLink: `/c/${collections.summaryName.split(' ').join('-')}/id-${
            collections._id
          }`,
          disableClose: true,
        },
      });
    } else {
      this.productService
        .userDisLikeCollections(collections._id)
        .subscribe((res) => {
          //clevertap intergation event
          if (this.isBrowser) {
            if (environment.CLEVERTAP) {
              clevertap.event.push('PostLoginBlinks', {
                PostLoginBlinks: 'PostLogin Blink collections',
                PostLoginBlinksSummaryName: collections.summaryName,
                PostLoginBlinksId: collections._id,
              });
            }
          }
          //clevertap intergation event
          // this.lottieimage = true;
          collections.summaryLikeCount++;
          collections.summaryLike = true;

          // setTimeout(() => {
          //   this.lottieimage = false;
          // }, 3000);
        });
    }
  }
  writeComment(collections: any) {
    this.dialog.open(CommentSectionDialogComponent, {
      data: {
        link: `${environment.SHARE_URL}/c/${collections.summaryName
          .split(' ')
          .join('-')}/id-${collections._id}`,
        video: collections.video,
      },
    });
  }
  appendItems() {
    // console.log('loading.......', this.collectionName);
    this.success = false;
    if (this.productService.getToken() != '') {
      this.collectionParams = {
        publisherName: this.channelName[1],
        id: this.userTypeId,
        language: this.languageChange,
        topicName: this.topicName,
        pageLimit: this.pageSize ? this.pageSize : '',
        pageNumber: ++this.currentPage,
      };
    } else {
      this.collectionParams = {
        publisherName: this.channelName[1],
        language: this.languageChange,
        topicName: this.topicName,
        pageLimit: this.pageSize ? this.pageSize : '',
        pageNumber: ++this.currentPage,
      };
    }

    this.productService
      .fliterbyChannelNameProductCollection(this.collectionParams)
      .subscribe((response) => {
        this.collectionList.push(...response.collectionData);
        this.success = true;
      });
  }
  langugeChange(e: any) {
    this.language = e.target.value;
    // this.summariesApiList();
    if (this.productService.getToken() == '') {
      this.queryParms = {
        publisherName: this.channelName[1],
        language: this.language,
        topic: this.topicName,
        pageLimit: this.pageSize ? this.pageSize : '',
        pageNumber: this.currentPage,
      };
    } else {
      this.queryParms = {
        publisherName: this.channelName[1],
        language: this.language,
        id: this.userTypeId,
        topic: this.topicName,
        pageLimit: this.pageSize ? this.pageSize : '',
        pageNumber: this.currentPage,
      };
    }
    this.collectionLoad = true;
    this.productService
      .fliterbyChannelNameProductCollection(this.queryParms)
      .subscribe((res: any) => {
        this.collectionLoad = false;

        this.productTypeDetails = res.productData;
        this.collectionLanguageList = res.collectionData;
        this.productList = res.productData;
        this.collectionList = res.collectionData;
        this.collectionLanguageList = res.collectionData;
        this.noOfRecords = res.totalCategoryRecord;
      });
  }
  tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {
    console.log('tabChangeEvent => ', tabChangeEvent.tab.textLabel);
    console.log('index => ', tabChangeEvent.index);
    this.topicName = tabChangeEvent.tab.textLabel;
    if (this.productService.getToken() == '') {
      this.queryParms = {
        publisherName: this.channelName[1],
        language: this.language,
        topic: this.topicName,
        pageLimit: this.pageSize ? this.pageSize : '',
        pageNumber: this.currentPage,
      };
    } else {
      this.queryParms = {
        publisherName: this.channelName[1],
        language: this.language,
        id: this.userTypeId,
        topic: this.topicName,
        pageLimit: this.pageSize ? this.pageSize : '',
        pageNumber: this.currentPage,
      };
    }
    this.collectionLoad = true;
    this.productService
      .fliterbyChannelNameProductCollection(this.queryParms)
      .subscribe((res: any) => {
        this.collectionLoad = false;

        this.productTypeDetails = res.productData;
        this.collectionLanguageList = res.collectionData;
        this.productList = res.productData;
        this.collectionList = res.collectionData;
        this.collectionLanguageList = res.collectionData;
        this.noOfRecords = res.totalCategoryRecord;
      });
    // if (this.tabindex) {
    //   this.currentPage = 1;
    // }
    // console.log('pagination', this.currentPage);
    // this.productType = tabChangeEvent.tab.textLabel.toLowerCase();
    // this.languageChange = tabChangeEvent.tab.textLabel.toLowerCase();
    // console.log('productType => ', this.productType);

    // if (this.productType == 'english') {
    //   this.queryParms = {
    //     publisherName: this.channelName[1],
    //     type: this.Collectiontype,
    //     language: this.languageChange,
    //     pageLimit: this.pageSize ? this.pageSize : '',
    //     pageNumber: this.currentPage,
    //   };
    //   console.log('productType => ', this.productType);

    //   this.productService
    //     .fliterbyChannelNameProductCollection(this.queryParms)
    //     .subscribe((res: any) => {
    //       this.productTypeDetails = res.productData;

    //       console.log(this.productList);
    //     });
    // } else {
    //   if (this.productService.getToken() == '') {
    //     this.queryParms = {
    //       publisherName: this.channelName[1],
    //       type: this.type,
    //       productType: this.languageChange,
    //       language: this.languageChange,
    //       pageLimit: this.pageSize ? this.pageSize : '',
    //       pageNumber: this.currentPage,
    //     };
    //   } else {
    //     this.queryParms = {
    //       publisherName: this.channelName[1],
    //       type: this.type,
    //       productType: this.languageChange,
    //       language: this.languageChange,
    //       id: this.userTypeId,
    //       pageLimit: this.pageSize ? this.pageSize : '',
    //       pageNumber: this.currentPage,
    //     };
    //   }
    //   this.collectionLoad = true;
    //   this.productService
    //     .fliterbyChannelNameProductCollection(this.queryParms)
    //     .subscribe((res: any) => {
    //       this.collectionLoad = false;

    //       this.productTypeDetails = res.productData;
    //       this.collectionLanguageList = res.collectionData;
    //       this.productList = res.productData;
    //       this.collectionList = res.collectionData;
    //       this.collectionLanguageList = res.collectionData;
    //       this.noOfRecords = res.totalCategoryRecord;
    //     });
    // }

    // if (tabChangeEvent.index == 1) {
    //   console.log('productType => ', this.productType);
    //   this.router.navigate([`/${this.channelName[1]}/language/chinese`]);

    // } else if (tabChangeEvent.index == 0) {
    //   this.router.navigate([`/${this.channelName[1]}`]);
    // } else if (tabChangeEvent.index == 2) {
    //   this.router.navigate([`/${this.channelName[1]}/language/hindi`]);
    // } else if (tabChangeEvent.index == 3) {
    //   this.router.navigate([`/${this.channelName[1]}/language/spanish`]);
    // } else if (tabChangeEvent.index == 4) {
    //   this.router.navigate([`/${this.channelName[1]}/language/arabic`]);
    // } else if (tabChangeEvent.index == 5) {
    //   this.router.navigate([`/${this.channelName[1]}/language/telugu`]);
    // } else if (tabChangeEvent.index == 6) {
    //   this.router.navigate([`/${this.channelName[1]}/language/russian`]);
    // } else if (tabChangeEvent.index == 7) {
    //   this.router.navigate([`/${this.channelName[1]}/language/german`]);
    // } else if (tabChangeEvent.index == 8) {
    //   this.router.navigate([`/${this.channelName[1]}/language/tamil`]);
    // } else if (tabChangeEvent.index == 9) {
    //   this.router.navigate([`/${this.channelName[1]}/language/malayalam`]);
    // } else if (tabChangeEvent.index == 10) {
    //   this.router.navigate([`/${this.channelName[1]}/myproducts`]);
    // } else if (tabChangeEvent.index == 11) {
    //   this.router.navigate([`/${this.channelName[1]}/appsipromote`]);
    // } else if (tabChangeEvent.index == 12) {
    //   this.router.navigate([`/${this.channelName[1]}/topbooks`]);
    // } else if (tabChangeEvent.index == 13) {
    //   this.router.navigate([`/${this.channelName[1]}/mydeals`]);
    // } else if (tabChangeEvent.index == 14) {
    //   this.router.navigate([`/${this.channelName[1]}/mygeariuse`]);
    // } else if (tabChangeEvent.index == 15) {
    //   this.router.navigate([`/${this.channelName[1]}/recommendations`]);
    // } else if (tabChangeEvent.index == 16) {
    //   this.router.navigate([`/${this.channelName[1]}/branddeals`]);
    // } else if (tabChangeEvent.index == 17) {
    //   this.router.navigate([`/${this.channelName[1]}/general`]);
    // }
  };
  CollectionUrl(title: any, id: any) {
    this.router.navigate([`c/${title.split(' ').join('-')}/id-${id}`]);
  }
  productDetails(title: any, id: any) {
    this.productTitle = title;
    this.productId = id;
    this.router.navigate([
      `/p/${this.productTitle.split(' ').join('-')}/id-${this.productId}`,
    ]);
  }
  share(collections: any) {
    this.collectionsClick(collections._id);
    //clevertap intergation event

    if (environment.CLEVERTAP) {
      clevertap.event.push('summaryShare', {
        LoginshareSummaryName: collections.summaryName,
        LoginshareId: collections._id,
      });
    }

    //clevertap intergation event
    this.dialog.open(DynamicShareButtonDialogComponent, {
      data: {
        title: `${environment.SHARE_URL}/c/${collections.summaryName
          .split(' ')
          .join('-')}/id-${collections._id}`,
        collectionName: collections.summaryName.split(' ').join('-'),
        disableClose: true,
      },
    });
  }
  inviteDialog() {
    if (environment.CLEVERTAP) {
      clevertap.event.push('PublisherProflieInviteClicks', {
        PublisherProflieInvite: this.channelName[1],
      });
    }

    this.dialog.open(InviteDialogComponent, {
      data: {
        name: this.channelName[1],
        profileName: 'publisher',
        title: this.channelTitle,
        shareLink: `${environment.SHARE_URL}/${this.channelName[1]}/invite?active=true`,
        disableClose: true,
      },
    });
  }

  collectionsClick(item: any) {
    let click = {
      summaryId: item,
    };
    this.productService.collectionClicks(click, item).subscribe((result) => {
      console.log(result);
    });
  }
  saveCollection(
    collections: any,
    bookmark: boolean,
    collectionId: any,
    title: any
  ) {
    this.collectionsClick(collections._id);
    if (this.productService.getToken() == '') {
      //clevertap intergation event

      if (environment.CLEVERTAP) {
        clevertap.event.push('PreLoginBookmarks', {
          PreLoginBookmarks: 'Pre login bookmark click',
          PreLoginBookmarksSummarieName: collections.summaryName,
          PreLoginBookmarksSummarieId: collections._id,
        });
      }

      //clevertap intergation event
      this.dialog.open(NoAuthSignupComponent, {
        data: {
          title: 'Read later this collection',
          ShareLink: `/c/${title.split(' ').join('-')}/id-${collectionId}`,
          disableClose: true,
        },
      });
    } else {
      let obj = {
        summaryId: collectionId,
      };
      this.productService.userCollectionGet(obj).subscribe(
        (res) => {
          //clevertap intergation event

          if (environment.CLEVERTAP) {
            clevertap.event.push('PostLoginBookmarks', {
              PostLoginBookmarksreadSummaryLater: 'Summary has been bookmarked',
              PostLoginBookmarksSummarieName: collections.summaryName,
              PostLoginBookmarksSummarieId: collections._id,
            });
          }

          //clevertap intergation event
          collections.bookmarkSummary = true;
        },
        (error) => {}
      );
    }
  }

  tweetBtn(item: any) {
    // this.collectionsClick(item._id);
    // console.log(item);
    this.shareDataLink = `${environment.SHARE_URL}/c/${item.summaryName
      .split(' ')
      .join('-')}/id-${item._id}`;
    return `https://www.shareaholic.com/api/share/?v=1&apitype=1&apikey=2ceb7e6973521a10a3e4b25ad56072e7
  &service=twitter&title=${item.summaryName}&link=${
      environment.SHARE_URL
    }/c/${item.summaryName.split(' ').join('-')}/id-${
      item._id
    }/&source=Shareaholic`;
  }
  subscribeChannel(collections: any, subscribe: boolean) {
    if (this.productService.getToken() == '') {
      if (environment.CLEVERTAP) {
        clevertap.event.push('PublisherProflieClicks', {
          PublisherProflieName: collections.publisherName,
        });
      }
      this.dialog.open(NoAuthSignupComponent, {
        data: {
          title: 'Follow this channel',
          ShareLink: `/c/${collections.summaryName.split(' ').join('-')}/id-${
            collections._id
          }`,
          disableClose: true,
        },
      });
    } else {
      this.params = {
        publisherName: collections.publisherName,
        publisherId: collections.publisherId,
      };
      this.productService.followCreatorChannel(this.params).subscribe((res) => {
        if (environment.CLEVERTAP) {
          clevertap.event.push('PublisherProflieFollows', {
            PublisherProflieName: collections.publisherName,
            readerName: this.userName,
          });
        }

        // this.followersAPI();
        collections.followpublisher = true;
      });
    }
  }
  likeCollections(collections: any, likes: Boolean, id: any) {
    console.log('clevertap succefully event', clevertap.event);
    this.collectionsClick(collections._id);
    if (this.productService.getToken() == '') {
      //clevertap intergation event

      if (environment.CLEVERTAP) {
        clevertap.event.push('PreLoginBlinks', {
          PreLoginBlinks: 'PreLogin Blink click',
          PreLoginBlinksSummaryName: collections.summaryName,
          PreLoginBlinksId: collections._id,
        });
      }

      //clevertap intergation event
      this.dialog.open(NoAuthSignupComponent, {
        data: {
          title: 'Blink collection',
          ShareLink: `/c/${collections.summaryName.split(' ').join('-')}/id-${
            collections._id
          }`,
          disableClose: true,
        },
      });
    } else {
      this.productService
        .userLikeCollections(collections._id)
        .subscribe((res) => {
          //clevertap intergation event

          if (environment.CLEVERTAP) {
            clevertap.event.push('PostLoginBlinks', {
              PostLoginBlinks: 'PostLogin Blink collections',
              PostLoginBlinksSummaryName: collections.summaryName,
              PostLoginBlinksId: collections._id,
            });
          }

          //clevertap intergation event
          // this.lottieimage = true;
          collections.summaryLikeCount++;
          collections.summaryLike = true;

          // setTimeout(() => {
          //   this.lottieimage = false;
          // }, 3000);
        });
    }
  }
  saveForlater(id: any) {
    console.log('p', id);

    if (this.productService.getToken() == '') {
      this.dialog.open(NoAuthSignupComponent, {
        data: {
          title: 'Bookmark this product',
          disableClose: true,
        },
      });
    } else {
      console.log('saveProductBookmark', id);
      let obj = {
        productId: id,
      };
      this.productService.userProductBookmark(obj).subscribe(
        (result) => {
          // this.saved = true;
          // setTimeout(() => {
          //   this.saved = false;
          // }, 3000);
        },
        (error) => {
          this.productError = error.error.message;
          // setTimeout(() => {
          //   this.productError = '';
          // }, 3000);
        }
      );
    }
  }
  editProduct(id: any) {
    this.router.navigate(['/Edit-Product'], {
      queryParams: { id: id },
    });
  }
  unFollowCategories(categories: any, boolena: any, collections: any) {
    console.log(categories);
    if (this.productService.getToken() == '') {
      this.dialog.open(NoAuthSignupFollowComponent, {
        data: {
          title: 'Follow this channel',
          ShareLink: `/c/${collections.summaryName.split(' ').join('-')}/id-${
            collections._id
          }`,
          disableClose: true,
        },
      });
    } else {
      this.productService
        .caterogyUnFollow(categories._id)
        .subscribe((response) => {
          categories.topicsFollow = false;
        });
    }
  }

  deleteProduct(id: any) {
    this.productService.powerUserDeleteProduct(id).subscribe((res) => {
      console.log(res);
      this.router.navigate(['/feed']);
    });
  }
  product() {
    this.productShow = true;
    this.activeProduct = true;
    this.collectionShow = false;
    this.activeCollection = false;

    this.type = 'product';
    this.queryParms = {
      channelName: this.channelName[1],
      type: this.type,
      productType: this.productType,
    };
    this.productService
      .fliterbyChannelNameProductCollection(this.queryParms)
      .subscribe((res: any) => {
        this.productList = res.productData;
        // this.collectionList = res.collectionData;
        // console.log(this.productList);
      });
  }
  collection() {
    this.collectionShow = true;
    this.activeCollection = true;
    this.productShow = false;
    this.activeProduct = false;
    this.Collectiontype = 'collection';
    this.queryParms = {
      channelName: this.channelName[1],
      type: this.Collectiontype,
    };
    this.productService
      .fliterbyChannelNameProductCollection(this.queryParms)
      .subscribe((res: any) => {
        //  this.productList = res.productData;
        this.collectionList = res.collectionData;
        // console.log(this.productList);
      });
  }
  viewProduct(title: any, id: any) {
    this.router.navigate([`p/${title.split(' ').join('-')}/id-${id}`]);
  }
  followBtn(item: any) {
    console.log(item);
    if (localStorage.getItem('token') == null) {
      this.dialog.open(NoAuthSignupComponent, {
        data: {
          title: ' to Follow this channel',
          ShareLink: 'feed',
          disableClose: true,
        },
      });
    } else {
      this.params = {
        publisherName: item.name,
        publisherId: item._id,
      };
      this.productService.followCreatorChannel(this.params).subscribe((res) => {
        this.unfollowBtnShow = true;
        this.followBtnShow = false;
        this.followersAPI();
        clevertap.event.push('PublisherProflieFollows', {
          PublisherProflieName: item.name,
          readerName: this.userName,
        });
      });
    }
  }
  unFollowBtn(item: any) {
    // console.log(this.userID);
    this.productService.ChannelUnFollowList(item._id).subscribe((res) => {
      // console.log(res);
      this.unfollowBtnShow = false;
      this.followBtnShow = true;
      this.followersAPI();
    });
  }
  followCollectionCatergory(
    item: any,
    catergoriesFollowed: any,
    collections: any
  ) {
    console.log(item);
    if (this.productService.getToken() == '') {
      this.dialog.open(NoAuthSignupComponent, {
        data: {
          title: 'Join this community',
          ShareLink: `/c/${collections.summaryName.split(' ').join('-')}/id-${
            collections._id
          }`,
          disableClose: true,
        },
      });
    } else {
      if (item) {
        let obj = {
          topicId: item._id,
        };
        // console.log(obj);
        this.productService.caterogyFollow(obj).subscribe((res) => {
          // console.log(obj);
          clevertap.event.push('TopicsProflieFollows', {
            TopicsProflieName: item.name,
            readerName: this.userName,
          });
          item.topicsFollow = true;
        });
      } else {
      }
    }
  }
  tweetEvent(item: any) {
    console.log('event clicks');

    if (environment.CLEVERTAP) {
      clevertap.event.push('Tweet', {
        Tweetthis: 'tweet',
        TweetthisSummaryName: item.summaryName,
        TweetthisSummaryId: item._id,
      });
    }
  }
  navClick(name: any) {
    this.activeState = name;
    // console.log(name);
    this.productName = name;
    //   this.productParams = {
    //     categorieName: this.productName,
    //   };
    //   this.productService
    //     .productListFliter(this.productParams)
    //     .subscribe((res) => {
    //       this.productList = res.data;
    //     });
    // }
  }
  followersAPI() {
    this.productService.ChannelFollowList().subscribe((res: any) => {
      console.log('followers', res);
      this.channelFollowList = res.data;
      this.totalFollowers = res.totalFollowers;
    });
  }
  comments(collections: any) {
    if (collections.showCollectionComment) {
      // Close the comment if it's open
      collections.showCollectionComment = false;
    } else {
      this.collectionList.forEach((c: any) => {
        if (c !== collections.showCollectionComment) {
          c.showCollectionComment = false;
        }
      });
      collections.showCollectionComment = true;
    }

    if (collections._id && collections.showCollectionComment == true) {
      this.summaryId = collections._id;
      this.commentsGet();
    }
  }

  // comment ts file
  onSubmitComment(collections: any) {
    console.log(this.commentForm.value);
    if (this.productService.getToken() != '') {
      if (this.commentForm) {
        this.commentBtnEvent = false;
        let obj = {
          summaryId: collections._id,
          comment: this.commentForm.value.title,
        };
        this.productService.commentPost(obj).subscribe((response) => {
          console.log(response);
          // this.commentUser = response.data;
          this.commentsGet();
          this.cancelComment();
        });
      }
    }
  }

  commentsGet() {
    this.showCommentLoader = true;
    this.productService.commentGet(this.summaryId).subscribe((response) => {
      this.commentUser = response.data;
      this.showCommentLoader = false;

      this.totalComments = response.totalComment;
      this.commentUser.forEach((comment: any) => {
        return (comment.showReplyForm = false), (comment.showReplys = false);
      });
      console.log(this.commentUser);
    });
  }
  get commentFormError(): { [key: string]: AbstractControl } {
    return this.commentForm.controls;
  }
  get replyCommentFormError(): { [key: string]: AbstractControl } {
    return this.replyCommentForm.controls;
  }
  // validations for AlphabetsOnly
  nameValidations(event: any, item: any) {
    if (item == 'title') {
      let newValue = event.target.value.replace(/[^a-zA-Z ]/g, '');
      console.log('alphabets', newValue);
      this.commentForm.controls['title'].setValue(newValue);
    } else if (item == 'edit') {
      let newValue = event.target.value.replace(/[^a-zA-Z ]/g, '');
      console.log('alphabets', newValue);
      this.editCommentForm.controls['edit'].setValue(newValue);
    } else if (item == 'reply') {
      let newValue = event.target.value.replace(/[^a-zA-Z ]/g, '');
      console.log('alphabets', newValue);
      this.replyCommentForm.controls['reply'].setValue(newValue);
    } else if (item == 'replyEdit') {
      let newValue = event.target.value.replace(/[^a-zA-Z ]/g, '');
      console.log('alphabets', newValue);
      this.replyEditCommentForm.controls['replyEdit'].setValue(newValue);
    }
  }
  showReplyBtn(item: any) {
    item.showReplys = !item.showReplys;
  }
  cancelComment() {
    this.inputActive = false;
    this.showButtons = false;
    this.commentForm.reset();
    this.editActive = false;
    this.showEditButtons = false;
  }
  spamComment(comment: any, collections: any) {
    console.log(comment._id);
    if (this.productService.getToken() == '') {
      this.readonly = true;
      this.dialog.open(NoAuthSignupComponent, {
        data: {
          title: 'to mark as spam',
          ShareLink: `/c/${collections.summaryName.split(' ').join('-')}/id-${
            collections._id
          }`,
          disableClose: true,
        },
      });
    } else {
      let obj = {
        commentReplyId: '',
      };
      this.productService.commentSpam(obj, comment._id).subscribe(
        (res) => {
          console.log(res);
          Swal.fire({
            title: 'successfully marked as spam',
            icon: 'success', // You can change the icon type
            timer: 2000, // Set the timer to 3000 milliseconds (3 seconds)
            showConfirmButton: false, // Hide the "OK" button
          });
        },
        (error) => {
          console.log(error);
          Swal.fire({
            title: error.error.message,
            icon: 'error', // You can change the icon type
            timer: 2000, // Set the timer to 3000 milliseconds (3 seconds)
            showConfirmButton: false, // Hide the "OK" button
          });
        }
      );
    }
  }
  spamReplyComment(comment: any, replyId: any, collections: any) {
    console.log('commentId', comment._id);
    console.log('repltID', replyId);
    if (this.productService.getToken() == '') {
      this.readonly = true;
      this.dialog.open(NoAuthSignupComponent, {
        data: {
          title: 'to mark as spam',
          ShareLink: `/c/${collections.summaryName.split(' ').join('-')}/id-${
            collections._id
          }`,
          disableClose: true,
        },
      });
    } else {
      let obj = {
        commentReplyId: replyId,
      };
      this.productService.commentSpam(obj, comment._id).subscribe(
        (res) => {
          console.log(res);
          Swal.fire({
            title: 'successfully marked as spam',
            icon: 'success', // You can change the icon type
            timer: 2000, // Set the timer to 3000 milliseconds (3 seconds)
            showConfirmButton: false, // Hide the "OK" button
          });
        },
        (error) => {
          Swal.fire({
            title: error.error.message,
            icon: 'error', // You can change the icon type
            timer: 2000, // Set the timer to 3000 milliseconds (3 seconds)
            showConfirmButton: false, // Hide the "OK" button
          });
        }
      );
    }
  }
  spamReplyOffensive(comment: any, replyId: any, collections: any) {
    console.log('commentId', comment._id);
    console.log('repltID', replyId);

    if (this.productService.getToken() == '') {
      this.readonly = true;
      this.dialog.open(NoAuthSignupComponent, {
        data: {
          title: 'to mark as spam',
          ShareLink: `/c/${collections.summaryName.split(' ').join('-')}/id-${
            collections._id
          }`,
          disableClose: true,
        },
      });
    } else {
      let obj = {
        commentReplyId: replyId,
      };
      this.productService.commentOffensive(obj, comment._id).subscribe(
        (res) => {
          console.log(res);
          Swal.fire({
            title: 'successfully marked as spam',
            icon: 'success', // You can change the icon type
            timer: 2000, // Set the timer to 3000 milliseconds (3 seconds)
            showConfirmButton: false, // Hide the "OK" button
          });
        },
        (error) => {
          Swal.fire({
            title: error.error.message,
            icon: 'error', // You can change the icon type
            timer: 2000, // Set the timer to 3000 milliseconds (3 seconds)
            showConfirmButton: false, // Hide the "OK" button
          });
        }
      );
    }
  }
  spamOffensive(comment: any, collections: any) {
    if (this.productService.getToken() == '') {
      this.readonly = true;
      this.dialog.open(NoAuthSignupComponent, {
        data: {
          title: 'to mark as offensive',
          ShareLink: `/c/${collections.summaryName.split(' ').join('-')}/id-${
            collections._id
          }`,
          disableClose: true,
        },
      });
    } else {
      let obj = {
        commentReplyId: '',
      };
      this.productService.commentOffensive(obj, comment._id).subscribe(
        (res) => {
          console.log(res);
          Swal.fire({
            title: 'successfully marked as offensive',
            icon: 'success', // You can change the icon type
            timer: 2000, // Set the timer to 3000 milliseconds (3 seconds)
            showConfirmButton: false, // Hide the "OK" button
          });
        },
        (error) => {
          console.log(error);
          Swal.fire({
            title: error.error.message,
            icon: 'error', // You can change the icon type
            timer: 2000, // Set the timer to 3000 milliseconds (3 seconds)
            showConfirmButton: false, // Hide the "OK" button
          });
        }
      );
    }
  }
  deleteCommentPublisherandPowerUser(comments: any) {
    console.log('commentID', comments._id);
    console.log('collectionID', this.CollectionID);
    let obj = {
      summaryId: this.CollectionID,
      commentId: comments._id,
    };
    this.productService.deleteCommentPublisher(obj).subscribe((res) => {
      this.commentsGet();
      Swal.fire({
        title: 'Comment delete successfully',
        icon: 'success', // You can change the icon type
        timer: 2000, // Set the timer to 3000 milliseconds (3 seconds)
        showConfirmButton: false, // Hide the "OK" button
      });
    });
  }
  deleteReplyCommentPublisherandPowerUser(comments: any, replyId: any) {
    console.log('commentID', comments._id);
    console.log('collectionID', this.CollectionID);
    let obj = {
      summaryId: this.CollectionID,
      commentId: comments._id,
      commentReplyId: replyId,
    };
    this.productService.deleteCommentPublisher(obj).subscribe((res) => {
      this.commentsGet();
      Swal.fire({
        title: 'Comment delete successfully',
        icon: 'success', // You can change the icon type
        timer: 2000, // Set the timer to 3000 milliseconds (3 seconds)
        showConfirmButton: false, // Hide the "OK" button
      });
    });
  }
  // edit and delete comment buttons and api calls
  editMainComment(editData: any) {
    editData.isEdit = !editData.isEdit;
    console.log(editData);
    this.editCommentForm.controls['edit'].setValue(editData.comment);

    this.toggleEditButtons();
  }
  editReplyComment(editData: any) {
    editData.isEdit = !editData.isEdit;
    console.log(editData);
    this.replyEditCommentForm.controls['replyEdit'].setValue(editData.comment);

    this.toggleReplyEditButtons();
  }
  cancelEditComment(item: any) {
    item.isEdit = false;
    this.editActive = false;
    this.showEditButtons = false;
  }
  saveEditForm(editData: any) {
    console.log(this.editCommentForm.value.edit);
    let obj = {
      comment: this.editCommentForm.value.edit,
      commentReply: false,
    };
    this.productService
      .commentUpdateReply(obj, editData._id)
      .subscribe((response) => {
        this.commentsGet();
      });
  }
  deletComment(editData: any) {
    console.log(this.editCommentForm.value.edit);
    let obj = {
      commentReplyId: '',
    };
    this.productService
      .commentDeleteReply(obj, editData._id)
      .subscribe((response) => {
        this.commentsGet();
      });
  }
  deletReplyComment(editData: any, replyId: any) {
    console.log(this.editCommentForm.value.edit, replyId);
    let obj = {
      commentReplyId: replyId,
    };
    this.productService
      .commentDeleteReply(obj, editData._id)
      .subscribe((response) => {
        this.commentsGet();
      });
  }
  saveReplyEditForm(editData: any) {
    console.log(this.replyEditCommentForm.value.replyEdit);
    let obj = {
      comment: this.replyEditCommentForm.value.replyEdit,
      commentReply: true,
    };
    this.productService
      .commentUpdateReply(obj, editData._id)
      .subscribe((response) => {
        this.commentsGet();
      });
  }
  toggleEditButtons() {
    if (this.productService.getToken() != '') {
      this.editActive = true;
      this.readonly = false;
      this.showEditButtons = true;
    }
  }
  toggleReplyEditButtons() {
    if (this.productService.getToken() != '') {
      this.editActive = true;
      this.readonly = false;
      this.showReplyEditButtons = true;
    }
  }
  // edit and delete comment buttons and api calls
  commentEvent() {
    if (this.productService.getToken() == '') {
      this.readonly = true;
      this.dialog.open(NoAuthSignupComponent, {
        data: {
          title: 'to write the comment',

          // ShareLink: `/c/${this.userDetailsParams.split(' ').join('-')}/id-${
          //   this.product_id
          // }`,
          disableClose: true,
        },
      });
    }
  }
  toggleButtons() {
    if (this.productService.getToken() != '') {
      this.inputActive = true;
      this.readonly = false;
      this.showButtons = true;
    }
  }

  // reply comment ts file
  replyShowInput(comment: any) {
    if (this.productService.getToken() == '') {
      this.readonly = true;
      this.dialog.open(NoAuthSignupComponent, {
        data: {
          title: 'to reply the comment',
          // image: this.thumbnailImage,
          // ShareLink: `/c/${this.userDetailsParams.split(' ').join('-')}/id-${
          //   this.product_id
          // }`,
          disableClose: true,
        },
      });
    } else {
      console.log('replyId', comment._id);
      this.commentId = comment._id;
      comment.showReplyForm = !comment.showReplyForm;
    }
  }

  commentEventReply(e: any) {
    if (e.target.value != '') {
      this.commentBtnEventReply = false;
    } else {
      this.commentBtnEventReply = true;
    }
  }

  cancelCommentReply(comments: any) {
    this.inputActive = false;
    this.showButtonsReply = false;
    comments.showReplyForm = false;
    this.replyCommentForm.reset();
  }
  onReplyComment() {
    let obj = {
      comment: this.replyCommentForm.value.reply,
    };
    this.productService
      .commentReply(obj, this.commentId)
      .subscribe((response) => {
        this.replyCommentShow = false;
        this.commentsGet();
        this.inputActive = false;
        this.showButtonsReply = false;
        this.replyCommentForm.reset();
      });
    // this.Replycomment = this.replyCommentForm.value.reply;
    // this.ReplycommentUser.push({
    //   name: 'ram',
    //   comment: this.Replycomment,
    //   date: '2023-09-07T05:28:39.342Z',
    //   id: '3456',
    // });
  }

  toggleButtonsReply() {
    this.inputActive = true;
    this.showButtonsReply = true;
  }
}
