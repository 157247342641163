<!-- <app-creatornavbar></app-creatornavbar> -->
<app-homenavbar *ngIf="sessionType == 'home'"></app-homenavbar>
<app-usernavbar *ngIf="sessionType == 'user'"></app-usernavbar>
<app-navbar *ngIf="sessionType == 'powerUser'"></app-navbar>
<app-creatornavbar *ngIf="sessionType == 'publisher'"></app-creatornavbar>

<app-loader *ngIf="loading"></app-loader>

<!-- <div class="main" *ngIf="collectionList == ''">
  <h1>No collection</h1>
</div> -->
<app-loader *ngIf="collectionLoad"></app-loader>

<div class="main">
  <div class="container">
    <div class="mt-md-3 pt-md-3">
      <div
        class="d-md-flex justify-content-between"
        *ngFor="let item of channelLoop"
      >
        <div class="d-flex mr-md-5">
          <div class="profile-img">
            <img
              *ngIf="item?.profile == null"
              src="../../assets/images/user-1.png"
            />
            <img *ngIf="item?.profile != null" src="{{ item.profile }}" />
          </div>
          <div class="ml-lg-4 ml-md-3 mt-md-3 mt-lg-4 name-fields">
            <h3>{{ item?.name }}</h3>
            <h4>
              <a [href]="item?.url | safePipe" target="_blank"
                ><img
                  class="icon-img ml-2"
                  src="../../assets/images/youtube-1.png"
              /></a>
            </h4>

            <!-- <div class="d-flex"><p><span>{{totalFollowers}}</span> followers</p><p class="ml-4"><span>{{totalProducts}} </span>Total products</p></div> -->
          </div>

          <div class="follow ml-md-4">
            <button
              (click)="followBtn(item)"
              type="submit"
              *ngIf="followBtnShow"
            >
              follow
            </button>
            <button
              class="button"
              type="submit"
              (click)="unFollowBtn(item)"
              *ngIf="unfollowBtnShow"
            >
              <span>following</span>
            </button>
          </div>
        </div>
 
        <div class="invite-section mt-md-3 web-section">
          <h4> Don't read these summaries alone</h4>
          <p> Help your friends to discover this AI knowledge</p>
          <div class="invite-button">
            <button (click)="inviteDialog()" class="cursor">
              <img src="../../assets/images/people.png" class="mr-2" />Invite
              friends
            </button>
          </div>
        </div>
        <div class="name-fields ml-md-4 mt-md-2 moblie-section">
          <h2>{{ channelTitle }}</h2>
        </div>
      </div>
      <div class="d-md-flex justify-content-between">
        <div class="name-fields ml-md-4 mt-md-2 web-section">
          <h2>{{ channelTitle }}</h2>
        </div>
        <div class="invite-section mt-md-3 moblie-section">
          <h4>Don't read summaries alone</h4>
          <p>Help your friends to discover AI summaries</p>
          <div class="invite-button">
            <button (click)="inviteDialog()" class="cursor">
              <img src="../../assets/images/people.png" class="mr-2" />Invite
              friends
            </button>
          </div>
        </div>

        <div class="d-flex language-header ml-4">
          <p>language</p>
          <select (change)="langugeChange($event)" class="selectpicker">
            <option value="">select</option>
            <option *ngFor="let item of Language" value="{{ item }}">
              {{ item }}
            </option>
          </select>
        </div>
      </div>

      <mat-tab-group
        mat-stretch-tabs="false"
        mat-align-tabs="start"
        class="mt-md-4"
        [selectedIndex]="selectedTab"
        (selectedTabChange)="tabChanged($event)"
      >
        <div class="d-flex">
          <div>
            <mat-tab label="{{ item.name }}" *ngFor="let item of topicNames">
              <div class="language-section" *ngIf="collectionList == ''">
                <h5>No summaries found</h5>
              </div>

              <div class="row">
                <div
                  class="col-md-6 mb-2"
                  *ngFor="let collection of collectionList"
                >
                  <div infinite-scroll (scrolled)="onScrollDown($event)">
                    <div
                      class="summary card mb-1 ml-lg-3 ml-md-1 mt-2"
                      id="fullSummary"
                    >
                      <div class="card-body">
                        <div class="d-flex summary-p">
                          <div class="summary-header ml-md-4 channel-details">
                            <div class="summary-start">
                              <div class="d-flex">
                                <a
                                  href="/c/{{
                                    collection?.summaryName
                                      ?.split(' ')
                                      ?.join('-')
                                  }}/id-{{ collection._id }}"
                                  (click)="collectionsClick(collection._id)"
                                >
                                  <h3>{{ collection.summaryName }}</h3>
                                </a>
                                <!-- <div class="moblie-tweet">
                                  <div
                                    *ngIf="!collection.bookmarkSummary"
                                    class="comment ml-4"
                                    (click)="
                                      saveCollection(
                                        collection,
                                        true,
                                        collection._id,
                                        collection.summaryName
                                      )
                                    "
                                  >
                                    <img
                                      src="../../../assets/images/h.png"
                                      class="img-b"
                                    />
                                  </div>
                                  <div
                                    *ngIf="collection.bookmarkSummary"
                                    class="comment ml-5"
                                  >
                                    <img
                                      src="../../../assets/images/he-1.png"
                                      class="img-b"
                                    />
                                  </div>
                                </div> -->
                              </div>

                              <!-- <img src="{{collection.thumbNail}}" class="img"> -->
                              <img
                                *ngIf="
                                  collection.thumbNail != '' &&
                                  collection.customThumbNail == null
                                "
                                src="{{ collection.thumbNail }}"
                                class="img"
                                loading="lazy"
                                alt="{{ collection.summaryName }}"
                              />
                              <img
                                *ngIf="
                                  collection.customThumbNail != '' &&
                                  collection.thumbNail == null
                                "
                                src="{{ collection.customThumbNail }}"
                                class="img"
                                loading="lazy"
                                alt="customThumbnNail"
                              />
                              <h6>
                                Video source
                                <a target="_blank" href="{{ collection.video }}"
                                  >Youtube</a
                                >
                              </h6>
                              <div
                                class="summary-paragrph summary-text"
                                innerHTML="{{ collection.Summary }}"
                              ></div>
                            </div>
                            <div class="mt-1 read-s">
                              <a
                                href="/c/{{
                                  collection?.summaryName?.split(' ')?.join('-')
                                }}/id-{{ collection._id }}"
                                class="anchor-tag"
                                >Show more</a
                              >
                              <!-- <a (click)="showMoreBlog(collection)" *ngIf="collection.isAnyCategoryAdded"  class="anchor-tag">Show more</a>
                      <a (click)="showLessBlog(collection)" *ngIf="!collection.isAnyCategoryAdded"   class="anchor-tag">Show less</a> -->
                            </div>
                          </div>
                        </div>

                        <div class="channel-card pl-md-1">
                          <div class="d-flex justify-content-around">
                            <div class="channel-avatar-section mr-3">
                              <div class="d-flex">
                                <a>
                                  <div class="avartar">
                                    <img
                                      *ngIf="collection.publisherImage == null"
                                      src="../../../assets/images/user-1.png"
                                      class="user-img"
                                    />
                                    <img
                                      *ngIf="collection.publisherImage != null"
                                      src="{{ collection.publisherImage }}"
                                      class="user-img"
                                    />
                                  </div>
                                </a>
                                <div class="channel-details">
                                  <a>
                                    <h4>{{ collection?.publisherName }}</h4>
                                  </a>
                                  <!-- <div class="d-flex details-tube"><img src="../../../assets/images/youtube-1.png" class="youtube-img"><p>2M+</p></div> -->
                                  <a
                                    class="subscribed"
                                    *ngIf="!collection.followpublisher"
                                    (click)="subscribeChannel(collection, true)"
                                    >Follow</a
                                  >
                                  <a
                                    class="subscribed"
                                    *ngIf="collection.followpublisher"
                                    >Followed</a
                                  >
                                </div>
                              </div>
                            </div>
                            <div>
                              <div>
                                <div
                                  class="category-follow mt-2 mr-3"
                                  *ngFor="
                                    let categories of collection.topics
                                      | fliterCategorty : { name: topicName }
                                  "
                                >
                                  <div class="d-flex">
                                    <div
                                      *ngIf="!categories.topicFollow"
                                      class="catergory-details"
                                    >
                                      <h6 class="mb-md-2">
                                        Join
                                        <span>{{ categories.name }}</span>
                                        community
                                      </h6>

                                      <a
                                        class="catergoryAnchorTag"
                                        (click)="
                                          followCollectionCatergory(
                                            categories,
                                            true,
                                            collection
                                          )
                                        "
                                        >join</a
                                      >
                                    </div>
                                    <div
                                      *ngIf="categories.topicFollow"
                                      class="catergory-details-1"
                                    >
                                      <h6 class="mb-md-2">
                                        <span>{{ categories.name }}</span>
                                        community
                                      </h6>

                                      <a
                                        class="catergoryAnchorTag"
                                        (click)="
                                          unFollowCategories(
                                            categories,
                                            true,
                                            collection
                                          )
                                        "
                                        ><span class="glyphicon glyphicon-"
                                          >Joined</span
                                        ></a
                                      >
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <!-- <div class="tweet web-tweet mt-2 ml-md-4">
                          <a [href]="tweetBtn(collection)" [attr.data-link]="shareLink" data-app="share_buttons" data-app-config='{"service":"twitter"}' target="_blank">Tweet this!
                            <img src="../../assets/images/tw.png">
                          </a>
                        </div> -->
                          </div>
                        </div>
                        <div class="share-section pt-3">
                          <!-- <p class="blink-sum ml-md-5 pl-md-3" *ngIf="collection.summaryLikeCount == 0"> Blink it (read this)</p>
                           <p class="blink-sum ml-md-5 pl-md-3" *ngIf="collection.summaryLikeCount != 0 && !collection.summaryLike">{{ collection.summaryLikeCount }}  Blinked (read this)</p>
                        <p  class="blink-sum ml-md-3 pl-md-3" *ngIf="collection.summaryLike"> you and {{ collection.summaryLikeCount }} others Blinked (read this)</p> -->
                          <div
                            class="d-flex justify-content-around media-screen-flex"
                          >
                            <div class="thumps-up-image pointer">
                              <button
                                class="upVote"
                                *ngIf="!collection.summaryLike"
                                (click)="
                                  likeCollections(
                                    collection,
                                    true,
                                    collection._id
                                  )
                                "
                              >
                                 <img
                                src="../../../assets/images/vote.png" style="width:36px;margin:-5px auto"
                                *ngIf="!collection.summaryLike"
                                
                              />
                              
                                <p  class="d-flex">
                                Vote this summary
                                  <!-- <img mat-raised-button
        matTooltip="a blink means you read this summary"
        matTooltipPosition="left"
        aria-label="Button that displays a tooltip in various positions" src="../../assets/imfages/info.png" class="info-img"> -->
                                </p>
                              </button>

                              <button
                                class="upVote"
                                *ngIf="collection.summaryLike"
                              >
                             <img
                                src="../../../assets/images/vote (1).png"
                               style="width:36px;margin:-5px auto"
                                
                              />

                                <p class="d-flex">
                                   you and {{ collection.summaryLikeCount }} others voted
                        this summary
                                  <!-- <img mat-raised-button
        matTooltip="a blink means you read this summary"
        matTooltipPosition="left"
        aria-label="Button that displays a tooltip in various positions" src="../../assets/images/info.png" class="info-img"> -->
                                </p>
                              </button>
                            </div>
                            <!-- <div class="comment" (click)="comments(collection)" >
                            <img src="../../../assets/images/conversation.png">
                          <p *ngIf="collection.SummaryCommentCount != 0"> {{collection.SummaryCommentCount}} comments</p>
                          <p *ngIf="collection.SummaryCommentCount == 0"> comment</p>
  
                        </div> -->
                            <div class="comment" (click)="share(collection)">
                              <img src="../../../assets/images/send.png" />
                              <p>share</p>
                            </div>

                            <div>
                              <!-- <div
                                *ngIf="!collection.bookmarkSummary"
                                class="comment"
                                (click)="
                                  saveCollection(
                                    collection,
                                    true,
                                    collection._id,
                                    collection.summaryName
                                  )
                                "
                              >
                                <img
                                  src="../../../assets/images/bookmark.png"
                                />
                                <p class="bookmark">Bookmark</p>
                              </div> -->
                              <!-- <div
                                *ngIf="collection.bookmarkSummary"
                                class="comment"
                              >
                                <img
                                  src="../../../assets/images/bookmark.png"
                                />
                                <p>Bookmarked</p>
                              </div> --><div class="ml-auto">
                          <button
                            mat-icon-button
                            [matMenuTriggerFor]="menu1"
                            aria-label="Example icon-button with a menu"
                          >
                            <mat-icon>
                              <img
                                src="../../assets/images/dots.png"
                                class="report"
                                width="35px"
                              />
                              <!-- <p class="report-text">report a problem</p> -->
                            </mat-icon>
                          </button>
                          <mat-menu
                            #menu1="matMenu"
                            class="mat-menu"
                            xPosition="after"
                          >
                            <button
                              mat-menu-item
                              class="mat-item-span"
                              *ngIf="!collection.bookmarkSummary"
                              (click)="
                                saveCollection(
                                  collection,
                                  true,
                                  collection._id,
                                  collection.summaryName
                                )
                              "
                            >
                              <mat-icon>
                                <img
                                  src="../../../assets/images/Icon feather-bookmark.svg"
                                  class="mr-3 spam-img"
                                />
                              </mat-icon>
                              <span>Bookmark</span>
                            </button>

                            <button
                              mat-menu-item
                              class="mat-item-span"
                              (click)="link()"
                              cdkCopyToClipboard="{{ url }}/c/{{
                                collection?.summaryName?.split(' ')?.join('-')
                              }}/id-{{ collection._id }}"
                            >
                              <mat-icon>
                                <img
                                  src="../../../assets/images/Icon feather-link-2.svg"
                                  class="mr-3 spam-img"
                                />
                              </mat-icon>
                              <span>Copy link</span>
                            </button>
                            <button mat-menu-item class="mat-item-span">
                              <mat-icon>
                                <img
                                  src="../../../assets/images/not-equal.png"
                                  class="mr-3 spam-img"
                                />
                              </mat-icon>
                              <span>Not interested in this</span>
                            </button>
                            <button
                              mat-menu-item
                              class="mat-item-span"
                              (click)="
                                dislikeSummary(collection, true, collection._id)
                              "
                            >
                              <mat-icon>
                                <img
                                  src="../../../assets/images/not-equal.png"
                                  class="mr-3 spam-img"
                                />
                              </mat-icon>
                              <span>Downvote</span>
                            </button>
                            <ng-container
                              *ngFor="
                                let categories of collection.topics
                                  | fliterCategorty : { name: activeState }
                              "
                            >
                              <button
                                mat-menu-item
                                class="mat-item-span"
                                (click)="
                                  unFollowCategories(
                                    categories,
                                    true,
                                    collection
                                  )
                                "
                                *ngIf="categories.topicFollow"
                              >
                                <mat-icon>
                                  <img
                                    src="../../../assets/images/Icon feather-delete.svg"
                                    class="mr-3 spam-img"
                                  />
                                </mat-icon>
                                <span>Unfollow {{ categories.name }}</span>
                              </button>
                            </ng-container>

                            <button
                              mat-menu-item
                              class="mat-item-span"
                              (click)="UnfollowBtn(collection, true)"
                              *ngIf="collection.followpublisher"
                            >
                              <mat-icon>
                                <img
                                  src="../../../assets/images/sad.png"
                                  class="mr-3 spam-img"
                                />
                              </mat-icon>
                              <span
                                >Unfollow {{ collection.publisherName }}</span
                              >
                            </button>
                            <button mat-menu-item class="mat-item-span" (click)="share(collection)">
                              <mat-icon>
                                <img
                                  src="../../../assets/images/Icon awesome-share-alt.svg"
                                  class="mr-3 spam-img"
                                />
                              </mat-icon>
                              <span>Share with a friend </span>
                            </button>
                            <button mat-menu-item class="mat-item-span">
                              <mat-icon>
                                <img
                                  src="../../../assets/images/Icon awesome-share-alt.svg"
                                  class="mr-3 spam-img"
                                />
                              </mat-icon>
                              <span>Dowload summary words</span>
                            </button>
                            <button
                              mat-menu-item
                              class="mat-item-span"
                              (click)="report(collection)"
                            >
                              <mat-icon>
                                <img
                                  src="../../../assets/images/Icon material-report-problem.svg"
                                  class="mr-3 spam-img"
                                />
                              </mat-icon>
                              <span>Report a problem</span>
                            </button>
                            <button mat-menu-item class="mat-item-span">
                              <mat-icon>
                                <img
                                  src="../../../assets/images/Icon zocial-email.svg"
                                  class="mr-3 spam-img"
                                />
                              </mat-icon>
                              <span>Contact us</span>
                            </button>
                          </mat-menu>
                        </div>
                            </div>

                            <!-- <div class="tweet moblie-tweet mt-2">
                              <a
                                [href]="tweetBtn(collection)"
                                (click)="tweetEvent(collection)"
                                target="_blank"
                                >Tweet this!
                                <img src="../../assets/images/tw.png" />
                              </a>
                            </div> -->
                          </div>
                        </div>
                        <!-- NEW-COMMENT-SECTION -->

                        <div class="new_comment_section">
                          <div class="d-flex">
                            <div class="comment_content">
                              <p>
                                This summary has been generated by AI from the
                                <a
                                  class="text-decoration-underline"
                                  target="_blank"
                                  [href]="collection.video"
                                  >Youtube video</a
                                >
                              </p>
                              <h4>
                                Comment this Summary <a>link </a> in the Youtube
                                video and help others in youtube community read
                                this summary
                              </h4>
                            </div>
                            <div
                              class="Comment_img ml-auto cursor"
                              (click)="writeComment(collection)"
                            >
                              <img src="../../assets/images/comment.png" />
                              <p>Write Comment</p>
                            </div>
                          </div>
                        </div>
                        <!-- NEW-COMMENT-SECTION -->
                        <!-- /* comments section */ -->
                        <div
                          class="comments-section pl-2 pb-4 mt-4 pt-3"
                          *ngIf="collection.showCollectionComment"
                        >
                          <h5 class="total_comments">
                            {{ totalComments }} Comments
                          </h5>
                          <div class="comment-header">
                            <div class="d-flex">
                              <div class="comment-img">
                                <img
                                  *ngIf="
                                    collection.thumbNail != '' &&
                                    collection.customThumbNail == null
                                  "
                                  src="{{ collection.thumbNail }}"
                                  class="c-img-1"
                                  loading="lazy"
                                  alt="{{ collection.summaryName }}"
                                />
                                <img
                                  *ngIf="
                                    collection.customThumbNail != '' &&
                                    collection.thumbNail == null
                                  "
                                  src="{{ collection.customThumbNail }}"
                                  class="c-img-1"
                                  loading="lazy"
                                  alt="customThumbnNail"
                                />
                              </div>
                              <div class="comment-input-field ml-md-3">
                                <form [formGroup]="commentForm">
                                  <input
                                    [readOnly]="readonly"
                                    (keyup)="nameValidations($event, 'title')"
                                    class="comment-form-input"
                                    [class.inputActive]="inputActive == true"
                                    formControlName="title"
                                    (focus)="toggleButtons()"
                                    (click)="commentEvent()"
                                  />
                                  <div
                                    *ngIf="
                                      commentFormError.title.errors?.maxlength
                                    "
                                    class="invalid-feedback m-t d-block"
                                  >
                                    Only 1000 characters are allowed
                                  </div>
                                  <div
                                    class="d-flex mt-3 justify-content-md-end comment-add_form"
                                    *ngIf="showButtons"
                                  >
                                    <button
                                      class="cancel-comment mr-md-3 cursor"
                                      (click)="cancelComment()"
                                    >
                                      Cancel
                                    </button>
                                    <button
                                      class="comment-form-button cursor"
                                      [disabled]="commentForm.invalid"
                                      type="submit"
                                      (click)="onSubmitComment(collection)"
                                    >
                                      Comment
                                    </button>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                          <app-loader
                            [commentsLoader]="commentsLoaderPostion"
                            *ngIf="showCommentLoader"
                          ></app-loader>
                          <div
                            class="comments-user-added mt-4"
                            *ngFor="let comments of commentUser"
                          >
                            <div class="d-flex">
                              <div class="comment-user-img">
                                <img
                                  *ngIf="comments.reader[0].profile == null"
                                  src="../../../assets/images/pr-img.jpeg"
                                />
                                <img
                                  *ngIf="comments.reader[0].profile != null"
                                  src="{{ comments.reader[0].profile }}"
                                />
                              </div>
                              <div class="user-comments-data ml-md-3">
                                <div class="d-flex">
                                  <h4>@{{ comments.reader[0].name }}</h4>
                                  <p class="ml-2">
                                    {{ comments.createdAt | datepipe }}
                                  </p>
                                </div>
                                <h6>{{ comments.comment }}</h6>

                                <!-- EDIT-COMMENT-FORM-SECTION -->
                                <div
                                  class="comment-input-field ml-md-3"
                                  *ngIf="comments.isEdit"
                                >
                                  <form [formGroup]="editCommentForm">
                                    <input
                                      [readOnly]="readonly"
                                      (keyup)="nameValidations($event, 'edit')"
                                      class="comment-form-input"
                                      [class.inputActive]="editActive == true"
                                      formControlName="edit"
                                      (focus)="toggleEditButtons()"
                                      (click)="commentEvent()"
                                    />
                                    <div
                                      class="d-flex mt-3 justify-content-end"
                                      *ngIf="showEditButtons"
                                    >
                                      <button
                                        class="cancel-comment mr-md-3 cursor"
                                        (click)="cancelEditComment(comments)"
                                      >
                                        Cancel
                                      </button>
                                      <button
                                        class="comment-form-button cursor"
                                        [disabled]="editCommentForm.invalid"
                                        type="submit"
                                        (click)="saveEditForm(comments)"
                                      >
                                        Save
                                      </button>
                                    </div>
                                  </form>
                                </div>
                                <!-- EDIT-COMMENT-FORM-SECTION -->
                                <div class="d-flex">
                                  <a
                                    class="reply mr-md-3 cursor mr-2"
                                    (click)="replyShowInput(comments)"
                                  >
                                    <h3>Reply</h3>
                                  </a>
                                  <a
                                    class="reply cursor mr-md-3 mr-2"
                                    *ngIf="comments.reader[0]._id == userTypeId"
                                    (click)="editMainComment(comments)"
                                  >
                                    <h3>Edit</h3>
                                  </a>
                                  <a
                                    class="reply cursor"
                                    (click)="deletComment(comments)"
                                    *ngIf="comments.reader[0]._id == userTypeId"
                                  >
                                    <h3>Delete</h3>
                                  </a>
                                </div>
                              </div>
                              <div class="ml-md-auto mat-menu-collection">
                                <button
                                  mat-icon-button
                                  [matMenuTriggerFor]="menu"
                                  aria-label="Example icon-button with a menu"
                                >
                                  <mat-icon>more_vert</mat-icon>
                                </button>
                                <mat-menu
                                  #menu="matMenu"
                                  class="mat-menu"
                                  yPosition="above"
                                >
                                  <button
                                    mat-menu-item
                                    class="mat-item-span"
                                    (click)="spamComment(comments, collection)"
                                  >
                                    <mat-icon
                                      ><img
                                        src="../../../assets/images/red-flag.png"
                                        class="mr-3 spam-img"
                                    /></mat-icon>
                                    <span>Mark as Spam</span>
                                  </button>
                                  <button
                                    mat-menu-item
                                    class="mat-item-span"
                                    (click)="
                                      spamOffensive(comments, collection)
                                    "
                                  >
                                    <mat-icon
                                      ><img
                                        src="../../../assets/images/sad.png"
                                        class="mr-3 spam-img"
                                    /></mat-icon>
                                    <span>Mark as Offensive</span>
                                  </button>
                                  <button
                                    mat-menu-item
                                    class="mat-item-span"
                                    *ngIf="
                                      collection.reader[0]._id == userTypeId
                                    "
                                    (click)="
                                      deleteCommentPublisherandPowerUser(
                                        comments
                                      )
                                    "
                                  >
                                    <mat-icon
                                      ><img
                                        src="../../../assets/images/de.png"
                                        class="mr-3 spam-img"
                                    /></mat-icon>
                                    <span>Delete Comment</span>
                                  </button>
                                </mat-menu>
                              </div>

                              <!-- <div class="comment-spam-img d-flex ml-md-auto">
                    <a (click)="spamComment(comments)"><img  src="../../../assets/images/spam (1).png" class="mr-3"></a>
                    <a (click)="spamOffensive(comments)"><img  src="../../../assets/images/report (1).png"></a>
                  </div> -->
                            </div>
                            <div
                              class="comment-header pl-md-3 mt-md-3"
                              *ngIf="comments.showReplyForm"
                            >
                              <div class="d-flex">
                                <div class="comment-img">
                                  <img
                                    src="../../../assets/images/user-1.png"
                                    width="50"
                                  />
                                </div>
                                <div class="comment-input-field ml-md-3">
                                  <form [formGroup]="replyCommentForm">
                                    <input
                                      class="comment-form-input"
                                      (keyup)="nameValidations($event, 'reply')"
                                      [class.inputActive]="inputActive == true"
                                      formControlName="reply"
                                      (focus)="toggleButtonsReply()"
                                      (input)="commentEventReply($event)"
                                    />
                                    <div
                                      *ngIf="
                                        replyCommentFormError.reply.errors
                                          ?.maxlength
                                      "
                                      class="invalid-feedback m-t d-block"
                                    >
                                      Only 1000 characters are allowed
                                    </div>
                                    <div
                                      class="d-flex mt-3 justify-content-end"
                                      *ngIf="showButtonsReply"
                                    >
                                      <button
                                        class="cancel-comment mr-md-3 cursor"
                                        (click)="cancelCommentReply(comments)"
                                      >
                                        Cancel
                                      </button>
                                      <button
                                        class="cursor comment-form-button"
                                        [disabled]="replyCommentForm.invalid"
                                        type="submit"
                                        (click)="onReplyComment()"
                                      >
                                        Reply
                                      </button>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                            <a
                              class="ml-5 pl-md-3 pl-2 replycount"
                              *ngIf="comments.commentReply.length != 0"
                              (click)="showReplyBtn(comments)"
                              >{{ comments.commentReply.length }} reply</a
                            >
                            <div *ngIf="comments.showReplys">
                              <div
                                class="comments-user-added mt-md-4 mt-3 ml-md-5"
                                *ngFor="let reply of comments.commentReply"
                              >
                                <div class="d-flex">
                                  <div class="comment-user-img">
                                    <img
                                      *ngIf="reply.profile == null"
                                      src="../../../assets/images/pr-img.jpeg"
                                    />
                                    <img
                                      *ngIf="reply.profile != null"
                                      src="{{ reply.profile }}"
                                    />
                                  </div>
                                  <div class="user-comments-data ml-md-3">
                                    <div class="d-flex">
                                      <h4>@{{ reply.name }}</h4>
                                      <p class="ml-2">
                                        {{ reply.createdAt | datepipe }}
                                      </p>
                                    </div>
                                    <h6>{{ reply.comment }}</h6>
                                    <!-- EDIT-COMMENT-FORM-SECTION -->
                                    <div
                                      class="comment-input-field ml-md-3"
                                      *ngIf="reply.isEdit"
                                    >
                                      <form [formGroup]="replyEditCommentForm">
                                        <input
                                          [readOnly]="readonly"
                                          (keyup)="
                                            nameValidations($event, 'replyEdit')
                                          "
                                          class="comment-form-input"
                                          [class.inputActive]="
                                            editActive == true
                                          "
                                          formControlName="replyEdit"
                                          (focus)="toggleReplyEditButtons()"
                                          (click)="commentEvent()"
                                        />
                                        <div
                                          class="d-flex mt-3 justify-content-end"
                                          *ngIf="showReplyEditButtons"
                                        >
                                          <button
                                            class="cancel-comment mr-md-3 cursor"
                                            (click)="cancelEditComment(reply)"
                                          >
                                            Cancel
                                          </button>
                                          <button
                                            class="comment-form-button cursor"
                                            [disabled]="
                                              replyEditCommentForm.invalid
                                            "
                                            type="submit"
                                            (click)="
                                              saveReplyEditForm(comments)
                                            "
                                          >
                                            Save
                                          </button>
                                        </div>
                                      </form>
                                    </div>
                                    <!-- EDIT-COMMENT-FORM-SECTION -->
                                    <div class="d-flex">
                                      <a
                                        class="reply cursor mr-3"
                                        *ngIf="reply.readerId == userTypeId"
                                        (click)="editReplyComment(reply)"
                                      >
                                        <h3>Edit</h3>
                                      </a>
                                      <a
                                        class="reply cursor"
                                        (click)="
                                          deletReplyComment(comments, reply._id)
                                        "
                                        *ngIf="reply.readerId == userTypeId"
                                      >
                                        <h3>Delete</h3>
                                      </a>
                                    </div>
                                  </div>
                                  <div class="ml-md-auto mat-menu-collection">
                                    <button
                                      mat-icon-button
                                      [matMenuTriggerFor]="menu"
                                      aria-label="Example icon-button with a menu"
                                    >
                                      <mat-icon>more_vert</mat-icon>
                                    </button>
                                    <mat-menu
                                      #menu="matMenu"
                                      class="mat-menu"
                                      yPosition="above"
                                    >
                                      <button
                                        mat-menu-item
                                        class="mat-item-span"
                                        (click)="
                                          spamReplyComment(
                                            comments,
                                            reply._id,
                                            collection
                                          )
                                        "
                                      >
                                        <mat-icon
                                          ><img
                                            src="../../../assets/images/red-flag.png"
                                            class="mr-3 spam-img"
                                        /></mat-icon>
                                        <span>Mark as Spam</span>
                                      </button>
                                      <button
                                        mat-menu-item
                                        class="mat-item-span"
                                        (click)="
                                          spamReplyOffensive(
                                            comments,
                                            reply._id,
                                            collection
                                          )
                                        "
                                      >
                                        <mat-icon
                                          ><img
                                            src="../../../assets/images/sad.png"
                                            class="mr-3 spam-img"
                                        /></mat-icon>
                                        <span>Mark as Offensive</span>
                                      </button>
                                      <button
                                        mat-menu-item
                                        class="mat-item-span"
                                        (click)="
                                          deleteReplyCommentPublisherandPowerUser(
                                            comments,
                                            reply._id
                                          )
                                        "
                                        *ngIf="
                                          collection.reader[0]._id == userTypeId
                                        "
                                      >
                                        <mat-icon
                                          ><img
                                            src="../../../assets/images/de.png"
                                            class="mr-3 spam-img"
                                        /></mat-icon>
                                        <span>Delete Comment</span>
                                      </button>
                                    </mat-menu>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- /* comments section */ -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </mat-tab>
          </div>
        </div>
      </mat-tab-group>
      <div class="nav-header"></div>
    </div>
  </div>
  <app-channel-footer *ngIf="footerShow"></app-channel-footer>
</div>
