import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { CollectionService } from '../_services/collection.service';
import { SummaryService } from '../_services/summary.service';
@Component({
  selector: 'app-createchannel',
  templateUrl: './createchannel.component.html',
  styleUrls: ['./createchannel.component.css'],
})
export class CreatechannelComponent implements OnInit {
  catergoryList: any = [];
  catergory: string;
  submiited: boolean = false;
  succeed: boolean = false;
  catergoriesFormGroup: FormGroup;
  subCatergory: any;
  msg: string;
  url: any;
  selectedFile: File;
  updateProfileForm: any;
  selectedFileEvent: File;
  fileNameEvent: any;
  categoryList: any;
  Cateogory = new FormControl('');
  multipleCatergory: any;
  country: any = ['India', 'US', 'Canada', 'London', 'New york', 'Australia'];
  countryEvent: any;
  fileName: any;
  uploadFlie: boolean = true;
  attachmentFile: boolean = false;
  uploadBtn: boolean = false;
  csvsucceed: boolean = false;
  userID: any;

  constructor(
    private collectionServices: CollectionService,
    private summaryService: SummaryService,
    private fb: FormBuilder
  ) {
    this.catergoriesFormGroup = this.fb.group({
      name: ['', [Validators.required, Validators.pattern(/^@.+/)]],
      url: [
        '',
        [
          Validators.required,
          Validators.pattern('^(https?://)?(www.youtube.com|youtu.be)/.+$'),
        ],
      ],
      ownerName: [''],
      category: ['', [Validators.required]],
      followersCount: [''],
      description: [''],
      profile: ['', [Validators.required]],
      country: [''],
      title: [''],
    });
  }

  ngOnInit(): void {
    this.collectionServices.caterogoryList().subscribe((res) => {
      console.log(res);
      this.categoryList = res.data;
    });
  }
  channelName = 'tseries';
  getData() {}
  categoryEvent(e: any) {
    console.log(e.value);
    this.multipleCatergory = e.value;
  }

  get c(): { [key: string]: AbstractControl } {
    return this.catergoriesFormGroup.controls;
  }
  onFileSelected(event: any): void {
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);

    reader.onload = (_event) => {
      this.msg = '';
      this.url = reader.result as string;

      this.selectedFile = <File>event.target.files[0];
    };
    this.selectedFileEvent = <File>event.target.files[0];
    this.fileNameEvent = event.target.files[0].name;
  }
  Country(event: any) {
    console.log(event.value);
    this.countryEvent = event.value;
  }

  onFileSelectedTvProgram(event: any): void {
    this.selectedFile = <File>event.target.files[0];
    this.fileName = event.target.files[0].name;
    if (event.target.files[0].name != null) {
      this.uploadFlie = false;
      this.attachmentFile = true;
      this.uploadBtn = true;
    }
  }
  uploadCsv() {
    const formData = new FormData();
    formData.append('file', this.selectedFile, this.fileName);
    this.collectionServices.uploadCsv(formData).subscribe(
      (data) => {
        console.log(data);
        this.csvsucceed = true;
        this.uploadFlie = true;
        this.attachmentFile = false;
        this.uploadBtn = false;
        setTimeout(() => {
          this.csvsucceed = false;
        }, 3000);
      },
      (error) => {
        console.log(error);
      }
    );
  }
  submit() {
    console.log(this.catergoriesFormGroup);
    if (this.catergoriesFormGroup.invalid) {
      this.submiited = true;
    } else {
      this.submiited = false;
      const formData = new FormData();
      formData.append('profile', this.selectedFileEvent, this.fileNameEvent);
      formData.append('topic', JSON.stringify(this.multipleCatergory));
      // formData.append('ownerName', this.catergoriesFormGroup.value.ownerName);
      formData.append('url', this.catergoriesFormGroup.value.url);
      formData.append('title', this.catergoriesFormGroup.value.title);
      formData.append('name', this.catergoriesFormGroup.value.name);
      formData.append(
        'description',
        this.catergoriesFormGroup.value.description
      );
      formData.append('country', this.catergoriesFormGroup.value.country);
      this.collectionServices.addChannelName(formData).subscribe((result) => {
        console.log(result);
        console.log('success');
        this.succeed = true;

        setTimeout(() => {
          this.succeed = false;
        }, 3000);
        this.url = null;
        this.catergoriesFormGroup.reset();
      });
    }
  }
}
