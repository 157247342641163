import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AdminService } from 'src/app/_services/admin.service';
import { saveAs } from 'file-saver';
import Swal from 'sweetalert2';
export interface PeriodicElement {
  sid: any;
  id: any;
  URL: any;
  Thumbali: any;
  summarytitle: any;
  Publishedby: any;
  channel: any;
  views: any;
  clicks: any;
}

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css'],
})
export class ReportsComponent implements OnInit {
  displayedColumns: string[] = [
    'sid',
    'id',
    'URL',
    'Thumbali',
    'Publishedby',
    'summarytitle',
    'channel',
    'views',
    'clicks',
  ];

  totalSummary: any;
  dataCount: any;
  published: any;
  mostTrending: any;
  items: any[];
  queryParams: any;
  language: any = '';
  date: any = '';
  inactive: any;
  active: any;
  activeParam: { isActive: any };
  statusChangeEvent: any = true;
  loader: boolean = false;
  userQuery: {};
  collectionDelete: boolean;
  exportLanguage: string;
  languageChange: any;
  report: any = '';
  closedCount: any;
  totalCount: any;
  invalidCount: any;
  openCount: any;
  obj: { action: string };
  // dataSource = ELEMENT_DATA;
  constructor(private adminService: AdminService, private router: Router) {}
  status = 'Off';
  ELEMENT_DATA: Element[] = [];
  pageSize: any = 10;
  currentPage: any = 0;
  unchecked: boolean = false;
  checked: boolean = true;
  pageSizeOptions: number[] = [10, 20, 40, 100];
  dataSource = new MatTableDataSource(this.ELEMENT_DATA);
  reportsError: any = [
    'Inaccurate',
    'Low quality',
    'Offensive',
    'Translation error',
  ];
  ngOnInit(): void {
    this.summariesApiList();
  }

  summariesApiList() {
    this.loader = true;
    this.queryParams = {
      report_type: this.report,
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };
    this.adminService.reportsList(this.queryParams).subscribe((data) => {
      console.log('data', data);
      this.loader = false;

      this.ELEMENT_DATA = data.reports;
      this.closedCount = data.closedCount;
      this.totalCount = data.totalCount;
      this.openCount = data.openCount;
      this.invalidCount = data.invalidCount;

      this.items = [
        { name: 'Reports', number: this.totalCount },
        { name: 'Open', number: this.openCount },
        { name: 'Closed', number: this.closedCount },
        { name: 'Invalid', number: this.invalidCount },
      ];

      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    });
  }

  View(blogName: any, id: any) {
    return `/c/${blogName.split(' ').join('-')}/id-${id}`;
  }

  changeFirstLetterToLower(str: string): string {
    return str.charAt(0).toLowerCase() + str.slice(1);
  }

  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;

    this.currentPage = event.pageIndex;
    let newCurrentPage = event.pageIndex + 1;
    this.queryParams = {
      report_type: this.report,

      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: newCurrentPage ? newCurrentPage : '',
    };
    this.adminService.adminSummariesList(this.queryParams).subscribe((data) => {
      console.log('data', data);

      this.ELEMENT_DATA = data.reports;
      this.closedCount = data.closedCount;
      this.totalCount = data.totalCount;
      this.openCount = data.openCount;
      this.invalidCount = data.invalidCount;

      this.items = [
        { name: 'Reports', number: this.totalCount },
        { name: 'Open', number: this.openCount },
        { name: 'Closed', number: this.closedCount },
        { name: 'Invalid', number: this.invalidCount },
      ];

      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    });
  }
  activetoggleChanged(e: any, item: any) {
    console.log('activetoggle', e, item);
    // return;
    if (e == 'open') {
      this.obj = {
        action: 'Open',
      };
    } else if (e == 'closed') {
      this.obj = {
        action: 'Close',
      };
    } else if (e == 'invalid') {
      this.obj = {
        action: 'Invalid',
      };
    }

    this.adminService.reportStatus(this.obj, item._id).subscribe((res) => {
      this.summariesApiList();
      Swal.fire({
        title: 'status updated successfully',
        icon: 'success', // You can change the icon type
        timer: 2000, // Set the timer to 3000 milliseconds (3 seconds)
        showConfirmButton: false, // Hide the "OK" button
      });
    });
  }
  inActivetoggleChanged(e: any, item: any) {
    this.activeParam = {
      isActive: true,
    };

    this.adminService
      .activeInactiveCollection(this.activeParam, item._id)
      .subscribe((res) => {
        item.isActive = true;
      });
  }

  deleteCollection(id: any) {
    this.adminService.deleteCollection(id).subscribe((res) => {
      console.log('delete ', res);
      this.summariesApiList();

      this.collectionDelete = true;
      setTimeout(() => {
        this.collectionDelete = false;
      }, 3000);
    });
  }

  statusChange(e: any) {
    this.statusChangeEvent = e.target.value;
    this.summariesApiList();
  }
  reportChange(e: any) {
    this.report = e.target.value;
    this.summariesApiList();
  }
  toggle(e: any) {
    console.log(e.target.checked);
  }
}
