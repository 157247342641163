<app-navbar *ngIf="sessionType == 'powerUser'"></app-navbar>
<app-usernavbar *ngIf="sessionType == 'user'"></app-usernavbar>
<app-homenavbar *ngIf="sessionType == 'home'"></app-homenavbar>
<app-creatornavbar *ngIf="sessionType == 'publisher'"></app-creatornavbar>


<div *ngIf="isBrowser">

<div class="container-fluid">
  <div class="product_details  mt-3 ml-md-3">

    <div class="row">
      <div class="col-lg-9 col">
<p><a routerLink="/feed">Go Back</a></p>

    <div class="row mt-3" *ngFor="let details of productDetails">
      <div class="col-md-4 details_card">
        <div class="card">
          <div class="card-body">
            <div class="Details_view">
              <div class="d-flex">
                <div class="pin-it mb-2">
                  <button type="button" class="pointer">
                    <a
                      data-pin-do="buttonPin"
                      data-pin-count="beside"
                      data-pin-custom="true"
                      data-pin-save="false"
                      data-pin-description="Pinterest specific description here"
                      target="_blank"
                      href="https://www.pinterest.com/pin/create/button/?url={{
                        shareLink
                      }}/&media={{ ProductImage }}&description=So%20delicious!"
                      data-pin-custom="true"
                    >
                      <img
                        src="../../../../assets/images/pinterest (2).png"
                        class="pointer pin-img"
                        alt="pinterest"
                      /><span class="save ml-2">Pin it</span>
                    </a>
                  </button>
                </div>
                <!-- <div class="link_it ml-auto">
                                    <button type="button">Link.it</button>
                                 </div> -->
              </div>
            </div>

            <img *ngIf="details.image != null" src="{{ details.image }}" class="d-1-img" />
            <img *ngIf="details.productImage != ''" src="{{ details?.productImage[0]?.url }}" class="d-1-img" />
          </div>
        </div>


        <div *ngIf="details.collection_Details != ''">
        <p class="c-name mt-md-2 pointer">
          This product featured on the following collection 
        </p>
         <span class="collection-name-link"
            ><a routerLink="/c/{{details?.collection_Details[0]?.collectionName?.split(' ')?.join('-')}}/id-{{details?.collection_Details[0]?._id}}">{{details?.collection_Details[0]?.collectionName}}</a></span
          >
      </div>
        <div *ngIf="details.collection_Details == ''">
        <p class="c-name mt-md-2 pointer">
         This product was curated by the following youtube creator/<a style="color:#0000ff;"class="collection-name-link" routerLink="/{{details.channelName}}">{{details.channelName}}</a>
          <!-- <span
            ><a (click)="channel(details.channelName, details._id)">{{
              details.channelName | slice : 0 : 30
            }}</a></span
          > -->
         
        </p>
        
      </div>
      </div>
      
       


      <div class="col-md-8">
        <div class="product_details_Paragraph">
          <h4>{{ details.productTitle }}</h4>
          <p>{{ details.description }}</p>
          <div class="caterogy scroll d-flex mt-md-2">
            <h2 *ngFor="let list of details.categories" class="ml-3 mt-3">
              {{ list.name }}
            </h2>
          </div>

           

          <div class="d-md-flex ">
            <div class="creator-code" *ngIf="details.couponCode != ''">
              <p class=" mt-lg-1 mb-lg-1 mt-md-3 mb-md-3">Creator discount code available</p>
              <div class="d-flex code-d">
                <div class="code-p">
                  <p
                    *ngIf="this.service.getToken() != ''"
                    [(ngModel)]="details.couponCode"
                    ngDefaultControl
                  >
                    {{ details.couponCode }}
                  </p>
                  <p *ngIf="this.service.getToken() == ''">*******</p>
                </div>
                <div class="copy-btn ml-auto">
                  <button
                    [cdkCopyToClipboard]="details.couponCode"
                    [matTooltipDisabled]="true"
                    #myTooltip="matTooltip"
                    matTooltip="Coupon copied"
                    matTooltipPosition="above"
                    (click)="displayTooltip(details._id,details.productTitle)"
                    type="button"
                    class="ml-auto"
                  >
                    show
                  </button>
                </div>
                 
              </div>
            </div>

<!-- webview -->
            <div class="but_now_Section ml-auto mt-md-4 webView">
              <p>
                Click the link below to purchase this product at 
                {{ details.affiliatePartnerName.toLowerCase() ? details.affiliatePartnerName.toLowerCase() : 'external website' }}
              </p>
              <!-- <a target="_black" class="buy_now" >Buy now</a> -->
              <a target="_black" href="{{ details.AffiliateLink }}">{{ details.AffiliateLink | slice:0:25 }}</a>

               <div class="but_now_Section ml-auto mt-md-2 webView">
              <p>
               This product link was provided by the youtube creator
              </p>
              <!-- <a target="_black" href="{{ details.AffiliateLink }}">{{ details.AffiliateLink | slice:0:25 }}</a> -->
            </div>
            </div>
           
          </div>
<!-- webview -->

          <div class="d-flex">
 
          
            <div class="">
              <div>
                <button
                  type="submit"
                  class="book-btn"
                  (click)="saveProductBookmark(details._id,details.productTitle)"
                >
                  <img
                    src="../../../../assets/images/bookmark.png"
                    class="book"
                  />
                  Bookmark product
                </button>
                <p *ngIf="saved" class="succesfully mt-1">
                  Product Bookmarked successfully
                </p>
              </div>
            </div>
          </div>
         <!-- moblie-view-buy-now -->
           
             <div class="but_now_Section mx-5 moblie-view-buy-now">
              <p>
                Click the link below to purchase this product at 
                {{ details.affiliatePartnerName.toLowerCase() ? details.affiliatePartnerName.toLowerCase() : 'external website' }}
              </p>
              <!-- <a target="_black" class="buy_now" >Buy now</a> -->
              <a target="_black" href="{{ details.AffiliateLink }}">{{ details.AffiliateLink | slice:0:25 }}</a>

               <div class="but_now_Section ml-auto mt-md-2 ">
              <p>
               This product link was provided by the youtube creator
              </p>
              <!-- <a target="_black" href="{{ details.AffiliateLink }}">{{ details.AffiliateLink | slice:0:25 }}</a> -->
            </div>
            </div>
         <!-- moblie-view-buy-now -->

          <p *ngIf="discountError != null" class="mt-md-3 code-d-c">
            {{ discountError | titlecase }}
          </p>
          <p *ngIf="productError != null" class="mt-md-3 code-d-c">
            {{ productError | titlecase }}
          </p>

         
           <div class="share_now mt-5 pt-2 moblie-view-c">
          <p class="pb-2">Share this product with Friends & help them discover this product</p>
          <div class="shareItBtn_Section d-lg-flex justify-content-center">
            <h6 id="realated-collection">{{shareLink | slice:0:60}}</h6>
            <button id="product-related-moblie" type="button" class="share">{{shareLink | slice:0:44}}</button>
            <!-- <h6 ></h6> -->
            <button type="button" class="pointer" (click)="shareIt()">Share it</button>
          </div>
        </div>
          <!-- <div class="sharethis-inline-share-buttons"></div> -->
        </div>
      </div>
    </div>
  </div>
      </div>
    </div>
    

  <div class="list-of-products mt-md-5 ml-md-3 pt-md-3">
    <h3 class="py-4">
      Other products  and collections
    </h3>
   <div class="row">
      <div class="col-md-4" *ngFor="let cardDetails of realatedProduct">
         
           
              <div class="card mb-md-4" >
                <div class="card-body pointer">
                  <div class="product_list">
                    <!-- <div class="d-flex list-P"></div> -->
                    <img *ngIf="cardDetails?.image == null && cardDetails?.productImage == ''" (click)="showRealtedproductsBtn(cardDetails.productTitle,cardDetails._id)" src="../../../assets/images/n-image.png" class="code-1-img image">
                    <img *ngIf="cardDetails?.image != null " (click)="showRealtedproductsBtn(cardDetails.productTitle,cardDetails._id)" src="{{cardDetails?.image}}" class="code-1-img image">
                    <img *ngIf="cardDetails?.productImage != ''" (click)="showRealtedproductsBtn(cardDetails.productTitle,cardDetails._id)" src="{{cardDetails?.productImage[0]?.url}}" class="code-1-img">
                    <div class="d-flex">
                      <div class="mr-auto">
                        <p class="header-p pt-2">{{cardDetails.productTitle | slice:0:24}}</p>
                      </div>
                       
                    </div>
                      <div class="d-flex">
                         <div class="mr-auto collection-name-link ml-3">
                          <a routerLink="/p/{{cardDetails.productTitle.split(' ').join('-')}}/id-{{cardDetails._id}}">
                            <h5>View Product</h5>
                          </a>
                        </div>
                         <div class="save-for-later d-flex  mr-3">
                        <div>
                          <img src="../../../assets/images/heart.svg">
                        </div>
                       <div>
                          <a (click)="  saveForlaterProducts(cardDetails._id,cardDetails.productTitle)">
                            <h5>Bookmark</h5>
                           
                          </a>

                           
                        </div>
                      </div>
                          
                      </div>
                      
                  </div>
                  
                  <div class="product_list_details">
                    <div class="d-flex">
                       
                    </div>
                    <div class="details-List-view" *ngIf="cardDetails.couponCode != ''">
                      <p>Creator Discount Code Available</p>
                      <div class="creator-code-1">
                        <div class="d-flex code-d">
                          <div class="code-p">
                            <!-- <p>{{cardDetails.couponCode}}</p> -->
                            <p>******</p>
                          </div>
                          <div class="copy-btn ml-auto">
                            <button type="button" class="ml-auto">Show</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <h5 class="mt-md-2 pointer">Share this product with friends</h5> -->
                  </div>
                </div>
              </div>
            <!-- <p *ngIf="saved"  class="succesfully-p mt-1">
                  Product Bookmarked successfully
                </p>
                 <p *ngIf="productError != null" class="mt-md-3 code-d-c">
            {{ productError }}
          </p> -->
          </div>
      
      </div>
    </div>  
  </div>

  <div class="collection-data">
    <div class="row">
 <div class="col-md-4" *ngFor="let list of collectionList">
        <div class="collection-prodcuts pointer mx-md-3">
          <a href="/c/{{list?.collectionName?.split(' ')?.join('-')}}/id-{{list._id}}"> 
          <!-- <img src="{{
                              'https://img.youtube.com/vi/' +
                                list?.sourceURL?.split('=')?.pop() +
                                '/hqdefault.jpg'
                            }}" (click)="collection(list.collectionName,list._id)"> -->
          <img src="{{list.thumbNail}}">
          <P (click)="collection(list.collectionName,list._id)" class="pt-md-2">{{list.collectionName | slice:0:55}}</P>

                            </a>
        
        </div>
        
                     <div class="view-now pointer">
                         <button type="button" (click)="collection(list.collectionName,list._id)" class="pointer">View Now</button>
                     </div>
      </div>
    </div>
    
  </div>
<app-footer></app-footer>
   
</div>

