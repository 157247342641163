import { Component, Inject, OnInit } from '@angular/core';
import { MatDateFormats } from '@angular/material/core';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SignupComponent } from '../login-page/signup/signup.component';
import { LoginComponent } from '../login-page/login/login.component';

@Component({
  selector: 'app-no-auth-signup',
  templateUrl: './no-auth-signup.component.html',
  styleUrls: ['./no-auth-signup.component.css'],
})
export class NoAuthSignupComponent implements OnInit {
  url: any;

  title: any;
  collectionImage: any;
  link: any;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dilaog: MatDialog,
    private dialogRef: MatDialogRef<NoAuthSignupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    // this.data = data;
    console.log(this.data.title);
    this.title = this.data.title;
    this.collectionImage = this.data?.image;
    this.link = this.data.ShareLink;
    localStorage.setItem('shareLink', this.link);
    console.log(this.link);
    // console.log(this.collectionImage);
    this.url = `${environment.USER}user/google?url=${this.link}`;
    console.log('redirection url', this.url);
  }

  signup() {
    // this.router.navigate(['user/Signup']);
    this.dialogRef.close();
    this.dilaog.open(SignupComponent, {
      data: {
        disableClose: true,
      },
    });
  }
  login() {
    this.dialogRef.close();

    this.dilaog.open(LoginComponent, {
      disableClose: true,
      data: {},
    });
  }
  close() {
    this.dilaog.closeAll();
    localStorage.removeItem('shareLink');
  }
}
