import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CollectionService } from 'src/app/_services/collection.service';
import { LoginService } from 'src/app/_services/login.service';
import { PublisherCommentsComponent } from 'src/app/publisher-portal/publisher-comments/publisher-comments.component';
import { environment } from 'src/environments/environment';
import { PublisherSignupModalComponent } from './publisher-signup-modal/publisher-signup-modal.component';
var emailPhonePattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$';

@Component({
  selector: 'app-creator-signup',
  templateUrl: './creator-signup.component.html',
  styleUrls: ['./creator-signup.component.css'],
})
export class CreatorSignupComponent implements OnInit {
  signUpWithEmail: boolean = true;
  signUpWithName: boolean = false;
  submitted: boolean = false;
  submittedUser: boolean = false;
  signUpEmail: FormGroup;
  SignUpUser: FormGroup;
  userName: any;
  userType: any;
  url: any = `${environment.COLLECTION_URL}publisher/google`;

  userId: any;
  error: any;
  categoryList: any;
  multipleCatergory: any;
  Email: any;
  noWhitespaceValidator(control: FormControl) {
    const isSpace = (control.value || '').match(/\s/g);
    return isSpace ? { whitespace: true } : null;
  }
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private loginService: LoginService,
    private fb: FormBuilder,
    private collectionsservice: CollectionService,
    private dialog: MatDialog
  ) {
    this.signUpEmail = this.fb.group({
      email: [
        '',
        [Validators.required, Validators.email, Validators.pattern(/^\S*$/)],
      ],
    });
    this.SignUpUser = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      phoneNumber: ['', [Validators.required]],
      youTubeChannelId: [''],
      categoryId: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    // this.Email = this.activatedRoute.snapshot.queryParams;
    // console.log(this.Email);
    this.collectionsservice.caterogoryList().subscribe((res) => {
      console.log(res);
      this.categoryList = res.data;
    });
  }

  howItworks() {
    this.dialog.open(PublisherSignupModalComponent, {
      disableClose: true,
      data: {},
    });
  }
  signUp() {
    if (this.signUpEmail.invalid) {
      this.submitted = true;
    } else {
      this.submitted = false;
      localStorage.setItem(
        'signUpEmail',
        this.signUpEmail.value.email.replace(/\s/g, '')
      );
      this.router.navigate(['/publisher_signup/new']);
      // this.signUpWithName = true;
      // this.signUpWithEmail = false;
    }
  }
  loginNow() {
    this.router.navigate(['/publisher_login']);
  }
  categoryEvent(e: any) {
    console.log(e.value);
    this.multipleCatergory = e.value;
  }
  login() {
    if (this.SignUpUser.invalid) {
      this.submittedUser = true;
    } else {
      this.submittedUser = false;
      console.log(this.SignUpUser.value);
      let obj = {
        firstName: this.SignUpUser.value.firstName,
        lastName: this.SignUpUser.value.lastName,
        phoneNumber: this.SignUpUser.value.phoneNumber,
        youTubeChannelId: this.SignUpUser.value.youTubeChannelId,
        categoryId: this.SignUpUser.value.categoryId,
        email: this.signUpEmail.value.email,
        password: this.SignUpUser.value.password,
      };

      console.log(obj);
      this.loginService.CreatorSignUp(obj).subscribe(
        (result) => {
          console.log(result);
          localStorage.setItem('token', result.token);
          this.router.navigate(['/feed']);
          this.collectionsservice.loginUserDetails().subscribe((result) => {
            this.userType = result.data.userType;
            // localStorage.setItem('userName', this.userName);
            localStorage.setItem('userType', this.userType);
            localStorage.setItem('ID', this.userId);
          });
        },
        (error) => {
          this.error = error.error.message;
          setTimeout(() => {
            this.error = '';
          }, 3000);
        }
      );
    }
  }
  get signUpEmailError(): { [key: string]: AbstractControl } {
    return this.signUpEmail.controls;
  }
  get signUpNameError(): { [key: string]: AbstractControl } {
    return this.SignUpUser.controls;
  }
  emailValidations(e: any) {
    console.log(e.charCode);
    if (e.charCode === 32) {
      e.preventDefault();
    }
  }
}
export function emailWithWhitespaceValidator(control: FormControl) {
  const isValid = control.value
    .trimEnd()
    .match('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$');
  return isValid
    ? null
    : {
        isValid: false,
      };
}
