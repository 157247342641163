import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { AdminService } from 'src/app/_services/admin.service';
import Swal from 'sweetalert2';
export interface PeriodicElement {
  sid: any;
  Thumbali: any;
  summarytitle: any;
  Publishedby: any;
  channel: any;
  views: any;
  clicks: any;
  actions: any;
}

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css'],
})
export class UsersComponent implements OnInit {
  displayedColumns: string[] = [
    'sid',
    'Thumbali',
    'summarytitle',
    'Publishedby',
    'channel',
    'views',
    'clicks',
    'actions',
  ];
  Language: any = [
    'English',
    'Chinese',
    'Hindi',
    'Spanish',
    'Arabic',
    'French',
    'Telugu',
    'Russian',
    'German',
    'Tamil',
    'Malayalam',
  ];
  loader: boolean;
  userQuery: {};
  language: any = '';
  ELEMENT_DATA: Element[] = [];
  pageSize: any = 10;
  currentPage: any = 0;
  unchecked: boolean = false;
  checked: boolean = true;
  pageSizeOptions: number[] = [10, 20, 40, 100];
  totalSummary: any;
  newUser: any;
  userDataCount: any;
  items: { name: string; number: number }[];
  dataSource = new MatTableDataSource(this.ELEMENT_DATA);
  userDelete: boolean;
  userData: any;
  constructor(private adminService: AdminService) {}

  ngOnInit(): void {
    this.userApisList();
  }

  userApisList() {
    this.loader = true;

    this.userQuery = {
      language: this.language,
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };

    this.adminService.adminUserList(this.userQuery).subscribe((result) => {
      console.log(result);
      console.log('data', result);
      this.loader = false;

      this.ELEMENT_DATA = result.userData;
      this.userData = result.userData;
      this.totalSummary = result.dataCount;
      this.newUser = result.newUser;
      this.userDataCount = result.userDataCount;
      this.items = [
        { name: 'Total users', number: this.totalSummary },
        { name: 'New Users', number: this.newUser },
        { name: 'Normal Sing up', number: this.userDataCount },
      ];

      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    });
  }

  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;

    this.currentPage = event.pageIndex;
    let newCurrentPage = event.pageIndex + 1;
    console.log(this.currentPage);
    this.loader = true;

    this.userQuery = {
      language: this.language,
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: newCurrentPage ? newCurrentPage : '',
    };

    this.adminService.adminUserList(this.userQuery).subscribe((result) => {
      console.log(result);
      console.log('data', result);
      this.loader = false;

      this.ELEMENT_DATA = result.userData;
      this.userData = result.userData;
      this.totalSummary = result.dataCount;
      this.newUser = result.newUser;
      this.userDataCount = result.userDataCount;
      this.items = [
        { name: 'Total users', number: this.totalSummary },
        { name: 'New Users', number: this.newUser },
        { name: 'Normal Sing up', number: this.userDataCount },
      ];

      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    });
  }
  langugeChange(e: any) {
    this.language = e.target.value;
    this.userApisList();
  }
  toggleChanged(e: any) {
    console.log('toggl', e.target.checked);
  }
  deleteUser(id: any) {
    this.adminService.deleteUser(id).subscribe((res) => {
      console.log('delete ', res);
      this.userDelete = true;
      Swal.fire({
        text: 'User delete succefully',
        showConfirmButton: true, // Hide the "OK" button
        icon: 'success', // You can change the icon type
        timer: 2000, // Set the timer to 3000 milliseconds (3 seconds)
      });
      this.userApisList();
      setTimeout(() => {
        this.userDelete = false;
      }, 3000);
    });
  }
}
