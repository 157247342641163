 


<div class="sidenavbar-heade">
<!-- <div class='row'>
    <div class="col-md-2">
    <app-sidenavbar></app-sidenavbar>

    </div> -->

    <app-loader *ngIf="loader"></app-loader>
    <div class="summary-header ml-3 mt-md-3">
       
     <div class="d-flex justify-content-end">
          
        
        <div class="month d-flex   language-header">
           <p>Fliter by</p>
            <select (change)="reportChange($event)"  class="selectpicker">
                <option value="">select</option>
                <option *ngFor="let report of reportsError" value="{{report}}">{{report}}</option>
                 
            </select>
        </div>
     </div>

     <div class="card-section my-md-4 d-flex justify-content-between">
        <div class="card mr-md-2" *ngFor="let item of items">
            <div class="card-body">
                <p>{{item.name}}</p>
                <h3>{{item.number}}</h3>
            </div>
        </div>
     </div>
   <p class="succesfully" *ngIf="collectionDelete">Collection deleted successfully</p>
     <div class="table-data">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

  <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

  <!-- Position Column -->
  <ng-container matColumnDef="sid">
    <th mat-header-cell *matHeaderCellDef> sid </th>
    <td mat-cell *matCellDef="let element"> {{element.summary_id}} </td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef> sid </th>
    <td mat-cell *matCellDef="let element"> {{element._id}} </td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="URL">
    <th mat-header-cell *matHeaderCellDef> S_URL </th>
    <td mat-cell *matCellDef="let element"><a target="_blank" [href]="View(element.summary_title, element.summary_id)">View</a> </td>
  </ng-container>
  <!-- Name Column -->
  <ng-container matColumnDef="Thumbali">
    <th mat-header-cell *matHeaderCellDef> S_title </th>
    <td mat-cell *matCellDef="let element" style="width: 15%;"> {{element.summary_title}} </td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="Publishedby">
    <th mat-header-cell *matHeaderCellDef> Reported_type </th>
    <td mat-cell *matCellDef="let element"> 
    {{element.report_type}} </td>
  </ng-container>
  <!-- Weight Column -->
  <ng-container matColumnDef="summarytitle">
    <th mat-header-cell *matHeaderCellDef> Reported_by</th>
    <td mat-cell *matCellDef="let element"> {{element.reported_by[0]}}
    <p></p> </td>
  </ng-container>

  <!-- Weight Column -->
  <ng-container matColumnDef="channel">
    <th mat-header-cell *matHeaderCellDef> Total_reported </th>
    <td mat-cell *matCellDef="let element">{{element.count}}   </td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="views">
    <th mat-header-cell *matHeaderCellDef> Actions  </th>
    <td mat-cell *matCellDef="let element"> 
         <!-- <select (change)="activetoggleChanged($event,element)" *ngIf="element.report_status != 'Closed' && element.report_status != 'Invalid'"   class="selectpicker">
                <option value="">select</option>
             
                <option   value="closed">Closed</option>
                <option  value="invalid">Invalid</option>
                 
            </select> 
         <select (change)="activetoggleChanged($event,element)"   *ngIf="element.report_status != 'Open' && element.report_status != 'Invalid'" class="selectpicker">
                <option value="">select</option>
                <option    value="open">open</option>
           
                <option >Invalid</option>
                 
            </select> 
         <select (change)="activetoggleChanged($event,element)" *ngIf="element.report_status != 'Closed' && element.report_status != 'Open'"  class="selectpicker">
                <option value="">select</option>
                <option   value="open">open</option>
                <option   value="closed">Closed</option>
               
                 
            </select>  -->
            <button mat-icon-button mat-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
              <mat-icon>
                <img src="../../assets/images/dots.png" id="users-total"
                  class="action-dots" /></mat-icon>
              <div #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu" place></div>
            </button>
            <mat-menu #menu="matMenu" xPosition="before" >
              
              <div *ngIf="element.report_status != 'Closed' && element.report_status != 'Invalid'">
               <button class="View" mat-menu-item value="Edit" (click)="activetoggleChanged('closed',element)" >
                <!-- <img src="../../assets/images/panel_images/view.svg" class="mr-3" /> -->
                <span>closed</span>
              </button>
            
              <button class="View" mat-menu-item value="Edit" (click)="activetoggleChanged('invalid',element)" >
                <!-- <img src="../../assets/images/panel_images/view.svg" class="mr-3" /> -->
                <span>Invalid</span>
              </button></div>
              <div *ngIf="element.report_status != 'Open' && element.report_status != 'Invalid'">
               <button class="View" mat-menu-item value="Edit" (click)="activetoggleChanged('open',element)" >
                <!-- <img src="../../assets/images/panel_images/view.svg" class="mr-3" /> -->
                <span>open</span>
              </button>
              
              <button class="View" mat-menu-item value="Edit"  (click)="activetoggleChanged('invalid',element)">
                <!-- <img src="../../assets/images/panel_images/view.svg" class="mr-3" /> -->
                <span>Invalid</span>
              </button></div>
              <div *ngIf="element.report_status != 'Closed' && element.report_status != 'Open'">
               <button class="View" mat-menu-item value="Edit" (click)="activetoggleChanged('open',element)" >
                <!-- <img src="../../assets/images/panel_images/view.svg" class="mr-3" /> -->
                <span>open</span>
              </button>
              <button class="View" mat-menu-item value="Edit" (click)="activetoggleChanged('closed',element)">
                <!-- <img src="../../assets/images/panel_images/view.svg" class="mr-3" /> -->
                <span>closed</span>
              </button></div>
             
              
              
            </mat-menu>  
           
            
         </td>
  </ng-container>
  <!-- Weight Column -->
  <ng-container matColumnDef="clicks">
    <th mat-header-cell *matHeaderCellDef> report_status </th>
    <td mat-cell *matCellDef="let element"> {{element.report_status}} </td>
  </ng-container>

  <!-- Symbol Column -->
  <!-- <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef> actions </th>
    <td mat-cell *matCellDef="let element">
       <div class="d-flex">
         
        <div class="toggle" *ngIf="element.isActive">
          <label class="toggle">
  <input type="checkbox" id="toggleButton"  [checked]="element.isActive" (change)="activetoggleChanged($event,element)">
  <span class="slider" [ngStyle]="{'background-color': getBackgroundColor(element)}"></span>
</label>
        </div>
        <div class="toggle" *ngIf="!element.isActive">
          <label class="toggle">
  <input type="checkbox" id="toggleButton" [checked]="element.isActive" (change)="inActivetoggleChanged($event,element)">
  <span class="slider"></span>
</label>
        </div>
         <div class="edit-img">
           <img class="cursor" src="../../../assets/images/delete.png" (click)="deleteCollection(element._id)">
        </div>
       </div>
       </td>
  </ng-container> -->

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<mat-paginator (page)="pageChanged($event)" [length]="totalSummary" [pageIndex]="currentPage" [pageSize]="pageSize"  [pageSizeOptions]="pageSizeOptions" aria-label="Select page of users"></mat-paginator>
     </div>
</div>
</div>
