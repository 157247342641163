import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CollectionService } from '../_services/collection.service';
import { LoginService } from '../_services/login.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
})
export class NavbarComponent implements OnInit {
  // linkAproduct: boolean = true;
  @Input() hideComponent: boolean = true;
  @Input() hideNotifications: boolean = true;
  @Input() hideadmin: boolean = true;
  @Input() hideCollection: boolean = false;
  @Output() hideComponentChange = new EventEmitter<boolean>();
  @Output() hideNotificationsChange = new EventEmitter<boolean>();
  @Output() hideadminChange = new EventEmitter<boolean>();
  @Output() hideCollectionChange = new EventEmitter<boolean>();
  userName: any;
  token: any;
  btn: boolean;
  categoriesBtn: boolean = true;
  token1: any;
  param1: any;
  constructor(
    private router: Router,
    private collectionServices: CollectionService,
    private loginService: LoginService,
    private activeRoute: ActivatedRoute
  ) {}
  @Output() triggerAction = new EventEmitter<void>();

  ngOnInit(): void {
    // console.log(this.token);
    // this.hideComponentChange.emit(false);
    // this.hideNotificationsChange.emit(true);
    // this.hideadminChange.emit(true);
    // this.hideCollectionChange.emit(false);
    // this.collectionServices.loginUserDetails().subscribe((result) => {
    //   this.userName = result.data.name;
    //   localStorage.setItem('userName', this.userName);
    // });
    // this.param1 = this.activeRoute.snapshot.queryParams.summaryCompleted;
    // console.log(this.param1);
    this.token1 = this.activeRoute.snapshot.queryParams.token;

    if (this.token1) {
      console.log('login');
      localStorage.setItem('token', this.token1);
      this.collectionServices.loginUserDetails().subscribe((result) => {
        localStorage.setItem('userName', result.data.name);
        localStorage.setItem('userType', result.data.userType);
        localStorage.setItem('ID', result.data._id);
      });
    }
    if (localStorage.getItem('token') != null) {
      this.categoriesBtn = false;
    }
  }
  channelBtn() {
    this.router.navigate(['/createChannel']);
  }
  viewlinks() {
    this.router.navigate(['creator/viewlinks']);
  }
  catergioresBtn() {
    console.log('btn');
    this.router.navigate(['/categories']);
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['/']);
    setTimeout(() => {
      // window.location.reload();
    });
  }
  linkProductBtn() {
    this.hideComponent = false;
    // this.router.navigate(['/Publish']);
    this.router.navigate(['/Collections']);
    this.triggerAction.emit();
    // if(this.param1){
    //   this.router.navigate([], {
    //     relativeTo: this.activeRoute,
    //     queryParams: { summaryCompleted: true },
    //     queryParamsHandling: 'merge',
    //   });
    // }
  }
  myCollections() {
    this.router.navigate(['My-Collections']);
  }
  myProducts() {
    this.router.navigate(['My-Products']);
  }
  editChannel() {
    this.router.navigate(['EditChannel']);
  }
  editCategories() {
    this.router.navigate(['EditCategories']);
  }
  feed() {
    this.router.navigate(['/feed']);
  }
}
