import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NoAuthSignupComponent } from 'src/app/no-auth-signup/no-auth-signup.component';
import { CollectionService } from 'src/app/_services/collection.service';

@Component({
  selector: 'app-myproducts',
  templateUrl: './myproducts.component.html',
  styleUrls: ['./myproducts.component.css'],
})
export class MyproductsComponent implements OnInit {
  collectionCreatedByCreator: any;
  productTitle: any;
  productId: any;
  productError: any;
  userType: any;

  constructor(
    private service: CollectionService,
    private router: Router,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.service.loginUserDetails().subscribe((res) => {
      this.userType = res.data.userType;
    });
    this.service.ProductList().subscribe((res) => {
      this.collectionCreatedByCreator = res.data;
    });
  }

  productDetails(title: any, id: any) {
    this.productTitle = title;
    this.productId = id;
    this.router.navigate([
      `/p/${this.productTitle.split(' ').join('-')}/id-${this.productId}`,
    ]);
  }
  saveForlater(id: any) {
    console.log('p', id);
    if (this.service.getToken() == '') {
      this.dialog.open(NoAuthSignupComponent, {
        data: {
          title: 'Bookmark this product',
          disableClose: true,
        },
      });
    } else {
      console.log('saveProductBookmark', id);
      let obj = {
        productId: id,
      };
      this.service.userProductBookmark(obj).subscribe(
        (result) => {
          // this.saved = true;
          // setTimeout(() => {
          //   this.saved = false;
          // }, 3000);
        },
        (error) => {
          this.productError = error.error.message;
          // setTimeout(() => {
          //   this.productError = '';
          // }, 3000);
        }
      );
    }
  }
}
