import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CollectionService } from 'src/app/_services/collection.service';

@Component({
  selector: 'app-usersavedproduct',
  templateUrl: './usersavedproduct.component.html',
  styleUrls: ['./usersavedproduct.component.css'],
})
export class UsersavedproductComponent implements OnInit {
  productList: any;
  constructor(private router: Router, public service: CollectionService) {}

  ngOnInit(): void {
    this.service.userProductGet().subscribe((result) => {
      console.log(result);
      this.productList = result.data;
    });
  }
  productDetails(title: any, id: any) {
    this.router.navigate([`p/${title.split(' ').join('-')}/id-${id}`]);
  }
}
