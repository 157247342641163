import { Component, Inject, NgZone, OnInit, PLATFORM_ID } from '@angular/core';
import { CollectionService } from '../_services/collection.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent implements OnInit {
  Date: any;
  currentIndex: number = 0;
  isBrowser: boolean = false;
  catergoryParams: any = '';
  categoryList: any;
  constructor(
    private ngZone: NgZone,
    @Inject(PLATFORM_ID) private platformId: any,
    private collectionService: CollectionService
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    this.isBrowser = isPlatformBrowser(this.platformId);

    if (this.isBrowser) {
      // Run client-side code only
      this.animateWords();
    }
    this.Date = new Date().getFullYear();
    // console.log(this.Date);
    this.collectionService.caterogoryListFeed(this.catergoryParams).subscribe(
      (res: any) => {
        this.categoryList = res.data.slice(1);
      },
      (error) => {}
    );
  }
  animateWords() {
    setInterval(() => {
      this.ngZone.run(() => {
        this.currentIndex = (this.currentIndex + 1) % this.categoryList?.length;
      });
    }, 3000); // Change text every 2 seconds
  }
}
