<app-navbar></app-navbar>

<div class="main">
    <div class="container">
        <div class="edit-collection mt-md-4 mb-md-3">
          <div *ngIf="collectionUpdate">
          <h4>Update Product</h4>
            <form [formGroup]="editCollectionForm">
                   

               <div class="row">
             <div class="col-md-4">
               <div class="card mt-5 pt-3">
                 <div class="card-body">
                    <!-- <img *ngIf="productImageUrl == null" src="../../assets/images/img-bg.svg" class="img-bg"> -->
                 <a  (click)="deleteImage()"><img src="../../assets/images/close-pdf.png" class="close-img-f"></a>

                <img *ngIf="productImageUrl != null" [src]="productImageUrl" width=100 height=100 alt="" class="img-upload-bg">
                <!-- <img *ngIf="producturl == null" src="../../assets/images/img-bg.svg" class="img-bg"> -->
                <img *ngIf="url != null" [src]="url" width=100 height=100 alt="" class="img-upload-bg">
                   <p *ngIf="url == null">Add one or more images of the product</p>
                 </div>
               </div>
               <div class="upload-img d-flex">
                 <input style="display: none" type="file"  accept="image/*" (change)="onFileSelectedEvent($event)" #fileInput>
                 <p>Upload product image</p>
                 <button class="plus-btn ml-2" (click)="fileInput.click()">+</button>
               </div>
      
             </div>
                
                <div class="col-md-8">
          <app-loader *ngIf="loaderPreview"></app-loader>

<div class="d-md-flex">
              <div class="input">

                <p class="pb-md-1 mt-md-4 ">Enter Affiliate Link</p>
                <input class="form-control" formControlName="AffiliateLink" placeholder="Paste the youtube video link">
                 <div *ngIf="c.AffiliateLink.errors?.required && submittedDetails" class="invalid-feedback m-t d-block">AffiliateLink is required </div>
              </div>
            </div>
<div class="d-md-flex">
              <div class="input">

                <p class="pb-md-1 mt-md-4 ">Enter the product title</p>
                <input class="form-control" formControlName="productTitle" placeholder="Paste the youtube video link">
                 <div *ngIf="c.productTitle.errors?.required && submittedDetails" class="invalid-feedback m-t d-block">AffiliateLink is required </div>
              </div>
            </div>
<div class="d-md-flex">
              <div class="input">

                <p class="pb-md-1 mt-md-4 ">Enter the description</p>
                <input class="form-control" formControlName="description" placeholder="Paste the youtube video link">
                 <div *ngIf="c.description.errors?.required && submittedDetails" class="invalid-feedback m-t d-block">AffiliateLink is required </div>
              </div>
            </div>

          <div class="details-section mt-md-3">
                <div class="d-flex">
                  <p class="mr-md-3">Discount code available?</p>
                  <mat-radio-group aria-label="Select an option" class="mt-1" [selectedIndex]="1">
                    <mat-radio-button [checked]="true" value="0" (change)="changeRoute($event)">
                      <span>No</span>
                    </mat-radio-button>
                    <mat-radio-button class="ml-md-5" value="1" (change)="changeRoute($event)">
                      <span>Yes</span>
                    </mat-radio-button>
                  </mat-radio-group>
                </div>
                <div class="title d-flex" *ngIf="showDiscount">
                  <input type="text" formControlName="couponCode" placeholder="Enter Discount code or offer code">
                </div>
              </div>
           

            
                  <div class="update-collection mt-md-4 mb-5">
                    <button class="btn pointer" (click)="updateProduct()">Update Product</button>
                </div>
                </div>
                
              </div>
            </form>
          </div>
           


            <div *ngIf="updatedsuccefully">
                   <div class="row">
           <div class="col-md-6 border-p">
             <div class="login-user-img mt-5">
               <img src="../../assets/images/check.png" class="success-img">
               <div class="login-details-msg d-flex justify-content-center mt-md-4">
                 <h5>Collection updated successfully</h5>
                 <P class=" ml-2 pointer">
                   <a routerLink="/p/{{this.CollectionTitle
             .split(' ')?.join('-')}}/id-{{this.id}}">View</a>
                 </P>
               </div>
               <h4 class="mt-md-4">Share this product link with your friends and help them discover</h4>
               <div class="shareItBtnSection d-flex justify-content-center mb-3">
                 <button type="button" class="link pointer">{{this.categoryLink | slice:0:50}}</button>
                 <button type="button" class="pointer" (click)="share()">Share it</button>
               </div>
               
             </div>
           </div>
           <div class="col-md-6">
             <div class="loginDetails">
               <img src="../../assets/images/arr-2.png" class="arrow">
               <div class="more-community justify-content-center">
                 <div class="pin-it mb-2 mt-md-2">
                   <button type="button" class="pointer">
                    <a data-pin-do="buttonPin" data-pin-count="beside" data-pin-custom="true" data-pin-save="false" data-pin-description="Pinterest specific description here" target="_blank" href="https://www.pinterest.com/pin/create/button/?url={{categoryLink}}/&media={{productImageUrl}}&description=So%20delicious!" data-pin-custom="true">
                  <img src="../../../../assets/images/pinterest (2).png" class="pointer pin-img" alt="pinterest">
                  <span class="save ml-2">Pin it</span></a>
                   </button>
                 </div>
                 <div>
                   <h5 class="mt-md-5 ml-md-3">Cross publish this collection to your Pinterest Community and drive more engagement and traffic</h5>
                 </div>
               </div>
             </div>
           </div>
         </div>
            </div>

        </div>
    </div>
</div>