import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CollectionService } from 'src/app/_services/collection.service';

@Component({
  selector: 'app-update-channel',
  templateUrl: './update-channel.component.html',
  styleUrls: ['./update-channel.component.css'],
})
export class UpdateChannelComponent implements OnInit {
  catergoryList: any = [];
  catergory: string;
  submiited: boolean = false;
  succeed: boolean = false;
  catergoriesFormGroup: FormGroup;
  subCatergory: any;
  msg: string;
  url: any;
  selectedFile: File;
  updateProfileForm: any;
  selectedFileEvent: File;
  fileNameEvent: any;
  categoryList: any;
  Cateogory = new FormControl('');
  multipleCatergory: any = [];
  country: any = ['India', 'US', 'Canada', 'London', 'New york', 'Australia'];
  countryEvent: any;
  fileName: any;
  uploadFlie: boolean = true;
  attachmentFile: boolean = false;
  uploadBtn: boolean = false;
  csvsucceed: boolean = false;
  userID: any;
  private _id: any;
  name: any;
  channelParams: any;
  channeldetails: any;
  profile: any;
  countryData: any;
  categorySelected: any = [];
  loader: boolean = false;
  completedSuceesfully: boolean = false;
  categoryListSelected: any = [''];
  previousCategoryIds: any;
  catergoryParams: {};
  isActive: any;

  constructor(
    private collectionServices: CollectionService,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute
  ) {
    this.catergoriesFormGroup = this.fb.group({
      name: [''],
      url: [''],
      ownerName: [''],
      category: [''],
      followersCount: [''],
      description: [''],
      profile: [''],
      country: [''],
      title: [''],
      isActive: [''],
    });
  }

  ngOnInit(): void {
    this._id = this.activatedRoute.snapshot.queryParams.id;
    this.name = this.activatedRoute.snapshot.queryParams.name;
    console.log(this._id);
    console.log(this.name);

    this.channelParams = {
      publisherName: this.name,
    };

    this.collectionServices
      .ChannelDetails(this.channelParams)
      .subscribe((res) => {
        this.channeldetails = res.data;
        console.log(this.channeldetails);
        this.isActive = res.data.isActive;
        this.countryData = res.data.country;
        this.categorySelected = res.data.topic;
        console.log(this.countryData);
        this.catergoriesFormGroup.controls['name'].setValue(res.data.name);
        this.catergoriesFormGroup.controls['title'].setValue(res.data.title);
        this.catergoriesFormGroup.controls['url'].setValue(res.data.url);
        this.catergoriesFormGroup.controls['description'].setValue(
          res.data.description
        );

        const selectedNames = res.data.topic.map(
          (category: any) => category._id
        );

        this.catergoriesFormGroup.get('category')?.setValue(selectedNames);
        this.catergoriesFormGroup.controls['followersCount'].setValue(
          res.data.followersCount
        );

        this.catergoriesFormGroup.controls['ownerName'].setValue(
          res.data.ownerName
        );

        this.profile = res.data.profile;
      });
    this.collectionServices.caterogoryList().subscribe((res) => {
      console.log(res);
      this.categoryList = res.data;
    });
  }
  deleteCatergory(item: any, i: any) {
    // console.log(item);
    // this.categorySelected.splice(i, 1);
    // console.log(this.categorySelected);
    // this.previousCategoryIds = this.categorySelected.forEach((item: any) => {
    //   this.categoryListSelected.push(item._id);
    // });
    console.log(this.categoryListSelected);

    this.catergoryParams = {
      channelId: this._id,
      categoryId: item._id,
    };

    this.collectionServices
      .catergoryDeleted(this.catergoryParams)
      .subscribe((res) => {
        console.log(res);
        this.categorySelected.splice(i, 1);
      });
  }
  categoryEvent(e: any) {
    console.log(e.value);
    if (e.value != '') {
      this.multipleCatergory = e.value;
      this.categoryListSelected = JSON.stringify(this.multipleCatergory);
    } else {
      this.categoryListSelected = '';
    }
  }

  get c(): { [key: string]: AbstractControl } {
    return this.catergoriesFormGroup.controls;
  }
  onFileSelected(event: any): void {
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    this.profile = null;

    reader.onload = (_event) => {
      // this.msg = '';
      this.url = reader.result as string;

      this.selectedFile = <File>event.target.files[0];
    };
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.catergoriesFormGroup.controls['profile'].setValue(file);
    }
    // if (file.size > 100 * 1024) {
    //   // Display an error message if the file size is too large
    //   this.errorMessage = 'Image size should be 100KB or smaller';
    //   this.selectedImage = null;
    // } else {
    //   // Image size is valid, reset error message and store the selected image
    //   this.errorMessage = null;
    //   this.selectedImage = file;
    // }
  }
  Country(event: any) {
    console.log(event.value);
    this.countryEvent = event.value;
  }

  onFileSelectedTvProgram(event: any): void {
    this.selectedFile = <File>event.target.files;
    this.fileName = event.target.files.name;
    if (event.target.files.name != null) {
      this.uploadFlie = false;
      this.attachmentFile = true;
      this.uploadBtn = true;
    }
  }

  submit() {
    console.log(this.catergoriesFormGroup);
    if (this.catergoriesFormGroup.invalid) {
      this.submiited = true;
    } else {
      this.submiited = false;
      this.loader = true;

      const formData = new FormData();
      formData.append(
        'profile',
        this.catergoriesFormGroup.controls['profile'].value
      );
      formData.append('category', this.categoryListSelected);
      formData.append('ownerName', this.catergoriesFormGroup.value.ownerName);
      formData.append('url', this.catergoriesFormGroup.value.url);
      if (this.catergoriesFormGroup.value.isActive == '') {
        formData.append('isActive', this.isActive);
      } else {
        formData.append('isActive', this.catergoriesFormGroup.value.isActive);
      }

      formData.append('title', this.catergoriesFormGroup.value.title);
      formData.append('name', this.catergoriesFormGroup.value.name);
      formData.append(
        'followersCount',
        this.catergoriesFormGroup.value.followersCount
      );
      formData.append(
        'description',
        this.catergoriesFormGroup.value.description
      );

      formData.append('country', this.catergoriesFormGroup.value.country);

      this.collectionServices
        .updateChannelDetails(this._id, formData)
        .subscribe((result) => {
          console.log(result);
          console.log('success');
          this.succeed = true;
          this.loader = false;
          this.completedSuceesfully = true;

          setTimeout(() => {
            this.succeed = false;
          }, 3000);
          this.url = null;
          this.catergoriesFormGroup.reset();
        });
    }
  }
}
