<app-navbar></app-navbar>
<div class="main">
  <div class="container">
    <div class="linkProductSection mt-5">
      <div class="link_product" *ngIf="createCollections">
        <!-- <h4>How to link a product?</h4> -->
        <h3>Provide the Product title </h3>
        <div class="publish-link mt-3">
          <app-loader *ngIf="loaderPreview"></app-loader>

          <form [formGroup]="createPowerCollectionEmail">
            <div class="d-md-flex">
              <div class="input">
                <p class="pb-md-1 mt-md-4 ">Enter product title</p>
                <input class="form-control" formControlName="title" placeholder="Enter the Title of the link">
                <div *ngIf="f.title.errors?.required && submittedSourceLink" class="invalid-feedback m-t d-block">Product is required </div>
              </div>
              </div>
              </form>

               <div class="publish-btn mb-3 d-flex">
            <a>
              <p class="mt-5">Back</p>
            </a>
            <button class="pointer" (click)="createCollectionBtn()">Proceed</button>
          </div>
           
              </div>
              </div>

              <div *ngIf="createProducts">
                  <form [formGroup]="createBulkProducts">
        <div class="row">
          <div class="col-md-4 mt-3">
            <div class="card upload-1">
              <div class="card-body">
                <img *ngIf="productImageUrl == null && producturl == null" src="../../assets/images/add-image.png" class="img-bg">
                 <a *ngIf="productImageUrl != null" (click)="deleteImage()"><img src="../../assets/images/close-pdf.png" class="close-img-f"></a>
                <img *ngIf="productImageUrl != null" [src]="productImageUrl" width=100 height=100 alt="" class="img-upload-bg">
                <!-- <img *ngIf="producturl == null" src="../../assets/images/img-bg.svg" class="img-bg"> -->
                <img *ngIf="producturl != null" [src]="producturl" width=100 height=100 alt="" class="img-upload-bg">
                <!-- <p *ngIf="producturl == null">Add one or more images of the product</p> -->
              </div>
              
            </div>
            <div class="upload-img d-flex"><input style="display: none" type="file"  accept="image/*" (change)="onFileSelectedProduct($event)" #fileInput><p>Upload product image</p><button class="plus-btn ml-2" (click)="fileInput.click()">+</button></div>
            <div class="Products-added-section scroll mt-md-5 pt-md-3" *ngIf="collectiontitle != ''">
              <div class="product">
                <p>Products added to the collection</p>
                <div class="" *ngFor="let value of collectiontitle;let i = index;">
                  <div class="d-flex">
                    <div class="mr-md-3 mt-md-2">
                      {{i + 1}}
                    </div>
                    <div class="p-title d-flex mb-md-3">
                      <p class="mr-auto">{{value.title | slice:0:23}}</p>
                      <a (click)="deleteProduct(value.id,i)">
                        <img src="../../assets/images/close-m.svg" class="close-img-view pointer">
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div *ngIf="p.productImage.errors?.required && submittedProduct" class="invalid-feedback m-t d-block"> ProductImage is required </div> -->
          </div>
          <app-loader *ngIf="loaderPreview2"></app-loader>
          <app-loader *ngIf="loaderPreview3"></app-loader>
          <div class="col-md-8">
            <div class="addProcductDetails-input">
              <div class="details-section">

                <p>Enter the destination url</p>
                <div class="d-flex">
                  <div class="title">
                    <input type="text" formControlName="AffiliateLink" placeholder="Enter the Destination url">
                    <div *ngIf="p.AffiliateLink.errors?.required && submittedProduct" class="invalid-feedback m-t d-block"> Destination url is required </div>
                    <div *ngIf="linkMypreview != null" class="invalid-feedback pr m-t d-block">{{linkMypreview}}</div>
                  </div>
                  <div class="fetch">
                    <button class="pointer ml-md-3 mt-2" (click)="fetchProductDetail()">Fetch</button>
                  </div>
                </div>
              </div>
              <div class="details-section mt-3">
                <p>Enter the product title</p>
                <div class="title">
                  <input type="text" formControlName="productTitle" placeholder="Enter the Title">
                  <div *ngIf="p.productTitle.errors?.required && submittedProduct" class="invalid-feedback m-t d-block"> Title is required </div>
                </div>
              </div>
              <div class="details-section d-flex mt-3">
                <div class="title">
                  <p>Enter the description</p>
                  <input type="text" formControlName="description" placeholder="Enter the description" class="brand-input">
                  <div *ngIf="p.description.errors?.required && submittedProduct" class="invalid-feedback m-t d-block"> Description is required </div>
                </div>
                <!-- <h6 class="ml-5">How to link a product?</h6> -->
              </div>
              <div class="details-section mt-3">
                    <p>Channel Name</p>
                    <div class="title">
                      <div>
                        <select formControlName="channelName" class="form-control">
                          <option value="" selected disabled hidden>Select Channel name</option>
                          <option *ngFor="let list of ChannelName" [value]="list.name">{{list.name}}</option>
                        </select>
                        <div *ngIf="p.channelName.errors?.required && submittedProduct" class="invalid-feedback m-t d-block"> Channel name is required </div>
                      </div>
                    </div>
                  </div>
              <div class="details-section p-select mt-3 d-flex">
                
                
                <div class="catergories-add-section mt-md-2"><p>Category Name</p><mat-form-field appearance="fill"><mat-select formControlName = "categoryId" multiple placeholder="Select Category"   disableOptionCentering panelClass="eligablePanelClass" (selectionChange)="categoryEvent($event)" #closeattendance ><mat-option class='services-view' *ngFor="let list of categoryList" [value]="list._id">{{list.name}}</mat-option></mat-select></mat-form-field><div *ngIf="p.categoryId.errors?.required && submittedProduct" class="invalid-feedback m-t d-block"> Please select the category </div></div>
              </div>
              
              <div class="details-section mt-md-3">
                <div class="d-flex">
                  <p class="mr-md-3">Discount code available?</p>
                  <mat-radio-group aria-label="Select an option" class="mt-1" [selectedIndex]="1">
                    <mat-radio-button [checked]="true" value="0" (change)="changeRoute($event)">
                      <span>No</span>
                    </mat-radio-button>
                    <mat-radio-button class="ml-md-5" value="1" (change)="changeRoute($event)">
                      <span>Yes</span>
                    </mat-radio-button>
                  </mat-radio-group>
                </div>
                <div class="title d-flex" *ngIf="showDiscount">
                  <input type="text" formControlName="couponCode" placeholder="Enter Discount code or offer code">
                </div>
              </div>
              <div class="checked mt-3">
                <p>
                  <mat-checkbox [checked]="true" class="disable_ripple">Also post this on Pinterest and make it available</mat-checkbox>
                </p>
                <p>
                  <mat-checkbox [checked]="true" class="disable_ripple">I agree to the terms of service and privacy policy of Summarizly AI</mat-checkbox>
                </p>
              </div>
              <p *ngIf="productErroMsg != null" class="invalid-feedback m-t d-block">{{productErroMsg}}</p>
              <!-- <div class="publishLinkbtn"><button class="mt-3 pointer" ></button></div> -->
              <div class="publishLinkbtn save-p d-flex mb-md-4">
                <button class="mt-3 pointer product" (click)="addProductDetails()">Save & add more products</button>
                <!-- <button class="mt-3 pointer product">Save & add more products</button> -->
                <button class="mt-3 ml-md-4 pointer publish" data-toggle="modal" data-target="#publishCollection">Publish Products</button>
              </div>
              <div class="modal fade" data-keyboard="false" data-backdrop="static" id="publishCollection" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <img src="../../assets/images/close-m.svg">
                      </button>
                    </div>
                    <div class="modal-body"> Are you sure you want to publish this bulk products </div>
                    <div class="modal-footer">
                      <button type="button" class="btn back" data-dismiss="modal">No, take me back</button>
                      <button type="button" class="btn confirm" data-dismiss="modal" (click)="saveProducts()">Yes, publish it</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
              </div>

               <div *ngIf="updatedsuccefully">
                   <div class="row">
           <div class="col-md-6 border-p">
             <div class="login-user-img mt-5">
               <img src="../../assets/images/check.png" class="success-img">
               <div class="login-details-msg d-flex justify-content-center mt-md-4">
                 <h5>Collection updated successfully</h5>
                 <P class=" ml-2 pointer">
                   <a routerLink="/{{title}}/{{collectiontitle
             .split(' ')?.join('-')}}/id-{{this.id}}">View</a>
                 </P>
               </div>
               <h4 class="mt-md-4">Share this product link with your audience and drive more traffic and sales for your affiliate links</h4>
               <div class="shareItBtnSection d-flex justify-content-center mb-3">
                 <button type="button" class="link pointer">{{this.navigateLink | slice:0:50}}</button>
                 <button type="button" class="pointer" (click)="share()">Share it</button>
               </div>
                 <div class="shorlink">
                <h4>Need a short link ? here it is</h4>
              <div class="need-a-short-link d-flex ">

               <h2><a href="{{shortUrl}}" target="_blank" class="mr-md-5">{{shortUrl}}</a></h2>
                  <button class="copy-btn mt-1 pointer" (click)="copyLink($event.target,'copied')" cdkCopyToClipboard="{{shortUrl}}">Copy</button>
               </div>
               </div>
               
             </div>
           </div>
           <div class="col-md-6">
             <div class="loginDetails">
               <img src="../../assets/images/arr-2.png" class="arrow">
               <div class="more-community justify-content-center">
                 <div class="pin-it mb-2 mt-md-2">
                   <button type="button" class="pointer">
                    <a data-pin-do="buttonPin" data-pin-count="beside" data-pin-custom="true" data-pin-save="false" data-pin-description="Pinterest specific description here" target="_blank" href="https://www.pinterest.com/pin/create/button/?url={{navigateLink}}/&media={{productImageUrl}}&description=So%20delicious!" data-pin-custom="true">
                  <img src="../../../../assets/images/pinterest (2).png" class="pointer pin-img" alt="pinterest">
                  <span class="save ml-2">Pin it</span></a>
                   </button>
                 </div>
                 <div>
                   <h5 class="mt-md-5 ml-md-3">Cross publish this collection to your Pinterest Community and drive more engagement and traffic</h5>
                 </div>
               </div>
             </div>
           </div>
         </div>
            </div>
              </div>
              </div>
              </div>
<app-footer></app-footer>