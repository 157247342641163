import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CollectionService } from 'src/app/_services/collection.service';

@Component({
  selector: 'app-allcollection',
  templateUrl: './allcollection.component.html',
  styleUrls: ['./allcollection.component.css'],
})
export class AllcollectionComponent implements OnInit {
  hideComponent: boolean = false;
  hideNotifications: boolean = false;
  hideadmin: boolean = true;
  hideCollection: boolean = true;

  collectionFeed: any;
  userName: any;
  collectionName: any = '';
  collectionParams: any;
  collectionList: any;
  activeState: any;
  categoryList: any;
  unfollowBtnShow: boolean = false;
  followBtnShow: boolean = true;
  caterogoryId: any;
  followingTab: any = false;
  followTab: boolean = true;
  followcaterogoryids: any = [];
  categoryListFollowListLoop: any;
  token: string | null;
  userType: string | null;
  userId: string | null;
  page: number;
  constructor(
    private collectionService: CollectionService,
    private router: Router
  ) {}

  ngOnInit() {
    this.collectionService.caterogoryList().subscribe((res: any) => {
      this.categoryList = res.data;
      this.getFollowingTab();
      this.activeState = res.data[0].name;
      this.caterogoryId = res.data[0]._id;
    });
    this.token = localStorage.getItem('token');

    this.userType = localStorage.getItem('userType');
    this.collectionParams = {
      categorieName: this.collectionName,
    };
    this.collectionService
      .collectionListFliter(this.collectionParams)
      .subscribe((response) => {
        this.collectionList = response.data;
      });
    this.userName = localStorage.getItem('userName');
  }
  navClick(item: any) {
    this.followingTab = item.following;
    this.followTab = item.follow;
    this.activeState = item.name;

    this.caterogoryId = item._id;
    this.collectionName = item.name;
    this.collectionParams = {
      categorieName: this.collectionName,
    };
    this.collectionService
      .collectionListFliter(this.collectionParams)
      .subscribe((res: any) => {
        this.collectionList = res.data;
      });
  }
  collectionBtn(title: any, id: any) {
    this.router.navigate([`v/${title.split(' ').join('-')}/id-${id}`]);
  }
  followBtn(item: boolean) {
    console.log(item);
    if (item) {
      let obj = {
        categoryId: this.caterogoryId,
      };
      console.log(obj);
      this.collectionService.caterogyFollow(obj).subscribe((res) => {
        console.log(obj);
        this.getFollowingTab();
      });
    } else {
      let obj = {
        categoryId: this.caterogoryId,
      };
      console.log(obj);
      this.collectionService
        .caterogyUnFollow(this.caterogoryId)
        .subscribe((res) => {
          console.log(obj);
          this.followTab = true;
          this.followingTab = false;
        });
    }
  }

  getFollowingTab() {
    this.followcaterogoryids = [];
    this.collectionService.caterogyFollowList().subscribe((result) => {
      console.log(result);
      this.categoryListFollowListLoop = result.data;
      this.categoryListFollowListLoop.forEach((item: any) => {
        this.followcaterogoryids.push(item._id);
      });
      console.log(this.followcaterogoryids);

      this.categoryList.forEach((citem: any) => {
        citem.follow = true;
        citem.following = false;
        this.followcaterogoryids.forEach((fitem: any) => {
          if (citem._id == fitem) {
            console.log(citem._id);
            citem.following = true;
            citem.follow = false;
            this.followingTab = citem.following;
            this.followTab = citem.follow;
          }
        });
      });

      console.log('caterogy-list', this.categoryList);
    });
  }
}
