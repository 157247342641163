
<div class="container" style="height: 88vh;">
    <div class="page-not-found-section h-100 d-flex  align-items-center justify-content-center">
        <div class="pagenotfound-img text-center" >
            <img src="../../assets/images/pagenotfound.svg">
            <div class="pagenotfound-Details">
            <h5 class="mt-3 pb-2">Oops! Page not found</h5>
            <P>The page you’re looking for has been changed or doesn’t exist.
<a routerLink="/feed">Click here </a></P>
        </div>
        </div>
        
    </div>
</div>
 