<div class="main">
  <div class="container-fluid">
    <div class="congrats text-center">
      <!-- <img src="../../assets/images/panel_images/thank.gif" class="success_Img" /> -->
    </div>
    <div class="cms-heading-succes-section">
      <h5>Are you sure you want to delete this summary?</h5>
     
    </div>
    <div class="close-btn mt-4 mb-4 d-flex">
      <button class="btn btn-delete mr-3" (click)="delete()">delete</button>
      <button class="btn btn-close" (click)="close()">Close</button>

    </div>
  </div>
</div>