

<div class="sidenavbar-heade">
<!-- <div class='row'>
    <div class="col-md-2">
    <app-sidenavbar></app-sidenavbar>

    </div> -->
    <div class="summary-header ml-3 mt-md-3">
     <!-- <div class="d-flex justify-content-end">
       <div class="d-flex   language-header">
            <p>Active</p>
            <select (change)="langugeChange($event)"  class="selectpicker" >
              <option value="">select</option>
                <option *ngFor="let item of Language" value="{{item}}">{{item}}</option>
            </select>
        </div>
     </div> -->

     <div class="card-section my-md-4 d-flex justify-content-between">
        <div class="card mr-md-5" *ngFor="let item of items">
            <div class="card-body">
                <p>{{item.name}}</p>
                <h3>{{item.number}}</h3>
            </div>
        </div>
     </div>
      <p class="succesfully" *ngIf="userDelete">User delete successfully</p>
     <div class="table-data">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
  <ng-container matColumnDef="sid">
    <th mat-header-cell *matHeaderCellDef> sid </th>
    <td mat-cell *matCellDef="let element"> {{element._id}} </td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="Language">
    <th mat-header-cell *matHeaderCellDef> Languages </th>
    <td mat-cell *matCellDef="let element"> {{element.language[0]}}</td>
  </ng-container>

  <!-- Weight Column -->
  <ng-container matColumnDef="totalSummaries">
    <th mat-header-cell *matHeaderCellDef> TotalSummaries </th>
    <td mat-cell *matCellDef="let element">{{element.summaryCount}}</td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="view">
    <th mat-header-cell *matHeaderCellDef> View </th>
    <td mat-cell *matCellDef="let element">  </td>
  </ng-container>
  <!-- Weight Column -->
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef> Status </th>
    <td mat-cell *matCellDef="let element"> 
       {{element.Status}}
    </td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef> Action </th>
    <td mat-cell *matCellDef="let element"> 
    
        <button mat-icon-button mat-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
              <mat-icon>
                <img src="../../assets/images/dots.png" id="users-total"
                  class="action-dots" /></mat-icon>
              <div #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu" place></div>
            </button>
            <mat-menu #menu="matMenu" xPosition="before" >
             
              <div>
               <button class="View" mat-menu-item value="Edit" *ngIf="element.Status == 'In-active'" (click)="activetoggleChanged('open',element)" >
                <!-- <img src="../../assets/images/panel_images/view.svg" class="mr-3" /> -->
                <span>Active</span>
              </button>
              <button class="View" mat-menu-item value="Edit" *ngIf="element.Status == 'Active'" (click)="activetoggleChanged('closed',element)">
                <!-- <img src="../../assets/images/panel_images/view.svg" class="mr-3" /> -->
                <span>In-Active</span>
              </button></div>
             
              
              
            </mat-menu> 
    
    </td>
  </ng-container>
  <!-- Weight Column -->
  <!-- <ng-container matColumnDef="clicks">
    <th mat-header-cell *matHeaderCellDef> Bookmarks </th>
    <td mat-cell *matCellDef="let element"> {{element.bookmarkDataCount}} </td>
  </ng-container> -->

  <!-- Symbol Column -->
  <!-- <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef> actions </th>
    <td mat-cell *matCellDef="let element"> <div class="d-flex">
        <div class="edit-img">
           <img  src="../../../assets/images/e-3.png">
        </div>
        <div class="toggle">
          <label class="toggle">
  <input type="checkbox" id="toggleButton" (change)="toggleChanged($event)">
  <span class="slider"></span>
</label>
        </div>
         <div class="edit-img">
           <a>
            <img class="cursor"  src="../../../assets/images/delete.png" (click)="deleteUser(element._id)">
            </a>
           
        </div>
       
       </div></td>
  </ng-container> -->

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
  <p class="no-user" *ngIf="userData == ''">No users in {{language}} language</p>
<mat-paginator (page)="pageChanged($event)" [length]="totalSummary" [pageIndex]="currentPage" [pageSize]="pageSize"  [pageSizeOptions]="pageSizeOptions" aria-label="Select page of users"></mat-paginator>
     </div>
</div>
</div>
   
