import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  IMAGE_URL: any;
  URL: any;
  userToken: any;
  poweruserToken: any;
  errorMessage: any;
  token: any;
  constructor(private http: HttpClient) {
    console.log('login-token', this.token);
  }
  login(data: any) {
    return this.http.post<any>(`${environment.AUTH_URL}auth/login`, data).pipe(
      map((res: any) => {
        this.poweruserToken = res.token;
        // console.log(this.userToken);
        return res;
      })
    );
  }
  signUp(data: any) {
    return this.http.post<any>(`${environment.AUTH_URL}auth/sign-up`, data);
  }
  forgotPassword(data: any) {
    return this.http.post<any>(
      `${environment.AUTH_URL}auth/forgot-password`,
      data
    );
  }
  otpVerfication(data: any) {
    return this.http.post<any>(
      `${environment.AUTH_URL}auth/otp-verification`,
      data
    );
  }
  resetPassword(data: any) {
    return this.http.put<any>(
      `${environment.AUTH_URL}auth/reset-password`,
      data
    );
  }
  resetOtp(data: any) {
    return this.http.post<any>(`${environment.AUTH_URL}auth/resendOtp`, data);
  }

  // USER
  loginUser(data: any) {
    const url = `${environment.USER}user/login`;

    return this.http.post<any>(url, data).pipe(
      map((res: any) => {
        this.userToken = res.token;
        // console.log(this.userToken);
        return res;
      })
    );
  }
  signupUser(data: any) {
    const url = `${environment.USER}user/sign-up`;

    return this.http.post<any>(url, data);
  }

  forgotPasswordUser(data: any) {
    return this.http.post<any>(`${environment.USER}user/forgot-password`, data);
  }
  ResetPasswordUser(data: any) {
    return this.http.put<any>(
      `${environment.USER}user/user-reset-password`,
      data
    );
  }

  //CREATORS
  creatorLogin(data: any) {
    return this.http
      .post<any>(`${environment.AUTH_URL}publisher/login`, data)
      .pipe(
        map((res: any) => {
          this.poweruserToken = res.token;
          // console.log(this.userToken);
          return res;
        })
      );
  }
  CreatorSignUp(data: any) {
    return this.http.post<any>(
      `${environment.AUTH_URL}publisher/Sing-up`,
      data
    );
  }
  creatorForgotPassword(data: any) {
    return this.http.post<any>(
      `${environment.AUTH_URL}publisher/forgot-password`,
      data
    );
  }
  ResetPasswordCreator(data: any) {
    return this.http.put<any>(
      `${environment.AUTH_URL}creator-onbording/reset-password`,
      data
    );
  }
  ResetPasswordPowerUser(data: any) {
    return this.http.put<any>(
      `${environment.AUTH_URL}publisher/reset-password`,
      data
    );
  }
  googleSignupUpdate(data: any) {
    return this.http.put<any>(`${environment.AUTH_URL}publisher`, data);
  }

  handleError(error: any) {
    let errorMessage = '';

    if (error.error instanceof ErrorEvent) {
      // client-side error

      this.errorMessage = `${error.error.message}`;
    } else {
      // server-side error

      this.errorMessage = `${error.status}\nMessage: ${error.message}`;
    }

    window.alert(errorMessage);

    return throwError(this.errorMessage);
  }
}
