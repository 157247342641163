import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CollectionService } from 'src/app/_services/collection.service';

@Component({
  selector: 'app-publishersidenavbar',
  templateUrl: './publishersidenavbar.component.html',
  styleUrls: ['./publishersidenavbar.component.css'],
})
export class PublishersidenavbarComponent implements OnInit {
  activeRoute: string;
  activeRouteColor: string;
  activeRouteColor1: string;
  activeRouteColor2: string;
  routeParam: any;
  routeNavigate: any;
  ChannelName: any;
  constructor(
    private router: Router,
    private activateRoute: ActivatedRoute,
    private service: CollectionService
  ) {}

  ngOnInit() {
    if (localStorage.getItem('token') != null) {
      this.service.creatorChannel().subscribe((res) => {
        this.ChannelName = res.data;
        console.log(this.ChannelName);
      });
    }

    this.routeParam = this.router.url.split('/');
    this.routeNavigate = `${this.routeParam[1]}/${this.routeParam[2]}`;
    if (this.routeNavigate == 'Publisher/Home') {
      this.routeNavigate = true;
    }
  }
  routetoBlog() {
    this.router.navigate([`/${this.ChannelName[0]?.name}`]);
  }
  route(route: any) {
    this.router.navigate([`${route}`]);
    this.routeNavigate = route;
  }
}
