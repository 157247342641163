

<div class="main">
    <div class="container">

        <div class="subscribe-section-main" *ngIf="subscribeEmail">
            <div class="just-last d-flex justify-content-end">
                <h4 class="mr-md-5">Just one last step</h4>
                <img (click)="close()"src="../../../assets/images/close.png" class="ml-md-5 pl-md-ks3 image-c">
            </div>
            <div class="progress mt-3" style="border-radius: 10px">
  <div class="progress-bar" style="border-radius: 10px" role="progressbar"  aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
    <p>90%</p></div>
</div>

<div class="weekly-mails">
     <h5>Get personalized weekly emails</h5>
  <form [formGroup]="subscribeForm">

  
     <div class="input">
        <input type="text" formControlName="name" placeholder="Enter your name">
        <div *ngIf="c.name.errors?.required && subscribeSubmitted" class="invalid-feedback m-t d-block">Name is required </div>

     </div>
     <div class="select-menu">
     <h5>Select your gender</h5>

     

        <select formControlName="gender" name="work_days" id="id_work_days" multiple (change)="change($event)">
  <option [class.change]="event ==  'male'" value="male">Male</option>
  <option [class.change]="event == 'female'" value="female">Female</option>
</select>
        <div *ngIf="c.gender.errors?.required && subscribeSubmitted" class="invalid-feedback m-t d-block">Gender is required</div>

     </div>
     <div class="">
     <h5>What are your interests</h5>
       <div class="details-section mt-2 ml-md-1">
                    <div class="catergories-add-section mt-md-2">
                      <mat-form-field appearance="fill">
                        <mat-select formControlName="categoryId"  multiple placeholder="Select Category" disableOptionCentering panelClass="eligablePanelClass" (selectionChange)="categoryEvent($event)" #closeattendance>
                          <!-- <mat-option class='services-view'  [value]=" ">Select Channel name</mat-option> -->
                          <mat-option class='services-view' *ngFor="let list of categoryList" [value]="list._id">{{list.name}}</mat-option>
                        </mat-select>
                      </mat-form-field>
        <div *ngIf="c.categoryId.errors?.required && subscribeSubmitted" class="invalid-feedback m-t d-block">Please select</div>

                    </div>
                  </div>
     </div>

     </form>
     <div class="subscribe-button">
        <button class="pointer" (click)="subscribe()">Subscribe</button>
     </div>
</div>
        </div>

        <div class="subscribe-succefully mt-md-4 " *ngIf="subscribeSuccefully">
               <img src="../../assets/images/check.png" class="success-img mb-2">
                <h3>Subscribed successfully</h3>
        </div>
        </div>
</div>
