import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import {
  Component,
  EventEmitter,
  OnInit,
  Inject,
  Renderer2,
  ViewChild,
  PLATFORM_ID,
  OnDestroy,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTooltip } from '@angular/material/tooltip';
import { ActivatedRoute, Router } from '@angular/router';
import { NoAuthSignupComponent } from 'src/app/no-auth-signup/no-auth-signup.component';
import { ShareproductLinkDialogComponent } from 'src/app/shareproduct-link-dialog/shareproduct-link-dialog.component';
import { CollectionService } from 'src/app/_services/collection.service';
import { environment } from 'src/environments/environment';
import { platformServer } from '@angular/platform-server';
import { Meta, Title } from '@angular/platform-browser';
import { UseronboardingflowDialogComponent } from 'src/app/useronboardingflow-dialog/useronboardingflow-dialog.component';

declare const swiper: any;
@Component({
  selector: 'app-productdetails',
  templateUrl: './productdetails.component.html',
  styleUrls: ['./productdetails.component.css'],
})
export class ProductdetailsComponent implements OnInit, OnDestroy {
  hideComponent: boolean = false;
  hideNotifications: boolean = false;

  productListView = [
    'Women top wear in red. Party wear',
    'Women top wear in red. Party wear',
    'Women top wear in red. Party wear',
    'Women top wear in red. Party wear',
  ];
  hideadmin: boolean = true;
  hideCollection: boolean = true;
  queryParamsDetails: any;
  userDetailsParams: any;
  productDetails: any;
  product_id: any;
  private _id: any;
  shareLink: any;
  devurl: any = 'http://13.234.216.18:3000';
  userName: any;
  realatedProduct: any;
  onLoadPage = new EventEmitter();
  token: string | null;
  userType: string | null;
  discountCode: any;
  saved: any = false;
  discountError: any;
  productError: any;
  catergoryType: any;
  discountcode: boolean = false;
  details: any = [];
  ProductImage: any;
  Productimg: any;
  Producttitle: any;
  ProductDescription: any;
  session: any;
  sessionType: any;
  image: any;
  productId: any;
  collectionList: any;
  subs: any;
  isBrowser: boolean;
  userDialog: any;
  token1: any;

  constructor(
    private router: Router,
    public service: CollectionService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private _renderer2: Renderer2,
    private metaTagService: Meta,
    private TitleService: Title,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private _document: Document
  ) {
    this.product_id = this.activatedRoute.snapshot.params.id;
    console.log('====================================');
    console.log(this.product_id);
    console.log('====================================');
    this._id = this.product_id?.split('-').pop();
    this.userDetailsParams = this.activatedRoute.snapshot.params.title;
    this.shareLink = `${environment.SHARE_URL}/p/${this.userDetailsParams}/${this.product_id}`;
    this.subs = this.service.ProductListDetails(this._id).subscribe((res) => {
      // console.log(res);
      this.productDetails = res.data;
      this.collectionList = res.collection;
      // this.session = res.session.isLogedIn;
      // this.sessionType = res.session.userType;
      console.log('====================================');
      console.log('------------>', this.productDetails);
      console.log('====================================');

      if (res.data[0].productImage != '') {
        this.ProductImage = res.data[0].productImage[0]?.url;
      } else {
        this.ProductImage = res.data[0].image;
      }
      this.Producttitle = res.data[0].productTitle;
      this.ProductDescription = res.data[0].description;
      this.metaTagService.updateTag({
        name: 'og:title',
        property: 'content',
        content: this.Producttitle,
      });

      this.TitleService.setTitle(this.Producttitle);
      this.metaTagService.updateTag({
        name: 'og:description',
        property: 'content',
        content: this.ProductDescription,
      });
      this.metaTagService.updateTag({
        name: 'og:image',
        property: 'content',
        content: this.ProductImage,
      });
      this.metaTagService.updateTag({
        name: 'og:url',
        property: 'content',
        content: this.shareLink,
      });

      console.log(this.ProductImage);
      console.log(this.Producttitle);
      console.log(this.ProductDescription);
      // this.details.push(this.productDetails);
      // this.catergoryType = res.data[0].categories[0]?.name;
      this.realatedProduct = res?.collectionProducts[0]?.product;

      console.log(this.realatedProduct);
    });
    this.isBrowser = isPlatformBrowser(platformId);
    if (isPlatformBrowser(platformId)) {
      console.log('Collection Platform browser', platformId);
    }
    if (isPlatformServer(platformId)) {
      console.log('Collection Platform server', platformId);
    }
  }

  ngOnInit() {
    this.token1 = this.activatedRoute.snapshot.queryParams.token;
    if (this.token1) {
      localStorage.setItem('token', this.token1);
    }
    if (this.service.getToken() == '') {
      this.sessionType = 'home';
    }
    if (this.service.getToken() != '') {
      console.log('login sucess fully');
      this.service.loginUserDetails().subscribe((result) => {
        this.sessionType = result.data.userType;
        this.userDialog = result.data.onbording;
        if (this.userDialog == false) {
          this.dialog.open(UseronboardingflowDialogComponent, {
            disableClose: true,
            data: {},
          });
        }
        console.log('product page', this.sessionType);
      });
    }
    if (isPlatformServer(this.platformId)) {
      this.service.userDiscountProductGet().subscribe((result) => {});

      this.hideCollection = true;
      this.hideadmin = true;
      this.hideComponent = false;
      this.hideNotifications = false;
    }
  }
  @ViewChild('myTooltip') myTooltip: MatTooltip;
  @ViewChild('myTooltip') myTooltipCode: MatTooltip;
  @ViewChild('myTooltip') matTooltipShare: MatTooltip;

  // PinterestBtn() {
  //   let obj = {
  //     link: 'https://www.pinterest.com/',
  //     title: 'Pin',
  //     description:
  //       'Discover recipes, home ideas, style inspiration and other ideas to try.',
  //     dominant_color: '#6E7874',
  //     alt_text: 'Pinterest logo',
  //     board_id: '870180027940512316',
  //     data: 'iVBORw0KGgoAAAANSUhEUgAAAZAAAAGQCAYAAACAvzbMAAAgAElEQVR4nOy9f7RlR3Xf+dm3X79udbe6W60fFkLIksAMxloaRVEIYRjsOCCwgp4FcWziX8txQsssmxDH8XgYO0uL5eXlZYTDchwW6ef4V0gyjD0B3BCCgTgxJh5Hi7ExZgghQogG/aDVkvqX+sfr9+6eP07tql11zn3d/V7/uN13f0F97z2nqnadc9/d',
  //   };
  //   this.service.PinterestApi(obj).subscribe((response) => {
  //     console.log(response);
  //   });
  // }

  //issue with ssr

  displayTooltip(id: any, title: any) {
    if (this.service.getToken() == '') {
      this.service.ProductListDetails(this._id).subscribe((res) => {
        // console.log(res);
        this.image = res.data[0].productImage[0]?.url;
        console.log(this.image);
      });
      this.dialog.open(NoAuthSignupComponent, {
        data: {
          title: 'view discount code',
          image: this.image,
          ShareLink: `p/${title.split(' ').join('-')}/id-${id}`,
          disableClose: true,
        },
      });
    } else {
      this.myTooltip.disabled = false;
      this.myTooltip.show();
      setTimeout(() => {
        this.myTooltip.disabled = true;
      }, 1000);
    }
  }

  // toDataURL(url: any, callback: any) {
  //   var xhr = new XMLHttpRequest();
  //   xhr.onload = function () {
  //     var reader = new FileReader();
  //     reader.onloadend = function () {
  //       callback(reader.result);
  //     };
  //     reader.readAsDataURL(xhr.response);
  //   };
  //   xhr.open('GET', url);
  //   xhr.responseType = 'blob';
  //   xhr.send();
  // }

  displayTooltipForSaveCoupon() {
    if (this.service.getToken() == '') {
      this.dialog.open(NoAuthSignupComponent, {
        disableClose: true,
      });
    } else {
      this.matTooltipShare.disabled = false;
      this.matTooltipShare.show();
      setTimeout(() => {
        this.matTooltipShare.disabled = true;
      }, 1000);
    }
  }
  showRealtedproductsBtn(title: any, id: any) {
    this.service.ProductListDetails(id).subscribe((res) => {
      this.productDetails = res.data;
      this.realatedProduct = res.collectionProducts[0].product;

      this.ProductImage = res.data[0].productImage[0]?.url;
      this.Producttitle = res.data[0].productTitle;
      this.ProductDescription = res.data[0].description;
      console.log(this.ProductImage);
      console.log(this.Producttitle);
      console.log(this.ProductDescription);
      this.onLoadPage.emit();

      this.shareLink = `${environment.SHARE_URL}/p/${title
        .split(' ')
        .join('-')}/id-${id}`;
      // window.scroll({
      //   top: 0,
      //   left: 0,
      //   behavior: 'smooth',
      // });
    });
    this.router.navigate([`p/${title.split(' ').join('-')}/id-${id}`]);
  }
  collection(title: any, id: any) {
    this.router.navigate([`/c/${title.split(' ').join('-')}/id-${id}`]);
  }
  channel(title: any, id: any) {
    this.router.navigate([`${title.split(' ').join('-')}`]);
  }
  saveForlater(code: any, id: any) {
    if (this.service.getToken() == '') {
      console.log(this.image);
      // this.service.ProductListDetails(this._id).subscribe((res) => {
      //   // console.log(res);
      //   this.image = res.data[0].productImage[0]?.url;
      //   console.log(this.image);
      // });
      this.dialog.open(NoAuthSignupComponent, {
        data: {
          title: 'view Bookmark this discount code',
          image: this.image,
          disableClose: true,
        },
      });
    } else {
      this.discountCode = code;
      this.productId = id;
      this.bookmarkProduct();
    }
  }
  saveProductBookmark(id: any, title: any) {
    if (this.service.getToken() == '') {
      this.dialog.open(NoAuthSignupComponent, {
        data: {
          title: 'Bookmark this product',
          image: this.image,
          ShareLink: `/p/${title.split(' ').join('-')}/id-${id}`,
          disableClose: true,
        },
      });
    } else {
      console.log('saveProductBookmark', id);
      let obj = {
        productId: id,
      };
      this.service.userProductBookmark(obj).subscribe(
        (result) => {
          this.saved = true;
          setTimeout(() => {
            this.saved = false;
          }, 3000);
        },
        (error) => {
          this.productError = error.error.message;
          setTimeout(() => {
            this.productError = '';
          }, 3000);
        }
      );
    }
  }
  saveForlaterProducts(id: any, title: any) {
    console.log('p', id);
    if (this.service.getToken() == '') {
      this.dialog.open(NoAuthSignupComponent, {
        data: {
          title: 'Bookmark this product',
          ShareLink: `/p/${title.split(' ').join('-')}/id-${id}`,
          disableClose: true,
        },
      });
    } else {
      console.log('saveProductBookmark', id);
      let obj = {
        productId: id,
      };
      this.service.userProductBookmark(obj).subscribe(
        (result) => {
          // this.saved = true;
          // setTimeout(() => {
          //   this.saved = false;
          // }, 3000);
        },
        (error) => {
          this.productError = error.error.message;
          // setTimeout(() => {
          //   this.productError = '';
          // }, 3000);
        }
      );
    }
  }

  bookmarkProduct() {
    let obj = {
      productId: this.productId,
      discountCode: this.discountCode,
    };
    this.service.userDiscountProduct(obj).subscribe(
      (res) => {
        this.discountcode = true;
        setTimeout(() => {
          this.discountcode = false;
        }, 2000);
      },
      (error) => {
        this.discountError = error.error.message;
        setTimeout(() => {
          this.discountError = '';
        }, 3000);
      }
    );
  }
  shareIt() {
    this.dialog.open(ShareproductLinkDialogComponent, {
      data: {
        title: this.shareLink,
        disableClose: true,
      },
    });
  }
  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
