import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AdminService } from 'src/app/_services/admin.service';
import { CollectionService } from 'src/app/_services/collection.service';
import { PublisheronboardingModelComponent } from '../publisheronboarding-model/publisheronboarding-model.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-publisher-onboarding-dialog',
  templateUrl: './publisher-onboarding-dialog.component.html',
  styleUrls: ['./publisher-onboarding-dialog.component.css'],
})
export class PublisherOnboardingDialogComponent implements OnInit {
  items: any[] = [
    { name: 'Publish your first blog', button: 'How to publish' },
    { name: 'Share it with your auidence', button: 'How to share' },
    { name: 'Get more traffic', button: '' },
    { name: 'Monetize and earn money', button: 'How much can you earn' },
  ];
  constructor(
    private dialog: MatDialog,
    private service: CollectionService,
    private Publisherservice: AdminService,
    private router: Router
  ) {}

  ngOnInit(): void {
    console.log('onboardin dialog');
  }
  GetStarted() {
    const dialogRef = this.dialog.open(PublisheronboardingModelComponent);

    dialogRef.afterClosed().subscribe(() => {
      this.service.triggerRefresh();
    });
  }
}
