export const environment = {
  AUTH_URL: 'https://auth.summarizly.ai/v1/',
  COLLECTION_URL: 'https://product.summarizly.ai/v1/',
  USER: 'https://user.summarizly.ai/v1/',
  SHARE_URL: 'https://summarizly.ai',
  URL: 'https://summarizly.ai',
  ADMIN_URL: 'https://admin.summarizly.ai/v1/admin/',
  EMAILAPIURL: 'https://app.loops.so/api/v1/contacts/create',
  MIX_PANEL_ID: '0e14cc5b81a73d6e12e403f8a2492d4d',
  CLEVERTAP: true,
  APICALL: false,
  production: true,
};
