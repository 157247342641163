import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-invite-succefully',
  templateUrl: './invite-succefully.component.html',
  styleUrls: ['./invite-succefully.component.css'],
})
export class InviteSuccefullyComponent implements OnInit {
  title: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dilaog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.title = this.data.name;
  }

  close() {
    this.dilaog.closeAll();

    const queryParams = { active: false };

    // Update the query parameters
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: queryParams,
      queryParamsHandling: 'merge', // This ensures that existing query parameters are preserved
    });
  }
}
