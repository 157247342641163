<app-creatornavbar></app-creatornavbar>

<div class="main-section sidenavbar-header mt-md-3" *ngIf="showDashboard">
  <mat-drawer-container class="example-container">
    <mat-drawer mode="side" [opened]="sidebarOpen">
          <app-publishersidenavbar></app-publishersidenavbar>

    </mat-drawer>
   
    <mat-drawer-content [class.open]="sidebarOpen === true" [class.closed]="sidebarOpen === false">
      
      <div class="router">
        <router-outlet *ngIf="showDashboard"></router-outlet>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
</div>
<router-outlet *ngIf="showLogin"></router-outlet>

<!-- <app-sidenavbar></app-sidenavbar> -->
