<div class="main mt-md-5 pt-md-5">
    <div class="publisher_onboarding_section">
        <div class="onboaring_header Your_blog">
           <h6>Looks like you haven't published anything yet.</h6>
        </div>
           <div class="">
             <div class="items_Onboarding d-flex py-4" *ngFor="let value of items">
                 <h5>{{value.name}}</h5>
                  <a class="ml-auto">{{value.button}}</a>
             </div>


             <div class="d-flex justify-content-center mt-md-4">
                <div class="button_publish">
                    <button class="cursor" type="button" (click)="GetStarted()">Publish now</button>
                </div>
                <div class="button_publish">
                    <a class="cursor" type="button">Need Help?</a>
                </div>
             </div>
           </div>
    </div>
</div>