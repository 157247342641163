import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CollectionService } from 'src/app/_services/collection.service';

@Component({
  selector: 'app-edit-channel',
  templateUrl: './edit-channel.component.html',
  styleUrls: ['./edit-channel.component.css'],
})
export class EditChannelComponent implements OnInit {
  ChannelName: any;
  sessionType: string;
  letters: string[] = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
  ];
  objParams: { publisherName: any };
  letterParams: any;
  PublisherName: any;
  searchByLetterName: any;
  count: any;
  optionReset: any = '';
  constructor(private service: CollectionService, private router: Router) {}

  ngOnInit(): void {
    if (this.service.getToken() != '') {
      this.service.loginUserDetails().subscribe((result) => {
        this.sessionType = result.data.userType;
      });
    }
    if (this.service.getToken() == '') {
      // console.log('not-login');
      this.sessionType = 'home';
    }
    this.service.channelList().subscribe((res) => {
      this.ChannelName = res.data;
      this.count = res.dataCount;
      console.log(this.ChannelName);
    });
  }
  seachPublisher(e: any) {
    console.log(e.target.value);
    this.PublisherName = e.target.value;
    this.searchByLetterName = '';
    // return;
    this.objParams = {
      publisherName: e.target.value,
    };
    this.service.channelListSearch(this.objParams).subscribe((res) => {
      this.ChannelName = res.data;
      this.count = res.dataCount;

      console.log(this.ChannelName);
    });
  }
  reset() {
    this.optionReset = '';
    this.letterParams = {
      letter: (this.searchByLetterName = ''),
      publisherName: (this.PublisherName = ''),
    };
    this.service.channelListSearch(this.letterParams).subscribe((res) => {
      this.ChannelName = res.data;
      this.count = res.dataCount;

      console.log(this.ChannelName);
    });
  }
  searchByLetter(e: any) {
    console.log(e);
    // return;
    this.searchByLetterName = e;
    this.PublisherName = '';
    this.letterParams = {
      letter: e,
    };
    this.service.channelListSearch(this.letterParams).subscribe((res) => {
      this.ChannelName = res.data;
      this.count = res.dataCount;

      console.log(this.ChannelName);
    });
  }
  updateChannel(item: any) {
    const queryParams = { name: `${item.name}`, id: `${item._id}` };
    this.router.navigate(['updateChannel'], { queryParams });
  }
}
