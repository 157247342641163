import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import * as Highcharts from 'highcharts';
import { takeUntil } from 'rxjs/operators';
import { AdminService } from 'src/app/_services/admin.service';
export interface PeriodicElement {
  summaryid: any;
  summarytitle: any;
  Commentdetails: any;
  Spam: any;
}

@Component({
  selector: 'app-publisher-subscribers',
  templateUrl: './publisher-subscribers.component.html',
  styleUrls: ['./publisher-subscribers.component.css'],
})
export class PublisherSubscribersComponent implements OnInit {
  highcharts = Highcharts;
  chartOptionsBar: any;
  params: { type: string };
  subscribers: any;
  subscriberData: any;
  subscriberDataMonth: any = [];
  subscriberDataCount: any = [];
  Language: any = ['month', 'year'];
  language: any;
  totalSummary: any;
  displayedColumns: string[] = [
    'summaryid',
    'summarytitle',
    'Commentdetails',
    'Spam',
  ];
  ELEMENT_DATA: Element[] = [];
  pageSize: any = 10;
  currentPage: any = 0;
  dataSource = new MatTableDataSource(this.ELEMENT_DATA);
  loader: boolean;
  userQuery: any;
  constructor(private adminService: AdminService) {}

  ngOnInit() {
    this.totalSummary = localStorage.getItem('publisherBlogsCount');
    this.params = {
      type: 'month',
    };
    this.adminService
      .PublisherAnalytics(this.params)
      .subscribe((chatData: any) => {
        console.log(chatData);
        this.subscribers = chatData.TotalSubscriber;
        // this.loader = false;
        this.ELEMENT_DATA = chatData.subscriberList;
        this.subscriberData = chatData.SubscriberData;
        this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
        this.subscriberData.forEach((item: any) => {
          this.subscriberDataMonth.push(item.name);
          this.subscriberDataCount.push(item.count);
        });
        this.chartOptionsBar = {
          chart: {
            type: 'column',
          },
          title: {
            text: '',
          },
          credits: {
            enabled: true,
          },
          legend: { enabled: false },
          //  subtitle: {
          //     text: "Source: WorldClimate.com"
          //  },
          xAxis: {
            gridLineWidth: 0,
            minorGridLineWidth: 0,
            lineWidth: 0,
            lineColor: 'transparent',
            title: {
              text: '',
            },
            labels: {
              style: {
                color: '#5A657F',
                fontSize: 12,
                fontWeight: 600,
                fontFamily: 'Inter',
              },
            },
            categories: this.subscriberDataMonth,
          },
          yAxis: {
            gridLineWidth: 0,
            minorGridLineWidth: 0,
            title: {
              text: '',
            },
            labels: {
              format: '{value}',
              style: {
                color: '#425466',
                fontSize: 12,
                fontWeight: 600,
                fontFamily: 'Inter',
              },
            },
          },
          //  tooltip: {
          //     valueSuffix:" °C"
          //  },
          plotOptions: {
            column: {
              dataLabels: {
                enabled: true,
              },
            },
          },

          colors: ['#0a090a', ''],
          series: [
            {
              name: '',
              data: this.subscriberDataCount,
            },
          ],
        };
      });
  }

  langugeChange(e: any) {
    this.language = e.target.value;

    this.subscriberDataMonth = [''];
    this.subscriberDataCount = [''];
    this.params = {
      type: this.language,
    };
    this.adminService
      .PublisherAnalytics(this.params)
      .subscribe((chatData: any) => {
        console.log(chatData);

        this.subscribers = chatData.TotalSubscriber;

        this.subscriberData = chatData.SubscriberData;
        this.subscriberData.forEach((item: any) => {
          if (this.language == 'month') {
            this.subscriberDataMonth.push(item.name);
            this.subscriberDataCount.push(item.count);
          } else {
            this.subscriberDataMonth.push(item.year);
            this.subscriberDataCount.push(item.count);
          }
        });
        this.chartOptionsBar = {
          chart: {
            type: 'column',
          },
          title: {
            text: '',
          },
          credits: {
            enabled: true,
          },
          legend: { enabled: false },
          //  subtitle: {
          //     text: "Source: WorldClimate.com"
          //  },
          xAxis: {
            gridLineWidth: 0,
            minorGridLineWidth: 0,
            lineWidth: 0,
            lineColor: 'transparent',
            title: {
              text: '',
            },
            labels: {
              style: {
                color: '#5A657F',
                fontSize: 12,
                fontWeight: 600,
                fontFamily: 'Inter',
              },
            },
            categories: this.subscriberDataMonth,
          },
          yAxis: {
            gridLineWidth: 0,
            minorGridLineWidth: 0,
            title: {
              text: '',
            },
            labels: {
              format: '{value}',
              style: {
                color: '#425466',
                fontSize: 12,
                fontWeight: 600,
                fontFamily: 'Inter',
              },
            },
          },
          //  tooltip: {
          //     valueSuffix:" °C"
          //  },
          plotOptions: {
            column: {
              dataLabels: {
                enabled: true,
              },
            },
          },

          colors: ['#0a090a', ''],
          series: [
            {
              name: 'subscribers',
              data: this.subscriberDataCount,
            },
          ],
        };
      });
  }
}
