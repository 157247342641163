import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PublisherPortalRoutingModule } from './publisher-portal-routing.module';
import { MaterialModule } from '../material.module';
import { ReactiveFormsModule } from '@angular/forms';
import { UsernavbarModule } from '../usernavbar/usernavbar.module';
import { PublisherhomepageComponent } from './publisherhomepage/publisherhomepage.component';
import { PublishersidenavbarComponent } from './publishersidenavbar/publishersidenavbar.component';
import { PublisherBlogsComponent } from './publisher-blogs/publisher-blogs.component';
import { PublisherContentComponent } from './publisher-content/publisher-content.component';
import { PublisherDashboardComponent } from './publisher-dashboard/publisher-dashboard.component';
import { PublisherAnalyticsComponent } from './publisher-analytics/publisher-analytics.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { PublisherCommentsComponent } from './publisher-comments/publisher-comments.component';
import { PublisherSubscribersComponent } from './publisher-subscribers/publisher-subscribers.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { PublisherOnboardingDialogComponent } from './publisher-onboarding-dialog/publisher-onboarding-dialog.component';
import { PublisherOnboardComponent } from './publisher-onboard/publisher-onboard.component';
import { PublisherProfileComponent } from './publisher-profile/publisher-profile.component';
@NgModule({
  declarations: [
    PublisherhomepageComponent,
    PublishersidenavbarComponent,
    PublisherBlogsComponent,
    PublisherContentComponent,
    PublisherDashboardComponent,
    PublisherAnalyticsComponent,
    PublisherCommentsComponent,
    PublisherSubscribersComponent,
    PublisherOnboardingDialogComponent,
    PublisherOnboardComponent,
    PublisherProfileComponent,
  ],
  imports: [
    CommonModule,
    UsernavbarModule,
    PublisherPortalRoutingModule,
    MaterialModule,
    ReactiveFormsModule,
    HighchartsChartModule,
    NgxPaginationModule,
  ],
})
export class PublisherPortalModule {}
