import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SharelinkComponent } from '../sharelink/sharelink.component';
import { LoginService } from '../_services/login.service';

@Component({
  selector: 'app-homenavbar',
  templateUrl: './homenavbar.component.html',
  styleUrls: ['./homenavbar.component.css'],
})
export class HomenavbarComponent implements OnInit {
  constructor(
    private router: Router,
    private loginService: LoginService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {}
  Singin() {
    this.router.navigate(['/@ycombinator']);
  }
  summary() {
    this.router.navigate(['/SummaryFeed']);
  }
  publisherLogin() {
    this.router.navigate(['/publisher_login']);
  }
  share() {
    this.dialog.open(SharelinkComponent, {
      data: {},
      disableClose: true,
    });
  }
}
