

<div class="sidenavbar-heade">
<!-- <div class='row'>
    <div class="col-md-2">
    <app-sidenavbar></app-sidenavbar>

    </div> -->

    <app-loader *ngIf="loader"></app-loader>
     <app-loader *ngIf="loaderexport"></app-loader>
    <div class="summary-header ml-3 mt-md-3">
       
     <div class="d-flex justify-content-between">
         <div class="d-flex">
            <button class="cursor export" (click)="export()">Download csv <img src="../../../assets/images/d-jj.png" width="20px"></button>
             
        </div>
          <div>
            <button class="cursor xml" (click)="XML()">Download XML <img src="../../../assets/images/d-jj.png" width="30px"></button>
             
        </div>
        <div class="d-flex ml-auto  language-header">
            <p>Topic</p>
            <select (change)="topicChange($event)"  class="selectpicker" >
              <option value="">select</option>
                <option *ngFor="let item of catergoryList" value="{{item.name}}">{{item.name}}</option>
            </select>
        </div>
        <!-- <div class="service-list-drop-down mr-2">
        <mat-form-field appearance="fill">
          <div class="arrow-up"></div>
          <span matPrefix>Topic:&nbsp;</span>
          <mat-icon matSuffix 
          ><img
            src="../../assets/images/arrow-1.svg"
            class="calendra-img"
        /></mat-icon>
          <mat-select
            placeholder="Select"
            multiple
            disableOptionCentering
            (selectionChange)="statusEvent($event)"
            panelClass="myPanelDonationrole"
            #closeSerivce
          >  
            <mat-option
              class="services-view"
              *ngFor="let viewService of catergoryList"
              [value]="viewService.name"
              >{{ viewService.name}}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div> -->
        <div class="d-flex   language-header ml-4">
            <p>language</p>
            <select (change)="langugeChange($event)"  class="selectpicker" >
              <option value="">select</option>
                <option *ngFor="let item of Language" value="{{item}}">{{item}}</option>
            </select>
        </div>
        <div class="month d-flex  language-header">
           <p>Date</p>
            <select (change)="dateChange($event)"  class="selectpicker">
                <option value="">select</option>
                <option value="today">Today</option>
                <option value="week">Week</option>
                <option value="month">Month</option>
            </select>
        </div>
        
     </div>

     <div class="card-section my-md-4 d-flex justify-content-between">
        <div class="card mr-md-2" *ngFor="let item of items">
            <div class="card-body">
                <p>{{item.name}}</p>
                <h3>{{item.number}}</h3>
            </div>
        </div>
     </div>
   <p class="succesfully" *ngIf="collectionDelete">Collection deleted successfully</p>
     <div class="table-data">

        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

  <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

  <!-- Position Column -->
  <ng-container matColumnDef="sid">
    <th mat-header-cell *matHeaderCellDef> sid </th>
    <td mat-cell *matCellDef="let element"> {{element._id}} </td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="URL">
    <th mat-header-cell *matHeaderCellDef> URL </th>
    <td mat-cell *matCellDef="let element"> <a target="_blank" [href]="View(element.summaryName, element._id)">View</a> </td>
  </ng-container>
  <!-- Name Column -->
  <ng-container matColumnDef="Thumbali">
    <th mat-header-cell *matHeaderCellDef> Thumbali </th>
    <td mat-cell *matCellDef="let element"> <img class="t-img" src="{{element.thumbNail}}"> </td>
  </ng-container>

  <!-- Weight Column -->
  <ng-container matColumnDef="summarytitle">
    <th mat-header-cell *matHeaderCellDef> summary title </th>
    <td mat-cell *matCellDef="let element" width="26%"> {{element.summaryName}} </td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="Publishedby">
    <th mat-header-cell *matHeaderCellDef> Publishedby </th>
    <td mat-cell *matCellDef="let element"> {{element?.reader[0]?.userType}} </td>
  </ng-container>
  <!-- Weight Column -->
  <ng-container matColumnDef="channel">
    <th mat-header-cell *matHeaderCellDef> channel </th>
    <td mat-cell *matCellDef="let element"> {{element.publisherName}} </td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="views">
    <th mat-header-cell *matHeaderCellDef> views </th>
    <td mat-cell *matCellDef="let element"> {{element.summaryClickCount ? element.summaryClickCount : 'N/A'}} </td>
  </ng-container>
  <!-- Weight Column -->
  <ng-container matColumnDef="clicks">
    <th mat-header-cell *matHeaderCellDef> clicks </th>
    <td mat-cell *matCellDef="let element"> {{element.summaryClickCount ? element.summaryClickCount : 'N/A'}} </td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef> actions </th>
    <td mat-cell *matCellDef="let element">
       <div class="d-flex">
        <div class="edit-img">
           <img  src="../../../assets/images/e-3.png" class="cursor" (click)="editCollection(element)">
        </div>
        <div class="toggle" *ngIf="element.isActive">
          <label class="toggle">
  <input type="checkbox" id="toggleButton"  [checked]="element.isActive" (change)="activetoggleChanged($event,element)">
  <span class="slider" [ngStyle]="{'background-color': getBackgroundColor(element)}"></span>
</label>
        </div>
        <div class="toggle" *ngIf="!element.isActive">
          <label class="toggle">
  <input type="checkbox" id="toggleButton" [checked]="element.isActive" (change)="inActivetoggleChanged($event,element)">
  <span class="slider"></span>
</label>
        </div>
         <div class="edit-img">
           <img class="cursor" src="../../../assets/images/delete.png" (click)="deleteCollection(element._id)">
        </div>
       </div>
       </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
   <p class="error-view" *ngIf="this.totalSummaryData == ''">No summaries found </p>

<mat-paginator (page)="pageChanged($event)" [length]="totalSummary" [pageIndex]="currentPage" [pageSize]="pageSize"  [pageSizeOptions]="pageSizeOptions" aria-label="Select page of users"></mat-paginator>
     </div>
</div>
</div>
