<div class="main">
<div class="container">
    <div class="mb-md-4 close-btn">
      <button (click)="close()"><img src="../../assets/images/close.png" class="close-img"></button>
    </div>
<div class="d-md-flex">
 <div class="login-follow">
          <p>Login or signup is required to follow</p>
            <p type="submit">Signup for free</p>
                    <div class="diloag-btn text-center">
                         <button type="submit" class="mt-md-3 google-btn">
                              <a [href]="url | safePipe">
                          <img src="../../assets/images/google.png" class="google mr-2">Continue with Google
                        </a>
                        </button> 
                          
                        <button type="submit" class="mt-md-3 email-btn" (click)="signup()">Continue with email</button> 
                       
                    </div>
                     <h6>Already have an account? <span class="pointer" (click)="login()">Login</span></h6>
    </div>

    <div>
        <!-- <img src="../../assets/images/pro-1.jpg" class="dialog-img"> -->
        <img [src]="collectionImage" class="dialog-img">
    </div>
</div>
   
</div>
</div>
