import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { Router } from '@angular/router';

import { environment } from 'src/environments/environment';
import { ShareproductLinkDialogComponent } from '../shareproduct-link-dialog/shareproduct-link-dialog.component';
import { CollectionService } from '../_services/collection.service';

@Component({
  selector: 'app-creator-create-product',
  templateUrl: './creator-create-product.component.html',
  styleUrls: ['./creator-create-product.component.css'],
})
export class CreatorCreateProductComponent implements OnInit {
  devurl: any = 'http://13.234.216.18:3000';
  hideComponent: boolean = false;
  hideNotifications: boolean = true;
  hideadmin: boolean = true;
  linkProduct: boolean = true;
  addProduct: boolean = false;
  loginUSerSuccess: boolean = false;
  notLoginUSerSection: boolean = false;
  signUpUser: boolean = false;
  signUpuserFinalStep: boolean = false;
  submitttedUrl: boolean = false;
  submiitedDetails: boolean = false;
  showDiscount: boolean = false;
  loaderProduct: boolean = false;
  selectedFileEvent: any;
  fileNameEvent: any;
  format: any;
  url: any = null;
  productUrl: FormGroup;
  productDetails: FormGroup;
  categoryList: any;
  collectionValue: any;
  collection: any = '';
  collectionTagsValue: any = '';
  collectiontagsvalues: any = '';
  categoryLink: any;
  userName: any;
  id: any;
  loader: boolean = false;
  subCategoryList: any;
  productType: any = [
    'apps i promote',
    'top books',
    'my deals',
    'my gear i use',
    'brand deals',
    'recommendations',
  ];

  @ViewChild('loginRef', { static: true }) loginElement: ElementRef;
  productError: any;
  ChannelName: any;
  multipleCatergory: any;
  productImage: any;
  params: any;
  productImageUrl: any;
  productTitle: any;
  collectiontitle: any = [];
  productTitleView: any = [
    'Kingone Upgraded Stylus Pen, iPad Pencil, Ultra High Precision & Sensitivity, Palm Stylus  ',
    'Kingone Upgraded Stylus Pen, iPad Pencil, Ultra High Precision & Sensitivity, Palm Stylus  ',
    'Kingone Upgraded Stylus Pen, iPad Pencil, Ultra High Precision & Sensitivity, Palm Stylus  ',
    'Kingone Upgraded Stylus Pen, iPad Pencil, Ultra High Precision & Sensitivity, Palm Stylus  ',
    'Kingone Upgraded Stylus Pen, iPad Pencil, Ultra High Precision & Sensitivity, Palm Stylus  ',
  ];
  title: any;
  linkMypreview: any;
  shortUrl: any;
  constructor(
    private fb: FormBuilder,
    private collectionServices: CollectionService,
    private router: Router,
    private dialog: MatDialog
  ) {
    this.productUrl = this.fb.group({
      AffiliateLink: ['', [Validators.required]],
    });
    this.productDetails = this.fb.group({
      description: ['', [Validators.required]],
      productTitle: ['', [Validators.required]],
      productImage: [''],
      channelName: [''],
      affiliatePartnerName: [''],
      price: [''],
      categoryId: ['', [Validators.required]],
      couponCode: [''],
      subCategoryName: [''],
      productType: ['', [Validators.required]],
      Keywords: [''],
      tag: [''],
      Summery: [''],
    });
  }

  ngOnInit(): void {
    this.productDetails.controls['productType'].setValue('my deals');
    // this.collectionServices.channelList().subscribe((res) => {
    //   this.ChannelName = res.data;
    // });
    this.collectionServices.creatorChannel().subscribe((res) => {
      this.ChannelName = res.data;
      console.log(this.ChannelName);
      this.productDetails.controls['channelName'].setValue(res.data[0]?.name);
    });

    this.collectionServices.loginUserDetails().subscribe((result) => {
      this.userName = result.data.name;
      localStorage.setItem('userName', this.userName);
    });
    this.collectionServices.caterogoryList().subscribe((res) => {
      console.log(res);
      this.categoryList = res.data;
    });
  }
  // googleSDKLoaded:any = []

  changeRoute(event: MatRadioChange) {
    console.log(event.value);
    if (event.value === '1') {
      this.showDiscount = true;
    }
    if (event.value === '0') {
      this.showDiscount = false;
    }
  }
  keyWordsCollection(e: any) {
    console.log(e.target.value);
    this.collectionValue = e.target.value;

    if (
      this.collectionValue != '' ||
      this.collectionValue != null ||
      this.collectionValue != undefined
    ) {
      this.collection = JSON.stringify(this.collectionValue.split(','));
    } else {
      this.collection = '';
    }
    console.log(this.collection);
  }
  categoryEvent(e: any) {
    console.log(e.value);
    this.multipleCatergory = e.value;
  }
  tagsCollection(e: any) {
    console.log(e.target.value);
    this.collectionTagsValue = e.target.value;
    if (this.collectionTagsValue != null || this.collectionTagsValue == '') {
      this.collectiontagsvalues = JSON.stringify(
        this.collectionTagsValue.split(',')
      );
    } else {
      this.collectionTagsValue = '';
    }
    console.log(this.collectionTagsValue.split(','));
  }
  get f(): { [key: string]: AbstractControl } {
    return this.productUrl.controls;
  }
  get c(): { [key: string]: AbstractControl } {
    return this.productDetails.controls;
  }

  Continue() {
    if (this.productUrl.invalid) {
      this.submitttedUrl = true;
    } else {
      this.submitttedUrl = false;
      this.linkProduct = false;
      this.addProduct = true;
    }
  }
  publishLink() {
    if (this.productUrl.invalid) {
      this.submitttedUrl = true;
    } else {
      this.submitttedUrl = false;
      this.loaderProduct = true;

      this.params = {
        key: '8ae171ef2257d9fbc1a5ae031cf06980',
        q: this.productUrl.value.AffiliateLink,
        site_name: this.productUrl.value.AffiliateLink,
      };
      this.collectionServices.linkPreviewDetails(this.params).subscribe(
        (res) => {
          console.log(res);
          console.log(res.site_name);
          this.loaderProduct = false;
          this.productImageUrl = res.image;
          this.productDetails.controls['productTitle'].setValue(res.title);
          this.productDetails.controls['description'].setValue(res.description);
          this.productDetails.controls['affiliatePartnerName'].setValue(
            res.site_name
          );
          this.linkProduct = false;
          this.addProduct = true;
        },
        (error) => {
          console.log(error);
          this.linkMypreview =
            'Unable to fetch product details automatically. Please click continue to add manually';

          // setTimeout(() => {
          //   this.linkMypreview = '';
          // }, 6000);
        }
      );
    }
  }
  addProductDetails() {
    console.log(this.productDetails);
    if (this.productDetails.invalid) {
      this.submiitedDetails = true;
    } else {
      this.loader = true;
      if (this.productDetails.value.couponCode == null) {
        this.productDetails.value.couponCode = '';
      }
      if (this.productDetails.value.affiliatePartnerName == null) {
        this.productDetails.value.affiliatePartnerName = '';
      }
      if (this.productDetails.value.price == null) {
        this.productDetails.value.price = '';
      }
      if (this.productImageUrl == null) {
        this.productImageUrl = '';
      }

      const formData = new FormData();
      formData.append(
        'productImage',
        this.productDetails.controls['productImage'].value
      );
      formData.append('image', this.productImageUrl);
      formData.append(
        'productTitle',
        this.productDetails.value.productTitle.replace(
          /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
          ''
        )
      );
      formData.append('channelName', this.productDetails.value.channelName);
      formData.append('description', this.productDetails.value.description);
      formData.append(
        'affiliatePartnerName',
        this.productDetails.value.affiliatePartnerName
      );
      formData.append('price', this.productDetails.value.price);
      formData.append('categoryId', JSON.stringify(this.multipleCatergory));
      formData.append('AffiliateLink', this.productUrl.value.AffiliateLink);
      formData.append('couponCode', this.productDetails.value.couponCode);
      formData.append('productType', this.productDetails.value.productType);
      formData.append('Keywords', this.collection);
      formData.append('tag', this.collectiontagsvalues);
      formData.append('Summery', this.productDetails.value.Summery);

      this.collectionServices.createProductCollection(formData).subscribe(
        (response) => {
          this.id = response._id;

          setTimeout(() => {
            this.loader = false;
            console.log(this.categoryLink);
            this.submiitedDetails = false;
            this.addProduct = false;
            this.loginUSerSuccess = true;
            // this.productTitle = this.productDetails.value.productTitle;
            // this.collectiontitle.push(this.productTitle);
            // console.log(this.collectiontitle);

            this.productImage = response?.productImage?.url;

            this.title = this.productDetails.value.productTitle.replace(
              /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
              ''
            );
            console.log(this.title);
            this.categoryLink = `${environment.SHARE_URL}/p/${this.title
              .split(' ')
              .join('-')}/id-${response._id}`;
            this.productDetails.reset();
            let obj = {
              longURL: this.categoryLink,
              productId: this.id,
            };
            this.collectionServices.createShortlinks(obj).subscribe((res) => {
              console.log(res);
              this.shortUrl = res.data.shortLink;
            });
            console.log('url link', this.categoryLink);
          }, 1000);
        },
        (error) => {
          console.log(error.error.message);
          this.productError = error.error.message;
          setTimeout(() => {
            this.productError = '';
          }, 3000);
        }
      );

      console.log(this.productDetails);
    }

    // setTimeout(() => {
    //   this.addProduct = false;
    //   this.loginUSerSuccess = false;
    //   this.notLoginUSerSection = true;
    //   this.signUpUser = true;
    //   this.hideNotifications = false;
    //   this.hideadmin = false;
    // }, 3000);
  }
  copyLink(e: any, text: any) {
    e.textContent = text;
  }
  deleteImage() {
    this.productImageUrl = null;
  }
  publishCollectionLogin() {
    this.router.navigate(['/Collections']);
  }
  publishProdcutLogin() {
    this.linkProduct = true;
    this.loginUSerSuccess = false;
  }
  viewProduct() {
    this.router.navigate([
      `/p/${this.productDetails.value.productTitle.split(' ').join('-')}/id-${
        this.id
      }`,
    ]);
  }
  category(e: any) {
    console.log(e.target.value);
    this.collectionServices
      .subCategoryFilter(e.target.value)
      .subscribe((result) => {
        console.log(result);
        this.subCategoryList = result.data;
      });
  }
  signUp() {
    this.signUpUser = false;
    this.hideNotifications = false;
    this.hideadmin = false;
    this.signUpuserFinalStep = true;
  }
  signUpPublishLink() {
    this.hideNotifications = false;
    this.hideadmin = false;
    this.signUpuserFinalStep = false;
    this.notLoginUSerSection = false;
    this.loginUSerSuccess = true;
  }
  onFileSelectedEvent(event: any): void {
    console.log(this.hideComponent);
    this.hideComponent = false;
    const file = event.target.files && event.target.files[0];
    this.selectedFileEvent = <File>event.target.files[0];
    this.fileNameEvent = event.target.files[0].name;
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.productDetails.controls['productImage'].setValue(file);
    }
    if (file) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      if (file.type.indexOf('image') > -1) {
        this.format = 'image';
      } else if (file.type.indexOf('video') > -1) {
        this.format = 'video';
      }
      reader.onload = (event) => {
        this.url = (<FileReader>event.target).result;
      };
    }
  }
  share() {
    this.dialog.open(ShareproductLinkDialogComponent, {
      data: {
        title: this.categoryLink,

        disableClose: true,
      },
    });
  }
}
