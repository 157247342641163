<app-publisher-onboarding-dialog
  *ngIf="totalSummary == 0"
></app-publisher-onboarding-dialog>

<div class="container" *ngIf="totalSummary != 0">
  <div class="spline text-center mt-md-5">
    <div class="d-flex justify-content-between">
      <div>
        <h5>Subscribers</h5>
        <p>your Summary has a total of {{ subscribers }} subscribers</p>
      </div>

      <div class="d-flex language-header mt-md-4">
        <p>Date</p>
        <select (change)="langugeChange($event)" class="selectpicker">
          <option
            *ngFor="let item of Language"
            [selected]="item == 'month'"
            value="{{ item }}"
          >
            {{ item }}
          </option>
        </select>
      </div>
    </div>
    <highcharts-chart
      [Highcharts]="highcharts"
      [options]="chartOptionsBar"
      style="width: 1000px; height: 500px; display: block"
    ></highcharts-chart>
  </div>

  <div class="table-data">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

      <!-- Position Column -->

      <!-- 
  <ng-container matColumnDef="commentid">
    <th mat-header-cell *matHeaderCellDef> Comment-id </th>
    <td mat-cell *matCellDef="let element" > 
        <p>{{element.summaryComments[0]?._id}}</p>
     </td>
  </ng-container> -->
      <ng-container matColumnDef="summaryid">
        <th mat-header-cell *matHeaderCellDef>Subscriber_name</th>
        <!-- <td mat-cell *matCellDef="let element"> {{element.collectionData[0]._id}} </td> -->
        <td mat-cell *matCellDef="let element">
          <p>{{ element.Subscriber_name }}</p>
        </td>
      </ng-container>

      <!-- Weight Column -->
      <ng-container matColumnDef="summarytitle">
        <th mat-header-cell *matHeaderCellDef>Email</th>
        <td mat-cell *matCellDef="let element">{{ element.Email }}</td>
        <!-- <td mat-cell *matCellDef="let element"> {{element.summarytitle}} </td> -->
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="Commentdetails">
        <th mat-header-cell *matHeaderCellDef>Date_when_subscribed</th>
        <td mat-cell *matCellDef="let element">
          <!-- {{element.comment}} -->
          <!-- <p class="comment">
  {{ showFullText ? element.summaryComments[0].comment : element.summaryComments[0].comment.slice(0, 30) }}
  <span *ngIf="!showFullText" (click)="toggleText(element.summaryComments[0].comment)">... <a>Show more</a></span>
   <span *ngIf="showFullText">
    <a (click)="toggleText(element.summaryComments[0].comment)">Show less</a>
  </span>
</p> -->
          <p class="comment">
            {{ element.Date_when_subscribed }}
          </p>
        </td>
      </ng-container>
      <!-- Weight Column -->
      <ng-container matColumnDef="Spam" class="ml-3">
        <th mat-header-cell *matHeaderCellDef>topics_followed</th>
        <td mat-cell *matCellDef="let element">
          {{ element.topics_followed }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
</div>
