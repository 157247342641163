<app-creatornavbar ></app-creatornavbar>

<div class="container mt-md-5">
    <div class="publisher_onboarding_section">
       <div class="onboarding_section mt-5 pt-5" *ngIf="onboarding">
           <h5>Hello , Vinay</h5>
           <h4>Welcome to Summarizly AI</h4>
            <P class="mt-md-5">Lets get you onboarded in few seconds</P>
       </div>


       <div class="onboaring_topics_section" *ngIf="showTopics">
          <div class="onboaring_topis card">
             <div class="  card-body">
                <h5 class="py-4">What type of content do you publish</h5>
                <div class="row catergoy-buttons">
          <div class="col-md-4 col-sm-3 col-4" *ngFor="let item of categoryList?.slice(1);let i=index;">
       <button #btn (click)="toggleColor(item)" [class.follow-category]="item.isButtonSelected">{{ item.name }}</button>

        </div>
                </div>
                  <div class="publisher_onboaring_button mt-md-4">
                     <button class="cursor" (click)="Continue()">Continue</button>
                  </div>
             </div>
          </div>
       </div>


       <div class="select_publisher_topics" *ngIf="gender">

        <div class="onboaring_topis card">
             <div class="  card-body">
                 
                <h6>who would be potential audience</h6>
<div  class="d-flex justify-content-center gender_select">
  <button *ngFor="let option of options"
    type="button"
    [ngClass]="{'selected': selectedOption === option}"
    (click)="selectOption(option)"
  >
    {{ option }}
  </button>
</div>
                  <div class="publisher_onboaring_button singUp_button_p mt-md-4">
                     <button class="cursor" (click)="singup()">Complete Signup</button>
                  </div>
             </div>
          </div>
          
       </div>
    </div>
</div>

