import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CollectionService } from 'src/app/_services/collection.service';

@Component({
  selector: 'app-usercollection',
  templateUrl: './usercollection.component.html',
  styleUrls: ['./usercollection.component.css'],
})
export class UsercollectionComponent implements OnInit {
  collectionList: any;

  constructor(private service: CollectionService, private router: Router) {}

  ngOnInit(): void {
    this.service.userProductGetCollection().subscribe((response) => {
      console.log(response);
      this.collectionList = response.data;
    });
  }
  collection(title: any, id: any) {
    this.router.navigate([`c/${title.split(' ').join('-')}/id-${id}`]);
  }
}
