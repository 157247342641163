  <app-homenavbar *ngIf="sessionType == 'home'"></app-homenavbar>
<app-usernavbar *ngIf="sessionType == 'user'"></app-usernavbar>
<app-navbar *ngIf="sessionType == 'powerUser'"></app-navbar>
<app-creatornavbar *ngIf="sessionType == 'publisher'"></app-creatornavbar>
   <div class="main">
    <div class="container">
    <div class="bulk-products mt-5 mb-5">
        <!-- <p *ngFor="let value of links">{{value.BulkProductLink}}</p> -->
          <div class="row">
            <div class="col-md-4" *ngFor="let cardDetails of links">
              <div class="card mb-3">
                 <div class="card-body">
                     <div class="collectionall-header  mt-3">
                      <h4>{{cardDetails.title}}</h4>
                      <p class="mt-2 mb-md-2">by <a>{{cardDetails.product[0].channelName}}</a></p>
                      <a class="links">{{cardDetails.product.length}}  products links </a>
                    </div>
                     <div class="view-now">
                         <button type="button" (click)="productDetails(cardDetails.productTitle,cardDetails._id)" class="pointer">View Now</button>
                     </div>
                     <div class="d-flex justify-content-around links-p">
                        <div class="d-flex">
                        <img src="../../assets/images/eye.png" class="icon-1">
                        <p>19K views</p>
                        </div>
                         <div class="d-flex">
                        <img src="../../assets/images/link-cart-logo.svg" class="icon">
                        <p>19K clicks</p>
                        </div>
                        <div class="d-flex">
                        <img src="../../assets/images/bookmark.png" class="icon-2">
                        <p>19K bookmarks</p>
                        </div>
                     </div>
                 </div>
              </div>
           
            </div>
        </div> 
    </div>
  
  
  </div>
   </div>
 <app-footer></app-footer>
   