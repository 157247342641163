import {
  Component,
  OnInit,
  Inject,
  PLATFORM_ID,
  Renderer2,
  OnDestroy,
  ElementRef,
  AfterViewInit,
  NgZone,
  ChangeDetectorRef,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NoAuthSignupFollowComponent } from '../no-auth-signup-follow/no-auth-signup-follow.component';
import { NoAuthSignupComponent } from '../no-auth-signup/no-auth-signup.component';
import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { UseronboardingflowDialogComponent } from '../useronboardingflow-dialog/useronboardingflow-dialog.component';
import { PageEvent } from '@angular/material/paginator';
import { ShareproductLinkDialogComponent } from '../shareproduct-link-dialog/shareproduct-link-dialog.component';
import { environment } from 'src/environments/environment';
import { DynamicShareButtonDialogComponent } from '../dynamic-share-button-dialog/dynamic-share-button-dialog.component';
import { AnimationOptions } from 'ngx-lottie';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import Swal from 'sweetalert2';
import { Observable, Subscription, forkJoin, interval, of } from 'rxjs';
import { CollectionService } from '../_services/collection.service';
import { mergeMap } from 'rxjs/operators';
import { LoaderService } from '../_services/loader.service';
// import clevertap from 'clevertap-web-sdk';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  AnimationEvent,
  keyframes,
} from '@angular/animations';
import { SignupComponent } from '../login-page/signup/signup.component';
import { CommentSectionDialogComponent } from './comment-section-dialog/comment-section-dialog.component';
import { UserForgotpasswordComponent } from '../login-page/user-forgotpassword/user-forgotpassword.component';
import { SuccessdialoghomeComponent } from './successdialoghome/successdialoghome.component';
import { ConstantPool } from '@angular/compiler';
import { ReportDialogComponent } from './report-dialog/report-dialog.component';
declare const commentBoxSection: any;
declare const comments: any;
declare const clevertap: any;

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css'],
  animations: [
    trigger('rotateWords', [
      transition('* => visible', [
        animate(
          '0.5s',
          keyframes([
            style({ opacity: 0, offset: 0 }),
            style({ opacity: 1, offset: 1 }),
          ])
        ),
      ]),
    ]),
  ],
})
export class HomepageComponent implements OnInit, OnDestroy {
  hideComponent: boolean = false;
  hideNotifications: boolean = false;
  hideadmin: boolean = true;
  hideCollection: boolean = true;

  collectionFeed: any;
  userName: any;
  collectionName: any = '';
  collectionParams: any;
  collectionList: any;
  activeState: any;
  categoryList: any;
  unfollowBtnShow: boolean = false;
  followBtnShow: boolean = true;
  token: string | null;
  followTab: boolean = false;
  followingTab: boolean = false;
  followcaterogoryids: any = [];
  categoryListFollowListLoop: any;
  caterogoryId: any;
  productName: any;
  queryParamsDetails: any;
  userDetailsParams: any;
  tokenParams: any;
  token1: any;
  sessionStorage: any;
  userType: any;
  footer: boolean = false;
  user: string;
  session: any;
  sessionType: any;
  message: string | null;
  userDialog: any;
  subs: any;
  q: any = 3;
  page: number = 1;
  queryParams: { pageLimit: any; pageNumber: any };
  totalrecords: any;
  pageSize: any = 10;
  currentPage: any = 1;
  pageSizeOptions: number[] = [5, 10, 20, 40, 100];
  pageLimit: any;
  direction: string;
  sum: number;
  stopApiCall: any;
  relatedCollection: any;
  realatedcollectionList: any = [];
  userBookmarkList: any;
  userId: any = '';
  liketotalCount: any;
  noOfRecords: any;
  currentLimit: number;
  params: any;
  categoryRecords: any;
  catetoryLimit: number;
  categorySelected: any = '';
  categoryRoute: any;
  catergoryParams: { id: string };
  routeIdentifier: string;
  disqus: boolean = false;
  catergoryLoad: boolean = false;
  isForYou: boolean = true;
  followedCatergorys: any = [];
  load: boolean = false;
  commentForm!: FormGroup;
  editCommentForm!: FormGroup;
  replyCommentForm!: FormGroup;
  replyEditCommentForm!: FormGroup;
  showButtons = false;
  inputActive: boolean = false;
  commentBtnEvent: boolean = true;
  commentBtnEventReply: boolean = true;
  readonly: boolean = true;
  replyCommentShow: boolean = false;
  showButtonsReply: boolean = false;
  inputValue: any = '';
  commentUser: any;
  commentsValue: any;
  errorLength: boolean = false;
  comment: any;
  ReplycommentUser: any = [];
  Replycomment: any;
  replyShow: boolean = false;
  commentId: any;
  showReplys: boolean = false;
  totalComments: any;
  showEditForm: boolean = false;
  showEditButtons: boolean = false;
  editActive: boolean = false;
  showReplyEditButtons: boolean = false;
  collectionUserId: any;
  CollectionID: any;
  optionsW: AnimationOptions = {
    path: '../../assets/images/blink.json',
  };
  lottieimage: boolean = false;
  shareLink: string;
  userTypeId: any;
  summaryId: any;
  showComments: boolean = false;
  showCommentLoader: boolean = false;
  commentsLoaderPostion: string = 'comments_loader_postion_change_css';
  replyInputActive: boolean;
  recommendedData: any;
  recommendedPublishers: any;
  recommendedTopics: any;
  recommendedParams: {};
  blogActive: boolean = false;
  animationsView: boolean = true;
  noAnimationsView: boolean = false;
  success: boolean = true;
  public client$: Observable<any[]>;
  itemsLoader: boolean;
  topicId: any = '';

  fadeIn: boolean = true;
  style: any;
  resetPasswordReaderid: any;
  forgotPasswordParams: any;
  private refreshSubscription: Subscription;
  test: any;
  unSubscribe: Subscription;
  categoryListAnimation: any;
  url: any = `${environment.URL}`;
  toggles: any;
  constructor(
    private collectionService: CollectionService,
    private dialog: MatDialog,
    private router: Router,
    public loading: LoaderService,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    @Inject(DOCUMENT) private document: Document,
    private ngZone: NgZone,
    @Inject(PLATFORM_ID) private platformId: any,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document,
    private cdr: ChangeDetectorRef
  ) {
    this.commentForm = this.fb.group({
      title: ['', [Validators.required, Validators.maxLength(1000)]],
    });
    this.replyCommentForm = this.fb.group({
      reply: ['', Validators.required],
    });
    this.editCommentForm = this.fb.group({
      edit: ['', Validators.required],
    });
    this.replyEditCommentForm = this.fb.group({
      replyEdit: ['', Validators.required],
    });
    this.isBrowser = isPlatformBrowser(platformId);
    if (isPlatformBrowser(PLATFORM_ID)) {
      // console.log('Platform', platformId);
    }
    if (isPlatformServer(PLATFORM_ID)) {
      // console.log('Platform', platformId);
    }

    this.refreshSubscription = this.collectionService
      .getRefreshSignal()
      .subscribe(() => {
        this.maincomponent();
        // this.test = this.collectionService.refreshComponent();
        // console.log('test!', this.test);
        // if (this.test == null) {
        //   this.ngOnInit();
        //   localStorage.setItem('refresh', 'false');
        // }
      });
  }
  channelName = 'tseries';
  rotatingWords: string[] = ['Word1', 'Word2', 'Word3'];
  currentIndex: number = 0;
  isBrowser: boolean = false;
  showFullTextTrue = true;
  showFullTextFalse = false;
  ngOnInit() {}

  maincomponent() {
    // this.dialog.open(SignupComponent, {
    //   data: {
    //     disableClose: true,
    //   },
    // });
    // this.dialog.open(UseronboardingflowDialogComponent, {
    //   disableClose: true,
    //   data: {},
    // });

    this.isBrowser = isPlatformBrowser(this.platformId);
    if (this.isBrowser) {
      // Run client-side code only
      this.animateWords();
    }
    // clevertap.init('W6K-KRR-696Z');
    this.categoryRoute = this.router.url.split('/');
    this.forgotPasswordParams = this.activatedRoute.snapshot?.url[1]?.path;
    console.log(this.forgotPasswordParams);
    this.resetPasswordReaderid =
      this.activatedRoute.snapshot.queryParams.readerId;
    if (this.forgotPasswordParams == 'reset-password') {
      this.dialog.open(UserForgotpasswordComponent, {
        disableClose: true,
        data: {},
      });
    }

    // console.log('categoryRoute', this.categoryRoute[2]);
    if (this.categoryRoute[1] == 'feed') {
      this.collectionName = '';
      if (this.collectionService.getToken() != '') {
      }
      this.activeState = 'ForYou';
    } else {
      this.activeState = 'All';
    }
    if (this.categoryRoute[3] != '') {
      if (this.categoryRoute[2] == 'topic') {
        this.activeState = this.categoryRoute[3];
        this.collectionName = this.activeState;
        // console.log('collectionName: ' + this.collectionName);
      }
    }

    this.token1 = this.activatedRoute.snapshot.queryParams.token;
    if (this.token1) {
      localStorage.setItem('token', this.token1);
      this.collectionService.loginUserDetails().subscribe((result) => {
        this.sessionType = result.data.userType;
        this.userTypeId = result.data._id;
      });
    }

    if (this.collectionService.getToken() == '') {
      // console.log('not-login');
      this.sessionType = 'home';
      this.recommendedParams = {};
      // console.log('collectionName: ' + this.collectionName);

      this.collectionService
        .recommendedData(this.recommendedParams)
        .subscribe((recommendedData) => {
          this.recommendedData = recommendedData.data[0];
          this.recommendedPublishers =
            recommendedData.data[0].recommendedPublishers;
          this.recommendedTopics = recommendedData.data[0].recommendedTopics;
        });
      this.collectionParams = {
        topicName: this.collectionName,
        pageLimit: this.pageSize ? this.pageSize : '',
        pageNumber: this.currentPage,
      };
      this.collectionService
        .collectionListFliter(this.collectionParams)
        .subscribe((response) => {
          this.collectionList = response.data;
          this.toggles = response.toggles;
          this.relatedCollection = response.relatedSummary;
          this.totalrecords = response.totalRecord;
          this.noOfRecords = response.totalDocument;
          this.categoryRecords = response.totaltopicRecord;
        });
      // console.log(this.activeState);
      this.unSubscribe = this.collectionService
        .caterogoryListFeed(this.catergoryParams)
        .subscribe(
          (res: any) => {
            this.categoryList = res.data;
            this.categoryListAnimation = res.data.slice(1);
            // console.log(this.categoryRoute[3]);
            if (this.categoryRoute[1] == 'feed') {
              this.collectionName = '';
              this.activeState = res.data[0].name;
            }
            if (this.categoryRoute[2] != '') {
              if (this.categoryRoute[2] == 'topic') {
                this.activeState = this.categoryRoute[3];
                this.collectionName = this.activeState;
                // this.collectionName = '';
                // this.activeState = 'For you';
              }
            }

            this.caterogoryId = res.data[0]._id;
          },
          (error) => {}
        );
    }
    if (this.collectionService.getToken() != '') {
      this.collectionService.loginUserDetails().subscribe((result) => {
        this.sessionType = result.data.userType;
        this.userDialog = result.data.onbording;
        this.userId = result.data._id;
        this.userTypeId = result.data._id;

        if (this.userDialog == false && this.sessionType == 'user') {
          this.dialog.open(UseronboardingflowDialogComponent, {
            disableClose: true,
            data: {},
          });
        }
        this.recommendedParams = {
          readerId: this.userId,
        };
        this.collectionService
          .recommendedData(this.recommendedParams)
          .subscribe((recommendedData) => {
            this.recommendedData = recommendedData.data[0];
            this.recommendedPublishers =
              recommendedData.data[0].recommendedPublishers;
            this.recommendedTopics = recommendedData.data[0].recommendedTopics;
          });
        this.catergoryParams = {
          id: this.userId,
        };

        this.collectionService
          .caterogoryListFeed(this.catergoryParams)
          .subscribe(
            (res: any) => {
              this.categoryList = res.data;
              this.categoryListAnimation = res.data.slice(1);
              if (this.categoryRoute[1] == 'feed') {
                this.collectionName = '';
                this.activeState = res.data[0].name;
              }
              if (this.categoryRoute[3] != '') {
                if (this.categoryRoute[2] == 'topic') {
                  this.activeState = this.categoryRoute[3];
                  this.collectionName = this.activeState;
                  // console.log('collectionName: ' + this.collectionName);
                  // this.collectionName = '';
                  // this.activeState = 'For you';
                }
              }
              // this.activeState = res.data[0].name;

              this.caterogoryId = res.data[0]._id;
            },
            (error) => {}
          );
        this.collectionParams = {
          topicName: this.collectionName,
          id: this.userId,
          pageLimit: this.pageSize ? this.pageSize : '',
          pageNumber: this.currentPage,
        };
        // console.log(this.activeState);
        // console.log('currentLimit', this.currentPage);

        this.collectionService
          .collectionListFliter(this.collectionParams)
          .subscribe((response) => {
            this.collectionList = response.data;
            // console.log('start', this.collectionList);
            this.success = true;
            this.toggles = response.toggles;
            this.relatedCollection = response.relatedSummary;
            this.totalrecords = response.totalRecord;
            this.noOfRecords = response.totalDocument;
            this.categoryRecords = response.totalTopicRecord;
            // console.log('start', this.followedCatergorys);
            // this.collectionList.forEach((item: any) => {
            //   console.log('item', item);

            //   if (item.categoriesFollow) {
            //     this.followedCatergorys.push(item);
            //   }
            // });
            // console.log('stop', this.followedCatergorys);
          });

        // console.log('homepage', this.sessionType);
        // console.log('homepage', this.userDialog);
      });
    }
    this.collectionService.sharedMessage.subscribe((message) => {
      this.message = message;
      // console.log(this.message);
    });

    setTimeout(() => {
      console.log(this.userId);

      this.footer = true;
    }, 2000);
    // console.log('category', this.categorySelected);
  }
  animateWords() {
    setInterval(() => {
      this.ngZone.run(() => {
        this.currentIndex =
          (this.currentIndex + 1) % this.categoryListAnimation?.length;
      });
    }, 3000); // Change text every 2 seconds
  }
  catergoryNavigate(item: any) {
    // console.log('clickded');
    this.router.navigate([`topic/${item}`]);

    // console.log('prod');

    if (environment.CLEVERTAP) {
      clevertap.event.push('TopicsProflieClicks', {
        TopicsProflieClicks: item,
      });
    }
  }
  toggleShowMore(content: any, collection: any, boolean: any) {
    // content.summary?.substring(0);
    if (boolean == true) {
      collection.showSummaryComment = true;
      this.showFullTextFalse = true;
      if (!collection.showSummaryComment) {
        this.showFullTextTrue = false;
      }
    } else {
      collection.showSummaryComment = false;
      // this.showFullTextFalse = false;
      this.showFullTextTrue = true;
      if (!collection.showSummaryComment) {
      }
    }

    console.log('summarybooleean', collection.showSummaryComment);
    // console.log('showtest', this.showFullText);
    console.log('boolean', boolean);
    // console.log(collection.showSummaryComment);

    // console.log('toggleShowMore', collection);
    // if (this.showMore) {
    //   collection.summary = content.substring(0, 200);
    // } else {
    //   collection.summary = content;
    // }
  }
  UnfollowBtn(collection: any, boolean: any) {
    // console.log(id);
    if (this.collectionService.getToken() == '') {
      this.dialog.open(NoAuthSignupFollowComponent, {
        data: {
          title: 'to follow  the channel',
          disableClose: true,
        },
      });
    } else {
      let obj = {
        publisherId: collection.publisherId,
      };
      this.collectionService
        .ChannelUnFollowList(collection.publisherId)
        .subscribe((res) => {
          // this.following = false;
          // this.follow = true;
          collection.followPublisher = false;
          this.dialog.open(SuccessdialoghomeComponent, {
            data: {
              title: collection.name,
              view: collection,
              topic: false,
              publisher: true,
              bookmark: false,
              disableClose: true,
            },
          });
          // this.followPublisher = false;
        });
    }
  }
  link() {
    this.dialog.open(SuccessdialoghomeComponent, {
      data: {
        title: 'collection.name',
        view: 'collection',
        topic: false,
        link: true,
        publisher: false,
        bookmark: false,
        disableClose: true,
      },
    });
  }
  navClick(item: any, responsive: any) {
    this.realatedcollectionList = [];
    this.categorySelected = item;
    // console.log('category', this.categorySelected);

    if (this.collectionService.getToken() == '') {
      if (item.name == 'All') {
        this.collectionName = '';
        this.topicId = '';
        this.activeState = item.name;
        if (responsive != 'resposive') {
          this.noAnimationsView = false;
          this.animationsView = true;
        }
      } else {
        this.collectionName = item.name;
        this.activeState = item.name;
        this.topicId = item._id;
        if (responsive == 'resposive') {
          this.noAnimationsView = true;
          this.animationsView = false;
        }
      }

      if (environment.CLEVERTAP) {
        // console.log('clevertap clicks ');
        clevertap.event.push('PreLoginFeedTopic', {
          preLoginFeedTopic: 'preLogin Feed Topic selected',
          preLoginFeedTopicName: item.name,
        });
        clevertap.setLogLevel(3);
      }

      this.followTab = true;
      this.catergoryLoad = true;
      this.currentPage = 1;
      this.activeState = item.name;
      this.recommendedParams = {
        topicId: this.topicId,
      };
      this.collectionService
        .recommendedData(this.recommendedParams)
        .subscribe((recommendedData) => {
          this.recommendedData = recommendedData.data[0];
          this.recommendedPublishers =
            recommendedData.data[0].recommendedPublishers;
          this.recommendedTopics = recommendedData.data[0].recommendedTopics;
          // console.log('recommendedPublishers: ', this.recommendedPublishers);
          // console.log('recommendedTopics: ', this.recommendedTopics);
        });
      this.collectionParams = {
        topicName: this.collectionName,
        pageLimit: this.pageSize ? this.pageSize : '',
        pageNumber: this.currentPage,
      };
      this.collectionService
        .collectionListFliter(this.collectionParams)
        .subscribe((response) => {
          this.catergoryLoad = false;
          this.toggles = response.toggles;
          this.collectionList = response.data;
          this.relatedCollection = response.relatedSummary;
          this.stopApiCall = response.data.length;
          this.categoryRecords = response.totaltopicRecord;

          // console.log('click', response.totalDocument);
        });
    } else {
      if (item.name == 'ForYou') {
        this.collectionName = '';
        this.topicId = '';
        this.activeState = 'ForYou';

        if (responsive != 'resposive') {
          this.noAnimationsView = false;
          this.animationsView = true;
        }
      } else {
        this.collectionName = item.name;
        this.activeState = item.name;
        this.topicId = item._id;

        if (responsive == 'resposive') {
          this.noAnimationsView = true;
          this.animationsView = false;
        }
      }
      this.activeState = item.name;
      this.catergoryLoad = true;

      // console.log(item.name);
      this.currentPage = 1;
      this.followingTab = item.following;
      this.followTab = item.follow;

      this.caterogoryId = item._id;
      this.productName = item.name;
      this.recommendedParams = {
        topicId: this.topicId,
        readerId: this.userId,
      };
      this.collectionService
        .recommendedData(this.recommendedParams)
        .subscribe((recommendedData) => {
          this.recommendedData = recommendedData.data[0];
          this.recommendedPublishers =
            recommendedData.data[0].recommendedPublishers;
          this.recommendedTopics = recommendedData.data[0].recommendedTopics;
        });
      this.collectionParams = {
        topicName: this.collectionName,
        id: this.userId,
        pageLimit: this.pageSize ? this.pageSize : '',
        pageNumber: this.currentPage,
      };
      this.collectionService
        .collectionListFliter(this.collectionParams)
        .subscribe((response) => {
          this.catergoryLoad = false;
          this.toggles = response.toggles;
          this.collectionList = response.data;
          this.stopApiCall = response.data.length;
          this.relatedCollection = response.relatedSummary;
          this.categoryRecords = response.totalTopicRecord;
          // this.router.navigate([`feed/topic/${item.name}`]);
        });
      if (environment.CLEVERTAP) {
        clevertap.event.push('FeedTopicsLogin', {
          FeedTopicsLoginName: 'FeedTopicsLoginSelected',
          FeedTopicSelected: item.name,
        });
      }
    }
    // console.log('ForYou topic selected');
    // }
  }
  //report button
  report(collections: any) {
    // console.log(text);
    if (this.collectionService.getToken() == '') {
      this.readonly = true;
      this.dialog.open(NoAuthSignupComponent, {
        data: {
          title: `to report`,
          ShareLink: `/c/${collections.summaryName.split(' ').join('-')}/id-${
            collections._id
          }`,
          disableClose: true,
        },
      });
    } else {
      this.dialog.open(ReportDialogComponent, {
        data: {
          title: collections._id,
          disableClose: true,
        },
      });

      // let obj = {
      //   report_type: text,
      // };
      // this.collectionService
      //   .reportSummary(collections._id, obj)
      //   .subscribe((result) => {
      //     Swal.fire({
      //       title:
      //         'Thanks for reporting.Our team will review your feedback and take necessary actions',
      //       icon: 'success', // You can change the icon type
      //       timer: 4000, // Set the timer to 3000 milliseconds (3 seconds)
      //       showConfirmButton: false, // Hide the "OK" button
      //     });
      //     console.log(result);
      //   });
    }
  }
  recommendedTopicAndPublisher() {
    this.recommendedParams = {
      topicId: this.topicId,
      readerId: this.userId,
    };
    this.collectionService
      .recommendedData(this.recommendedParams)
      .subscribe((recommendedData) => {
        this.recommendedData = recommendedData.data[0];
        this.recommendedPublishers =
          recommendedData.data[0].recommendedPublishers;
        this.recommendedTopics = recommendedData.data[0].recommendedTopics;
      });
  }
  realatedCollectionListBtn(items: any) {
    // console.log(items);
    if (this.isBrowser) {
      if (environment.CLEVERTAP) {
        clevertap.event.push('RelatedCollectionClicks', {
          RelatedCollectionClicks: 'RelatedCollectionClickSuccefully',
          RelatedCollectionName: items.summaryName,
          RelatedCollectionId: items._id,
        });
      }
    }
    if (this.collectionService.getToken() != '') {
      let idparams = {
        id: this.userId,
        summaryId: items._id,
      };
      this.collectionService.homeFeedIdCollection(idparams).subscribe((res) => {
        // console.log(res);
        this.realatedcollectionList = res.data;
        // window.scrollTo(0, 0);
        this.renderer.setProperty(
          this.document.documentElement,
          'scrollTop',
          0
        );
        this.renderer.setProperty(this.document.body, 'scrollTop', 0);
      });
    } else {
      let idparams = {
        summaryId: items._id,
      };
      this.collectionService.homeFeedIdCollection(idparams).subscribe((res) => {
        // console.log(res);
        this.realatedcollectionList = res.data;
        // console.log(this.realatedcollectionList);
        // window.scrollTo(0, 0);
        this.renderer.setProperty(
          this.document.documentElement,
          'scrollTop',
          0
        );
        this.renderer.setProperty(this.document.body, 'scrollTop', 0);
      });
    }
  }
  writeComment(collections: any) {
    this.dialog.open(CommentSectionDialogComponent, {
      data: {
        link: `${environment.SHARE_URL}/c/${collections.summaryName
          .split(' ')
          .join('-')}/id-${collections._id}`,
        video: collections.video,
      },
    });
  }
  onScrollDown(ev: any) {
    this.currentLimit = this.noOfRecords / 10;
    this.catetoryLimit = this.categoryRecords / 10;
    // console.log(this.activeState);
    // console.log('currentLimit', this.catetoryLimit);
    // console.log('currentLimit', this.currentPage);
    if (this.success) {
      if (Math.round(this.catetoryLimit) >= this.currentPage) {
        this.appendItems();
      }
    }

    // }
  }

  appendItems() {
    this.success = false;
    if (this.currentPage) {
      this.itemsLoader = false;
    } else {
      this.itemsLoader = true;
    }

    // console.log('loading.......', this.collectionName);
    if (this.collectionService.getToken() != '') {
      this.collectionParams = {
        topicName: this.collectionName,
        id: this.userId,
        pageLimit: this.pageSize ? this.pageSize : '',
        pageNumber: ++this.currentPage,
      };
    } else {
      this.collectionParams = {
        topicName: this.collectionName,
        pageLimit: this.pageSize ? this.pageSize : '',
        pageNumber: ++this.currentPage,
      };
    }
    // console.log('loading.......', this.collectionName);

    this.collectionService
      .collectionListFliter(this.collectionParams)
      .subscribe((response) => {
        this.collectionList.push(...response.data);
        this.toggles = response.toggles;
        this.stopApiCall = response.data.length;
        this.success = true;
        this.itemsLoader = false;
      });
    this.itemsLoader = false;
    if (this.collectionService.getToken() != '') {
      this.recommendedParams = {
        readerId: this.userId,
        topicId: this.topicId,
      };
    } else {
      this.recommendedParams = {
        topicId: this.topicId,
      };
    }

    this.collectionService
      .recommendedData(this.recommendedParams)
      .subscribe((recommendedData) => {
        this.recommendedData = recommendedData.data[0];
        this.recommendedPublishers =
          recommendedData.data[0].recommendedPublishers;
        this.recommendedTopics = recommendedData.data[0].recommendedTopics;
      });
  }
  unFollowCategories(categories: any, boolena: any, collections: any) {
    // const filteredItems = collections.topics.filter(
    //   (e: any) => e.topicsFollow == true && e.isAdded == true
    // );
    // console.log(filteredItems);
    if (this.collectionService.getToken() == '') {
      this.dialog.open(NoAuthSignupComponent, {
        data: {
          title: 'Join this community',
          ShareLink: `/c/${collections.summaryName.split(' ').join('-')}/id-${
            collections._id
          }`,
          disableClose: true,
        },
      });
    } else {
      this.collectionService
        .caterogyUnFollow(categories._id)
        .subscribe((response) => {
          categories.topicsFollow = false;
          this.dialog.open(SuccessdialoghomeComponent, {
            data: {
              title: categories.name,
              view: categories,
              topic: true,
              publisher: false,
              bookmark: false,
              disableClose: true,
            },
          });
        });
    }
  }
  navigateChannel(channelName: any) {
    this.router.navigate([`/${channelName}`.split(' ')?.join('')]);
    if (this.isBrowser) {
      if (environment.CLEVERTAP) {
        clevertap.event.push('PublisherProflieClicks', {
          PublisherProflieName: channelName,
        });
      }
    }
  }

  closePubliser(i: any) {
    // if (i > 1) {
    //   this.recommendedPublishers.splice(i, 1);
    // }
    this.recommendedPublishers.splice(i, 1);
  }
  closeTopics(i: any) {
    // if (i > 0) {
    //   this.recommendedTopics.splice(i, 1);
    // }
    this.recommendedTopics.splice(i, 1);
  }
  share(collections: any) {
    // // console.log(
    clevertap.event.push('blink', {
      LoginshareSummaryName: collections.summaryName,
      LoginshareId: collections._id,
    });
    // this.collectionsClick(collections._id);

    //clevertap intergation event
    if (this.isBrowser) {
      if (environment.CLEVERTAP) {
        clevertap.event.push('summaryShare', {
          LoginshareSummaryName: collections.summaryName,
          LoginshareId: collections._id,
        });
      }
    } // clevertap intergation event
    this.dialog.open(DynamicShareButtonDialogComponent, {
      data: {
        title: `${environment.SHARE_URL}/c/${collections.summaryName
          .split(' ')
          .join('-')}/id-${collections._id}`,
        collectionName: collections.summaryName.split(' ').join('-'),
        disableClose: true,
      },
    });

    this.collectionService
      .sharecount(collections._id)
      .subscribe((response) => {});
  }

  collectionsClick(item: any) {
    let click = {
      collectionId: item,
    };
    this.collectionService.collectionClicks(click, item).subscribe((result) => {
      // console.log(result);
    });
  }
  likeCollections(collections: any, likes: Boolean, id: any) {
    // console.log('clevertap succefully event', clevertap.event);

    if (this.collectionService.getToken() == '') {
      //clevertap intergation event
      if (this.isBrowser) {
        if (environment.CLEVERTAP) {
          clevertap.event.push('PreLoginBlinks', {
            PreLoginBlinks: 'PreLogin Blink click',
            PreLoginBlinksSummaryName: collections.summaryName,
            PreLoginBlinksId: collections._id,
          });
        }
      }
      // this.collectionsClick(collections._id);
      //clevertap intergation event
      this.dialog.open(NoAuthSignupComponent, {
        data: {
          title: 'Blink collection',
          ShareLink: `/c/${collections.summaryName.split(' ').join('-')}/id-${
            collections._id
          }`,
          disableClose: true,
        },
      });
    } else {
      this.collectionService
        .userLikeCollections(collections._id)
        .subscribe((res) => {
          //clevertap intergation event
          if (this.isBrowser) {
            if (environment.CLEVERTAP) {
              clevertap.event.push('PostLoginBlinks', {
                PostLoginBlinks: 'PostLogin Blink collections',
                PostLoginBlinksSummaryName: collections.summaryName,
                PostLoginBlinksId: collections._id,
              });
            }
          }
          //clevertap intergation event
          // this.lottieimage = true;
          collections.summaryLikeCount++;
          collections.summaryLike = true;

          // setTimeout(() => {
          //   this.lottieimage = false;
          // }, 3000);
        });
    }
  }
  dislikeSummary(collections: any, likes: Boolean, id: any) {
    // console.log('clevertap succefully event', clevertap.event);

    if (this.collectionService.getToken() == '') {
      //clevertap intergation event
      if (this.isBrowser) {
        if (environment.CLEVERTAP) {
          clevertap.event.push('PreLoginBlinks', {
            PreLoginBlinks: 'PreLogin Blink click',
            PreLoginBlinksSummaryName: collections.summaryName,
            PreLoginBlinksId: collections._id,
          });
        }
      }
      // this.collectionsClick(collections._id);
      //clevertap intergation event
      this.dialog.open(NoAuthSignupComponent, {
        data: {
          title: 'unBlinkcollection',
          ShareLink: `/c/${collections.summaryName.split(' ').join('-')}/id-${
            collections._id
          }`,
          disableClose: true,
        },
      });
    } else {
      this.collectionService
        .userDisLikeCollections(collections._id)
        .subscribe((res) => {
          //clevertap intergation event
          if (this.isBrowser) {
            if (environment.CLEVERTAP) {
              clevertap.event.push('PostLoginBlinks', {
                PostLoginBlinks: 'PostLogin Blink collections',
                PostLoginBlinksSummaryName: collections.summaryName,
                PostLoginBlinksId: collections._id,
              });
            }
          }
          //clevertap intergation event
          // this.lottieimage = true;
          // collections.summaryLikeCount++;
          // collections.summaryLike = true;
          this.dialog.open(SuccessdialoghomeComponent, {
            data: {
              title: collections.name,
              view: collections,
              topic: false,
              publisher: false,
              bookmark: false,
              downvote: true,
              disableClose: true,
            },
          });
          // setTimeout(() => {
          //   this.lottieimage = false;
          // }, 3000);
        });
    }
  }
  saveCollection(
    collections: any,
    bookmark: boolean,
    collectionId: any,
    title: any
  ) {
    // this.collectionsClick(collections._id);
    if (this.collectionService.getToken() == '') {
      //clevertap intergation event
      if (this.isBrowser) {
        if (environment.CLEVERTAP) {
          clevertap.event.push('PreLoginBookmarks', {
            PreLoginBookmarks: 'Pre login bookmark click',
            PreLoginBookmarksSummarieName: collections.summaryName,
            PreLoginBookmarksSummarieId: collections._id,
          });
        }
      }
      //clevertap intergation event
      this.dialog.open(NoAuthSignupComponent, {
        data: {
          title: 'Read later this collection',
          ShareLink: `/c/${title.split(' ').join('-')}/id-${collectionId}`,
          disableClose: true,
        },
      });
    } else {
      let obj = {
        summaryId: collectionId,
      };
      this.collectionService.userCollectionGet(obj).subscribe(
        (res) => {
          //clevertap intergation event
          if (this.isBrowser) {
            if (environment.CLEVERTAP) {
              clevertap.event.push('PostLoginBookmarks', {
                PostLoginBookmarksreadSummaryLater:
                  'Summary has been bookmarked',
                PostLoginBookmarksSummarieName: collections.summaryName,
                PostLoginBookmarksSummarieId: collections._id,
              });
            }
          }
          //clevertap intergation event
          collections.bookmarkSummary = true;
          this.dialog.open(SuccessdialoghomeComponent, {
            data: {
              title: collections.name,
              view: collections,
              topic: false,
              publisher: false,
              bookmark: true,
              disableClose: true,
            },
          });
        },
        (error) => {}
      );
    }
  }
  showMoreBlog(item: any) {
    this.blogActive = true;

    item.isAnyCategoryAdded = false;
  }
  showLessBlog(item: any) {
    this.blogActive = false;
    item.isAnyCategoryAdded = true;
  }
  followPublisher(item: any) {
    // console.log(item);
    if (this.collectionService.getToken() == '') {
      this.dialog.open(NoAuthSignupComponent, {
        data: {
          title: 'Follow this channel',
          ShareLink: 'feed',
          disableClose: true,
        },
      });
    } else {
      this.params = {
        publisherName: item.name,
        publisherId: item._id,
      };
      this.collectionService
        .followCreatorChannel(this.params)
        .subscribe((res) => {
          // console.log(res);
          item.followPublisher = true;
        });
    }
  }
  followTopic(item: any) {
    // console.log(item);
    this.collectionsClick(item._id);
    if (this.collectionService.getToken() == '') {
      this.dialog.open(NoAuthSignupComponent, {
        data: {
          title: 'Join this community',
          ShareLink: item.name,
          disableClose: true,
        },
      });
    } else {
      let obj = {
        topicId: item._id,
      };
      // console.log(obj);
      this.collectionService.caterogyFollow(obj).subscribe((res) => {
        // console.log(obj);
        if (this.isBrowser) {
          if (environment.CLEVERTAP) {
            clevertap.event.push('TopicsProflieFollows', {
              TopicsProflieName: item.name,
              readerName: this.userName,
            });
          }
        }
        item.topicFollowed = true;
      });
    }
  }
  navigatePublisher(name: any) {
    this.router.navigate([`${name}`]);
  }
  subscribeChannel(collections: any, subscribe: boolean) {
    if (this.collectionService.getToken() == '') {
      this.dialog.open(NoAuthSignupComponent, {
        data: {
          title: 'Follow this channel',
          ShareLink: `/c/${collections.summaryName.split(' ').join('-')}/id-${
            collections._id
          }`,
          disableClose: true,
        },
      });
    } else {
      this.params = {
        publisherName: collections.publisherName,
        publisherId: collections.publisherId,
      };
      this.collectionService
        .followCreatorChannel(this.params)
        .subscribe((res) => {
          if (this.isBrowser) {
            if (environment.CLEVERTAP) {
              clevertap.event.push('PublisherProflieFollows', {
                PublisherProflieName: collections.publisherName,
                readerName: this.userName,
              });
            } // this.followersAPI();
          }
          collections.followPublisher = true;
        });
    }
  }

  followBtn(item: any, catergoriesFollowed: any, collection: any) {
    // console.log(item);
    this.collectionsClick(item._id);
    if (this.collectionService.getToken() == '') {
      this.dialog.open(NoAuthSignupComponent, {
        data: {
          title: 'Join this community',
          ShareLink: `/c/${collection.summaryName.split(' ').join('-')}/id-${
            collection._id
          }`,
          disableClose: true,
        },
      });
    } else {
      if (item) {
        let obj = {
          topicId: item._id,
        };
        // console.log(obj);
        this.collectionService.caterogyFollow(obj).subscribe((res) => {
          // console.log(obj);
          if (this.isBrowser) {
            if (environment.CLEVERTAP) {
              clevertap.event.push('TopicsProflieFollows', {
                TopicsProflieName: item.name,
                readerName: this.userName,
              });
            }
          }
          item.topicsFollow = true;
        });
      } else {
      }
    }
  }
  creatorData() {
    this.collectionService.creatorData().subscribe((data) => {
      // console.log(data);
    });
  }
  // params() {
  //   this.activatedRoute.queryParamMap.subscribe((params) => {
  //     this.queryParamsDetails = { ...params.keys, ...params };
  //     this.userDetailsParams = params.get('token');
  //   });
  //   if (this.userDetailsParams != null) {
  //     this.tokenParams = this.userDetailsParams;
  //     if (this.tokenParams != null) {
  //       return;
  //     } else {
  //       this.router.navigate(['/']);
  //     }
  //   }
  //   return;
  // }
  slideConfig = {
    slidesToShow: 4,
    slidesToScroll: 1,
    dots: true,
    infinite: false,
  };

  collection(title: any, id: any) {
    const url = `/c/${title.split(' ').join('-')}/id-${id}`;
    //  window.open(url, '_blank'); // This is optional; it focuses on the newly opened tab if the browser allows it.
  }
  tweetEvent(item: any) {
    // console.log('event clicks');
    if (this.isBrowser) {
      if (environment.CLEVERTAP) {
        clevertap.event.push('Tweet', {
          Tweetthis: 'tweet',
          TweetthisSummaryName: item.summaryName,
          TweetthisSummaryId: item._id,
        });
      }
    }
  }
  tweetBtn(item: any) {
    // this.collectionsClick(item._id);
    // console.log(item);
    this.shareLink = `${environment.SHARE_URL}/c/${item.summaryName
      ?.split(' ')
      .join('-')}/id-${item._id}`;
    return `https://www.shareaholic.com/api/share/?v=1&apitype=1&apikey=2ceb7e6973521a10a3e4b25ad56072e7
  &service=twitter&title=${item.summaryName}&link=${
      environment.SHARE_URL
    }/c/${item.summaryName?.split(' ').join('-')}/id-${
      item._id
    }/&source=Shareaholic`;
  }

  lastOpenedComment: any = null; // Track the last opened comment
  comments(collections: any) {
    if (collections.showCollectionComment) {
      // Close the comment if it's open
      collections.showCollectionComment = false;
    } else {
      this.collectionList.forEach((c: any) => {
        if (c !== collections.showCollectionComment) {
          c.showCollectionComment = false;
        }
      });
      collections.showCollectionComment = true;
    }

    if (collections._id && collections.showCollectionComment == true) {
      this.summaryId = collections._id;
      this.commentsGet();
    }
  }

  // comment ts file
  onSubmitComment(collections: any) {
    // console.log(this.commentForm.value);
    if (this.collectionService.getToken() != '') {
      if (this.commentForm) {
        this.commentBtnEvent = false;
        let obj = {
          summaryId: collections._id,
          comment: this.commentForm.value.title,
        };
        this.collectionService.commentPost(obj).subscribe((response) => {
          // console.log(response);
          // this.commentUser = response.data;
          this.commentsGet();
          this.cancelComment();
        });
      }
    }
  }

  commentsGet() {
    this.showCommentLoader = true;
    this.collectionService.commentGet(this.summaryId).subscribe((response) => {
      this.commentUser = response.data;
      this.showCommentLoader = false;

      this.totalComments = response.totalComment;
      this.commentUser.forEach((comment: any) => {
        return (comment.showReplyForm = false), (comment.showReplys = false);
      });
      // console.log(this.commentUser);
    });
  }
  get commentFormError(): { [key: string]: AbstractControl } {
    return this.commentForm.controls;
  }
  get replyCommentFormError(): { [key: string]: AbstractControl } {
    return this.replyCommentForm.controls;
  }
  // validations for AlphabetsOnly
  nameValidations(event: any, item: any) {
    if (item == 'title') {
      let newValue = event.target.value.replace(/[^a-zA-Z ]/g, '');
      // console.log('alphabets', item);
      this.commentForm.controls['title'].setValue(newValue);
    } else if (item == 'edit') {
      let newValue = event.target.value.replace(/[^a-zA-Z ]/g, '');
      // console.log('alphabets', newValue);
      this.editCommentForm.controls['edit'].setValue(newValue);
    } else if (item == 'replyEdit') {
      let newValue = event.target.value.replace(/[^a-zA-Z ]/g, '');
      // console.log('alphabets', newValue);
      this.replyEditCommentForm.controls['replyEdit'].setValue(newValue);
    }
  }
  ReplyValidations(event: any, item: any) {
    if (item == 'reply') {
      let newValue = event.target.value.replace(/[^a-zA-Z ]/g, '');
      // console.log('alphabets', item);
      this.replyCommentForm.controls['reply'].setValue(newValue);
    }
  }
  showReplyBtn(item: any) {
    item.showReplys = !item.showReplys;
  }
  cancelComment() {
    this.inputActive = false;
    this.showButtons = false;
    this.commentForm.reset();
    this.editActive = false;
    this.showEditButtons = false;
  }
  spamComment(comment: any, collections: any) {
    // console.log(comment._id);
    if (this.collectionService.getToken() == '') {
      this.readonly = true;
      this.dialog.open(NoAuthSignupComponent, {
        data: {
          title: 'to mark spanview',
          ShareLink: `/c/${collections.summaryName.split(' ').join('-')}/id-${
            collections._id
          }`,
          disableClose: true,
        },
      });
    } else {
      let obj = {
        commentReplyId: '',
      };
      this.collectionService.commentSpam(obj, comment._id).subscribe(
        (res) => {
          // console.log(res);
          Swal.fire({
            title: 'successfully marked as spam',
            icon: 'success', // You can change the icon type
            timer: 2000, // Set the timer to 3000 milliseconds (3 seconds)
            showConfirmButton: false, // Hide the "OK" button
          });
        },
        (error) => {
          // console.log(error);
          Swal.fire({
            title: error.error.message,
            icon: 'error', // You can change the icon type
            timer: 2000, // Set the timer to 3000 milliseconds (3 seconds)
            showConfirmButton: false, // Hide the "OK" button
          });
        }
      );
    }
  }
  spamReplyComment(comment: any, replyId: any, collections: any) {
    // console.log('commentId', comment._id);
    // console.log('repltID', replyId);
    if (this.collectionService.getToken() == '') {
      this.readonly = true;
      this.dialog.open(NoAuthSignupComponent, {
        data: {
          title: 'to mark as spam',
          ShareLink: `/c/${collections.summaryName.split(' ').join('-')}/id-${
            collections._id
          }`,
          disableClose: true,
        },
      });
    } else {
      let obj = {
        commentReplyId: replyId,
      };
      this.collectionService.commentSpam(obj, comment._id).subscribe(
        (res) => {
          // console.log(res);
          Swal.fire({
            title: 'successfully marked as spam',
            icon: 'success', // You can change the icon type
            timer: 2000, // Set the timer to 3000 milliseconds (3 seconds)
            showConfirmButton: false, // Hide the "OK" button
          });
        },
        (error) => {
          Swal.fire({
            title: error.error.message,
            icon: 'error', // You can change the icon type
            timer: 2000, // Set the timer to 3000 milliseconds (3 seconds)
            showConfirmButton: false, // Hide the "OK" button
          });
        }
      );
    }
  }
  spamReplyOffensive(comment: any, replyId: any, collections: any) {
    // console.log('commentId', comment._id);
    // console.log('repltID', replyId);

    if (this.collectionService.getToken() == '') {
      this.readonly = true;
      this.dialog.open(NoAuthSignupComponent, {
        data: {
          title: 'to mark as spam',
          ShareLink: `/c/${collections.summaryName.split(' ').join('-')}/id-${
            collections._id
          }`,
          disableClose: true,
        },
      });
    } else {
      let obj = {
        commentReplyId: replyId,
      };
      this.collectionService.commentOffensive(obj, comment._id).subscribe(
        (res) => {
          // console.log(res);
          Swal.fire({
            title: 'successfully marked as spam',
            icon: 'success', // You can change the icon type
            timer: 2000, // Set the timer to 3000 milliseconds (3 seconds)
            showConfirmButton: false, // Hide the "OK" button
          });
        },
        (error) => {
          Swal.fire({
            title: error.error.message,
            icon: 'error', // You can change the icon type
            timer: 2000, // Set the timer to 3000 milliseconds (3 seconds)
            showConfirmButton: false, // Hide the "OK" button
          });
        }
      );
    }
  }
  spamOffensive(comment: any, collections: any) {
    if (this.collectionService.getToken() == '') {
      this.readonly = true;
      this.dialog.open(NoAuthSignupComponent, {
        data: {
          title: 'to mark view',
          ShareLink: `/c/${collections.summaryName.split(' ').join('-')}/id-${
            collections._id
          }`,
          disableClose: true,
        },
      });
    } else {
      let obj = {
        commentReplyId: '',
      };
      this.collectionService.commentOffensive(obj, comment._id).subscribe(
        (res) => {
          // console.log(res);
          Swal.fire({
            title: 'successfully marked as offensive',
            icon: 'success', // You can change the icon type
            timer: 2000, // Set the timer to 3000 milliseconds (3 seconds)
            showConfirmButton: false, // Hide the "OK" button
          });
        },
        (error) => {
          // console.log(error);
          Swal.fire({
            title: error.error.message,
            icon: 'error', // You can change the icon type
            timer: 2000, // Set the timer to 3000 milliseconds (3 seconds)
            showConfirmButton: false, // Hide the "OK" button
          });
        }
      );
    }
  }
  deleteCommentPublisherandPowerUser(comments: any) {
    // console.log('commentID', comments._id);
    // console.log('collectionID', this.CollectionID);
    let obj = {
      summaryId: this.CollectionID,
      commentId: comments._id,
    };
    this.collectionService.deleteCommentPublisher(obj).subscribe((res) => {
      this.commentsGet();
      Swal.fire({
        title: 'Comment delete successfully',
        icon: 'success', // You can change the icon type
        timer: 2000, // Set the timer to 3000 milliseconds (3 seconds)
        showConfirmButton: false, // Hide the "OK" button
      });
    });
  }
  deleteReplyCommentPublisherandPowerUser(comments: any, replyId: any) {
    // console.log('commentID', comments._id);
    // console.log('collectionID', this.CollectionID);
    let obj = {
      summaryId: this.CollectionID,
      commentId: comments._id,
      commentReplyId: replyId,
    };
    this.collectionService.deleteCommentPublisher(obj).subscribe((res) => {
      this.commentsGet();
      Swal.fire({
        title: 'Comment delete successfully',
        icon: 'success', // You can change the icon type
        timer: 2000, // Set the timer to 3000 milliseconds (3 seconds)
        showConfirmButton: false, // Hide the "OK" button
      });
    });
  }
  // edit and delete comment buttons and api calls
  editMainComment(editData: any) {
    editData.isEdit = !editData.isEdit;
    // console.log(editData);
    this.editCommentForm.controls['edit'].setValue(editData.comment);

    this.toggleEditButtons();
  }
  editReplyComment(editData: any) {
    editData.isEdit = !editData.isEdit;
    // console.log(editData);
    this.replyEditCommentForm.controls['replyEdit'].setValue(editData.comment);

    this.toggleReplyEditButtons();
  }
  cancelEditComment(item: any) {
    item.isEdit = false;
    this.editActive = false;
    this.showEditButtons = false;
  }
  saveEditForm(editData: any) {
    // console.log(this.editCommentForm.value.edit);
    let obj = {
      comment: this.editCommentForm.value.edit,
      commentReply: false,
    };
    this.collectionService
      .commentUpdateReply(obj, editData._id)
      .subscribe((response) => {
        this.commentsGet();
      });
  }
  deletComment(editData: any) {
    // console.log(this.editCommentForm.value.edit);
    let obj = {
      commentReplyId: '',
    };
    this.collectionService
      .commentDeleteReply(obj, editData._id)
      .subscribe((response) => {
        this.commentsGet();
      });
  }
  deletReplyComment(editData: any, replyId: any) {
    // console.log(this.editCommentForm.value.edit, replyId);
    let obj = {
      commentReplyId: replyId,
    };
    this.collectionService
      .commentDeleteReply(obj, editData._id)
      .subscribe((response) => {
        this.commentsGet();
      });
  }
  saveReplyEditForm(editData: any) {
    // console.log(this.replyEditCommentForm.value.replyEdit);
    let obj = {
      comment: this.replyEditCommentForm.value.replyEdit,
      commentReply: true,
    };
    this.collectionService
      .commentUpdateReply(obj, editData._id)
      .subscribe((response) => {
        this.commentsGet();
      });
  }
  toggleEditButtons() {
    if (this.collectionService.getToken() != '') {
      this.editActive = true;
      this.readonly = false;
      this.showEditButtons = true;
    }
  }
  toggleReplyEditButtons() {
    if (this.collectionService.getToken() != '') {
      this.editActive = true;
      this.readonly = false;
      this.showReplyEditButtons = true;
    }
  }
  // edit and delete comment buttons and api calls
  commentEvent() {
    if (this.collectionService.getToken() == '') {
      this.readonly = true;
      this.dialog.open(NoAuthSignupComponent, {
        data: {
          title: 'to write the comment',

          // ShareLink: `/c/${this.userDetailsParams.split(' ').join('-')}/id-${
          //   this.product_id
          // }`,
          disableClose: true,
        },
      });
    }
  }
  toggleButtons() {
    if (this.collectionService.getToken() != '') {
      this.inputActive = true;
      this.readonly = false;
      this.showButtons = true;
    }
  }
  replyToggleButtons() {
    if (this.collectionService.getToken() != '') {
      this.replyInputActive = true;
      this.showButtons = true;
    }
  }

  // reply comment ts file
  replyShowInput(comment: any) {
    if (this.collectionService.getToken() == '') {
      this.readonly = true;
      this.dialog.open(NoAuthSignupComponent, {
        data: {
          title: 'to reply the comment',
          // image: this.thumbnailImage,
          // ShareLink: `/c/${this.userDetailsParams.split(' ').join('-')}/id-${
          //   this.product_id
          // }`,
          disableClose: true,
        },
      });
    } else {
      // console.log('replyId', comment._id);
      this.commentId = comment._id;
      comment.showReplyForm = !comment.showReplyForm;
    }
  }

  commentEventReply(e: any) {
    if (e.target.value != '') {
      this.commentBtnEventReply = false;
    } else {
      this.commentBtnEventReply = true;
    }
  }

  cancelCommentReply(comments: any) {
    this.inputActive = false;
    this.showButtonsReply = false;
    comments.showReplyForm = false;
    this.replyCommentForm.reset();
  }
  onReplyComment() {
    let obj = {
      comment: this.replyCommentForm.value.reply,
    };
    this.collectionService
      .commentReply(obj, this.commentId)
      .subscribe((response) => {
        this.replyCommentShow = false;
        this.commentsGet();
        this.inputActive = false;
        this.showButtonsReply = false;
        this.replyCommentForm.reset();
      });
    // this.Replycomment = this.replyCommentForm.value.reply;
    // this.ReplycommentUser.push({
    //   name: 'ram',
    //   comment: this.Replycomment,
    //   date: '2023-09-07T05:28:39.342Z',
    //   id: '3456',
    // });
  }

  toggleButtonsReply() {
    this.replyInputActive = true;
    this.showButtonsReply = true;
  }
  publisherLogin() {
    this.router.navigate(['/publisher_login']);
  }
  ngOnDestroy() {
    console.log('destroy refreshed!');

    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
    // this.unSubscribe.unsubscribe();
  }
}
