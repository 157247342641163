import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { DynamicShareButtonDialogComponent } from '../dynamic-share-button-dialog/dynamic-share-button-dialog.component';
import { InviteDialogComponent } from '../fliterwithchannelname/invite-dialog/invite-dialog.component';
import { InviteSuccefullyComponent } from '../fliterwithchannelname/invite-succefully/invite-succefully.component';
import { NoAuthSignupFollowComponent } from '../no-auth-signup-follow/no-auth-signup-follow.component';
import { NoAuthSignupComponent } from '../no-auth-signup/no-auth-signup.component';
import { CollectionService } from '../_services/collection.service';
declare var clevertap: any;
@Component({
  selector: 'app-fliterwithcatergory',
  templateUrl: './fliterwithcatergory.component.html',
  styleUrls: ['./fliterwithcatergory.component.css'],
})
export class FliterwithcatergoryComponent implements OnInit {
  collectionParams: any;
  collectionList: any;
  page: number;
  collectionName: any;
  catergoryName: string[];
  sessionType: string;
  userTypeId: any;
  loading: boolean;
  selectedTab: number;
  params: any;
  channelDetails: any;
  channelLoop: any = [];
  channelTitle: any;
  channelDesc: any;
  channelImage: any;
  channelUrl: any;
  channelParams: any;
  productType: string = '';
  tabindex: number;
  queryParms: any;
  productTypeDetails: any;
  collectionLanguageList: any;
  pageSize: any = 10;
  currentPage: any = 1;
  pageSizeOptions: number[] = [5, 10, 20, 40, 100];
  currentLimit: number;
  noOfRecords: any;
  catetoryLimit: number;
  shareLink: string;
  activeParams: any;
  queryParamValue: any;
  activeState: string;
  userName: any;
  shareDataLink: string;
  commentForm!: FormGroup;
  editCommentForm!: FormGroup;
  replyCommentForm!: FormGroup;
  replyEditCommentForm!: FormGroup;
  showButtons = false;
  inputActive: boolean = false;
  commentBtnEvent: boolean = true;
  commentBtnEventReply: boolean = true;
  readonly: boolean = true;
  replyCommentShow: boolean = false;
  showButtonsReply: boolean = false;
  inputValue: any = '';
  commentUser: any;
  commentsValue: any;
  errorLength: boolean = false;
  comment: any;
  ReplycommentUser: any = [];
  Replycomment: any;
  replyShow: boolean = false;
  commentId: any;
  showReplys: boolean = false;
  totalComments: any;
  showEditForm: boolean = false;
  showEditButtons: boolean = false;
  editActive: boolean = false;
  showReplyEditButtons: boolean = false;
  collectionUserId: any;
  CollectionID: any;

  lottieimage: boolean = false;
  summaryId: any;
  showComments: boolean = false;
  showCommentLoader: boolean = false;
  commentsLoaderPostion: string = 'comments_loader_postion_change_css';
  success: boolean = true;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private productService: CollectionService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private metaTagService: Meta,
    private TitleService: Title,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.commentForm = this.fb.group({
      title: ['', [Validators.required, Validators.maxLength(1000)]],
    });
    this.replyCommentForm = this.fb.group({
      reply: ['', Validators.required],
    });
    this.editCommentForm = this.fb.group({
      edit: ['', Validators.required],
    });
    this.replyEditCommentForm = this.fb.group({
      replyEdit: ['', Validators.required],
    });
    this.catergoryName = this.router.url.split('/');
    this.activeState = this.catergoryName[2];
    if (this.productService.getToken() == '') {
      this.channelParams = {
        topicName: this.catergoryName[2],
      };
      this.productService
        .CatergoryDetails(this.channelParams)
        .subscribe((res) => {
          console.log(res);
          this.channelDetails = res.topicData;
          this.channelLoop.push(this.channelDetails);
          console.log(this.channelLoop);
          this.channelTitle = res.topicData.title;
          this.channelDesc = res.topicData.description;
          this.channelImage = res.topicData.topicImage;
          this.channelUrl = res.topicData.url;
          this.shareLink = `${environment.URL}/topic/${res.topicData.name}`;

          this.metaTagService.updateTag({
            name: 'og:title',
            property: 'content',
            content: this.channelTitle,
          });

          this.TitleService.setTitle(this.channelTitle);
          this.metaTagService.updateTag({
            name: 'og:description',
            property: 'content',
            content: this.channelDesc,
          });
          this.metaTagService.updateTag({
            name: 'og:image',
            property: 'content',
            content: this.channelImage,
          });
          this.metaTagService.updateTag({
            name: 'og:image:width',
            property: 'content',
            content: '250',
          });
          this.metaTagService.updateTag({
            name: 'og:image:height',
            property: 'content',
            content: '250',
          });
          this.metaTagService.updateTag({
            name: 'og:url',
            property: 'content',
            content: this.shareLink,
          });
        });
    } else {
      this.productService.loginUserDetails().subscribe((result: any) => {
        this.sessionType = result.data.userType;
        this.userTypeId = result.data._id;
        this.channelParams = {
          topicName: this.catergoryName[2],
          readerId: this.userTypeId,
        };
        this.productService
          .CatergoryDetails(this.channelParams)
          .subscribe((res) => {
            console.log(res);
            this.channelDetails = res.topicData;
            this.channelLoop.push(this.channelDetails);
            this.channelTitle = res.topicData.title;
            this.channelDesc = res.topicData.description;
            this.channelImage = res.topicData.topicImage;
            this.channelUrl = res.topicData.url;
            this.shareLink = `${environment.URL}/topic/${res.topicData.name}`;

            this.metaTagService.updateTag({
              name: 'og:title',
              property: 'content',
              content: this.channelTitle,
            });

            this.TitleService.setTitle(this.channelTitle);
            this.metaTagService.updateTag({
              name: 'og:description',
              property: 'content',
              content: this.channelDesc,
            });
            this.metaTagService.updateTag({
              name: 'og:image',
              property: 'content',
              content: this.channelImage,
            });
            this.metaTagService.updateTag({
              name: 'og:image:width',
              property: 'content',
              content: '250',
            });
            this.metaTagService.updateTag({
              name: 'og:image:height',
              property: 'content',
              content: '250',
            });
            this.metaTagService.updateTag({
              name: 'og:url',
              property: 'content',
              content: this.shareLink,
            });
          });
      });
    }
  }

  ngOnInit(): void {
    clevertap.init('6ZW-454-496Z');

    this.activeParams = this.route.queryParams.subscribe((params) => {
      this.queryParamValue = params['active']; // Access the 'id' query parameter
      if (params['active'] === 'true') {
        //
        this.dialog.open(InviteSuccefullyComponent, {
          data: {
            name: this.catergoryName[2],
            disableClose: true,
          },
        });
      }
    });

    this.loading = true;
    this.catergoryName = this.router.url.split('/');
    console.log(this.catergoryName[2]);
    if (this.productService.getToken() == '') {
      // console.log('not-login');
      this.sessionType = 'home';
    }
    if (this.productService.getToken() != '') {
      this.productService.loginUserDetails().subscribe((result: any) => {
        this.sessionType = result.data.userType;
        this.userTypeId = result.data._id;
        this.userName = result.data.name;
        this.collectionParams = {
          topicName: this.catergoryName[2],
          id: this.userTypeId,
          pageLimit: this.pageSize ? this.pageSize : '',
          pageNumber: this.currentPage,
        };
        this.productService
          .collectionListFliter(this.collectionParams)
          .subscribe((response: any) => {
            this.collectionList = response.data;
            this.loading = false;
            this.noOfRecords = response.totalCategoryRecord;
          });
      });
    }
    if (this.productService.getToken() == '') {
      this.collectionParams = {
        topicName: this.catergoryName[2],
        pageLimit: this.pageSize ? this.pageSize : '',
        pageNumber: this.currentPage,
      };
      this.productService
        .collectionListFliter(this.collectionParams)
        .subscribe((response: any) => {
          this.collectionList = response.data;
          this.noOfRecords = response.totalCategoryRecord;

          this.loading = false;
        });
      // this.channelParams = {
      //   categoryName: this.catergoryName[2],
      // };
      // this.productService
      //   .CatergoryDetails(this.channelParams)
      //   .subscribe((res) => {
      //     console.log(res);
      //     this.channelDetails = res.data;
      //     this.channelLoop.push(this.channelDetails);
      //     console.log(this.channelLoop);
      //     this.channelTitle = res.data.title;
      //     this.channelDesc = res.data.description;
      //     this.channelImage = res.data.categotyImage;
      //     this.channelUrl = res.data.url;
      //     console.log(this.noOfRecords);
      //   });
    }
  }

  /* inivte dialg and share dialog */
  inviteDialog() {
    if (environment.CLEVERTAP) {
      clevertap.event.push('TopicsProflieInviteClicks', {
        TopicsProflieInvite: this.catergoryName[2],
      });
    }

    this.dialog.open(InviteDialogComponent, {
      data: {
        name: this.catergoryName[2],
        profileName: 'topics',
        title: this.channelTitle,
        shareLink: `${environment.SHARE_URL}/topic/${this.catergoryName[2]}/invite?active=true`,
        disableClose: true,
      },
    });
  }
  share(collections: any) {
    this.collectionsClick(collections._id);
    //clevertap intergation event

    if (environment.CLEVERTAP) {
      clevertap.event.push('summaryShare', {
        LoginshareSummaryName: collections.summaryName,
        LoginshareId: collections._id,
      });
    }

    //clevertap intergation event
    this.dialog.open(DynamicShareButtonDialogComponent, {
      data: {
        title: `${environment.SHARE_URL}/c/${collections.summaryName
          .split(' ')
          .join('-')}/id-${collections._id}`,
        collectionName: collections.summaryName.split(' ').join('-'),
        disableClose: true,
      },
    });
  }
  /* inivte dialg and share dialog */

  /* scroll collection buttons */
  onScrollDown(ev: any) {
    // this.currentLimit = this.noOfRecords / 10;
    this.catetoryLimit = this.noOfRecords / 10;
    // console.log(Math.round(this.catetoryLimit));
    if (this.success) {
      if (Math.round(this.catetoryLimit) >= this.currentPage) {
        this.appendItems();
      }
    }
  }
  appendItems() {
    this.success = false;
    // console.log('loading.......', this.collectionName);
    if (this.productService.getToken() != '') {
      this.collectionParams = {
        topicName: this.catergoryName[2],
        id: this.userTypeId,
        language: this.productType,
        pageLimit: this.pageSize ? this.pageSize : '',
        pageNumber: ++this.currentPage,
      };
    } else {
      this.collectionParams = {
        topicName: this.catergoryName[2],
        language: this.productType,
        pageLimit: this.pageSize ? this.pageSize : '',
        pageNumber: ++this.currentPage,
      };
    }

    this.productService
      .collectionListFliter(this.collectionParams)
      .subscribe((response) => {
        this.collectionList.push(...response.data);
        this.success = true;
      });
  }

  /* scroll collection buttons */

  /* follow channel ,tweet event , subscribe channel , likeCollections ,savecollections,followCollectionCatergory button */
  followBtn(item: any) {
    this.collectionsClick(item._id);
    if (this.productService.getToken() == '') {
      this.dialog.open(NoAuthSignupComponent, {
        data: {
          title: 'Join this community',
          ShareLink: 'feed',
          disableClose: true,
        },
      });
    } else {
      if (item) {
        let obj = {
          topicId: item._id,
        };
        this.productService.caterogyFollow(obj).subscribe((res) => {
          // console.log(obj);
          if (environment.CLEVERTAP) {
            clevertap.event.push('TopicsProflieFollows', {
              TopicsProflieName: item.name,
              readerName: this.userName,
            });
          }

          item.categoriesFollow = true;
        });
      }
    }
  }
  tweetEvent(item: any) {
    console.log('event clicks');
    if (environment.CLEVERTAP) {
      clevertap.event.push('Tweet', {
        Tweetthis: 'tweet',
        TweetthisSummaryName: item.collectionName,
        TweetthisSummaryId: item._id,
      });
    }
  }
  subscribeChannel(collections: any, subscribe: boolean) {
    if (this.productService.getToken() == '') {
      this.dialog.open(NoAuthSignupComponent, {
        data: {
          title: 'Follow this channel',
          ShareLink: `/c/${collections.summaryName.split(' ').join('-')}/id-${
            collections._id
          }`,
          disableClose: true,
        },
      });
    } else {
      this.params = {
        publisherName: collections.publisherName,
        publisherId: collections.publisherId,
      };
      this.productService.followCreatorChannel(this.params).subscribe((res) => {
        if (environment.CLEVERTAP) {
          clevertap.event.push('PublisherProflieFollows', {
            PublisherProflieName: collections.publisherName,
            readerName: this.userName,
          });
        }

        // this.followersAPI();
        collections.followChannel = true;
      });
    }
  }
  likeCollections(collections: any, likes: Boolean, id: any) {
    console.log('clevertap succefully event', clevertap.event);
    this.collectionsClick(collections._id);
    if (this.productService.getToken() == '') {
      //clevertap intergation event

      if (environment.CLEVERTAP) {
        clevertap.event.push('PreLoginBlinks', {
          PreLoginBlinks: 'PreLogin Blink click',
          PreLoginBlinksSummaryName: collections.summaryName,
          PreLoginBlinksId: collections._id,
        });
      }

      //clevertap intergation event
      this.dialog.open(NoAuthSignupComponent, {
        data: {
          title: 'Blink collection',
          ShareLink: `/c/${collections.summaryName.split(' ').join('-')}/id-${
            collections._id
          }`,
          disableClose: true,
        },
      });
    } else {
      this.productService
        .userLikeCollections(collections._id)
        .subscribe((res) => {
          //clevertap intergation event
          if (environment.CLEVERTAP) {
            clevertap.event.push('PostLoginBlinks', {
              PostLoginBlinks: 'PostLogin Blink collections',
              PostLoginBlinksSummaryName: collections.summaryName,
              PostLoginBlinksId: collections._id,
            });
          }

          //clevertap intergation event
          // this.lottieimage = true;
          collections.summaryLikeCount++;
          collections.summaryLike = true;

          // setTimeout(() => {
          //   this.lottieimage = false;
          // }, 3000);
        });
    }
  }
  followCollectionCatergory(
    item: any,
    catergoriesFollowed: any,
    collections: any
  ) {
    console.log(item);
    this.collectionsClick(collections._id);
    if (this.productService.getToken() == '') {
      if (environment.CLEVERTAP) {
        clevertap.event.push('TopicsProflieClicks', {
          TopicsProflieClicks: item,
        });
      }
      this.dialog.open(NoAuthSignupComponent, {
        data: {
          title: 'Join this community',
          ShareLink: `/c/${collections.summaryName.split(' ').join('-')}/id-${
            collections._id
          }`,
          disableClose: true,
        },
      });
    } else {
      if (item) {
        let obj = {
          topicId: item._id,
        };
        // console.log(obj);
        this.productService.caterogyFollow(obj).subscribe((res) => {
          // console.log(obj);

          if (environment.CLEVERTAP) {
            clevertap.event.push('TopicsProflieFollows', {
              TopicsProflieName: item.name,
              readerName: this.userName,
            });
          }

          item.topicsFollow = true;
        });
      } else {
      }
    }
  }
  saveCollection(
    collections: any,
    bookmark: boolean,
    collectionId: any,
    title: any
  ) {
    this.collectionsClick(collections._id);
    if (this.productService.getToken() == '') {
      //clevertap intergation event

      if (environment.CLEVERTAP) {
        clevertap.event.push('PreLoginBookmarks', {
          PreLoginBookmarks: 'Pre login bookmark click',
          PreLoginBookmarksSummarieName: collections.summaryName,
          PreLoginBookmarksSummarieId: collections._id,
        });
      }

      //clevertap intergation event
      this.dialog.open(NoAuthSignupComponent, {
        data: {
          title: 'Read later this collection',
          ShareLink: `/c/${title.split(' ').join('-')}/id-${collectionId}`,
          disableClose: true,
        },
      });
    } else {
      let obj = {
        summaryId: collectionId,
      };
      this.productService.userCollectionGet(obj).subscribe(
        (res) => {
          //clevertap intergation event
          if (environment.CLEVERTAP) {
            clevertap.event.push('PostLoginBookmarks', {
              PostLoginBookmarksreadSummaryLater: 'Summary has been bookmarked',
              PostLoginBookmarksSummarieName: collections.summaryName,
              PostLoginBookmarksSummarieId: collections._id,
            });
          }

          //clevertap intergation event
          collections.bookmarkSummary = true;
        },
        (error) => {}
      );
    }
  }
  /* follow channel ,tweet event , subscribe channel , likeCollections , save collections ,followCollectionCatergory button */

  /*  collectionsClicks APi call , tab changed  */
  collectionsClick(item: any) {
    let click = {
      collectionId: item,
    };
    this.productService.collectionClicks(click, item).subscribe((result) => {
      console.log(result);
    });
  }

  tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {
    this.tabindex = tabChangeEvent.index;

    this.productType = tabChangeEvent.tab.textLabel.toLowerCase();
    console.log('productType => ', this.productType);

    if (this.productType === 'english summaries') {
      this.productType = 'english';
    }

    // if (this.productType == 'my summaries') {
    //   if (this.productService.getToken() != '') {
    //     this.queryParms = {
    //       topicName: this.catergoryName[2],
    //       id: this.userTypeId,
    //       language: this.productType,
    //       pageLimit: this.pageSize ? this.pageSize : '',
    //       pageNumber: this.currentPage,
    //     };
    //   } else {
    //     this.queryParms = {
    //       topicName: this.catergoryName[2],
    //       language: this.productType,
    //       pageLimit: this.pageSize ? this.pageSize : '',
    //       pageNumber: this.currentPage,
    //     };
    //   }
    //   console.log('productType => ', this.productType);
    //   this.router.navigate([`/${this.catergoryName[2]}/product`]);
    //   this.productService
    //     .collectionListFliter(this.queryParms)
    //     .subscribe((res: any) => {
    //       this.productTypeDetails = res.productData;
    //       // this.collectionList = res.collectionData;
    //     });
    // } else {
    if (this.productService.getToken() == '') {
      this.queryParms = {
        topicName: this.catergoryName[2],
        language: this.productType,
        pageLimit: this.pageSize ? this.pageSize : '',
        pageNumber: this.currentPage,
      };
    } else {
      this.queryParms = {
        topicName: this.catergoryName[2],
        language: this.productType,
        id: this.userTypeId,
        pageLimit: this.pageSize ? this.pageSize : '',
        pageNumber: this.currentPage,
      };
    }
    this.loading = true;
    this.productService
      .collectionListFliter(this.queryParms)
      .subscribe((res: any) => {
        this.loading = false;
        this.collectionLanguageList = res.data;
      });

    //  if (tabChangeEvent.index == 1) {
    //    console.log('productType => ', this.productType);
    //    this.router.navigate([`/${this.catergoryName[2]}/language/chinese`]);
    //    this.selectedTab = 1;

    //    // this.router.urlUpdateStrategy('')
    //  } else if (tabChangeEvent.index == 0) {
    //    this.router.navigate([`/${this.catergoryName[2]}/mysummaries`]);
    //  } else if (tabChangeEvent.index == 2) {
    //    this.router.navigate([`/${this.catergoryName[2]}/language/hindi`]);
    //  } else if (tabChangeEvent.index == 3) {
    //    this.router.navigate([`/${this.catergoryName[2]}/language/spanish`]);
    //  } else if (tabChangeEvent.index == 4) {
    //    this.router.navigate([`/${this.catergoryName[2]}/language/arabic`]);
    //  } else if (tabChangeEvent.index == 5) {
    //    this.router.navigate([`/${this.catergoryName[2]}/language/telugu`]);
    //  } else if (tabChangeEvent.index == 6) {
    //    this.router.navigate([`/${this.catergoryName[2]}/language/russian`]);
    //  } else if (tabChangeEvent.index == 7) {
    //    this.router.navigate([`/${this.catergoryName[2]}/language/german`]);
    //  } else if (tabChangeEvent.index == 8) {
    //    this.router.navigate([`/${this.catergoryName[2]}/language/tamil`]);
    //  } else if (tabChangeEvent.index == 9) {
    //    this.router.navigate([`/${this.catergoryName[2]}/language/malayalam`]);
    //  }
  };
  /*  collectionsClicks APi call , tab changed  */

  unFollowBtn(categories: any) {
    // console.log(categories);
    if (this.productService.getToken() == '') {
      this.dialog.open(NoAuthSignupComponent, {
        data: {
          title: 'Join this community',
          disableClose: true,
        },
      });
    } else {
      this.productService
        .caterogyUnFollow(categories._id)
        .subscribe((response) => {
          categories.categoriesFollow = false;
        });
    }
  }
  unFollowCategories(categories: any, boolena: any, collections: any) {
    console.log(categories);
    if (this.productService.getToken() == '') {
      this.dialog.open(NoAuthSignupFollowComponent, {
        data: {
          title: 'Follow this channel',
          ShareLink: `/c/${collections.summaryName.split(' ').join('-')}/id-${
            collections._id
          }`,
          disableClose: true,
        },
      });
    } else {
      this.productService
        .caterogyUnFollow(categories._id)
        .subscribe((response) => {
          categories.topicsFollow = false;
        });
    }
  }
  tweetBtn(item: any) {
    // this.collectionsClick(item._id);
    // console.log(item);
    this.shareDataLink = `${environment.SHARE_URL}/c/${item.summaryName
      .split(' ')
      .join('-')}/id-${item._id}`;
    return `https://www.shareaholic.com/api/share/?v=1&apitype=1&apikey=2ceb7e6973521a10a3e4b25ad56072e7
  &service=twitter&title=${item.summaryName}&link=${
      environment.SHARE_URL
    }/c/${item.summaryName.split(' ').join('-')}/id-${
      item._id
    }/&source=Shareaholic`;
  }
  comments(collections: any) {
    if (collections.showCollectionComment) {
      // Close the comment if it's open
      collections.showCollectionComment = false;
    } else {
      this.collectionList.forEach((c: any) => {
        if (c !== collections.showCollectionComment) {
          c.showCollectionComment = false;
        }
      });
      collections.showCollectionComment = true;
    }

    if (collections._id && collections.showCollectionComment == true) {
      this.summaryId = collections._id;
      this.commentsGet();
    }
  }

  // comment ts file
  onSubmitComment(collections: any) {
    console.log(this.commentForm.value);
    if (this.productService.getToken() != '') {
      if (this.commentForm) {
        this.commentBtnEvent = false;
        let obj = {
          collectionId: collections._id,
          comment: this.commentForm.value.title,
        };
        this.productService.commentPost(obj).subscribe((response) => {
          console.log(response);
          // this.commentUser = response.data;
          this.commentsGet();
          this.cancelComment();
        });
      }
    }
  }

  commentsGet() {
    this.showCommentLoader = true;
    this.productService.commentGet(this.summaryId).subscribe((response) => {
      this.commentUser = response.data;
      this.showCommentLoader = false;

      this.totalComments = response.totalComment;
      this.commentUser.forEach((comment: any) => {
        return (comment.showReplyForm = false), (comment.showReplys = false);
      });
      console.log(this.commentUser);
    });
  }
  get commentFormError(): { [key: string]: AbstractControl } {
    return this.commentForm.controls;
  }
  get replyCommentFormError(): { [key: string]: AbstractControl } {
    return this.replyCommentForm.controls;
  }
  // validations for AlphabetsOnly
  nameValidations(event: any, item: any) {
    if (item == 'title') {
      let newValue = event.target.value.replace(/[^a-zA-Z ]/g, '');
      console.log('alphabets', newValue);
      this.commentForm.controls['title'].setValue(newValue);
    } else if (item == 'edit') {
      let newValue = event.target.value.replace(/[^a-zA-Z ]/g, '');
      console.log('alphabets', newValue);
      this.editCommentForm.controls['edit'].setValue(newValue);
    } else if (item == 'reply') {
      let newValue = event.target.value.replace(/[^a-zA-Z ]/g, '');
      console.log('alphabets', newValue);
      this.replyCommentForm.controls['reply'].setValue(newValue);
    } else if (item == 'replyEdit') {
      let newValue = event.target.value.replace(/[^a-zA-Z ]/g, '');
      console.log('alphabets', newValue);
      this.replyEditCommentForm.controls['replyEdit'].setValue(newValue);
    }
  }
  showReplyBtn(item: any) {
    item.showReplys = !item.showReplys;
  }
  cancelComment() {
    this.inputActive = false;
    this.showButtons = false;
    this.commentForm.reset();
    this.editActive = false;
    this.showEditButtons = false;
  }
  spamComment(comment: any, collections: any) {
    console.log(comment._id);
    if (this.productService.getToken() == '') {
      this.readonly = true;
      this.dialog.open(NoAuthSignupComponent, {
        data: {
          title: 'to mark as spam',
          ShareLink: `/c/${collections.summaryName.split(' ').join('-')}/id-${
            collections._id
          }`,
          disableClose: true,
        },
      });
    } else {
      let obj = {
        commentReplyId: '',
      };
      this.productService.commentSpam(obj, comment._id).subscribe(
        (res) => {
          console.log(res);
          Swal.fire({
            title: 'successfully marked as spam',
            icon: 'success', // You can change the icon type
            timer: 2000, // Set the timer to 3000 milliseconds (3 seconds)
            showConfirmButton: false, // Hide the "OK" button
          });
        },
        (error) => {
          console.log(error);
          Swal.fire({
            title: error.error.message,
            icon: 'error', // You can change the icon type
            timer: 2000, // Set the timer to 3000 milliseconds (3 seconds)
            showConfirmButton: false, // Hide the "OK" button
          });
        }
      );
    }
  }
  spamReplyComment(comment: any, replyId: any, collections: any) {
    console.log('commentId', comment._id);
    console.log('repltID', replyId);
    if (this.productService.getToken() == '') {
      this.readonly = true;
      this.dialog.open(NoAuthSignupComponent, {
        data: {
          title: 'to mark as spam',
          ShareLink: `/c/${collections.summaryName.split(' ').join('-')}/id-${
            collections._id
          }`,
          disableClose: true,
        },
      });
    } else {
      let obj = {
        commentReplyId: replyId,
      };
      this.productService.commentSpam(obj, comment._id).subscribe(
        (res) => {
          console.log(res);
          Swal.fire({
            title: 'successfully marked as spam',
            icon: 'success', // You can change the icon type
            timer: 2000, // Set the timer to 3000 milliseconds (3 seconds)
            showConfirmButton: false, // Hide the "OK" button
          });
        },
        (error) => {
          Swal.fire({
            title: error.error.message,
            icon: 'error', // You can change the icon type
            timer: 2000, // Set the timer to 3000 milliseconds (3 seconds)
            showConfirmButton: false, // Hide the "OK" button
          });
        }
      );
    }
  }
  spamReplyOffensive(comment: any, replyId: any, collections: any) {
    console.log('commentId', comment._id);
    console.log('repltID', replyId);

    if (this.productService.getToken() == '') {
      this.readonly = true;
      this.dialog.open(NoAuthSignupComponent, {
        data: {
          title: 'to mark as spam',
          ShareLink: `/c/${collections.summaryName.split(' ').join('-')}/id-${
            collections._id
          }`,
          disableClose: true,
        },
      });
    } else {
      let obj = {
        commentReplyId: replyId,
      };
      this.productService.commentOffensive(obj, comment._id).subscribe(
        (res) => {
          console.log(res);
          Swal.fire({
            title: 'successfully marked as spam',
            icon: 'success', // You can change the icon type
            timer: 2000, // Set the timer to 3000 milliseconds (3 seconds)
            showConfirmButton: false, // Hide the "OK" button
          });
        },
        (error) => {
          Swal.fire({
            title: error.error.message,
            icon: 'error', // You can change the icon type
            timer: 2000, // Set the timer to 3000 milliseconds (3 seconds)
            showConfirmButton: false, // Hide the "OK" button
          });
        }
      );
    }
  }
  spamOffensive(comment: any, collections: any) {
    if (this.productService.getToken() == '') {
      this.readonly = true;
      this.dialog.open(NoAuthSignupComponent, {
        data: {
          title: 'to mark as offensive',
          ShareLink: `/c/${collections.summaryName.split(' ').join('-')}/id-${
            collections._id
          }`,
          disableClose: true,
        },
      });
    } else {
      let obj = {
        commentReplyId: '',
      };
      this.productService.commentOffensive(obj, comment._id).subscribe(
        (res) => {
          console.log(res);
          Swal.fire({
            title: 'successfully marked as offensive',
            icon: 'success', // You can change the icon type
            timer: 2000, // Set the timer to 3000 milliseconds (3 seconds)
            showConfirmButton: false, // Hide the "OK" button
          });
        },
        (error) => {
          console.log(error);
          Swal.fire({
            title: error.error.message,
            icon: 'error', // You can change the icon type
            timer: 2000, // Set the timer to 3000 milliseconds (3 seconds)
            showConfirmButton: false, // Hide the "OK" button
          });
        }
      );
    }
  }
  deleteCommentPublisherandPowerUser(comments: any) {
    console.log('commentID', comments._id);
    console.log('collectionID', this.CollectionID);
    let obj = {
      collectionId: this.CollectionID,
      commentId: comments._id,
    };
    this.productService.deleteCommentPublisher(obj).subscribe((res) => {
      this.commentsGet();
      Swal.fire({
        title: 'Comment delete successfully',
        icon: 'success', // You can change the icon type
        timer: 2000, // Set the timer to 3000 milliseconds (3 seconds)
        showConfirmButton: false, // Hide the "OK" button
      });
    });
  }
  deleteReplyCommentPublisherandPowerUser(comments: any, replyId: any) {
    console.log('commentID', comments._id);
    console.log('collectionID', this.CollectionID);
    let obj = {
      collectionId: this.CollectionID,
      commentId: comments._id,
      commentReplyId: replyId,
    };
    this.productService.deleteCommentPublisher(obj).subscribe((res) => {
      this.commentsGet();
      Swal.fire({
        title: 'Comment delete successfully',
        icon: 'success', // You can change the icon type
        timer: 2000, // Set the timer to 3000 milliseconds (3 seconds)
        showConfirmButton: false, // Hide the "OK" button
      });
    });
  }
  // edit and delete comment buttons and api calls
  editMainComment(editData: any) {
    editData.isEdit = !editData.isEdit;
    console.log(editData);
    this.editCommentForm.controls['edit'].setValue(editData.comment);

    this.toggleEditButtons();
  }
  editReplyComment(editData: any) {
    editData.isEdit = !editData.isEdit;
    console.log(editData);
    this.replyEditCommentForm.controls['replyEdit'].setValue(editData.comment);

    this.toggleReplyEditButtons();
  }
  cancelEditComment(item: any) {
    item.isEdit = false;
    this.editActive = false;
    this.showEditButtons = false;
  }
  saveEditForm(editData: any) {
    console.log(this.editCommentForm.value.edit);
    let obj = {
      comment: this.editCommentForm.value.edit,
      commentReply: false,
    };
    this.productService
      .commentUpdateReply(obj, editData._id)
      .subscribe((response) => {
        this.commentsGet();
      });
  }
  deletComment(editData: any) {
    console.log(this.editCommentForm.value.edit);
    let obj = {
      commentReplyId: '',
    };
    this.productService
      .commentDeleteReply(obj, editData._id)
      .subscribe((response) => {
        this.commentsGet();
      });
  }
  deletReplyComment(editData: any, replyId: any) {
    console.log(this.editCommentForm.value.edit, replyId);
    let obj = {
      commentReplyId: replyId,
    };
    this.productService
      .commentDeleteReply(obj, editData._id)
      .subscribe((response) => {
        this.commentsGet();
      });
  }
  saveReplyEditForm(editData: any) {
    console.log(this.replyEditCommentForm.value.replyEdit);
    let obj = {
      comment: this.replyEditCommentForm.value.replyEdit,
      commentReply: true,
    };
    this.productService
      .commentUpdateReply(obj, editData._id)
      .subscribe((response) => {
        this.commentsGet();
      });
  }
  toggleEditButtons() {
    if (this.productService.getToken() != '') {
      this.editActive = true;
      this.readonly = false;
      this.showEditButtons = true;
    }
  }
  toggleReplyEditButtons() {
    if (this.productService.getToken() != '') {
      this.editActive = true;
      this.readonly = false;
      this.showReplyEditButtons = true;
    }
  }
  // edit and delete comment buttons and api calls
  commentEvent() {
    if (this.productService.getToken() == '') {
      this.readonly = true;
      this.dialog.open(NoAuthSignupComponent, {
        data: {
          title: 'to write the comment',

          // ShareLink: `/c/${this.userDetailsParams.split(' ').join('-')}/id-${
          //   this.product_id
          // }`,
          disableClose: true,
        },
      });
    }
  }
  toggleButtons() {
    if (this.productService.getToken() != '') {
      this.inputActive = true;
      this.readonly = false;
      this.showButtons = true;
    }
  }

  // reply comment ts file
  replyShowInput(comment: any) {
    if (this.productService.getToken() == '') {
      this.readonly = true;
      this.dialog.open(NoAuthSignupComponent, {
        data: {
          title: 'to reply the comment',
          // image: this.thumbnailImage,
          // ShareLink: `/c/${this.userDetailsParams.split(' ').join('-')}/id-${
          //   this.product_id
          // }`,
          disableClose: true,
        },
      });
    } else {
      console.log('replyId', comment._id);
      this.commentId = comment._id;
      comment.showReplyForm = !comment.showReplyForm;
    }
  }

  commentEventReply(e: any) {
    if (e.target.value != '') {
      this.commentBtnEventReply = false;
    } else {
      this.commentBtnEventReply = true;
    }
  }

  cancelCommentReply(comments: any) {
    this.inputActive = false;
    this.showButtonsReply = false;
    comments.showReplyForm = false;
    this.replyCommentForm.reset();
  }
  onReplyComment() {
    let obj = {
      comment: this.replyCommentForm.value.reply,
    };
    this.productService
      .commentReply(obj, this.commentId)
      .subscribe((response) => {
        this.replyCommentShow = false;
        this.commentsGet();
        this.inputActive = false;
        this.showButtonsReply = false;
        this.replyCommentForm.reset();
      });
    // this.Replycomment = this.replyCommentForm.value.reply;
    // this.ReplycommentUser.push({
    //   name: 'ram',
    //   comment: this.Replycomment,
    //   date: '2023-09-07T05:28:39.342Z',
    //   id: '3456',
    // });
  }

  toggleButtonsReply() {
    this.inputActive = true;
    this.showButtonsReply = true;
  }
}
