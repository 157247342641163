
<app-creatornavbar ></app-creatornavbar>

<div class="main">
<div class="container">

     
        <div class="list-of-products mt-md-3 pt-md-3">
              

            <div class="d-flex">
                <div class="d-flex mr-md-5">
 <div class="profile-img">
                     <img *ngIf="ChannelNameProfile == null " src="../../assets/images/pro-1.jpg">
                     <img *ngIf="ChannelNameProfile != null " src="{{ChannelNameProfile}}">
                 </div>
                 <div class="ml-md-4 mt-md-4 name-fields">
                     <h3>{{ChannelNameCreator}}</h3>
                     <h4><a href="{{ChannelNameUrl}}" target="_blank">{{ChannelNameUrl}}</a></h4>
                     <!-- <div class="d-flex">
                        <p><span>{{totalFollowers}}</span> followers</p>
                        <p class="ml-4"><span>{{totalProducts}} </span>Total products</p>
                     </div> -->
                 </div>
                </div>
<!--                 
                  <div class="follow">
                        <button (click)="followBtn()" type="submit" *ngIf="followBtnShow">
                             Follow
                        </button>
                        <button class="button" type="submit" (click)="unFollowBtn()" *ngIf="unfollowBtnShow">
                            <span>following</span>
                        </button>
                    </div> -->
            </div>
            <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" class="mt-md-4" [selectedIndex]="selectedTab" (selectedTabChange)="tabChanged($event)">
                 <mat-tab label=" My collections">   <div class="row" >

               
             <div class="col-md-4" *ngFor="let list of collectionList">
                <div class="collection-prodcuts mt-md-4 pb-md-4 ml-md-3 pointer" (click)="CollectionUrl(list.collectionName,list._id)">
                     <img src="{{
                              'https://img.youtube.com/vi/' +
                                list.sourceURL?.split('=')?.pop() +
                                '/hqdefault.jpg'
                            }}">
                   
                     <P class="pt-md-2" >{{list.collectionName}}</P>
                     
                </div>
             </div>
              </div></mat-tab>
  <mat-tab label=" My products">  
     <div class="row ml-md-3 mt-md-3 ">
  
            <div class="col-md-4" *ngFor="let cardDetails of productList;let i = index">
              
  <div class="collection-header product_details_Paragraph">
      <div class="list-of-products mt-md-3 pt-md-3" id="product-related">
        <!-- <h1 *ngIf="collectionCreatedByCreator != ''" class="py-md-3">Products used in this video by the creator</h1>
        <div class="c-p mt-md-5" *ngIf="collectionCreatedByCreator == ''">
          <h4>No products found in this collection</h4>
        </div> -->
         
           
              <div class="card mb-md-4" >
                <div class="card-body pointer">
                  <div class="product_list">
                    <!-- <div class="d-flex list-P"></div> -->
                    <img *ngIf="cardDetails?.image == null && cardDetails?.productImage == ''" (click)="productDetails(cardDetails.productTitle,cardDetails._id)" src="../../../assets/images/n-image.png" class="code-1-img image">
                    <img *ngIf="cardDetails?.image != null " (click)="productDetails(cardDetails.productTitle,cardDetails._id)" src="{{cardDetails?.image}}" class="code-1-img image">
                    <img *ngIf="cardDetails?.productImage != ''" (click)="productDetails(cardDetails.productTitle,cardDetails._id)" src="{{cardDetails?.productImage[0]?.url}}" class="code-1-img">
                    <div class="d-flex">
                      <div class="mr-auto">
                        <p class="header-p pt-2">{{cardDetails.productTitle | slice:0:24}}</p>
                      </div>
                       
                    </div>
                      <div class="d-flex">
                         <div class="mr-auto collection-name-link ml-3">
                          <a routerLink="/p/{{cardDetails.productTitle.split(' ').join('-')}}/id-{{cardDetails._id}}">
                            <h5>View Product</h5>
                          </a>
                        </div>
                         <div class="save-for-later d-flex  mr-3">
                        <div>
                          <img src="../../../assets/images/heart.svg">
                        </div>
                       <div>
                          <a (click)="saveForlater(cardDetails._id)">
                            <h5>Bookmark</h5>
                           
                          </a>

                           
                        </div>
                      </div>
                          
                      </div>
                      
                  </div>
                  
                  <div class="product_list_details">
                    <div class="d-flex">
                      <!-- <div class=""><img src="../../../assets/images/heart.png"></div><div class="save-for-later"><a><h5>Save for later</h5></a></div> -->
                      <!-- <div class="list_price"><p>${{cardDetails.price}}</p></div> -->
                      <!-- <div class="product_list_sold_on ml-auto"><h4>Sold on</h4><p class="product-website-name">{{cardDetails.affiliatePartnerName ? cardDetails.affiliatePartnerName : 'N/A'}}</p></div> -->
                    </div>
                    <div class="details-List-view" *ngIf="cardDetails.couponCode != ''">
                      <p>Creator Discount Code Available</p>
                      <div class="creator-code">
                        <div class="d-flex code-d">
                          <div class="code-p">
                            <!-- <p>{{cardDetails.couponCode}}</p> -->
                            <p>******</p>
                          </div>
                          <div class="copy-btn ml-auto">
                            <button type="button" class="ml-auto">Show</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <h5 class="mt-md-2 pointer">Share this product with friends</h5> -->
                  </div>
                </div>
              </div>
            <!-- <p *ngIf="saved"  class="succesfully-p mt-1">
                  Product Bookmarked successfully
                </p>
                 <p *ngIf="productError != null" class="mt-md-3 code-d-c">
            {{ productError }}
          </p> -->
          </div>
        </div>
    
    
                  <!-- <div class="card mb-md-4 pointer" (click)="viewProduct(cardDetails.productTitle,cardDetails._id)">
                    <div class="card-body">
                       <div class="product_list">
                         <div class="d-flex list-P">
                             </div>
                             <img *ngIf="cardDetails.image != null" src="{{ cardDetails.image }}" class="d-1-img" />
            <img *ngIf="cardDetails.productImage != ''" src="{{ cardDetails?.productImage[0]?.url }}" class="d-1-img" />

                             <p class="header-p py-3">{{cardDetails.productTitle  | slice:0:24}}</p>
                       </div>

                       <div class="product_list_details">

                         <div class="d-flex">
                             
                             <div class="product_list_sold_on ml-auto">
                                 <h4>Sold on</h4>
                            <p>{{cardDetails.affiliatePartnerName ? cardDetails.affiliatePartnerName : 'N/A'}}</p>
                                 
                             </div>
                         </div>

                         <div class="details-List-view" *ngIf="cardDetails.couponCode != ''">
                             <p>Creator Discount Code Available</p>
                             <div class="creator-code">
                           
                           <div class="d-flex code-d">
                            <div class="code-p">
                             
                               <p>*****</p>


                            </div>
                            <div class="copy-btn ml-auto">
                               <button type="button" class="ml-auto pointer" (click)="viewProduct(cardDetails.productTitle,cardDetails._id)">Show</button>
 
                            </div>
                           </div>
                        </div>
                            
                         </div>
                            <h5 class="mt-md-2 pointer">Share this product with friends</h5>

                       </div>

                    </div>
                  </div> -->
            </div>
        </div>
    </mat-tab>
 
  <mat-tab label="Apps I promote"> <div class="row ml-md-3 mt-md-3 ">
  
            <div class="col-md-4" *ngFor="let cardDetails of productTypeDetails;let i = index">
               <div class="collection-header product_details_Paragraph">
      <div class="list-of-products mt-md-3 pt-md-3" id="product-related">
        <!-- <h1 *ngIf="collectionCreatedByCreator != ''" class="py-md-3">Products used in this video by the creator</h1>
        <div class="c-p mt-md-5" *ngIf="collectionCreatedByCreator == ''">
          <h4>No products found in this collection</h4>
        </div> -->
         
           
              <div class="card mb-md-4" >
                <div class="card-body pointer">
                  <div class="product_list">
                    <!-- <div class="d-flex list-P"></div> -->
                    <img *ngIf="cardDetails?.image == null && cardDetails?.productImage == ''" (click)="productDetails(cardDetails.productTitle,cardDetails._id)" src="../../../assets/images/n-image.png" class="code-1-img image">
                    <img *ngIf="cardDetails?.image != null " (click)="productDetails(cardDetails.productTitle,cardDetails._id)" src="{{cardDetails?.image}}" class="code-1-img image">
                    <img *ngIf="cardDetails?.productImage != ''" (click)="productDetails(cardDetails.productTitle,cardDetails._id)" src="{{cardDetails?.productImage[0]?.url}}" class="code-1-img">
                    <div class="d-flex">
                      <div class="mr-auto">
                        <p class="header-p pt-2">{{cardDetails.productTitle | slice:0:24}}</p>
                      </div>
                       
                    </div>
                      <div class="d-flex">
                         <div class="mr-auto collection-name-link ml-3">
                          <a routerLink="/p/{{cardDetails.productTitle.split(' ').join('-')}}/id-{{cardDetails._id}}">
                            <h5>View Product</h5>
                          </a>
                        </div>
                         <div class="save-for-later d-flex  mr-3">
                        <div>
                          <img src="../../../assets/images/heart.svg">
                        </div>
                       <div>
                          <a (click)="saveForlater(cardDetails._id)">
                            <h5>Bookmark</h5>
                           
                          </a>

                           
                        </div>
                      </div>
                          
                      </div>
                      
                  </div>
                  
                  <div class="product_list_details">
                    <div class="d-flex">
                      <!-- <div class=""><img src="../../../assets/images/heart.png"></div><div class="save-for-later"><a><h5>Save for later</h5></a></div> -->
                      <!-- <div class="list_price"><p>${{cardDetails.price}}</p></div> -->
                      <!-- <div class="product_list_sold_on ml-auto"><h4>Sold on</h4><p class="product-website-name">{{cardDetails.affiliatePartnerName ? cardDetails.affiliatePartnerName : 'N/A'}}</p></div> -->
                    </div>
                    <div class="details-List-view" *ngIf="cardDetails.couponCode != ''">
                      <p>Creator Discount Code Available</p>
                      <div class="creator-code">
                        <div class="d-flex code-d">
                          <div class="code-p">
                            <!-- <p>{{cardDetails.couponCode}}</p> -->
                            <p>******</p>
                          </div>
                          <div class="copy-btn ml-auto">
                            <button type="button" class="ml-auto">Show</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <h5 class="mt-md-2 pointer">Share this product with friends</h5> -->
                  </div>
                </div>
              </div>
            <!-- <p *ngIf="saved"  class="succesfully-p mt-1">
                  Product Bookmarked successfully
                </p>
                 <p *ngIf="productError != null" class="mt-md-3 code-d-c">
            {{ productError }}
          </p> -->
          </div>
        </div>
                  <!-- <div class="card mb-md-4 pointer" (click)="viewProduct(cardDetails.productTitle,cardDetails._id)">
                    <div class="card-body">
                       <div class="product_list">
                         <div class="d-flex list-P">
                             </div>
                             <img src="{{cardDetails.productImage[0]?.url}}" class="code-1-img"> 

                             <p class="header-p py-3">{{cardDetails.productTitle  | slice:0:24}}</p>
                       </div>

                       <div class="product_list_details">

                         <div class="d-flex">
                             <div class="list_price">
                                 <p>${{cardDetails.price}}</p>
                             </div>
                             <div class="product_list_sold_on ml-auto">
                                 <h4>Sold on</h4>
                                 <img src="../../assets/brands/logo-1.png" lazyloading class="brand-img">
                             </div>
                         </div>

                         <div class="details-List-view">
                             <p>Creator Discount Code Available</p>
                             <div class="creator-code">
                           
                           <div class="d-flex code-d">
                            <div class="code-p">
                              <p *ngIf="show">{{cardDetails.couponCode}}</p>  
                               <p>*****</p>


                            </div>
                            <div class="copy-btn ml-auto">
                               <button type="button" class="ml-auto pointer" (click)="viewProduct(cardDetails.productTitle,cardDetails._id)">Show</button>
 
                            </div>
                           </div>
                        </div>
                            <h5 class="mt-md-2 pointer">Share this product with friends</h5>
                            
                         </div>
                       </div>

                    </div>
                  </div> -->
            </div>
        </div></mat-tab>
  <mat-tab label="Top Books">
     <div class="row ml-md-3 mt-md-3 ">
  
            <div class="col-md-4" *ngFor="let cardDetails of productTypeDetails;let i = index">
                  <div class="collection-header product_details_Paragraph">
      <div class="list-of-products mt-md-3 pt-md-3" id="product-related">
        <!-- <h1 *ngIf="collectionCreatedByCreator != ''" class="py-md-3">Products used in this video by the creator</h1>
        <div class="c-p mt-md-5" *ngIf="collectionCreatedByCreator == ''">
          <h4>No products found in this collection</h4>
        </div> -->
         
           
              <div class="card mb-md-4" >
                <div class="card-body pointer">
                  <div class="product_list">
                    <!-- <div class="d-flex list-P"></div> -->
                    <img *ngIf="cardDetails?.image == null && cardDetails?.productImage == ''" (click)="productDetails(cardDetails.productTitle,cardDetails._id)" src="../../../assets/images/n-image.png" class="code-1-img image">
                    <img *ngIf="cardDetails?.image != null " (click)="productDetails(cardDetails.productTitle,cardDetails._id)" src="{{cardDetails?.image}}" class="code-1-img image">
                    <img *ngIf="cardDetails?.productImage != ''" (click)="productDetails(cardDetails.productTitle,cardDetails._id)" src="{{cardDetails?.productImage[0]?.url}}" class="code-1-img">
                    <div class="d-flex">
                      <div class="mr-auto">
                        <p class="header-p pt-2">{{cardDetails.productTitle | slice:0:24}}</p>
                      </div>
                       
                    </div>
                      <div class="d-flex">
                         <div class="mr-auto collection-name-link ml-3">
                          <a routerLink="/p/{{cardDetails.productTitle.split(' ').join('-')}}/id-{{cardDetails._id}}">
                            <h5>View Product</h5>
                          </a>
                        </div>
                         <div class="save-for-later d-flex  mr-3">
                        <div>
                          <img src="../../../assets/images/heart.svg">
                        </div>
                       <div>
                          <a (click)="saveForlater(cardDetails._id)">
                            <h5>Bookmark</h5>
                           
                          </a>

                           
                        </div>
                      </div>
                          
                      </div>
                      
                  </div>
                  
                  <div class="product_list_details">
                    <div class="d-flex">
                      <!-- <div class=""><img src="../../../assets/images/heart.png"></div><div class="save-for-later"><a><h5>Save for later</h5></a></div> -->
                      <!-- <div class="list_price"><p>${{cardDetails.price}}</p></div> -->
                      <!-- <div class="product_list_sold_on ml-auto"><h4>Sold on</h4><p class="product-website-name">{{cardDetails.affiliatePartnerName ? cardDetails.affiliatePartnerName : 'N/A'}}</p></div> -->
                    </div>
                    <div class="details-List-view" *ngIf="cardDetails.couponCode != ''">
                      <p>Creator Discount Code Available</p>
                      <div class="creator-code">
                        <div class="d-flex code-d">
                          <div class="code-p">
                            <!-- <p>{{cardDetails.couponCode}}</p> -->
                            <p>******</p>
                          </div>
                          <div class="copy-btn ml-auto">
                            <button type="button" class="ml-auto">Show</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <h5 class="mt-md-2 pointer">Share this product with friends</h5> -->
                  </div>
                </div>
              </div>
            <!-- <p *ngIf="saved"  class="succesfully-p mt-1">
                  Product Bookmarked successfully
                </p>
                 <p *ngIf="productError != null" class="mt-md-3 code-d-c">
            {{ productError }}
          </p> -->
          </div>
        </div>
            </div>
        </div></mat-tab>
  <mat-tab label="My Deals"> <div class="row ml-md-3 mt-md-3 ">
  
            <div class="col-md-4" *ngFor="let cardDetails of productTypeDetails;let i = index">
                   <div class="collection-header product_details_Paragraph">
      <div class="list-of-products mt-md-3 pt-md-3" id="product-related">
        <!-- <h1 *ngIf="collectionCreatedByCreator != ''" class="py-md-3">Products used in this video by the creator</h1>
        <div class="c-p mt-md-5" *ngIf="collectionCreatedByCreator == ''">
          <h4>No products found in this collection</h4>
        </div> -->
         
           
              <div class="card mb-md-4" >
                <div class="card-body pointer">
                  <div class="product_list">
                    <!-- <div class="d-flex list-P"></div> -->
                    <img *ngIf="cardDetails?.image == null && cardDetails?.productImage == ''" (click)="productDetails(cardDetails.productTitle,cardDetails._id)" src="../../../assets/images/n-image.png" class="code-1-img image">
                    <img *ngIf="cardDetails?.image != null " (click)="productDetails(cardDetails.productTitle,cardDetails._id)" src="{{cardDetails?.image}}" class="code-1-img image">
                    <img *ngIf="cardDetails?.productImage != ''" (click)="productDetails(cardDetails.productTitle,cardDetails._id)" src="{{cardDetails?.productImage[0]?.url}}" class="code-1-img">
                    <div class="d-flex">
                      <div class="mr-auto">
                        <p class="header-p pt-2">{{cardDetails.productTitle | slice:0:24}}</p>
                      </div>
                       
                    </div>
                      <div class="d-flex">
                         <div class="mr-auto collection-name-link ml-3">
                          <a routerLink="/p/{{cardDetails.productTitle.split(' ').join('-')}}/id-{{cardDetails._id}}">
                            <h5>View Product</h5>
                          </a>
                        </div>
                         <div class="save-for-later d-flex  mr-3">
                        <div>
                          <img src="../../../assets/images/heart.svg">
                        </div>
                       <div>
                          <a (click)="saveForlater(cardDetails._id)">
                            <h5>Bookmark</h5>
                           
                          </a>

                           
                        </div>
                      </div>
                          
                      </div>
                      
                  </div>
                  
                  <div class="product_list_details">
                    <div class="d-flex">
                      <!-- <div class=""><img src="../../../assets/images/heart.png"></div><div class="save-for-later"><a><h5>Save for later</h5></a></div> -->
                      <!-- <div class="list_price"><p>${{cardDetails.price}}</p></div> -->
                      <!-- <div class="product_list_sold_on ml-auto"><h4>Sold on</h4><p class="product-website-name">{{cardDetails.affiliatePartnerName ? cardDetails.affiliatePartnerName : 'N/A'}}</p></div> -->
                    </div>
                    <div class="details-List-view" *ngIf="cardDetails.couponCode != ''">
                      <p>Creator Discount Code Available</p>
                      <div class="creator-code">
                        <div class="d-flex code-d">
                          <div class="code-p">
                            <!-- <p>{{cardDetails.couponCode}}</p> -->
                            <p>******</p>
                          </div>
                          <div class="copy-btn ml-auto">
                            <button type="button" class="ml-auto">Show</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <h5 class="mt-md-2 pointer">Share this product with friends</h5> -->
                  </div>
                </div>
              </div>
            <!-- <p *ngIf="saved"  class="succesfully-p mt-1">
                  Product Bookmarked successfully
                </p>
                 <p *ngIf="productError != null" class="mt-md-3 code-d-c">
            {{ productError }}
          </p> -->
          </div>
        </div>
            </div>
        </div></mat-tab>
  <mat-tab label="My Gear I use"> <div class="row ml-md-3 mt-md-3 ">
  
            <div class="col-md-4" *ngFor="let cardDetails of productTypeDetails;let i = index">
                    <div class="collection-header product_details_Paragraph">
      <div class="list-of-products mt-md-3 pt-md-3" id="product-related">
        <!-- <h1 *ngIf="collectionCreatedByCreator != ''" class="py-md-3">Products used in this video by the creator</h1>
        <div class="c-p mt-md-5" *ngIf="collectionCreatedByCreator == ''">
          <h4>No products found in this collection</h4>
        </div> -->
         
           
              <div class="card mb-md-4" >
                <div class="card-body pointer">
                  <div class="product_list">
                    <!-- <div class="d-flex list-P"></div> -->
                    <img *ngIf="cardDetails?.image == null && cardDetails?.productImage == ''" (click)="productDetails(cardDetails.productTitle,cardDetails._id)" src="../../../assets/images/n-image.png" class="code-1-img image">
                    <img *ngIf="cardDetails?.image != null " (click)="productDetails(cardDetails.productTitle,cardDetails._id)" src="{{cardDetails?.image}}" class="code-1-img image">
                    <img *ngIf="cardDetails?.productImage != ''" (click)="productDetails(cardDetails.productTitle,cardDetails._id)" src="{{cardDetails?.productImage[0]?.url}}" class="code-1-img">
                    <div class="d-flex">
                      <div class="mr-auto">
                        <p class="header-p pt-2">{{cardDetails.productTitle | slice:0:24}}</p>
                      </div>
                       
                    </div>
                      <div class="d-flex">
                         <div class="mr-auto collection-name-link ml-3">
                          <a routerLink="/p/{{cardDetails.productTitle.split(' ').join('-')}}/id-{{cardDetails._id}}">
                            <h5>View Product</h5>
                          </a>
                        </div>
                         <div class="save-for-later d-flex  mr-3">
                        <div>
                          <img src="../../../assets/images/heart.svg">
                        </div>
                       <div>
                          <a (click)="saveForlater(cardDetails._id)">
                            <h5>Bookmark</h5>
                           
                          </a>

                           
                        </div>
                      </div>
                          
                      </div>
                      
                  </div>
                  
                  <div class="product_list_details">
                    <div class="d-flex">
                      <!-- <div class=""><img src="../../../assets/images/heart.png"></div><div class="save-for-later"><a><h5>Save for later</h5></a></div> -->
                      <!-- <div class="list_price"><p>${{cardDetails.price}}</p></div> -->
                      <!-- <div class="product_list_sold_on ml-auto"><h4>Sold on</h4><p class="product-website-name">{{cardDetails.affiliatePartnerName ? cardDetails.affiliatePartnerName : 'N/A'}}</p></div> -->
                    </div>
                    <div class="details-List-view" *ngIf="cardDetails.couponCode != ''">
                      <p>Creator Discount Code Available</p>
                      <div class="creator-code">
                        <div class="d-flex code-d">
                          <div class="code-p">
                            <!-- <p>{{cardDetails.couponCode}}</p> -->
                            <p>******</p>
                          </div>
                          <div class="copy-btn ml-auto">
                            <button type="button" class="ml-auto">Show</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <h5 class="mt-md-2 pointer">Share this product with friends</h5> -->
                  </div>
                </div>
              </div>
            <!-- <p *ngIf="saved"  class="succesfully-p mt-1">
                  Product Bookmarked successfully
                </p>
                 <p *ngIf="productError != null" class="mt-md-3 code-d-c">
            {{ productError }}
          </p> -->
          </div>
        </div>
            </div>
        </div></mat-tab>
  <mat-tab label="Recommendations">
       <div class="row ml-md-3 mt-md-3">
  
            <div class="col-md-4" *ngFor="let cardDetails of productTypeDetails;let i = index">
                   <div class="collection-header product_details_Paragraph">
      <div class="list-of-products mt-md-3 pt-md-3" id="product-related">
        <!-- <h1 *ngIf="collectionCreatedByCreator != ''" class="py-md-3">Products used in this video by the creator</h1>
        <div class="c-p mt-md-5" *ngIf="collectionCreatedByCreator == ''">
          <h4>No products found in this collection</h4>
        </div> -->
         
           
              <div class="card mb-md-4" >
                <div class="card-body pointer">
                  <div class="product_list">
                    <!-- <div class="d-flex list-P"></div> -->
                    <img *ngIf="cardDetails?.image == null && cardDetails?.productImage == ''" (click)="productDetails(cardDetails.productTitle,cardDetails._id)" src="../../../assets/images/n-image.png" class="code-1-img image">
                    <img *ngIf="cardDetails?.image != null " (click)="productDetails(cardDetails.productTitle,cardDetails._id)" src="{{cardDetails?.image}}" class="code-1-img image">
                    <img *ngIf="cardDetails?.productImage != ''" (click)="productDetails(cardDetails.productTitle,cardDetails._id)" src="{{cardDetails?.productImage[0]?.url}}" class="code-1-img">
                    <div class="d-flex">
                      <div class="mr-auto">
                        <p class="header-p pt-2">{{cardDetails.productTitle | slice:0:24}}</p>
                      </div>
                       
                    </div>
                      <div class="d-flex">
                         <div class="mr-auto collection-name-link ml-3">
                          <a routerLink="/p/{{cardDetails.productTitle.split(' ').join('-')}}/id-{{cardDetails._id}}">
                            <h5>View Product</h5>
                          </a>
                        </div>
                         <div class="save-for-later d-flex  mr-3">
                        <div>
                          <img src="../../../assets/images/heart.svg">
                        </div>
                       <div>
                          <a (click)="saveForlater(cardDetails._id)">
                            <h5>Bookmark</h5>
                           
                          </a>

                           
                        </div>
                      </div>
                          
                      </div>
                      
                  </div>
                  
                  <div class="product_list_details">
                    <div class="d-flex">
                      <!-- <div class=""><img src="../../../assets/images/heart.png"></div><div class="save-for-later"><a><h5>Save for later</h5></a></div> -->
                      <!-- <div class="list_price"><p>${{cardDetails.price}}</p></div> -->
                      <!-- <div class="product_list_sold_on ml-auto"><h4>Sold on</h4><p class="product-website-name">{{cardDetails.affiliatePartnerName ? cardDetails.affiliatePartnerName : 'N/A'}}</p></div> -->
                    </div>
                    <div class="details-List-view" *ngIf="cardDetails.couponCode != ''">
                      <p>Creator Discount Code Available</p>
                      <div class="creator-code">
                        <div class="d-flex code-d">
                          <div class="code-p">
                            <!-- <p>{{cardDetails.couponCode}}</p> -->
                            <p>******</p>
                          </div>
                          <div class="copy-btn ml-auto">
                            <button type="button" class="ml-auto">Show</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <h5 class="mt-md-2 pointer">Share this product with friends</h5> -->
                  </div>
                </div>
              </div>
            <!-- <p *ngIf="saved"  class="succesfully-p mt-1">
                  Product Bookmarked successfully
                </p>
                 <p *ngIf="productError != null" class="mt-md-3 code-d-c">
            {{ productError }}
          </p> -->
          </div>
        </div>
            </div>
        </div>
  </mat-tab>
  <mat-tab label="Brand Deals">
    <div class="row ml-md-3 mt-md-3">
  
            <div class="col-md-4" *ngFor="let cardDetails of productTypeDetails;let i = index">
                   <div class="collection-header product_details_Paragraph">
      <div class="list-of-products mt-md-3 pt-md-3" id="product-related">
        <!-- <h1 *ngIf="collectionCreatedByCreator != ''" class="py-md-3">Products used in this video by the creator</h1>
        <div class="c-p mt-md-5" *ngIf="collectionCreatedByCreator == ''">
          <h4>No products found in this collection</h4>
        </div> -->
         
           
              <div class="card mb-md-4" >
                <div class="card-body pointer">
                  <div class="product_list">
                    <!-- <div class="d-flex list-P"></div> -->
                    <img *ngIf="cardDetails?.image == null && cardDetails?.productImage == ''" (click)="productDetails(cardDetails.productTitle,cardDetails._id)" src="../../../assets/images/n-image.png" class="code-1-img image">
                    <img *ngIf="cardDetails?.image != null " (click)="productDetails(cardDetails.productTitle,cardDetails._id)" src="{{cardDetails?.image}}" class="code-1-img image">
                    <img *ngIf="cardDetails?.productImage != ''" (click)="productDetails(cardDetails.productTitle,cardDetails._id)" src="{{cardDetails?.productImage[0]?.url}}" class="code-1-img">
                    <div class="d-flex">
                      <div class="mr-auto">
                        <p class="header-p pt-2">{{cardDetails.productTitle | slice:0:24}}</p>
                      </div>
                       
                    </div>
                      <div class="d-flex">
                         <div class="mr-auto collection-name-link ml-3">
                          <a routerLink="/p/{{cardDetails.productTitle.split(' ').join('-')}}/id-{{cardDetails._id}}">
                            <h5>View Product</h5>
                          </a>
                        </div>
                         <div class="save-for-later d-flex  mr-3">
                        <div>
                          <img src="../../../assets/images/heart.svg">
                        </div>
                       <div>
                          <a (click)="saveForlater(cardDetails._id)">
                            <h5>Bookmark</h5>
                           
                          </a>

                           
                        </div>
                      </div>
                          
                      </div>
                      
                  </div>
                  
                  <div class="product_list_details">
                    <div class="d-flex">
                      <!-- <div class=""><img src="../../../assets/images/heart.png"></div><div class="save-for-later"><a><h5>Save for later</h5></a></div> -->
                      <!-- <div class="list_price"><p>${{cardDetails.price}}</p></div> -->
                      <!-- <div class="product_list_sold_on ml-auto"><h4>Sold on</h4><p class="product-website-name">{{cardDetails.affiliatePartnerName ? cardDetails.affiliatePartnerName : 'N/A'}}</p></div> -->
                    </div>
                    <div class="details-List-view" *ngIf="cardDetails.couponCode != ''">
                      <p>Creator Discount Code Available</p>
                      <div class="creator-code">
                        <div class="d-flex code-d">
                          <div class="code-p">
                            <!-- <p>{{cardDetails.couponCode}}</p> -->
                            <p>******</p>
                          </div>
                          <div class="copy-btn ml-auto">
                            <button type="button" class="ml-auto">Show</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <h5 class="mt-md-2 pointer">Share this product with friends</h5> -->
                  </div>
                </div>
              </div>
            <!-- <p *ngIf="saved"  class="succesfully-p mt-1">
                  Product Bookmarked successfully
                </p>
                 <p *ngIf="productError != null" class="mt-md-3 code-d-c">
            {{ productError }}
          </p> -->
          </div>
        </div>
            </div>
        </div>
    </mat-tab>
  <mat-tab label="General">
    
  </mat-tab>

 
</mat-tab-group>
            <div class="nav-header ">
                <!-- <h6>Find products promoted and curated by top youtube creators in fashion, beauty, skin care</h6>
                <h3>Find all creators discount codes at one place</h3>
                <div class="d-flex mb-md-5">
                    <button class="category-btns pointer" (click)="product()"   [class.all]="activeProduct"  >
                       product
                    </button>              
                    <button class="category-btns pointer" (click)="collection()"  [class.all]="activeCollection"   >
                       collection
                    </button>              
                                 
                  </div> -->
                  

     

     
            </div>
       
    </div>
</div>

</div>


 
<app-channel-footer></app-channel-footer>
