import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fliter',
})
export class FliterPipe implements PipeTransform {
  public users: any = [];
  transform(value: any, fliterString: string) {
    if (value === 0 || fliterString === '') {
      return value;
    }
    // var users = [];
    for (let user of value) {
      if (user['name'] === fliterString) {
        this.users.push(user);
      }
    }
    return this.users;
  }
}
