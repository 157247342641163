 <app-navbar [(hideComponent)]="hideComponent" [(hideNotifications)]="hideNotifications"></app-navbar>
 <div class="main">
   <div class="container">
     <div class="linkProductSection mt-5">
       <div class="link_product" *ngIf="linkProduct">
         <h4>How to link a product?</h4>
         <h3>Link a product</h3>
         <!-- <p class="py-4">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been t he industry’s standard dummy text ever since the 1500s, </p> -->
         <div class="publish-link mt-3">
           <div class="d-flex mb-md-3">
             <div class="input">
             <app-loader *ngIf="loaderProduct"></app-loader>

               <form [formGroup]="productUrl">
                 <p class="pb-md-1">Enter product Link</p>
                 <input class="form-control" formControlName="AffiliateLink" placeholder="Paste the product link">
                 <div *ngIf="f.AffiliateLink.errors?.required && submitttedUrl" class="invalid-feedback m-t d-block"> Destinations url is required </div>
                 <div *ngIf="linkMypreview !=  null" class="invalid-feedback m-t d-block"> {{linkMypreview}}</div>
               </form>
               <h6 class="mt-1">Where is the product can be purchased by others in the community</h6>
             </div>
             <div class="publish-btn">
               <button class="ml-3 pointer" [class.continuePreview]="linkMypreview !=  null" (click)="publishLink()">Publish Link</button>
               <a class="ml-3 pointer linkPrview-a" *ngIf="linkMypreview !=  null" (click)="Continue()">Continue</a>
             </div>
           </div>
         </div>
       </div>
       <div class="add-product-details" *ngIf="addProduct">
         <div class="d-flex justify-content-between">
           <div class="step">
             <div class=''>
               <div class="bullet" [class.completed]="this.linkProduct == true"></div>
               <div class="stepper-class">
                 <p>Add Product Link</p>
               </div>
             </div>
           </div>
           <div class="step">
             <div class=''>
               <div class="bullet" [class.productLinks]="this.addProduct == true"></div>
               <div class="stepper-class">
                 <p>Add details</p>
               </div>
             </div>
           </div>
           <div class="step">
             <div class=''>
               <div class="bullet" [class.publishedCompletd]="this.addProduct == true"></div>
             </div>
             <div class="stepper-class ">
               <p>Publish link</p>
             </div>
           </div>
         </div>
         <form [formGroup]="productDetails">
           <div class="row">
             <div class="col-md-4">
               <div class="card">
                 <div class="card-body">
                    <!-- <img *ngIf="productImageUrl == null" src="../../assets/images/img-bg.svg" class="img-bg"> -->
                <img *ngIf="productImageUrl != null" [src]="productImageUrl" width=100 height=100 alt="" class="img-upload-bg">
                <!-- <img *ngIf="producturl == null" src="../../assets/images/img-bg.svg" class="img-bg"> -->
                <img *ngIf="url != null" [src]="url" width=100 height=100 alt="" class="img-upload-bg">
                   <p *ngIf="url == null">Add one or more images of the product</p>
                 </div>
               </div>
               <div *ngIf="c.productImage.errors?.required && submiitedDetails" class="invalid-feedback m-t d-block"> Product image is required </div>
               <div class="upload-img d-flex">
                 <input style="display: none" type="file"  accept="image/*" (change)="onFileSelectedEvent($event)" #fileInput>
                 <p>Upload product image</p>
                 <button class="plus-btn ml-2" (click)="fileInput.click()">+</button>
               </div>
              
             </div>
             <app-loader *ngIf="loader"></app-loader>
             <div class="col-md-8">
               <div class="addProcductDetails-input">
                 <div class="details-section">
                   <p>Enter the product title</p>
                   <div class="title d-flex">
                     <div>
                       <input type="text" formControlName="productTitle" placeholder="Enter the Title">
                       <div *ngIf="c.productTitle.errors?.required && submiitedDetails" class="invalid-feedback m-t d-block"> Product title is required </div>
                     </div>
                     <div>
                       <h6 class="ml-5">Add description ( optional)</h6>
                     </div>
                   </div>
                 </div>
                  
                 <div class="details-section d-flex mt-3">
                   <div class="title">
                     <p>Enter the Description</p>
                     <input type="text" formControlName="description" placeholder="Enter the description" class="brand-input">
                     <div *ngIf="c.description.errors?.required && submiitedDetails" class="invalid-feedback m-t d-block"> Description is required </div>
                   </div>
                   <h6 class="ml-5">How to link a product?</h6>
                 </div>
                 <div class="details-section mt-md-3">
                   <p class='mb-2'>Enter the channel name</p>
                   <div class="title">
                    
                     <mat-form-field class="width" floatLabel="never">
    <input
      matInput
       placeholder="Select channel name"
      [matAutocomplete]="auto"
      [formControl]="countryCtrl"
    />
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option
      
        *ngFor="let country of filteredCountry | async"
        [value]="country.name"
      >
        <span>{{ country.name }}</span>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
                     
                     <div *ngIf="channelNameAvailable" class="invalid-feedback m-t d-block">Channel name is required </div>
              ->
                   </div>
                 </div>
                 <div class="details-section p-select mt-3 d-flex">
                
                   <div class="catergories-add-section mt-md-2">
                     <p>Category Name</p>
                     <mat-form-field appearance="fill">
                       <mat-select formControlName="categoryId" multiple placeholder="Select Category" disableOptionCentering panelClass="eligablePanelClass" (selectionChange)="categoryEvent($event)" #closeattendance>
                         <mat-option class='services-view' *ngFor="let list of categoryList" [value]="list._id">{{list.name}}</mat-option>
                         <div class="apply-btn-option pt-4 pb-3"></div>
                       </mat-select>
                     </mat-form-field>
                     <div *ngIf="c.categoryId.errors?.required && submiitedDetails" class="invalid-feedback m-t d-block"> Please select the category </div>
                   </div>
                
                 </div>
                 <div class="details-section mt-md-3">
                   <div class="title">
                     <p>product Type</p>
                     <select formControlName="productType" class="form-control">
                       <!-- <option value="" selected disabled hidden>Select Produc type</option> -->
                       <option [selected]="list == 'my deals'" *ngFor="let list of productType" [value]="list">{{list | titlecase}}</option>
                     </select>
                     <div *ngIf="c.productType.errors?.required && submiitedDetails" class="invalid-feedback m-t d-block"> Please select the Product type </div>
                   </div>
                 </div>
  
                 <div class="details-section mt-md-3">
                   <div class="d-flex">
                     <p class="mr-md-3">Discount code available?</p>
                     <mat-radio-group aria-label="Select an option" class="mt-1" [selectedIndex]="1">
                       <mat-radio-button [checked]="true" value="0" (change)="changeRoute($event)">
                         <span>No</span>
                       </mat-radio-button>
                       <mat-radio-button class="ml-md-5" value="1" (change)="changeRoute($event)">
                         <span>Yes</span>
                       </mat-radio-button>
                     </mat-radio-group>
                   </div>
                   <div class="title d-flex" *ngIf="showDiscount">
                     <input type="text" formControlName="couponCode" placeholder="Enter Discount code or offer code">
                   </div>
                 </div>
                 <div class="checked mt-3">
                   <p>
                     <mat-checkbox [checked]="true" class="disable_ripple">Also post this on Pinterest and make it available</mat-checkbox>
                   </p>
                   <p>
                     <mat-checkbox [checked]="true" class="disable_ripple">I agree to the terms of service and privacy policy of Summarizly AI</mat-checkbox>
                   </p>
                 </div>
                 <div *ngIf="productError != null" class="invalid-feedback m-t d-block"> {{productError}}</div>
                 <div class="publishLinkbtn">
                   <button class="mt-3 mb-3 pointer" (click)="addProductDetails()">Publish Product link</button>
                 </div>
               </div>
             </div>
           </div>
         </form>
       </div>
       <div class="login-success-user-section" *ngIf="loginUSerSuccess">
         <div class="row">
           <div class="col-md-6 border-p">
             <div class="login-user-img mt-5">
               <img src="../../assets/images/check.png" class="success-img">
               <div class="login-details-msg d-flex justify-content-center mt-md-4">
                 <h5>Product link published successfully</h5>
                 <P class=" ml-2 pointer">
                   <a routerLink="/p/{{this.title.split(' ')?.join('-')}}/id-{{this.id}}">View</a>
                 </P>
               </div>
               <h4 class="mt-md-4">Share this Summary with your network and help others discover more summaries</h4>
               <div class="shareItBtnSection d-flex justify-content-center mb-3">
                 <button type="button" class="link pointer">{{this.categoryLink | slice:0:50}}</button>
                 <button type="button" class="pointer" (click)="share()">Share it</button>
               </div>

               <div class="shorlink">
                <h4>Need a short link ? here it is</h4>
              <div class="need-a-short-link d-flex ">

               <h2><a href="{{shortUrl}}" target="_blank" class="mr-md-5">{{shortUrl}}</a></h2>
                  <button class="copy-btn mt-1 pointer" (click)="copyLink($event.target,'copied')" cdkCopyToClipboard="{{shortUrl}}">Copy</button>
               </div>
               </div>
              
               <div class="publish-product mt-md-5 mb-md-5">
                 <h6>Want to publish more?</h6>
                 <div class="d-flex mt-4">
                   <div class="publish-collection pointer">
                    <a (click)="publishCollectionLogin()">
                     <div class="d-flex">
                       <img src="../../assets/images/youtube-img.svg">
                       <p class="mt-2">Publish collection</p>
                     </div>
                     </a>
                   </div>
                   <div class="publish-collection ml-md-4 pointer">
                    <a (click)="publishProdcutLogin()">
                     <div class="d-flex">
                       <img src="../../assets/images/p.svg">
                       <p class="mt-2">Publish product link</p>
                     </div>
                     </a>
                   </div>
                 </div>
               </div>
             </div>
           </div>
           <div class="col-md-6">
             <div class="loginDetails">
               <img src="../../assets/images/arr-2.png" class="arrow">
               <div class="more-community justify-content-center">
                 <div class="pin-it mb-2 mt-md-2">
                   <button type="button" class="pointer">
                     <a data-pin-do="buttonPin" data-pin-count="beside" data-pin-custom="true" data-pin-save="true" data-pin-description="ProductDescription" target="_blank" data-pin-shape="round" href="https://www.pinterest.com/pin/create/button/?url={{categoryLink}}/&media={{productImage}}" data-pin-custom="true">
                       <img src="../../../../assets/images/pinterest (2).png" class="pointer pin-img" alt="pinterest">
                       <span class="save ml-2">Pin it</span>
                     </a>
                   </button>
                 </div>
                 <div>
                   <h5 class="mt-md-5 ml-md-3">Cross publish this collection to your Pinterest Community and drive more engagement and traffic</h5>
                 </div>
               </div>
             </div>
           </div>
         </div>
         <div class="login-user-img ">
          
         </div>
       </div>
  
     </div>
   </div>
 </div>
 
 <app-footer></app-footer>