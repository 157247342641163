import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { CollectionService } from 'src/app/_services/collection.service';
import Swal from 'sweetalert2';
import { SuccessdialoghomeComponent } from '../successdialoghome/successdialoghome.component';

@Component({
  selector: 'app-report-dialog',
  templateUrl: './report-dialog.component.html',
  styleUrls: ['./report-dialog.component.css'],
})
export class ReportDialogComponent implements OnInit {
  report: any[] = [
    {
      name: 'Summary seems wrong',
      paragraph:
        'The contents of the summary does not seems like accurate about the topic',
      tag: 'Inaccurate',
    },
    {
      name: 'Wrong translation',
      paragraph: 'The contents of the summary seems like pooorly translated',
      tag: 'Translation error',
    },
    {
      name: 'Low quality',
      paragraph: 'The contents of the summary seems like very low quality',
      tag: 'Low quality',
    },
    {
      name: 'Offensive',
      paragraph: 'The contents of the summary seems like offensive',
      tag: 'Offensive',
    },
    {
      name: 'Spam',
      paragraph: 'The contents of the summary seems like a spam content',
      tag: 'Spam',
    },
  ];
  collectionsid: any;
  constructor(
    private collectionService: CollectionService,
    private dilaog: MatDialog,
    private dialogRef: MatDialogRef<ReportDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    console.log(this.data.title);
    this.collectionsid = this.data.title;
  }
  close() {
    this.dilaog.closeAll();
  }
  reportBtn(e: any) {
    console.log(e.value);

    let obj = {
      report_type: e.value,
    };
    this.collectionService
      .reportSummary(this.collectionsid, obj)
      .subscribe((result) => {
        this.dialogRef.close();
        this.dilaog.open(SuccessdialoghomeComponent, {
          data: {
            topic: false,
            publisher: false,
            bookmark: false,
            report: true,
            disableClose: true,
          },
        });
        console.log(result);
      });
  }
}
