<!-- [ngClass]="message === 'true'?'active-sidebar':'close-sidebar'" -->
<div class="sidemenu-wrap">
  <span class="d-xl-none close-sidebar"><i class="fa fa-times"></i></span>
  <div class="sidebar-logo" *ngFor="let ChannelName of ChannelName">
     <img *ngIf="ChannelName == ' '" src="../../../assets/images/user-1.png" width="72px">
     <img *ngIf="ChannelName != ''" [src]="ChannelName.profile" width="72px">
     <h4>Publisher Profile</h4>
     <h3 (click)="routetoBlog()">{{ChannelName.name}}</h3>
  </div>

  <div class="menu-wrap">
    <ul>
        <ng-container >
        <li>
        <a (click)="route('Publisher/dashboard')" [class.active-route]="routeNavigate === 'Publisher/dashboard'"
     [class.inactive-route]="routeNavigate !== 'Publisher/dashboard'">
          <img class="images" src="../../../assets/images/menu.png" alt="" />
         Dashboard
        </a>
      
      </li>
        <li>
        <a (click)="route('Publisher/content')" [class.active-route]="routeNavigate === 'Publisher/content'"
     [class.inactive-route]="routeNavigate !== 'Publisher/content'">
          <img class="images" src="../../../assets/images/report.png" alt="" />
          
          Content
        </a>
      
      </li>
        <li>
          <a (click)="route('Publisher/Blogs')" [class.active-route]="routeNavigate === 'Publisher/Blogs'"
     [class.inactive-route]="routeNavigate !== 'Publisher/Blogs'">
          <img class="images" src="../../../assets/images/group.png" alt="" />
          
         Summaries
        </a>
      
      </li>
        <li>
        <a (click)="route('Publisher/analytics')" [class.active-route]="routeNavigate === 'Publisher/analytics'"
     [class.inactive-route]="routeNavigate !== 'Publisher/analytics'">
          <img class="images" src="../../../assets/images/content.png" alt="" />
          
          Analytics
        </a>
      
      </li>
        <li>
        <a  (click)="route('Publisher/subscribers')" [class.active-route]="routeNavigate === 'Publisher/subscribers'"
     [class.inactive-route]="routeNavigate !== 'Publisher/subscribers'" >
          <img class="images" src="../../../assets/images/content.png" alt="" />
          
          Subscribers
        </a>
      
      </li>
        <li>
        <a  (click)="route('Publisher/comments')" [class.active-route]="routeNavigate === 'Publisher/comments'"
     [class.inactive-route]="routeNavigate !== 'Publisher/comments'"  >
          <img class="images" src="../../../assets/images/categories.png" alt="" />
          
         Comments
        </a>
      
      </li>
        <li>
        <a   >
          <img class="images" src="../../../assets/images/green-energy.png" alt="" />
          
          Earnings
        </a>
      
      </li>
        <li>
        <a   >
          <img class="images" src="../../../assets/images/refferals.jpg" alt="" />
          
          Settings
        </a>
      
      </li>
        <li>
        <a >
          <img class="images" src="../../../assets/images/support.png" alt="" />
          
          Help
        </a>
      
      </li>
      </ng-container>
      
    </ul>
    <!-- <p>HELP</p>
    <ul>
      <ng-container *ngFor="let help of sidemenuHelpItems; let i = index">
 <li *ngIf="help.isPermit ">
        <a routerLink="{{ help.link }}" routerLinkActive="active-page">
          <img src="{{ help.icon }}" alt="" />
          {{ help.title }}
        </a>
      </li>
      </ng-container>
     
    </ul> -->
  </div>
 
</div>
