<div class="container">
   <div class="close_img cursor" (click)="close()">
              <img src="../../../assets/images/close-m.svg">
            </div>

            <div class="topic-content_dialog" *ngIf="create">
                <img src="../../assets/images/check.svg" class="success-img">
                <P>Topic {{title}} Create  successfully</P>


                <a [href]="route" (click)="catergoryNavigate()" target="_blank">{{Url}}/topic/{{title}}</a>
                <div class="d-flex buttons-succes-content mt-md-5 justify-content-between">
                    <button (click)="navigate('feed')"   class="cursor feed">Go to feed</button>
                    <button (click)="navigate('Publish')" class="cursor publish">Publish summary</button>
                </div>
            </div>
            <div class="topic-content_dialog" *ngIf="update">
                <img src="../../assets/images/check.svg" class="success-img">
                <P>Topic Update  successfully</P>


                <a [href]="route" (click)="catergoryNavigate()" target="_blank">{{Url}}/topic/{{title}}</a>
                <div class="d-flex buttons-succes-content mt-md-5 justify-content-between">
                    <button (click)="navigate('feed')"   class="cursor feed">Go to feed</button>
                    <button (click)="navigate('Publish')" class="cursor publish">Publish summary</button>
                </div>
            </div>
</div>