import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CollectionService } from 'src/app/_services/collection.service';

@Component({
  selector: 'app-add-topics-dialog',
  templateUrl: './add-topics-dialog.component.html',
  styleUrls: ['./add-topics-dialog.component.css'],
})
export class AddTopicsDialogComponent implements OnInit {
  catergoriesFormGroup: FormGroup;
  catergoryList: any = [];
  catergory: string;
  submiited: boolean = false;
  succeed: boolean = false;
  subCatergory: any = [];
  error: any;
  selectedFile: File;
  selectedFileEvent: File;
  fileNameEvent: any;

  url: any;
  subCatergoryList: string;
  constructor(
    private collectionServices: CollectionService,
    private fb: FormBuilder,
    private dialog: MatDialog
  ) {
    this.catergoriesFormGroup = this.fb.group({
      categoryName: ['', [Validators.required]],
      categoryImage: ['', [Validators.required]],
      description: [''],
      title: [''],
      isAdded: [''],
      subCategories: [''],
    });
  }
  ngOnInit(): void {}
  get c(): { [key: string]: AbstractControl } {
    return this.catergoriesFormGroup.controls;
  }
  onFileSelected(event: any): void {
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);

    reader.onload = (_event) => {
      this.url = reader.result as string;

      this.selectedFile = <File>event.target.files[0];
    };
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.catergoriesFormGroup.controls['categoryImage'].setValue(file);
    }
  }
  subCategory(e: any) {
    this.subCatergoryList = e.target.value;
    // console.log(e.target.value);

    if (
      this.subCatergoryList != '' ||
      this.subCatergoryList != null ||
      this.subCatergoryList != undefined
    ) {
      this.subCatergory =
        this.catergoriesFormGroup.value.subCategories.split(',');
      console.log(this.subCatergory);
    } else {
      this.subCatergory = '';
      console.log(JSON.stringify(this.subCatergory));
    }
  }
  close() {
    this.dialog.closeAll();
  }
  submit() {
    console.log(this.catergoriesFormGroup.value);

    if (this.catergoriesFormGroup.invalid) {
      this.submiited = true;
    } else {
      const formData = new FormData();
      formData.append(
        'topicImage',
        this.catergoriesFormGroup.controls['categoryImage'].value
      );
      formData.append(
        'topicName',
        this.catergoriesFormGroup.value.categoryName
      );
      formData.append('isAdded', 'true');
      formData.append('subTopics', JSON.stringify(this.subCatergory));
      this.collectionServices.addCategories(formData).subscribe(
        (result) => {
          console.log(result);
          console.log('success');
          this.succeed = true;
          this.url = null;

          setTimeout(() => {
            this.succeed = false;
            this.collectionServices.triggerRefresh();
            this.dialog.closeAll();
          }, 3000);
          this.catergoriesFormGroup.reset();
          this.subCatergory = '';
        },
        (error) => {
          this.error = error.error.message;
          setTimeout(() => {
            this.error = '';
          }, 5000);
        }
      );
    }
  }
}
