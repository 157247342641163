<app-homenavbar *ngIf="sessionType == 'home'"></app-homenavbar>
<app-usernavbar *ngIf="sessionType == 'user'"></app-usernavbar>
<app-navbar *ngIf="sessionType == 'powerUser'"></app-navbar>
<app-creatornavbar *ngIf="sessionType == 'publisher'"></app-creatornavbar>
     <div class="container">
        <div class="main">
            <div class="policy mt-md-5">
<a href="https://www.iubenda.com/privacy-policy/80192873" class="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe iub-body-embed" title="Privacy Policy">Privacy Policy</a>
            </div>
        </div>


     
     </div>  
    
 <app-footer ></app-footer>
   