import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as AOS from 'aos';
import { AnimationOptions } from 'ngx-lottie';
import { CollectionService } from '../_services/collection.service';
@Component({
  selector: 'app-landingpage',
  templateUrl: './landingpage.component.html',
  styleUrls: ['./landingpage.component.css'],
})
export class LandingpageComponent implements OnInit {
  videosArray: any = [
    {
      count: '1',
      title: 'Paste the video link',
    },
    {
      count: '2',
      title: 'Get summary in any language',
    },
    {
      count: '3',
      title: 'Read, refer and remember',
    },
    {
      count: '4',
      title:
        'Publish summary help others discover the knowledge and information in the video',
    },
  ];
  cards: any = [
    {
      category: 'beauty tips and tricks',
      img: '../../../assets/images/skin-care.png',
    },
    {
      category: 'fashion tips and tricks',
      img: '../../../assets/images/woman.png',
    },
    {
      category: 'Business skills',
      img: '../../../assets/images/briefcase.png',
    },
    {
      category: 'Finance lessons',
      img: '../../../assets/images/budget.png',
    },
    {
      category: 'Science lessons ',
      img: '../../../assets/images/science (1).png',
    },
    {
      category: ' Technology skills',
      img: '../../../assets/images/data-management.png',
    },
    {
      category: 'SEO tricks',
      img: '../../../assets/images/seo.png',
    },
    {
      category: '100+ products',
      img: '../../../assets/images/list.png',
    },
  ];
  token1: any;
  sessionType: any;
  scrollPosition: number;
  constructor(
    private route: Router,
    private collectionService: CollectionService,
    private activatedRoute: ActivatedRoute,
    private elementRef: ElementRef
  ) {}
  slideConfig = {
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 2,
    centerMode: true,
    autoplay: true,
    autoplaySpeed: 2000,
    prevArrow:
      "<img class='a-left control-c prev slick-prev' src='../../assets/images/l-11.png'>",
    nextArrow:
      "<img class='a-right control-c next slick-next' src='../../assets/images/l-22.png'>",
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: true,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: true,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 1,
        },
      },
    ],
  };
  optionsW: AnimationOptions = {
    path: '../../assets/images/global.json',
  };
  slickInit(e: any) {
    console.log('slick initialized');
  }

  ngOnInit(): void {
    AOS.init();
    if (this.collectionService.getToken() == '') {
      console.log('not-login');
      this.sessionType = 'home';
    }
    this.token1 = this.activatedRoute.snapshot.queryParams.token;
    if (this.token1) {
      localStorage.setItem('token', this.token1);
      this.collectionService.loginUserDetails().subscribe((result) => {
        this.sessionType = result.data.userType;
      });
    }
    if (this.collectionService.getToken() != '') {
      console.log('not-login');
      this.collectionService.loginUserDetails().subscribe((result) => {
        this.sessionType = result.data.userType;
      });
    }
  }
  feedRoute() {
    this.route.navigate(['/feed']);
  }

  imageUrls: string[] = [
    'https://images.hdqwalls.com/download/mountains-sky-reflection-lake-y5-1920x1080.jpg',
    'https://images.hdqwalls.com/download/neowise-seen-from-mt-shasta-4k-t6-1920x1080.jpg',
    'https://images.hdqwalls.com/download/snow-landscape-mountains-trees-forest-5k-4u-1920x1080.jpg',
    // Add more URLs here
  ];
}
