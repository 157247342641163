import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'category',
})
export class CategoryPipe implements PipeTransform {
  transform(items: any[], filter: Record<string, any>): any {
    if (!items || !filter) {
      return items;
    }

    const key = Object.keys(filter)[0];
    const value = filter[key];

    // console.log(key, value);
    const filteredItems = items.filter((e) => e[key].indexOf(value) !== -1);
    // console.log(filteredItems);
    return filteredItems.slice(0, 1);
  }
}
