import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-sidenavbar',
  templateUrl: './sidenavbar.component.html',
  styleUrls: ['./sidenavbar.component.css'],
})
export class SidenavbarComponent implements OnInit {
  sidemenuMainItems = [
    {
      icon: '../.',
      title: 'Dashboard',
      link: '/dashboard',
      active: false,
    },
    {
      icon: '',
      title: 'Summaries',
      link: '/summaries',
      active: false,
    },
    {
      icon: '../../../../assets/images/group.png',
      title: 'Users',
      link: '/users',
      active: false,
    },
    {
      icon: '../../assets/images/panel_images/tv-program.svg',
      title: 'Creators',
      link: '/Tv-programs',
      active: false,
    },

    {
      icon: '../../assets/images/panel_images/prayer.svg',
      title: 'topics',
      active: false,
    },
    {
      icon: '../../assets/images/panel_images/donate.svg',
      title: 'power users',
      link: '/powerUser',
      active: false,
    },

    {
      icon: '../../assets/images/panel_images/cms.svg',
      title: 'Referrals',

      active: false,
    },

    {
      icon: '../../assets/images/panel_images/sf.svg',
      title: 'Customer Support',

      active: false,
    },
  ];

  storelogo = '../../assets/images/panel_images/sidenav-img.svg';
  activeRoute: string;
  activeRouteColor: string;
  activeRouteColor1: string;
  activeRouteColor2: string;
  routeParam: any;
  routeNavigate: any;

  // Define a mapping of routes to colors
  // routeColors: any = {
  //   'loopkup-admin/dashboard': ['#0a090a', '#000000', '#000000'],
  //   'loopkup-admin/summaries': ['#000000', '#0a090a', '#000000'],
  //   'loopkup-admin/users': ['#000000', '#000000', '#0a090a'],
  //   'loopkup-admin/publishers': ['#000000', '#000000', '#0a090a'],
  // };
  constructor(private router: Router, private activateRoute: ActivatedRoute) {}

  ngOnInit() {
    this.routeParam = this.router.url.split('/');
    this.routeNavigate = `${this.routeParam[1]}/${this.routeParam[2]}`;
  }

  route(route: any) {
    this.router.navigate([`${route}`]);
    this.routeNavigate = route;
  }
}
