<div class="">
  <div class="unfollow-section">
    <div *ngIf="topic">
      <div class="d-flex">
        <p>You won't see summaries from {{ title }} in your feed</p>
        <button class="cursor" (click)="followBtn()">Undo</button>
        <a (click)="hide()">Hide</a>
      </div>
    </div>
  </div>

  <div class="unfollow-section" *ngIf="this.publisher === true">
    <div class="d-flex">
      <p>You will see less summaries like this in your feed</p>
      <button class="cursor" (click)="followBtnPublisher()">Undo</button>
      <a (click)="hide()">Hide</a>
    </div>
  </div>

  <div class="unfollow-section" *ngIf="this.bookmark === true">
    <div class="d-flex">
      <p>Added to your bookmarks</p>
      <a (click)="bookmarkPublishers()">View Bookmarks</a>
      <a (click)="hide()">Hide</a>
    </div>
  </div>
  <div class="unfollow-section" *ngIf="this.link === true">
    <div class="d-flex">
      <p>Link copied succesfully</p>
      <a (click)="hide()">Hide</a>
    </div>
  </div> 
  <div class="unfollow-section" *ngIf="this.dwnvote === true">
    <div class="d-flex">
      <p>Summary downvote successfully</p>
      <a (click)="hide()">Hide</a>
    </div>
  </div>
  <div class="unfollow-section" *ngIf="this.report === true">
    <div class="d-flex">
      <p>
        Thank you for reporting.We will look into this and we improve the
        summary
      </p>
      <a (click)="hide()">Hide</a>
    </div>
  </div>
</div>
