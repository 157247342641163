

<app-homenavbar *ngIf="sessionType == 'home'"></app-homenavbar>
<app-usernavbar *ngIf="sessionType == 'user'"></app-usernavbar>
<app-navbar *ngIf="sessionType == 'powerUser'"></app-navbar>
<app-creatornavbar *ngIf="sessionType == 'publisher'"></app-creatornavbar>

     <div class="container">


        <div class="main">
            <div class="policy mt-md-5">
<a href="https://www.iubenda.com/privacy-policy/80192873/cookie-policy" class="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe iub-body-embed" title="Cookie Policy">Cookie Policy</a>
            </div>
        </div>


     
     </div>  
 <app-footer ></app-footer>