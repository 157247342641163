  <app-homenavbar *ngIf="sessionType == 'home'"></app-homenavbar>
<app-usernavbar *ngIf="sessionType == 'user'"></app-usernavbar>
<app-navbar *ngIf="sessionType == 'powerUser'"></app-navbar>
<app-creatornavbar *ngIf="sessionType == 'publisher'"></app-creatornavbar>
   <div class="main">
    <div class="container">
         <div class="collectionall-header  mt-md-5 ">
           <!-- <h5>My Products</h5> -->
           <h4>{{title}}</h4>
           <p class="mt-2 mb-md-2">by <a routerLink="/{{channelName?.split(' ')?.join('')}}">{{channelName}}</a></p>
          
        </div>
  

    <div class="bulk-products mb-5">
        <div class="row">
            <div class="col-md-4" *ngFor="let cardDetails of collectionCreatedByCreator">
               <a href="/p/{{cardDetails.productTitle.split(' ')?.join('-')}}/id-{{cardDetails._id}}">
              <div class="card mb-3">
                 <div class="card-body">
                     <div>
                      <img *ngIf="cardDetails?.image == null && cardDetails?.productImage == ''" (click)="productDetails(cardDetails.productTitle,cardDetails._id)" src="../../../assets/images/n-image.png" class="code-1-img image">
                    <img *ngIf="cardDetails?.image != null " (click)="productDetails(cardDetails.productTitle,cardDetails._id)" src="{{cardDetails?.image}}" class="code-1-img image">
                    <img *ngIf="cardDetails?.productImage != ''" (click)="productDetails(cardDetails.productTitle,cardDetails._id)" src="{{cardDetails?.productImage[0]?.url}}" class="code-1-img">
                     </div>

                     <div class="view-now">
                         <button type="button" (click)="productDetails(cardDetails.productTitle,cardDetails._id)" class="pointer">View Now</button>
                     </div>
                 </div>
              </div>
              </a>
            </div>
        </div>
    </div>
  
  
  </div>
   </div>
   
 <app-footer></app-footer>