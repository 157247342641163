<app-creatornavbar ></app-creatornavbar>




<div class="container">

    <div class="publisher_video_section mt-md-5">
        <div class="sync_video_section">
            <div class="sync_card card">
                <div class="card-body">
                    <div class="sync_youtube">
                        <h5>Sync your channel</h5>
                        <div class="d-flex">
                             <div class="sync_heading">
                                 <h6>When you sync all your show up here. With one click you can turn your videos into summaries</h6>
                             </div>
                             <div class="sync_video_button">
                                  <button class="cursor" (click)="syncNowButton()" *ngIf="syncNow">Sync Now <img src="../../../assets/images/yt.png" class="ml-2" alt="yotube-icon"></button>
                                  <button class="cursor" *ngIf="ManageButton">Manage</button>

                             </div>
                              
                        </div>
                    </div>
                </div>
            </div>
<app-loader *ngIf="showLoader"></app-loader>

              <div class="videos_sync_section mt-md-5 pt-5" *ngIf="showVideos">
                 <div class="d-flex" *ngFor="let details of thumbnailData"> 
                    <div class="video_thumbnail">
                        <img src="{{details.img}}">
                    </div>
                    <div class="video_details ml-md-4">
                         <h6>{{details.title}}</h6>
                          <p>{{details.desc}}</p>
                          <a>www.youtube.com/v-afdfdfdfs</a>
                    </div>
                    <div class="video_publish_ablog mt-md-4">
                        <button class="cursor">Publish a blog</button>
                    </div>
                 </div>
              </div>
        </div>
    </div>
</div>
