import { Component, Inject, OnInit } from '@angular/core';

import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SignupComponent } from '../login-page/signup/signup.component';
import { LoginComponent } from '../login-page/login/login.component';
import { NoAuthSignupComponent } from '../no-auth-signup/no-auth-signup.component';
@Component({
  selector: 'app-no-auth-signup-follow',
  templateUrl: './no-auth-signup-follow.component.html',
  styleUrls: ['./no-auth-signup-follow.component.css'],
})
export class NoAuthSignupFollowComponent implements OnInit {
  collectionImage: any;
  link: any;
  url: string;

  constructor(
    private router: Router,
    private dilaog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<NoAuthSignupComponent>
  ) {}

  ngOnInit(): void {
    this.collectionImage = this.data?.image;
    this.link = this.data.ShareLink;
    localStorage.setItem('shareLink', this.link);
    console.log(this.collectionImage);
    console.log(this.link);
    this.url = `${environment.USER}user/google?url=${this.link}`;
    console.log('redirection url', this.url);
  }

  signup() {
    // this.router.navigate(['user/Signup']);
    this.dilaog.open(SignupComponent, {
      data: {
        disableClose: true,
      },
    });
    // this.dilaog.closeAll();
  }
  close() {
    this.dilaog.closeAll();
    localStorage.removeItem('shareLink');
  }
  login() {
    this.dialogRef.close();

    this.dilaog.open(LoginComponent, {
      disableClose: true,
      data: {},
    });
  }
}
