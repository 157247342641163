import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CollectionService } from 'src/app/_services/collection.service';
import { LoginService } from 'src/app/_services/login.service';
import { environment } from 'src/environments/environment';
import { PublisherSignupModalComponent } from '../creator-signup/publisher-signup-modal/publisher-signup-modal.component';
var emailPhonePattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$';

@Component({
  selector: 'app-creator-login',
  templateUrl: './creator-login.component.html',
  styleUrls: ['./creator-login.component.css'],
})
export class CreatorLoginComponent implements OnInit {
  loginPage: boolean = true;
  resetPassword: boolean = false;
  submitted: boolean = false;
  confirmPassword: boolean = false;
  passwordChangedSuccesfully: boolean = false;
  submittedPassword: boolean = false;
  loginFormGroup: FormGroup;
  forGotPasswordForm: FormGroup;
  errorMessage: any;

  loginErrorMessage: any;
  userType: any;
  userName: string;
  url: any = `${environment.COLLECTION_URL}publisher/google`;
  loader: boolean;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private loginService: LoginService,
    private collectionsservice: CollectionService
  ) {
    this.loginFormGroup = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });
    this.forGotPasswordForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  ngOnInit(): void {
    if (localStorage.getItem('token') != null) {
      this.router.navigate(['/feed']);
    }
  }
  login() {
    if (this.loginFormGroup.invalid) {
      this.submitted = true;
    } else {
      this.loader = true;
      this.submitted = false;
      let obj = {
        email: this.loginFormGroup.value.email,
        password: this.loginFormGroup.value.password,
      };

      this.loginService.creatorLogin(obj).subscribe(
        (res) => {
          localStorage.setItem('token', res.token);
          this.loader = false;

          // this.collectionsservice.powerUserToken = res.token;
          // debugger;

          // var username = 'chandra';
          this.collectionsservice.loginUserDetails().subscribe((result) => {
            // this.userName = result.data.name;
            this.userType = result.data.userType;
            // localStorage.setItem('userName', this.userName);
            localStorage.setItem('userType', this.userType);
            this.router.navigate(['/Publisher/Home']);

            // if (result.onboarding == false) {
            //   this.router.navigate(['/publisher/onboaring']);
            // } else {
            //   this.router.navigate(['/publisher/myVideos']);
            // }
          });
        },
        (err) => {
          console.log(err.error.message);

          this.loginErrorMessage = err.error.message;
          setTimeout(() => {
            this.loginErrorMessage = '';
          }, 3000);
        }
      );

      console.log(this.loginFormGroup.value);
    }
  }

  get f(): { [key: string]: AbstractControl } {
    return this.loginFormGroup.controls;
  }
  get r(): { [key: string]: AbstractControl } {
    return this.forGotPasswordForm.controls;
  }
  signUp() {
    this.router.navigate(['publisher_signup']);
  }
  home() {
    this.router.navigate(['/feed']);
  }
  forGotPassword() {
    this.resetPassword = true;
    this.loginPage = false;
  }
  resetPasswordBtn() {
    if (this.forGotPasswordForm.invalid) {
      this.submittedPassword = true;
    } else {
      this.submittedPassword = false;
      this.submitted = false;
      let obj = {
        email: this.forGotPasswordForm.value.email,
      };
      this.loginService.creatorForgotPassword(obj).subscribe(
        (res) => {
          this.passwordChangedSuccesfully = true;
          this.resetPassword = false;
        },
        (error) => {
          console.error(error.error);
          this.errorMessage = error.error.message;
          setTimeout(() => {
            this.errorMessage = '';
          }, 3000);
        }
      );
    }
  }
  confirmPasswordBtn() {
    this.passwordChangedSuccesfully = true;
    this.confirmPassword = false;
  }
  loginNow() {
    this.loginPage = true;
    this.passwordChangedSuccesfully = false;
  }

  howItworks() {
    this.dialog.open(PublisherSignupModalComponent, {
      disableClose: true,
      data: {},
    });
  }
}
