
<div class="main">
<app-navbar *ngIf="userType == 'powerUser'"></app-navbar>
<app-creatornavbar *ngIf="userType == 'publisher'"></app-creatornavbar>
  
    <div class="container mt-md-4">
        <div class="collectionall-header ml-md-3">
           <h5>My Summaries</h5>
        </div>
        <div class="collectionall-header mt-md-5 text-center" *ngIf="collectionList == ''">
           <h5 class="text-center">No Blogs</h5>
        </div>
        <!-- <div class="row">
 <div class="col-md-4" *ngFor="let list of collectionList;let i = index">
    <a href="c/{{list.summaryName.split(' ').join('-')}}/id-{{list._id}}">

   
                <div class="collection-prodcuts pb-md-4 ml-md-3 pointer">
                    <img *ngIf="list.thumbNail != '' && list.customThumbNail == null" src="{{list.thumbNail}}" (click)="collection(list.summaryName,list._id)" class="c-img-1" loading="lazy" alt={{list.summaryName}}>
                  <img *ngIf="list.customThumbNail != '' && list.thumbNail == null" src="{{list.customThumbNail}}"  (click)="collection(list.summaryName,list._id)" class="c-img-1" loading="lazy" alt="customThumbnNail">
                     
                     <P class="pt-md-2" (click)="collection(list.summaryName,list._id)">{{list.summaryName}}</P>
                     <a class="mt-md-3" [href]="list.publisherName" target="_blank">{{list.publisherName}}</a>
                </div>

                
                 </a>
                 <div class="google-Translate d-flex ml-md-2">
                    <div class="">
              <label for="languageSelect">Select Language:</label>
              <form [formGroup]="translate">

             
               <select class="form-control" formControlName="googleTransalate" id="languageSelect "   #languageSelect
                (change)="selecteLanguage(languageSelect.value,$event,i)">
               <option value="" disabled selected>Select</option>
               <option  *ngFor="let lang of Language" [value]="lang.value">{{ lang.name}}</option>
                </select>
                 <div *ngIf="c.googleTransalate.errors?.required && list.showErrorMesseage" class="invalid-feedback m-t d-block"> Please select the language </div>
                 </form>
                    </div>
                    <app-loader *ngIf="loader && list.showloader"> </app-loader>
                     <div class="translate-btn ml-md-2">
                         <button class="btn btn-dark" (click)="googleTranslateService(list._id,list)">Translate</button>
                     </div>


                    
                  
                </div>
                   <a  *ngIf="list.showDropDown" class="mt-md-3" href="c/{{list.summaryName?.replace('/?','').split(' ')?.join('-')}}/id-{{_id}}"> <p class="check_summary">Click here to view the  summary in {{this.LanguageName}}</p></a>
             </div>
        </div> -->
          

        <div class="fliter_summaries">
           <div class="d-flex justify-content-between">
               <p class="summary">Fliter summaries</p>
               <div class="selct_by_option">
                   <p>by topic</p>
                    <select class="form-control" [(ngModel)]="selectedOption" (change)="topic($event)"  id="languageSelect ">
               <option value="" disabled selected>Select</option>
               <option  *ngFor="let topic of catergoryList" [value]="topic.name">{{ topic.name}}</option>
                </select>
               </div>
               <div class="selct_by_option">
                   <p>by language</p>
                    <select class="form-control" [(ngModel)]="selectedOption1" (change)="lanugage($event)"   id="languageSelect ">
               <option value="" disabled selected>Select</option>
               <option  *ngFor="let lang of Language" [value]="lang.name">{{ lang.name}}</option>
                </select>
               </div>
              
               <div class="search_bar selct_by_option">
                   <p>by publisher</p>

        <input type="text"  (input)="search($event)" [(ngModel)]="selectedOption2"  class="form-control" placeholder="Enter publisher name">
    </div>
    <div class="selct_by_option">
                   <p>by popularity</p>
                    <select class="form-control" [(ngModel)]="popularitySelected" (change)="popularityBtn($event)"  id="languageSelect ">
               <option value="" disabled selected>Select</option>
               <option  *ngFor="let lang of popularity" [value]="lang">{{ lang}}</option>
                </select>
               </div>
    <div class="selct_by_option">
                   <img src="../../../assets/images/undo.png" (click)="reset()" id="undo">
               </div>
           </div>
             


         <div class="card-section_blogs">
             <div class="row">
                <div class="col-md-4" *ngFor="let list of collectionList;let i = index">
                      <div class="d-flex">
                  <a (click)="edit(list._id)">
                    <img class="edit mr-3" loading="lazy" src="../../../assets/images/edit.png">
                  </a>
                  <a (click)="delete(list._id)">
                    <img loading="lazy" class="edit" src="../../../assets/images/delete.png">
                  </a>
                </div>
                 
                   <div class="card mb-md-3">
                      <div class="card-body ">
                        <div class="collection-prodcuts">
 <img *ngIf="list.thumbNail != '' && list.customThumbNail == null" src="{{list.thumbNail}}" (click)="collection(list.summaryName,list._id)" class="c-img-1" loading="lazy" alt={{list.summaryName}}>
                  <img *ngIf="list.customThumbNail != '' && list.thumbNail == null" src="{{list.customThumbNail}}"  (click)="collection(list.summaryName,list._id)" class="c-img-1" loading="lazy" alt="customThumbnNail">
                  <div class="ml-md-3 title">
   <P class="pt-md-2" (click)="collection(list.summaryName,list._id)">{{list.summaryName}}</P>
                     <a class="mt-md-3" [href]="list.publisherName" target="_blank">publisher by <span class="text-decoration-underline">{{list.publisherName}}</span></a>
                        </div>
                        </div>
                         
                  
<div class="ml-md-3 my-md-4"> 
                     <div class="count_of_likes my-md-3 d-flex justify-content-around">
                         <div class="likes">
                            <p>{{list.viewCount}} reads</p>
                         </div>
                         <div class="likes">
                            <p>{{list.likeCount}} likes</p>
                         </div>
                         <div class="likes">
                            <p>{{list.commentCount}} comments</p>
                         </div>
                     </div>

                     <div class="languages_section" *ngIf="list.translatedLanguages != ''">
                         <h6>Transalted in following languages</h6>
                         <div class="d-flex l-flex">
<button class="cursor l-btn" *ngFor="let btn of list.translatedLanguages">
                             {{btn}} <img src="../../../assets/images/tick-11.png">
                          </button>
                         </div>
                          
                     </div>
                     <div class="languages_section mt-md-3">
                         <h6>Translate in other languages</h6>
                         <div class="d-flex l-flex">
                         
                            <form [formGroup]="translate">

             
               <select class="form-control" (click)="translateBtn(list.translatedLanguages)" formControlName="googleTransalate" id="languageSelect "   #languageSelect
                (change)="selecteLanguage(languageSelect.value,$event,i,list._id,list)">
               <option value="" disabled selected>Translate</option>
               <option  *ngFor="let lang of afterTranslateCompelted" [value]="lang.value">{{ lang.name}}</option>
                </select>
                 <div *ngIf="c.googleTransalate.errors?.required && list.showErrorMesseage" class="invalid-feedback m-t d-block"> Please select the language </div>
                 </form>
                         </div>
                          
                     </div>
                     <!-- <div>
  <button mat-raised-button (click)="toggleSelect()">Select Language</button>
  <mat-select [(value)]="selectedLanguage1" #select>
    <mat-option *ngFor="let language of languages" [value]="language.value">
      {{ language.name }}
    </mat-option>
  </mat-select>
</div> -->
                  </div>
                
                      </div>
                   </div>
                    <a  *ngIf="list.showDropDown" class="mt-md-3" href="c/{{list.summaryName?.replace('/?','').split(' ')?.join('-')}}/id-{{_id}}"> <p class="check_summary">Click here to view the  summary in {{this.LanguageName}}</p></a>
         <div *ngIf="this.error  != '' && list.showError" class="error m-t d-block"> {{this.error}} </div>
                    
                </div>
             </div>
         </div>
        </div>
    </div>
</div>

 <!-- <app-footer ></app-footer> -->
