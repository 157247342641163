import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CreatordialogchannelComponent } from '../creatorchannelcreate/creatordialogchannel/creatordialogchannel.component';
import { CollectionService } from '../_services/collection.service';

@Component({
  selector: 'app-creatornavbar',
  templateUrl: './creatornavbar.component.html',
  styleUrls: ['./creatornavbar.component.css'],
})
export class CreatornavbarComponent implements OnInit {
  categoriesBtn: boolean = true;
  ChannelName: any;
  refreshSubscription: any;

  constructor(
    private router: Router,
    private collectionServices: CollectionService,
    private dialog: MatDialog
  ) {
    if (localStorage.getItem('token') != null) {
      this.refreshSubscription = this.collectionServices.refresh$.subscribe(
        () => {
          // Perform any other action you need on refresh
          console.log('refreshing subscription publishercollection');
          this.collectionServices.creatorChannel().subscribe((res) => {
            this.ChannelName = res.data;
            console.log(this.ChannelName);
          });
        }
      );
    }
  }

  ngOnInit(): void {
    if (localStorage.getItem('token') != null) {
      this.categoriesBtn = false;
      this.collectionServices.creatorChannel().subscribe((res) => {
        this.ChannelName = res.data;
        console.log(this.ChannelName);
      });
    }
  }

  linkProductBtn() {
    // this.hideComponent = false;

    if (this.ChannelName == '') {
      this.router.navigate(['Publisher/Blogs'], {
        queryParams: { createBlog: false },
      });
    } else {
      this.router.navigate(['/publisher/post_type-collection/new']);
    }
  }
  myVideos() {
    this.router.navigate(['/Publisher/Home']);
  }
  publisherAnalatics() {
    this.router.navigate(['/publisher/Analytics']);
  }
  logout() {
    localStorage.clear();
    this.router.navigate(['/feed']);
    setTimeout(() => {
      window.location.reload();
    });
  }
  myCollections() {
    this.router.navigate(['My-Collections']);
  }
  collectionRouterCreator() {
    this.router.navigate(['/creator/post_type-collection/new']);
  }
  productRouteCreator() {
    this.router.navigate(['/creator/post_type-product_link/new']);
  }
  channelBtn() {
    this.router.navigate(['creator/createChannel']);
  }
  viewlinks() {
    this.router.navigate(['creator/viewlinks']);
  }
  profile() {
    this.router.navigate(['/Publisher/profile']);
  }
  myProducts() {
    this.router.navigate(['My-Products']);
  }
}
