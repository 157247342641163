import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CollectionService } from 'src/app/_services/collection.service';

@Component({
  selector: 'app-publisher-onboarding',
  templateUrl: './publisher-onboarding.component.html',
  styleUrls: ['./publisher-onboarding.component.css'],
})
export class PublisherOnboardingComponent implements OnInit {
  categoryList: any;
  onboarding: boolean = true;
  showTopics: boolean = false;
  gender: boolean = false;
  options: string[] = ['Male', 'Female', 'Both'];
  selectedOption: string = '';
  constructor(
    private collectionService: CollectionService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.collectionService.caterogoryList().subscribe((res: any) => {
      this.categoryList = res.data;
      this.categoryList.forEach(
        (category: any) => {
          category.isButtonSelected = false;
        },
        (error: any) => {}
      );
    });
    setTimeout(() => {
      this.onboarding = false;
      this.showTopics = true;
    }, 3000);
  }

  selectOption(option: string) {
    this.selectedOption = option;
  }
  singup() {
    this.router.navigate(['/publisher/myVideos']);
  }
  Continue() {
    this.showTopics = false;
    this.gender = true;
  }
  toggleColor(item: any) {
    item.isButtonSelected = !item.isButtonSelected;
    console.log('toggleColor', item.isButtonSelected);
  }
}
