<app-homenavbar *ngIf="sessionType == 'home'"></app-homenavbar>
<app-usernavbar *ngIf="sessionType == 'user'"></app-usernavbar>
<app-navbar *ngIf="sessionType == 'powerUser'"></app-navbar>
<app-creatornavbar *ngIf="sessionType == 'publisher'"></app-creatornavbar>

<app-loader *ngIf="loader"></app-loader>
<div class="main mt-5" *ngIf="userDetails">
    <div class="container">
        <div class="profile-section">
            <div class="d-flex mb-3" *ngFor="let user of userDetails">

                <div class="d-flex">
<div class="pr-img">
                    <img *ngIf="user.profile == null" src="../../../assets/images/user-1.png">
                    <img *ngIf="user.profile != null" src="{{user.profile}}">
                </div>
                 <div class="pr-details ml-3">
                    <div class="d-flex user-details-flex">
                        <div class="pr-name">
                            <h4>{{user.name}}</h4>
                        </div>
                        <div class="pr-follow ml-3">
                            <!-- <button class="cursor">Follow</button> -->
                        </div>
                           <div class="pl-md-5">
                    <img src="../../../assets/images/sh.png" class="share-img cursor" (click)="shareUserProfile()">
                </div>
                    </div>
                    <h6> {{user?.followpublisher[0]?.publisherName}} <span *ngIf="user?.followpublisher[1]?.publisherName">and {{user?.followpublisher[1]?.publisherName}}</span> </h6>
                     
                        <p> Follow {{user.name}} Find the best AI summaries in 
  <span *ngFor="let item of categoriesData?.slice(0,8); let last = last">
    {{ item.name }}{{ !last ? ', ' : '' }}
  </span>
</p>
                               
                  
              
                    <!-- <div class="d-flex pr-followers">
                        <p> 200 followers</p>
                        <p class="ml-md-3"> 500 following</p>
                    </div> -->
                </div>
                </div>

             
                
            </div>
        </div>
    </div>

    <div class="user-summaries-section mt-md-3">
        <div class='row'>
            <div class="col-md-8">
                <div class="summaries-section pt-md-5 pl-md-4">
                    <h3> Summaries read by {{userName}}</h3>
                    <div class="d-flex scroll" >
            <button class="category-btns pointer" (click)="click()" [class.all]="active == true">
              All
            </button>
            <button class="category-btns pointer" *ngFor="let items of categoriesData" (click)="navClick(items)" [class.all]="items.name == activeState">
              {{items.name}}
            </button>
          </div>
           <div class="pb-2" infinite-scroll (scrolled)="onScrollDown($event)">
                  <div class="summary card mb-2" id="fullSummary" *ngFor="let collection of collectionList;let i = index"  >
                  <div class="card-body">
                    <div class="d-flex summary-p">
                      <div class="summary-header ml-md-4 channel-details">
                         

                        <div class="summary-start ">
                          <div class="d-flex">
                        <a href="/c/{{collection?.summaryName?.split(' ')?.join('-')}}/id-{{collection._id}}"  >
                            <h3>{{collection.summaryName}}</h3>
                          
                          </a>
                          <div class="moblie-tweet">
                         <div *ngIf="!collection.bookmarkSummary" class="comment ml-4" (click)="saveCollection(collection,true,collection._id,collection.summaryName)">
                          <img src="../../../assets/images/h.png" class="img-b">
                        </div>
                        <div *ngIf="collection.bookmarkSummary" class="comment ml-5">
                          <img src="../../../assets/images/he-1.png" class="img-b">
                        </div>
                        </div>
                          </div>
                   
                            <img *ngIf="collection.thumbNail != '' && collection.customThumbNail == null" src="{{collection.thumbNail}}" class="img" loading="lazy" alt={{collection.summaryName}}>
                  <img *ngIf="collection.customThumbNail != '' && collection.thumbNail == null" src="{{collection.customThumbNail}}" class="img" loading="lazy" alt="customThumbnNail">
                          <h6>Video source <a target="_blank" href="{{collection.video}}">Youtube</a>
                          </h6>
                          <div   class="summary-paragrph summary-text" innerHTML="{{collection.Summary}}"></div>
                        </div>
                        <div class="mt-1 read-s">
                      <a href="/c/{{collection?.summaryName?.split(' ')?.join('-')}}/id-{{collection._id}}" class="anchor-tag">Show more</a>
                   
                    </div>
                      </div>
                    </div>
                    
                    <div class="channel-card pl-md-5">
                      <div class="d-flex">
                        <div class="channel-avatar-section mr-5">
                          <div class="d-flex">
                            <a (click)="navigateChannel(collection.publisherName)">
                            <div class="avartar">
                              <img *ngIf="collection.publisherImage == null" src="../../../assets/images/user-1.png" class="user-img">
                              <img  *ngIf="collection.publisherImage != null"  src="{{collection.publisherImage}}" class="user-img">
                            </div>
                            </a>
                            <div class="channel-details">
                               <a (click)="navigateChannel(collection.publisherName)" >
                              <h4>{{collection?.publisherName}}</h4>

                               </a>
                              <!-- <div class="d-flex details-tube"><img src="../../../assets/images/youtube-1.png" class="youtube-img"><p>2M+</p></div> -->
                              <a  class="subscribed" *ngIf="!collection.followpublisher" (click)="subscribeChannel(collection,true)">Follow</a>
                              <a  class="subscribed" *ngIf="collection.followpublisher">Followed</a>
                            </div>
                          </div>
                        </div>
                        <div>
                        
                     <div >
                      <div  class='category-follow mt-2 mr-3' *ngFor="let categories of collection.topics.slice(0,1)">
                          <div class="d-flex ">
                            <div *ngIf="!categories.topicsFollow" class="catergory-details">
                              <a (click)="catergoryNavigate(categories.name)"><h6 class="mb-md-2" >Join <span>{{categories.name}}</span> community</h6></a>
                            
                              <a class="catergoryAnchorTag" (click)="followBtn(categories,true,collection)">join</a>
                            </div>
                            <div *ngIf="categories.topicsFollow" class="catergory-details-1">
                               <a (click)="catergoryNavigate(categories.name)"><h6 class="mb-md-2" ><span>{{categories.name}}</span> community</h6></a>
                  
                              <a class="catergoryAnchorTag" (click)="unFollowCategories(categories,true,collection)"><span class="glyphicon glyphicon-">Joined</span></a>
                            </div>
                          </div>
                        </div>
                     </div>
                     
                        </div>
                        
                         <div class="tweet web-tweet mt-3 ml-md-5">
                          <a [href]="tweetBtn(collection)"   data-app="share_buttons" data-app-config='{"service":"twitter"}' target="_blank">Tweet this!
                            <img src="../../assets/images/tw.png">
                          </a>
                        </div>

                      </div>
                    </div>
                    <div class="share-section pt-3">
                           <p class="blink-sum ml-md-5 pl-md-3" *ngIf="collection.summaryLikeCount == 0"> Blink it (read this)</p>
                           <p class="blink-sum ml-md-5 pl-md-3" *ngIf="collection.summaryLikeCount != 0 && !collection.summaryLike">{{ collection.summaryLikeCount }}  Blinked (read this)</p>
                        <p  class="blink-sum ml-md-3 pl-md-3" *ngIf="collection.summaryLike"> you and {{ collection.summaryLikeCount }} others Blinked (read this)</p>
                      <div class="d-flex justify-content-md-around media-screen-flex">

                        <div class="thumps-up-image pointer">
                         
                          <button class="upVote" *ngIf="!collection.summaryLike" (click)="
                            likeCollections(collection,true,collection._id)">
                            <img src="../../../assets/images/blink.png">
                            <p id="upvote " class="d-flex">
                              Blink <img mat-raised-button
        matTooltip="a blink means you read this summary"
        matTooltipPosition="left"
        aria-label="Button that displays a tooltip in various positions" src="../../assets/images/info.png" class="info-img">
                            </p>
                          </button>
                          
                          <button class="upVote" *ngIf="collection.summaryLike">
                              <img src="../../../assets/images/blink.png">
                               
                            <p id="upvote" class="d-flex Blinked"> Blinked <img mat-raised-button
        matTooltip="a blink means you read this summary"
        matTooltipPosition="left"
        aria-label="Button that displays a tooltip in various positions" src="../../assets/images/info.png" class="info-img"></p>
                          </button>
                        </div>
                        <div class="comment" (click)="comments(collection)" >
                          <img src="../../../assets/images/bubble-chat.png">
                          <p *ngIf="collection.SummaryCommentCount != 0"> {{collection.SummaryCommentCount}} comments</p>
                          <p *ngIf="collection.SummaryCommentCount == 0"> comment</p>
  
                        </div>
                        <div class="comment" (click)="share(collection)">
                          <img src="../../../assets/images/share-1.png">
                          <p>share</p>
                          
                        </div>

                        <div class="web-tweet">
                         <div *ngIf="!collection.bookmarkSummary" class="comment" (click)="saveCollection(collection,true,collection._id,collection.summaryName)">
                          <img src="../../../assets/images/h.png">
                          <p class="bookmark">Read later</p>
                        </div>
                        <div *ngIf="collection.bookmarkSummary" class="comment">
                          <img src="../../../assets/images/he-1.png">
                          <p>Added to Reading list</p>
                        </div>
                        </div>
                       
                         <div class="tweet moblie-tweet mt-2">
                          <a [href]="tweetBtn(collection)"   target="_blank">Tweet this!
                            <img src="../../assets/images/tw.png">
                          </a>
                        </div>
                      </div>
                    </div>
   <!-- /* comments section */ -->
           <div class="comments-section ml-md-5 pl-2 pb-4 mt-4 pt-3" *ngIf="collection.showCollectionComment">
              <h5 class="total_comments">{{totalComments}} Comments</h5>
              <div class="comment-header">
                <div class="d-flex">
                  <div class="comment-img">
                      <img *ngIf="collection.thumbNail != '' && collection.customThumbNail == null" src="{{collection.thumbNail}}" class="c-img-1" loading="lazy" alt={{collection.summaryName}}>
                  <img *ngIf="collection.customThumbNail != '' && collection.thumbNail == null" src="{{collection.customThumbNail}}" class="c-img-1" loading="lazy" alt="customThumbnNail">
                  </div>
                  <div class="comment-input-field ml-md-3">
                    <form [formGroup]="commentForm">
                      <input [readOnly]="readonly"   (keyup)="nameValidations($event,'title')" 
                 class="comment-form-input" [class.inputActive]="inputActive == true" formControlName="title" (focus)="toggleButtons()" (click)="commentEvent()">
                      <div *ngIf="commentFormError.title.errors?.maxlength" class="invalid-feedback m-t d-block"> Only 1000 characters are allowed </div>
                      <div class="d-flex mt-3 justify-content-md-end comment-add_form" *ngIf="showButtons">
                        <button class="cancel-comment mr-md-3 cursor" (click)="cancelComment()"> Cancel </button>
                        <button class=" comment-form-button cursor" [disabled]="commentForm.invalid" type="submit" (click)="onSubmitComment(collection)"> Comment </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <app-loader  *ngIf="showCommentLoader"></app-loader>
              <div class="comments-user-added mt-4" *ngFor="let comments of commentUser">
                <div class="d-flex">
                  <div class="comment-user-img">
                    <img *ngIf="comments.reader[0].profile == null" src="../../../assets/images/pr-img.jpeg">
                    <img *ngIf="comments.reader[0].profile != null" src="{{comments.reader[0].profile}}">
                  </div>
                  <div class="user-comments-data ml-md-3">
                    <div class="d-flex">
                      <h4>@{{comments.reader[0].name}}</h4>
                      <p class="ml-2">{{comments.createdAt | datepipe}}</p>
                    </div>
                    <h6>{{comments.comment}}</h6>
                         
                    <!-- EDIT-COMMENT-FORM-SECTION -->
                     <div class="comment-input-field ml-md-3" *ngIf="comments.isEdit">
                    <form [formGroup]="editCommentForm">
                      <input [readOnly]="readonly"  (keyup)="nameValidations($event,'edit')"
                  class="comment-form-input" [class.inputActive]="editActive == true" formControlName="edit" (focus)="toggleEditButtons()" (click)="commentEvent()">
                      <div class="d-flex mt-3 justify-content-end" *ngIf="showEditButtons">
                        <button class="cancel-comment mr-md-3 cursor" (click)="cancelEditComment(comments)"> Cancel </button>
                        <button class=" comment-form-button cursor" [disabled]="editCommentForm.invalid" type="submit" (click)="saveEditForm(comments)"> Save </button>
                      </div>
                    </form>
                  </div>
                    <!-- EDIT-COMMENT-FORM-SECTION -->
                    <div class="d-flex">
                    <a class='reply mr-md-3 cursor mr-2'   (click)="replyShowInput(comments)">
                      <h3>Reply</h3>
                    </a>
                    <a class='reply cursor mr-md-3 mr-2' *ngIf="comments.reader[0]._id == userTypeId" (click)="editMainComment(comments)">
                      <h3>Edit</h3>
                    </a>
                    <a class='reply cursor' (click)="deletComment(comments)" *ngIf="comments.reader[0]._id == userTypeId">
                      <h3>Delete</h3>
                    </a>
                    </div>
                    
                  </div>
                  <div class="ml-md-auto mat-menu-collection">
<button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
  <mat-icon>more_vert</mat-icon>
</button>
<mat-menu #menu="matMenu" class="mat-menu" yPosition="above">
  <button mat-menu-item class="mat-item-span" (click)="spamComment(comments,collection)"> 
    <mat-icon><img  src="../../../assets/images/red-flag.png" class="mr-3 spam-img"></mat-icon>
    <span>Mark as Spam</span>
  </button>
  <button mat-menu-item class="mat-item-span" (click)="spamOffensive(comments,collection)">
    <mat-icon><img  src="../../../assets/images/sad.png" class="mr-3 spam-img"></mat-icon>
    <span>Mark as Offensive</span>
  </button>
  <button mat-menu-item class="mat-item-span"  *ngIf="collection.reader[0]._id == userTypeId" (click)="deleteCommentPublisherandPowerUser(comments)"> 
    <mat-icon><img  src="../../../assets/images/de.png" class="mr-3 spam-img"></mat-icon>
    <span>Delete Comment</span>
  </button>
</mat-menu>
                  </div>
                  
                  <!-- <div class="comment-spam-img d-flex ml-md-auto">
                    <a (click)="spamComment(comments)"><img  src="../../../assets/images/spam (1).png" class="mr-3"></a>
                    <a (click)="spamOffensive(comments)"><img  src="../../../assets/images/report (1).png"></a>
                  </div> -->
                </div>
                <div class="comment-header ml-md-5 pl-md-3 mt-md-3" *ngIf="comments.showReplyForm">
                  <div class="d-flex">
                    <div class="comment-img">
                      <img src="../../../assets/images/user-1.png" width="50">
                    </div>
                    <div class="comment-input-field ml-md-3">
                      <form [formGroup]="replyCommentForm">
                        <input class="comment-form-input" (keyup)="nameValidations($event,'reply')"  [class.inputActive]="inputActive == true" formControlName="reply" (focus)="toggleButtonsReply()" (input)="commentEventReply($event)">
                           <div *ngIf="replyCommentFormError.reply.errors?.maxlength" class="invalid-feedback m-t d-block"> Only 1000 characters are allowed </div>
                        <div class="d-flex mt-3 justify-content-end" *ngIf="showButtonsReply">
                          <button class="cancel-comment mr-md-3 cursor" (click)="cancelCommentReply(comments)"> Cancel </button>
                          <button class=" cursor comment-form-button" [disabled]="replyCommentForm.invalid" type="submit" (click)="onReplyComment()"> Reply </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <a class="ml-5 pl-md-3 pl-2 replycount" *ngIf="comments.commentReply.length != 0" (click)="showReplyBtn(comments)">{{comments.commentReply.length}} reply</a>
                <div *ngIf="comments.showReplys">
                  <div class="comments-user-added mt-md-4 mt-3 ml-md-5" *ngFor="let reply of comments.commentReply">
                    <div class="d-flex">
                      <div class="comment-user-img">
                        <img *ngIf="reply.profile == null" src="../../../assets/images/pr-img.jpeg">
                        <img *ngIf="reply.profile != null" src="{{reply.profile}}">
                      </div>
                      <div class="user-comments-data ml-md-3">
                        <div class="d-flex">
                          <h4>@{{reply.name}}</h4>
                          <p class="ml-2">{{reply.createdAt | datepipe}}</p>
                        </div>
                        <h6>{{reply.comment}}</h6>
                           <!-- EDIT-COMMENT-FORM-SECTION -->
                     <div class="comment-input-field ml-md-3" *ngIf="reply.isEdit">
                    <form [formGroup]="replyEditCommentForm">
                      <input [readOnly]="readonly"  (keyup)="nameValidations($event,'replyEdit')"
                class="comment-form-input" [class.inputActive]="editActive == true" formControlName="replyEdit" (focus)="toggleReplyEditButtons()" (click)="commentEvent()">
                      <div class="d-flex mt-3 justify-content-end" *ngIf="showReplyEditButtons">
                        <button class="cancel-comment mr-md-3 cursor" (click)="cancelEditComment(reply)"> Cancel </button>
                        <button class=" comment-form-button cursor" [disabled]="replyEditCommentForm.invalid" type="submit" (click)="saveReplyEditForm(comments)"> Save </button>
                      </div>
                    </form>
                  </div>
                    <!-- EDIT-COMMENT-FORM-SECTION -->
                    <div class="d-flex">
                   
                    <a class='reply cursor mr-3' *ngIf="reply.userId == userTypeId" (click)="editReplyComment(reply)">
                      <h3>Edit</h3>
                    </a>
                    <a class='reply cursor' (click)="deletReplyComment(comments,reply._id)" *ngIf="reply.userId == userTypeId">
                      <h3>Delete</h3>
                    </a>
                    </div>
                      </div>
                       <div class="ml-md-auto mat-menu-collection">
<button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
  <mat-icon>more_vert</mat-icon>
</button>
<mat-menu #menu="matMenu" class="mat-menu" yPosition="above">
  <button mat-menu-item class="mat-item-span" (click)="spamReplyComment(comments,reply._id,collection)"> 
    <mat-icon><img  src="../../../assets/images/red-flag.png" class="mr-3 spam-img"></mat-icon>
    <span>Mark as Spam</span>
  </button>
  <button mat-menu-item class="mat-item-span" (click)="spamReplyOffensive(comments,reply._id,collection)">
    <mat-icon><img  src="../../../assets/images/sad.png" class="mr-3 spam-img"></mat-icon>
    <span>Mark as Offensive</span>
  </button>
  <button mat-menu-item class="mat-item-span" (click)="deleteReplyCommentPublisherandPowerUser(comments,reply._id)" *ngIf="collection.reader[0]._id == userTypeId"> 
    <mat-icon><img  src="../../../assets/images/de.png" class="mr-3 spam-img"></mat-icon>
    <span>Delete Comment</span>
  </button>
</mat-menu>
                  </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /* comments section */ -->
                  </div>
                 
                </div>
              </div>
                </div>
                
            </div>
            <div class="col-md-4">

                <div class="publisher-card">
                     <h4>Publishers Followed by {{userName}}</h4>
                    <div class="card mb-md-2" *ngFor="let name of followChannel">
                        <div class="card-body">
                           
                            <div class="d-flex mt-md-2">
                                <div class="c-img">
                                    <img  *ngIf="name.publisherImage != null" src="{{name.publisherImage}}" width="50">
                                    <img *ngIf="name.publisherImage == null" src="../../../assets/images/user-1.png" width="50">
                                </div>
                                <div class="pr-followed-channel ml-md-3 mr-auto">
                                    <h5>{{name.publisherName.slice(0,15)}}r</h5>
                                    <!-- <h6 *ngFor="let categroy of followpublisher.channelCategory">{{categroy?.name}}</h6> -->
                                    <p>{{name.summaryCount ? name.summaryCount : 0}} AI Summaries</p>
                                </div>
                                <div class="follow-c-pr ml-auto">
                             <button *ngIf="!name.publisherFollow" (click)="channelSubsribeProfile(name,true)">Follow</button>
                              <button  *ngIf="name.publisherFollow">Followed</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="publisher-card mt-md-4">
                            <h4>Topics {{userName}} Interested in</h4>

                    <div class="card mb-md-2" *ngFor="let name of categoriesData">
                        <div class="card-body">
                            <div class="d-flex mt-md-2">
                                <div class="c-img">
                                    <img  *ngIf="name.topicImage != null" src="{{name.topicImage}}" width="50">
                                    <img *ngIf="name.topicImage == null" src="../../../assets/images/skin-care.png" width="50">
                                </div>
                                <div class="pr-followed-channel ml-md-3">
                                    <h5>{{name.name}}</h5>
                                    <h6>Discover AI summaries in {{name.name}}</h6>
                                </div>
                                <div class="follow-c-pr ml-md-5  ml-auto">
                                     <div  class='category-follow mt-2 mr-3'>
                          <div class="d-flex "> 
                            <div *ngIf="!name.topicFollow" class="catergory-details">
                              <button (click)="followcategoryBtn(name)">join</button>
                            </div>
                            <div *ngIf="name.topicFollow" class="catergory-details-1">
                             
                              <button (click)="unFollowCategoriesBtn(name)"><span class="glyphicon glyphicon-">Joined</span></button>
                            </div>
                          </div>
                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>