<!-- <div class="main">
  <app-homenavbar></app-homenavbar>

    <div class="container-fluid">
        
        <div class="login-section ">
          <div class="row flex-column-reverse flex-md-row">
              <div class="col-md-6 ">
                <div class="login-section-common-data pl-md-5 pt-md-5" id="signup-para">
                      <h4 class="ml-md-2 mt-md-3 pb-md-1">Turn youtube videos into AI Blogs</h4>
<h6>Why Summarizly AI?</h6>

                       <div class="d-flex points mt-3">
                        <img src="../../assets/images/check-1.png" class="check-1">
                        <p class="ml-2">Create an AI blog in seconds and publish
</p>
                    </div>
                    <div class="d-flex points mt-2">
                        <img src="../../assets/images/check-1.png" class="check-1">
                        <p class="ml-2">Grow your audience and make money from each blog</p>
                    </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="login-form-section" >

                    <div class="email-section" >
                    <div class="form-section mt-3">

    
                   <h4 class="ml-md-4 pl-md-2">Publisher Signup</h4>
                      <div class="d-md-flex mt-md-5">
                         <form [formGroup]="signUpEmail">

                  
                            <div class="email-form-section ml-md-4 pl-md-2">
                           <input  class="form-control" (keypress)="emailValidations($event)" formControlName="email" placeholder="Enter email address">
                             <div
                    *ngIf="signUpEmailError.email.errors?.required && submitted"
                    class="invalid-feedback m-t d-block"
                  >
                    Email is required
                  </div>
                            <div
                    *ngIf="signUpEmailError.email.errors?.email && submitted"
                    class="invalid-feedback m-t d-block"
                  >
                    Email is invalid
                  </div>
                            <div
                    *ngIf="signUpEmailError.email.errors?.pattern && submitted"
                    class="invalid-feedback m-t d-block"
                  >
                    Email is invalid
                  </div>
                         </div>
                           </form>
                         <div class="btn-signin ">
                              <button class="pointer" (click)="signUp()"  type="submit">Sign up for free</button>
                           </div>
                           
                        </div>
                        
                         
                    </div>
                   

                    <div class='google-intergration ml-md-4 pl-md-2'>
                         <p class="py-md-2">OR</p>
                          <div class=" google-btn">
   <button type="submit">
    
     <a [href]="url | safePipe">

    <img src="../../assets/images/google.png" class="google mr-md-3">Sign up via Google
  </a>
  </button>
   <h6>Don’t have an account? <span class="pointer" ><a (click)="loginNow()">Login</a></span></h6>
</div>

                    </div>
                    </div>
                   
                </div>
               
              </div>
          </div>
        </div>
    </div>
</div> -->


<div class="main">
   <div class="publisher_singup bg-img">
    
      <div class="Publisher_header">
        <div class="details_publisher">
          <div>
           <h3>A picture is worth 1000 words</h3>
          <h3>Isn't your video worth 10,000 words?</h3>
          <div class="singup_view">
          <!-- <p>Turn youtube videos into AI blogs in seconds</p> -->
           <p>Turn YouTube videos to AI blogs.
Magically, Share it with the world in 90+ languages <span>in seconds</span></p>
            <div class=" google-btn">
   <button type="submit">
     <a [href]="url | safePipe">
    <img src="../../assets/images/google.png" class="google mr-md-3">Continue with Google
  </a>
  </button>
</div>

<a class="works" (click)="howItworks()">How it works?</a>
          </div>
          

          </div>
          
        </div>
          
      </div>
   </div>
</div>