import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { fas } from '@fortawesome/free-solid-svg-icons';

import { AnimationOptions } from 'ngx-lottie';
import { environment } from 'src/environments/environment';
import { CollectionService } from '../_services/collection.service';

declare var clevertap: any;
@Component({
  selector: 'app-useronboardingflow-dialog',
  templateUrl: './useronboardingflow-dialog.component.html',
  styleUrls: ['./useronboardingflow-dialog.component.css'],
})
export class UseronboardingflowDialogComponent implements OnInit {
  followCatergory: boolean = true;
  channelFollow: boolean = false;
  loadingSketlon: boolean = false;
  welcome: boolean = false;
  buttonDisabledNext: boolean = true;
  followBtn: boolean = true;
  unfollowBtn: boolean = false;
  follow2more: boolean = false;
  channelFollow1more: boolean = false;
  channelFollow2more: boolean = false;
  lastOne: boolean = false;
  channelBtnDisabled: boolean = true;
  categoryList: any;
  buttonDisabled: any = [];
  channelListNames: any;
  channelNamesList: any = [];
  userID: any;
  channelNames: any = [];
  channelNameShow: any;
  channelNamesView: any;
  params: any;
  catergoryNames: any = [];
  showText: boolean = false;
  topicLoader: boolean = false;
  loader: boolean = false;
  catergorySeleceted: any = [];
  items: any;
  totaluserCatgoeyFollowedList: any;
  Publisherloader: boolean;
  channelFollowedList: any = [];
  constructor(
    private collectionService: CollectionService,
    private dialog: MatDialog
  ) {}
  options: AnimationOptions = {
    path: '../../assets/images/s.json',
  };
  optionsW: AnimationOptions = {
    path: '../../assets/images/w.json',
  };

  ngOnInit(): void {
    // console.log('clevertap initialized');
    // clevertap.init('6ZW-454-496Z');

    this.collectionService.caterogoryList().subscribe((res: any) => {
      this.categoryList = res.data;
      this.categoryList.forEach(
        (category: any) => {
          category.isButtonSelected = false;
          console.log(this.categoryList);
        },
        (error: any) => {
          console.log(error.error);
        }
      );
      this.collectionService.loginUserDetails().subscribe((user) => {
        console.log(user);
        this.userID = user.data._id;
      });

      // this.collectionService.channelList().subscribe((res) => {
      //   console.log(res);
      //   this.channelListNames = res.data;
      // });
    });
  }

  toggleColor(item: any) {
    item.isButtonSelected = !item.isButtonSelected;
    console.log('toggleColor', item.isButtonSelected);

    if (item.isButtonSelected) {
      this.FollowBtn(item);
    } else {
      this.unFollowCategory(item);

      console.log('item pushed', this.items);
    }
  }
  close() {
    this.dialog.closeAll();
  }
  FollowBtn(item: any) {
    // console.log(event);
    this.catergorySeleceted.push(item.name);
    console.log('dev clicks', item.name);
    if (environment.CLEVERTAP) {
      console.log('producton clicks');
      clevertap.event.push('ReaderSingupSelectTopics', {
        topicName: item.name,
        topicId: item._id,
      });
      clevertap.setLogLevel(2);
    }
    this.topicLoader = true;
    let obj = {
      topicId: item._id,
    };
    this.collectionService.caterogyFollow(obj).subscribe((res) => {
      // element.textContent = text;
      this.buttonDisabled.push(item);
      this.collectionService.caterogyFollowList().subscribe((res) => {
        this.totaluserCatgoeyFollowedList = res.data;

        if (this.totaluserCatgoeyFollowedList != '') {
          console.log('button enabled');
          this.buttonDisabledNext = false;
          this.topicLoader = false;

          this.lastOne = false;
        } else {
          this.buttonDisabledNext = true;
        }
      });
    });
  }
  unFollowCategory(item: any) {
    // console.log(event);
    this.catergorySeleceted.push(item.name);

    this.collectionService.caterogyUnFollow(item._id).subscribe((res) => {
      // element.textContent = text;
      // this.buttonDisabled.push(item);
      this.collectionService.caterogyFollowList().subscribe((res) => {
        this.totaluserCatgoeyFollowedList = res.data;

        if (this.totaluserCatgoeyFollowedList != '') {
          this.buttonDisabledNext = false;
          this.lastOne = false;
        } else {
          this.buttonDisabledNext = true;
        }
      });
    });
  }

  FollowChannelName(element: any, item: any, text: any, i: any) {
    this.channelNames.push(item.name);
    element.textContent = text;
    this.channelNamesList.push(item);
    if (environment.CLEVERTAP) {
      clevertap.event.push('ReaderSingupFollowsPublishers', {
        publisherName: item.name,
        publisherId: item._id,
      });
      clevertap.setLogLevel(2);
    }
    this.Publisherloader = true;
    this.params = {
      publisherName: item.name,
      publisherId: item._id,
    };
    this.channelFollowedList.push(item.name);
    this.collectionService
      .followCreatorChannel(this.params)
      .subscribe((res) => {
        // console.log(res);
        if (i !== -1) {
          this.channelListNames.splice(i, 1);
        }
        this.Publisherloader = false;

        if (this.channelNamesList.length == 1) {
          // this.channelFollow1more = true;
          this.channelFollow2more = false;
          this.channelBtnDisabled = false;
        }

        // if (this.channelNamesList.length == 2) {
        //   console.log(' 2 channel button enabled');
        //   this.channelFollow2more = true;
        //   this.channelFollow1more = false;
        // }

        // if (this.channelNamesList.length == 3) {
        //   console.log(' 3 channel button enabled');
        //   this.channelFollow2more = false;
        //   this.channelBtnDisabled = false;
        // }
      });
  }
  unFollowBtn(item: any) {
    this.unfollowBtn = false;
    this.followBtn = true;
  }
  catergoryCompleted() {
    this.channelFollow = true;
    this.loader = true;
    this.followCatergory = false;
    // }

    if (this.userID) {
      let channelParams = {
        readerId: this.userID,
      };
      this.collectionService
        .channelListOnboarding(channelParams)
        .subscribe((res) => {
          this.channelListNames = res.data;
          this.loader = false;
        });
    }
  }
  nextChannel() {
    // if (environment.CLEVERTAP) {
    //   clevertap.event.push('ReaderSingupCompleted', {
    //     ReaderSingupCompleted: 'Readers signup Compelted', // String
    //   });
    // }
    this.loadingSketlon = true;
    this.channelFollow = false;
    this.collectionService.onBoardingUser().subscribe((user) => {
      console.log(user);
    });

    setTimeout(() => {
      this.loadingSketlon = false;
      this.welcome = true;
    }, 4000);
    setTimeout(() => {
      this.welcome = false;
      this.dialog.closeAll();
    }, 7000);
  }
}
