import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NoAuthSignupComponent } from 'src/app/no-auth-signup/no-auth-signup.component';
import { CollectionService } from 'src/app/_services/collection.service';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { ContentObserver } from '@angular/cdk/observers';
import { DeleteSummaryComponent } from '../delete-summary/delete-summary.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-mycollection',
  templateUrl: './mycollection.component.html',
  styleUrls: ['./mycollection.component.css'],
})
export class MycollectionComponent implements OnInit {
  collectionList: any;
  productError: any;
  collectionServices: any;
  userType: any;
  Language: any = [
    { name: 'English', value: 'en' },
    { name: 'Chinese', value: 'zh' },
    { name: 'Hindi', value: 'hi' },
    { name: 'Spanish', value: 'es' },
    { name: 'Arabic', value: 'ar' },
    { name: 'French', value: 'fr' },
    { name: 'Telugu', value: 'te' },
    { name: 'Russian', value: 'ru' },
    { name: 'German', value: 'de' },
    { name: 'Tamil', value: 'ta' },
    { name: 'Malayalam', value: 'ml' },
  ];
  LanguageSelected: any = [
    { name: 'English', value: 'en' },
    { name: 'Chinese', value: 'zh' },
    { name: 'Hindi', value: 'hi' },
    { name: 'Spanish', value: 'es' },
    { name: 'Arabic', value: 'ar' },
    { name: 'French', value: 'fr' },
    { name: 'Telugu', value: 'te' },
    { name: 'Russian', value: 'ru' },
    { name: 'German', value: 'de' },
    { name: 'Tamil', value: 'ta' },
    { name: 'Malayalam', value: 'ml' },
  ];
  newItems: any[];
  selectedLanguage: string = '';
  LanguageName: any;
  smallLanguageName: any;
  summaryName: any;
  _id: any;
  loader: boolean = false;
  submittedDetails: boolean = false;
  translate: FormGroup;
  catergoryList: any;
  topicSelected: any = '';
  paramsObj: any;
  lanugageSelected: any = '';
  popularitySelected: any = '';
  searchSelected: any = '';
  selectedOption: string = '';
  selectedOption1: string = '';
  selectedOption2: string = '';
  items: any;
  afterTranslate: any;
  langName: any = [];
  translatedDropDown: any = [];
  afterTranslateCompelted: any;
  error: any;
  popularity: any = ['views', 'likes', 'comments'];
  private refreshSubscription: Subscription;
  constructor(
    private service: CollectionService,
    private router: Router,
    private dialog: MatDialog,
    private fb: FormBuilder
  ) {
    this.refreshSubscription = this.service.getRefreshSignal().subscribe(() => {
      this.ngOnInit();
    });
    this.translate = this.fb.group({
      googleTransalate: ['', [Validators.required]],
    });
    // this.newItems = this.findNewItems();
  }
  get c(): { [key: string]: AbstractControl } {
    return this.translate.controls;
  }
  ngOnInit(): void {
    this.service.loginUserDetails().subscribe((res: any) => {
      this.userType = res.data.userType;
    });
    this.service.allCatergory().subscribe((res: any) => {
      console.log(res);
      var feed = res.data;
      this.catergoryList = res.data;

      console.log(this.items);
    });
    this.service.collectionList(this.paramsObj).subscribe((res) => {
      this.collectionList = res.data;
      this.collectionList.forEach((item: any) => {
        item.showDropDown = false;
        item.showError = false;
        item.showloader = false;
        item.showErrorMesseage = false;
      });
      console.log(this.collectionList);
    });
  }
  // languages = [
  //   { name: 'English', value: 'en' },
  //   { name: 'Chinese', value: 'zh' },
  //   { name: 'Hindi', value: 'hi' },
  //   // Add more languages as needed
  // ];
  // @ViewChild('select') select: MatSelect;
  // selectedLanguage1: string;
  // showSelect: boolean = false;
  // toggleSelect() {
  //   this.select.open(); // Toggle the visibility of the select dropdown
  // }
  findNewItems() {
    // return this.Language.filter(
    //   (lang) => !this.alreadyExist.includes(lang.name)
    // );
  }
  translateBtn(lan: any) {
    console.log('lang:', lan);
    this.afterTranslate = this.Language;
    // .map((item: any) => {
    //   this.langName.push(item.name);
    // });
    const results = this.afterTranslate.filter(
      (item1: any) => !lan.some((item2: any) => item1.name === item2)
    );
    this.afterTranslateCompelted = results;
    console.log('lang:', results);
  }

  selecteLanguage(
    selectedValue: any,
    event: Event,
    index: number,
    id: any,
    list: any
  ) {
    const selectedOption = (event.target as HTMLSelectElement).value;
    console.log(`Selected option on Card ${index + 1}: ${selectedOption}`);

    const selectedLanguage = this.Language.find(
      (lang: any) => lang.value === selectedValue
    );
    if (selectedLanguage) {
      this.LanguageName = selectedLanguage.name;
      this.smallLanguageName = selectedLanguage.value;
      this.afterTranslateCompelted = '';
      // console.log(
      //   `Selected Language: ${selectedLanguage.name}, Value: ${selectedLanguage.value}`
      // );
      this.googleTranslateService(id, list);
    }
  }
  edit(id: any) {
    this.router.navigate(['/Edit-Collection'], {
      queryParams: { id: id },
    });
  }
  delete(id: any) {
    console.log(id);
    this.dialog.open(DeleteSummaryComponent, {
      data: {
        id: id,
        disableClose: true,
      },
    });
    return;
  }
  topic(e: any) {
    this.topicSelected = e.target.value;
    console.log(`Selected Topic: ${this.topicSelected}`);
    this.paramsObj = {
      Topic: this.topicSelected,
      language: this.lanugageSelected,
      popularity: this.popularitySelected,
      publisherName: this.searchSelected,
    };
    this.service.collectionList(this.paramsObj).subscribe((res) => {
      this.collectionList = res.data;
      this.collectionList.forEach((item: any) => {
        item.showDropDown = false;
        item.showloader = false;
        item.showErrorMesseage = false;
      });
      console.log(this.collectionList);
    });
  }
  popularityBtn(e: any) {
    this.popularitySelected = e.target.value;
    console.log(`Selected Topic: ${this.topicSelected}`);
    this.paramsObj = {
      Topic: this.topicSelected,
      language: this.lanugageSelected,
      popularity: this.popularitySelected,
      publisherName: this.searchSelected,
    };
    this.service.collectionList(this.paramsObj).subscribe((res) => {
      this.collectionList = res.data;
      this.collectionList.forEach((item: any) => {
        item.showDropDown = false;
        item.showloader = false;
        item.showErrorMesseage = false;
      });
      console.log(this.collectionList);
    });
  }
  lanugage(e: any) {
    this.lanugageSelected = e.target.value;
    console.log(`Selected Topic: ${this.topicSelected}`);
    this.paramsObj = {
      Topic: this.topicSelected,
      language: this.lanugageSelected,
      popularity: this.popularitySelected,
      publisherName: this.searchSelected,
    };
    this.service.collectionList(this.paramsObj).subscribe((res) => {
      this.collectionList = res.data;
      this.collectionList.forEach((item: any) => {
        item.showDropDown = false;
        item.showloader = false;
        item.showErrorMesseage = false;
      });
      console.log(this.collectionList);
    });
  }
  search(e: any) {
    this.searchSelected = e.target.value;
    console.log(`Selected Topic: ${this.topicSelected}`);
    this.paramsObj = {
      Topic: this.topicSelected,
      language: this.lanugageSelected,
      popularity: this.popularitySelected,
      publisherName: this.searchSelected,
    };
    this.service.collectionList(this.paramsObj).subscribe((res) => {
      this.collectionList = res.data;
      this.collectionList.forEach((item: any) => {
        item.showDropDown = false;
        item.showloader = false;
        item.showErrorMesseage = false;
      });
      console.log(this.collectionList);
    });
  }
  reset() {
    this.selectedOption = '';
    this.selectedOption1 = '';
    this.selectedOption2 = '';
    this.paramsObj = {
      Topic: (this.topicSelected = ''),
      language: (this.lanugageSelected = ''),
      popularity: (this.popularitySelected = ''),
      publisherName: (this.searchSelected = ''),
    };
    this.service.collectionList(this.paramsObj).subscribe((res) => {
      this.collectionList = res.data;
      this.collectionList.forEach((item: any) => {
        item.showDropDown = false;
        item.showloader = false;
        item.showErrorMesseage = false;
      });
      console.log(this.collectionList);
    });
  }
  googleTranslateService(id: any, item: any) {
    console.log(this.translate, id, item);
    if (this.translate.invalid) {
      item.showErrorMesseage = true;
    } else {
      this.loader = true;
      item.showloader = true;
      item.showErrorMesseage = false;

      let obj = {
        outputLanguage: this.smallLanguageName,
        Language: this.LanguageName,
      };
      this.service.googleTranslate(id, obj).subscribe(
        (res: any) => {
          console.log(res);
          this.loader = false;
          item.showloader = false;

          item.showDropDown = true;
          this.summaryName = res.data[0].collectionName;
          this._id = res.data[0]._id;
        },
        (error) => {
          this.error = error.error.message;
          item.showError = true;
          console.log(error.error.message);
        }
      );
    }
  }
  collection(title: any, id: any) {
    this.router.navigate([`c/${title.split(' ').join('-')}/id-${id}`]);
  }
}
