import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { CollectionService } from '../_services/collection.service';
import { SuccessDialogComponent } from './success-dialog/success-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-add-categories',
  templateUrl: './add-categories.component.html',
  styleUrls: ['./add-categories.component.css'],
})
export class AddCategoriesComponent implements OnInit {
  catergoryList: any = [];
  catergory: string;
  submiited: boolean = false;
  succeed: boolean = false;
  catergoriesFormGroup: FormGroup;
  subCatergory: any = [];
  error: any;
  selectedFile: File;
  selectedFileEvent: File;
  fileNameEvent: any;
  msg: any;
  url: any = null;
  subCatergoryList: any;
  errorView: boolean;
  errorMesseage: string;
  uploadProgress: number = 0;
  uploadLoader: boolean = false;
  topicName: any;
  errorValidate: any = '';
  constructor(
    private collectionServices: CollectionService,
    private fb: FormBuilder,
    private dialog: MatDialog
  ) {
    this.catergoriesFormGroup = this.fb.group({
      categoryName: ['', [Validators.required]],
      categoryImage: [''],
      redditURL: [''],
      redditMessage: [''],
      description: [''],
      ctaText: [''],
      title: [''],
      isAdded: [''],
      subCategories: [''],
    });
  }

  ngOnInit(): void {}
  // addCategory(e: any) {
  //   this.catergoryList = e.target.value;

  //   if (
  //     this.catergoryList != '' ||
  //     this.catergoryList != null ||
  //     this.catergoryList != undefined
  //   ) {
  //     this.subCatergory = this.catergoryList.split(',');
  //   } else {
  //     this.subCatergory = '';
  //   }
  //   console.log(this.catergory);
  // }
  get c(): { [key: string]: AbstractControl } {
    return this.catergoriesFormGroup.controls;
  }
  onFileSelected(event: any): void {
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);

    reader.onload = (_event) => {
      this.url = reader.result as string;

      this.selectedFile = <File>event.target.files[0];
    };
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
    }
  }
  onFileSelectedEvent(event: any): void {
    this.uploadLoader = true;
    const file: File = event.target.files[0];
    const maxSizeKB = 100; // Maximum file size in kilobytes
    if (file) {
      const fileSizeKB = file.size / 1024; // Calculate file size in kilobytes
      this.uploadProgress = 0;
      if (fileSizeKB > maxSizeKB) {
        // Show an error message when the file is too large
        this.errorView = true;
        this.errorMesseage = `File size should be less than ${maxSizeKB}KB. Selected file size is ${fileSizeKB.toFixed(
          2
        )}KB.`;
      } else {
        const file = event.target.files && event.target.files[0];
        this.selectedFile = <File>event.target.files[0];

        if (event.target.files.length > 0) {
          this.errorView = false;

          const file = event.target.files[0];
          this.catergoriesFormGroup.controls['categoryImage'].setValue(file);
        }
        var reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = (event) => {
          const interval = setInterval(() => {
            if (this.uploadProgress < 100) {
              this.uploadProgress += 50; // Increase progress by 10% every second
              if (this.uploadProgress == 100) {
                this.url = (<FileReader>event.target).result;
                console.log(this.url);
              }
            } else {
              clearInterval(interval); // Stop interval when progress reaches 100%
            }
          }, 1000);
        };
      }
    }
  }
  subCategory(e: any) {
    this.subCatergoryList = e.target.value;
    console.log(e.target.value);

    if (
      this.subCatergoryList != '' ||
      this.subCatergoryList != null ||
      this.subCatergoryList != undefined
    ) {
      this.subCatergory = this.subCatergoryList;
      console.log(this.subCatergory);
    } else {
      this.subCatergory = '';
      console.log(JSON.stringify(this.subCatergory));
    }
  }
  subtopicsInput: string = '';
  subtopics: any = [];
  addSubtopics() {
    if (this.subtopicsInput.trim()) {
      const newSubtopics = this.subtopicsInput
        .split(',')
        .map((subtopic) => subtopic.trim());
      this.subtopics.reverse().push(...newSubtopics);
      this.subtopicsInput = ''; // Clear input field after adding subtopics
      console.log(JSON.stringify(this.subtopics));
    }
  }
  remveSubtopic(i: any) {
    this.subtopics.splice(i, 1);
  }
  topicNameValidation(e: any) {
    if (e.target.value == '') {
      this.errorValidate = '';
    }
    let obj = {
      topicName: e.target.value,
    };
    this.collectionServices.validateTopic(obj).subscribe(
      (result) => {},
      (err) => {
        console.log(err.error.message);
        this.errorValidate = err.error.message;
      }
    );
  }
  submit() {
    console.log(this.catergoriesFormGroup.value);
    if (this.catergoriesFormGroup.invalid) {
      this.submiited = true;
    } else {
      const formData = new FormData();
      formData.append(
        'topicImage',
        this.catergoriesFormGroup.controls['categoryImage'].value
      );
      formData.append(
        'topicName',
        this.catergoriesFormGroup.value.categoryName
      );
      formData.append('title', this.catergoriesFormGroup.value.title);
      formData.append('subTopics', JSON.stringify(this.subtopics));
      formData.append(
        'description',
        this.catergoriesFormGroup.value.description
      );
      formData.append(
        'redditMessage',
        this.catergoriesFormGroup.value.redditMessage
      );
      formData.append('redditURL', this.catergoriesFormGroup.value.redditURL);
      formData.append('ctaText', this.catergoriesFormGroup.value.ctaText);
      this.topicName = this.catergoriesFormGroup.value.categoryName;
      formData.append('isAdded', this.catergoriesFormGroup.value.isAdded);
      // debugger;
      this.collectionServices.addCategories(formData).subscribe(
        (result) => {
          this.dialog.open(SuccessDialogComponent, {
            data: {
              title: this.topicName,
              create: true,
              update: false,
              disableClose: true,
            },
          });
          this.catergoriesFormGroup.reset();
          this.subtopics = [];
          this.uploadLoader = false;
        },
        (error) => {
          this.error = error.error.message;
          setTimeout(() => {
            this.error = '';
          }, 5000);
        }
      );
    }
  }
}
