
<div class="main">
    <div class="">
          <div class="image-close cursor" (click)="close()">
      <img src="../../assets/images/cancel.png" class="close-img"/>
    </div>
    <div class="share-section">
        <h6>Don't read {{title}} AI blog alone</h6>
        <p class="mt-md-3">Invite your friends</p>

        <div class="d-flex mt-md-4 justify-content-center">
            <div class="shareButtons mr-4">
        <a [href]="FaceBookDynamicLInk" target="_blank" (click)="faceBook('Facebook')">
            <img src="../../assets/images/invite-1.png">
        </a>
     </div>
            <div class="shareButtons mr-4">
        <a [href]="TwitterDynamicLInk" target="_blank" (click)="faceBook('Twitter')" >
            <img src="../../assets/images/invite-2.png">
        </a>
     </div>
            <div class="shareButtons mr-4">
        <a [href]="whatsappDynamicLInk" target="_blank" (click)="faceBook('Whatsapp')" >
            <img src="../../assets/images/invite-3.png">
        </a>
     </div>
            <div class="shareButtons mr-4">
        <a [href]="diggDynamicLInk" target="_blank" (click)="faceBook('Digg')" >
            <img src="../../assets/images/invite-4.png">
        </a>
     </div>
        </div>

        <div class="d-md-flex justify-content-center mt-md-3">
            <div class="input-copy">
                <input [value]="shareLink" disabled>
            </div>
            <div class="copyLink">
                <button [textContent]="buttonText" [cdkCopyToClipboard]="shareLink" (click)="copied('copied',$event)" class="cursor">Copylink</button>
            </div>
        </div>
    </div>
    </div>
</div>