<nav class="navbar navbar-expand-lg navbar-light fixed-top nav">
  <div class="container">
    <a href="/">
      <div class="logo-class d-flex mr-md-5">
        <!-- <img
          src="../../assets/images/link-cart-logo.svg"
          class="header-logo mr-1 mt-1"
          alt="linkscartLogo"
        /> -->

        <p>Summarizly AI</p>
      </div>
    </a>
    <div class="logo-class d-flex ml-md-3 pointer">
      <img src="../../assets/images/feed.png" class="header-logo mr-2 mt-3" />

      <h2><a (click)="feed()">Feed</a></h2>
    </div>

    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarNavAltMarkup"
      aria-controls="navbarNavAltMarkup"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
      <div class="navbar-nav ml-auto">
        <!-- <div class="link-btn mt-md-1">
          <button class="linkProduct" (click)="share()">
            <img
              src="../../assets/images/upload-1.png"
              class="link-img mr-2"
              alt="shareLinkscartLogo"
            />

            Share Summarizly AI
          </button>
        </div> -->
         <div class="navbar-nav ml-auto mr-3">
        
        <!-- <div class="singin-c mt-md-1 mr-2">
   <button class="linkProduct" (click)="Singin()">

       Y Combinator AI Blog
        </button>
         </div>     -->
      </div>

        <div class="" *ngIf="token">
          <img
            src="../../assets/images/admin.png"
            class="admin-img ml-4 mr-2 mt-1"
          />

          <button
            mat-icon-button
            class="Administrator-btn"
            mat-button
            [matMenuTriggerFor]="menu"
            aria-label="Example icon-button with a menu"
          >
            <img
              src="../../assets/images/a-1.png"
              class="arrow-img mt-1 pointer"
            />

            <div
              #menuTrigger="matMenuTrigger"
              [matMenuTriggerFor]="menu"
              place
            ></div>
          </button>
          <mat-menu #menu="matMenu" xPosition="before" class="mx-3">

            <div class="">
              <div class="mx-4">
                <img src="../../assets/images/user-1.png" width="50px" class="user-img-u">
                <div class="d-flex b-r">


                  <div class="proflie">
                    <P>{{userName}}</P>
                <span class="mb-3">user</span>
                  </div>
                  <div class="ml-auto mt-3">
                    <img src="../../assets/images/next.png" width="40px">
                  </div>
                </div>
                
              </div>
          
            <!-- <button (click)="editProfile()" class="Deactivate" mat-menu-item value="Logout">
              <span>My profile</span>
            </button> -->
            <button
              class="Deactivate"
              mat-menu-item
              value="Logout"
             
            ><img src="../../assets/images/Icon material-featured-play-list.svg">
              <span>My Summary collections</span>
            </button>
            <button
              class="Deactivate"
              mat-menu-item
              value="Logout"
               (click)="bookmarkCollection()"
            >
            <img src="../../assets/images/bookmark.png">
              <span>Bookmarks</span>
            </button>
            <button
              class="Deactivate"
              mat-menu-item
              value="Logout"
               (click)="bookmarkPublishers()"
            >
            <img src="../../assets/images/Icon material-people-outline.svg">
              <span>Publishers</span>
            </button> 
              <button class="Deactivate" mat-menu-item value="Logout" (click)="bookmarkTopics()">
                <img src="../../assets/images/Icon awesome-th-list.svg">
              <span>Topics</span>
            </button>
            <button class="Deactivate" mat-menu-item value="Logout">
              <img src="../../assets/images/Icon ionic-md-person-add.svg">
              <span>Become a publisher </span>
            </button>
            <button
              class="Deactivate"
              mat-menu-item
              value="Logout"
               
            >
            <img src="../../assets/images/Icon feather-help-circle.svg">
              <span>help</span>
            </button> 
              <button class="Deactivate" mat-menu-item value="Logout">
                <img src="../../assets/images/Icon feather-settings.svg">
              <span>Settings</span>
            </button>
            <button class="Deactivate" mat-menu-item value="Logout">
              <img src="../../assets/images/Icon awesome-external-link-alt.svg">
              <span>Privacy Policy </span>
            </button>
            <button
              class="Deactivate"
              mat-menu-item
              value="Logout"
              (click)="logout()"
            >
              <img src="../../assets/images/log-out.png" class="mr-3 logout" />
              <span>Logout</span>
            </button>
              </div>
          </mat-menu>
        </div>
      </div>
    </div>
  </div>
</nav>
