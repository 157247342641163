import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CollectionService } from '../_services/collection.service';

@Component({
  selector: 'app-publish-collection',
  templateUrl: './publish-collection.component.html',
  styleUrls: ['./publish-collection.component.css'],
})
export class PublishCollectionComponent implements OnInit {
  sessionType: any;
  constructor(private router: Router, private service: CollectionService) {}

  ngOnInit(): void {
    this.service.loginUserDetails().subscribe((result) => {
      this.sessionType = result.data.userType;
    });
  }

  productRoute() {
    this.router.navigate(['/linkProduct']);
  }
  collectionRouter() {
    this.router.navigate(['/Collections']);
  }
  productRouteCreator() {
    this.router.navigate(['/publisher/post_type-product_link/new']);
  }
  bulkRouteCreator() {
    this.router.navigate(['/publish_type-bulk-products']);
  }
  bulkRoutePowerUser() {
    this.router.navigate(['/powerUser-bulk-products']);
  }
  collectionRouterCreator() {
    this.router.navigate(['/publisher/post_type-collection/new']);
  }
}
