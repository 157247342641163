import { Component, Inject, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  AbstractControl,
  FormControl,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { CollectionService } from 'src/app/_services/collection.service';

@Component({
  selector: 'app-add-sub-topics-dialog',
  templateUrl: './add-sub-topics-dialog.component.html',
  styleUrls: ['./add-sub-topics-dialog.component.css'],
})
export class AddSubTopicsDialogComponent implements OnInit {
  catergoryList: any;
  catergoriesFormGroup: FormGroup;
  categoryItem: any;
  profile: any;
  submiited: boolean = false;
  succeed: boolean = false;
  subCatergory: any = [];
  error: any;
  subCatergoryList: any;
  succesfully: boolean;
  categoryIds: any;
  filteredItemsArray: any = [];
  messeage: string;
  constructor(
    private service: CollectionService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: { id: string[] }
  ) {
    this.catergoriesFormGroup = this.fb.group({
      categoryName: [''],
      isAdded: [''],
      description: [''],
      title: [''],
      categoryImage: [''],
      subCategories: [''],
    });
  }
  ngOnInit(): void {
    this.filteredItemsArray = [];

    this.categoryIds = this.data.id;
    console.log(this.categoryIds);

    this.service.allCatergory().subscribe((res: any) => {
      console.log(res);
      var feed = res.data;
      this.catergoryList = res.data;
      // this.categoryIds.forEach((id: any) => {
      //   const matchingItem = this.catergoryList.find(
      //     (item: any) => item._id === id
      //   );
      //   if (matchingItem) {
      //     this.filteredItemsArray.push(matchingItem);
      //     console.log(this.filteredItemsArray);
      //   }
      // });
      this.catergoryList.forEach((item: any) => {
        if (this.categoryIds.includes(item._id)) {
          this.filteredItemsArray.push(item);
          console.log(this.filteredItemsArray);
        }
      });

      // const categoryItem1 = this.catergoryList.filter((item: any) => {
      //   return item._id == this.categoryIds[0];
      // });
      // const categoryItem2 = this.catergoryList.filter((item: any) => {
      //   return item._id == this.categoryIds[1];
      // });
      // const combinedArray = categoryItem2.concat(categoryItem1);
      // const filteredItems = this.catergoryList.filter((item: any) =>
      //   this.categoryIds.includes(item._id)
      // );
      // this.filteredItemsArray.push(...filteredItems);
      // console.log(this.filteredItemsArray);
    });
  }

  get c(): { [key: string]: AbstractControl } {
    return this.catergoriesFormGroup.controls;
  }
  inputControl = new FormControl('');

  onInputChange(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const inputValue = inputElement.value;

    // Replace spaces with spaces and commas
    const formattedValue = inputValue.replace(/ /g, ', ');

    // Update the input value
    this.inputControl.setValue(formattedValue, { emitEvent: false });
  }
  subCategory(e: any) {
    this.subCatergoryList = e.target.value;
    console.log(e.target.value);

    if (
      this.subCatergoryList != '' ||
      this.subCatergoryList != null ||
      this.subCatergoryList != undefined
    ) {
      this.subCatergory = this.subCatergoryList.split(',');
      console.log(this.subCatergory);
      this.messeage = '';
    } else {
      this.subCatergory = '';
      console.log(JSON.stringify(this.subCatergory));
    }
  }
  close() {
    this.dialog.closeAll();
    this.filteredItemsArray = [];
    this.service.triggerRefresh();
  }
  update(item: any) {
    console.log('name id' + item.name + item._id);

    if (this.subCatergory != '') {
      this.messeage = '';

      const formData = new FormData();
      formData.append('subTopics', JSON.stringify(this.subCatergory));
      formData.append('isAdded', 'true');

      this.service.editCategroy(formData, item._id).subscribe((res) => {
        console.log(res);
        this.succeed = true;
        setTimeout(() => {
          this.succeed = false;
        }, 3000);
      });
    } else {
      this.messeage = 'add subtopics';
    }
  }
}
