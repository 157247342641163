import { Component, Inject, OnInit } from '@angular/core';

import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CollectionService } from 'src/app/_services/collection.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-successdialoghome',
  templateUrl: './successdialoghome.component.html',
  styleUrls: ['./successdialoghome.component.css'],
})
export class SuccessdialoghomeComponent implements OnInit {
  url: any;

  title: any;
  collectionImage: any;
  link: any;
  topic: boolean;
  publisher: boolean;
  bookmark: boolean;
  params: { publisherName: any; publisherId: any };
  view: any;
  report: any;
  dwnvote: any;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dilaog: MatDialog,
    private collectionService: CollectionService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    // this.data = data;
    console.log(this.data.topic);
    this.title = this.data.title;
    this.view = this.data.view;
    this.topic = this.data.topic;
    this.publisher = this.data.publisher;
    this.bookmark = this.data.bookmark;
    this.link = this.data.link;
    this.dwnvote = this.data.downvote;
    this.report = this.data.report;
  }
  hide() {
    this.dilaog.closeAll();
  }
  bookmarkPublishers() {
    this.router.navigate(['user/publishers']);
  }
  followBtn() {
    let obj = {
      topicId: this.view._id,
    };
    // console.log(obj);
    this.collectionService.caterogyFollow(obj).subscribe((res) => {
      // console.log(obj);
      this.view.topicsFollow = true;
      this.collectionService.refresh();
      this.hide();
    });
  }
  followBtnPublisher() {
    this.params = {
      publisherName: this.view.publisherName,
      publisherId: this.view.publisherId,
    };
    this.collectionService
      .followCreatorChannel(this.params)
      .subscribe((res) => {
        this.collectionService.refresh();
        this.hide();
        this.view.followPublisher = true;
      });
  }
}
