<div class="main">
  <!-- <app-navbar></app-navbar> -->
  <app-homenavbar></app-homenavbar>

    <div class="container-fluid">
        
        <div class="login-section ">
          <div class="row">
              <div class="col-md-6 ">
                <div class="login-section-common-data pl-md-5 pt-md-5">
                     <div class="common-data-login">
                         <h5>One place where you </h5>
                         <h4>discover, inspire products curated, promoted by top YouTube creators In</h4>
                         <h3>In <span class="fashion">Fashion,</span> <span class="beauty"> beauty,</span> <span class="personal-care">personal care </span> </h3>

                         <h2 >Find, save all creator discount codes shared by YouTube creators in 1000s of videos in one place</h2>
                     </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="login-form-section" >

                    <div class="email-section" *ngIf = "signUpWithEmail">
                    <div class="form-section">

      <h4 class="ml-md-5  mt-md-4 pb-md-5">Sign up for free</h4>
                       <div class="d-flex points mt-3">
                        <img src="../../assets/images/check-1.png" class="check-1">
                        <p class="ml-2">View all discount codes for all products</p>
                    </div>
                    <div class="d-flex points mt-2">
                        <img src="../../assets/images/check-1.png" class="check-1">
                        <p class="ml-2">Save discount codes for any product and view later</p>
                    </div>
                    <div class="d-flex points mt-2">
                        <img src="../../assets/images/check-1.png" class="check-1">
                        <p class="ml-2">Follow favourite products and publish products you like</p>
                    </div>
                   
                      <div class="d-flex mt-md-4">
                         <form [formGroup]="signUpEmail">

                  
                            <div class="email-form-section ml-md-4 pl-md-2">
                           <input class="form-control" formControlName="email" placeholder="Enter email address">
                             <div
                    *ngIf="signUpEmailError.email.errors?.required && submitted"
                    class="invalid-feedback m-t d-block"
                  >
                    Email is required
                  </div>
                            <div
                    *ngIf="signUpEmailError.email.errors?.pattern && submitted"
                    class="invalid-feedback m-t d-block"
                  >
                    Email is invalid
                  </div>
                         </div>
                           </form>
                         <div class="btn-signin ">
                              <button class="pointer" (click)="signUp()"  type="submit">Sign up for free</button>
                           </div>
                           
                        </div>
                        
                         
                    </div>
                   

                    <div class='google-intergration ml-md-4 pl-md-2'>
                         <p class="py-md-2">OR</p>
                          <div class=" google-btn">
   <button type="submit">
    
     <a [href]="url | safePipe">

    <img src="../../assets/images/google.png" class="google mr-md-3">Sign up via Google
  </a>
  </button>
   <h6>Don’t have an account? <span class="pointer" ><a (click)="loginNow()">Login</a></span></h6>
</div>

                    </div>
                    </div>
                    <div class="name-section ml-md-4" *ngIf = "signUpWithName">
                    
                    <div class="form-section ">
                         <h4>Sign up to your account</h4>
                          <form [formGroup]="SignUpUser">
  <div class="email-form-section mt-md-5">
                           <input class="form-control" formControlName="name" placeholder="Enter your name">
                           <div
                    *ngIf="signUpNameError.name.errors?.required && submittedUser"
                    class="invalid-feedback m-t d-block"
                  >
                    Name is required
                  </div>
                         </div>
                         <div class="password-form-section mt-md-4 pt-md-1">
                           <input type="password" class="form-control" formControlName="password" placeholder="Enter your password">
                           <div
                    *ngIf="signUpNameError.password.errors?.required && submittedUser"
                    class="invalid-feedback m-t d-block"
                  >
                  Password is required
                  </div>
                         </div>
                          </form>
                           <p *ngIf="error != null"  class="invalid-feedback m-t d-block">{{error}}</p>

                       
                    </div>
                    <div class="sign-in-section mt-md-4 pt-md-2">
                        <div class="d-flex">
                           <div class="btn-signin">
                              <button class="pointer" (click)="login()"  type="submit">SIGN UP</button>
                           </div>
                            
                        </div>
                    </div>

                    <div class='google-intergration'>
                         <p class="py-md-4">OR</p>
                           <div class=" google-btn">
   <button type="submit">
    
    <a [href]="url | safePipe">
    <img src="../../assets/images/google.png" class="google mr-md-3">Sign up via Google
  </a>
  </button>
</div>
  <h6>Don’t have an account? <span class="pointer" ><a (click)="loginNow()">Login</a></span></h6>

                    </div>
                </div>
                </div>
               
              </div>
          </div>
        </div>
    </div>
</div>
