import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-success-dialog',
  templateUrl: './success-dialog.component.html',
  styleUrls: ['./success-dialog.component.css'],
})
export class SuccessDialogComponent implements OnInit {
  title: any;
  Url: any = `${environment.URL}`;
  route: string;
  create: any;
  update: any;
  constructor(
    private dialog: MatDialog,
    private router: Router,
    private dilaog: MatDialog,

    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    console.log(this.data.title);
    this.title = this.data.title;
    this.create = this.data.create;
    this.update = this.data.update;
    this.route = `${this.Url}/topic/${this.title}`;
    console.log(this.route);
  }
  close() {
    this.dialog.closeAll();
  }
  catergoryNavigate() {
    // console.log('clickded');
    // this.router.navigate([`topic/${this.title}`]);
    // console.log('prod');
    this.dialog.closeAll();
  }
  navigate(text: any) {
    this.router.navigate([`${text}`]);
    this.dialog.closeAll();
  }
}
