import { Component, Inject, OnInit } from '@angular/core';

import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dynamic-share-button-dialog',
  templateUrl: './dynamic-share-button-dialog.component.html',
  styleUrls: ['./dynamic-share-button-dialog.component.css'],
})
export class DynamicShareButtonDialogComponent implements OnInit {
  // link: any = `${environment.SHARE_URL}`;
  title: any;
  FaceDynamicLInk: any;
  collectionName: any;
  FaceBookDynamicLInk: any;
  TwitterDynamicLInk: any;
  PinterestDynamicLInk: any;
  RedditDynamicLInk: any;
  copyLinkDynamicLInk: string;
  linkInDynamicLInk: string;
  diggDynamicLInk: string;
  gmailDynamicLInk: string;
  whatsappDynamicLInk: string;
  constructor(
    private router: Router,
    private dilaog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    // this.data = data;
    console.log(this.data.title);
    this.title = this.data.title;
    this.collectionName = this.data.collectionName;
    this.FaceBookDynamicLInk = `https://www.shareaholic.com/api/share/?v=1&apitype=1&apikey=2ceb7e6973521a10a3e4b25ad56072e7
    &service=facebook&title=${this.collectionName}&link=${this.title}/&source=Shareaholic`;
    this.TwitterDynamicLInk = `https://www.shareaholic.com/api/share/?v=1&apitype=1&apikey=2ceb7e6973521a10a3e4b25ad56072e7
    &service=twitter&title=${this.collectionName}&link=${this.title}/&source=Shareaholic`;
    this.linkInDynamicLInk = `https://www.shareaholic.com/api/share/?v=1&apitype=1&apikey=2ceb7e6973521a10a3e4b25ad56072e7
    &service=linkedin&title=${this.collectionName}&link=${this.title}/&source=Shareaholic`;
    this.diggDynamicLInk = `https://www.shareaholic.com/api/share/?v=1&apitype=1&apikey=2ceb7e6973521a10a3e4b25ad56072e7
    &service=digg&title=${this.collectionName}&link=${this.title}/&source=Shareaholic`;
    this.gmailDynamicLInk = `https://www.shareaholic.com/api/share/?v=1&apitype=1&apikey=2ceb7e6973521a10a3e4b25ad56072e7
    &service=google_mail&title=${this.collectionName}&link=${this.title}/&source=Shareaholic`;
    this.PinterestDynamicLInk = `https://www.shareaholic.com/api/share/?v=1&apitype=1&apikey=2ceb7e6973521a10a3e4b25ad56072e7
    &service=pinterest&title=${this.collectionName}&link=${this.title}/&source=Shareaholic`;
    this.RedditDynamicLInk = `https://www.shareaholic.com/api/share/?v=1&apitype=1&apikey=2ceb7e6973521a10a3e4b25ad56072e7
    &service=reddit&title=${this.collectionName}&link=${this.title}/&source=Shareaholic`;
    this.copyLinkDynamicLInk = `https://www.shareaholic.com/api/share/?v=1&apitype=1&apikey=2ceb7e6973521a10a3e4b25ad56072e7&service=copy_link&title=${this.collectionName}&link=${this.title}/&source=Shareaholic`;
    this.whatsappDynamicLInk = `https://www.shareaholic.com/api/share/?v=1&apitype=1&apikey=2ceb7e6973521a10a3e4b25ad56072e7&service=whatsapp&short_link=${this.title}&link=${this.title}/&source=Shareaholic`;

    console.log('links', this.FaceBookDynamicLInk);
    console.log('links', this.collectionName);
  }

  close() {
    this.dilaog.closeAll();
  }
}
