import {
  isPlatformBrowser,
  isPlatformServer,
  PlatformLocation,
} from '@angular/common';
import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import * as clevertap from 'clevertap-web-sdk';
import { CollectionService } from './_services/collection.service';
import { productFruits } from 'product-fruits';
declare global {
  interface Window {
    productFruits: any;
  }
}
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  userName: any;
  userType: string;
  id: any;
  display: boolean = true;
  constructor(
    private collectionServices: CollectionService,

    private PlatformLocation: PlatformLocation,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  title = 'linkscartV1';
  showLogin: boolean = true;
  admin: boolean = true;
  user: boolean = false;
  ngDoCheck(): void {
    if (isPlatformBrowser(PLATFORM_ID)) {
      if (localStorage.getItem('token') == null) {
        this.showLogin = true;
      } else {
        this.showLogin = false;
      }
    }
  }
  ngOnInit(): void {
    this.loadPf();
    const isServerRendered = this.PlatformLocation.pathname.startsWith('/c/');
    // console.log('====================================');
    // console.log(this.PlatformLocation.pathname);
    // console.log('====================================');
    // console.log(`Is server-rendered: ${isServerRendered}`);
    // if (isPlatformBrowser(this._platformId)) {
    //   console.log('Platform', this._platformId);
    // }
    // if (isPlatformServer(PLATFORM_ID)) {
    // }
    // console.log('Platform', this.platformId);

    // if (isPlatformBrowser(this._platformId)) {
    //   console.log('Platform', isPlatformBrowser(PLATFORM_ID));
    //   if (localStorage.getItem('token') != null) {
    //     this.collectionServices.loginUserDetails().subscribe((result) => {
    //       this.userName = result.data.name;
    //       this.userType = result.data.userType;
    //       this.id = result.data._id;
    //       localStorage.setItem('userName', this.userName);
    //       localStorage.setItem('userType', this.userType);
    //       localStorage.setItem('ID', this.id);
    //     });
    //   }
    // }
  }

  loadPf = () => {
    // productFruits.init(
    //   'EP087M9LGfRy7LuJ',
    //   'en',
    //   {
    //     username: 'Mahesh',
    //   },
    //   {
    //     disableLocationChangeDetection: true,
    //   }
    // );
    // productFruits.safeExec(($pf) => {
    //   console.log($pf);
    // });
  };
}
type PfWindow = {
  productFruits: any;
};
