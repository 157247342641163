import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { CollectionService } from '../_services/collection.service';

@Component({
  selector: 'app-creatorchannelcreate',
  templateUrl: './creatorchannelcreate.component.html',
  styleUrls: ['./creatorchannelcreate.component.css'],
})
export class CreatorchannelcreateComponent implements OnInit {
  catergoryList: any = [];
  catergory: string;
  submiited: boolean = false;
  succeed: boolean = false;
  catergoriesFormGroup: FormGroup;
  subCatergory: any;
  msg: string;
  url: any;
  selectedFile: File;
  updateProfileForm: any;
  selectedFileEvent: File;
  fileNameEvent: any;
  categoryList: any;
  Cateogory = new FormControl('');
  multipleCatergory: any;
  country: any = ['India', 'US', 'Canada', 'London', 'New york', 'Australia'];
  countryEvent: any;
  fileName: any;
  uploadFlie: boolean = true;
  attachmentFile: boolean = false;
  uploadBtn: boolean = false;
  csvsucceed: boolean = false;
  userID: any;
  error: any;

  constructor(
    private collectionServices: CollectionService,
    private fb: FormBuilder,
    private router: Router
  ) {
    this.catergoriesFormGroup = this.fb.group({
      name: ['', [Validators.required]],
      url: ['', [Validators.required]],
      ownerName: ['', [Validators.required]],
      category: ['', [Validators.required]],
      followersCount: ['', [Validators.required]],
      description: ['', [Validators.required]],
      profile: ['', [Validators.required]],
      country: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.collectionServices.caterogoryList().subscribe((res) => {
      console.log(res);
      this.categoryList = res.data;
    });
  }
  categoryEvent(e: any) {
    console.log(e.value);
    this.multipleCatergory = e.value;
  }
  addCategory(e: any) {
    this.catergoryList = e.target.value;

    if (
      this.catergoryList != '' ||
      this.catergoryList != null ||
      this.catergoryList != undefined
    ) {
      this.subCatergory = this.catergoryList.split(',');
    } else {
      this.subCatergory = '';
    }
    console.log(this.catergory);
  }
  get c(): { [key: string]: AbstractControl } {
    return this.catergoriesFormGroup.controls;
  }
  onFileSelected(event: any): void {
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);

    reader.onload = (_event) => {
      this.msg = '';
      this.url = reader.result as string;

      this.selectedFile = <File>event.target.files[0];
    };
    this.selectedFileEvent = <File>event.target.files[0];
    this.fileNameEvent = event.target.files[0].name;
  }
  Country(event: any) {
    console.log(event.value);
    this.countryEvent = event.value;
  }

  onFileSelectedTvProgram(event: any): void {
    this.selectedFile = <File>event.target.files[0];
    this.fileName = event.target.files[0].name;
    if (event.target.files[0].name != null) {
      this.uploadFlie = false;
      this.attachmentFile = true;
      this.uploadBtn = true;
    }
  }
  uploadCsv() {
    const formData = new FormData();
    formData.append('file', this.selectedFile, this.fileName);
    this.collectionServices.uploadCsv(formData).subscribe(
      (data) => {
        console.log(data);
        this.csvsucceed = true;
        this.uploadFlie = true;
        this.attachmentFile = false;
        this.uploadBtn = false;
        setTimeout(() => {
          this.csvsucceed = false;
        }, 3000);
      },
      (error) => {
        console.log(error);
      }
    );
  }
  submit() {
    console.log(this.catergoriesFormGroup);
    if (this.catergoriesFormGroup.invalid) {
      this.submiited = true;
    } else {
      this.submiited = false;
      const formData = new FormData();
      formData.append('profile', this.selectedFileEvent, this.fileNameEvent);
      formData.append('category', JSON.stringify(this.multipleCatergory));
      formData.append('ownerName', this.catergoriesFormGroup.value.ownerName);
      formData.append('url', this.catergoriesFormGroup.value.url);
      formData.append('name', this.catergoriesFormGroup.value.name);
      formData.append(
        'followersCount',
        this.catergoriesFormGroup.value.followersCount
      );
      formData.append(
        'description',
        this.catergoriesFormGroup.value.description
      );
      formData.append('country', this.catergoriesFormGroup.value.country);
      this.collectionServices.addChannelName(formData).subscribe(
        (result) => {
          console.log(result);
          console.log('success');
          this.succeed = true;
          this.router.navigate(['creator/createChannel']);

          setTimeout(() => {
            this.succeed = false;
          }, 3000);
          this.url = null;
          this.catergoriesFormGroup.reset();
        },
        (error) => {
          this.error = error.error.message;
        }
      );
    }
  }
}
