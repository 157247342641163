import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NoAuthSignupComponent } from 'src/app/no-auth-signup/no-auth-signup.component';
import { CollectionService } from 'src/app/_services/collection.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-viewbulkproducts',
  templateUrl: './viewbulkproducts.component.html',
  styleUrls: ['./viewbulkproducts.component.css'],
})
export class ViewbulkproductsComponent implements OnInit {
  collectionCreatedByCreator: any;
  productTitle: any;
  productId: any;
  productError: any;
  userType: any;
  activeRouteParams: any;
  params: { bulkProductId: any };
  id: any;
  url: any;
  sessionType: any;
  title: any;
  channelName: any;

  constructor(
    private service: CollectionService,
    private router: Router,
    private dialog: MatDialog,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    if (this.service.getToken() == '') {
      console.log('not-login');
      this.sessionType = 'home';
    }
    if (this.service.getToken() != '') {
      this.service.loginUserDetails().subscribe((result) => {
        this.sessionType = result.data.userType;
        console.log('homepage', this.sessionType);
      });
    }
    this.activeRouteParams = this.route.snapshot.params.id;
    this.channelName = this.route.snapshot.params.name;
    console.log(this.route.snapshot.params);

    this.id = this.activeRouteParams.split('-').pop().replace('', '');
    //  this._id = this.activeRouteParams?.split('-').pop();
    console.log(this.id);
    this.url = `${environment.URL}/${this.route.snapshot.params.name}/${this.route.snapshot.params.title}/id-${this.id}`;
    console.log(this.url);
    this.params = {
      bulkProductId: this.id,
    };
    this.service.creatorBulkProductsView(this.params).subscribe((res) => {
      this.collectionCreatedByCreator = res.data[0].products;
      this.title = res.data[0].title;
      console.log(this.collectionCreatedByCreator);
    });
  }

  productDetails(title: any, id: any) {
    this.productTitle = title;
    this.productId = id;
    this.router.navigate([
      `/p/${this.productTitle.split(' ').join('-')}/id-${this.productId}`,
    ]);
  }
  // saveForlater(id: any) {
  //   console.log('p', id);
  //   if (this.service.getToken() == '') {
  //     this.dialog.open(NoAuthSignupComponent, {
  //       data: {
  //         title: 'Bookmark this product',
  //         disableClose: true,
  //       },
  //     });
  //   } else {
  //     console.log('saveProductBookmark', id);
  //     let obj = {
  //       productId: id,
  //     };
  //     this.service.userProductBookmark(obj).subscribe(
  //       (result) => {
  //         this.saved = true;
  //         setTimeout(() => {
  //           this.saved = false;
  //         }, 3000);
  //       },
  //       (error) => {
  //         this.productError = error.error.message;
  //         setTimeout(() => {
  //           this.productError = '';
  //         }, 3000);
  //       }
  //     );
  //   }
  // }
}
