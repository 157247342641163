<app-navbar></app-navbar>
<div class="container">
  <!-- <h4 class="succesfully mt-md-3" *ngIf="succeed">Topic created successfully</h4> -->
<p *ngIf="errorView == true" class="error_filesize mt-md-4">{{errorMesseage}}</p>
<!-- 
  <form [formGroup]="catergoriesFormGroup">
    <div class="upload pt-4 pl-4 mt-5">
      <img *ngIf="url != null " [src]="url" width=100 height=100 alt="" class="upload-img">
      <img *ngIf="url == null " src="../../assets/images/place-holder.jpg" width=100 height=100 alt="" class="upload-img">
      <div class="round">
        <input type="file" accept="image/*" (change)="onFileSelectedEvent($event)">
        <img src="../../assets/images/camera.svg" width=100 height=100 alt="" class="camera-img">
      </div>
    </div>
    <div *ngIf="c.categoryImage.errors?.required && submiited" class="invalid-feedback ml-md-2 pt-md-5 d-block">Category image is required </div>
    <div class="row">
       
        <div class="col-md-6">
        <div class="catergories-add-section mt-md-2">
          <label>Description</label>
          <input formControlName="description" type="text" placeholder="Enter the Description" class="form-control">
         
        </div>
    
    
      </div>
      
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="catergories-add-section mt-md-2">
          <label>Add topics</label>
          <input formControlName="categoryName" placeholder="Add category" type="text" class="form-control">
          <div *ngIf="c.categoryName.errors?.required && submiited" class="invalid-feedback m-t d-block">Categoryname is required </div>
        </div>
        <div class="checkbox">
          <div class="form-check form-check-inline">
            <input class="form-check-input" formControlName="isAdded" type="radio" name="isAdded" id="inlineRadio1" value="true">
            <label class="form-check-label" for="inlineRadio1">Active</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" formControlName="isAdded" type="radio" name="isAdded" id="inlineRadio2" value="false">
            <label class="form-check-label" for="inlineRadio2">Inactive</label>
          </div>
        </div>
        <div class="submit-btn">
          <button type="button" class="mt-md-3 pointer" (click)="submit()">Submit</button>
        </div>
      </div>
       <div class="col-md-6">
        <div class="catergories-add-section mt-md-2">
          <label>Add Sub topics</label>
          <input (keypress)="subCategory($event)" formControlName="subCategories" placeholder="Add subtopics" type="text" class="form-control">
          <div *ngIf="this.error != null" class="invalid-feedback m-t d-block">{{this.error }}</div>
        </div>
        </div>
    </div>
  </form> -->
</div>


  <div class="container mt-5">
  <div class="topic_content">
     <h3>Create a new topic</h3>
     <p>Which topic are you most interested in publishing summaries ?</p>

     <form [formGroup]="catergoriesFormGroup">

      <div class="row">
        <div class="col-md-6">
  <div class="catergories-add-section mt-md-2">
          <label>Enter the name of the topic</label>
          <input formControlName="categoryName" (input)="topicNameValidation($event)" type="text" class="form-control">
          <p style="color: #d0d0d0;font-weight: 400;">Example:Beauty</p>
          <div *ngIf="c.categoryName.errors?.required && submiited" class="invalid-feedback m-t d-block">topicName is required </div>
          <div *ngIf="errorValidate != ''" class="invalid-feedback m-t d-block">{{this.catergoriesFormGroup.value.categoryName}} topic name already created. Please create a different topic. </div>
        </div>
         <div class="catergories-add-section mt-md-2">
          <label>Add Sub topics for <span *ngIf="this.catergoriesFormGroup.value.categoryName != ''">{{this.catergoriesFormGroup.value.categoryName}}</span></label>
          <p>
You can add multiple subtopics after each topic by pressing the Enter key on your keyboard.</p>
          <div class="d-flex">
            <div>

          <input (keydown.enter)="addSubtopics()" (input)="subCategory($event)"  [ngModelOptions]="{standalone: true}" [(ngModel)]="subtopicsInput" placeholder="Add subtopics" type="text" class="form-control">
         
        
            </div>
 
           
          </div>
          <div class="sub-topic_view ml-md-3" *ngIf="subtopics != ''">
               <ul class="d-flex"> 
  <li *ngFor="let subtopic of subtopics;index as i">
    <div class="d-flex">
{{ subtopic }}
<img src="../../assets/images/close.png" class="ml-3" (click)="remveSubtopic(i)">
    </div></li>
</ul>
            </div>
        </div>
<div class="pt-5 pb-3">
      <div class="d-flex">
<div class=" upload">
<div class=" round">
        <input type="file" accept="image/*" (change)="onFileSelectedEvent($event)">
        <img src="../../assets/images/up0.svg" width=100 height=100 alt="" class="camera-img">
      </div>
      </div>
        <div>
          <p class="upload-p">Upload a profile picture for topic</p>
<mat-progress-bar mode="determinate" *ngIf="uploadLoader" [value]="uploadProgress"></mat-progress-bar>
        </div>
        <div>
   <img *ngIf="url != null " [src]="url" width=100 height=100 alt="" class="upload-img">
      <!-- <img *ngIf="url == null " src="../../assets/images/place-holder.jpg" width=100 height=100 alt="" class="upload-img"> -->
        </div>
      </div>
      
   
    </div>


         <div class="checkbox">
          <div class="form-check form-check-inline">
            <input class="form-check-input" formControlName="isAdded" type="radio" name="isAdded" id="inlineRadio1" value="true">
            <label class="form-check-label" for="inlineRadio1">Active</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" formControlName="isAdded" type="radio" name="isAdded" id="inlineRadio2" value="false">
            <label class="form-check-label" for="inlineRadio2">Inactive</label>
          </div>
        </div>
        <p *ngIf="errorView == true" class="error_filesize mt-md-4">{{errorMesseage}}</p>
          <div *ngIf="this.error != null" class="invalid-feedback m-t d-block">{{this.error }}</div>
        </div>
        <div class="col-md-6">
            <div class="catergories-add-section mt-md-2">
          <label>RedditURL</label>
          <input formControlName="redditURL"  type="text" class="form-control">
          <!-- <p style="color: #d0d0d0;font-weight: 400;">Example:Beauty</p> -->
          <div *ngIf="c.redditURL.errors?.required && submiited" class="invalid-feedback m-t d-block">topicName is required </div>
          <div *ngIf="errorValidate != ''" class="invalid-feedback m-t d-block">{{this.catergoriesFormGroup.value.categoryName}} topic name already created. Please create a different topic. </div>
        </div>
        <div class="catergories-add-section mt-md-5 pt-2">
          <label>RedditMessage</label>
          <input formControlName="redditMessage"  type="text" class="form-control">
          <!-- <p style="color: #d0d0d0;font-weight: 400;">Example:Beauty</p> -->
          <div *ngIf="c.redditMessage.errors?.required && submiited" class="invalid-feedback m-t d-block">topicName is required </div>
          <div *ngIf="errorValidate != ''" class="invalid-feedback m-t d-block">{{this.catergoriesFormGroup.value.categoryName}} topic name already created. Please create a different topic. </div>
        </div>
<div class="catergories-add-section mt-md-2">
          <label>CtaText</label>
          <input formControlName="ctaText"  type="text" class="form-control">
          <!-- <p style="color: #d0d0d0;font-weight: 400;">Example:Beauty</p> -->
          <div *ngIf="c.ctaText.errors?.required && submiited" class="invalid-feedback m-t d-block">topicName is required </div>
          <div *ngIf="errorValidate != ''" class="invalid-feedback m-t d-block">{{this.catergoriesFormGroup.value.categoryName}} topic name already created. Please create a different topic. </div>
        </div>


        
        </div>
      </div>
    
         <div class="submit-btn">
          <button type="button" class="mt-md-3 pointer" (click)="submit()">Create Topic</button>
        </div>
    </form>
  </div>
</div> 