import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DynamicShareButtonDialogComponent } from 'src/app/dynamic-share-button-dialog/dynamic-share-button-dialog.component';
import { NoAuthSignupComponent } from 'src/app/no-auth-signup/no-auth-signup.component';
import { CollectionService } from 'src/app/_services/collection.service';
import { environment } from 'src/environments/environment';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css'],
})
export class UserProfileComponent implements OnInit {
  sessionType: string;
  categoryList: any = ['All', 'Beauty', 'Skincare', 'Startups'];
  params: any;
  pageSize: any = 10;
  currentPage: any = 1;
  userDetails: any;
  categoriesData: any;
  categorName: any;
  loader: boolean = false;
  collectionList: any;
  activeState: any = 'All';
  categoryRecords: any;
  collectionName: any = '';
  userName: any;
  id: any;
  userNameRoute: any;
  catetoryLimit: number;
  followChannel: any;
  followCategroy: any;
  active: boolean = true;
  userId: any = '';
  catergoryFollowedByUser: any = [];
  vistorsId: any;
  profileRoute: string;
  commentForm!: FormGroup;
  editCommentForm!: FormGroup;
  replyCommentForm!: FormGroup;
  replyEditCommentForm!: FormGroup;
  showButtons = false;
  inputActive: boolean = false;
  commentBtnEvent: boolean = true;
  commentBtnEventReply: boolean = true;
  readonly: boolean = true;
  replyCommentShow: boolean = false;
  showButtonsReply: boolean = false;
  inputValue: any = '';
  commentUser: any;
  commentsValue: any;
  errorLength: boolean = false;
  comment: any;
  ReplycommentUser: any = [];
  Replycomment: any;
  replyShow: boolean = false;
  commentId: any;
  showReplys: boolean = false;
  totalComments: any;
  showEditForm: boolean = false;
  showEditButtons: boolean = false;
  editActive: boolean = false;
  showReplyEditButtons: boolean = false;
  collectionUserId: any;
  showCommentLoader: boolean;
  CollectionID: any;
  userTypeId: any;
  summaryId: any;
  replyInputActive: boolean;
  constructor(
    private collectionService: CollectionService,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder
  ) {
    this.commentForm = this.fb.group({
      title: ['', [Validators.required, Validators.maxLength(1000)]],
    });
    this.replyCommentForm = this.fb.group({
      reply: ['', Validators.required],
    });
    this.editCommentForm = this.fb.group({
      edit: ['', Validators.required],
    });
    this.replyEditCommentForm = this.fb.group({
      replyEdit: ['', Validators.required],
    });
  }
  showCard = false;
  ngOnInit(): void {
    if (this.collectionService.getToken() == '') {
      console.log('not-login');
      this.sessionType = 'home';
      this.route.queryParams.subscribe((params) => {
        const paramValue = params['id'];
        this.userNameRoute = paramValue;
        console.log('Query parameter value:', this.userNameRoute);
      });
      this.userProfileDetaails();
    }
    if (this.collectionService.getToken() != '') {
      this.collectionService.loginUserDetails().subscribe((result) => {
        this.sessionType = result.data.userType;
        this.userId = result.data._id;
        this.userTypeId = result.data._id;
        this.route.queryParams.subscribe((params) => {
          const paramValue = params['id'];
          this.userNameRoute = paramValue ? paramValue : this.userId;
          console.log('Query parameter value:', this.userNameRoute);
        });
        this.userProfileDetaails();
      });
    }
  }
  navigateProfilePage(item: any) {
    // this.router.navigate([
    //   `user/${item.userId.name.split(' ').join('-')}?id=${item.userId._id}`,
    // ]);
    this.profileRoute = `user/${item.userId.name.split(' ').join('-')}?id=${
      item.userId._id
    }`;
  }
  userProfileDetaails() {
    this.params = {
      _id: this.userNameRoute,
      visitorId: this.userId,
      categorieName: this.collectionName,
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage,
    };
    this.loader = true;
    this.collectionService
      .userProfileDetails(this.params)
      .subscribe((result) => {
        console.log('user', result);
        this.loader = false;

        this.userDetails = result.data;
        this.collectionList = result.summaryData;
        this.userName = result.data[0].name;
        this.categoriesData = result.data[0].topicData.sort((a: any, b: any) =>
          a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
        );
        console.log(this.categoriesData);

        this.followChannel = result.data[0].followpublisher;
        this.categoryRecords = result.totalTopicRecord;
      });
  }
  navClick(item: any): void {
    console.log('navClick', item);
    if (item.name == 'All') {
      this.collectionName = '';
      this.activeState = 'All';

      console.log('click');
      this.params = {
        _id: this.userNameRoute,
        visitorId: this.userId,
        topicName: this.collectionName,
        pageLimit: this.pageSize ? this.pageSize : '',
        pageNumber: this.currentPage,
      };
      this.collectionService
        .userProfileDetails(this.params)
        .subscribe((response) => {
          this.collectionList = response.summaryData;
          this.categoryRecords = response.totalCategoryRecord;
        });
    } else {
      this.active = false;
      this.loader = true;
      this.currentPage = 1;
      this.activeState = item.name;
      this.collectionName = item.name;
      this.params = {
        _id: this.userNameRoute,
        visitorId: this.userId,
        topicName: this.collectionName,
        pageLimit: this.pageSize ? this.pageSize : '',
        pageNumber: this.currentPage,
      };
      this.collectionService
        .userProfileDetails(this.params)
        .subscribe((response) => {
          this.loader = false;

          this.collectionList = response.summaryData;
          this.categoryRecords = response.totalCategoryRecord;

          console.log('click', response.totalDocument);
        });
    }
  }
  click() {
    this.collectionName = '';
    this.activeState = 'All';

    console.log('click');
    this.params = {
      _id: this.userNameRoute,
      visitorId: this.userId,
      topicName: this.collectionName,
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage,
    };
    this.collectionService
      .userProfileDetails(this.params)
      .subscribe((response) => {
        this.active = true;
        this.collectionList = response.summaryData;
        this.categoryRecords = response.totalCategoryRecord;
      });
  }
  onScrollDown(ev: any) {
    // this.currentLimit = this.noOfRecords / 10;
    this.catetoryLimit = this.categoryRecords / 10;
    if (Math.round(this.catetoryLimit) > this.currentPage) {
      this.appendItems();
    }
  }
  appendItems() {
    console.log('loading.......', this.collectionName);
    this.params = {
      _id: this.userNameRoute,
      visitorId: this.userId,
      topicName: this.collectionName,
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: ++this.currentPage,
    };
    this.collectionService
      .userProfileDetails(this.params)
      .subscribe((response) => {
        this.collectionList.push(...response.summaryData);
      });
  }
  saveCollection(
    collections: any,
    bookmark: boolean,
    collectionId: any,
    title: any
  ) {
    if (this.collectionService.getToken() == '') {
      this.dialog.open(NoAuthSignupComponent, {
        data: {
          title: 'Read later this collection',
          ShareLink: `/c/${title.split(' ').join('-')}/id-${collectionId}`,
          disableClose: true,
        },
      });
    } else {
      let obj = {
        summaryId: collectionId,
      };
      this.collectionService.userCollectionGet(obj).subscribe(
        (res) => {
          collections.bookmarkSummary = true;
        },
        (error) => {}
      );
    }
  }
  shareUserProfile() {
    if (this.userNameRoute != '') {
      this.vistorsId = this.userNameRoute;
    } else {
      this.vistorsId = this.userId;
    }
    this.dialog.open(DynamicShareButtonDialogComponent, {
      data: {
        title: `${environment.SHARE_URL}/user/${this.userName
          .split(' ')
          .join('-')}?id=${this.vistorsId}`,
        disableClose: true,
      },
    });
  }
  navigateChannel(channelName: any) {
    this.router.navigate([`/${channelName}`.split(' ')?.join('')]);
  }
  subscribeChannel(collections: any, subscribe: boolean) {
    // console.log('collection', collections);
    // console.log('subscribe', subscribe);

    if (this.collectionService.getToken() == '') {
      this.dialog.open(NoAuthSignupComponent, {
        data: {
          title: 'Follow this channel',
          ShareLink: `/c/${collections.summaryName.split(' ').join('-')}/id-${
            collections._id
          }`,
          disableClose: true,
        },
      });
    } else {
      this.params = {
        publisherName: collections.publisherName,
        publisherId: collections.publisherId,
      };
      this.collectionService
        .followCreatorChannel(this.params)
        .subscribe((res) => {
          // this.followersAPI();
          collections.channelFollow = true;
        });
    }
  }

  catergoryNavigate(item: any) {
    console.log('clickded');
    this.router.navigate([`topic/${item}`]);
  }
  unFollowCategories(categories: any, boolena: any, collections: any) {
    // console.log(categories);
    if (this.collectionService.getToken() == '') {
      this.dialog.open(NoAuthSignupComponent, {
        data: {
          title: 'Join this community',
          ShareLink: `/c/${collections.summaryName.split(' ').join('-')}/id-${
            collections._id
          }`,
          disableClose: true,
        },
      });
    } else {
      this.collectionService
        .caterogyUnFollow(categories._id)
        .subscribe((response) => {
          categories.topicFollow = false;
        });
    }
  }

  likeCollections(collections: any, likes: Boolean, id: any) {
    // console.log('collection', collections);
    // console.log('likes', likes);
    // console.log('id', id);
    if (this.collectionService.getToken() == '') {
      this.dialog.open(NoAuthSignupComponent, {
        data: {
          title: 'Blink collection',
          ShareLink: `/c/${collections.summaryName.split(' ').join('-')}/id-${
            collections._id
          }`,
          disableClose: true,
        },
      });
    } else {
      this.collectionService
        .userLikeCollections(collections._id)
        .subscribe((res) => {
          // this.lottieimage = true;
          collections.summaryLikeCount++;
          collections.summaryLike = true;

          // setTimeout(() => {
          //   this.lottieimage = false;
          // }, 3000);
        });
    }
  }

  followBtn(item: any, catergoriesFollowed: any, collection: any) {
    // console.log(item);
    if (this.collectionService.getToken() == '') {
      this.dialog.open(NoAuthSignupComponent, {
        data: {
          title: 'Join this community',
          ShareLink: `/c/${collection?.summaryName?.split(' ').join('-')}/id-${
            collection._id
          }`,
          disableClose: true,
        },
      });
    } else {
      if (item) {
        let obj = {
          topicId: item._id,
        };
        // console.log(obj);
        this.collectionService.caterogyFollow(obj).subscribe((res) => {
          // console.log(obj);
          item.topicFollow = true;
        });
      } else {
      }
    }
  }
  // channel-follow and category -follows btn
  unFollowCategoriesBtn(categories: any) {
    // console.log(categories);
    if (this.collectionService.getToken() == '') {
      this.dialog.open(NoAuthSignupComponent, {
        data: {
          title: 'Join this community',
          disableClose: true,
        },
      });
    } else {
      this.collectionService
        .caterogyUnFollow(categories._id)
        .subscribe((response) => {
          categories.topicFollow = false;
        });
    }
  }
  followcategoryBtn(item: any) {
    // console.log(item);
    if (this.collectionService.getToken() == '') {
      this.dialog.open(NoAuthSignupComponent, {
        data: {
          title: 'Join this community',
          disableClose: true,
        },
      });
    } else {
      if (item) {
        let obj = {
          topicId: item._id,
        };
        // console.log(obj);
        this.collectionService.caterogyFollow(obj).subscribe((res) => {
          // console.log(obj);
          item.topicFollow = true;
        });
      } else {
      }
    }
  }
  channelSubsribeProfile(collections: any, subscribe: boolean) {
    // console.log('collection', collections);
    // console.log('subscribe', subscribe);

    if (this.collectionService.getToken() == '') {
      this.dialog.open(NoAuthSignupComponent, {
        data: {
          title: 'Follow this channel',
          disableClose: true,
        },
      });
    } else {
      this.params = {
        publisherName: collections.publisherName,
        publisherId: collections.publisherId,
      };
      this.collectionService
        .followCreatorChannel(this.params)
        .subscribe((res) => {
          // this.followersAPI();
          collections.publisherFollow = true;
        });
    }
  }
  // channel-follow and category -follows btn

  share(collections: any) {
    this.dialog.open(DynamicShareButtonDialogComponent, {
      data: {
        title: `${environment.SHARE_URL}/c/${collections.summaryName
          .split(' ')
          .join('-')}/id-${collections._id}`,
        summaryName: collections.summaryName.split(' ').join('-'),
        disableClose: true,
      },
    });
  }
  tweetBtn(item: any) {
    // this.collectionsClick(item._id);
    // console.log(item);
    return `https://www.shareaholic.com/api/share/?v=1&apitype=1&apikey=2ceb7e6973521a10a3e4b25ad56072e7
  &service=twitter&title=${item.summaryName}&link=${
      environment.SHARE_URL
    }/c/${item.summaryName?.split(' ').join('-')}/id-${
      item._id
    }/&source=Shareaholic`;
  }
  comments(collections: any) {
    if (collections.showCollectionComment) {
      // Close the comment if it's open
      collections.showCollectionComment = false;
    } else {
      this.collectionList.forEach((c: any) => {
        if (c !== collections.showCollectionComment) {
          c.showCollectionComment = false;
        }
      });
      collections.showCollectionComment = true;
    }

    if (collections._id && collections.showCollectionComment == true) {
      this.summaryId = collections._id;
      this.commentsGet();
    }
  }

  // comment ts file
  onSubmitComment(collections: any) {
    console.log(this.commentForm.value);
    if (this.collectionService.getToken() != '') {
      if (this.commentForm) {
        this.commentBtnEvent = false;
        let obj = {
          summaryId: collections._id,
          comment: this.commentForm.value.title,
        };
        this.collectionService.commentPost(obj).subscribe((response) => {
          console.log(response);
          // this.commentUser = response.data;
          this.commentsGet();
          this.cancelComment();
        });
      }
    }
  }

  commentsGet() {
    this.showCommentLoader = true;
    this.collectionService.commentGet(this.summaryId).subscribe((response) => {
      this.commentUser = response.data;
      this.showCommentLoader = false;

      this.totalComments = response.totalComment;
      this.commentUser.forEach((comment: any) => {
        return (comment.showReplyForm = false), (comment.showReplys = false);
      });
      console.log(this.commentUser);
    });
  }
  get commentFormError(): { [key: string]: AbstractControl } {
    return this.commentForm.controls;
  }
  get replyCommentFormError(): { [key: string]: AbstractControl } {
    return this.replyCommentForm.controls;
  }
  // validations for AlphabetsOnly
  nameValidations(event: any, item: any) {
    if (item == 'title') {
      let newValue = event.target.value.replace(/[^a-zA-Z ]/g, '');
      console.log('alphabets', item);
      this.commentForm.controls['title'].setValue(newValue);
    } else if (item == 'edit') {
      let newValue = event.target.value.replace(/[^a-zA-Z ]/g, '');
      console.log('alphabets', newValue);
      this.editCommentForm.controls['edit'].setValue(newValue);
    } else if (item == 'replyEdit') {
      let newValue = event.target.value.replace(/[^a-zA-Z ]/g, '');
      console.log('alphabets', newValue);
      this.replyEditCommentForm.controls['replyEdit'].setValue(newValue);
    }
  }
  ReplyValidations(event: any, item: any) {
    if (item == 'reply') {
      let newValue = event.target.value.replace(/[^a-zA-Z ]/g, '');
      console.log('alphabets', item);
      this.replyCommentForm.controls['reply'].setValue(newValue);
    }
  }
  showReplyBtn(item: any) {
    item.showReplys = !item.showReplys;
  }
  cancelComment() {
    this.inputActive = false;
    this.showButtons = false;
    this.commentForm.reset();
    this.editActive = false;
    this.showEditButtons = false;
  }
  spamComment(comment: any, collections: any) {
    console.log(comment._id);
    if (this.collectionService.getToken() == '') {
      this.readonly = true;
      this.dialog.open(NoAuthSignupComponent, {
        data: {
          title: 'to mark as spam',
          ShareLink: `/c/${collections.summaryName.split(' ').join('-')}/id-${
            collections._id
          }`,
          disableClose: true,
        },
      });
    } else {
      let obj = {
        commentReplyId: '',
      };
      this.collectionService.commentSpam(obj, comment._id).subscribe(
        (res) => {
          console.log(res);
          Swal.fire({
            title: 'successfully marked as spam',
            icon: 'success', // You can change the icon type
            timer: 2000, // Set the timer to 3000 milliseconds (3 seconds)
            showConfirmButton: false, // Hide the "OK" button
          });
        },
        (error) => {
          console.log(error);
          Swal.fire({
            title: error.error.message,
            icon: 'error', // You can change the icon type
            timer: 2000, // Set the timer to 3000 milliseconds (3 seconds)
            showConfirmButton: false, // Hide the "OK" button
          });
        }
      );
    }
  }
  spamReplyComment(comment: any, replyId: any, collections: any) {
    console.log('commentId', comment._id);
    console.log('repltID', replyId);
    if (this.collectionService.getToken() == '') {
      this.readonly = true;
      this.dialog.open(NoAuthSignupComponent, {
        data: {
          title: 'to mark as spam',
          ShareLink: `/c/${collections.summaryName.split(' ').join('-')}/id-${
            collections._id
          }`,
          disableClose: true,
        },
      });
    } else {
      let obj = {
        commentReplyId: replyId,
      };
      this.collectionService.commentSpam(obj, comment._id).subscribe(
        (res) => {
          console.log(res);
          Swal.fire({
            title: 'successfully marked as spam',
            icon: 'success', // You can change the icon type
            timer: 2000, // Set the timer to 3000 milliseconds (3 seconds)
            showConfirmButton: false, // Hide the "OK" button
          });
        },
        (error) => {
          Swal.fire({
            title: error.error.message,
            icon: 'error', // You can change the icon type
            timer: 2000, // Set the timer to 3000 milliseconds (3 seconds)
            showConfirmButton: false, // Hide the "OK" button
          });
        }
      );
    }
  }
  spamReplyOffensive(comment: any, replyId: any, collections: any) {
    console.log('commentId', comment._id);
    console.log('repltID', replyId);

    if (this.collectionService.getToken() == '') {
      this.readonly = true;
      this.dialog.open(NoAuthSignupComponent, {
        data: {
          title: 'to mark as spam',
          ShareLink: `/c/${collections.summaryName.split(' ').join('-')}/id-${
            collections._id
          }`,
          disableClose: true,
        },
      });
    } else {
      let obj = {
        commentReplyId: replyId,
      };
      this.collectionService.commentOffensive(obj, comment._id).subscribe(
        (res) => {
          console.log(res);
          Swal.fire({
            title: 'successfully marked as spam',
            icon: 'success', // You can change the icon type
            timer: 2000, // Set the timer to 3000 milliseconds (3 seconds)
            showConfirmButton: false, // Hide the "OK" button
          });
        },
        (error) => {
          Swal.fire({
            title: error.error.message,
            icon: 'error', // You can change the icon type
            timer: 2000, // Set the timer to 3000 milliseconds (3 seconds)
            showConfirmButton: false, // Hide the "OK" button
          });
        }
      );
    }
  }
  spamOffensive(comment: any, collections: any) {
    if (this.collectionService.getToken() == '') {
      this.readonly = true;
      this.dialog.open(NoAuthSignupComponent, {
        data: {
          title: 'to mark as offensive',
          ShareLink: `/c/${collections.summaryName.split(' ').join('-')}/id-${
            collections._id
          }`,
          disableClose: true,
        },
      });
    } else {
      let obj = {
        commentReplyId: '',
      };
      this.collectionService.commentOffensive(obj, comment._id).subscribe(
        (res) => {
          console.log(res);
          Swal.fire({
            title: 'successfully marked as offensive',
            icon: 'success', // You can change the icon type
            timer: 2000, // Set the timer to 3000 milliseconds (3 seconds)
            showConfirmButton: false, // Hide the "OK" button
          });
        },
        (error) => {
          console.log(error);
          Swal.fire({
            title: error.error.message,
            icon: 'error', // You can change the icon type
            timer: 2000, // Set the timer to 3000 milliseconds (3 seconds)
            showConfirmButton: false, // Hide the "OK" button
          });
        }
      );
    }
  }
  deleteCommentPublisherandPowerUser(comments: any) {
    console.log('commentID', comments._id);
    console.log('collectionID', this.CollectionID);
    let obj = {
      summaryId: this.CollectionID,
      commentId: comments._id,
    };
    this.collectionService.deleteCommentPublisher(obj).subscribe((res) => {
      this.commentsGet();
      Swal.fire({
        title: 'Comment delete successfully',
        icon: 'success', // You can change the icon type
        timer: 2000, // Set the timer to 3000 milliseconds (3 seconds)
        showConfirmButton: false, // Hide the "OK" button
      });
    });
  }
  deleteReplyCommentPublisherandPowerUser(comments: any, replyId: any) {
    console.log('commentID', comments._id);
    console.log('collectionID', this.CollectionID);
    let obj = {
      summaryId: this.CollectionID,
      commentId: comments._id,
      commentReplyId: replyId,
    };
    this.collectionService.deleteCommentPublisher(obj).subscribe((res) => {
      this.commentsGet();
      Swal.fire({
        title: 'Comment delete successfully',
        icon: 'success', // You can change the icon type
        timer: 2000, // Set the timer to 3000 milliseconds (3 seconds)
        showConfirmButton: false, // Hide the "OK" button
      });
    });
  }
  // edit and delete comment buttons and api calls
  editMainComment(editData: any) {
    editData.isEdit = !editData.isEdit;
    console.log(editData);
    this.editCommentForm.controls['edit'].setValue(editData.comment);

    this.toggleEditButtons();
  }
  editReplyComment(editData: any) {
    editData.isEdit = !editData.isEdit;
    console.log(editData);
    this.replyEditCommentForm.controls['replyEdit'].setValue(editData.comment);

    this.toggleReplyEditButtons();
  }
  cancelEditComment(item: any) {
    item.isEdit = false;
    this.editActive = false;
    this.showEditButtons = false;
  }
  saveEditForm(editData: any) {
    console.log(this.editCommentForm.value.edit);
    let obj = {
      comment: this.editCommentForm.value.edit,
      commentReply: false,
    };
    this.collectionService
      .commentUpdateReply(obj, editData._id)
      .subscribe((response) => {
        this.commentsGet();
      });
  }
  deletComment(editData: any) {
    console.log(this.editCommentForm.value.edit);
    let obj = {
      commentReplyId: '',
    };
    this.collectionService
      .commentDeleteReply(obj, editData._id)
      .subscribe((response) => {
        this.commentsGet();
      });
  }
  deletReplyComment(editData: any, replyId: any) {
    console.log(this.editCommentForm.value.edit, replyId);
    let obj = {
      commentReplyId: replyId,
    };
    this.collectionService
      .commentDeleteReply(obj, editData._id)
      .subscribe((response) => {
        this.commentsGet();
      });
  }
  saveReplyEditForm(editData: any) {
    console.log(this.replyEditCommentForm.value.replyEdit);
    let obj = {
      comment: this.replyEditCommentForm.value.replyEdit,
      commentReply: true,
    };
    this.collectionService
      .commentUpdateReply(obj, editData._id)
      .subscribe((response) => {
        this.commentsGet();
      });
  }
  toggleEditButtons() {
    if (this.collectionService.getToken() != '') {
      this.editActive = true;
      this.readonly = false;
      this.showEditButtons = true;
    }
  }
  toggleReplyEditButtons() {
    if (this.collectionService.getToken() != '') {
      this.editActive = true;
      this.readonly = false;
      this.showReplyEditButtons = true;
    }
  }
  // edit and delete comment buttons and api calls
  commentEvent() {
    if (this.collectionService.getToken() == '') {
      this.readonly = true;
      this.dialog.open(NoAuthSignupComponent, {
        data: {
          title: 'to write the comment',

          // ShareLink: `/c/${this.userDetailsParams.split(' ').join('-')}/id-${
          //   this.product_id
          // }`,
          disableClose: true,
        },
      });
    }
  }
  toggleButtons() {
    if (this.collectionService.getToken() != '') {
      this.inputActive = true;
      this.readonly = false;
      this.showButtons = true;
    }
  }
  replyToggleButtons() {
    if (this.collectionService.getToken() != '') {
      this.replyInputActive = true;
      this.showButtons = true;
    }
  }

  // reply comment ts file
  replyShowInput(comment: any) {
    if (this.collectionService.getToken() == '') {
      this.readonly = true;
      this.dialog.open(NoAuthSignupComponent, {
        data: {
          title: 'to reply the comment',
          // image: this.thumbnailImage,
          // ShareLink: `/c/${this.userDetailsParams.split(' ').join('-')}/id-${
          //   this.product_id
          // }`,
          disableClose: true,
        },
      });
    } else {
      console.log('replyId', comment._id);
      this.commentId = comment._id;
      comment.showReplyForm = !comment.showReplyForm;
    }
  }

  commentEventReply(e: any) {
    if (e.target.value != '') {
      this.commentBtnEventReply = false;
    } else {
      this.commentBtnEventReply = true;
    }
  }

  cancelCommentReply(comments: any) {
    this.inputActive = false;
    this.showButtonsReply = false;
    comments.showReplyForm = false;
    this.replyCommentForm.reset();
  }
  onReplyComment() {
    let obj = {
      comment: this.replyCommentForm.value.reply,
    };
    this.collectionService
      .commentReply(obj, this.commentId)
      .subscribe((response) => {
        this.replyCommentShow = false;
        this.commentsGet();
        this.inputActive = false;
        this.showButtonsReply = false;
        this.replyCommentForm.reset();
      });
    // this.Replycomment = this.replyCommentForm.value.reply;
    // this.ReplycommentUser.push({
    //   name: 'ram',
    //   comment: this.Replycomment,
    //   date: '2023-09-07T05:28:39.342Z',
    //   id: '3456',
    // });
  }

  toggleButtonsReply() {
    this.replyInputActive = true;
    this.showButtonsReply = true;
  }
}
