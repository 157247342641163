<div class="main">
<div class="">

    <div class="inviteSuccfully-section">
        <div class="image-close cursor" (click)="close()">
      <img src="../../assets/images/cancel.png" class="close-img"/>
    </div>


    <div class="invited">
         <h1>Summarizly AI</h1>
         <h5>Welcome to {{title}} AI Summaries</h5>
                         <ul class='mt-3 arrow-list'>
                          <li>Read AI summaries,grow abd retain your knowledge</li>
                          <li>Choose your favorite topics and grow knowledge in that with AI</li>
                          <li>AI summaries are generated from the most popular youtube videos</li>
                         </ul>
                         <h3> Ready to read {{title}} AI summaries?</h3>
         <div class="invite-button">
            <button class="cursor" (click)="close()">Surprise me</button>

         </div>
    </div>
    </div>
</div>
</div>

