import { Component, NgModule, ViewChild } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AddCategoriesComponent } from './add-categories/add-categories.component';
import { EditCategoriesComponent } from './add-categories/edit-categories/edit-categories.component';
import { UpdateCategoriesComponent } from './add-categories/update-categories/update-categories.component';
import { AdminHomeComponent } from './admin/admin-home/admin-home.component';
import { DashboardComponent } from './admin/dashboard/dashboard.component';
import { BulkProductComponent } from './bulk-product/bulk-product.component';
import { AllcollectionComponent } from './collections/allcollection/allcollection.component';
import { CollectionsComponent } from './collections/collections.component';
import { UsercollectionComponent } from './collections/usercollection/usercollection.component';
import { ViewcollectionComponent } from './collections/viewcollection/viewcollection.component';
import { CookiesPolicyComponent } from './cookies-policy/cookies-policy.component';
import { CreatechannelComponent } from './createchannel/createchannel.component';
import { EditChannelComponent } from './createchannel/edit-channel/edit-channel.component';
import { UpdateChannelComponent } from './createchannel/update-channel/update-channel.component';
import { CreatorCreateCollectionComponent } from './creator-create-collection/creator-create-collection.component';
import { CreatorCreateProductComponent } from './creator-create-product/creator-create-product.component';
import { CreatorProfileComponent } from './creator-profile/creator-profile.component';
import { CreatorViewlinksComponent } from './creator-viewlinks/creator-viewlinks.component';
import { CreatorchannelcreateComponent } from './creatorchannelcreate/creatorchannelcreate.component';
import { EditCollectionComponent } from './edit-collection/edit-collection.component';
import { EditProductComponent } from './edit-product/edit-product.component';
import { FliterwithcatergoryComponent } from './fliterwithcatergory/fliterwithcatergory.component';
import { FliterwithchannelnameComponent } from './fliterwithchannelname/fliterwithchannelname.component';
import { HomenavbarComponent } from './homenavbar/homenavbar.component';
import { HomepageComponent } from './homepage/homepage.component';
import { Summaryfeed2Component } from './homepage/summaryfeed2/summaryfeed2.component';
import { LandingpageComponent } from './landingpage/landingpage.component';
import { LinkproductComponent } from './linkproduct/linkproduct.component';
import { UserdiscountcodesComponent } from './linkproduct/userdiscountcodes/userdiscountcodes.component';
import { UsersavedproductComponent } from './linkproduct/usersavedproduct/usersavedproduct.component';
import { ProductdetailsComponent } from './linkproduct/viewproduct/productdetails/productdetails.component';
import { ViewproductComponent } from './linkproduct/viewproduct/viewproduct.component';
import { CreatorForgotpasswordComponent } from './login-page/creator-forgotpassword/creator-forgotpassword.component';
import { CreatorGooglesignupComponent } from './login-page/creator-googlesignup/creator-googlesignup.component';
import { CreatorLoginComponent } from './login-page/creator-login/creator-login.component';
import { CreatorSignupComponent } from './login-page/creator-signup/creator-signup.component';
import { PowerUserLoginComponent } from './login-page/power-user-login/power-user-login.component';
import { PowerUserSignupComponent } from './login-page/power-user-signup/power-user-signup.component';
import { PoweruserForgotpasswordComponent } from './login-page/poweruser-forgotpassword/poweruser-forgotpassword.component';
import { NavbarComponent } from './navbar/navbar.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { PoweruserBulkProductComponent } from './poweruser-bulk-product/poweruser-bulk-product.component';
import { MycollectionComponent } from './PowerUser/mycollection/mycollection.component';
import { MyproductsComponent } from './PowerUser/myproducts/myproducts.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ProductnavbarComponent } from './productnavbar/productnavbar.component';
import { TermsOfServicesComponent } from './terms-of-services/terms-of-services.component';
import { UserProfileComponent } from './usernavbar/user-profile/user-profile.component';
import { UserprofileupdateComponent } from './usernavbar/userprofileupdate/userprofileupdate.component';
import { ViewbulkproductsComponent } from './viewbulkproducts/viewbulkproducts.component';
import { FliterCategortyPipe } from './_pipes/fliter-categorty.pipe';
import { LoginGuard } from './_services/login.guard';
import { PublisherVideosComponent } from './creator-profile/publisher-videos/publisher-videos.component';
import { PublisherAnalyticsComponent } from './creator-profile/publisher-analytics/publisher-analytics.component';
import { PublisherOnboardingComponent } from './creator-profile/publisher-onboarding/publisher-onboarding.component';
import { PublisherhomepageComponent } from './publisher-portal/publisherhomepage/publisherhomepage.component';
import { PublishCollectionComponent } from './publish-collection/publish-collection.component';
import { UserForgotpasswordComponent } from './login-page/user-forgotpassword/user-forgotpassword.component';
import { UserbookmarksComponent } from './userbookmarks/userbookmarks.component';
import { UserpublishersComponent } from './userpublishers/userpublishers.component';
import { UsertopicsComponent } from './usertopics/usertopics.component';

const LoginPageModule = () =>
  import('../../src/app/login-page/login-page.module').then(
    (x) => x.LoginPageModule
  );
// const AdminModule = () =>
//   import('../../src/app/_admin/admin/admin.module').then((x) => x.AdminModule);
const routes: Routes = [
  // {
  //   path: ':name',
  //   component: ViewproductComponent,
  //   children: [{ path: ':name', component: ViewproductComponent }],
  // },
  // {
  //   path: ':name',
  //   component: ViewproductComponent,
  //   children: [{ path: ':name', component: ViewcollectionComponent }],
  // },
  { path: '', component: HomepageComponent },
  {
    path: 'lookup-admin',
    component: AdminHomeComponent,
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: 'Publisher',
    component: PublisherhomepageComponent,
    loadChildren: () =>
      import('./publisher-portal/publisher-portal.module').then(
        (m) => m.PublisherPortalModule
      ),
  },
  { path: 'feed', component: HomepageComponent },
  { path: '404-page-not-found', component: PagenotfoundComponent },
  { path: 'SummaryFeed', component: Summaryfeed2Component },
  { path: 'user', loadChildren: LoginPageModule },
  { path: 'Poweruserhome-batman', component: NavbarComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'terms-of-service', component: TermsOfServicesComponent },
  { path: 'cookies-policy', component: CookiesPolicyComponent },
  {
    path: 'PowerUser/login-batman',
    component: PowerUserLoginComponent,
  },
  {
    path: 'PowerUser/signup-batman',
    component: PowerUserSignupComponent,
  },
  {
    path: 'publisher_login',
    component: CreatorLoginComponent,
  },
  {
    path: 'publisher_signup/new',
    component: CreatorGooglesignupComponent,
  },
  {
    path: 'publisher_signup',
    component: CreatorSignupComponent,
  },
  {
    path: 'publisher/reset-password',
    component: CreatorForgotpasswordComponent,
  },
  {
    path: 'publisher/myVideos',
    component: PublisherVideosComponent,
  },
  {
    path: 'publisher/Analytics',
    component: PublisherAnalyticsComponent,
  },
  {
    path: 'publisher/onboaring',
    component: PublisherOnboardingComponent,
  },
  {
    path: 'powerUser/reset-password',
    component: PoweruserForgotpasswordComponent,
  },

  {
    path: 'linkProduct',
    component: LinkproductComponent,
  },

  {
    path: 'viewProducts',
    component: ViewproductComponent,
  },
  {
    path: 'robot.txt',
    component: ViewproductComponent,
  },
  {
    path: 'viewProducts',
    children: [
      {
        path: 'ProductsDetails',
        component: ProductdetailsComponent,
        canActivate: [LoginGuard],
      },
    ],
  },
  {
    path: 'Collections',
    component: CollectionsComponent,
  },
  {
    path: 'user/bookmark',
    component: UserbookmarksComponent,
  },
  {
    path: 'user/publishers',
    component: UserpublishersComponent,
  },
  {
    path: 'user/topics',
    component: UsertopicsComponent,
  },
  { path: 'feed/reset-password', component: HomepageComponent },

  {
    path: 'Collections',
    children: [
      {
        path: 'ViewCollection',
        component: ViewcollectionComponent,
      },
    ],
  },
  {
    path: 'Collections',
    children: [
      {
        path: 'Feed',
        component: AllcollectionComponent,
        canActivate: [LoginGuard],
      },
    ],
  },
  { path: 'user/discountCodes', component: UserdiscountcodesComponent },
  { path: 'user/productBookmark', component: UsersavedproductComponent },
  { path: 'user/collectionBookmark', component: UsercollectionComponent },

  {
    path: 'user/profile',
    component: UserProfileComponent,
  },
  {
    path: 'user/edit-profile',
    component: UserprofileupdateComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'categories',
    component: AddCategoriesComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'creator/profile',
    component: CreatorProfileComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'My-Collections',
    component: MycollectionComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'EditChannel',
    component: EditChannelComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'updateChannel',
    component: UpdateChannelComponent,
    canActivate: [LoginGuard],
  },

  {
    path: 'EditCategories',
    component: EditCategoriesComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'updateCategories',
    component: UpdateCategoriesComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'Edit-Collection',
    component: EditCollectionComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'publish_type-bulk-products',
    component: BulkProductComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'powerUser-bulk-products',
    component: PoweruserBulkProductComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'Edit-Product',
    component: EditProductComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'My-Products',
    component: MyproductsComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'creator/createChannel',
    component: CreatorchannelcreateComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'publisher/post_type-collection/new',
    component: CreatorCreateCollectionComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'creator/post_type-product_link/new',
    component: CreatorCreateProductComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'creator/viewlinks',
    component: CreatorViewlinksComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'Publish',
    component: PublishCollectionComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'createChannel',
    component: CreatechannelComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'user/:username',
    component: UserProfileComponent,
  },
  {
    path: 'topic/:title',
    component: FliterwithcatergoryComponent,
  },
  {
    path: 'topic/:title/invite',
    component: FliterwithcatergoryComponent,
  },
  {
    path: ':username',
    component: FliterwithchannelnameComponent,
  },
  {
    path: ':username/invite',
    component: FliterwithchannelnameComponent,
  },
  {
    path: ':username/:name',
    component: FliterwithchannelnameComponent,
  },
  {
    path: 'feed/topic/:title',
    component: HomepageComponent,
  },

  {
    path: ':username/language/:title',
    component: FliterwithchannelnameComponent,
  },
  {
    path: ':username/product',
    component: FliterwithchannelnameComponent,
  },

  {
    path: ':username/collection',
    component: FliterwithchannelnameComponent,
  },
  // {
  //   path: ':name/product/:title/:id',
  //   component: ProductdetailsComponent,
  // },
  // {
  //   path: 'p/:title/:id',
  //   component: ProductdetailsComponent,
  // },
  {
    path: 'c/:title/:id',
    component: ViewcollectionComponent,
  },
  // {
  //   path: ':name/:title/:id',
  //   component: ViewbulkproductsComponent,
  // },

  // {
  //   path: 'ViewCollection/:title/:id',
  //   component: ViewcollectionComponent,
  // },

  { path: '**', pathMatch: 'full', component: PagenotfoundComponent },
];
// {
//   path: 'product/:name',
//   component: ViewproductComponent,
//   canActivate: [LoginGuard],
// },
// {
//   path: ':name/:title',
//   component: ProductdetailsComponent,
//   canActivate: [LoginGuard],
// },
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
