import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { AdminService } from 'src/app/_services/admin.service';

export interface PeriodicElement {
  sid: any;
  Thumbali: any;
  summarytitle: any;
  Publishedby: any;
  Status: any;
  action: any;
}
@Component({
  selector: 'app-toogle',
  templateUrl: './toogle.component.html',
  styleUrls: ['./toogle.component.css'],
})
export class ToogleComponent implements OnInit {
  ELEMENT_DATA: Element[] = [];
  displayedColumns: string[] = ['sid', 'Thumbali', 'summarytitle', 'action'];
  dataSource = new MatTableDataSource(this.ELEMENT_DATA);
  loader: boolean;
  constructor(private adminService: AdminService) {}

  ngOnInit(): void {
    this.userApisList();
  }
  userApisList() {
    this.loader = true;

    this.adminService.getToggle().subscribe((result) => {
      console.log(result);
      console.log('data', result);
      this.loader = false;
      this.ELEMENT_DATA = result.toggles;
      this.ELEMENT_DATA.forEach((toggle: any) => {
        if (toggle.status == 'active') {
          toggle.isActive = true;
        } else {
          toggle.isActive = false;
        }
      });
      console.log('toggles', this.ELEMENT_DATA);
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    });
  }
  activetoggleChanged(e: any, item: any) {
    let obj = {
      action: 'inactive',
    };
    this.adminService.getToggleStatus(obj, item._id).subscribe((res) => {
      item.isActive = false;
      this.userApisList();
    });
  }
  inActivetoggleChanged(e: any, item: any) {
    item.isActive = true;
    let obj = {
      action: 'active',
    };
    this.adminService.getToggleStatus(obj, item._id).subscribe((res) => {
      item.isActive = true;
      this.userApisList();
    });
  }
  getBackgroundColor(item: any) {
    return item.isActive ? '#2196f3' : ''; // Change color as needed
  }
}
