   
   <div class="main">
<app-usernavbar></app-usernavbar>

    <div class="container">
         <div class="collectionall-header ml-md-3 mt-3">
           <h5>My Products</h5>
        </div>
  <div class="collection-header product_details_Paragraph">
      <div class="list-of-products mt-md-3 pt-md-3" id="product-related">
       <div class="no-bookmarked"  *ngIf="productList == ''">
         
         <h3>No products bookmarked</h3>
       </div>
        <div class="row" *ngIf="productList != ''">
          <div class="col-md-4" *ngFor="let cardDetails of productList">
           
              <div class="card mb-md-4" >
                <div class="card-body pointer">
                  <div class="product_list">
                    <!-- <div class="d-flex list-P"></div> -->
                    <img *ngIf="cardDetails.products[0].image == null && cardDetails?.products[0].productImage[0]?.url == ''" (click)="productDetails(cardDetails.products[0].productTitle,cardDetails._id)" src="../../../assets/images/n-image.png" class="code-1-img image">
                    <img *ngIf="cardDetails.products[0].image != null " (click)="productDetails(cardDetails.products[0].productTitle,cardDetails.products[0]._id)" src="{{cardDetails.products[0].image}}" class="code-1-img image">
                    <img *ngIf="cardDetails?.products[0].productImage != ''" (click)="productDetails(cardDetails.products[0].productTitle,cardDetails.products[0]._id)" src="{{cardDetails?.products[0].productImage[0]?.url}}" class="code-1-img">
                    <div class="d-flex">
                      <div class="mr-auto">
                        <p class="header-p pt-2">{{cardDetails.products.productTitle | slice:0:24}}</p>
                      </div>
                       
                    </div>
                      <div class="d-flex">
                         <div class="mr-auto collection-name-link ml-3">
                          <a routerLink="/p/{{cardDetails.products[0].productTitle?.split(' ').join('-')}}/id-{{cardDetails.products[0]._id}}">
                            <h5>View Product</h5>
                          </a>
                        </div>
                         <div class="save-for-later d-flex  mr-3">
                        <div>
                      <img src="../../../assets/images/heart-1.png" >

                        </div>
                       <div>
                          <!-- <a (click)="saveForlater(cardDetails._id)">
                            <h5>Bookmark</h5>
                           
                          </a> -->
                          <a>
                            <h5 class="saved-b">Bookmarked</h5>
                           
                          </a>

                           
                        </div>
                      </div>
                          
                      </div>
                      
                  </div>
                  
                  <div class="product_list_details">
                    <div class="d-flex">
                      
                    </div>
                    <div class="details-List-view" *ngIf="cardDetails.couponCode != ''">
                      <p>Creator Discount Code Available</p>
                      <div class="creator-code">
                        <div class="d-flex code-d">
                          <div class="code-p">
                            <!-- <p>{{cardDetails.couponCode}}</p> -->
                            <p>******</p>
                          </div>
                          <div class="copy-btn ml-auto">
                            <button type="button" class="ml-auto" (click)="productDetails(cardDetails.products[0].productTitle,cardDetails.products[0]._id)">Show</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            <!-- <p *ngIf="saved"  class="succesfully-p mt-1">
                  Product Bookmarked successfully
                </p>
                 <p *ngIf="productError != null" class="mt-md-3 code-d-c">
            {{ productError }}
          </p> -->
          </div>
        </div>
      </div>
       
    </div>
        </div>
   </div>
   
 