<div class="container">
  <div class="share-btns d-flex">
    <div class="share-1 share mr-auto pr-2">
      <h4>Share</h4>
    </div>
    <div class="image-close cursor" (click)="close()">
      <img src="../../assets/images/close.png" class="close-img"/>
    </div>
  </div>

  <div class="d-md-flex items">
     <div class="shareButtons mr-3">
        <a  [href]='FaceBookDynamicLInk' [attr.data-link]="title" data-app="share_buttons" data-app-config='{"service":"facebook"}' target="_blank">
            <img src="../../assets/images/facebook (1).png">
        </a>
     </div>
     <div class="shareButtons mr-3">
        <a [href]='TwitterDynamicLInk' [attr.data-link]="title" data-app="share_buttons" data-app-config='{"service":"twitter"}'  target="_blank">
            <img src="../../assets/images/twitter (1).png">
        </a>
     </div>
     <div class="shareButtons mr-3">
        <a    [href]='linkInDynamicLInk' target="_blank">
            <img src="../../assets/images/linkedin.png">
        </a>
     </div>
     <div class="shareButtons mr-3">
        <a   [href]='diggDynamicLInk' target="_blank">
            <img src="../../assets/images/digg.png">
        </a>
     </div>
     <div class="shareButtons mr-3">
        <a   [href]='gmailDynamicLInk' target="_blank">
            <img src="../../assets/images/gmail.png">
        </a>
     </div>
     <div class="shareButtons mr-3">
        <a  [href]='copyLinkDynamicLInk' target="_blank">
            <img src="../../assets/images/copy.png">
        </a>
     </div>
     <div class="shareButtons mr-3">
        <a   [href]='RedditDynamicLInk' target="_blank">
            <img src="../../assets/images/reddit.png">
        </a>
     </div>
     <div class="shareButtons mr-3">
        <a  [href]='whatsappDynamicLInk'  data-title='{og:title}'  data-image='{og:image}' [attr.data-link]="title" data-app="share_buttons" data-app-config='{"service":"whatsapp}' target="_blank">
            <img src="../../assets/images/whatsapp (1).png">
        </a>
     </div>
    
  </div>
  <!-- <div class="d-md-flex items">
     <div class="shareButtons mr-3">
        <a  data-app="share_buttons" data-app-config='{"service":"facebook"}' [href]='FaceBookDynamicLInk' target="_blank">
            <img src="../../assets/images/facebook (1).png">
        </a>
     </div>
     <div class="shareButtons mr-3">
        <a  data-app="share_buttons" [href]='TwitterDynamicLInk' target="_blank">
            <img src="../../assets/images/twitter (1).png">
        </a>
     </div>
     <div class="shareButtons mr-3">
        <a  data-app="share_buttons" data-app-config='{"service":"linkedin"}'   [href]='linkInDynamicLInk' target="_blank">
            <img src="../../assets/images/linkedin.png">
        </a>
     </div>
     <div class="shareButtons mr-3">
        <a  data-app="share_buttons" data-app-config='{"service":"digg"}'  [href]='diggDynamicLInk' target="_blank">
            <img src="../../assets/images/digg.png">
        </a>
     </div>
     <div class="shareButtons mr-3">
        <a  data-app="share_buttons" data-app-config='{"service":"google_mail"}'  [href]='gmailDynamicLInk' target="_blank">
            <img src="../../assets/images/gmail.png">
        </a>
     </div>
     <div class="shareButtons mr-3">
        <a data-app="share_buttons" data-app-config='{"service":"copy_link"}'  [href]='copyLinkDynamicLInk' target="_blank">
            <img src="../../assets/images/copy.png">
        </a>
     </div>
     <div class="shareButtons mr-3">
        <a data-app="share_buttons" data-app-config='{"service":"reddit"}'  [href]='RedditDynamicLInk' target="_blank">
            <img src="../../assets/images/reddit.png">
        </a>
     </div>
     <div class="shareButtons mr-3">
        <a data-app="share_buttons" data-app-config='{"service":"whatsapp"}'  [href]='whatsappDynamicLInk' target="_blank">
            <img src="../../assets/images/whatsapp (1).png">
        </a>
     </div>
    
  </div> -->
  </div>