<app-homenavbar *ngIf="sessionType == 'home'"></app-homenavbar>
<app-usernavbar *ngIf="sessionType == 'user'"></app-usernavbar>
<app-navbar *ngIf="sessionType == 'powerUser'"></app-navbar>
<app-creatornavbar *ngIf="sessionType == 'publisher'"></app-creatornavbar>
<!-- <div class="container">
  <div class="update-catergories mt-5">
    <h5 class="succesfully" *ngIf="succesfully">Topic updated successfully</h5>
<p *ngIf="errorView == true" class="error_filesize mt-md-4">{{errorMesseage}}</p>

    <h4>Update Topics</h4>
    <div class="row">
      <div class="col" *ngFor="let item of categoryItem;let i = index">
        <div class="card mb-3">
          <div class="card-body ">
            <form [formGroup]="catergoriesFormGroup">
              <div class="category-items radio-button-section">
                <div class="">
                  <p>{{item.name}}</p>
                </div>
                <div class="upload pt-4 pl-4 mt-5">
      <img *ngIf="url != null" [src]="url" width=100 height=100 alt="" class="upload-img">
      <img *ngIf="profile != null" [src]="profile" width=100 height=100 alt="" class="upload-img">
      <img *ngIf="url == null && profile == null" src="../../assets/images/place-holder.jpg" width=100 height=100 alt="" class="upload-img">
      <div class="round">
        <input type="file" accept="image/*" formControlName="categoryImage" (change)="onFileSelectedEvent($event)">
        <img src="../../assets/images/camera.svg" width=100 height=100 alt="" class="camera-img">
      </div>
    </div>
                 <div class="row mb-3">
      <div class="col-md-6">
        <div class="catergories-add-section mt-md-2">
          <label>Title</label>
          <input formControlName="title"   type="text" placeholder=" Enter the title" class="form-control">
           
        </div>
    
      </div>
      <div class="col-md-6">
        <div class="catergories-add-section mt-md-2">
          <label>Description</label>
          <input formControlName="description"   type="text" placeholder="Enter the Description" class="form-control">
          
        </div>
    
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 mt-md-4">
  
           <mat-radio-group formControlName="isAdded" class="mt-3" aria-label="Select an option">
                  <mat-radio-button class='mr-2' value="true" [checked]="item.isAdded">Active</mat-radio-button>
                  <mat-radio-button value="false" [checked]="!item.isAdded" >In active</mat-radio-button>
                </mat-radio-group>
      </div>
        <div class="col-md-6">
        <div class="catergories-add-section mt-md-2">
          <label>Add subTopics</label>
          <input (input)="subCategory($event)" formControlName="subCategories" placeholder="Add subtopics" type="text" class="form-control">
        </div>
        </div>
    </div>
             
              </div>
              <div class="d-flex justify-content-center mt-3 pb-3">
                <button class="btn-c" (click)="update(item,i)">update</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->

  <div class="container mt-5">
  <div class="topic_content" *ngFor="let item of categoryItem;let i = index">
     <h3>Edit topic {{item.name}}</h3>
     <p>Which topic are you most interested in publishing summaries ?</p>

     <form [formGroup]="catergoriesFormGroup">

      <div class="row">
        <div class="col-md-6">
           <div class="catergories-add-section mt-md-2" >
          <label>Enter the name of the topic</label>
          <input formControlName="categoryName" type="text" class="form-control">
          <p style="color: #d0d0d0;font-weight: 400;">Example:Beauty</p>
          <!-- <div *ngIf="c.categoryName.errors?.required && submiited" class="invalid-feedback m-t d-block">Categoryname is required </div> -->
        </div>
         <div class="catergories-add-section mt-md-2">
          <label>Add Sub topics for <span class="text-decoration-underline" *ngIf="this.catergoriesFormGroup.value.categoryName != ''">{{this.catergoriesFormGroup.value.categoryName}}</span></label>
          <p>
You can add multiple subtopics after each topic by pressing the Enter key on your keyboard.</p>
          <div class="d-flex">
            <div>

          <input (keydown.enter)="addSubtopics()" formControlName="subCategories" [ngModelOptions]="{standalone: true}" [(ngModel)]="subtopicsInput" placeholder="Add subtopics" type="text" class="form-control">
         
          <!-- <div *ngIf="this.error != null" class="invalid-feedback m-t d-block">{{this.error }}</div> -->
            </div>
 
            <div class="sub-topic_view ml-md-3">
               <ul class="d-flex"> 
  <li *ngFor="let subtopic of subtopics;index as i">
    <div class="d-flex">
{{ subtopic }}
<img src="../../assets/images/close.png" class="ml-3" (click)="remveSubtopic(i)">
    </div></li>
</ul>
            </div>
          </div>
         
        </div>
<div class="pt-5 pb-3">
      <div class="d-flex">
<div class=" upload">
<div class=" round">
        <input type="file" accept="image/*" (change)="onFileSelectedEvent($event)">
        <img src="../../assets/images/up0.svg" width=100 height=100 alt="" class="camera-img">
      </div>
      </div>
        <div>
          <p class="upload-p">Upload a profile picture for topic</p>
<mat-progress-bar mode="determinate" *ngIf="uploadLoader" [value]="uploadProgress"></mat-progress-bar>
        </div>
        <div>
   <img *ngIf="url != null " [src]="url" width=100 height=100 alt="" class="upload-img">
      <!-- <img *ngIf="url == null " src="../../assets/images/place-holder.jpg" width=100 height=100 alt="" class="upload-img"> -->
        </div>
      </div>
      
   
    </div>
<p *ngIf="errorView == true" class="error_filesize mt-md-4">{{errorMesseage}}</p>

            <mat-radio-group formControlName="isAdded" class="mt-3" aria-label="Select an option">
                  <mat-radio-button class='mr-2' value="true" [checked]="item.isAdded">Active</mat-radio-button>
                  <mat-radio-button value="false" [checked]="!item.isAdded" >In active</mat-radio-button>
                </mat-radio-group>
        </div>
        <div class="col-md-6">
         
            <div class="catergories-add-section mt-md-2">
          <label>RedditURL</label>
          <input formControlName="redditURL"  type="text" class="form-control">
          <!-- <p style="color: #d0d0d0;font-weight: 400;">Example:Beauty</p> -->
        
        </div>
        <div class="catergories-add-section mt-md-5 pt-2">
          <label>RedditMessage</label>
          <input formControlName="redditMessage"  type="text" class="form-control">
          <!-- <p style="color: #d0d0d0;font-weight: 400;">Example:Beauty</p> -->
        
        </div>
<div class="catergories-add-section mt-md-2">
          <label>CtaText</label>
          <input formControlName="ctaText"  type="text" class="form-control">
          <!-- <p style="color: #d0d0d0;font-weight: 400;">Example:Beauty</p> -->
       
        </div>


        
        </div>
      
      </div>
     
         <div class="submit-btn">
          <button type="button" class="mt-md-3 pointer" (click)="update(item,i)">update Topic</button>
        </div>
    </form>
  </div>
</div> 