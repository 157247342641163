import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
declare var clevertap: any;
@Component({
  selector: 'app-invite-dialog',
  templateUrl: './invite-dialog.component.html',
  styleUrls: ['./invite-dialog.component.css'],
})
export class InviteDialogComponent implements OnInit {
  title: any;
  shareLink: string;
  buttonText: string = 'Copylink';
  FaceBookDynamicLInk: string;
  TwitterDynamicLInk: string;
  whatsappDynamicLInk: string;
  diggDynamicLInk: string;
  channelTitle: any;
  profileName: any;
  constructor(
    private router: Router,
    private dilaog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    // this.data = data;
    clevertap.init('6ZW-454-496Z');

    console.log('clevertap initialized');
    console.log(this.data.name);
    this.title = this.data.name;
    this.profileName = this.data.profileName;
    this.shareLink = this.data.shareLink;
    this.channelTitle = this.data.title;
    this.FaceBookDynamicLInk = `https://www.shareaholic.com/api/share/?v=1&apitype=1&apikey=2ceb7e6973521a10a3e4b25ad56072e7
    &service=facebook&title=${this.channelTitle}&link=${this.shareLink}&source=Shareaholic`;
    this.TwitterDynamicLInk = `https://www.shareaholic.com/api/share/?v=1&apitype=1&apikey=2ceb7e6973521a10a3e4b25ad56072e7
    &service=twitter&title=${this.channelTitle}&link=${this.shareLink}&source=Shareaholic`;
    this.whatsappDynamicLInk = `https://www.shareaholic.com/api/share/?v=1&apitype=1&apikey=2ceb7e6973521a10a3e4b25ad56072e7
    &service=whatsapp&title=${this.channelTitle}&link=${this.shareLink}/&source=Shareaholic`;
    this.diggDynamicLInk = `https://www.shareaholic.com/api/share/?v=1&apitype=1&apikey=2ceb7e6973521a10a3e4b25ad56072e7
    &service=digg&title=${this.channelTitle}&link=${this.shareLink}/&source=Shareaholic`;
  }
  faceBook(name: any) {
    console.log(this.profileName);
    if (environment.production) {
      if (this.profileName == 'publisher') {
        clevertap.event.push('PublisherProflieInvitesVia', {
          PublisherProflieInviteVia: name,
        });
      } else {
        clevertap.event.push('TopicsProflieInvitesVia', {
          TopicsProflieInviteVia: name,
        });
      }
    }

    console.log('click facebook', name);
  }
  copied(text: any, e: any) {
    this.buttonText = this.buttonText === 'Copied' ? 'Copylink!' : 'Copied';
  }
  close() {
    this.dilaog.closeAll();
  }
}
