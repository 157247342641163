<div class="container">
  <div class="mb-md-4 close-btn">
    <button (click)="close()">
      <img src="../../assets/images/close.png" class="close-img" />
    </button>
  </div>
  <div class="d-flex">
    <div class="login-follow">
      <p *ngIf="title.includes('Read later this collection')">
        Login or signup is required to Read later this summary
      </p>
      <p *ngIf="title.includes('to mark view')">
        Login or signup is required to mark as offensive
      </p>
      <p *ngIf="title.includes('to mark spanview')">
        Login or signup is required to mark as spam
      </p>
      <p *ngIf="title.includes('view discount code')">
        Login or signup is required to {{ title }}
      </p>
      <p *ngIf="title.includes('to like collection')">
        Login or signup is required to vote summary
      </p>
      <p *ngIf="title.includes('Blink collection')">
        Login or signup is required to vote summary
      </p>
      <p *ngIf="title.includes('unBlinkcollection')">
        Login or signup is required to downVote summary
      </p>
      <p *ngIf="title.includes('Bookmark this product')">
        Login or signup is required to {{ title }}
      </p>
      <p *ngIf="title.includes('view Bookmark this discount code')">
        Login or signup is required to {{ title }}
      </p>
      <p *ngIf="title.includes('to follow  the channel')">
        Login or signup is required {{ title }}
      </p>
      <p *ngIf="title.includes('Follow this channel')">
        Login or signup is required Follow this publisher
      </p>
      <p *ngIf="title.includes('Join this community')">
        Login or signup is required {{ title }}
      </p>
      <p *ngIf="title.includes('to reply the comment')">
        Login or signup is required {{ title }}
      </p>
      <p *ngIf="title.includes('to write the comment')">
        Login or signup is required {{ title }}
      </p>
      <p *ngIf="title.includes('to mark as')">
        Login or signup is required {{ title }}
      </p>
 <p *ngIf="title.includes('to report')">
        Login or signup is required to report a summary
      </p>
      <p type="submit" class="font-weight-bold">Signup for free</p>

      <div class="diloag-btn text-center">
        <button type="submit" class="mt-md-3 google-btn">
          <a [href]="url | safePipe">
            <img
              src="../../assets/images/google.png"
              class="google mr-2"
            />Continue with Google
          </a>
        </button>
        <button type="submit" class="mt-md-3 email-btn" (click)="signup()">
          Continue with email
        </button>
      </div>
      <h6>
        Already have an account?
        <span class="pointer" (click)="login()">Login</span>
      </h6>
    </div>

    <div>
      <!-- <img src="../../assets/images/pro-1.jpg" class="dialog-img"> -->
      <img [src]="collectionImage" class="dialog-img" />
    </div>
  </div>
</div>
