import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AdminService } from 'src/app/_services/admin.service';
import { saveAs } from 'file-saver';
import { CollectionService } from 'src/app/_services/collection.service';
export interface PeriodicElement {
  sid: any;
  URL: any;
  Thumbali: any;
  summarytitle: any;
  Publishedby: any;
  channel: any;
  views: any;
  clicks: any;
  actions: any;
}

// const ELEMENT_DATA: PeriodicElement[] = [
//   {
//     sid: '6378uyhu',
//     Thumbali: 'https://img.youtube.com/vi/oWZbWzAyHAE/hqdefault.jpg',
//     summarytitle: 'How to wash hair properly LInk',
//     Publishedby: '@prabhakar',
//     channel: '@ycombinator',
//     views: '200',
//     clicks: '100',
//     actions: '',
//   },
//   {
//     sid: '6378uyhu',
//     Thumbali: 'https://img.youtube.com/vi/oWZbWzAyHAE/hqdefault.jpg',
//     summarytitle: 'How to wash hair properly LInk',
//     Publishedby: '@prabhakar',
//     channel: '@ycombinator',
//     views: '200',
//     clicks: '100',
//     actions: '',
//   },
//   {
//     sid: '6378uyhu',
//     Thumbali: 'https://img.youtube.com/vi/oWZbWzAyHAE/hqdefault.jpg',
//     summarytitle: 'How to wash hair properly LInk',
//     Publishedby: '@prabhakar',
//     channel: '@ycombinator',
//     views: '200',
//     clicks: '100',
//     actions: '',
//   },
//   {
//     sid: '6378uyhu',
//     Thumbali: 'https://img.youtube.com/vi/oWZbWzAyHAE/hqdefault.jpg',
//     summarytitle: 'How to wash hair properly LInk',
//     Publishedby: '@prabhakar',
//     channel: '@ycombinator',
//     views: '200',
//     clicks: '100',
//     actions: '',
//   },
//   {
//     sid: '6378uyhu',
//     Thumbali: 'https://img.youtube.com/vi/oWZbWzAyHAE/hqdefault.jpg',
//     summarytitle: 'How to wash hair properly LInk',
//     Publishedby: '@prabhakar',
//     channel: '@ycombinator',
//     views: '200',
//     clicks: '100',
//     actions: '',
//   },
//   {
//     sid: '6378uyhu',
//     Thumbali: 'https://img.youtube.com/vi/oWZbWzAyHAE/hqdefault.jpg',
//     summarytitle: 'How to wash hair properly LInk',
//     Publishedby: '@prabhakar',
//     channel: '@ycombinator',
//     views: '200',
//     clicks: '100',
//     actions: '',
//   },
//   {
//     sid: '6378uyhu',
//     Thumbali: 'https://img.youtube.com/vi/oWZbWzAyHAE/hqdefault.jpg',
//     summarytitle: 'How to wash hair properly LInk',
//     Publishedby: '@prabhakar',
//     channel: '@ycombinator',
//     views: '200',
//     clicks: '100',
//     actions: '',
//   },
//   {
//     sid: '6378uyhu',
//     Thumbali: 'https://img.youtube.com/vi/oWZbWzAyHAE/hqdefault.jpg',
//     summarytitle: 'How to wash hair properly LInk',
//     Publishedby: '@prabhakar',
//     channel: '@ycombinator',
//     views: '200',
//     clicks: '100',
//     actions: '',
//   },
//   {
//     sid: '6378uyhu',
//     Thumbali: 'https://img.youtube.com/vi/oWZbWzAyHAE/hqdefault.jpg',
//     summarytitle: 'How to wash hair properly LInk',
//     Publishedby: '@prabhakar',
//     channel: '@ycombinator',
//     views: '200',
//     clicks: '100',
//     actions: '',
//   },
// ];

@Component({
  selector: 'app-summaryies',
  templateUrl: './summaryies.component.html',
  styleUrls: ['./summaryies.component.css'],
})
export class SummaryiesComponent implements OnInit {
  displayedColumns: string[] = [
    'sid',
    'URL',
    'Thumbali',
    'summarytitle',
    'Publishedby',
    'channel',
    'views',
    'clicks',
    'actions',
  ];
  Language: any = [
    'English',
    'Chinese',
    'Hindi',
    'Spanish',
    'Arabic',
    'French',
    'Telugu',
    'Russian',
    'German',
    'Tamil',
    'Malayalam',
  ];
  totalSummary: any;
  dataCount: any;
  published: any;
  mostTrending: any;
  items: any[];
  queryParams: any;
  language: any = '';
  date: any = '';
  inactive: any;
  active: any;
  activeParam: { isActive: any };
  statusChangeEvent: any = true;
  loader: boolean = false;
  userQuery: {};
  collectionDelete: boolean;
  exportLanguage: string;
  languageChange: any;
  catergoryList: any;
  topicChangeParam: any = '';
  totalSummaryData: any;
  loaderexport: boolean;
  params: any;
  statusView: string;
  // dataSource = ELEMENT_DATA;
  constructor(
    private adminService: AdminService,
    private router: Router,
    private service: CollectionService
  ) {}
  status = 'Off';
  ELEMENT_DATA: Element[] = [];
  pageSize: any = 10;
  currentPage: any = 0;
  unchecked: boolean = false;
  checked: boolean = true;
  pageSizeOptions: number[] = [10, 20, 40, 100];
  dataSource = new MatTableDataSource(this.ELEMENT_DATA);

  ngOnInit(): void {
    this.summariesApiList();
    this.service.caterogoryListFeed(this.params).subscribe((res: any) => {
      this.catergoryList = res.data;
    });
  }

  summariesApiList() {
    this.loader = true;
    this.queryParams = {
      language: this.language,
      search: this.date,
      topic: this.topicChangeParam,
      // isActive: this.statusChangeEvent,
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };
    this.adminService.adminSummariesList(this.queryParams).subscribe((data) => {
      console.log('data', data);
      this.loader = false;

      this.ELEMENT_DATA = data.data;
      this.totalSummaryData = data.data;
      this.totalSummary = data.active;

      this.active = data.active;
      this.dataCount = data.dataCount;
      this.inactive = data.inactive;
      this.published = data.published;
      this.mostTrending = data.mostTrending;
      this.items = [
        { name: 'Total Summaries', number: this.totalSummary },
        { name: 'Data Count', number: this.dataCount },
        { name: 'Active', number: this.active },
        { name: 'In Active', number: this.inactive },
        // { name: 'Published', number: this.published },
        { name: 'Most trending', number: this.mostTrending },
      ];

      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    });
  }

  View(blogName: any, id: any) {
    return `/c/${blogName.split(' ').join('-')}/id-${id}`;
  }
  export() {
    this.loaderexport = true;
    this.languageChange = this.language;
    this.exportLanguage = this.changeFirstLetterToLower(this.languageChange);
    let obj = {
      language: this.exportLanguage,
      // topicName: this.topicChangeParam,
    };
    this.adminService.adminSummariesExport(obj).subscribe((res) => {
      saveAs(res, `file.csv`);
      console.log(res);
      this.loaderexport = false;
    });
  }
  // saveFile(data: any) {
  //   FileSaver.saveAs(data, 'data.csv');
  // }
  changeFirstLetterToLower(str: string): string {
    return str.charAt(0).toLowerCase() + str.slice(1);
  }

  XML() {
    this.loaderexport = true;
    this.languageChange = this.language;
    this.exportLanguage = this.changeFirstLetterToLower(this.languageChange);
    let obj = {
      language: this.exportLanguage,
      // topicName: this.topicChangeParam,
    };
    this.adminService.adminSummariesXml(obj).subscribe((res) => {
      saveAs(res, `sitemap.xml`);
      console.log(res);
      this.loaderexport = false;
    });
  }
  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;

    this.currentPage = event.pageIndex;
    let newCurrentPage = event.pageIndex + 1;
    this.queryParams = {
      language: this.language,
      search: this.date,
      isActive: this.statusChangeEvent,
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: newCurrentPage ? newCurrentPage : '',
    };
    this.adminService.adminSummariesList(this.queryParams).subscribe((data) => {
      console.log('data', data);
      this.ELEMENT_DATA = data.data;
      this.totalSummary = data.active;
      this.active = data.active;
      this.dataCount = data.dataCount;
      this.inactive = data.inactive;
      this.published = data.published;
      this.mostTrending = data.mostTrending;
      this.items = [
        { name: 'Total Summaries', number: this.totalSummary },
        { name: 'Data Count', number: this.dataCount },
        { name: 'Active', number: this.active },
        { name: 'In Active', number: this.inactive },
        // { name: 'Published', number: this.published },
        { name: 'Most trending', number: this.mostTrending },
      ];

      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    });
  }
  activetoggleChanged(e: any, item: any) {
    let obj = {
      isActive: false,
    };

    this.adminService
      .activeInactiveCollection(obj, item._id)
      .subscribe((res) => {
        item.isActive = false;
      });
  }
  inActivetoggleChanged(e: any, item: any) {
    this.activeParam = {
      isActive: true,
    };

    this.adminService
      .activeInactiveCollection(this.activeParam, item._id)
      .subscribe((res) => {
        item.isActive = true;
      });
  }
  getBackgroundColor(item: any) {
    return item.isActive ? '#2196f3' : ''; // Change color as needed
  }
  deleteCollection(id: any) {
    this.adminService.deleteCollection(id).subscribe((res) => {
      console.log('delete ', res);
      this.summariesApiList();

      this.collectionDelete = true;
      setTimeout(() => {
        this.collectionDelete = false;
      }, 3000);
    });
  }
  langugeChange(e: any) {
    this.language = e.target.value;
    this.summariesApiList();
  }
  topicChange(e: any) {
    this.topicChangeParam = e.target.value;
    this.summariesApiList();
  }
  statusChange(e: any) {
    this.statusChangeEvent = e.target.value;
    this.summariesApiList();
  }
  dateChange(e: any) {
    this.date = e.target.value;
    this.summariesApiList();
  }
  toggle(e: any) {
    console.log(e.target.checked);
  }
  statusEvent(e: any) {
    console.log(e.value);
    if (e.value != '') {
      this.statusView = JSON.stringify(e.value);
      // this.role = e.value;
    } else {
      this.statusView = '';
    }

    this.topicChangeParam = this.statusView;
    this.summariesApiList();
  }
  editCollection(collection: any) {
    this.router.navigate(['lookup-admin/summaries/Edit-Collection'], {
      queryParams: { id: collection._id },
    });
  }
}
