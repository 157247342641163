import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from 'src/app/_services/login.service';

import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';

import { LoginComponent } from '../../login-page/login/login.component';
@Component({
  selector: 'app-user-forgotpassword',
  templateUrl: './user-forgotpassword.component.html',
  styleUrls: ['./user-forgotpassword.component.css'],
})
export class UserForgotpasswordComponent implements OnInit {
  passwordChangedSuccesfully: boolean = false;
  confirmPassword: boolean = true;
  submittedUser: boolean = false;
  loginPage: boolean;
  id: any;
  resetPassword: FormGroup;
  error: any;
  loader: boolean;
  constructor(
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private service: LoginService,
    private router: Router,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<UserForgotpasswordComponent>
  ) {
    this.resetPassword = this.fb.group({
      password: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]],
      userId: [''],
    });
  }

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.queryParams.readerId;
    console.log(this.id);
    // this.dialog.open(UserForgotpasswordComponent, {
    //   disableClose: true,
    //   data: {},
    // });
  }
  close() {
    this.dialog.closeAll();
  }
  resetPasswordBtn() {
    if (this.resetPassword.invalid) {
      this.submittedUser = true;
    } else {
      this.submittedUser = false;
      this.loader = true;
      let obj = {
        password: this.resetPassword.value.password,
        confirmPassword: this.resetPassword.value.confirmPassword,
        readerId: this.id,
      };

      this.service.ResetPasswordUser(obj).subscribe(
        (res) => {
          console.log(res);
          this.passwordChangedSuccesfully = true;
          this.loader = false;

          this.confirmPassword = false;
        },
        (error) => {
          this.error = error.error.message;
          console.log(this.error);
          setTimeout(() => {
            this.error = '';
          }, 4000);
          this.loader = false;
        }
      );
    }
  }
  get resetPasswordControl(): { [key: string]: AbstractControl } {
    return this.resetPassword.controls;
  }
  // confirmPasswordBtn() {

  // }
  loginNow() {
    this.router.navigate(['/feed']);
    this.dialogRef.close();
    this.dialog.open(LoginComponent, {
      disableClose: true,
      data: {},
    });
  }
  home() {
    this.router.navigate(['/feed']);
  }
}
