import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CollectionService } from 'src/app/_services/collection.service';
import { SuccessDialogComponent } from '../success-dialog/success-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-update-categories',
  templateUrl: './update-categories.component.html',
  styleUrls: ['./update-categories.component.css'],
})
export class UpdateCategoriesComponent implements OnInit {
  catergoryParams: any;
  catergoryList: any;
  catergoriesFormGroup: FormGroup;
  sessionType: any;
  btnDisabled: boolean = true;
  succesfully: boolean = false;
  name: any;
  private _id: any;
  categoryItem: any;

  isAdded: any = [
    { item: 'Active', boolean: true },
    { item: 'Inactive', boolean: false },
  ];
  profile: null;
  url: any;
  selectedFile: File;
  subCatergoryList: string;
  subCatergory: any = '';
  errorView: boolean;
  errorMesseage: string;
  uploadProgress: number = 0;
  uploadLoader: boolean = false;
  subtopicsInput: string = '';
  subtopics: any = [];
  topicName: any;
  constructor(
    private service: CollectionService,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private dilaog: MatDialog
  ) {
    this.catergoriesFormGroup = this.fb.group({
      categoryName: [''],
      isAdded: [''],
      redditURL: [''],
      redditMessage: [''],
      description: [''],
      ctaText: [''],
      title: [''],
      categoryImage: [''],
      subCategories: [''],
    });
  }

  ngOnInit(): void {
    this._id = this.activatedRoute.snapshot.queryParams.id;
    this.name = this.activatedRoute.snapshot.queryParams.name;
    console.log('Id', this._id);
    if (this.service.getToken() != '') {
      this.service.loginUserDetails().subscribe((result) => {
        this.sessionType = result.data.userType;
      });
    }
    if (this.service.getToken() == '') {
      // console.log('not-login');
      this.sessionType = 'home';
    }
    this.service.allCatergory().subscribe((res: any) => {
      // console.log(res);
      var feed = res.data;
      this.catergoryList = res.data;
      this.categoryItem = this.catergoryList.filter((item: any) => {
        return item._id == this._id;
      });
      console.log(this.categoryItem);

      // console.log(this.categoryItem.includes(this.categoryItem[0].subTopics));
      // if (this.categoryItem.includes(this.categoryItem[0]?.subTopics)) {
      //   console.log('Value exists');
      // } else {
      //   this.categoryItem.forEach((item: any) => {
      //     item.subTopics = [];
      //   });
      //   console.log('Value does not exist');
      // }
      const fieldNameToCheck = 'subTopics';
      if (
        this.categoryItem.find((item: any) =>
          item.hasOwnProperty(fieldNameToCheck)
        )
      ) {
        console.log('Field exists');
      } else {
        console.log('Field does not exist');
        this.categoryItem.forEach((item: any) => {
          item.subTopics = [];
        });
      }
      this.topicName = this.categoryItem[0].name;
      this.url = this.categoryItem[0].topicImage;
      this.catergoriesFormGroup.controls['description'].setValue(
        this.categoryItem[0].description
      );
      this.catergoriesFormGroup.controls['categoryName'].setValue(
        this.categoryItem[0].name
      );
      this.catergoriesFormGroup.controls['title'].setValue(
        this.categoryItem[0].title
      );
      this.catergoriesFormGroup.controls['isAdded'].setValue(
        this.categoryItem[0].isAdded
      );
      this.catergoriesFormGroup.controls['subCategories'].setValue(
        this.categoryItem[0].subTopics
      );
      this.catergoriesFormGroup.controls['ctaText'].setValue(
        this.categoryItem[0].ctaText
      );
      this.catergoriesFormGroup.controls['redditMessage'].setValue(
        this.categoryItem[0].redditMessage
      );
      this.catergoriesFormGroup.controls['redditURL'].setValue(
        this.categoryItem[0].redditURL
      );
      this.subtopics = this.categoryItem[0]?.subTopics;

      // console.log(this.subtopics);
    });
  }
  onFileSelectedEvent(event: any): void {
    this.uploadLoader = true;
    const file: File = event.target.files[0];
    const maxSizeKB = 100; // Maximum file size in kilobytes
    if (file) {
      const fileSizeKB = file.size / 1024; // Calculate file size in kilobytes
      this.uploadProgress = 0;
      if (fileSizeKB > maxSizeKB) {
        // Show an error message when the file is too large
        this.errorView = true;
        this.errorMesseage = `File size should be less than ${maxSizeKB}KB. Selected file size is ${fileSizeKB.toFixed(
          2
        )}KB.`;
      } else {
        const file = event.target.files && event.target.files[0];
        this.selectedFile = <File>event.target.files[0];

        if (event.target.files.length > 0) {
          this.errorView = false;

          const file = event.target.files[0];
          this.catergoriesFormGroup.controls['categoryImage'].setValue(file);
        }
        var reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = (event) => {
          const interval = setInterval(() => {
            if (this.uploadProgress < 100) {
              this.uploadProgress += 50; // Increase progress by 10% every second
              if (this.uploadProgress == 100) {
                this.url = (<FileReader>event.target).result;
                console.log(this.url);
              }
            } else {
              clearInterval(interval); // Stop interval when progress reaches 100%
            }
          }, 1000);
        };
      }
    }
  }
  handleInputClick(e: any) {
    console.log(e.target.value);
    if (e.target.value != '') {
      this.btnDisabled = false;
    }
  }

  addSubtopics() {
    if (this.subtopicsInput.trim()) {
      const newSubtopics = this.subtopicsInput
        .split(',')
        .map((subtopic) => subtopic.trim());
      this.subtopics.reverse().push(...newSubtopics);
      this.subtopicsInput = ''; // Clear input field after adding subtopics
    }
  }
  remveSubtopic(i: any) {
    this.subtopics.reverse().splice(i, 1);
  }
  subCategory(e: any) {
    this.subCatergoryList = e.target.value;
    // console.log(e.target.value);

    if (
      this.subCatergoryList != '' ||
      this.subCatergoryList != null ||
      this.subCatergoryList != undefined
    ) {
      this.subCatergory =
        this.catergoriesFormGroup.value.subCategories.split(',');
      console.log(this.subCatergory);
    } else {
      this.subCatergory = '';
      console.log(JSON.stringify(this.subCatergory));
    }
  }
  update(item: any, i: any) {
    console.log(i);
    // this.topicName = this.catergoriesFormGroup.value.title;
    const formData = new FormData();
    formData.append(
      'topicImage',
      this.catergoriesFormGroup.controls['categoryImage'].value
    );
    formData.append('title', this.catergoriesFormGroup.value.title);

    formData.append('subTopics', JSON.stringify(this.subtopics));

    formData.append('description', this.catergoriesFormGroup.value.description);
    formData.append('isAdded', this.catergoriesFormGroup.value.isAdded);
    formData.append(
      'redditMessage',
      this.catergoriesFormGroup.value.redditMessage
    );
    formData.append('redditURL', this.catergoriesFormGroup.value.redditURL);
    formData.append('ctaText', this.catergoriesFormGroup.value.ctaText);
    this.service.editCategroy(formData, item._id).subscribe((res) => {
      console.log(res);
      this.dilaog.open(SuccessDialogComponent, {
        data: {
          title: this.topicName,
          create: false,
          update: true,
          disableClose: true,
        },
      });

      this.catergoriesFormGroup.reset();
      this.subtopics = [];
      this.url = null;
      this.uploadLoader = false;
    });
  }
}
