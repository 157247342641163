
<div class="main">
  <app-usernavbar></app-usernavbar>
  
    <div class="container mt-md-5">
        <div class="collectionall-header ml-md-5 mb-md-3">
           <h5>Saved Bookmark Collection</h5>
        </div>
        <div class="row">
 <div class="col-md-4" *ngFor="let list of collectionList">
                <div class="collection-prodcuts mt-md-4 pb-md-4 ml-lg-3 pointer">
                     <!-- <img src="{{
                              'https://img.youtube.com/vi/' +
                                list.collection[0].sourceURL.split('=').pop() +
                                '/maxresdefault.jpg'
                            }}" (click)="collection(list.collection[0].collectionName,list.collection[0]._id)"> -->

                             <img *ngIf="list?.collection[0]?.thumbNail != '' && list?.collection[0]?.customThumbNail == null" src="{{list?.collection[0]?.thumbNail}}" (click)="collection(list.collection[0]?.collectionName,list.collection[0]._id)" class="c-img-1" loading="lazy" alt={{list.collectionName}}>
                  <img *ngIf="list?.collection[0]?.customThumbNail != '' && list?.collection[0]?.thumbNail == null" src="{{list?.collection[0]?.customThumbNail}}"  (click)="collection(list.collection[0]?.collectionName,list.collection[0]._id)" class="c-img-1" loading="lazy" alt="customThumbnNail">
                   
                     <P class="pt-md-2" (click)="collection(list.collection[0]?.collectionName,list.collection[0]._id)">{{list.collection[0]?.collectionName}}</P>
                </div>
             </div>
        </div>
          
    </div>
</div>

 <app-footer ></app-footer>
