import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PublisheronboardingModelComponent } from '../publisheronboarding-model/publisheronboarding-model.component';
import { CollectionService } from 'src/app/_services/collection.service';
declare const phyllo: any;
import { v4 as uuid } from 'uuid';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from 'src/app/_services/admin.service';
import { Unsub } from 'src/app/_models/unsub.class';
import { takeUntil } from 'rxjs/operators';
import { PageEvent } from '@angular/material/paginator';
import { productFruits } from 'product-fruits';
import { PublisherOnboardingDialogComponent } from '../publisher-onboarding-dialog/publisher-onboarding-dialog.component';
import { PublisherOnboardComponent } from '../publisher-onboard/publisher-onboard.component';
@Component({
  selector: 'app-publisher-blogs',
  templateUrl: './publisher-blogs.component.html',
  styleUrls: ['./publisher-blogs.component.css'],
})
export class PublisherBlogsComponent implements OnInit {
  loader: boolean = false;
  p: number = 1;
  activeState: any = 'all';
  languageButtons: any = [
    { name: 'ALL', value: 'all' },
    { name: 'ENGLISH', value: 'english' },
    { name: 'HINDI', value: 'hindi' },
    { name: 'CHINESE', value: 'chinese' },
    { name: 'GERMAN', value: 'german' },
    { name: 'RUSSIAN', value: 'russian' },
    { name: 'SPANISH', value: 'spanish' },
    { name: 'TAMIL', value: 'tamil' },
    { name: 'ARABIC', value: 'arabic' },
    { name: 'MALAYALAM', value: 'malayalam' },
    { name: 'TELUGU', value: 'telugu' },
  ];
  pageSizeOptions: number[] = [10, 20, 40, 100];
  pageSize: any = 10;
  currentPage: any = 0;

  publisherBlogs: any;
  ChannelName: any;
  account_id: string | null;
  sessionType: any;
  userTypeId: any;
  token1: any;
  refreshed: boolean;
  refreshSubscription: any;
  params: {};
  language: any = '';
  totalSummary: any;
  userName: string = 'Mahesh';
  PublisherOnboarding: any;
  onboarding: any;
  totalSummaryCount: any;
  constructor(
    private dialog: MatDialog,
    private service: CollectionService,
    private Publisherservice: AdminService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.refreshSubscription = this.service.refresh$.subscribe(() => {
      this.refreshed = true;
      // Perform any other action you need on refresh
      console.log('refreshing subscription');

      if (localStorage.getItem('token') != null) {
        this.service.creatorChannel().subscribe((res) => {
          this.ChannelName = res.data;
          console.log(this.ChannelName);
        });
      }

      // Reset the refresh flag after a delay (e.g., 2 seconds)
      setTimeout(() => {
        this.refreshed = false;
      }, 2000);
    });
  }

  ngOnInit(): void {
    // this.dialog.open(PublisherOnboardComponent);

    this.token1 = this.activatedRoute.snapshot.queryParams.token;
    if (this.token1) {
      localStorage.setItem('token', this.token1);
      this.service.loginUserDetails().subscribe((result) => {
        this.sessionType = result.data.userType;
        this.userTypeId = result.data._id;
        this.onboarding = result.data.onboarding;
        this.userName = result.data.name;
        localStorage.setItem('userType', this.sessionType);
      });
    }

    if (localStorage.getItem('token') != null) {
      this.service.creatorChannel().subscribe((res: { data: any }) => {
        this.ChannelName = res.data;
        console.log(this.ChannelName);
      });

      this.publisherBLogs();
      this.totalSummary = localStorage.getItem('publisherBlogsCount');
    }

    this.account_id = localStorage.getItem('accountId');
    console.log(this.account_id);
    // this.service.loginUserDetails().subscribe((result) => {
    //   this.sessionType = result.data.userType;
    //   this.userTypeId = result.data._id;
    //   this.onboarding = result.data.onbording;
    //   this.userName = result.data.name;
    //   localStorage.setItem('userType', this.sessionType);

    //   if (this.onboarding) {
    //     this.dialog.open(PublisherOnboardComponent, {
    //       data: {
    //         name: this.userName,
    //       },
    //     });
    //   }
    // });
  }

  publisherBLogs() {
    this.params = {
      language: this.language,
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };

    this.Publisherservice.PublisherBlogsList(this.params).subscribe((res) => {
      console.log(res);
      this.publisherBlogs = res.totalSummary;
      this.totalSummary = res.totalData;
      this.totalSummaryCount = res.totalData;
      localStorage.setItem('publisherBlogsCount', this.totalSummaryCount);
    });
  }
  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;

    this.currentPage = event.pageIndex;
    let newCurrentPage = event.pageIndex + 1;
    // this.currentPage = newCurrentPage
    console.log(this.currentPage);
    this.loader = true;
    this.params = {
      language: this.language,
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: newCurrentPage ? newCurrentPage : '',
    };

    this.Publisherservice.PublisherBlogsList(this.params).subscribe((res) => {
      console.log(res);
      this.publisherBlogs = res.totalSummary;
      this.totalSummary = res.totalData;
    });
  }
  ViewBtn() {
    this.router.navigate([`/${this.ChannelName[0].name}`]);
  }
  View(blogName: any, id: any) {
    return `/c/${blogName.split(' ').join('-')}/id-${id}`;
  }
  GetStarted() {
    const dialogRef = this.dialog.open(PublisheronboardingModelComponent);

    dialogRef.afterClosed().subscribe(() => {
      this.service.triggerRefresh();
    });
  }

  changelanguage(item: any) {
    console.log('changelanguage', item);
    this.activeState = item;

    if (item == 'all') {
      this.params = {
        language: this.language,
        pageLimit: this.pageSize ? this.pageSize : '',
        pageNumber: this.currentPage ? this.currentPage : '',
      };
    } else {
      this.params = {
        language: item,
        pageLimit: this.pageSize ? this.pageSize : '',
        pageNumber: this.currentPage ? this.currentPage : '',
      };
    }

    this.Publisherservice.PublisherBlogsList(this.params).subscribe((res) => {
      console.log(res);
      this.publisherBlogs = res.totalSummary;
      this.totalSummary = res.totalData;
    });
  }
}
