<!-- <div class="main">
  <app-usernavbar></app-usernavbar>
<app-loader *ngIf="singupLoader" ></app-loader>

  <div class="container-fluid">
    <div class="login-section ">
      <div class="row flex-column-reverse flex-md-row">
        <div class="col-md-6 " id="signup-para">
          <div class="login-section-common-data pl-md-4 pt-md-5">
            <div class="common-data-login">
              <h4>Never forget what you learned from YouTube Videos
            
              </h4>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="login-form-section">
            <div class="email-section" *ngIf="signUpWithEmail">
              <div class="form-section">
                <h4 class="ml-md-3  mt-md-2 pb-md-2">Sign up for free</h4>
                <div id="bullet-points">
                  <div class="d-flex points mt-3">
                    <img src="../../assets/images/signup-check.png" class="check-1">
                    <p class="ml-2">Never forget what you learned from videos</p>
                  </div>
                  <div class="d-flex points mt-2">
                    <img src="../../assets/images/signup-check.png" class="check-1">
                    <p class="ml-2">Increase your knowledge on any topic</p>
                  </div>
                  <div class="d-flex points mt-2">
                    <img src="../../assets/images/signup-check.png" class="check-1">
                    <p class="ml-2">Read, remember, retain video information in the form of text summaries</p>
                  </div>
                  <div class="d-flex points mt-2">
                    <img src="../../assets/images/signup-check.png" class="check-1">
                    <p class="ml-2">Quit taking notes manfully from youtube videos. AI will do it for you</p>
                  </div>
                </div>
                <div class="common-data-login" id="moblie-view">
                  <div>
                    <div class="d-flex points mt-3">
                      <img src="../../assets/images/signup-check.png" class="check-1">
                      <p class="ml-2">Never forget what you learned from Youtube videos</p>
                    </div>
                    <div class="d-flex points mt-2">
                      <img src="../../assets/images/signup-check.png" class="check-1">
                      <p class="ml-2">Read, remember, retain video information in the form of text summaries</p>
                    </div>
                    <div class="d-flex points mt-2">
                      <img src="../../assets/images/signup-check.png" class="check-1">
                      <p class="ml-2">Quit taking notes manfully for youtube videos. AI will do it for you</p>
                    </div>
                  </div>
                </div>
                <div class="d-md-flex mt-md-4">
                  <form [formGroup]="signUpEmail">
                    <div class="email-form-section ml-md-4 pl-md-2">
                      <input (keydown.space)="$event.preventDefault();" class="form-control" formControlName="email" placeholder="Enter email address">
                      <div *ngIf="signUpEmailError.email.errors?.required && submitted" class="invalid-feedback m-t d-block"> Email is required </div>
                      <div *ngIf="signUpEmailError.email.errors?.email && submitted" class="invalid-feedback m-t d-block"> Email is invalid </div>
                    </div>
                  </form>
                  <div class="btn-signin ">
                    <button class="pointer" (click)="signUp()" type="submit">Sign up for free</button>
                  </div>
                </div>
                <div class='google-intergration mt-4 ml-md-4 pl-md-2'>
                  <p class="py-md-2">OR</p>
                  <div class="google-btn ">
                    <button type="submit">
                      <a [href]="url | safePipe">
                        <img src="../../assets/images/google.png" class="google mr-3">Sign up via Google </a>
                    </button>
                    <h6>Already have an account? <span class="pointer">
                        <a (click)="loginNow()">Login</a>
                      </span>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            <div class="name-section ml-md-4" *ngIf="signUpWithName">
              <div class="form-section mt-md-4">
            
                <form [formGroup]="SignUpUser">
                  <div class="back-img" (click)="backBtn()">
                    <img src="../../../assets/images/back.png">
                  </div>
                  <div class="email-form-section mt-md-3">
                    <input class="form-control" formControlName="name" placeholder="Enter your name">
                    <div *ngIf="signUpNameError.name.errors?.required && submittedUser" class="invalid-feedback m-t d-block"> Name is required </div>
                  </div>
                  <div class="title price-select mt-md-4 pt-md-1">
                    <select formControlName="gender">
                      <option value="" selected hidden disabled>Select gender</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </select>
                    <div *ngIf="signUpNameError.gender.errors?.required && submittedUser" class="invalid-feedback m-t d-block"> Select your gender </div>
           
                  </div>
                  <div class="password-form-section mt-md-4 pt-md-1">
                    <input type="password" class="form-control" formControlName="password" placeholder="Create your password">
                    <div *ngIf="signUpNameError.password.errors?.required && submittedUser" class="invalid-feedback m-t d-block"> Password is required </div>
                  </div>
                  
                  <div *ngIf="signUpNameError.languages.errors?.required && submittedUser" class="invalid-feedback m-t d-block"> Select language </div>
                  <div *ngIf="signupErrorMessage != null" class="invalid-feedback m-t d-block">
                    {{signupErrorMessage}}
                  </div>
                </form>
              </div>
              <div class="sign-in-section mt-md-2 pt-md-2">
                <div class="d-flex">
                  <div class="btn-signin">
                    <button class="pointer" (click)="login()" type="submit">SIGN UP</button>
                  </div>
                </div>
              </div>
              <div class='google-intergration'>
                <p class="py-md-4">OR</p>
                <div class="google-btn">
                  <button type="submit">
                    <a [href]="url | safePipe">
                      <img src="../../assets/images/google.png" class="google mr-md-3">Sign up via Google </a>
                  </button>
                </div>
                <h6>Already have an account? <span class="pointer">
                    <a (click)="loginNow()">Login</a>
                  </span>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->


<app-loader *ngIf="singupLoader"></app-loader>
<div class="main">
  
           
           <div class="ml-md-4">
<div class="name_form_section" *ngIf="signUpWithEmail">
<div class="d-flex">
 <div class="signup_1">
              <h4>Create your free account to start reading unlimted free summaries 
            
              </h4>
            </div>
            <div class="close_img cursor" (click)="close()">
              <img src="../../../assets/images/close-m.svg">
            </div>
           </div>
          <form [formGroup]="signUpEmail">
                    <div class="email-form-section ">
                      <input (keydown.space)="$event.preventDefault();"  (input)="validateEmail($event)" class="form-control" formControlName="email" placeholder="Enter email address">
                      <div *ngIf="signUpEmailError.email.errors?.required && submitted" class="invalid-feedback m-t d-block"> Email is required </div>
                      <div *ngIf="signUpEmailError.email.errors?.email && submitted" class="invalid-feedback m-t d-block"> Email is invalid </div>
                       <div *ngIf="ErrorMessage != ''" class="invalid-feedback m-t d-block"> {{ErrorMessage}}
                        <a (click)="loginNow()" *ngIf="ErrorMessage.includes('existing')" class="text-decoration-underline">Login</a> </div>
                    </div>
                     <div class="email-form-section  mt-md-3">
                    <input class="form-control" formControlName="name" placeholder="Enter your name">
                    <div *ngIf="signUpEmailError.name.errors?.required && submitted" class="invalid-feedback m-t d-block"> Name is required </div>
                  </div>
                  </form>
         </div>
         <div class="password_form_section" *ngIf="signUpWithName">
           <div class="back_img cursor" (click)="backBtn()">
              <img src="../../../assets/images/back.png">
            </div>
            
            <div class="d-flex">
 <div class="signup_1">
               <h4>One more step to complete your signup
            
              </h4>
            </div>
            <div class="close_img cursor" (click)="close()">
              <img src="../../../assets/images/close-m.svg" class="img_1">
            </div>
           </div>
          <form [formGroup]="SignUpUser">
            <p>Select your gender</p>
             <mat-radio-group aria-label="Select an option" formControlName="gender">
  <mat-radio-button disableRipple="true" value="Male">Male</mat-radio-button>
  <mat-radio-button disableRipple="true" value="Female">Female</mat-radio-button>
  <mat-radio-button disableRipple="true" value="others">others</mat-radio-button>
</mat-radio-group>
                    <div class="password-form-section mt-md-4 pt-md-1">
                    <input type="password" class="form-control" formControlName="password" placeholder="Create your password">
                    <div *ngIf="signUpNameError.password.errors?.required && submittedUser" class="invalid-feedback m-t d-block"> Password is required </div>
                     
                  </div>
                  
                 
                      
                  </form>
         </div>

         <div class="section_signUp">
             <p class="terms">By signup for summarizly.ai,i agree to the <span class="text-decoration-underline">terms and conditions</span></p>
 <div class="btn-signin"  *ngIf="signUpWithEmail">
                    <button class="pointer" (click)="signUp()" type="submit">Continue</button>
                  </div>
 <div class="btn-signin"  *ngIf="signUpWithName">
                    <button class="pointer" (click)="login()" type="submit">Sign up now</button>
                  </div>
                   <div class='google-intergration'  *ngIf="signUpWithEmail">
                <p>OR</p>
                <div class="google-btn">
                  <button type="submit">
                    <a [href]="url | safePipe">
                      Sign up via Google </a>
                  </button>
                </div>
                <h6>Already have an account? <span class="pointer">
                    <a (click)="loginNow()">Login</a>
                  </span>
                </h6>
              </div>
         </div>
           </div>
         
</div>