import { Component, OnInit } from '@angular/core';
import { CollectionService } from 'src/app/_services/collection.service';

@Component({
  selector: 'app-publisherhomepage',
  templateUrl: './publisherhomepage.component.html',
  styleUrls: ['./publisherhomepage.component.css'],
})
export class PublisherhomepageComponent implements OnInit {
  showLogin: boolean = true;
  sidebarOpen: boolean = true;
  notificationsOpen: boolean = false;
  showDashboard: boolean = false;
  sessionType: any;
  constructor(private service: CollectionService) {}

  ngOnInit(): void {}
  ngDoCheck(): void {
    if (localStorage.getItem('userType') == 'publisher') {
      this.showDashboard = true;
      this.showLogin = false;
    } else {
      this.showLogin = true;
      this.showDashboard = false;
    }
  }
}
