import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { AdminService } from 'src/app/_services/admin.service';
import { CollectionService } from 'src/app/_services/collection.service';
import Swal from 'sweetalert2';
export interface PeriodicElement {
  commentid: any;
  summaryid: any;
  summarytitle: any;
  Commentdetails: any;
  Spam: any;
  offensive: any;
  Commented_by: any;
  Publisher_id: any;
  Action: any;
}

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.css'],
})
export class CommentsComponent implements OnInit {
  displayedColumns: string[] = [
    'commentid',
    'summaryid',
    'summarytitle',
    'Commentdetails',
    'Spam',
    'offensive',
    'Commented_by',
    'Publisher_id',
    'Action',
  ];
  Language: any = ['Spam', 'Offensive'];
  loader: boolean;
  userQuery: {};
  language: any = '';
  ELEMENT_DATA: Element[] = [];
  pageSize: any = 10;
  currentPage: any = 0;
  unchecked: boolean = false;
  checked: boolean = true;
  pageSizeOptions: number[] = [10, 20, 40, 100];
  totalSummary: any;
  newUser: any;
  userDataCount: any;
  items: { name: string; number: number }[];
  dataSource = new MatTableDataSource(this.ELEMENT_DATA);
  userDelete: boolean;
  userData: any;
  totalCommentReplyCount: number;
  paramValue: boolean;
  SpamparamValue: any = '';
  offensiveparamValue: any = '';
  constructor(
    private adminService: AdminService,
    private collectionService: CollectionService
  ) {}

  ngOnInit(): void {
    this.userApisList();
  }

  userApisList() {
    this.loader = true;

    this.userQuery = {
      spam: this.SpamparamValue,
      offensive: this.offensiveparamValue,
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };

    this.adminService.adminCommentsList(this.userQuery).subscribe((result) => {
      console.log(result);
      console.log('data', result);
      this.loader = false;

      this.ELEMENT_DATA = result.data;
      this.userData = result.userData;
      this.totalSummary = result.totalComment;
      this.newUser = result.totalMarkAsSpamCount;
      this.userDataCount = result.totalOffensiveCount;
      this.totalCommentReplyCount = result.totalCommentReplyCount;
      this.items = [
        { name: 'Total Comments', number: this.totalSummary },
        { name: 'Spam', number: this.newUser },
        { name: 'Offensive', number: this.userDataCount },
        { name: 'Replies', number: this.totalCommentReplyCount },
      ];

      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    });
  }
  showFullText = false;

  toggleText(item: any) {
    // this.showFullText = !this.showFullText;
    Swal.fire({
      title: 'Comment',
      text: item,
      showConfirmButton: true, // Hide the "OK" button
    });
  }
  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;

    this.currentPage = event.pageIndex;
    let newCurrentPage = event.pageIndex + 1;
    console.log(this.currentPage);
    this.loader = true;
    this.userQuery = {
      spam: this.SpamparamValue,
      offensive: this.offensiveparamValue,
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: newCurrentPage ? newCurrentPage : '',
    };
    this.adminService.adminCommentsList(this.userQuery).subscribe((result) => {
      console.log(result);
      console.log('data', result);
      this.loader = false;

      this.ELEMENT_DATA = result.data;
      this.userData = result.userData;
      this.totalSummary = result.totalComment;
      this.newUser = result.totalMarkAsSpamCount;
      this.userDataCount = result.totalOffensiveCount;
      this.totalCommentReplyCount = result.totalCommentReplyCount;
      this.items = [
        { name: 'Total Comments', number: this.totalSummary },
        { name: 'Spam', number: this.newUser },
        { name: 'Offensive', number: this.userDataCount },
        { name: 'Replies', number: this.totalCommentReplyCount },
      ];

      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    });
  }
  deleteCommets(item: any) {
    console.log(item);
    let obj = {
      commentId: item._id,
    };
    this.adminService.adminDeleteComment(obj).subscribe((response) => {
      this.userApisList();
      Swal.fire({
        text: 'Comment delete succefully',
        showConfirmButton: true, // Hide the "OK" button
        icon: 'success', // You can change the icon type
        timer: 2000, // Set the timer to 3000 milliseconds (3 seconds)
      });
    });
  }
  langugeChange(e: any) {
    this.language = e.target.value;
    console.log(this.language);
    if (this.language == 'Spam') {
      this.SpamparamValue = true;
      this.offensiveparamValue = false;
    } else if (this.language == 'Offensive') {
      this.offensiveparamValue = true;
      this.SpamparamValue = false;
    } else if (this.language == '') {
      this.offensiveparamValue = false;
      this.SpamparamValue = false;
    }
    this.userApisList();
  }
  toggleChanged(e: any) {
    console.log('toggl', e.target.checked);
  }
}
