<app-usernavbar></app-usernavbar>

<app-loader *ngIf="loader"></app-loader>
<div class="bookmarks-main" *ngIf="collectionList">
  <div class="bookmark_wrapper">
    <div class="bookmark_header">
      <h4>Your Bookmarks</h4>

      <div class="d-flex scroll mx-md-5 mb-3">
        <button
          class="category-btns pointer"
          [class.all]="items.name == activeState"
          *ngFor="let items of categoriesData"
          (click)="navClick(items)"
        >
          {{ items }}
        </button>
      </div>

      <ngx-slick-carousel
        class="carousel"
        #slickModal="slick-carousel"
        [config]="slideConfig"
        (init)="slickInit($event)"
      >
        <div class="slide" ngxSlickItem *ngFor="let item of collectionList">
          <div class="card card-view">
            <div class="card-body">
              <div class="card-details-view">
                <img
                  class="thumb"
                  [src]="item.thumbNail"
                  *ngIf="item.thumbNail != null"
                />
                <img
                  class="thumb"
                  src="../../assets/images/no-image (1).jpg"
                  *ngIf="item.thumbNail == null"
                />
                <div class="d-flex">
                  <h3>{{ item.summaryName }}</h3>
                  <div class="image-container cursor">
                    <img
                      src="../../assets/images/bookmark.png"
                      (click)="unfollowBookmark(item)"
                      class="img-book"
                    />
                    <p class="image-text">remove</p>
                  </div>
                </div>
                <p>
                  Publisher by <span>{{ item.publisherName }}</span>
                </p>
                <h6 class="summary-text" [innerHTML]="item.Summary"></h6>
                <h4>
                  Topics:<span *ngFor="let t of item?.topics">#{{ t }}</span>
                </h4>
                <div class="d-flex">
                  <h1>Tell a friend about this</h1>
                  <a
                    class="cursor ml-auto"
                    href="/c/{{
                      item?.summaryName?.split(' ')?.join('-')
                    }}/id-{{ item._id }}"
                    >Read more</a
                  >
                </div>
                <!-- <a class="mt-3">www.summarizly.ai/beauty</a> -->
              </div>
            </div>
          </div>
        </div>
      </ngx-slick-carousel>

      <h5 *ngIf="collectionList == ''">No summary found</h5>
    </div>
  </div>
</div>
