import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { CollectionService } from 'src/app/_services/collection.service';
import { LoginService } from 'src/app/_services/login.service';
import { environment } from 'src/environments/environment';
var emailPhonePattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$';
@Component({
  selector: 'app-power-user-signup',
  templateUrl: './power-user-signup.component.html',
  styleUrls: ['./power-user-signup.component.css'],
})
export class PowerUserSignupComponent implements OnInit {
  signUpWithEmail: boolean = true;
  signUpWithName: boolean = false;
  submitted: boolean = false;
  submittedUser: boolean = false;
  signUpEmail: FormGroup;
  SignUpUser: FormGroup;
  userName: any;
  userType: any;
  url: any = `${environment.AUTH_URL}auth/google`;

  userId: any;
  error: any;
  constructor(
    private router: Router,
    private loginService: LoginService,
    private fb: FormBuilder,
    private collectionsservice: CollectionService
  ) {
    this.signUpEmail = this.fb.group({
      email: ['', [Validators.required, Validators.pattern(emailPhonePattern)]],
    });
    this.SignUpUser = this.fb.group({
      name: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {}
  signUp() {
    if (this.signUpEmail.invalid) {
      this.submitted = true;
    } else {
      this.submitted = false;
      this.signUpWithName = true;
      this.signUpWithEmail = false;
    }
  }
  loginNow() {
    this.router.navigate(['/PowerUser/login-batman']);
  }
  login() {
    if (this.SignUpUser.invalid) {
      this.submittedUser = true;
    } else {
      this.submittedUser = false;
      console.log(this.SignUpUser.value);
      let obj = {
        name: this.SignUpUser.value.name,
        email: this.signUpEmail.value.email,
        password: this.SignUpUser.value.password,
      };
      console.log(obj);
      this.loginService.signUp(obj).subscribe(
        (result) => {
          console.log(result);
          localStorage.setItem('token', result.token);
          this.router.navigate(['/Poweruserhome-batman']);
          this.collectionsservice.loginUserDetails().subscribe((result) => {
            this.userName = result.data.name;
            this.userType = result.data.userType;
            this.userId = result.data._id;
            // localStorage.setItem('userName', this.userName);
            localStorage.setItem('userType', this.userType);
            localStorage.setItem('ID', this.userId);
          });
        },
        (error) => {
          this.error = error.error.message;
          setTimeout(() => {
            this.error = '';
          }, 3000);
        }
      );
    }
  }
  get signUpEmailError(): { [key: string]: AbstractControl } {
    return this.signUpEmail.controls;
  }
  get signUpNameError(): { [key: string]: AbstractControl } {
    return this.SignUpUser.controls;
  }
}
