

<div class="sidenavbar-heade">
<!-- <div class='row'>
    <div class="col-md-2">
    <app-sidenavbar></app-sidenavbar>

    </div> -->
    <div class="summary-header ml-3 mt-md-3">
     <!-- <div class="d-flex justify-content-end">
       <div class="d-flex   language-header">
            <p>language</p>
            <select (change)="langugeChange($event)"  class="selectpicker" >
              <option value="">select</option>
                <option *ngFor="let item of Language" value="{{item}}">{{item}}</option>
            </select>
        </div>
     </div> -->

     <div class="card-section my-md-4 d-flex justify-content-start">
        <div class="card mr-md-5" *ngFor="let item of items">
            <div class="card-body">
                <p>{{item.name}}</p>
                <h3>{{item.number}}</h3>
            </div>
        </div>
     </div>
      <!-- <p class="succesfully" *ngIf="userDelete">User delete successfully</p> -->
     <div class="table-data">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
  <ng-container matColumnDef="sid">
    <th mat-header-cell *matHeaderCellDef> sid </th>
    <td mat-cell *matCellDef="let element"> {{element._id}} </td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="Thumbali">
    <th mat-header-cell *matHeaderCellDef> Name </th>
    <td mat-cell *matCellDef="let element"> {{element.name}}</td>
  </ng-container>

  <!-- Weight Column -->
  <ng-container matColumnDef="summarytitle">
    <th mat-header-cell *matHeaderCellDef> Email </th>
    <td mat-cell *matCellDef="let element"> {{element.email}} </td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="Publishedby">
    <th mat-header-cell *matHeaderCellDef> date </th>
    <td mat-cell *matCellDef="let element"> {{element.createdAt | date : "mediumDate"}} </td>
  </ng-container>
  <!-- Weight Column -->
  <!-- <ng-container matColumnDef="channel">
    <th mat-header-cell *matHeaderCellDef> Interests </th>
    <td mat-cell *matCellDef="let element"> 
      <div class="categroy" *ngFor="let item of element.topicData">
     <h4 >{{item.name ? item.name : 'N/A'}},</h4> 

      </div>
    
    </td>
  </ng-container> -->

  <!-- Symbol Column -->
  <ng-container matColumnDef="views">
    <th mat-header-cell *matHeaderCellDef> Total summary created </th>
    <td mat-cell *matCellDef="let element"> {{element.totalSummaryCount}} </td>
  </ng-container>
  <!-- Weight Column -->
  <!-- Symbol Column -->
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef> Status </th>
    <td mat-cell *matCellDef="let element"> {{element.status}} </td>
  </ng-container>
  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef> Action </th>
    <td mat-cell *matCellDef="let element">  
      <button mat-icon-button mat-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
              <mat-icon>
                <img src="../../assets/images/dots.png" id="users-total"
                  class="action-dots" /></mat-icon>
              <div #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu" place></div>
            </button>
            <mat-menu #menu="matMenu" xPosition="before">
              <div   >
              <button class="View" mat-menu-item value="Edit" *ngIf="element.status == 'Have access'" (click)="removeAcess('false',element._id)">
                <!-- <img src="../../assets/images/panel_images/view.svg" class="mr-3" /> -->
                <span>Remove access</span>
              </button>
            </div>
              <div   >
                <button class="View" mat-menu-item value="Edit"*ngIf="element.status == 'Access removed'"    (click)="removeAcess('true',element._id)">
                <!-- <img src="../../assets/images/panel_images/view.svg" class="mr-3" /> -->
                <span>Give access</span>
              </button>
              </div>
              
              
              
              
            </mat-menu>
           </td>
  </ng-container>
  <!-- Weight Column -->
  <!-- <ng-container matColumnDef="clicks">
    <th mat-header-cell *matHeaderCellDef> Bookmarks </th>
    <td mat-cell *matCellDef="let element"> {{element.bookmarkDataCount}} </td>
  </ng-container> -->

  <!-- Symbol Column -->
  <!-- <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef> actions </th>
    <td mat-cell *matCellDef="let element"> <div class="d-flex">
       
         <div class="edit-img">
           <a>
            <img class="cursor"  src="../../../assets/images/delete.png" (click)="deleteUser(element._id)">
            </a>
           
        </div>
       
       </div></td>
  </ng-container> -->

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
  <p class="no-user" *ngIf="userData == ''">No users in {{language}} language</p>
<mat-paginator (page)="pageChanged($event)" [length]="totalSummary" [pageIndex]="currentPage" [pageSize]="pageSize"  [pageSizeOptions]="pageSizeOptions" aria-label="Select page of users"></mat-paginator>
     </div>
</div>
</div>
  <!-- modal -->
                <!-- <div class="modal fade" data-keyboard="false" data-backdrop="static" id="deleteCollection" tabindex="-1"
                  role="dialog" aria-labelledby="deleteCollectionTitle" aria-hidden="true">
                  <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                      <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                          <img src="../../assets/images/close-m.svg" loading="lazy">
                        </button>
                      </div>
                      <div class="modal-body">Are you sure you want to delete this collection ? </div>
                      <div class="modal-footer">
                        <button type="button" class="btn back" data-dismiss="modal">No, take me back</button>
                        <button type="button" class="btn confirm" data-dismiss="modal"
                         >delete</button>
                      </div>
                    </div>
                  </div>
                </div> -->
                <!-- modal -->