import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize, last } from 'rxjs/operators';
import { EditorPreviewDialogComponent } from 'src/app/editor-preview-dialog/editor-preview-dialog.component';
import { ShareproductLinkDialogComponent } from 'src/app/shareproduct-link-dialog/shareproduct-link-dialog.component';
import { CollectionService } from 'src/app/_services/collection.service';
import { environment } from 'src/environments/environment';
import { AddSubTopicsDialogComponent } from 'src/app/creator-create-collection/add-sub-topics-dialog/add-sub-topics-dialog.component';
import { AddTopicsDialogComponent } from 'src/app/creator-create-collection/add-topics-dialog/add-topics-dialog.component';

@Component({
  selector: 'app-summaries-edit',
  templateUrl: './summaries-edit.component.html',
  styleUrls: ['./summaries-edit.component.css'],
})
export class SummariesEditComponent implements OnInit {
  editCollectionForm: FormGroup;
  VideoCount = [
    '50k',
    '100k',
    '200k',
    '300k',
    '400k',
    '500k',
    '600k',
    '700k',
    '800k',
    '900k',
    '1M',
    '2M',
    '3M',
    '4M',
    '5M',
    '6M',
    '7M',
    '8M',
    '9M',
    '10M',
    '20M',
    '30M',
  ];
  ChannelName: any;
  categoryList: any;
  channelName: any;
  keyWordsValue: any;
  keywords: string;
  category: string;
  ProdcutINFOid: any;
  activeRouteParams: any;
  CImage: void;
  categoryLink: string;
  CollectionTitle: any;
  updatedsuccefully: boolean = false;
  loaderPreview: boolean = false;
  submittedDetails: boolean = false;
  collectionUpdate: boolean = true;
  id: string;
  // storage: any;
  keywordsLoader: any = false;
  keywordsLoaderPostionChange: string = 'key_words_loader_postion_change_css';
  modules = {};
  generatedText: any;

  totalSummaryData: any;
  selectedFile: File;
  hideComponent: boolean;
  fileName: any;
  url: string | ArrayBuffer | null;
  errorMessage: any;
  loadinSubTopics: boolean = false;
  subTopics: any;
  subCaterogoryList: any;
  catergoryId: string;
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private dialog: MatDialog,
    public collectionServices: CollectionService,
    public activeRoute: ActivatedRoute
  ) {
    this.editCollectionForm = this.fb.group({
      sourceURL: ['', [Validators.required]],
      videoViewsCount: ['', [Validators.required]],
      targetAudience: [''],
      gender: [''],
      language: [''],
      customThumbNail: [''],
      collectionName: ['', [Validators.required]],
      channelName: ['', [Validators.required]],
      video: [''],
      categoryId: [''],
      subCategoryId: [''],
      productInfoId: [''],
      Keywords: [''],
      tags: [''],
      Summery: [''],
    });

    if (this.collectionServices.getToken() != '') {
      Promise.all([import('quill-image-resize-module'), import('quill')]).then(
        ([ImageResize, Quill]) => {
          console.log({ Quill, ImageResize });
          // each has a "default" which is identical to the traditional import
          Quill.default.register('modules/imageResize', ImageResize.default);
          this.modules = {
            imageResize: {},
            toolbar: {
              container: [
                ['bold', 'italic', 'underline', 'strike'], // toggled buttons
                ['blockquote', 'code-block'],

                [{ header: 1 }, { header: 2 }, { header: 3 }, { header: 4 }], // custom button values
                [{ list: 'ordered' }, { list: 'bullet' }],
                [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
                [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
                [{ direction: 'rtl' }], // text direction

                [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
                [{ header: [1, 2, 3, 4, 5, 6, false] }],

                [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                [{ font: [] }],
                [{ align: [] }],

                ['clean'], // remove formatting button

                ['link', 'image', 'video'], // link and image, video
                ['showHtml'],
              ],
            },
          };
        }
      );
    }
  }
  htmlText = 'loading........';

  ngOnInit(): void {
    this.activeRouteParams = this.activeRoute.snapshot.queryParams;
    console.log(this.activeRouteParams.id);
    this.collectionServices
      .CollectionListDetails(this.activeRouteParams.id, this.params)
      .subscribe((res) => {
        console.log(res.data[0].Keywords[0]);

        this.CImage = res.data[0].thumbNail;
        if (res.data[0].thumbNail == null) {
          this.CImage = res.data[0].customThumbNail;
        } else {
          this.CImage = res.data[0].thumbNail;
        }
        // this.keyWords = res.data[0].keywords.toString();

        this.editCollectionForm.controls['sourceURL'].setValue(
          res.data[0].sourceURL
        );
        this.editCollectionForm.controls['videoViewsCount'].setValue(
          res.data[0].videoViewsCount
        );
        this.editCollectionForm.controls['collectionName'].setValue(
          res.data[0].summaryName
        );
        this.editCollectionForm.controls['channelName'].setValue(
          res.data[0].publisherName
        );
        this.editCollectionForm.controls['Summery'].setValue(
          res.data[0].Summary
        );
        this.totalSummaryData = res.data[0].Summary;
        this.editCollectionForm.controls['Keywords'].setValue(
          res.data[0].Keywords[0]
        );
      });
    this.collectionServices.channelList().subscribe((res) => {
      this.ChannelName = res.data;
    });
    this.collectionServices.caterogoryList().subscribe((res) => {
      console.log(res);
      this.categoryList = res.data;
    });
  }
  params(id: any, params: any) {
    throw new Error('Method not implemented.');
  }
  onFileSelectedEvent(event: any): void {
    this.hideComponent = false;
    const file = event.target.files && event.target.files[0];
    this.selectedFile = <File>event.target.files[0];
    this.fileName = event.target.files[0].name;

    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.editCollectionForm.controls['customThumbNail'].setValue(file);
    }
    var reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = (event) => {
      this.url = (<FileReader>event.target).result;
    };
  }
  preview() {
    this.dialog.open(EditorPreviewDialogComponent, {
      data: {
        content: this.editCollectionForm.value.Summery,
        // image: details.image,
      },
    });
  }
  getkeyWords() {
    this.keywordsLoader = true;
    this.collectionServices.chatGptkeyWords(this.totalSummaryData).subscribe(
      (response: any) => {
        this.keywordsLoader = false;
        this.generatedText = response.data.choices[0].message.content;
        console.log(this.generatedText);
        this.editCollectionForm.controls['Keywords'].setValue(
          this.generatedText
        );
        this.keywords = this.editCollectionForm.value.Keywords;
        console.log(this.keywords.split('/n'));
      },
      (error: any) => {
        console.error('Error generating text', error);
      }
    );
  }
  get c(): { [key: string]: AbstractControl } {
    return this.editCollectionForm.controls;
  }
  channelEvent(e: any) {
    console.log(e.target.value);
    this.channelName = e.target.value;
  }
  keyWords(e: any) {
    this.keyWordsValue = e.target.value;

    console.log(e.target.value);
    if (this.keyWordsValue == '' || this.keyWordsValue == null) {
      this.keywords = '';
    } else {
      this.keywords = this.keyWordsValue.split(',');
      console.log(this.keywords);
    }
  }
  addsubtopics() {
    console.log(this.category);
    if (this.category != '') {
      this.dialog.open(AddSubTopicsDialogComponent, {
        data: {
          id: JSON.parse(this.catergoryId),
          disableClose: true,
        },
      });
    } else {
      this.errorMessage = 'Please select aleast one topic';
    }
  }

  categoryEvent(event: any) {
    if (event.value != '') {
      this.errorMessage = '';
      this.category = JSON.stringify(event.value);
      this.loadinSubTopics = true;
      this.subTopics = {
        topicIds: this.category,
      };
      this.collectionServices
        .subCaterogoryList(this.subTopics)
        .subscribe((res) => {
          this.loadinSubTopics = false;

          console.log(res);
          this.subCaterogoryList = res.subTopics;
        });
    } else {
      this.category = '';
      this.subCaterogoryList = '';
    }
    console.log(event.value);
    this.catergoryId = this.category;
  }
  addTopics() {
    this.dialog.open(AddTopicsDialogComponent, {
      data: {
        disableClose: true,
      },
    });
  }
  updateCollection() {
    console.log(this.editCollectionForm);
    if (this.editCollectionForm.invalid) {
      this.submittedDetails = true;
    } else {
      this.submittedDetails = false;
      this.loaderPreview = true;
      const formData = new FormData();
      formData.append('sourceURL', this.editCollectionForm.value.sourceURL);
      formData.append(
        'customThumbNail',
        this.editCollectionForm.controls['customThumbNail'].value
      );
      formData.append(
        'videoViewsCount',
        this.editCollectionForm.value.videoViewsCount
      );
      formData.append(
        'summaryName',
        this.editCollectionForm.value.collectionName.replace(
          /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
          ''
        )
      );
      if (this.editCollectionForm.value.subCategoryId != '') {
        formData.append(
          'subTopicsId',
          JSON.stringify(this.editCollectionForm.value.subCategoryId)
        );
      }

      formData.append('video', this.editCollectionForm.value.sourceURL);
      formData.append(
        'publisherName',
        this.editCollectionForm.value.channelName
      );

      if (this.editCollectionForm.value.categoryId != '') {
        formData.append(
          'topicId',
          JSON.stringify(this.editCollectionForm.value.categoryId)
        );
      }
      if (this.editCollectionForm.value.Keywords != '') {
        formData.append(
          'Keywords',
          JSON.stringify(this.editCollectionForm.value.Keywords)
        );
      }

      formData.append('Summary', this.editCollectionForm.value.Summery);

      this.collectionServices
        .powerUserUpdateCollection(this.activeRouteParams.id, formData)
        .subscribe((res) => {
          console.log(res);

          this.updatedsuccefully = true;
          this.collectionUpdate = false;
          this.loaderPreview = false;

          this.CollectionTitle =
            this.editCollectionForm.value.collectionName.replace(
              /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
              ''
            );
          this.id = this.activeRouteParams.id;
          this.categoryLink = `${
            environment.SHARE_URL
          }/c/${this.editCollectionForm.value.collectionName
            .split(' ')
            .join('-')}/id-${this.activeRouteParams.id}`;
        });
    }
  }

  share() {
    this.dialog.open(ShareproductLinkDialogComponent, {
      data: {
        title: this.categoryLink,
        disableClose: true,
      },
    });
  }
}
