import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CollectionService } from 'src/app/_services/collection.service';
import { LoginService } from 'src/app/_services/login.service';
import { UseronboardingflowDialogComponent } from 'src/app/useronboardingflow-dialog/useronboardingflow-dialog.component';
import { environment } from 'src/environments/environment';
import { LoginComponent } from '../login/login.component';
var emailPhonePattern = '/^w+([.-]?w+)*@w+([.-]?w+)*(.w{2,3})+$/';

declare var clevertap: any;

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css'],
})
export class SignupComponent implements OnInit {
  signUpWithEmail: boolean = true;
  signUpWithName: boolean = false;
  submitted: boolean = false;
  submittedUser: boolean = false;
  singupLoader: boolean = false;
  signUpEmail: FormGroup;
  SignUpUser: FormGroup;
  signupErrorMessage: any;
  userName: any;
  userType: any;
  languageControl = new FormControl('');
  language: any = [
    { key: 'Telugu', value: 'Telugu' },
    { key: 'English', value: 'English' },
    { key: 'Hindi', value: 'Hindi' },
    { key: 'Kannada', value: 'Kannada' },
    { key: 'Tamil', value: 'Tamil' },
  ];

  userId: any;
  url: any = `${environment.USER}user/google`;
  languageparams: string;
  redirectionLink: string | null;
  userDialog: any;
  ErrorMessage: any = '';
  constructor(
    private router: Router,
    private loginService: LoginService,
    private fb: FormBuilder,
    private diloag: MatDialog,
    private collectionsservice: CollectionService,
    private dialogRef: MatDialogRef<SignupComponent>
  ) {
    this.signUpEmail = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      name: ['', [Validators.required]],
    });
    this.SignUpUser = this.fb.group({
      password: ['', [Validators.required]],
      gender: ['', [Validators.required]],
      languages: [''],
    });
  }

  ngOnInit(): void {
    //
    clevertap.init('6ZW-454-496Z');

    this.SignUpUser.controls['languages'].setValue('English');
  }
  close() {
    this.diloag.closeAll();
  }
  signUp() {
    if (this.signUpEmail.invalid) {
      this.submitted = true;
    } else {
      if (this.ErrorMessage == '') {
        this.submitted = false;
        this.signUpWithName = true;
        this.signUpWithEmail = false;
      }
    }
  }
  loginNow() {
    // this.router.navigate(['user/login']);
    this.dialogRef.close();
    this.diloag.open(LoginComponent, {
      disableClose: true,
      data: {},
    });
  }
  languageDropDown(event: any) {
    if (event.value != '') {
      this.languageparams = event.value;
    } else {
      this.languageparams = '';
    }
    // console.log(this.languageparams);
  }

  backBtn() {
    this.signUpWithEmail = true;
    this.signUpWithName = false;
  }
  validateEmail(e: any) {
    let obj = {
      email: e.target.value,
    };
    this.collectionsservice.validateEmail(obj).subscribe(
      (res) => {
        this.ErrorMessage = '';
      },
      (error) => {
        this.ErrorMessage = error.error.message;
        this.singupLoader = false;
        // if (this.signupErrorMessage != null) {
        // }
        // setTimeout(() => {
        //   this.signupErrorMessage = '';
        // }, 2500);
        // console.log(error.error.message);
      }
    );
  }
  login() {
    if (this.SignUpUser.invalid) {
      this.submittedUser = true;
    } else {
      if (environment.CLEVERTAP) {
        clevertap.event.push('ReaderSingupCtaClick', {
          ReaderSingupName: this.SignUpUser.value.name, // String
          ReaderSingupEmail: this.signUpEmail.value.email, // Email address of the user
        });
      }
      this.submittedUser = false;
      this.singupLoader = true;
      let obj = {
        name: this.signUpEmail.value.name,
        email: this.signUpEmail.value.email,
        password: this.SignUpUser.value.password,
        gender: this.SignUpUser.value.gender,
        languages: this.languageparams,
      };
      this.loginService.signupUser(obj).subscribe(
        (result) => {
          // console.log(result);
          this.singupLoader = false;

          localStorage.setItem('token', result.token);

          this.collectionsservice.loginUserDetails().subscribe((result) => {
            this.userName = result.data.name;
            this.userType = result.data.userType;
            this.userId = result.data._id;
            this.userDialog = result.data.onbording;
            localStorage.setItem('userName', this.userName);
            localStorage.setItem('userType', this.userType);
            localStorage.setItem('ID', this.userId);
            this.diloag.closeAll();
            this.collectionsservice.refresh();
            this.redirectionLink = localStorage.getItem('shareLink');
            console.log('rediredtion', this.redirectionLink);
            if (
              this.redirectionLink == '' ||
              this.redirectionLink == null ||
              this.redirectionLink == undefined
            ) {
              this.router.navigate(['/feed']);
            } else {
              this.router.navigate([`${this.redirectionLink}`]);
            }

            localStorage.removeItem('shareLink');
          });
        },
        (error) => {
          this.signupErrorMessage = error.error.message;
          this.singupLoader = false;
          // if (this.signupErrorMessage != null) {
          // }
          setTimeout(() => {
            this.signupErrorMessage = '';
          }, 2500);
          // console.log(error.error.message);
        }
      );
    }
  }
  get signUpEmailError(): { [key: string]: AbstractControl } {
    return this.signUpEmail.controls;
  }
  get signUpNameError(): { [key: string]: AbstractControl } {
    return this.SignUpUser.controls;
  }
}
