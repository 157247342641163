<div class="container">
    <img (click)="close()" src="../../../../assets/images/close-m.svg" class="close-img_works"> 
   <div class="how_it_works">
    <h5>If you have an existing Youtube channel, you can become a publisher</h5>
      <ul>
        <li>Create an AI blog in seconds</li>
        <li>Enter any of your youtube video link
</li>
        <li>Our AI automatically creates a blog from that video content
</li>
        <li>Also, publishes the same blog in 90 plus languages</li>
</ul>
   </div>
    </div>