<div class="main">
  <div class="container-fluid ml-3 mr-4">
    <div class="publiser_blog">
      <div class="card mr-3" *ngIf="ChannelName == ''">
        <div class="card-body">
          <div class="blog_p d-flex">
            <div class="blog_header">
              <h5>Create your AI Summary</h5>
            </div>
            <div class="blog_button ml-auto">
              <button class="cursor" (click)="GetStarted()">
                Get Started
                <img src="../../../assets/publisherassets/arrow-right.png" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card mr-3" *ngIf="ChannelName != ''">
        <div class="card-body">
          <div class="blog_p d-flex">
            <div class="blog_header">
              <h5>View your AI Summary</h5>
            </div>
            <div class="blog_button ml-auto">
              <button class="cursor" (click)="ViewBtn()">
                View
                <img src="../../../assets/publisherassets/arrow-right.png" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <app-publisher-onboarding-dialog
      *ngIf="this.totalSummaryCount == 0"
    ></app-publisher-onboarding-dialog>
    <div class="Your_blog mt-md-3" *ngIf="this.totalSummaryCount != 0">
      <h6>Your summary content</h6>
      <div class="blogs-Button_section">
        <button
          [class.active]="buttons.value == activeState"
          *ngFor="let buttons of languageButtons"
          (click)="changelanguage(buttons.value)"
        >
          {{ buttons.name }}
        </button>
      </div>

      <div class="blogs_section_details mt-md-4">
        <div class="no_blogs text-center" *ngIf="publisherBlogs == ''">
          <h6>No blogs in this {{ activeState }} language</h6>
        </div>

        <div class="row border-width" *ngFor="let item of publisherBlogs">
          <div class="col-md-3">
            <div class="blog_img pt-md-2">
              <img [src]="item.thumbNail" />
              <h5>{{ item.summaryName }}</h5>
            </div>
          </div>
          <div class="col-md-9">
            <div
              class="blogs_details-Views d-flex justify-content-around pt-md-2"
            >
              <div class="blogs_Reads">
                <h5>Reads</h5>
                <p>{{ item.SummaryLikes }}</p>
              </div>
              <div class="blogs_Reads">
                <h5>Comments</h5>
                <p>{{ item.SummaryComments }}</p>
              </div>
              <div class="blogs_Reads">
                <h5>Bookmark</h5>
                <p>{{ item.bookmarkDataCount }}</p>
              </div>
              <div class="blogs_Reads">
                <h5>Date</h5>
                <p>{{ item.createdAt | date : "MMM d, y" }}</p>
              </div>
              <div class="blogs_Reads">
                <h5>Status</h5>
                <p>Published</p>
              </div>
              <div class="blogs_Reads">
                <h5>Action</h5>
                <a [href]="View(item.summaryName, item._id)" target="_blank"
                  ><p class="text-decoration-underline cursor">View</p></a
                >
              </div>
            </div>
          </div>
        </div>
        <mat-paginator
          (page)="pageChanged($event)"
          [length]="totalSummary"
          [pageIndex]="currentPage"
          [pageSize]="pageSize"
          [pageSizeOptions]="pageSizeOptions"
          aria-label="Select page of users"
        ></mat-paginator>
        <!-- <pagination-controls (pageChange)="p = $event"></pagination-controls> -->
      </div>
    </div>
  </div>
</div>
