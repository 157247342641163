<app-creatornavbar ></app-creatornavbar>


<div class="container-fluid mt-5">
    <div class="analytics_section">
        <div class="analytics_card_section card-section_analytics d-flex justify-content-center">
            <div class="card mr-3" *ngFor="let item of Analytics">
              <div class="card-body">
                  <h5>{{item.number}}</h5>
                  <p>{{item.name}}</p>
              </div>
            </div>
        </div>


          <div class="table-data mt-md-5">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

  <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

  <!-- Position Column -->


  <ng-container matColumnDef="commentid">
    <th mat-header-cell *matHeaderCellDef> Comment-id </th>
    <td mat-cell *matCellDef="let element"> {{element.commentid}} </td>
  </ng-container>
  <ng-container matColumnDef="summaryid">
    <th mat-header-cell *matHeaderCellDef> Summary-id </th>
    <td mat-cell *matCellDef="let element"> {{element.summaryid}} </td>
  </ng-container>

  

  <!-- Weight Column -->
  <ng-container matColumnDef="summarytitle">
    <th mat-header-cell *matHeaderCellDef> Summary title </th>
    <td mat-cell *matCellDef="let element"> {{element.summarytitle}} </td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="Commentdetails">
    <th mat-header-cell *matHeaderCellDef> Comment details </th>
    <td mat-cell *matCellDef="let element">
        
        <!-- {{element.comment}} -->
    <p class="comment">
     {{element.Commentdetails}}
</p>
    </td>
  </ng-container>
  <!-- Weight Column -->
  <ng-container matColumnDef="Spam" class='ml-3'>
    <th mat-header-cell *matHeaderCellDef> Spam? </th>
    <td mat-cell *matCellDef="let element"> 
        {{element.Spam}}
    </td>
  </ng-container>
<!-- Name Column -->
  <ng-container matColumnDef="offensive">
    <th mat-header-cell *matHeaderCellDef> offensive ? </th>
     <td mat-cell *matCellDef="let element"> 
        {{element.offensive}}
    </td>
  </ng-container>
  <!-- Symbol Column -->
  <ng-container matColumnDef="Commented_by">
    <th mat-header-cell *matHeaderCellDef> Commented_by</th>
    <td mat-cell *matCellDef="let element"> {{element.Commented_by}} </td>
  </ng-container>
  <!-- Weight Column -->
  <ng-container matColumnDef="Publisher_id">
    <th mat-header-cell *matHeaderCellDef> Publisher_id </th>
    <td mat-cell *matCellDef="let element"> {{element.Publisher_id}} </td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="Action">
    <th mat-header-cell *matHeaderCellDef> Action </th>
    <td mat-cell *matCellDef="let element"> <div class="d-flex">
        <!-- <div class="edit-img">
           <img  src="../../../assets/images/e-3.png">
        </div>
        <div class="toggle">
          <label class="toggle">
  <input type="checkbox" id="toggleButton" (change)="toggleChanged($event)">
  <span class="slider"></span>
</label>
        </div> -->
         <div class="edit-img">
           <a>
            <img class="cursor"  src="../../../assets/images/delete.png">
            </a>
           
        </div>
       
       </div></td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
    </div>
</div>