
<div class="side-h">
<div class="container-fluid mr-md-5">
   
      <button mat-icon-button class="Administrator-btn" class="justify-content-end"
           
            mat-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
         <div class="header mr-md-5 mt-md-2">
        <div class="login-header login-img d-flex justify-content-end">
            <img src="../../../../assets/images/user-1.png" alt="login-img">
            <img src="../../../../assets/images/123.png" alt="login-img" class="arrow">
        </div>
    </div>
							<div #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu" place></div>
						</button>
						<mat-menu #menu="matMenu" xPosition="before">
							<!-- <button class="View" mat-menu-item value="Profile"> <img src="../../../../assets/images/user-1.png" class="mr-3 logout" /> <span>Profile</span>
              </button> -->
							<button class="Deactivate" mat-menu-item value="Logout" (click)="logout()"> <img src="../../../../assets/images/log-out.png" class="mr-3 logout" /> <span>Logout</span>
              </button>
						</mat-menu>
</div>
</div>
