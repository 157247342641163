import { Component, OnInit } from '@angular/core';
import { CollectionService } from '../_services/collection.service';

@Component({
  selector: 'app-userpublishers',
  templateUrl: './userpublishers.component.html',
  styleUrls: ['./userpublishers.component.css'],
})
export class UserpublishersComponent implements OnInit {
  data: any;
  loader: boolean;

  constructor(private _userService: CollectionService) {}

  ngOnInit(): void {
    this.loader = true;
    this._userService.userFollowedApi().subscribe((user) => {
      this.data = user.publishers;
      this.loader = false;
      console.log(this.data);
    });
  }
  unfollow(collection: any): void {
    // console.log(id);

    let obj = {
      publisherId: collection.publisherId,
    };
    this._userService.ChannelUnFollowList(collection._id).subscribe((res) => {
      // this.following = false;
      // this.follow = true;
      collection.followChannel = false;
      this._userService.userFollowedApi().subscribe((user) => {
        this.data = user.publishers;
        console.log(this.data);
      });
    });
  }
}
