import { Directive, ElementRef, Renderer2, OnInit } from '@angular/core';

@Directive({
  selector: '[appRemoveStyles]',
})
export class RemoveStylesDirective {
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    // Remove inline styles from the DOM element
    this.renderer.removeAttribute(this.el.nativeElement, 'style');
  }
}
