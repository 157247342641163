import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { CollectionService } from 'src/app/_services/collection.service';
import { LoginService } from 'src/app/_services/login.service';
import { environment } from 'src/environments/environment';
var emailPhonePattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$';
@Component({
  selector: 'app-power-user-login',
  templateUrl: './power-user-login.component.html',
  styleUrls: ['./power-user-login.component.css'],
})
export class PowerUserLoginComponent implements OnInit {
  loginPage: boolean = true;
  resetPassword: boolean = false;
  submitted: boolean = false;
  confirmPassword: boolean = false;
  passwordChangedSuccesfully: boolean = false;
  submittedPassword: boolean = false;
  loginFormGroup: FormGroup;
  forGotPasswordForm: FormGroup;
  errorMessage: any;

  loginErrorMessage: any;
  userType: any;
  userName: string;
  url: any = `${environment.AUTH_URL}auth/google`;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private loginService: LoginService,
    private collectionsservice: CollectionService
  ) {
    this.loginFormGroup = this.fb.group({
      email: ['', [Validators.required, Validators.pattern(emailPhonePattern)]],
      password: ['', Validators.required],
    });
    this.forGotPasswordForm = this.fb.group({
      email: ['', [Validators.required, Validators.pattern(emailPhonePattern)]],
    });
  }

  ngOnInit(): void {
    if (localStorage.getItem('token') != null) {
      this.router.navigate(['/Poweruserhome-batman']);
    }
  }
  login() {
    if (this.loginFormGroup.invalid) {
      this.submitted = true;
    } else {
      this.submitted = false;
      let obj = {
        email: this.loginFormGroup.value.email,
        password: this.loginFormGroup.value.password,
      };

      this.loginService.login(obj).subscribe(
        (res) => {
          localStorage.setItem('token', res.token);
          // this.collectionsservice.powerUserToken = res.token;
          // debugger;

          this.router.navigate(['/Poweruserhome-batman']);
          // var username = 'chandra';
          // this.userName = 'kadalikar';
          this.collectionsservice.loginUserDetails().subscribe((result) => {
            // this.userName = result.data.name;
            this.userType = result.data.userType;
            // localStorage.setItem('userName', this.userName);
            localStorage.setItem('userType', this.userType);
          });
        },
        (err) => {
          console.log(err.error.message);

          this.loginErrorMessage = err.error.message;
          setTimeout(() => {
            this.loginErrorMessage = '';
          }, 3000);
        }
      );

      console.log(this.loginFormGroup.value);
    }
  }

  get f(): { [key: string]: AbstractControl } {
    return this.loginFormGroup.controls;
  }
  get r(): { [key: string]: AbstractControl } {
    return this.forGotPasswordForm.controls;
  }
  signUp() {
    this.router.navigate(['PowerUser/signup-batman']);
  }

  forGotPassword() {
    this.resetPassword = true;
    this.loginPage = false;
  }
  resetPasswordBtn() {
    if (this.forGotPasswordForm.invalid) {
      this.submittedPassword = true;
    } else {
      this.submittedPassword = false;
      this.submitted = false;
      let obj = {
        email: this.forGotPasswordForm.value.email,
      };
      this.loginService.forgotPassword(obj).subscribe(
        (res) => {
          this.passwordChangedSuccesfully = true;
          this.resetPassword = false;
        },
        (error) => {
          console.error(error.error);
          this.errorMessage = error.error.message;
          setTimeout(() => {
            this.errorMessage = '';
          }, 3000);
        }
      );
    }
  }
  home() {
    this.router.navigate(['/feed']);
  }
  confirmPasswordBtn() {
    this.passwordChangedSuccesfully = true;
    this.confirmPassword = false;
  }
  loginNow() {
    this.loginPage = true;
    this.passwordChangedSuccesfully = false;
  }
}
