import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fliterCategorty',
})
export class FliterCategortyPipe implements PipeTransform {
  followedCatergorys: any = [];
  isForYou: boolean = false;

  transform(items: any[], filter: Record<string, any>): any {
    if (!items || !filter) {
      return items;
    }

    const key = Object.keys(filter)[0];
    const value = filter[key];

    // console.log(key, value);
    if (value == 'All') {
      const filteredItems = items.filter((e) => e.isAdded);
      return filteredItems.slice(0, 1);
    }

    if (value == 'ForYou') {
      if (items.filter((e) => e.isAdded)) {
        const filteredItems = items.filter(
          (e) =>
            (e.topicsFollow == true && e.isAdded == true) ||
            (e.topicsFollow == false && e.isAdded == true)
        );
        // console.log('alse', filteredItems);
        return filteredItems
          .sort((a, b) => {
            return a.topicsFollow === b.topicsFollow
              ? 0
              : a.topicsFollow
              ? -1
              : 1;
          })
          .slice(0, 1);
      }
    } else {
      return items.filter((e) => e[key].indexOf(value) !== -1);
    }
  }
}
