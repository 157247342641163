<div class="container moblie-view-container">
  <div class="user-flow-section" *ngIf="followCatergory">
    <div class="user-flow-header">
      <h4>What are your interests?</h4>
      <p>Pick one or more Interests</p>
    </div>

<app-loader *ngIf="topicLoader"></app-loader>

    <div  class="catergoy-buttons">
      <div class="row">
        <div class="col-md-4 col-sm-3 col-4" *ngFor="let item of categoryList?.slice(1);let i=index;">
       <button #btn (click)="toggleColor(item)" [class.follow-category]="item.isButtonSelected">{{ item.name }}</button>

        </div>
      </div>
</div>
 <!-- <p class="mt-1 interest" *ngIf="showText">Please follow atleast one interest</p> -->
 <div class="next-btn d-flex justify-content-end mt-md-1">

          <button
            class="pointer"
             [class.next-btn-enabled]="buttonDisabledNext == false"
            [disabled]="buttonDisabledNext"
            (click)="catergoryCompleted()"
          >
            Next
          </button>
        </div>
    
  </div>
<app-loader *ngIf="loader"></app-loader>
<app-loader *ngIf="Publisherloader"></app-loader>
  <div class="channel-flow" *ngIf="channelFollow">
    <div class="user-flow-section">
      <div class="user-flow-header">
         <!-- <div class="close_img cursor" (click)="close()">
              <img src="../../../assets/images/close-m.svg">
            </div> -->
        <h4>Based on your interests we recommmend you to follow these popular Publishers</h4>
      <p>Pick one or more Interests</p>
      <p class="followed" *ngIf="channelFollowedList != ''">You are following <span *ngFor="let name of channelFollowedList; let last = last">{{name}}{{!last ? ', ' : ''}}</span></p>
      </div> 

      <!-- <div class="choice-selection pt-md-4"> -->
        
      </div>
      <div class="caterogy-section mt-md-3">
        <div
          class="d-flex cards-follow mb-2"
          *ngFor="let channelName of channelListNames; index as i"
        >
          <div class="caterogry-img mt-md-2">
            <img src="{{ channelName.profile }}" class="c-1-img" />
          </div>
          <div class="caterogy-section-details mt-2 ml-3">
            <h4>{{ channelName.name }}</h4>
            <!-- <h5>2M Youtube Subscribers</h5> -->
            <p >
              Popular publisher in 
              <span *ngFor="let name of channelName.topic; let last = last">{{name.name}}{{!last ? ', ' : ''}}</span>
              <!-- <span *ngIf="channelName?.topic[1]?.name != ''"> {{channelName?.topic[1]?.name}}</span>
              <span *ngIf="channelName?.topic[2]?.name != ''"> {{channelName?.topic[2]?.name}}</span>
              <span *ngIf="channelName?.topic[3]?.name != ''"> {{channelName?.topic[3]?.name}}</span> -->
            </p>
          </div>
          <div class="follow-channel mt-md-2">
            <button
              class="cursor"
              (click)="
                FollowChannelName($event.target, channelName, 'Following', i)
              "
            >
              Follow
            </button>
          </div>
        </div>
      </div>

      <!-- <div class="checkbox">
        <div class="custom-control custom-checkbox">
          <input
            type="checkbox"
            class="custom-control-input"
            id="customCheck1"
            checked
          />
          <label class="custom-control-label" for="customCheck1"
            >Send me a weekly email with best collections curated by top
            creators</label
          >
        </div>
      </div> -->

      <div class="complete-signup d-flex justify-content-center mt-md-4">
        <button
          class="pointer"
          [class.complete-signup-enabled]="channelBtnDisabled == false"
           
          (click)="nextChannel()"
        >
         View Summaries
        </button>
      </div>
    </div>

    <div class="loading-s" *ngIf="loadingSketlon">
      <!-- <p>Do you know people forget 90% what they learned from YouTube videos within 7 days? </p> -->
      <h5>Loading your personalised feed of summaries....</h5>
      <div class="loading">
<ng-lottie
  width="500px"
  height="500px"
  [options]="options"
   
></ng-lottie>
      </div>
      
    </div>
    <div class="welcome" *ngIf="welcome">
      <div class="welcome-s">
<ng-lottie
 
  [options]="optionsW"
   
></ng-lottie>
      </div>

      <h4>Weclome to summarizly.ai</h4>
    </div>
 </div>
