import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-channel-footer',
  templateUrl: './channel-footer.component.html',
  styleUrls: ['./channel-footer.component.css'],
})
export class ChannelFooterComponent implements OnInit {
  Date: any;

  constructor() {}

  ngOnInit(): void {
    this.Date = new Date().getFullYear();
    // console.log(this.Date);
  }
}
