import { Platform } from '@angular/cdk/platform';
import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import {
  BehaviorSubject,
  MonoTypeOperatorFunction,
  Observable,
  Subject,
} from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root',
})
export class CollectionService {
  user: string;
  token: string;
  userName: string | null;
  summary: any = '';
  userDetails: any;
  private refreshSubject = new Subject<void>();

  refresh$ = this.refreshSubject.asObservable();

  triggerRefresh() {
    this.refreshSubject.next();
  }
  private message = new BehaviorSubject(localStorage.getItem('userType'));
  sharedMessage = this.message.asObservable();

  constructor(
    private http: HttpClient,
    private router: Router,
    private Loginservice: LoginService,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    if (isPlatformBrowser(platformId)) {
      // this.userName = localStorage.getItem('userType');

      this.user = this.Loginservice.token;

      // this.user = localStorage.getItem('token') || '{}';
      this.user = localStorage.getItem('token') || '{}';
      this.token = 'Bearer ' + this.user;
      console.log('token', this.token);
    }
    console.log('userDetails', this.userDetails);
  }
  private refreshSubject$ = new BehaviorSubject<boolean>(false);
  refresh() {
    this.refreshSubject$.next(true);
  }

  getRefreshSignal() {
    return this.refreshSubject$.asObservable();
  }
  getUserDetails() {
    return localStorage.getItem('userType') || '';
  }
  refreshComponent() {
    return localStorage.getItem('refresh');
  }
  getToken() {
    return localStorage.getItem('token') || '';
  }

  logOut() {
    if (localStorage.getItem('token') != null) {
      localStorage.clear();
      this.router.navigate(['/feed']);
    }
  }

  // comment-API
  commentPost(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${environment.COLLECTION_URL}power-user/summary-comment`;
    return this.http.post<any>(url, data, httpOptions);
  }
  googleTranslate(id: any, data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url =
      `${environment.COLLECTION_URL}power-user/google-translate/` + id;
    return this.http.post<any>(url, data, httpOptions);
  }
  reportSummary(id: any, data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url =
      `${environment.COLLECTION_URL}power-user/summary/report-issue/` + id;
    return this.http.post<any>(url, data, httpOptions);
  }
  commentGet(id: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url =
      `${environment.COLLECTION_URL}power-user/summary-comment-details/` + id;
    return this.http.get<any>(url, httpOptions);
  }
  topicNames(params: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params: params,
    };
    const url = `${environment.COLLECTION_URL}power-user/remove-duplicate`;
    return this.http.get<any>(url, httpOptions);
  }
  getYoutubeData(id: any, count: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${environment.COLLECTION_URL}power-user/youtube/videos?channelId=${id}&maxResults=${count}`;
    return this.http.get<any>(url, httpOptions);
  }
  getYoutubeDataByVideoId(id: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${environment.COLLECTION_URL}power-user/youtube/videos-data?id=${id}`;
    return this.http.get<any>(url, httpOptions);
  }
  summaryReadingTime(id: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${environment.USER}home/start-record-readingTime?summaryId=${id}`;
    return this.http.get<any>(url, httpOptions);
  }
  summaryEndingTime(id: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${environment.USER}home/end-record-readingTime?summaryId=${id}`;

    return this.http.get<any>(url, httpOptions);
  }
  sharecount(id: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${environment.USER}user/share-count?summaryId=${id}`;

    return this.http.post<any>(url, httpOptions);
  }
  getPublisherDetails(id: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${environment.COLLECTION_URL}power-user/youtube/create-channel?channelId=${id}`;
    return this.http.get<any>(url, httpOptions);
  }
  commentReply(data: any, id: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${environment.COLLECTION_URL}summary-comment/` + id;
    return this.http.put<any>(url, data, httpOptions);
  }
  commentUpdateReply(data: any, id: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url =
      `${environment.COLLECTION_URL}power-user/update-summary-comment/` + id;
    return this.http.put<any>(url, data, httpOptions);
  }
  commentSpam(data: any, id: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${environment.COLLECTION_URL}power-user/comment-spam/` + id;
    return this.http.put<any>(url, data, httpOptions);
  }
  commentOffensive(data: any, id: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url =
      `${environment.COLLECTION_URL}power-user/comment-offensive/` + id;
    return this.http.put<any>(url, data, httpOptions);
  }
  deleteCommentPublisher(params: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params: params,
    };
    const url = `${environment.COLLECTION_URL}power-user/publisher-comment`;
    return this.http.delete<any>(url, httpOptions);
  }
  commentDeleteReply(data: any, id: any) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url =
      `${environment.COLLECTION_URL}power-user/delete-summary-comment/` + id;
    return this.http.delete<any>(url, {
      headers,
      body: data,
    });
  }

  sessionAPICheck() {
    const url = `${environment.USER}user/session`;
    return this.http.get<any>(url);
  }
  collectionClicks(params: any, id: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        // Authorization: this.token,
      }),
      params: params,
    };
    const url = `${environment.USER}home/summary-click?summaryId=` + id;
    return this.http.put<any>(url, params);
  }
  unbookSummarty(id: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        // Authorization: this.token,
      }),
    };
    const url = `${environment.USER}user/unbookmark/` + id;
    return this.http.delete<any>(url, httpOptions);
  }
  getPosition(): Promise<any> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (resp) => {
          resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  userProfileUpdate(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        // Authorization: this.token,
      }),
    };
    const url = `${environment.USER}user/update/profile`;
    return this.http.put<any>(url, data);
  }
  userProfileDetails(params: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params: params,
    };
    const url = `${environment.USER}user/profile/details`;
    return this.http.get<any>(url, httpOptions);
  }
  userFollowedApi() {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${environment.USER}user/follow-data`;
    return this.http.get<any>(url, httpOptions);
  }
  publisherDeleteComment(params: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params: params,
    };
    const url = `${environment.COLLECTION_URL}power-user/publisher-comment`;
    return this.http.delete<any>(url, httpOptions);
  }
  createPowerUserCollection(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        // Authorization: this.token,
      }),
    };
    const url = `${environment.COLLECTION_URL}power-user`;

    return this.http.post<any>(url, data, httpOptions);
  }
  createProductCollection(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${environment.COLLECTION_URL}power-user/product`;

    return this.http.post<any>(url, data, httpOptions);
  }
  caterogoryList() {
    const url = `${environment.COLLECTION_URL}power-user/topics`;

    return this.http.get<any>(url);
  }
  subCaterogoryList(params: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params: params,
    };
    const url = `${environment.COLLECTION_URL}publisher/sub-topic`;

    return this.http.get<any>(url, httpOptions);
  }
  validateTopic(params: any) {
    const httpOptions = {
      params: params,
    };
    const url = `${environment.COLLECTION_URL}power-user/topics-exist`;

    return this.http.get<any>(url, httpOptions);
  }
  validateEmail(params: any) {
    const httpOptions = {
      params: params,
    };
    const url = `${environment.USER}user/email-exist`;

    return this.http.get<any>(url, httpOptions);
  }

  ProductList() {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${environment.COLLECTION_URL}power-user/product`;

    return this.http.get<any>(url, httpOptions);
  }
  ProductListDetails(id: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        // Authorization: this.token,
      }),
    };
    const url = `${environment.COLLECTION_URL}power-user/product/` + id;

    return this.http.get<any>(url, httpOptions);
  }
  CollectionListDetails(id: any, params: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        // Authorization: this.token,
      }),
      params: params,
    };
    const url = `${environment.COLLECTION_URL}power-user/summary/` + id;

    return this.http.get<any>(url, httpOptions);
  }
  CatergoryDetails(params: any) {
    const httpOptions = {
      params: params,
    };
    const url = `${environment.COLLECTION_URL}power-user/topics-details`;

    return this.http.get<any>(url, httpOptions);
  }
  collectionList(params: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params: params,
    };
    const url = `${environment.COLLECTION_URL}power-user`;

    return this.http.get<any>(url, httpOptions);
  }
  channelList() {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${environment.COLLECTION_URL}power-user/publisher`;

    return this.http.get<any>(url, httpOptions);
  }
  channelListSearch(params: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params: params,
    };
    const url = `${environment.COLLECTION_URL}power-user/publisher`;

    return this.http.get<any>(url, httpOptions);
  }
  channelListOnboarding(params: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params: params,
    };
    const url = `${environment.COLLECTION_URL}power-user/publisher`;

    return this.http.get<any>(url, httpOptions);
  }
  uploadCsv(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${environment.COLLECTION_URL}power-user/upload-file`;

    return this.http.post<any>(url, data, httpOptions);
  }

  subCategoryFilter(id: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        // Authorization: this.token,
      }),
    };
    const url = `${environment.COLLECTION_URL}power-user/sub-topic/` + id;

    return this.http.get<any>(url, httpOptions);
  }
  private baseUrl = `${environment.COLLECTION_URL}power-user/chat/completions`;

  chatGptkeyWords(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const body = {
      model: 'gpt-3.5-turbo',
      messages: [
        {
          role: 'user',
          content: `${data}.Extract keywords from a block of text.`,
        },
      ],
      max_tokens: 100, // Adjust as needed
    };
    return this.http.post(this.baseUrl, body, httpOptions);
  }
  chatGptkeyWordsSummarise(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const body = {
      model: 'gpt-3.5-turbo',
      messages: [
        {
          role: 'user',
          content: `${data} You have been tasked with creating a concise summary of a YouTube video using its transcription.Make a summary of the transcript.Additionally make a short complex analogy to give context and/or analogy from day-to-day life from the transcript.Create 10 bullet points with short summary (each with an appropriate emoji) that summarize the key points or important moments from the video's transcription.Create 10 bullet points (each with an appropriate emoji) that summarize the key points or important moments from the video's transcription.In addition to the bullet points, extract the most important keywords and any complex words not known to the average reader aswell as any acronyms mentioned. For each keyword and complex word, provide an explanation and definition based on its occurrence in the transcription.Please ensure that the summary, bullet points, and explanations fit within the 500-word limit, while still offering a comprehensive and clear understanding of the video's conten`,
        },
      ],
      max_tokens: 100, // Adjust as needed
    };
    return this.http.post(this.baseUrl, body, httpOptions);
  }
  powerUserUpdateCollection(id: any, data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${environment.COLLECTION_URL}power-user/summary-update/` + id;
    return this.http.put<any>(url, data, httpOptions);
  }
  powerUserDeleteCollection(id: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${environment.COLLECTION_URL}power-user/summary/` + id;
    return this.http.delete<any>(url, httpOptions);
  }
  powerUserUpdateProduct(id: any, data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${environment.COLLECTION_URL}power-user/` + id;
    return this.http.put<any>(url, data, httpOptions);
  }
  powerUserDeleteProduct(id: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${environment.COLLECTION_URL}power-user/product/` + id;
    return this.http.delete<any>(url, httpOptions);
  }
  fliterbyChannelNameProductCollection(params: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        // Authorization: this.token,
      }),
      params: params,
    };
    const url = `${environment.COLLECTION_URL}power-user/product-summary-list`;

    return this.http.get<any>(url, httpOptions);
  }
  fliterbyCreatorProductCollection(params: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        // Authorization: this.token,
      }),
      params: params,
    };
    const url = `${environment.COLLECTION_URL}creator/product-summary-list`;

    return this.http.get<any>(url, httpOptions);
  }
  creatorChannel() {
    const httpOptions = {
      headers: new HttpHeaders({
        // Authorization: this.token,
      }),
    };
    const url = `${environment.COLLECTION_URL}power-user/creator-channel`;

    return this.http.get<any>(url, httpOptions);
  }
  addCategories(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        // Authorization: this.token,
      }),
    };
    const url = `${environment.COLLECTION_URL}power-user/topics`;
    return this.http.post<any>(url, data, httpOptions);
  }
  addChannelName(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${environment.COLLECTION_URL}power-user/publisher`;
    return this.http.post<any>(url, data, httpOptions);
  }
  getSummary(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${environment.COLLECTION_URL}power-user/summary`;
    return this.http.post<any>(url, data, httpOptions);
  }
  creatorBulkProducts(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${environment.COLLECTION_URL}creator/bulk-product`;
    return this.http.post<any>(url, data, httpOptions);
  }
  createShortlinks(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${environment.COLLECTION_URL}power-user/product-short-link`;
    return this.http.post<any>(url, data, httpOptions);
  }
  creatorShortlinks(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${environment.COLLECTION_URL}creator/short-link`;
    return this.http.post<any>(url, data, httpOptions);
  }
  creatorBulkProductsView(params: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params: params,
    };

    const url = `${environment.COLLECTION_URL}creator/bulk-product`;
    return this.http.get<any>(url, httpOptions);
  }
  creatorBulkProductsLinks() {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };

    const url = `${environment.COLLECTION_URL}creator/links`;
    return this.http.get<any>(url, httpOptions);
  }
  recommendedData(params: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params: params,
    };

    const url = `${environment.COLLECTION_URL}power-user/unfollow-data`;
    return this.http.get<any>(url, httpOptions);
  }
  allCatergory() {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${environment.COLLECTION_URL}power-user/topics-list`;
    return this.http.get<any>(url, httpOptions);
  }
  SearchTopic(params: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params: params,
    };
    const url = `${environment.COLLECTION_URL}power-user/topics-list`;
    return this.http.get<any>(url, httpOptions);
  }

  // USER-SERVICE-CALLS

  loginUserDetails() {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    // console.log(this.token, 'user/me');
    const url = `${environment.USER}user/me`;

    return this.http.get<any>(url, httpOptions);
  }
  ChannelFollowList() {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${environment.USER}user/my-creator`;

    return this.http.get<any>(url, httpOptions);
  }
  ChannelUnFollowList(id: any) {
    const url = `${environment.USER}user/` + id;

    return this.http.delete<any>(url);
  }
  followCreatorChannel(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${environment.USER}user/follow-publisher`;

    return this.http.post<any>(url, data, httpOptions);
  }

  productListFliter(params: any) {
    const httpOptions = {
      params: params,
    };
    const url = `${environment.USER}home/product`;
    return this.http.get<any>(url, httpOptions);
  }
  updateChannelDetails(id: any, data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url =
      `${environment.COLLECTION_URL}power-user/update-Publisher/` + id;
    return this.http.put<any>(url, data, httpOptions);
  }
  ChannelDetails(params: any) {
    const httpOptions = {
      params: params,
    };
    const url = `${environment.USER}user/publisher-details`;
    return this.http.get<any>(url, httpOptions);
  }
  onBoardingUser() {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${environment.USER}user/onbording`;
    return this.http.get<any>(url, httpOptions);
  }
  homeFeedIdCollection(params: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params: params,
    };
    const url = `${environment.USER}home/feed-summary`;
    return this.http.get<any>(url, httpOptions);
  }
  caterogoryListFeed(params: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        // Authorization: this.token,
      }),
      params: params,
    };
    const url = `${environment.COLLECTION_URL}power-user/topics`;

    return this.http.get<any>(url, httpOptions);
  }
  catergoryDeleted(params: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params: params,
    };
    const url = `${environment.COLLECTION_URL}power-user/channel-category`;

    return this.http.delete<any>(url, httpOptions);
  }
  collectionListFliter(params: any) {
    const httpOptions = {
      params: params,
    };
    const url = `${environment.USER}home/summary`;
    return this.http.get<any>(url, httpOptions);
  }
  caterogyFollow(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${environment.USER}user/follow-topic`;
    return this.http.post<any>(url, data, httpOptions);
  }
  caterogyUnFollow(id: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${environment.USER}user/unfollow-topic/` + id;
    return this.http.delete<any>(url, httpOptions);
  }
  caterogyFollowList() {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${environment.USER}user/follow-topic-list`;
    return this.http.get<any>(url, httpOptions);
  }

  // USER BOOKMARKS AND DISCOUNT
  userDiscountProduct(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        // Authorization: this.token,
      }),
    };
    const url = `${environment.USER}user/discount-code/product`;
    return this.http.post<any>(url, data, httpOptions);
  }
  userDiscountProductGet() {
    const httpOptions = {
      headers: new HttpHeaders({
        // Authorization: this.token,
      }),
    };
    const url = `${environment.USER}user/discount-code`;
    return this.http.get<any>(url, httpOptions);
  }
  userProductGetCollection() {
    const httpOptions = {
      headers: new HttpHeaders({
        // Authorization: this.token,
      }),
    };
    const url = `${environment.USER}user/bookmark/summary`;
    return this.http.get<any>(url, httpOptions);
  }
  userCollectionGet(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${environment.USER}user/bookmark/summary`;
    return this.http.post<any>(url, data, httpOptions);
  }
  subscriberEmail(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${environment.USER}user/subscribe/user`;
    return this.http.post<any>(url, data, httpOptions);
  }
  subscribeLinkscart(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${environment.USER}user/update-user-subscribe`;
    return this.http.put<any>(url, data, httpOptions);
  }
  userProductGet() {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${environment.USER}user/bookmark/product`;
    return this.http.get<any>(url, httpOptions);
  }
  userBoarding() {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${environment.AUTH_URL}creator-onbording`;
    return this.http.get<any>(url, httpOptions);
  }
  userBoardingChannel() {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${environment.COLLECTION_URL}power-user/creator-channel`;
    return this.http.get<any>(url, httpOptions);
  }
  userFollowedList() {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${environment.USER}user/my-creator`;
    return this.http.get<any>(url, httpOptions);
  }
  userProductBookmark(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${environment.USER}user/bookmark/product`;
    return this.http.post<any>(url, data, httpOptions);
  }

  editCategroy(data: any, id: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${environment.COLLECTION_URL}power-user/update/topic/` + id;
    return this.http.put<any>(url, data, httpOptions);
  }

  DeleteProduct(id: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${environment.COLLECTION_URL}power-user/product/` + id;
    return this.http.delete<any>(url, httpOptions);
  }
  userLoginGoogle() {
    const url = `${environment.USER}user/google`;
    return this.http.get<any>(url);
  }

  // Pinteset APIS

  PinterestApi(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${environment.COLLECTION_URL}pinterest/create-pins
`;
    return this.http.post<any>(url, data, httpOptions);
  }

  userLikeCollections(id: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${environment.USER}user/like-summary?summaryId=` + id;
    return this.http.get<any>(url, httpOptions);
  }
  userDisLikeCollections(id: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${environment.USER}/user/unlike-summary?summaryId=` + id;
    return this.http.get<any>(url, httpOptions);
  }
  userCountCollection() {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${environment.USER}/user/count-like-summary`;
    return this.http.get<any>(url, httpOptions);
  }
  linkPreviewDetails(params: any) {
    const url = `https://api.linkpreview.net/?fields=image_x,image_y,icon,icon_type,site_name,`;
    return this.http.post<any>(url, params);
  }

  creatorData() {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization:
          'Basic MWIxNjVmODEtMjExNC00ZGE1LTlkM2YtNzEwMzczMmUxZThhOjgxYzM2OWUzLTUyNDItNDk5Mi04MTJlLWM3MjljNzA3NzRmYg==',
      }),
    };
    const url = `https://api.staging.getphyllo.com/v1/accounts`;
    return this.http.get<any>(url, httpOptions);
  }

  EmailSignupInformation(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${environment.COLLECTION_URL}power-user/loop`;
    return this.http.post<any>(url, data, httpOptions);
  }

  phylloAPIUserId(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${environment.COLLECTION_URL}power-user/phyllo-api`;
    return this.http.post<any>(url, data, httpOptions);
  }
  phylloAPISDkToken(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${environment.COLLECTION_URL}power-user/phyllo-api-sdk-token`;
    return this.http.post<any>(url, data, httpOptions);
  }
  phylloAPIRefreshToken(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${environment.COLLECTION_URL}power-user/phyllo-api/contents/refresh`;
    return this.http.post<any>(url, data, httpOptions);
  }
  phylloAPIACcountDetails(params: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params: params,
    };
    const url = `${environment.COLLECTION_URL}power-user/phyllo-api/profile-contents`;
    return this.http.get<any>(url, httpOptions);
  }
  phylloAPISaveAccoundDetails(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };
    const url = `${environment.USER}user/me`;
    return this.http.post<any>(url, data, httpOptions);
  }
}
