<app-navbar *ngIf="userType == 'powerUser'"></app-navbar>
<app-usernavbar *ngIf="userType == 'user'"></app-usernavbar>

 <div class="container">
     <div class="collectionall-header mt-md-5">

         <h5>Find the best collections of products from top YouTube creators in fashion, beauty, and personal care</h5>
         <div class="d-flex scroll mb-md-5">
                    <button class="category-btns pointer" (click)="navClick(items)" [class.all]="items.name == activeState" *ngFor="let items of categoryList">
                       {{items.name}}
                    </button>              
                                 
                  </div>
                     <div class="d-flex mb-md-4">
                    <div class="follow-section">
                         <h3>Follow {{activeState}} category</h3>
                         <p>Be the first one to be notified when creators post new products, and creators discount codes</p>
                    </div>
                         <div class="follow" *ngIf="followTab">
                        <button (click)="followBtn(true)" type="submit" >
                             Follow
                        </button>
 </div>
                    <div class="follow" *ngIf="followingTab">
                     
                        <button class="button" (click)="followBtn(false)" type="submit" > 
                            <span>following</span>
                        </button>
 </div>
             
                  </div>
<div class="row">

               
             <div class="col-md-4" *ngFor="let list of collectionList">
                <div class="collection-prodcuts mt-md-4 pb-md-4 pointer" (click)="collectionBtn(list.collectionName,list._id)">
                     <img src="{{
                              'https://img.youtube.com/vi/' +
                                list.sourceURL.split('=').pop() +
                                '/maxresdefault.jpg'
                            }}">
                     <P class="pt-md-2">{{list.collectionName}}</P>
                     <h6 href="/{{list.publisherName.split(' ').join('')}}"> by<a> {{list.publisherName}}</a></h6>
                </div>
             </div>
              </div>
         
     </div>
 </div>

 <app-footer ></app-footer>
