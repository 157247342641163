import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-editor-preview-dialog',
  templateUrl: './editor-preview-dialog.component.html',
  styleUrls: ['./editor-preview-dialog.component.css'],
})
export class EditorPreviewDialogComponent implements OnInit {
  content: any;
  constructor(
    private dilaog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.log(this.data.title);
    this.content = this.data.content;
  }

  ngOnInit(): void {}
}
