import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CollectionService } from 'src/app/_services/collection.service';
import { LoginService } from 'src/app/_services/login.service';
import { environment } from 'src/environments/environment';
var emailPhonePattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$';

@Component({
  selector: 'app-creator-googlesignup',
  templateUrl: './creator-googlesignup.component.html',
  styleUrls: ['./creator-googlesignup.component.css'],
})
export class CreatorGooglesignupComponent implements OnInit {
  signUpWithEmail: boolean = true;
  signUpWithName: boolean = false;
  submitted: boolean = false;
  submittedUser: boolean = false;
  SignUpUser: FormGroup;
  userName: any;
  userType: any;
  url: any = `${environment.COLLECTION_URL}publisher/google`;

  userId: any;
  error: any;
  categoryList: any;
  multipleCatergory: any;
  Email: string | null;
  EmailAddress: any;
  loader: boolean;
  constructor(
    private router: Router,
    private loginService: LoginService,
    private fb: FormBuilder,
    private collectionsservice: CollectionService,
    private activatedRoute: ActivatedRoute
  ) {
    this.SignUpUser = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      youTubeChannelId: [''],
      categoryId: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.EmailAddress = this.activatedRoute.snapshot.queryParams;
    console.log(this.EmailAddress.email);
    this.collectionsservice.caterogoryList().subscribe((res) => {
      console.log(res);
      this.categoryList = res.data;
    });
    this.Email = localStorage.getItem('signUpEmail');
    console.log(this.Email);
  }
  // signUp() {
  //   if (this.signUpEmail.invalid) {
  //     this.submitted = true;
  //   } else {
  //     this.submitted = false;
  //     this.signUpWithName = true;
  //     this.signUpWithEmail = false;
  //   }
  // }
  loginNow() {
    this.router.navigate(['/publisher_login']);
  }
  categoryEvent(e: any) {
    console.log(e.value);
    this.multipleCatergory = e.value;
  }
  login() {
    if (this.SignUpUser.invalid) {
      this.submittedUser = true;
    } else {
      this.submittedUser = false;
      this.loader = true;
      console.log(this.SignUpUser.value);

      if (this.EmailAddress.email == null) {
        let obj = {
          firstName: this.SignUpUser.value.firstName,
          lastName: this.SignUpUser.value.lastName,
          youTubeChannelId: this.SignUpUser.value.youTubeChannelId,
          categoryId: this.SignUpUser.value.categoryId,
          email: this.Email,
          password: this.SignUpUser.value.password,
        };
        console.log(obj);

        console.log('signup API call');
        this.loginService.CreatorSignUp(obj).subscribe(
          (result) => {
            console.log(result);
            this.loader = false;

            localStorage.setItem('token', result.token);
            this.router.navigate(['/Publisher/Home']);
            this.collectionsservice.loginUserDetails().subscribe((result) => {
              this.userType = result.data.userType;
              // localStorage.setItem('userName', this.userName);
              localStorage.setItem('userType', this.userType);
              localStorage.setItem('ID', this.userId);
              localStorage.removeItem('signUpEmail');
            });
          },
          (error) => {
            this.error = error.error.message;
            setTimeout(() => {
              this.error = '';
            }, 3000);
          }
        );
      } else {
        console.log('google signup API call');
        let obj = {
          firstName: this.SignUpUser.value.firstName,
          lastName: this.SignUpUser.value.lastName,
          phoneNumber: this.SignUpUser.value.phoneNumber,
          youTubeChannelId: this.SignUpUser.value.youTubeChannelId,
          categoryId: this.SignUpUser.value.categoryId,
          email: this.EmailAddress.email,
          password: this.SignUpUser.value.password,
        };
        this.loginService.googleSignupUpdate(obj).subscribe(
          (result) => {
            console.log(result);
            this.loader = false;

            localStorage.setItem('token', result.token);
            this.router.navigate(['/Publisher/Home']);
            this.collectionsservice.loginUserDetails().subscribe((result) => {
              this.userType = result.data.userType;
              // localStorage.setItem('userName', this.userName);
              localStorage.setItem('userType', this.userType);
              // localStorage.setItem('ID', this.userId);
              localStorage.removeItem('signUpEmail');
            });
          },
          (error) => {
            this.error = error.error.message;
            setTimeout(() => {
              this.error = '';
            }, 3000);
          }
        );
      }
    }
  }

  get signUpNameError(): { [key: string]: AbstractControl } {
    return this.SignUpUser.controls;
  }
}
