<!-- [ngClass]="message === 'true'?'active-sidebar':'close-sidebar'" -->
<div class="sidemenu-wrap">
  <span class="d-xl-none close-sidebar"><i class="fa fa-times"></i></span>
  <div class="sidebar-logo">
    <a href="/lookup-admin"> <h4>Summarizly</h4> </a>
  </div>

  <div class="menu-wrap">
    <ul>
      <ng-container>
        <li>
          <a
            (click)="route('lookup-admin/dashboard')"
            [class.active-route]="routeNavigate === 'lookup-admin/dashboard'"
            [class.inactive-route]="routeNavigate !== 'lookup-admin/dashboard'"
          >
            <img class="images" src="../../../assets/images/menu.png" alt="" />
            Dashboard
          </a>
        </li>
        <li>
          <a
            (click)="route('lookup-admin/summaries')"
            [class.active-route]="routeNavigate === 'lookup-admin/summaries'"
            [class.inactive-route]="routeNavigate !== 'lookup-admin/summaries'"
          >
            <img
              class="images"
              src="../../../assets/images/report.png"
              alt=""
            />

            Summaries
          </a>
        </li>
        <li>
          <a
            (click)="route('lookup-admin/users')"
            [class.active-route]="routeNavigate === 'lookup-admin/users'"
            [class.inactive-route]="routeNavigate !== 'lookup-admin/users'"
          >
            <img class="images" src="../../../assets/images/group.png" alt="" />

            Users
          </a>
        </li>
        <li>
          <a
            (click)="route('lookup-admin/publishers')"
            [class.active-route]="routeNavigate === 'lookup-admin/publishers'"
            [class.inactive-route]="routeNavigate !== 'lookup-admin/publishers'"
          >
            <img
              class="images"
              src="../../../assets/images/content.png"
              alt=""
            />

            Publishers
          </a>
        </li>
        <li>
          <a
            (click)="route('lookup-admin/comments')"
            [class.active-route]="routeNavigate === 'lookup-admin/comments'"
            [class.inactive-route]="routeNavigate !== 'lookup-admin/comments'"
          >
            <img
              class="images"
              src="../../../assets/images/content.png"
              alt=""
            />

            Comments
          </a>
        </li>
        <li>
          <a
            (click)="route('lookup-admin/topics')"
            [class.active-route]="routeNavigate === 'lookup-admin/topics'"
            [class.inactive-route]="routeNavigate !== 'lookup-admin/topics'"
          >
            <img
              class="images"
              src="../../../assets/images/categories.png"
              alt=""
            />

            Topics
          </a>
        </li>
        <li>
          <a
            (click)="route('lookup-admin/powerUser')"
            [class.active-route]="routeNavigate === 'lookup-admin/powerUser'"
            [class.inactive-route]="routeNavigate !== 'lookup-admin/powerUser'"
          >
            <img
              class="images"
              src="../../../assets/images/green-energy.png"
              alt=""
            />

            Power users
          </a>
        </li>
        <li>
          <a
            (click)="route('lookup-admin/languages')"
            [class.active-route]="routeNavigate === 'lookup-admin/languages'"
            [class.inactive-route]="routeNavigate !== 'lookup-admin/languages'"
          >
            <img
              class="images"
              src="../../../assets/images/green-energy.png"
              alt=""
            />

            Languages
          </a>
        </li>
        <li>
          <a
            (click)="route('lookup-admin/toggle')"
            [class.active-route]="routeNavigate === 'lookup-admin/toggle'"
            [class.inactive-route]="routeNavigate !== 'lookup-admin/toggle'"
          >
            <img
              class="images"
              src="../../../assets/images/green-energy.png"
              alt=""
            />

            Toggle
          </a>
        </li>
        <li>
          <a>
            <img
              class="images"
              src="../../../assets/images/refferals.jpg"
              alt=""
            />

            Referrals
          </a>
        </li>
        <li>
          <a>
            <img
              class="images"
              src="../../../assets/images/support.png"
              alt=""
            />

            Customer Support
          </a>
        </li>
        <li>
          <a>
            <img
              class="images"
              src="../../../assets/images/support.png"
              alt=""
            />

            Reports
          </a>
        </li>
      </ng-container>
    </ul>
    <!-- <p>HELP</p>
    <ul>
      <ng-container *ngFor="let help of sidemenuHelpItems; let i = index">
 <li *ngIf="help.isPermit ">
        <a routerLink="{{ help.link }}" routerLinkActive="active-page">
          <img src="{{ help.icon }}" alt="" />
          {{ help.title }}
        </a>
      </li>
      </ng-container>
     
    </ul> -->
  </div>
</div>
