import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material.module';
import { UsernavbarComponent } from './usernavbar.component';
import { NavbarComponent } from '../navbar/navbar.component';
import { SafePipePipe } from '../_models/safe-pipe.pipe';
import { FooterComponent } from '../footer/footer.component';
import { CreatornavbarComponent } from '../creatornavbar/creatornavbar.component';
import { HomenavbarComponent } from '../homenavbar/homenavbar.component';
import { LoaderComponent } from '../loader/loader.component';
import { SidenavbarComponent } from '../admin/sidenavbar/sidenavbar.component';
import { HighchartsChartModule } from 'highcharts-angular';

@NgModule({
  declarations: [
    UsernavbarComponent,
    NavbarComponent,
    SafePipePipe,
    FooterComponent,
    CreatornavbarComponent,
    HomenavbarComponent,
    LoaderComponent,
    SidenavbarComponent,
  ],
  imports: [CommonModule, MaterialModule],
  exports: [
    UsernavbarComponent,
    NavbarComponent,
    SafePipePipe,
    FooterComponent,
    CreatornavbarComponent,
    HomenavbarComponent,
    LoaderComponent,
    SidenavbarComponent,
  ],
})
export class UsernavbarModule {}
