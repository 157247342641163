import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Collection } from 'ngx-pagination';
import { CollectionService } from 'src/app/_services/collection.service';

@Component({
  selector: 'app-delete-summary',
  templateUrl: './delete-summary.component.html',
  styleUrls: ['./delete-summary.component.css'],
})
export class DeleteSummaryComponent implements OnInit {
  id: any;
  constructor(
    private dialog: MatDialog,
    private router: Router,
    private service: CollectionService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.id = this.data.id;
    console.log(this.id);
  }
  close() {
    this.dialog.closeAll();
  }
  delete() {
    this.service.powerUserDeleteCollection(this.id).subscribe((res) => {
      // console.log(res);
      // this.router.navigate(['/feed']);
      this.service.refresh();
      this.close();
    });
  }
}
