<app-navbar></app-navbar>
<div class="container">
  <div class="upload-csv mt-md-4">
    <!-- <h4 class="succesfully mt-md-3" *ngIf="csvsucceed">CSV file uploaded successfully</h4>
    <p class="ml-md-5">Upload csv</p>
    <div class="pl-4 ml-2" *ngIf="uploadFlie">
      <input style="display: none" accept=".csv" (change)="onFileSelectedTvProgram($event)" #fileInput type="file">
      <button class="btn-upload" (click)="fileInput.click()">
        <img src="../../assets/images/upload.svg" class="mr-2">Upload CSV or Excel file </button>
    </div> -->
    <!-- <div class="row mt-1 ml-md-4" *ngIf="attachmentFile">
      <div class="col-md-5">
        <div class="file-upload-view-section">
          <div class="d-flex">
            <div class="mr-auto">
              <div class="image-section d-flex">
                <div class="img-section">
                  <img src="../../assets/images/attach-file.png" class="mr-2 flie-img">
                </div>
                <div class="view_details">
                  <h5>{{fileName}}</h5>
                </div>
              </div>
            </div>
            <div class="close-img cursor">
              <img src="../../assets/images/close-pdf.png" width="30px">
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6"></div>
    </div> -->
    <!-- <div class="btn-upload-1" *ngIf="uploadBtn">
      <button type="button" (click)="uploadCsv()" class="pointer ml-md-5">Upload</button>
    </div>
  </div> -->
  <h4 class="succesfully mt-md-3" *ngIf="succeed">Publisher created successfully</h4>
  <form [formGroup]="catergoriesFormGroup">
    <div class="upload pt-4 pl-4 mt-5">
      <img *ngIf="url != null " [src]="url" width=100 height=100 alt="" class="upload-img">
      <img *ngIf="url == null " src="../../assets/images/place-holder.jpg" width=100 height=100 alt="" class="upload-img">
      <div class="round">
        <input type="file" accept="image/*" formControlName="profile" (change)="onFileSelected($event)">
        <img src="../../assets/images/camera.svg" width=100 height=100 alt="" class="camera-img">
      </div>
    </div>
    <div *ngIf="c.profile.errors?.required && submiited" class="invalid-feedback ml-md-2 pt-md-5 d-block">Profile is required </div>

    <!-- <button class="btn btn-primary" (click)="getData()">Get youtube data</button> -->
    <!-- <div class="row">
      <div class="col-md-6">
        
        <div class="catergories-add-section mt-md-2">
          <label>Channel name</label>
          <input formControlName="name" type="text" class="form-control" placeholder="Enter the channel name">
          <div *ngIf="c.name.errors?.required && submiited" class="invalid-feedback m-t d-block">Channel name is required </div>
           <div  *ngIf="c.name.errors?.pattern && submiited" class="invalid-feedback m-t d-block">
    Username must start with "@"
  </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="catergories-add-section mt-md-2">
          <label>Description</label>
          <input type="text" class="form-control" formControlName="description" placeholder="Enter the description">
            <div *ngIf="c.description.errors?.required && submiited" class="invalid-feedback m-t d-block">Description is required </div>  
        </div>
      </div>
    </div> -->
    <div class="row">
      <div class="col-md-6">
        <div class="catergories-add-section mt-md-2">
          <label>Publisher name</label>
          <input formControlName="name" type="text" class="form-control" placeholder="Enter the publisher name">
          <div *ngIf="c.name.errors?.required && submiited" class="invalid-feedback m-t d-block">Publisher name is required </div>
           <div  *ngIf="c.name.errors?.pattern && submiited" class="invalid-feedback m-t d-block">
    Username must start with "@"
  </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="catergories-add-section la mt-md-2">
          <label>Channel url</label>
          <input type="text" class="form-control" formControlName="url" placeholder="Enter the channel url">
           <h6> Please enter your youtube channel URL. Example:<span class="text-decoration-underline">www.youtube.com@bbc</span></h6>
          <div *ngIf="c.url.errors?.required && submiited" class="invalid-feedback m-t d-block">Channel url is required </div>
          <div *ngIf="c.url.errors?.pattern && submiited" class="invalid-feedback m-t d-block"> Please enter valid youtube link. </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="catergories-add-section mt-md-2">
          <label>Description</label>
          <input type="text" class="form-control" formControlName="description" placeholder="Enter the description">
            <div *ngIf="c.description.errors?.required && submiited" class="invalid-feedback m-t d-block">Description is required </div>  
        </div>
      </div>
      <!-- <div class="col-md-6">
        <div class="catergories-add-section mt-md-2">
          <label>Channel owner name</label>
          <input formControlName="ownerName" type="text" class="form-control" placeholder="Enter the channel owner name">
          <div *ngIf="c.ownerName.errors?.required && submiited" class="invalid-feedback m-t d-block">Channel owner name is required </div>
        </div>
      </div> -->
      <div class="col-md-6">
        <div class="catergories-add-section mt-md-2">
          <p class="m-0">Topic</p>
          <mat-form-field appearance="fill">
            <mat-select formControlName="category" multiple placeholder="Select" disableOptionCentering panelClass="eligablePanelClass" (selectionChange)="categoryEvent($event)" #closeattendance>
              <mat-option class='services-view' *ngFor="let list of categoryList" [value]="list._id">{{list.name}}</mat-option>
              <div class="apply-btn-option pt-4 pb-3"></div>
            </mat-select>
          </mat-form-field>
          <div *ngIf="c.category.errors?.required && submiited" class="invalid-feedback m-t d-block">Topic is required </div>
        </div>
      </div>
    </div>
    <div class="row">
      <!-- <div class="col-md-6">
        <div class="catergories-add-section mt-md-2">
          <label>Followers count</label>
          <input formControlName="followersCount" type="text" class="form-control" placeholder="Enter the followers count">
           
        </div>
      </div> -->
     
      
    </div>
     <div class="row">
       
        <div class="col-md-6">
       <div class="radio-button-section mt-2">
      <p class="mb-3">Country</p>
      <mat-radio-group formControlName="country" (change)="Country($event)">
        <mat-radio-button *ngFor="let country of country" [disableRipple]="true" value="{{country}}" name="Website" class="mr-2">{{country}}</mat-radio-button>
      </mat-radio-group>
     
    </div>
      </div>
      <div class="col-md-6">
        
        <!-- <div class="catergories-add-section mt-md-2">
          <label>Channel name</label>
          <input formControlName="name" type="text" class="form-control" placeholder="Enter the channel name">
          <div *ngIf="c.name.errors?.required && submiited" class="invalid-feedback m-t d-block">Channel name is required </div>
           <div  *ngIf="c.name.errors?.pattern && submiited" class="invalid-feedback m-t d-block">
    Username must start with "@"
  </div>
        </div> -->
      </div>
    
    </div>
    <div class="submit-btn">
      <button type="button" class="pointer" (click)="submit()">Submit</button>
    </div>
  </form>
</div>