import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-publisher-videos',
  templateUrl: './publisher-videos.component.html',
  styleUrls: ['./publisher-videos.component.css'],
})
export class PublisherVideosComponent implements OnInit {
  syncNow: boolean = true;
  ManageButton: boolean = false;

  thumbnailData: any[] = [
    {
      img: 'https://img.youtube.com/vi/QRZ_l7cVzzU/hqdefault.jpg',
      title: 'How to start a company in USA',
      desc: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt, similique. Numquam hic aut vel architecto modi in, labore ut assumenda quibusdam maiores atque neque nesciunt saepe ratione aliquam voluptatibus sapiente?',
    },
    {
      img: 'https://img.youtube.com/vi/Th8JoIan4dg/hqdefault.jpg',
      title: 'How to start a company in USA',
      desc: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt, similique. Numquam hic aut vel architecto modi in, labore ut assumenda quibusdam maiores atque neque nesciunt saepe ratione aliquam voluptatibus sapiente?',
    },
    {
      img: 'https://img.youtube.com/vi/je8G5QK0VEo/hqdefault.jpg',
      title: 'How to start a company in USA',
      desc: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt, similique. Numquam hic aut vel architecto modi in, labore ut assumenda quibusdam maiores atque neque nesciunt saepe ratione aliquam voluptatibus sapiente?',
    },
    {
      img: 'https://img.youtube.com/vi/3xU050kMbHM/hqdefault.jpg',
      title: 'How to start a company in USA',
      desc: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt, similique. Numquam hic aut vel architecto modi in, labore ut assumenda quibusdam maiores atque neque nesciunt saepe ratione aliquam voluptatibus sapiente?',
    },
    {
      img: 'https://img.youtube.com/vi/ephzgxgOjR0/hqdefault.jpg',
      title: 'How to start a company in USA',
      desc: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt, similique. Numquam hic aut vel architecto modi in, labore ut assumenda quibusdam maiores atque neque nesciunt saepe ratione aliquam voluptatibus sapiente?',
    },
    {
      img: 'https://img.youtube.com/vi/M93xlPxQADE/hqdefault.jpg',
      title: 'How to start a company in USA',
      desc: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt, similique. Numquam hic aut vel architecto modi in, labore ut assumenda quibusdam maiores atque neque nesciunt saepe ratione aliquam voluptatibus sapiente?',
    },
  ];
  showVideos: boolean = false;
  showLoader: boolean = false;
  constructor() {}

  ngOnInit(): void {}

  syncNowButton() {
    this.showLoader = true;
    setTimeout(() => {
      this.showVideos = true;
      this.syncNow = false;
      this.showLoader = false;
      this.ManageButton = true;
    }, 3000);
  }
}
