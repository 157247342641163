import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { AdminService } from 'src/app/_services/admin.service';
const emailPhonePattern =
  '^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+.)+([a-zA-Z0-9]{2,4})|(^[0-9]{10})+$';

@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.css'],
})
export class AdminLoginComponent implements OnInit {
  hide = true;
  loginForm: FormGroup;
  submitted: boolean = false;
  error: any;
  token: any;
  errorMsg: boolean = false;
  admin: string = 'admin';
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private adminService: AdminService
  ) {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(emailPhonePattern)]],
      password: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    if (localStorage.getItem('admin') != null) {
      this.router.navigate(['/lookup-admin/summaries']);
    }
  }
  login() {
    if (this.loginForm.invalid) {
      this.submitted = true;
    } else {
      console.log(this.loginForm.value);
      let obj = {
        email: this.loginForm.value.email,
        password: this.loginForm.value.password,
        userType: 'Admin',
      };
      this.adminService.login(obj).subscribe(
        (result) => {
          console.log(result);
          this.token = result.token;
          this.loginForm.reset();
          localStorage.setItem('token', this.token);
          localStorage.setItem('admin', result.data.userType);
          this.router.navigate(['/lookup-admin/summaries']);
        },
        (err) => {
          console.log(err.error.message);
          this.error = err.error.message;
          setTimeout(() => {
            this.error = '';
          }, 3000);
        }
      );
    }
  }
  get f(): { [key: string]: AbstractControl } {
    return this.loginForm.controls;
  }
}
