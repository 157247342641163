<app-usernavbar></app-usernavbar>

<app-loader *ngIf="loader"></app-loader>
<div class="user_followed_p container">
  <div class="header_p">
    <h5>Followed Topics</h5>

    <div class="d-flex border-p" *ngFor="let item of data">
      <div class="">
        <img *ngIf="item?.topicImage != null" src="{{ item?.topicImage }}" />
        <img
          *ngIf="item?.topicImage == null"
          src="../../assets/images/user-1.png"
          width="40px"
        />
      </div>
      <div class="name_publisher">
        <h5>{{ item?.name ? item.name : "N/A" }}</h5>
        <p *ngIf="item.subTopics != ''">
          Subtopics :
          <span *ngFor="let sub of item.subTopics; let last = last"
            >{{ sub }}{{ !last ? ", " : "" }}</span
          >
        </p>
      </div>
      <div class="ml-auto follow">
        <a (click)="unfollow(item)"><span>following</span></a>
      </div>
    </div>
  </div>
</div>
