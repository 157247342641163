<!-- <div class="main">
  <app-homenavbar></app-homenavbar>

  <div class="container-fluid">
    <div class="login-section">
      <div class="row flex-column-reverse flex-md-row">
        <app-loader *ngIf="loader"></app-loader>

        <div class="col-md-6">
          <div
            class="login-section-common-data pl-md-5 pt-md-5"
            id="login-para"
          >
            <h4 class="ml-md-2 mt-md-3 pb-md-1">
              Turn youtube videos into AI Blogs
            </h4>
            <h6>Why Summarizly AI?</h6>

            <div class="d-flex points mt-3">
              <img src="../../assets/images/check-1.png" class="check-1" />
              <p class="ml-2">Create an AI blog in seconds and publish</p>
            </div>
            <div class="d-flex points mt-2">
              <img src="../../assets/images/check-1.png" class="check-1" />
              <p class="ml-2">
                Grow your audience and make money from each blog
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="login-form-section" *ngIf="loginPage">
            <div class="form-section mt-3">
              <h4>Publisher login</h4>

              <form [formGroup]="loginFormGroup">
                <div class="email-form-section mt-md-2">
                  <input
                    class="form-control"
                    formControlName="email"
                    placeholder="Enter email address"
                  />
                  <div
                    *ngIf="f.email.errors?.required && submitted"
                    class="invalid-feedback m-t d-block"
                  >
                    Email is required
                  </div>
                  <div
                    *ngIf="f.email.errors?.email && submitted"
                    class="invalid-feedback m-t d-block"
                  >
                    Email is invalid
                  </div>
                </div>
                <div class="password-form-section mt-md-4 pt-md-1">
                  <input
                    type="password"
                    class="form-control"
                    formControlName="password"
                    placeholder="Enter your password"
                  />
                  <div
                    *ngIf="f.password.errors?.required && submitted"
                    class="invalid-feedback m-t d-block"
                  >
                    Password is required
                  </div>
                  <div
                    *ngIf="loginErrorMessage != null"
                    class="invalid-feedback m-t d-block"
                  >
                    {{ loginErrorMessage }}
                  </div>
                </div>
              </form>
            </div>
            <div class="sign-in-section mt-md-4 pt-md-2">
              <div class="d-flex">
                <div class="btn-signin">
                  <button class="pointer" (click)="login()" type="submit">
                    SIGN IN
                  </button>
                </div>
                <div class="forgot-password pointer">
                  <h6>
                    <a (click)="forGotPassword()">Forgot password?</a>
                  </h6>
                </div>
              </div>
            </div>
            <div class="google-intergration">
              <p class="py-md-4">OR</p>
              <div class="google-btn">
                <button type="submit">
                  <a [href]="url | safePipe">
                    <img
                      src="../../assets/images/google.png"
                      class="google mr-md-3"
                    />Sign in via Google
                  </a>
                </button>
              </div>
              <h6>
                Don’t have an account?
                <span class="pointer" (click)="signUp()">Sign up for free</span>
              </h6>
            </div>
          </div>
          <div class="login-form-section mt-md-5" *ngIf="resetPassword">
            <div class="form-section">
              <h4>Reset your password</h4>
              <form [formGroup]="forGotPasswordForm">
                <div class="email-form-section mt-md-5">
                  <input
                    (keydown.space)="$event.preventDefault()"
                    class="form-control"
                    formControlName="email"
                    placeholder="Enter email address"
                  />
                  <div
                    *ngIf="r.email.errors?.required && submittedPassword"
                    class="invalid-feedback m-t d-block"
                  >
                    Email is required
                  </div>
                  <div
                    *ngIf="r.email.errors?.email && submittedPassword"
                    class="invalid-feedback m-t d-block"
                  >
                    Email is invalid
                  </div>
                  <div
                    *ngIf="errorMessage != null"
                    class="invalid-feedback m-t d-block"
                  >
                    {{ errorMessage }}
                  </div>
                </div>
              </form>
            </div>
            <div class="sign-in-section mt-md-4 pt-md-2">
              <div class="d-flex">
                <div class="btn-signin">
                  <button
                    class="pointer"
                    type="submit"
                    (click)="resetPasswordBtn()"
                  >
                    Reset Password
                  </button>
                </div>
              </div>
            </div>
            <div class="google-intergration pt-md-3">
              <h6>
                Don’t have an account?
                <span class="pointer" (click)="signUp()">Sign up for free</span>
              </h6>
            </div>
          </div>

          <div class="password-changed mt-4" *ngIf="passwordChangedSuccesfully">
            <img src="../../assets/images/check.png" class="success-img" />
            <p class="mt-4">
              Password reset link has been sent to your email. Please check your
              inbox.
            </p>
            <h6>
              In case if you don’t see it consider checking your spam folder
              too, in case
            </h6>

            <div class="d-flex after-success justify-content-center">
              <a class="mr-4 pointer" (click)="loginNow()">Login now</a>
              <a class="pointer" (click)="home()">Go to home</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->

<div class="main">
  <div class="publisher_singup bg-img">
    <div class="Publisher_header">
      <div class="details_publisher">
        <div>
          <h3>A picture is worth 1000 words</h3>
          <h3>Isn't your video worth 10,000 words?</h3>
          <div class="singup_view">
            <!-- <p>Turn youtube videos into AI blogs in seconds</p> -->
            <p>
             Turn YouTube videos into AI summaries. Magically, Share it with the world in 90+ languages
<span>in seconds</span>
            </p>
            <div class="google-btn">
              <button type="submit">
                <a [href]="url | safePipe">
                  <img
                    src="../../assets/images/google.png"
                    class="google mr-md-3"
                  />Continue with Google
                </a>
              </button>
            </div>

            <a class="works" (click)="howItworks()">How it works?</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
