import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'profileCategroypipe',
})
export class ProfileCategroypipePipe implements PipeTransform {
  transform(value: string[]): string[] {
    if (!value) return value;

    return value.slice().sort((a, b) => a.localeCompare(b));
  }
}
