import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  constructor(
    private http: HttpClient,
    private router: Router,
    @Inject(PLATFORM_ID) platformId: Object
  ) {}

  //admin-collection-list
  adminSummariesList(params: any) {
    const httpOptions = {
      params: params,
    };
    const url = `${environment.ADMIN_URL}summary/summeries`;
    return this.http.get<any>(url, httpOptions);
  }
  reportsList(params: any) {
    const httpOptions = {
      params: params,
    };
    const url = `${environment.ADMIN_URL}report/list`;
    return this.http.get<any>(url, httpOptions);
  }
  // adminSummariesExport(params: any) {
  //   const httpOptions = {
  //     params: params,
  //   };
  //   const url = `${environment.ADMIN_URL}summary/export-summaries`;
  //   return this.http.get<any>(url, httpOptions);
  // }
  public adminSummariesExport(params: any): Observable<any> {
    const url = `${environment.ADMIN_URL}summary/export-summaries`;

    return this.http.get(url, {
      responseType: 'blob',
      params: params,
    });
  }
  public adminSummariesXml(params: any): Observable<any> {
    const url = `${environment.ADMIN_URL}summary/export-summaries/xml`;

    return this.http.get(url, {
      responseType: 'blob',
      params: params,
    });
  }
  adminPublisherList(params: any) {
    const httpOptions = {
      params: params,
    };
    const url = `${environment.ADMIN_URL}summary/publisher`;
    return this.http.get<any>(url, httpOptions);
  }
  adminPowerUserList(params: any) {
    const httpOptions = {
      params: params,
    };
    const url = `${environment.ADMIN_URL}power-user/list`;
    return this.http.get<any>(url, httpOptions);
  }
  adminSummariesHide(params: any) {
    const httpOptions = {
      params: params,
    };
    const url = `${environment.ADMIN_URL}language/list`;
    return this.http.get<any>(url, httpOptions);
  }
  adminTopicList(params: any) {
    const httpOptions = {
      params: params,
    };
    const url = `${environment.ADMIN_URL}topic/list`;
    return this.http.get<any>(url, httpOptions);
  }
  adminCommentsList(params: any) {
    const httpOptions = {
      params: params,
    };
    const url = `${environment.ADMIN_URL}summary/all/comment-list`;
    return this.http.get<any>(url, httpOptions);
  }
  adminUserList(params: any) {
    const httpOptions = {
      params: params,
    };
    const url = `${environment.ADMIN_URL}summary/user-summeries`;
    return this.http.get<any>(url, httpOptions);
  }
  getToggle() {
    const url = `${environment.ADMIN_URL}toggle/list`;
    return this.http.get<any>(url);
  }
  subscibersList() {
    const url = `${environment.ADMIN_URL}publisher/summary/analytics`;
    return this.http.get<any>(url);
  }
  getToggleStatus(data: any, id: any) {
    const url = `${environment.ADMIN_URL}toggle/update-status/` + id;
    return this.http.put<any>(url, data);
  }

  deleteUser(id: any) {
    const url = `${environment.ADMIN_URL}summary/user/` + id;
    return this.http.delete<any>(url);
  }
  deletePublisher(id: any) {
    const url = `${environment.ADMIN_URL}publisher/` + id;
    return this.http.delete<any>(url);
  }
  deleteCollection(id: any) {
    const url = `${environment.ADMIN_URL}summary/` + id;
    return this.http.delete<any>(url);
  }
  activeInactiveCollection(data: any, id: any) {
    const url = `${environment.ADMIN_URL}summary/active-inactive/` + id;
    return this.http.put<any>(url, data);
  }
  reportStatus(data: any, id: any) {
    const url = `${environment.ADMIN_URL}report/update-status/` + id;
    return this.http.put<any>(url, data);
  }
  reportStatusSummary(data: any, id: any) {
    const url = `${environment.ADMIN_URL}language/update-status/` + id;
    return this.http.put<any>(url, data);
  }
  removeAcess(text: any, id: any) {
    const httpOptions = {};
    const url =
      `${environment.ADMIN_URL}power-user/remove-access/` +
      id +
      `?isActive=${text}`;
    return this.http.put<any>(url, httpOptions);
  }
  removeAcessTopic(data: any, id: any) {
    const httpOptions = {};
    const url = `${environment.ADMIN_URL}topic/update-status/` + id;
    return this.http.put<any>(url, data, httpOptions);
  }
  adminDeleteComment(params: any) {
    const httpOptions = {
      params: params,
    };
    const url = `${environment.ADMIN_URL}summary/delete-comment`;
    return this.http.delete<any>(url, httpOptions);
  }

  //admin-login
  login(data: any) {
    return this.http
      .post<any>(`${environment.ADMIN_URL}summary/login`, data)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  //pulibhser-admin-apis
  PublisherBlogsList(params: any) {
    const httpOptions = {
      params: params,
    };
    const url = `${environment.ADMIN_URL}publisher/summary`;
    return this.http.get<any>(url, httpOptions);
  }
  PublisherAnalytics(params: any) {
    const httpOptions = {
      params: params,
    };
    const url = `${environment.ADMIN_URL}publisher/summary/analytics`;
    return this.http.get<any>(url, httpOptions);
  }
  PublisherComments(params: any) {
    const httpOptions = {
      params: params,
    };
    const url = `${environment.ADMIN_URL}publisher/summary/comment`;
    return this.http.get<any>(url, httpOptions);
  }
  publisherProfileUpdate(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        // Authorization: this.token,
      }),
    };
    const url = `${environment.AUTH_URL}publisher`;
    return this.http.put<any>(url, data);
  }
}
