import { Component, Inject, OnInit } from '@angular/core';

import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-comment-section-dialog',
  templateUrl: './comment-section-dialog.component.html',
  styleUrls: ['./comment-section-dialog.component.css'],
})
export class CommentSectionDialogComponent implements OnInit {
  title: any;
  buttonText: string = 'Copy Link';
  copied: boolean = false;
  video: any;
  videoTag: boolean = false;
  constructor(
    private dilaog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    console.log(this.data.link);
    this.title = this.data.link;
    this.video = this.data.video;
  }
  close() {
    this.dilaog.closeAll();
  }
  copyLink() {
    // Your logic for copying the link goes here
    // For demonstration purposes, we'll just toggle the copied state and update the button text
    this.copied = true;
    this.videoTag = true;
    this.buttonText = 'Copied';
  }
  onKeyDown(event: KeyboardEvent) {
    // Prevent any changes to the input value
    event.preventDefault();
  }
}
