
       <app-publisher-onboarding-dialog *ngIf="totalSummary == 0"></app-publisher-onboarding-dialog>







<div class="container-fluid px-5" *ngIf="totalSummary != 0">
     <div class="Your_blog mt-md-3">
        <h6>Analytics</h6>
         <!-- <div class="blogs-Button_section">
             <button [class.active]="buttons.value == activeState" *ngFor="let buttons of languageButtons" (click)="changelanguage(buttons.value)">
{{buttons.name}}
            </button>
         </div> -->

<div class="card-section my-md-4 d-flex justify-content-between">
        <div class="card mr-md-5" *ngFor="let item of items">
            <div class="card-body">
                <h3>{{item.number ? item.number : 0}}</h3>
                <p>{{item.name}}</p>
            </div>
        </div>
     </div>
   

     <div class="charts_section pb-md-5 mb-md-5">
        <!-- <div class="spline text-center">
            <h5>Traffic to the blog</h5>
<highcharts-chart 
             [Highcharts]="highcharts" 
             [options]="chartOptions" 
             style="width: 1000px; height: 500px;display:block"></highcharts-chart>
        </div> -->






        <div class="spline text-center mt-md-5">
            <div class="d-flex justify-content-between">
                <div>
                     <h5>Subscribers</h5>
            <p>your Summary has a total of {{subscribers}}  subscribers</p>
                </div>

                 <div class="d-flex language-header mt-md-4">
            <p>Date</p>
            <select (change)="dateChange($event)"  class="selectpicker" >
                <option *ngFor="let item of Language" [selected]="item == 'month'"  value="{{item}}">{{item}}</option>
            </select>
        </div>
            </div>
<highcharts-chart 
             [Highcharts]="highcharts" 
             [options]="chartOptionsBar" 
             style="width: 1000px; height: 500px;display:block"></highcharts-chart>
        </div>














        <div class="spline text-center mt-md-5">
             <h5>Top performing Summary categories</h5>
<highcharts-chart 
             [Highcharts]="highcharts" 
             [options]="chartOptionsRevered" 
             style="width: 1000px; height: 500px;display:block"></highcharts-chart>
        </div>
        
     </div>
    </div>
