import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PublisherhomepageComponent } from './publisherhomepage/publisherhomepage.component';
import { PublisherBlogsComponent } from './publisher-blogs/publisher-blogs.component';
import { PublisherContentComponent } from './publisher-content/publisher-content.component';
import { PublisherDashboardComponent } from './publisher-dashboard/publisher-dashboard.component';
import { PublisherAnalyticsComponent } from './publisher-analytics/publisher-analytics.component';
import { PublisherSubscribersComponent } from './publisher-subscribers/publisher-subscribers.component';
import { PublisherCommentsComponent } from './publisher-comments/publisher-comments.component';
import { PublisherProfileComponent } from './publisher-profile/publisher-profile.component';
import { LoginGuard } from '../_services/login.guard';

const routes: Routes = [
  {
    path: 'Home',
    component: PublisherBlogsComponent,
  },
  {
    path: 'Blogs',
    component: PublisherBlogsComponent,
  },
  {
    path: 'dashboard',
    component: PublisherDashboardComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'analytics',
    component: PublisherAnalyticsComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'subscribers',
    component: PublisherSubscribersComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'comments',
    component: PublisherCommentsComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'content',
    component: PublisherContentComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'profile',
    component: PublisherProfileComponent,
    canActivate: [LoginGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PublisherPortalRoutingModule {}
