<app-navbar *ngIf="sessionType == 'powerUser'"></app-navbar>
<app-usernavbar *ngIf="sessionType == 'user'"></app-usernavbar>
<app-homenavbar *ngIf="sessionType == 'home'"></app-homenavbar>
<div class="main">
<div class="container">

   
    
        <div class="list-of-products mt-md-3 pt-md-3">

            <div class="nav-header ">
                <h6>Find products promoted and curated by top youtube creators in fashion, beauty, skin care</h6>
                <h3>Find all creators discount codes at one place</h3>
                <div class="">
                    
                </div>
                <div class="d-flex scroll btns-c mb-md-5">
                    <button class="category-btns pointer"  (click)="navClick(items)" [class.all]="items.name == activeState" *ngFor="let items of categoryList let i= index;">
                       {{items.name}}
                    </button>              
                  </div>

                  <div class="d-flex mb-md-4" *ngIf="activeState != 'All'">
                    <div class="follow-section">
                         <h3>Follow {{activeState}} category</h3>
                         <p>Be the first one to be notified when creators post new products, and creators discount codes</p>
                    </div>
                    <div class="follow" *ngIf="followTab">
                        <button (click)="followBtn(true)" type="submit" >
                             Follow
                        </button>
 </div>
                    <div class="follow" *ngIf="followingTab">
                     
                        <button class="button" (click)="followBtn(false)" type="submit" > 
                            <span>following</span>
                        </button>
 </div>
                   
                  </div>
               

        <div class="row">
  
            <div class="col-md-4" *ngFor="let cardDetails of productList;let i = index">
                  <div class="card mb-md-4 pointer" (click)="productCard(cardDetails.productTitle,cardDetails._id)">
                    <div class="card-body">
                       <div class="product_list">
                         <div class="d-flex list-P">
                                 <!-- <div class="pin-it">
                                    <button type="button">Pin it</button>
                                 </div>
                                 <div class="link_it ml-auto">
                                    <button type="button">Link.it</button>
                                 </div> -->
                             </div>
                                                 <img *ngIf="cardDetails?.image == null && cardDetails?.productImage == ''"  src="../../../assets/images/n-image.png" class="code-1-img image">

                               <img *ngIf="cardDetails?.image != null "  src="{{cardDetails?.image}}" class="code-1-img image">
                    <img *ngIf="cardDetails?.productImage != ''" src="{{cardDetails?.productImage[0]?.url}}" class="code-1-img">
                             <!-- <img src="https://linkscart.s3.amazonaws.com/productImage-1680008517658-51VX%2BQeZjRL._SX679_.jpg" class="code-1-img">  -->

                             <p class="header-p py-3">{{cardDetails.productTitle  | slice:0:24}}</p>
                       </div>

                       <div class="product_list_details">

                         <div class="d-flex">
                             <!-- <div class="list_price">
                                 <p>curated by {{cardDetails.channelName}}</p>
                             </div> -->
                             <div class="product_list_sold_on ml-auto">
                                 <h4>Sold on</h4>
                                <p>{{cardDetails.affiliatePartnerName ? cardDetails.affiliatePartnerName : 'N/A'}}</p>

                             </div>
                         </div>

                         <div class="details-List-view">

                            <div *ngIf="cardDetails.couponCode != ''">
                            <p>Creator Discount Code Available</p>
                             <div class="creator-code">
                           
                           <div class="d-flex code-d">
                            <div class="code-p">
                               <p *ngIf="show">{{cardDetails.couponCode}}</p>
                               <p *ngIf="hide">*****</p>


                            </div>
                            <div class="copy-btn ml-auto">
                               <button type="button" class="ml-auto pointer" href="/chandra/product/{{cardDetails.productTitle.split(' ').join('-')}}/id-{{cardDetails._id}}"   (click)="showCoupon()">Show</button>
 
                            </div>
                           </div>
                            </div>
                            
                        </div>
                        <a>
                            <h5 class="mt-md-2 pointer" (click)="share(cardDetails.productTitle,cardDetails._id)">Share this product with friends</h5>
                            </a>
                            
                         </div>
                       </div>

                    </div>
                  </div>
            </div>
        </div>
            </div>
       
    </div>
</div>

</div>


 
 <app-footer ></app-footer>
