import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { CollectionService } from '../_services/collection.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css'],
})
export class PrivacyPolicyComponent implements OnInit {
  sessionType: string;
  constructor(
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document,
    private collectionService: CollectionService
  ) {}

  ngOnInit(): void {
    if (this.collectionService.getToken() == '') {
      console.log('not-login');
      this.sessionType = 'home';
    }
    if (this.collectionService.getToken() != '') {
      this.collectionService.loginUserDetails().subscribe((result) => {
        this.sessionType = result.data.userType;
      });
    }
    let script = this._renderer2.createElement('script');
    script.type = `text/javascript`;
    script.text = `
              (function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);
        `;
    this._renderer2.appendChild(this._document.body, script);
  }
}
