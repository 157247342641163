import { Component, Inject, OnInit } from '@angular/core';

import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-shareproduct-link-dialog',
  templateUrl: './shareproduct-link-dialog.component.html',
  styleUrls: ['./shareproduct-link-dialog.component.css'],
})
export class ShareproductLinkDialogComponent implements OnInit {
  // link: any = `${environment.SHARE_URL}`;
  title: any;
  constructor(
    private router: Router,
    private dilaog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    // this.data = data;
    console.log(this.data.title);
    this.title = this.data.title;
  }

  close() {
    this.dilaog.closeAll();
  }
}
