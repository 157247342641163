<app-homenavbar *ngIf="sessionType == 'home'"></app-homenavbar>
<app-usernavbar *ngIf="sessionType == 'user'"></app-usernavbar>
<app-navbar *ngIf="sessionType == 'powerUser'"></app-navbar>
<app-creatornavbar *ngIf="sessionType == 'publisher'"></app-creatornavbar>

<div class="container">
    <!-- <div class="update-channel mt-5">
    <h5 class="mt-4 mb-3">Edit channel</h5>
        <div class="row">
            <div class="col-md-3" *ngFor="let item of ChannelName">
               <div class="card mb-3">
                  <div class="card-body">
                    <div class="edit-img">
                      <img src="../../../assets/images/e.png" (click)="updateChannel(item)">
                    </div>
                     <div class="item-class">
                        <img src="{{item.profile}}">
                        <p>{{item.name}}</p>
                     </div>
                  </div>
               </div>
            </div>
        </div>
    </div> -->
     <div class=" mt-5">
    <div class="d-flex fliterTopics">
        <div class="update-topic">
          <h4>Fliter channel</h4>
        </div>
        <div class="d-flex">
          <p>by channel name</p>
           <div class="search_bar">
        <input type="text" (input)="seachPublisher($event)" [(ngModel)]="optionReset" class="form-control" placeholder="Search by publisher name">
    </div>
        </div>
        <div class="d-flex ml-md-4">
           <p>sort by letter</p>
            <span class="letters" *ngFor="let letter of letters"><a (click)="searchByLetter(letter)">{{letter}}</a></span>
        </div>
           <div class="selct_by_option">
                   <img src="../../../assets/images/undo.png" (click)="reset()" id="undo">
               </div>
    </div>

    <h6 class="total">Total {{count}} publishers found</h6>
            <h6 class="not-found" *ngIf="ChannelName == ''">No publisher found with 
              <span *ngIf="PublisherName !=''">name {{PublisherName}}</span>
              <span *ngIf="searchByLetterName != ''">letter {{searchByLetterName}}</span>
            </h6>
    <div class="row">
      <div class="col-md-4" *ngFor="let item of ChannelName;let i = index">
        <div class="card mb-3">
           <div class="card-body">
                    <div class="edit-img">
                      <img src="../../../assets/images/eee.png" (click)="updateChannel(item)">
                    </div>
                     <div class="item-class mt-md-5">
                        <img src="{{item.profile}}">
                        <!-- <img src="../../../assets/images/categories.png"> -->
                        <p>{{item.name}}</p>
                     </div>
                     <div class="count_of d-flex justify-content-around">
                         <h5>{{item.numberOfTopics}} topics</h5>
                         <h5><a  [href]="item.name" target="_blank">{{item.numberOfSummaries}} summaries</a></h5>
                     </div>
                     <div class="subtopics_view mt-md-4">
                         <h6 >Available topics</h6>
                         <div class="bg-subtopics">
                           
                            <ul class="subtopic_flex">
      <ng-container *ngIf="item.topic && item.topic.length > 0; else noSubtopics">
        <li *ngFor="let subtopic of item.topic" >
          <p  class="sub_available">{{ subtopic.name }}</p>
        </li>
      </ng-container>
      <ng-template #noSubtopics>
        <li class="no-subtopic">
          <p>No topics</p>
        </li>
      </ng-template>
    </ul>
                         </div>
                     </div>
                  </div>
        </div>
        </div>
        </div>
  </div>
</div>