<div class="comment_section_main">
    <div class="comment_close cursor" (click)="close()">
        <img src="../../../assets/images/close.png">
    </div>
    <div class="comment_diloag">
         <h4>Great choice. You are helping many youtube community members to read this summary and save their time</h4>

         <p>Copy this link and comment this on youtube video</p>

         <div class="copy_link d-md-flex justify-content-start ml-md-4 pl-md-4">
            <input (keydown)="onKeyDown($event)"  readonly type="text" [(ngModel)]="title">
            <button class="cursor"  (click)="copyLink()"  [disabled]="copied" [cdkCopyToClipboard]="title">{{ buttonText }}</button>
         </div>
         <div class="youtube_comment" *ngIf="videoTag">
             <div class="d-flex justify-content-md-end">
                <img src="../../../assets/images/comment.png">
                <a class="text-decoration-underline" target="_blank" [href]="video">Write comment on Youtube</a>
             </div>
         </div>
    </div>
</div>