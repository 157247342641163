import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { NoAuthSignupFollowComponent } from '../no-auth-signup-follow/no-auth-signup-follow.component';
import { NoAuthSignupComponent } from '../no-auth-signup/no-auth-signup.component';
import { CollectionService } from '../_services/collection.service';

@Component({
  selector: 'app-creator-profile',
  templateUrl: './creator-profile.component.html',
  styleUrls: ['./creator-profile.component.css'],
})
export class CreatorProfileComponent implements OnInit {
  activeState: any;
  productName: any;
  username: any;
  queryParms: any;
  channelName: any;
  productList: any;
  type: any = 'product';
  collectionList: any;
  activeProduct: any = true;
  productShow: any = true;
  collectionShow: any = false;
  activeCollection: any = false;
  selectedTab: number;
  unfollowBtnShow: boolean = false;
  followBtnShow: boolean = true;
  token: string | null;
  userType: string | null;
  userId: string | null;
  channelFollowList: any;
  userID: any;
  productType: any;
  productTypeDetails: any;
  params: any;
  ChannelName: any;
  profile: any;
  profileImg: any;
  Collectiontype: any = 'collection';
  totalFollowers: any;
  totalProducts: any;
  sessionType: string;
  productError: any;
  productTitle: any;
  productId: any;
  name: string;
  ChannelNameProfile: any;
  ChannelNameCreator: any;
  ChannelNameUrl: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private productService: CollectionService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    if (this.productService.getToken() == '') {
      console.log('not-login');
      this.sessionType = 'home';
    }
    if (this.productService.getToken() != '') {
      this.productService.loginUserDetails().subscribe((result) => {
        console.log(result.data);
        this.name = `${result.data.firstName} ${result.data.lastName}`;
      });
      this.productService.creatorChannel().subscribe((res) => {
        this.ChannelNameCreator = res.data[0].name;
        this.ChannelNameProfile = res.data[0].profile;
        this.ChannelNameUrl = res.data[0].url;
        console.log(res.data);
      });
    }
    // this.token = localStorage.getItem('token');
    // this.userId = localStorage.getItem('ID');
    // // userId;
    // this.productService.loginUserDetails().subscribe((result) => {
    //   localStorage.setItem('userName', result.data.name);
    //   localStorage.setItem('userType', result.data.userType);
    //   localStorage.setItem('ID', result.data._id);
    // });

    // this.userType = localStorage.getItem('userType');
    // console.log(this.router.url.split('/').pop());
    this.channelName = this.router.url.split('/');
    console.log(this.channelName);
    this.productService.channelList().subscribe((res) => {
      this.ChannelName = res.data;
      console.log(this.ChannelName);

      this.profile = this.ChannelName.filter((item: any) => {
        console.log(item.name === this.channelName[1]);

        return item.name === this.channelName[1];
      });

      this.profileImg = this.profile[0]?.profile;
      console.log(this.profileImg);
    });
    this.queryParms = {
      type: this.type,
    };

    this.productService
      .fliterbyCreatorProductCollection(this.queryParms)
      .subscribe((res: any) => {
        this.productList = res.productData;
        this.collectionList = res.collectionData;

        this.collectionList?.forEach((item: any) => {
          this.userID = item.userId;
        });
        this.productList.forEach((item: any) => {
          this.userID = item.userId;
        });
        this.totalProducts = this.productList.filter(
          (item: any) => item
        ).length;
        console.log('total', this.totalProducts);
        console.log('USERID', this.userID);
      });
    // setTimeout(() => {
    //   if (this.productService.getToken() != '') {
    //     this.productService.ChannelFollowList().subscribe((res: any) => {
    //       console.log('followers', res);
    //       this.channelFollowList = res.data;
    //       this.totalFollowers = res.totalFollowers;
    //       this.channelFollowList.forEach((item: any) => {
    //         console.log(
    //           'follow logic',
    //           this.channelName[1] == item.channelName
    //         );
    //         if (this.channelName[1] == item.channelName) {
    //           this.unfollowBtnShow = true;
    //           this.followBtnShow = false;
    //           console.log('follow match');
    //           this.followersAPI();
    //         } else {
    //           console.log(' follow not match');
    //           this.followBtnShow = true;
    //           this.unfollowBtnShow = false;
    //         }
    //       });
    //     });
    //   }
    // }, 2000);

    if (this.channelName[2] == 'product') {
      this.selectedTab = 1;
    } else if (this.channelName[2] == 'top-books') {
      this.queryParms = {
        type: this.type,
        productType: 'top-books',
      };
      this.productService
        .fliterbyCreatorProductCollection(this.queryParms)
        .subscribe((res: any) => {
          this.productTypeDetails = res.productData;
        });
      this.selectedTab = 3;
    } else if (this.channelName[2] == 'apps-i-promote') {
      this.queryParms = {
        type: this.type,
        productType: 'apps-i-promote',
      };
      this.productService
        .fliterbyCreatorProductCollection(this.queryParms)
        .subscribe((res: any) => {
          this.productTypeDetails = res.productData;
        });
      this.selectedTab = 2;
    } else if (this.channelName[2] == 'my-deals') {
      this.queryParms = {
        type: this.type,
        productType: 'my-deals',
      };
      this.productService
        .fliterbyCreatorProductCollection(this.queryParms)
        .subscribe((res: any) => {
          this.productTypeDetails = res.productData;
        });
      this.selectedTab = 4;
    } else if (this.channelName[2] == 'my-gear-i-use') {
      this.queryParms = {
        type: this.type,
        productType: ' my gear i use',
      };
      this.productService
        .fliterbyCreatorProductCollection(this.queryParms)
        .subscribe((res: any) => {
          this.productTypeDetails = res.productData;
        });
      this.selectedTab = 5;
    } else if (this.channelName[2] == 'brand-deals') {
      this.queryParms = {
        type: this.type,
        productType: 'brand deals',
      };
      this.productService
        .fliterbyCreatorProductCollection(this.queryParms)
        .subscribe((res: any) => {
          this.productTypeDetails = res.productData;
        });
      this.selectedTab = 7;
    } else if (this.channelName[2] == 'recommendations') {
      this.queryParms = {
        type: this.type,
        productType: 'recommendations',
      };
      this.productService
        .fliterbyCreatorProductCollection(this.queryParms)
        .subscribe((res: any) => {
          this.productTypeDetails = res.productData;
        });
      this.selectedTab = 6;
    } else if (this.channelName[2] == 'collection') {
      this.type = 'collections';
      this.queryParms = {
        type: this.Collectiontype,
      };
      this.productService
        .fliterbyCreatorProductCollection(this.queryParms)
        .subscribe((res: any) => {
          //  this.productList = res.productData;
          this.collectionList = res.collectionData;
          // console.log(this.productList);
        });
    }
    //   else if (this.channelName[2] == 'branddeals') {
    //    this.selectedTab = 7;
    //  }
  }
  tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {
    console.log('tabChangeEvent => ', tabChangeEvent.tab.textLabel);
    // console.log('index => ', tabChangeEvent.index);

    this.productType = tabChangeEvent.tab.textLabel.toLowerCase();

    if (this.productType == 'my collections') {
      this.queryParms = {
        type: this.Collectiontype,
      };
      this.productService
        .fliterbyCreatorProductCollection(this.queryParms)
        .subscribe((res: any) => {
          this.productTypeDetails = res.productData;
          // this.collectionList = res.collectionData;
          // console.log(this.productList);
        });
    } else {
      this.queryParms = {
        type: this.type,
        productType: this.productType,
      };
      this.productService
        .fliterbyCreatorProductCollection(this.queryParms)
        .subscribe((res: any) => {
          this.productTypeDetails = res.productData;
        });
    }
  };
  CollectionUrl(title: any, id: any) {
    this.router.navigate([`c/${title.split(' ').join('-')}/id-${id}`]);
  }
  product() {
    this.productShow = true;
    this.activeProduct = true;
    this.collectionShow = false;
    this.activeCollection = false;

    this.type = 'product';
    this.queryParms = {
      type: this.type,
      productType: this.productType,
    };
    this.productService
      .fliterbyCreatorProductCollection(this.queryParms)
      .subscribe((res: any) => {
        this.productList = res.productData;
        // this.collectionList = res.collectionData;
        // console.log(this.productList);
      });
  }
  productDetails(title: any, id: any) {
    this.productTitle = title;
    this.productId = id;
    this.router.navigate([
      `/p/${this.productTitle.split(' ').join('-')}/id-${this.productId}`,
    ]);
  }
  saveForlater(id: any) {
    console.log('p', id);
    if (this.productService.getToken() == '') {
      this.dialog.open(NoAuthSignupComponent, {
        data: {
          title: 'Bookmark this product',
          disableClose: true,
        },
      });
    } else {
      console.log('saveProductBookmark', id);
      let obj = {
        productId: id,
      };
      this.productService.userProductBookmark(obj).subscribe(
        (result) => {
          // this.saved = true;
          // setTimeout(() => {
          //   this.saved = false;
          // }, 3000);
        },
        (error) => {
          this.productError = error.error.message;
          // setTimeout(() => {
          //   this.productError = '';
          // }, 3000);
        }
      );
    }
  }
  collection() {
    this.collectionShow = true;
    this.activeCollection = true;
    this.productShow = false;
    this.activeProduct = false;
    this.Collectiontype = 'collection';
    this.queryParms = {
      type: this.Collectiontype,
    };
    this.productService
      .fliterbyCreatorProductCollection(this.queryParms)
      .subscribe((res: any) => {
        //  this.productList = res.productData;
        this.collectionList = res.collectionData;
        // console.log(this.productList);
      });
  }
  viewProduct(title: any, id: any) {
    this.router.navigate([`p/${title.split(' ').join('-')}/id-${id}`]);
  }
  // followBtn() {
  //   if (localStorage.getItem('token') == null) {
  //     this.dialog.open(NoAuthSignupFollowComponent, {
  //       data: {
  //         title: 'to follow  the channel',

  //         disableClose: true,
  //       },
  //     });
  //   } else {
  //     this.params = {};
  //     this.productService
  //       .followCreatorChannel(this.userID, this.params)
  //       .subscribe((res) => {
  //         // console.log(res);
  //         this.unfollowBtnShow = true;
  //         this.followBtnShow = false;
  //         this.followersAPI();
  //       });
  //   }
  // }
  unFollowBtn() {
    // console.log(this.userID);
    // this.productService.ChannelUnFollowList(this.userID).subscribe((res) => {
    //   // console.log(res);
    //   this.unfollowBtnShow = false;
    //   this.followBtnShow = true;
    //   this.followersAPI();
    // });
  }
  navClick(name: any) {
    this.activeState = name;
    // console.log(name);
    this.productName = name;
    //   this.productParams = {
    //     categorieName: this.productName,
    //   };
    //   this.productService
    //     .productListFliter(this.productParams)
    //     .subscribe((res) => {
    //       this.productList = res.data;
    //     });
    // }
  }
  followersAPI() {
    this.productService.ChannelFollowList().subscribe((res: any) => {
      console.log('followers', res);
      this.channelFollowList = res.data;
      this.totalFollowers = res.totalFollowers;
    });
  }
}
