import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SummaryService {
  private httpClient: HttpClient;
  constructor(private router: Router, handler: HttpBackend) {
    this.httpClient = new HttpClient(handler);
  }
}
