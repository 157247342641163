<app-creatornavbar></app-creatornavbar>


<div class="container">








    <h4  class="succesfully mt-md-3" *ngIf="succeed">Channel created  successfully</h4> 
    <h4  class="invalid-feedback text-center pt-md-3 d-block" *ngIf="error != null">{{error}}</h4> 

    <form [formGroup]="catergoriesFormGroup">
       <div class="upload pt-4 pl-4 mt-5">
            <img *ngIf="url != null "  [src]="url" width=100 height=100 alt="" class="upload-img">
            <img *ngIf="url == null "  src="../../assets/images/place-holder.jpg" width=100 height=100 alt="" class="upload-img">
            <div class="round">
              <input type="file" accept="image/*" formControlName="profile"  (change)="onFileSelected($event)">
              <img src="../../assets/images/camera.svg" width=100 height=100 alt="" class="camera-img">
            </div>
          </div>
        <div *ngIf="c.profile.errors?.required && submiited" class="invalid-feedback ml-md-2 pt-md-5 d-block">Profile is required </div>

   <div class="row">
 <div class="col-md-6">
    
  <div class="catergories-add-section mt-md-2">

      <label>Channel name</label>
      <input  formControlName="name" type="text" class="form-control" placeholder="Enter the channel name">
        <div *ngIf="c.name.errors?.required && submiited" class="invalid-feedback m-t d-block">Channel name is required </div>

  </div>
  </div>
 <div class="col-md-6">

    <div class="catergories-add-section mt-md-2">
      <label>Channel url</label>
      <input type="text"  class="form-control" formControlName="url" placeholder="Enter the channel url">
        <div *ngIf="c.url.errors?.required && submiited" class="invalid-feedback m-t d-block">Channel url is required </div>

  </div>
    </div>

 
   </div>
   <div class="row">
 <div class="col-md-6">
  <div class="catergories-add-section mt-md-2">

      <label>Channel owner name</label>
      <input  formControlName="ownerName" type="text" class="form-control" placeholder="Enter the channel owner name"> 
        <div *ngIf="c.ownerName.errors?.required && submiited" class="invalid-feedback m-t d-block">Channel owner name is required </div>

  </div>
  </div>
 <div class="col-md-6">

    <div class="catergories-add-section mt-md-2">
      <p>Category</p>
        
      <mat-form-field appearance="fill"><mat-select formControlName = "category" multiple placeholder="Select"   disableOptionCentering panelClass="eligablePanelClass" (selectionChange)="categoryEvent($event)" #closeattendance >
                       
      <mat-option class='services-view' *ngFor="let list of categoryList" [value]="list._id">{{list.name}}</mat-option><div class="apply-btn-option pt-4 pb-3">
        
    </div></mat-select></mat-form-field>
        <div *ngIf="c.category.errors?.required && submiited" class="invalid-feedback m-t d-block">Category is required </div>

  </div>
    </div>

    

  


   </div>
   
    <div class="row">
 <div class="col-md-6">
    
  <div class="catergories-add-section mt-md-2">

      <label>Followers count</label>
      <input  formControlName="followersCount" type="text" class="form-control" placeholder="Enter the followers count">
        <div *ngIf="c.followersCount.errors?.required && submiited" class="invalid-feedback m-t d-block">Followers count is required </div>

  </div>
  </div>
 <div class="col-md-6">

    <div class="catergories-add-section mt-md-2">
      <label>Description</label>
      <input type="text"  class="form-control" formControlName="description" placeholder="Enter the description">
        <div *ngIf="c.description.errors?.required && submiited" class="invalid-feedback m-t d-block">Description is required </div>

  </div>
    </div>

 
   </div>

   <div class="radio-button-section mt-1 ml-3">
  <p>Country</p>
 <mat-radio-group formControlName="country" (change)="Country($event)">
           <mat-radio-button *ngFor="let country of country" [disableRipple]="true"  value="{{country}}"  name="Website" class="mr-3">{{country}}</mat-radio-button>
            
         </mat-radio-group>
        <div *ngIf="c.country.errors?.required && submiited" class="invalid-feedback m-t d-block">Country is required </div>
</div>
    <div class="submit-btn">
       <button type="button" class="pointer" (click)="submit()">Submit</button>
    </div>
</form>
   

</div>