import {
  CUSTOM_ELEMENTS_SCHEMA,
  InjectionToken,
  NgModule,
  NO_ERRORS_SCHEMA,
} from '@angular/core';
import {
  BrowserModule,
  BrowserTransferStateModule,
} from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';
import { LinkproductComponent } from './linkproduct/linkproduct.component';
import { ViewproductComponent } from './linkproduct/viewproduct/viewproduct.component';
import { ProductdetailsComponent } from './linkproduct/viewproduct/productdetails/productdetails.component';
import { CollectionsComponent } from './collections/collections.component';
import { ViewcollectionComponent } from './collections/viewcollection/viewcollection.component';
import { AllcollectionComponent } from './collections/allcollection/allcollection.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';

import { AuthlogoutInterceptor } from './_services/authlogout.interceptor';
import { FliterwithchannelnameComponent } from './fliterwithchannelname/fliterwithchannelname.component';
import { HomepageComponent } from './homepage/homepage.component';
import { NoAuthSignupComponent } from './no-auth-signup/no-auth-signup.component';
import { NoAuthSignupFollowComponent } from './no-auth-signup-follow/no-auth-signup-follow.component';
import { AddCategoriesComponent } from './add-categories/add-categories.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { UserdiscountcodesComponent } from './linkproduct/userdiscountcodes/userdiscountcodes.component';
import { UsercollectionComponent } from './collections/usercollection/usercollection.component';
import { UsernavbarModule } from './usernavbar/usernavbar.module';
import { UsersavedproductComponent } from './linkproduct/usersavedproduct/usersavedproduct.component';
import { ErrorInterceptor } from './_services/error.interceptor';

import { CreatechannelComponent } from './createchannel/createchannel.component';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { SharelinkComponent } from './sharelink/sharelink.component';
import { ShareproductLinkDialogComponent } from './shareproduct-link-dialog/shareproduct-link-dialog.component';

import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsOfServicesComponent } from './terms-of-services/terms-of-services.component';
import { CookiesPolicyComponent } from './cookies-policy/cookies-policy.component';
import { LoaderComponent } from './loader/loader.component';
import { UseronboardingflowDialogComponent } from './useronboardingflow-dialog/useronboardingflow-dialog.component';
import { ChannelFooterComponent } from './channel-footer/channel-footer.component';
import { PublishCollectionComponent } from './publish-collection/publish-collection.component';
import { MycollectionComponent } from './PowerUser/mycollection/mycollection.component';
import { MyproductsComponent } from './PowerUser/myproducts/myproducts.component';
import { CreatorCreateCollectionComponent } from './creator-create-collection/creator-create-collection.component';
import { CreatorCreateProductComponent } from './creator-create-product/creator-create-product.component';
import { CreatorProfileComponent } from './creator-profile/creator-profile.component';
import { EditCollectionComponent } from './edit-collection/edit-collection.component';
import { EditProductComponent } from './edit-product/edit-product.component';
import { BulkProductComponent } from './bulk-product/bulk-product.component';
import { ViewbulkproductsComponent } from './viewbulkproducts/viewbulkproducts.component';
import { CreatorViewlinksComponent } from './creator-viewlinks/creator-viewlinks.component';
import { SubscribeDialogComponent } from './collections/subscribe-dialog/subscribe-dialog.component';
import { FliterPipe } from './_pipes/fliter.pipe';
import { CreatorchannelcreateComponent } from './creatorchannelcreate/creatorchannelcreate.component';
import { PoweruserBulkProductComponent } from './poweruser-bulk-product/poweruser-bulk-product.component';
import { environment } from 'src/environments/environment';
import { QuillModule } from 'ngx-quill';
import { EditorPreviewDialogComponent } from './editor-preview-dialog/editor-preview-dialog.component';
import { Summaryfeed2Component } from './homepage/summaryfeed2/summaryfeed2.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { FliterCategortyPipe } from './_pipes/fliter-categorty.pipe';
import { LandingpageComponent } from './landingpage/landingpage.component';
export function playerFactory() {
  return player;
}
import { DynamicShareButtonDialogComponent } from './dynamic-share-button-dialog/dynamic-share-button-dialog.component';
import { EditChannelComponent } from './createchannel/edit-channel/edit-channel.component';
import { UpdateChannelComponent } from './createchannel/update-channel/update-channel.component';
import { UpdateCategoriesComponent } from './add-categories/update-categories/update-categories.component';
import { AdminRoutingModule } from './admin/admin-routing.module';
import { AdminModule } from './admin/admin.module';
import { EditCategoriesComponent } from './add-categories/edit-categories/edit-categories.component';
import { FliterwithcatergoryComponent } from './fliterwithcatergory/fliterwithcatergory.component';
import { InviteDialogComponent } from './fliterwithchannelname/invite-dialog/invite-dialog.component';
import { InviteSuccefullyComponent } from './fliterwithchannelname/invite-succefully/invite-succefully.component';
import { CategoryPipe } from './_pipes/category.pipe';
import { UserProfileComponent } from './usernavbar/user-profile/user-profile.component';
import { UserprofileupdateComponent } from './usernavbar/userprofileupdate/userprofileupdate.component';
import { ProfileCategroypipePipe } from './_pipes/profile-categroypipe.pipe';
import { PublishersComponent } from './admin/publishers/publishers.component';
import { DatepipePipe } from './_pipes/datepipe.pipe';
import { CommentsComponent } from './admin/comments/comments.component';
import { PublisherVideosComponent } from './creator-profile/publisher-videos/publisher-videos.component';
import { PublisherAnalyticsComponent } from './creator-profile/publisher-analytics/publisher-analytics.component';
import { PublisherOnboardingComponent } from './creator-profile/publisher-onboarding/publisher-onboarding.component';
import { PublisherPortalModule } from './publisher-portal/publisher-portal.module';
import { PublisheronboardingModelComponent } from './publisher-portal/publisheronboarding-model/publisheronboarding-model.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { PublisherSignupModalComponent } from './login-page/creator-signup/publisher-signup-modal/publisher-signup-modal.component';
import { AddTopicsDialogComponent } from './creator-create-collection/add-topics-dialog/add-topics-dialog.component';
import { AddSubTopicsDialogComponent } from './creator-create-collection/add-sub-topics-dialog/add-sub-topics-dialog.component';
import { RemoveStylesDirective } from './_directive/remove-styles.directive';
import { CommentSectionDialogComponent } from './homepage/comment-section-dialog/comment-section-dialog.component';
import { SuccessDialogComponent } from './add-categories/success-dialog/success-dialog.component';
import { DeleteSummaryComponent } from './PowerUser/delete-summary/delete-summary.component';
import { UserbookmarksComponent } from './userbookmarks/userbookmarks.component';
import { UserpublishersComponent } from './userpublishers/userpublishers.component';
import { UsertopicsComponent } from './usertopics/usertopics.component';
import { SuccessdialoghomeComponent } from './homepage/successdialoghome/successdialoghome.component';
import { RecordsPipe } from './_pipes/records.pipe';
import { ReportDialogComponent } from './homepage/report-dialog/report-dialog.component';

@NgModule({
  declarations: [
    AppComponent,
    LinkproductComponent,
    ViewproductComponent,
    ProductdetailsComponent,
    CollectionsComponent,
    ViewcollectionComponent,
    AllcollectionComponent,
    PagenotfoundComponent,
    FliterwithchannelnameComponent,
    HomepageComponent,
    NoAuthSignupComponent,
    NoAuthSignupFollowComponent,
    AddCategoriesComponent,
    UserdiscountcodesComponent,
    UsercollectionComponent,
    UsersavedproductComponent,
    CreatechannelComponent,
    SharelinkComponent,
    ShareproductLinkDialogComponent,
    PrivacyPolicyComponent,
    TermsOfServicesComponent,
    CookiesPolicyComponent,
    UseronboardingflowDialogComponent,
    ChannelFooterComponent,
    PublishCollectionComponent,
    MycollectionComponent,
    MyproductsComponent,
    CreatorCreateCollectionComponent,
    CreatorCreateProductComponent,
    CreatorProfileComponent,
    EditCollectionComponent,
    EditProductComponent,
    BulkProductComponent,
    ViewbulkproductsComponent,
    CreatorViewlinksComponent,
    SubscribeDialogComponent,
    FliterPipe,
    CreatorchannelcreateComponent,
    PoweruserBulkProductComponent,
    EditorPreviewDialogComponent,
    Summaryfeed2Component,
    FliterCategortyPipe,
    LandingpageComponent,
    DynamicShareButtonDialogComponent,
    EditChannelComponent,
    UpdateChannelComponent,
    UpdateCategoriesComponent,
    EditCategoriesComponent,
    FliterwithcatergoryComponent,
    InviteDialogComponent,
    InviteSuccefullyComponent,
    CategoryPipe,
    UserProfileComponent,
    UserprofileupdateComponent,
    ProfileCategroypipePipe,
    PublishersComponent,
    DatepipePipe,
    CommentsComponent,
    PublisherVideosComponent,
    PublisherAnalyticsComponent,
    PublisherOnboardingComponent,
    PublisheronboardingModelComponent,
    PublisherSignupModalComponent,
    AddTopicsDialogComponent,
    AddSubTopicsDialogComponent,
    RemoveStylesDirective,
    CommentSectionDialogComponent,
    SuccessDialogComponent,
    DeleteSummaryComponent,
    UserbookmarksComponent,
    UserpublishersComponent,
    UsertopicsComponent,
    SuccessdialoghomeComponent,
    RecordsPipe,
    ReportDialogComponent,
  ],
  imports: [
    HttpClientModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    SlickCarouselModule,
    UsernavbarModule,
    NgxPaginationModule,
    InfiniteScrollModule,
    ShareButtonsModule.withConfig({
      debug: true,
    }),
    ShareIconsModule,
    QuillModule.forRoot(),
    LottieModule.forRoot({ player: playerFactory }),
    AdminModule,
    PublisherPortalModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthlogoutInterceptor,
      multi: true,
    },
    // {
    //   provide: AuthServiceConfig,
    //   useFactory: provideConfig,
    // },

    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: ErrorInterceptor,
    //   multi: true,
    // },
    // '787729279307-p6khsa6a9r3ssk3dcp5dpl7hv6maui84.apps.googleusercontent.com'
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
