<div class="container">
  <div class="share-btns d-flex">
    <div class="share-1 share mr-auto pr-2">
      <h4>Share</h4>
    </div>
    <div class="image-close cursor" (click)="close()">
      <img src="../../assets/images/close.png" class="close-img"/>
    </div>
  </div>
  <share-buttons
    [include]="[
      'facebook',
      'twitter',
      'linkedin',
      'telegram',
      'messenger',
      'whatsapp',
      'email',
      'viber',
      'reddit',
      'sMS',
      'copylink',
      'print',
    ]"
    [theme]="'circles-dark'"
    show:any="12"
    [url]="title"
  ></share-buttons>
</div>

<!-- <share-buttons
  title="Title works!"
  description="Description works!"
  [theme]="'circles-light'"
  show:any="11"
></share-buttons> -->
