import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
export interface PeriodicElement {
  commentid: any;
  summaryid: any;
  summarytitle: any;
  Commentdetails: any;
  Spam: any;
  offensive: any;
  Commented_by: any;
  Publisher_id: any;
  Action: any;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {
    commentid: '6378uy45654hu',
    summaryid: 'tr454erg546rg',
    summarytitle: 'How to wash hair properly LInk',
    Commentdetails: 'I love this summary Found it very interesting',
    Spam: '56',
    offensive: '200',
    Commented_by: '@ycombinator',
    Publisher_id: '43635654g45h64',
    Action: '',
  },
  {
    commentid: '8765jfd9876lk',
    summaryid: 'hj765nm876fg6',
    summarytitle: 'Best Recipes of 2023',
    Commentdetails: 'This summary is fantastic!',
    Spam: '10',
    offensive: '30',
    Commented_by: '@foodlover',
    Publisher_id: '6789ghf7654nh54',
    Action: '',
  },
  {
    commentid: '3245sfd7654sd',
    summaryid: '876dsg34sd654',
    summarytitle: '10 Tips for Healthy Living',
    Commentdetails: 'I found this summary very informative.',
    Spam: '5',
    offensive: '15',
    Commented_by: '@wellnessguru',
    Publisher_id: '4328df8763hjfh2',
    Action: '',
  },
  {
    commentid: '908gfdgh456jkm',
    summaryid: 'lk876dfhg87jlk',
    summarytitle: 'Travel Adventures in Europe',
    Commentdetails: 'I dream of visiting these places someday!',
    Spam: '8',
    offensive: '25',
    Commented_by: '@wanderlust',
    Publisher_id: '876hjg456jdfhg9',
    Action: '',
  },
  {
    commentid: '7654poi8765uyt',
    summaryid: 'lkjhg4567poiuy',
    summarytitle: 'Tech Trends in 2023',
    Commentdetails: 'This summary is up-to-date with the latest tech trends!',
    Spam: '15',
    offensive: '5',
    Commented_by: '@techenthusiast',
    Publisher_id: 'kjhgf456poiuy7',
    Action: '',
  },
  {
    commentid: 'lkjh3456zxcvbn',
    summaryid: '0987poiuytrewq',
    summarytitle: 'Top 10 Movies of the Year',
    Commentdetails: 'I agree with the movie rankings in this summary.',
    Spam: '2',
    offensive: '10',
    Commented_by: '@filmcritic',
    Publisher_id: 'zxcvbn09876poiuy',
    Action: '',
  },
  {
    commentid: 'poiuyt23456lkjhg',
    summaryid: '67890zxcvbnmlkj',
    summarytitle: 'Health and Fitness Tips',
    Commentdetails: 'These tips have helped me stay healthy and fit!',
    Spam: '7',
    offensive: '3',
    Commented_by: '@fitnessfanatic',
    Publisher_id: '12345zxcvbnlkjhg',
    Action: '',
  },
  {
    commentid: '1234ab5678cd',
    summaryid: 'efg567hij890',
    summarytitle: '10 Must-Read Books of 2023',
    Commentdetails: 'This summary is a great guide for book lovers!',
    Spam: '15',
    offensive: '8',
    Commented_by: '@bookworm',
    Publisher_id: '9876klm54321opq',
    Action: '',
  },
  {
    commentid: 'qwerty123456',
    summaryid: 'asdfgh789012',
    summarytitle: 'The Latest Fashion Trends',
    Commentdetails: 'I enjoyed reading this summary. Very trendy!',
    Spam: '20',
    offensive: '10',
    Commented_by: '@fashionista',
    Publisher_id: 'lkjhgf876543zxc',
    Action: '',
  },
  {
    commentid: 'rtyui789012zxc',
    summaryid: 'cvbnm1234lkjh',
    summarytitle: 'Top 5 Fitness Workouts for 2023',
    Commentdetails: "Excellent summary! Can't wait to try these workouts.",
    Spam: '5',
    offensive: '12',
    Commented_by: '@fitnessfreak',
    Publisher_id: '0987poiuyt5431bn',
    Action: '',
  },
];
@Component({
  selector: 'app-publisher-analytics',
  templateUrl: './publisher-analytics.component.html',
  styleUrls: ['./publisher-analytics.component.css'],
})
export class PublisherAnalyticsComponent implements OnInit {
  Analytics: any[] = [
    { name: 'Total comments', number: 400 },
    { name: 'Spam', number: 700 },
    { name: 'Offensive', number: 200 },
    { name: 'Replies', number: 800 },
  ];
  displayedColumns: string[] = [
    'commentid',
    'summaryid',
    'summarytitle',
    'Commentdetails',
    'Spam',
    'offensive',
    'Commented_by',
    'Publisher_id',
    'Action',
  ];
  dataSource = new MatTableDataSource(ELEMENT_DATA);
  constructor() {}

  ngOnInit(): void {}
}
