 

<footer class="footer_section">
  <div class="container">
    <div class="row py-4">
      <div class="col-md-4 mb-4 col-lg-4">
           <a href="/">
    <div class="logo-class d-flex" >
      <!-- <img src="../../assets/images/fav-ico  n.png" class="footer-logo mr-3" /> -->
       
        <p>Summarizly AI </p>
         
    </div>
     </a>
    <div class="linkscart-footer">
   <h4>Platform for people to acquire and share knowledge on any topic</h4> 
       <P>Powered by AI</P>
    </div>
   
      </div>
     
     
    </div>

    <div class="row flex-column-reverse flex-md-row border_row">
      <div class="col-sm-6 col-md-6 col-lg-9 col-xxl-8 box">
        <ul class="list-inline quick-links">
          <li class="list-inline-item text-center mb-2">
            <span class="copyright quick-links pr-md-3">
              @Summarizly AI  {{ Date }}
            </span>
          </li>

          <li class="list-inline-item pr-md-4 pr-lg-4 mt-sm-3 mt-md-3">
            <a href="/terms-of-service" routerLink="/terms-of-service">Terms 
              Of Service </a>
          </li>
          <li class="list-inline-item pr-md-4 mb-sm-3">
            <a href="/privacy-policy" routerLink="/privacy-policy">Privacy Policy</a>
          </li>
          <li class="list-inline-item pr-md-4 mb-sm-3">
            <a href="/cookies-policy" routerLink="/cookies-policy">Cookie Policy</a>
          </li>
          <li class="list-inline-item pr-md-4 mb-sm-3">
            <a href="https://form.jotform.com/230251781254451" target="_blank">Contact us</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</footer>
