import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { ActivatedRoute, Router } from '@angular/router';
import { faSleigh } from '@fortawesome/free-solid-svg-icons';
import { environment } from 'src/environments/environment';
import { ShareproductLinkDialogComponent } from '../shareproduct-link-dialog/shareproduct-link-dialog.component';
import { CollectionService } from '../_services/collection.service';
import { AddTopicsDialogComponent } from './add-topics-dialog/add-topics-dialog.component';
import { AddSubTopicsDialogComponent } from './add-sub-topics-dialog/add-sub-topics-dialog.component';
@Component({
  selector: 'app-creator-create-collection',
  templateUrl: './creator-create-collection.component.html',
  styleUrls: ['./creator-create-collection.component.css'],
})
export class CreatorCreateCollectionComponent implements OnInit {
  hideComponent: boolean = false;
  hideNotifications: boolean = true;
  cardSectionCollectionView: boolean = true;
  createCollections: boolean = true;
  addCollections: boolean = false;
  collectionsAdd: boolean = false;
  loaderShowProduct: boolean = false;
  addCollectionsProducts: boolean = false;
  submittedSourceLink: boolean = false;
  submittedDetails: boolean = false;
  showDiscount: boolean = false;

  submittedProduct: boolean = false;
  loginUSerSuccess: boolean = false;
  loaderPreviewProduct: boolean = false;
  loaderPreview: boolean = false;
  selectedFileEvent: any;
  fileNameEvent: any;
  format: any;
  url: any = null;

  createPowerCollectionEmail: FormGroup;
  createPowerCollectionDetails: FormGroup;
  addproductForm: FormGroup;
  addCollection: any = [
    { key: '', value: 'Product 1' },
    { key: '', value: 'Product 2' },
    { key: '', value: 'Product 3' },
    { key: '', value: 'Product 4' },
  ];

  // categoryList: any = ['Nails', 'Handbags', 'bags', 'beauty'];
  keyWordsValue: any = [];
  keywords: any = [];
  tagsValue: any;
  tagsvalues: any = '';
  Cateogory = new FormControl('');
  category: any = '';
  summaryData: any = '';
  productTitle: any;
  collectiontitle: any = [];
  producturl: any;
  selectedFile: any;
  fileName: any;
  collectionurl: any;
  selectedFileCollection: any;
  fileNameCollection: any;
  collectionValue: any;
  collection: any = '';
  collectionTagsValue: any = '';
  collectiontagsvalues: any = '';
  categoryList: any;
  productInfoId: any;
  ProdcutINFOid: any = [];
  categoryLink: any;
  devurl: any = 'http://13.234.216.18:3000';
  userName: any;
  selectedTab: any;
  id: any;
  VideoCount = [
    '50k',
    '100k',
    '200k',
    '300k',
    '400k',
    '500k',
    '600k',
    '700k',
    '800k',
    '900k',
    '1M',
    '2M',
    '3M',
    '4M',
    '5M',
    '6M',
    '7M',
    '8M',
    '9M',
    '10M',
    '20M',
    '30M',
  ];
  subCategoryList: any;
  ChannelName: any;
  summeryView: any;
  productErroMsg: any;
  image: any;
  CImage: string;
  channelName: any;
  summaryCollection: string;
  collectionError: any;
  collectionImage: any;
  summaryAdded: string | null;
  params: any;
  productImageUrl: any;
  ProductsId: any;
  linkMypreview: string;
  CollectionTitle: any;
  gender: any = ['Female', 'male', 'Both'];
  CountryFileds: any = ['Global', 'USA', 'India', 'UK', 'Canada'];
  Language: any = ['English', 'Hindi', 'Telugu', 'Tamil', 'Urdu'];
  emailFormArray: Array<any> = [];
  CountryArray: Array<any> = [];
  LanguageArray: Array<any> = [];
  summaryLoader: boolean = false;
  fullSummary: any;
  summaryList: string;
  totalSummaryData: any = '';
  summaryHeader: any;
  modules = {};
  keywordsLoaderPostionChange: string = 'key_words_loader_postion_change_css';
  keywordsLoader: boolean = false;
  generatedText: any;
  youtubeUrl: any;
  Error: string;
  refreshSubscription: any;
  subCaterogoryList: any;
  errorMessage: string;
  catergoryId: any;
  subTopics: any;
  loadinSubTopics: boolean = false;
  channelErrorMessage: any;
  errorView: boolean;
  errorMesseage: string;
  viewsCount: any;
  viewsCountDataYoutube: any;
  snippet: any;
  snipeetName: string;
  channelAlreadyThere: any;
  finalName: any;
  channelnameB: any;
  countryCtrl: any;
  publisherN: any;
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private dialog: MatDialog,
    public collectionServices: CollectionService,
    private route: ActivatedRoute
  ) {
    this.createPowerCollectionEmail = this.fb.group({
      sourceURL: ['', [Validators.required]],
      videoViewsCount: [''],
      targetAudience: [''],
      gender: [''],
      language: [''],
    });
    this.createPowerCollectionDetails = this.fb.group({
      collectionName: ['', [Validators.required]],
      channelName: [''],
      video: [''],
      categoryId: ['', [Validators.required]],
      subCategoryId: [''],
      productInfoId: [''],
      customThumbNail: [''],
      Keywords: [''],
      tags: [''],
      Summery: [''],
    });
    this.addproductForm = this.fb.group({
      productTitle: ['', [Validators.required]],
      channelName: [''],
      description: [''],
      productImage: [''],
      affiliatePartnerName: [''],
      AffiliateLink: ['', [Validators.required]],
      price: [''],
      categoryId: [''],
      couponCode: [''],
      Keywords: [''],
      tag: [''],
      Summery: [''],
    });
    this.refreshSubscription = this.collectionServices.refresh$.subscribe(
      () => {
        // Perform any other action you need on refresh
        console.log('refreshing subscription publishercollection');
        this.getTopicsandSubTopics();
      }
    );
    this.route.queryParams.subscribe((params) => {
      this.youtubeUrl = params['url'];
      if (this.youtubeUrl != '') {
        this.createPowerCollectionEmail.controls['sourceURL'].setValue(
          this.youtubeUrl
        );
      }
      console.log('Query Parameter 1:', this.youtubeUrl);
    });

    Promise.all([import('quill-image-resize-module'), import('quill')]).then(
      ([ImageResize, Quill]) => {
        console.log({ Quill, ImageResize });
        // each has a "default" which is identical to the traditional import
        Quill.default.register('modules/imageResize', ImageResize.default);
        this.modules = {
          imageResize: true,
          toolbar: {
            container: [
              ['bold', 'italic', 'underline', 'strike'], // toggled buttons
              ['blockquote', 'code-block'],

              [{ header: 1 }, { header: 2 }], // custom button values
              [{ list: 'ordered' }, { list: 'bullet' }],
              [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
              [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
              [{ direction: 'rtl' }], // text direction

              [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
              [{ header: [1, 2, 3, 4, 5, 6, false] }],

              [{ color: [] }, { background: [] }], // dropdown with defaults from theme
              [{ font: [] }],
              [{ align: [] }],

              ['clean'], // remove formatting button

              ['link', 'image', 'video'], // link and image, video
            ],
          },
          clipboard: {
            matchVisual: true,
          },
        };
      }
    );
  }
  get f(): { [key: string]: AbstractControl } {
    return this.createPowerCollectionEmail.controls;
  }
  get c(): { [key: string]: AbstractControl } {
    return this.createPowerCollectionDetails.controls;
  }
  get p(): { [key: string]: AbstractControl } {
    return this.addproductForm.controls;
  }

  ngOnInit(): void {
    console.log(this.collectiontitle);
    console.log(this.collectionServices.summary);
    this.createPowerCollectionEmail.controls['targetAudience'].setValue(
      'Female'
    );

    if (this.youtubeUrl != null) {
      this.createCollectionBtn();
    }
    this.collectionServices.channelList().subscribe((res) => {
      this.ChannelName = res.data;
      console.log(this.ChannelName);
    });
    this.collectionServices.creatorChannel().subscribe((res) => {
      this.ChannelName = res.data;
      console.log(this.ChannelName);
      this.createPowerCollectionDetails.controls['channelName'].setValue(
        res.data[0]?.name
      );
    });
    this.getTopicsandSubTopics();
  }
  getTopicsandSubTopics() {
    this.collectionServices.caterogoryList().subscribe((res) => {
      console.log(res);
      this.categoryList = res.data;
    });

    if (this.category != '') {
      this.subTopics = {
        topicIds: this.category,
      };
      console.log('after added subtopics', this.category);
      this.collectionServices
        .subCaterogoryList(this.subTopics)
        .subscribe((res) => {
          console.log(res);
          this.subCaterogoryList = res.subTopics;
        });
    }
  }
  getSummary(id: any) {
    console.log('summary vide id', id);
    let obj = {
      video_id: id,
    };
    this.summaryLoader = true;

    this.collectionServices.getSummary(obj).subscribe((res) => {
      // console.log(res);
      this.summaryLoader = false;

      this.fullSummary = res.data.result.summary;
      // console.log('keywords summary', this.fullSummary);
      this.fullSummary.forEach((item: any) => {
        this.summaryList =
          '<h3> ' +
          item.tldr +
          '</h3>' +
          '<ul>' +
          item.keyIdeas
            .map(function (item: any) {
              return '<li> ' + item + '</li>';
            })
            .join('') +
          '</ul>';
        // console.log('summary', this.summaryList);
        let finalSummaryData = this.summaryList;

        let final = `${this.totalSummaryData} ${finalSummaryData}`;
        this.totalSummaryData = final;
        this.createPowerCollectionDetails.controls['Summery'].setValue(
          this.totalSummaryData
        );
      });

      // console.log('fullSummary', this.totalSummaryData);
      this.summaryHeader = this.totalSummaryData;

      // this.createPowerCollectionEmail.controls['Summery'].setValue(
      //   'testing.......'
      // );
    });
  }
  getShortSummary(id: any) {
    let obj = {
      video_id: id,
    };
    this.summaryLoader = true;

    this.collectionServices.getSummary(obj).subscribe(
      (res) => {
        // console.log(res);
        this.summaryLoader = false;

        this.fullSummary = res.data.result.summary;
        console.log('fullSummary', this.fullSummary);
        this.summaryList =
          '<ol>' +
          this.fullSummary
            .map(function (item: any) {
              return '<li> ' + item.tldr + '</li>';
            })
            .join('') +
          '</ol>';
        let finalSummaryData = this.summaryList;

        let final = `${finalSummaryData}`;
        this.totalSummaryData = final;
        this.createPowerCollectionDetails.controls['Summery'].setValue(
          this.totalSummaryData
        );
        console.log('summary', this.totalSummaryData);

        this.summaryHeader = this.totalSummaryData;
      },
      (error) => {
        console.log('error', error.error.meessage);
        this.Error = 'Video doesnot not have subtitles';

        setTimeout(() => {
          this.Error = '';
        }, 5000);
      }
    );
  }
  getkeyWords() {
    this.keywordsLoader = true;
    this.collectionServices.chatGptkeyWords(this.totalSummaryData).subscribe(
      (response: any) => {
        this.keywordsLoader = false;

        this.generatedText = response.data.choices[0].message.content;
        console.log(this.generatedText);
        this.createPowerCollectionDetails.controls['Keywords'].setValue(
          this.generatedText
        );
        this.keywords = this.createPowerCollectionEmail.value.Keywords;
        console.log(this.keywords);
      },
      (error: any) => {
        console.error('Error generating text', error);
      }
    );
  }
  onChange(email: string, e: any) {
    if (e.target.checked) {
      this.emailFormArray.push(email);
    } else {
      let index = this.emailFormArray.indexOf(email);
      this.emailFormArray.splice(index, 1);
    }

    console.log(this.emailFormArray);
  }
  onChangeLanguage(email: string, e: any) {
    if (e.target.checked) {
      this.LanguageArray.push(email);
    } else {
      let index = this.LanguageArray.indexOf(email);
      this.LanguageArray.splice(index, 1);
    }

    console.log(this.LanguageArray);
  }
  onChangeCountry(email: string, e: any) {
    if (e.target.checked) {
      this.CountryArray.push(email);
    } else {
      let index = this.CountryArray.indexOf(email);
      this.CountryArray.splice(index, 1);
    }

    console.log(this.CountryArray);
  }

  subCategoryListEvent(e: any) {
    console.log(e.target.value);
    this.collectionServices
      .subCategoryFilter(e.target.value)
      .subscribe((result) => {
        console.log(result);
        this.subCategoryList = result.data;
      });
  }
  changeRoute(event: MatRadioChange) {
    console.log(event.value);
    if (event.value === '1') {
      this.showDiscount = true;
    }
    if (event.value === '0') {
      this.showDiscount = false;
    }
  }

  createCollectionBtn() {
    if (this.createPowerCollectionEmail.invalid) {
      this.submittedSourceLink = true;
    } else {
      this.loaderPreview = true;
      this.collectionImage =
        this.createPowerCollectionEmail.value.sourceURL.slice(
          this.createPowerCollectionEmail.value.sourceURL.indexOf('=') + 1
        );

      this.CImage = `https://img.youtube.com/vi/${this.collectionImage}/hqdefault.jpg`;

      console.log(this.CImage);

      this.submittedSourceLink = false;

      this.params = {
        key: '8ae171ef2257d9fbc1a5ae031cf06980',
        q: this.createPowerCollectionEmail.value.sourceURL,
      };
      const url = this.createPowerCollectionEmail.value.sourceURL;
      console.log(url);
      const queryString = url.split('?')[1]; // Split the URL by '?' and take the second part
      const value = queryString.split('=')[1];
      console.log(value);

      // this.collectionServices
      //   .getYoutubeDataByVideoId(value)
      //   .subscribe((count) => {
      //     this.viewsCount = count.items.forEach((c: any) => {
      //       console.log('count', c.statistics.viewCount);
      //       this.viewsCountDataYoutube = c.statistics.viewCount;
      //       this.snippet = c.snippet;
      //       this.snipeetName = `@${c.snippet.channelTitle.replace(' ', '')}`;

      //       this.channelAlreadyThere = this.ChannelName?.filter((i: any) =>
      //         this.snipeetName.includes(i.name)
      //       );
      //       //   this.channelnameB = b.name;
      //       console.log('channel', this.channelAlreadyThere);
      //       // });
      //       this.finalName = this.channelAlreadyThere.forEach(
      //         (element: any) => {
      //           console.log('final', element.name);
      //           this.channelnameB = element.name;
      //         }
      //       );
      //       // this.channelnameB.includes(this.snipeetName);

      //       console.log('channel', this.channelnameB === this.snipeetName);
      //       console.log('channel', this.ChannelName);
      //       if (this.channelnameB?.replace(' ', '') === this.snipeetName) {
      //         console.log('channel alreathis.snipeetNamedy there');
      //         this.countryCtrl.setValue(this.snipeetName);
      //         this.publisherN = this.snipeetName;
      //         console.log('channel already there', this.publisherN);
      //       } else {
      //         console.log('channel not there');
      //         this.countryCtrl.setValue(this.snipeetName);
      //         this.publisherN = this.snipeetName;
      //         console.log('channel not there', this.publisherN);
      //         this.createChannel(this.snippet);
      //       }
      //     });
      //   });
      this.collectionServices
        .linkPreviewDetails(this.params)
        .subscribe((res) => {
          console.log(res);

          this.createPowerCollectionDetails.controls['collectionName'].setValue(
            res.title
          );
          this.loaderPreview = false;
          this.addCollections = true;
          this.createCollections = false;
        });
    }
  }
  createChannel(snippet: any) {
    throw new Error('Method not implemented.');
  }

  publishCollectionLogin() {
    this.createCollections = true;
    this.loginUSerSuccess = false;
  }
  publishProdcutLogin() {
    this.router.navigate(['/linkProduct']);
  }

  fetchProductDetail() {
    this.loaderPreviewProduct = true;
    this.params = {
      key: '8ae171ef2257d9fbc1a5ae031cf06980',
      q: this.addproductForm.value.AffiliateLink,
    };
    this.collectionServices.linkPreviewDetails(this.params).subscribe(
      (res) => {
        console.log('productDetails', res);
        this.productImageUrl = res.image;
        this.loaderPreviewProduct = false;

        this.addproductForm.controls['productTitle'].setValue(res.title);
        this.addproductForm.controls['description'].setValue(res.description);
      },
      (error) => {
        console.log(error);
        this.linkMypreview = `${error.error.description} with the actual response code we got from the remote server`;

        setTimeout(() => {
          this.loaderPreviewProduct = false;
          this.linkMypreview = '';
        }, 6000);
      }
    );
  }

  addTopics() {
    this.dialog.open(AddTopicsDialogComponent, {
      data: {
        disableClose: true,
      },
    });
  }
  addsubtopics() {
    console.log(this.category);
    if (this.category != '') {
      this.dialog.open(AddSubTopicsDialogComponent, {
        data: {
          id: JSON.parse(this.catergoryId),
          disableClose: true,
        },
      });
    } else {
      this.errorMessage = 'Please select aleast one topic';
    }
  }
  categoryEvent(event: any) {
    if (event.value != '') {
      this.errorMessage = '';
      this.category = JSON.stringify(event.value);
      this.loadinSubTopics = true;
      this.subTopics = {
        topicIds: this.category,
      };
      this.collectionServices
        .subCaterogoryList(this.subTopics)
        .subscribe((res) => {
          this.loadinSubTopics = false;

          console.log(res);
          this.subCaterogoryList = res.subTopics;
        });
    } else {
      this.category = '';
      this.subCaterogoryList = '';
    }
    console.log(event.value);
    this.catergoryId = this.category;
  }
  channelEvent(e: any) {
    this.channelName = e.target.value;
    console.log(this.channelName);

    // this.addproductForm.controls['channelName'].setValue(this.addproductForm);
    // console.log(
    //   this.addproductForm.controls.channelName.setValue(this.addproductForm)
    // );
    // this.addproductForm.get('ControlName').setValue(this.addproductForm);
  }
  // test() {
  // }
  publishCollectionProducts() {
    this.saveCollection();
  }
  addProduct() {
    if (this.createPowerCollectionDetails.invalid) {
      this.submittedDetails = true;
    } else {
      this.submittedDetails = false;

      this.addCollections = false;
      this.collectionsAdd = true;
    }
  }

  saveCollection() {
    console.log('success');
    console.log(this.createPowerCollectionDetails);
    console.log(this.collectionServices.summary);

    if (this.createPowerCollectionDetails.invalid) {
      this.submittedDetails = true;
    } else {
      if (this.summaryData != null) {
        this.summaryData = localStorage.getItem('summary');
      } else {
        this.summaryData = '';
      }

      const formData = new FormData();
      formData.append(
        'sourceURL',
        this.createPowerCollectionEmail.value.sourceURL
      );

      formData.append(
        'videoViewsCount',
        this.createPowerCollectionEmail.value.videoViewsCount
      );
      formData.append(
        'customThumbNail',
        this.createPowerCollectionDetails.controls['customThumbNail'].value
      );
      formData.append(
        'summaryName',
        this.createPowerCollectionDetails.value.collectionName.replace(
          /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
          ''
        )
      );
      formData.append('video', this.createPowerCollectionEmail.value.sourceURL);
      formData.append(
        'publisherName',
        this.createPowerCollectionDetails.value.channelName
      );
      formData.append(
        'topicId',
        JSON.stringify(this.createPowerCollectionDetails.value.categoryId)
      );
      formData.append(
        'subTopicId',
        JSON.stringify(this.createPowerCollectionDetails.value.subCategoryId)
      );
      formData.append('productInfoId', this.ProdcutINFOid);
      if (this.createPowerCollectionDetails.value.Keywords != null) {
        formData.append(
          'Keywords',
          JSON.stringify(this.createPowerCollectionDetails.value.Keywords)
        );
      }

      formData.append('tag', this.tagsvalues);
      formData.append(
        'Summary',
        this.createPowerCollectionDetails.value.Summery
      );

      formData.append('targetAudience', JSON.stringify(this.CountryArray));
      formData.append('gender', JSON.stringify(this.emailFormArray));
      formData.append('language', JSON.stringify(this.LanguageArray));

      this.collectionServices.createPowerUserCollection(formData).subscribe(
        (res) => {
          console.log(res);

          this.loginUSerSuccess = true;
          this.id = res._id;
          this.addCollections = false;
          this.collectionsAdd = false;
          this.addCollectionsProducts = false;

          this.image = res.video.slice(res.video.indexOf('=') + 1);
          this.collectionServices.summary = '';
          this.CollectionTitle =
            this.createPowerCollectionDetails.value.collectionName;
          this.CImage = `https://img.youtube.com/vi/${this.image}/hqdefault.jpg`;

          console.log('c-img', this.CImage);
          localStorage.removeItem('summary');
          this.collectionServices.summary = '';
          this.categoryLink = `${
            environment.SHARE_URL
          }/c/${this.createPowerCollectionDetails.value.collectionName
            .split(' ')
            .join('-')}/id-${this.id}`;
          this.createPowerCollectionEmail.reset();
          this.createPowerCollectionDetails.reset();
        },
        (error) => {
          console.log(error.error.message);
          this.collectionError = error.error.message;
          setTimeout(() => {
            this.collectionError = '';
          }, 3000);
        }
      );
      console.log(this.createPowerCollectionDetails.value);
      console.log(this.createPowerCollectionEmail.value);
    }
  }
  deleteImage() {
    this.productImageUrl = null;
  }
  keyWords(e: any) {
    this.keyWordsValue = e.target.value;

    console.log(e.target.value);
    if (this.keyWordsValue == '' || this.keyWordsValue == null) {
      this.keywords = '';
    } else {
      this.keywords = this.keyWordsValue.split(',');
      console.log(this.keywords);
    }
  }
  tags(e: any) {
    console.log(e.target.value);
    this.tagsValue = e.target.value;

    if (this.tagsValue == '' || this.tagsValue == null) {
      this.tagsvalues = '';
    } else {
      this.tagsvalues = JSON.stringify(this.tagsValue.split(','));
      console.log(this.tagsValue.split(','));
    }
  }
  keyWordsCollection(e: any) {
    console.log(e.target.value);
    this.collectionValue = e.target.value;

    if (this.collectionValue != '' || this.collectionValue != null) {
      this.collection = JSON.stringify(this.collectionValue.split(','));
    } else {
      this.collection = '';
    }
    console.log(this.keywords);
  }
  tagsCollection(e: any) {
    console.log(e.target.value);
    this.collectionTagsValue = e.target.value;
    if (this.collectionTagsValue != null || this.collectionTagsValue == '') {
      this.collectiontagsvalues = JSON.stringify(
        this.collectionTagsValue.split(',')
      );
    } else {
      this.collectionTagsValue = '';
    }
    console.log(this.collectionTagsValue.split(','));
  }
  addCollectionsInProducts() {
    this.collectionsAdd = true;
    this.addCollectionsProducts = false;
  }
  goBackProduct() {
    this.collectionsAdd = false;
    this.addCollections = true;
  }
  goBackaddCollections() {
    this.createCollections = true;
    this.addCollections = false;
  }
  goBackCollection() {
    this.addCollections = true;
    this.addCollectionsProducts = false;
  }
  // addProductDetails() {
  //   console.log(this.addproductForm);
  //   if (this.addproductForm.invalid) {
  //     this.submittedProduct = true;
  //   } else {
  //     this.loaderShowProduct = true;
  //     this.submittedProduct = false;
  //     if (this.addproductForm.value.couponCode == null) {
  //       this.addproductForm.value.couponCode = '';
  //     }
  //     if (this.addproductForm.value.affiliatePartnerName == null) {
  //       this.addproductForm.value.affiliatePartnerName = '';
  //     }
  //     if (this.addproductForm.value.price == null) {
  //       this.addproductForm.value.price = '';
  //     }
  //     if (this.productImageUrl == null) {
  //       this.productImageUrl = '';
  //     }
  //     console.log(this.addproductForm.value.productTitle);

  //     const formData = new FormData();
  //     formData.append(
  //       'productImage',
  //       this.addproductForm.controls['productImage'].value
  //     );
  //     formData.append(
  //       'productTitle',
  //       this.addproductForm.value.productTitle.replace(
  //         /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
  //         ''
  //       )
  //     );
  //     formData.append(
  //       'channelName',
  //       this.createPowerCollectionDetails.value.channelName
  //     );
  //     formData.append('image', this.productImageUrl);
  //     formData.append('description', this.addproductForm.value.description);
  //     formData.append(
  //       'affiliatePartnerName',
  //       this.addproductForm.value.affiliatePartnerName
  //     );
  //     formData.append('price', this.addproductForm.value.price);
  //     formData.append(
  //       'categoryId',
  //       JSON.stringify(this.createPowerCollectionDetails.value.categoryId)
  //     );
  //     formData.append('AffiliateLink', this.addproductForm.value.AffiliateLink);
  //     formData.append('couponCode', this.addproductForm.value.couponCode);
  //     formData.append('Keywords', this.collection);
  //     formData.append('tag', this.collectiontagsvalues);
  //     formData.append('Summery', this.addproductForm.value.Summery);

  //     this.collectionServices.createProductCollection(formData).subscribe(
  //       (response) => {
  //         this.productInfoId = response._id;
  //         this.productTitle = this.addproductForm.value.productTitle;
  //         let obj = {
  //           id: response._id,
  //           title: this.addproductForm.value.productTitle,
  //         };
  //         this.collectiontitle.push(obj);
  //         console.log(this.collectiontitle);
  //         this.ProdcutINFOid.push(this.productInfoId);

  //         console.log('product info', this.ProdcutINFOid);
  //         this.collectionServices.summary = '';

  //         setTimeout(() => {
  //           this.loaderShowProduct = false;
  //           this.addproductForm.reset();
  //           this.collectionsAdd = true;
  //           this.collectionurl = null;
  //           this.productImageUrl = null;
  //           this.producturl = null;
  //         });
  //       },
  //       (error) => {
  //         console.log(error.error.message);
  //         this.productErroMsg = error.error.message;

  //         setTimeout(() => {
  //           this.productErroMsg = '';
  //         }, 3000);
  //       }
  //     );

  //     console.log(this.addproductForm);
  //   }
  // }

  // publishCollection() {
  //   if (this.summaryData != null) {
  //     this.summaryData = localStorage.getItem('summary');
  //   } else {
  //     this.summaryData = '';
  //   }
  //   let obj = {
  //     sourceURL: this.createPowerCollectionEmail.value.sourceURL,
  //     videoViewsCount: this.createPowerCollectionEmail.value.videoViewsCount,
  //     collectionName:
  //       this.createPowerCollectionDetails.value.collectionName.replace(
  //         /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
  //         ''
  //       ),
  //     video: this.createPowerCollectionEmail.value.sourceURL,
  //     channelName: this.createPowerCollectionDetails.value.channelName,
  //     categoryId: this.createPowerCollectionDetails.value.categoryId,
  //     productInfoId: this.ProdcutINFOid,
  //     keywords: this.keywords,
  //     tags: this.tagsvalues,
  //     Summery: this.summaryData,
  //   };
  //   console.log(obj, 'object API');

  //   this.collectionServices.createPowerUserCollection(obj).subscribe(
  //     (res) => {
  //       console.log(res);

  //       this.loginUSerSuccess = true;
  //       this.id = res._id;
  //       this.addCollections = false;
  //       this.collectionsAdd = false;
  //       this.addCollectionsProducts = false;

  //       this.image = res.video.slice(res.video.indexOf('=') + 1);
  //       this.CImage = `https://img.youtube.com/vi/${this.image}/hqdefault.jpg`;

  //       console.log('c-img', this.CImage);
  //       this.userName = localStorage.getItem('userName');
  //       localStorage.removeItem('summary');
  //       this.collectionServices.summary = '';
  //       this.categoryLink = `${
  //         environment.SHARE_URL
  //       }/c/${this.createPowerCollectionDetails.value.collectionName
  //         .split(' ')
  //         .join('-')}/id-${this.id}`;
  //       console.log(this.categoryLink);
  //     },
  //     (error) => {
  //       console.log(error.error.message);
  //     }
  //   );
  // }
  // deleteProduct(id: any, i: any) {
  //   console.log('ID', id);
  //   console.log('ProductID', this.ProdcutINFOid);
  //   console.log('ID', this.ProdcutINFOid);

  //   this.collectionServices.DeleteProduct(id).subscribe((res) => {
  //     console.log(res);
  //     const index = this.ProdcutINFOid.indexOf(id);
  //     this.ProdcutINFOid.splice(index, 1);
  //     console.log('ID', this.ProdcutINFOid);
  //     if (i !== -1) {
  //       this.collectiontitle.splice(i, 1);
  //     }
  //   });
  // }
  onFileSelectedEvent(event: any): void {
    console.log(this.hideComponent);

    const file: File = event.target.files[0];
    const maxSizeKB = 100; // Maximum file size in kilobytes
    if (file) {
      const fileSizeKB = file.size / 1024; // Calculate file size in kilobytes

      if (fileSizeKB > maxSizeKB) {
        // Show an error message when the file is too large
        this.errorView = true;
        this.errorMesseage = `File size should be less than ${maxSizeKB}KB. Selected file size is ${fileSizeKB.toFixed(
          2
        )}KB.`;
      } else {
        this.hideComponent = false;
        const file = event.target.files && event.target.files[0];
        this.selectedFile = <File>event.target.files[0];
        this.fileName = event.target.files[0].name;

        if (event.target.files.length > 0) {
          this.errorView = false;

          const file = event.target.files[0];
          this.createPowerCollectionDetails.controls[
            'customThumbNail'
          ].setValue(file);
        }
        var reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = (event) => {
          this.url = (<FileReader>event.target).result;

          console.log(this.producturl);
        };
      }
    }
  }
  // onFileSelectedProduct(event: any) {
  //   console.log(this.hideComponent);
  //   this.hideComponent = false;
  //   const file = event.target.files && event.target.files[0];
  //   this.selectedFile = <File>event.target.files[0];
  //   this.fileName = event.target.files[0].name;

  //   if (event.target.files.length > 0) {
  //     const file = event.target.files[0];
  //     this.addproductForm.controls['productImage'].setValue(file);
  //   }
  //   var reader = new FileReader();
  //   reader.readAsDataURL(file);

  //   reader.onload = (event) => {
  //     this.producturl = (<FileReader>event.target).result;
  //     console.log(this.producturl);
  //   };
  // }
  onFileSelectedCollection(event: any): void {
    console.log(this.hideComponent);
    this.hideComponent = false;
    const file = event.target.files && event.target.files[0];
    this.selectedFileCollection = <File>event.target.files[0];
    this.fileNameCollection = event.target.files[0].name;
    if (file) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      if (file.type.indexOf('image') > -1) {
        this.format = 'image';
      } else if (file.type.indexOf('video') > -1) {
        this.format = 'video';
      }
      reader.onload = (event) => {
        this.collectionurl = (<FileReader>event.target).result;
      };
    }
  }

  share() {
    this.dialog.open(ShareproductLinkDialogComponent, {
      data: {
        title: this.categoryLink,

        disableClose: true,
      },
    });
  }
}
