 <app-navbar *ngIf="sessionType =='powerUser'"></app-navbar>
<app-creatornavbar *ngIf="sessionType =='publisher'"></app-creatornavbar>
<div class="main" *ngIf="sessionType =='powerUser'">
  <div class="container">
    <div class="linkProductSection mt-5">


      <div class="cards-section-collection">
           <h2>Create an AI Summary in seconds</h2>

        <div class="cards-section d-flex justify-content-center">
            <div class="card mr-md-3" (click)="collectionRouter()">
               <div class="card-body">
                  <div class="collection-view-section">
                     <h4>Create a Summary</h4>
                     <img src="../../assets/images/youtube-img.svg" class="hover-image-1">
                     <img src="../../assets/images/youtube (3).png" class="hover-image">
                     <!-- <p>Publish a YouTube video link, and few product links</p> -->
               </div>
            </div>
        </div>
            <!-- <div class="card ml-md-5" (click)="productRoute()">
               <div class="card-body">
                  <div class="collection-view-section">
                     <h4>A single product link</h4>
                     <img src="../../assets/images/p.svg">
                     <p>Publish a single product link</p>
               </div>
            </div>
        </div>
        <div class="card ml-md-5" (click)="bulkRoutePowerUser()">
               <div class="card-body">
                  <div class="collection-view-section">
                     <h4>Bulk product links</h4>
                     <img src="../../assets/images/p.svg">
                     <p>Publish a Bulk product links</p>
               </div>
            </div>
        </div> -->
      </div>
      </div>
      </div>
      </div>
      </div>
<div class="main" *ngIf="sessionType == 'publisher'">
  <div class="container">
    <div class="linkProductSection mt-5">


      <div class="cards-section-collection">
            <h2>Create an AI Blog in seconds</h2>

        <div class="cards-section d-md-flex justify-content-center">
            <div class="card mr-md-3" (click)="collectionRouterCreator()">
               <div class="card-body">
                  <div class="collection-view-section">
                     <h4>Create a blog</h4>
                     <img src="../../assets/images/youtube-img.svg">
                     <p>Publish a YouTube video link, and few product links</p>
               </div>
            </div>
        </div>
            <!-- <div class="card ml-md-5" (click)="productRouteCreator()">
               <div class="card-body">
                  <div class="collection-view-section">
                     <h4>A single product link</h4>
                     <img src="../../assets/images/p.svg">
                     <p>Publish a single product link</p>
               </div>
            </div>
        </div>
            <div class="card ml-md-5" (click)="bulkRouteCreator()">
               <div class="card-body">
                  <div class="collection-view-section">
                     <h4>Bulk product links</h4>
                     <img src="../../assets/images/p.svg">
                     <p>Publish a Bulk product links</p>
               </div>
            </div>
        </div> -->
      </div>
      </div>
      </div>
      </div>
      </div>
      