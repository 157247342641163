import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from 'src/app/_services/login.service';

@Component({
  selector: 'app-poweruser-forgotpassword',
  templateUrl: './poweruser-forgotpassword.component.html',
  styleUrls: ['./poweruser-forgotpassword.component.css'],
})
export class PoweruserForgotpasswordComponent implements OnInit {
  passwordChangedSuccesfully: boolean = false;
  confirmPassword: boolean = true;
  submittedUser: boolean = false;
  loginPage: boolean;
  id: any;
  resetPassword: FormGroup;
  error: any;
  constructor(
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private service: LoginService,
    private router: Router
  ) {
    this.resetPassword = this.fb.group({
      password: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]],
      userId: [''],
    });
  }

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.queryParams.powerUserId;
    console.log(this.id);
  }

  resetPasswordBtn() {
    if (this.resetPassword.invalid) {
      this.submittedUser = true;
    } else {
      this.submittedUser = false;
      let obj = {
        password: this.resetPassword.value.password,
        confirmPassword: this.resetPassword.value.confirmPassword,
        readerId: this.id,
      };

      this.service.ResetPasswordPowerUser(obj).subscribe(
        (res) => {
          console.log(res);
          this.passwordChangedSuccesfully = true;
          this.confirmPassword = false;
        },
        (error) => {
          this.error = error.error.message;
          console.log(this.error);
        }
      );
    }
  }
  get resetPasswordControl(): { [key: string]: AbstractControl } {
    return this.resetPassword.controls;
  }
  // confirmPasswordBtn() {

  // }
  loginNow() {
    this.router.navigate(['/PowerUser/login-batman']);
  }
  home() {
    this.router.navigate(['/feed']);
  }
}
