import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ClevertapService {
  private httpClient: HttpClient;
  private apiUrl = 'https://api.clevertap.com/1/upload';
  private accountId = '6ZW-454-496Z';
  private passcode = 'WVW-AEA-AXUL';
  constructor(private router: Router, handler: HttpBackend) {
    this.httpClient = new HttpClient(handler);
  }

  sendProfileData(userProfile: any) {
    const headers = new HttpHeaders({
      Authorization: `Basic ${btoa(`${this.accountId}:${this.passcode}`)}`,
    });

    return this.httpClient.post(this.apiUrl, userProfile, { headers });
  }
}
