import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AdminService } from 'src/app/_services/admin.service';
import { CollectionService } from 'src/app/_services/collection.service';

@Component({
  selector: 'app-publisher-profile',
  templateUrl: './publisher-profile.component.html',
  styleUrls: ['./publisher-profile.component.css'],
})
export class PublisherProfileComponent implements OnInit {
  profile: null;
  url: string;
  selectedFile: File;
  profileUpdateFormData: FormGroup;
  genderButtons: any = [
    {
      name: 'Male',
      value: 'male',
    },
    {
      name: 'Female',
      value: 'female',
    },
  ];
  sessionType: string;
  params: any;
  userId: any;
  pageSize: any = 10;
  currentPage: any = 1;
  userDetails: any;
  succesfully: boolean;
  languageControl = new FormControl('');
  language: any = [
    { key: 'Telugu', value: 'Telugu' },
    { key: 'English', value: 'English' },
    { key: 'Hindi', value: 'Hindi' },
    { key: 'Kannada', value: 'Kannada' },
    { key: 'Tamil', value: 'Tamil' },
  ];
  languageparams: any = '';
  userLanugages: any;
  loader: boolean;
  errorMesseage: string;
  errorView: boolean = false;
  constructor(
    private collectionService: CollectionService,
    private admin: AdminService,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute
  ) {
    this.profileUpdateFormData = this.fb.group({
      name: [''],
      email: [''],
      profile: [''],
      gender: [''],
      languages: [''],
    });
  }

  ngOnInit(): void {
    this.loader = true;
    if (this.collectionService.getToken() == '') {
      console.log('not-login');
      this.sessionType = 'home';
    }
    if (this.collectionService.getToken() != '') {
      this.collectionService.loginUserDetails().subscribe((result) => {
        this.sessionType = result.data.userType;
        this.userId = result.data._id;
        this.params = {
          _id: this.userId,
          visitorId: this.userId,
          pageLimit: this.pageSize ? this.pageSize : '',
          pageNumber: this.currentPage,
        };
        this.collectionService
          .userProfileDetails(this.params)
          .subscribe((result) => {
            console.log('user', result);
            this.loader = false;

            this.userDetails = result.data[0];
            this.profile = result.data[0].profile;
            this.userLanugages = this.userDetails.languages;
            console.log('userLanugages', this.userLanugages);
            this.profileUpdateFormData.controls['name'].setValue(
              this.userDetails.name
            );
            this.profileUpdateFormData.controls['email'].setValue(
              this.userDetails.email
            );
            this.profileUpdateFormData.controls['gender'].setValue(
              this.userDetails.gender
            );
            this.capitalizeItems();
            this.languageControl.setValue(this.userLanugages);
          });
      });
    }
  }
  capitalizeItems() {
    this.userLanugages = this.userLanugages.map((item: any) =>
      this.capitalizeFirstLetter(item)
    );
    console.log(this.userLanugages);
  }
  capitalizeFirstLetter(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  languageDropDown(event: any) {
    if (event.value != '') {
      this.languageparams = JSON.stringify(event.value);
    } else {
      this.languageparams = '';
    }
    console.log(this.languageparams);
  }
  update() {
    const formData = new FormData();
    formData.append('name', this.profileUpdateFormData.value.name);
    formData.append(
      'profile',
      this.profileUpdateFormData.controls['profile'].value
    );
    formData.append('email', this.profileUpdateFormData.value.email);
    formData.append('gender', this.profileUpdateFormData.value.gender);
    formData.append('languages', this.languageparams);

    this.admin.publisherProfileUpdate(formData).subscribe((res) => {
      console.log(res);
      this.succesfully = true;
      setTimeout(() => {
        this.succesfully = false;
      }, 3000);

      // this.profileUpdateFormData.reset();
    });
  }
  genderSelect(value: any) {
    console.log(value);
  }
  onFileSelected(event: any): void {
    const file: File = event.target.files[0];
    const maxSizeKB = 100; // Maximum file size in kilobytes
    if (file) {
      const fileSizeKB = file.size / 1024; // Calculate file size in kilobytes

      if (fileSizeKB > maxSizeKB) {
        // Show an error message when the file is too large
        this.errorView = true;
        this.errorMesseage = `File size should be less than ${maxSizeKB}KB. Selected file size is ${fileSizeKB.toFixed(
          2
        )}KB.`;
      } else {
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        this.profile = null;

        reader.onload = (_event) => {
          // this.msg = '';
          this.url = reader.result as string;

          this.selectedFile = <File>event.target.files[0];
        };
        if (event.target.files.length > 0) {
          const file = event.target.files[0];
          this.errorView = false;

          this.profileUpdateFormData.controls['profile'].setValue(file);
        }
      }
    }
  }
}
