import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { AdminService } from 'src/app/_services/admin.service';
import Swal from 'sweetalert2';
export interface PeriodicElement {
  sid: any;
  Thumbali: any;
  summarytitle: any;
  Publishedby: any;

  views: any;
  status: any;
  action: any;
}

@Component({
  selector: 'app-admin-power-user',
  templateUrl: './admin-power-user.component.html',
  styleUrls: ['./admin-power-user.component.css'],
})
export class AdminPowerUserComponent implements OnInit {
  displayedColumns: string[] = [
    'sid',
    'Thumbali',
    'summarytitle',
    'Publishedby',
    'views',
    'status',
    'action',
  ];
  Language: any = [
    'English',
    'Chinese',
    'Hindi',
    'Spanish',
    'Arabic',
    'French',
    'Telugu',
    'Russian',
    'German',
    'Tamil',
    'Malayalam',
  ];
  loader: boolean;
  userQuery: {};
  language: any = '';
  ELEMENT_DATA: Element[] = [];
  pageSize: any = 10;
  currentPage: any = 0;
  unchecked: boolean = false;
  checked: boolean = true;
  pageSizeOptions: number[] = [10, 20, 40, 100];
  totalSummary: any;
  newUser: any;
  userDataCount: any;
  items: { name: string; number: number }[];
  dataSource = new MatTableDataSource(this.ELEMENT_DATA);
  userDelete: boolean;
  userData: any;
  objParam: any;
  access: string;
  constructor(private adminService: AdminService) {}

  ngOnInit(): void {
    this.userApisList();
  }

  userApisList() {
    this.loader = true;

    this.userQuery = {
      language: this.language,
    };

    this.adminService.adminPowerUserList(this.userQuery).subscribe((result) => {
      console.log(result);
      console.log('data', result);
      this.loader = false;

      this.ELEMENT_DATA = result.powerUsers;
      this.userData = result.powerUsers;
      this.totalSummary = result.totalPowerUsers;
      this.newUser = result.totalAccessRemovedUsers;
      this.userDataCount = result.userDataCount;
      this.items = [
        { name: 'Total PowerUsers', number: this.totalSummary },
        { name: 'TotalAccessRemoved', number: this.newUser },
        // { name: 'Normal Sing up', number: this.userDataCount },
      ];

      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    });
  }
  removeAcess(text: any, id: any) {
    if (text == 'true') {
      this.objParam = true;
      this.access = 'added';
    } else {
      this.access = 'removed';

      this.objParam = false;
    }

    this.adminService.removeAcess(this.objParam, id).subscribe((res) => {
      console.log('delete ', res);
      this.userDelete = true;
      Swal.fire({
        text: `Access ${this.access} succefully`,
        showConfirmButton: true, // Hide the "OK" button
        icon: 'success', // You can change the icon type
        timer: 2000, // Set the timer to 3000 milliseconds (3 seconds)
      });
      this.userApisList();
      setTimeout(() => {
        this.userDelete = false;
      }, 3000);
    });
  }
  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;

    this.currentPage = event.pageIndex;
    let newCurrentPage = event.pageIndex + 1;
    console.log(this.currentPage);
    this.loader = true;

    this.userQuery = {
      language: this.language,
    };

    this.adminService.adminPowerUserList(this.userQuery).subscribe((result) => {
      console.log(result);
      console.log('data', result);
      this.loader = false;

      this.ELEMENT_DATA = result.powerUsers;
      this.userData = result.powerUsers;
      this.totalSummary = result.totalPowerUsers;
      this.newUser = result.newUser;
      this.userDataCount = result.userDataCount;
      this.items = [{ name: 'Total PowerUsers', number: this.totalSummary }];

      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    });
  }
  langugeChange(e: any) {
    this.language = e.target.value;
    this.userApisList();
  }
  toggleChanged(e: any) {
    console.log('toggl', e.target.checked);
  }
  deleteUser(id: any) {
    this.adminService.deleteUser(id).subscribe((res) => {
      console.log('delete ', res);
      this.userDelete = true;
      Swal.fire({
        text: 'User delete succefully',
        showConfirmButton: true, // Hide the "OK" button
        icon: 'success', // You can change the icon type
        timer: 2000, // Set the timer to 3000 milliseconds (3 seconds)
      });
      this.userApisList();
      setTimeout(() => {
        this.userDelete = false;
      }, 3000);
    });
  }
}
