import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ShareproductLinkDialogComponent } from '../shareproduct-link-dialog/shareproduct-link-dialog.component';
import { CollectionService } from '../_services/collection.service';

@Component({
  selector: 'app-edit-product',
  templateUrl: './edit-product.component.html',
  styleUrls: ['./edit-product.component.css'],
})
export class EditProductComponent implements OnInit {
  loaderPreview: boolean = false;
  collectionUpdate: boolean = true;
  submittedDetails: boolean = false;
  updatedsuccefully: boolean = false;
  editCollectionForm: FormGroup;
  format: string;
  selectedFileEvent: File;
  fileNameEvent: any;
  url: any;
  activeRouteParams: any;
  productImageUrl: any;
  CollectionTitle: any;
  categoryLink: string;
  id: any;
  showDiscount: boolean = false;
  constructor(
    private fb: FormBuilder,
    private collectionServices: CollectionService,
    private router: Router,
    private dialog: MatDialog,
    private activeRoute: ActivatedRoute
  ) {
    this.editCollectionForm = this.fb.group({
      AffiliateLink: ['', [Validators.required]],
      description: ['', [Validators.required]],
      productTitle: ['', [Validators.required]],
      productImage: [''],
      channelName: [''],
      affiliatePartnerName: [''],
      price: [''],
      categoryId: [''],
      couponCode: [''],
      subCategoryName: [''],
      productType: [''],
      Keywords: [''],
      tag: [''],
      Summery: [''],
    });
  }

  ngOnInit(): void {
    this.activeRouteParams = this.activeRoute.snapshot.queryParams;
    console.log(this.activeRouteParams.id);
    this.collectionServices
      .ProductListDetails(this.activeRouteParams.id)
      .subscribe((res) => {
        console.log(res.data);
        if (res.data[0].image != null) {
          this.productImageUrl = res.data[0]?.image;
        } else {
          this.url = res.data[0].productImage[0]?.url;
        }
        // this.CImage = res.data[0].thumbNail;

        this.editCollectionForm.controls['AffiliateLink'].setValue(
          res.data[0].AffiliateLink
        );
        this.editCollectionForm.controls['productTitle'].setValue(
          res.data[0].productTitle
        );
        this.editCollectionForm.controls['description'].setValue(
          res.data[0].description
        );
      });
  }
  get c(): { [key: string]: AbstractControl } {
    return this.editCollectionForm.controls;
  }
  deleteImage() {
    if (this.productImageUrl != null) {
      this.productImageUrl = null;
    } else {
      this.url = '';
    }
  }
  changeRoute(event: MatRadioChange) {
    console.log(event.value);
    if (event.value === '1') {
      this.showDiscount = true;
    }
    if (event.value === '0') {
      this.showDiscount = false;
    }
  }
  updateProduct() {
    console.log(this.editCollectionForm);
    if (this.editCollectionForm.invalid) {
      this.submittedDetails = true;
    } else {
      this.submittedDetails = false;
      console.log(this.editCollectionForm.controls['productImage'].value);
      const formData = new FormData();
      if (this.editCollectionForm.controls['productImage'].value != '') {
        formData.append(
          'productImage',
          this.editCollectionForm.controls['productImage'].value
        );
      }
      if (this.productImageUrl != null) {
        formData.append('image', this.productImageUrl);
      }

      formData.append(
        'productTitle',
        this.editCollectionForm.value.productTitle.replace(
          /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
          ''
        )
      );
      formData.append('description', this.editCollectionForm.value.description);
      formData.append(
        'AffiliateLink',
        this.editCollectionForm.value.AffiliateLink
      );
      formData.append('couponCode', this.editCollectionForm.value.couponCode);
      this.collectionServices
        .powerUserUpdateProduct(this.activeRouteParams.id, formData)
        .subscribe((result) => {
          console.log(result);
          this.updatedsuccefully = true;
          this.collectionUpdate = false;
          this.loaderPreview = false;

          this.CollectionTitle =
            this.editCollectionForm.value.productTitle.replace(
              /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
              ''
            );
          this.id = this.activeRouteParams.id;
          this.categoryLink = `${
            environment.SHARE_URL
          }/p/${this.editCollectionForm.value.productTitle
            .split(' ')
            .join('-')}/id-${this.activeRouteParams.id}`;
        });
    }
  }
  share() {
    this.dialog.open(ShareproductLinkDialogComponent, {
      data: {
        title: this.categoryLink,
        disableClose: true,
      },
    });
  }
  onFileSelectedEvent(event: any): void {
    const file = event.target.files && event.target.files[0];
    this.selectedFileEvent = <File>event.target.files[0];
    this.fileNameEvent = event.target.files[0].name;
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.editCollectionForm.controls['productImage'].setValue(file);
    }
    if (file) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      if (file.type.indexOf('image') > -1) {
        this.format = 'image';
      } else if (file.type.indexOf('video') > -1) {
        this.format = 'video';
      }
      reader.onload = (event) => {
        this.url = (<FileReader>event.target).result;
      };
    }
  }
}
