<!-- <app-usernavbar></app-usernavbar>

<div class="main">
<app-loader *ngIf="loginLoader" ></app-loader>
    <div class="container-fluid">
        
        <div class="login-section ">

          <div class="row flex-column-reverse flex-md-row">
              <div class="col-md-6 ">
                <div class="login-section-common-data pl-md-4 pt-md-5" id="login-para">
                    <div class="common-data-login">
              <h4>Acquire knowledge on Startups, Entrepreneurship, Product and Growth Hacking</h4>
          
            </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="login-form-section" *ngIf="loginPage">
                    <div class="form-section">
                         <h4>Sign in to your account</h4>
                          <form [formGroup]="loginFormGroup">
 <div class="email-form-section mt-md-5">
                           <input  (keydown.space)="$event.preventDefault();" class="form-control" formControlName="email" placeholder="Enter email address">
                            <div
                    *ngIf="f.email.errors?.required && submitted"
                    class="invalid-feedback m-t d-block"
                  >
                    Email is required
                  </div>
                            <div
                    *ngIf="f.email.errors?.email && submitted"
                    class="invalid-feedback m-t d-block"
                  >
                    Email is invalid
                  </div>
                         </div>
                         <div class="password-form-section mt-md-4 pt-md-1">
                           <input  type="password" class="form-control" formControlName="password" placeholder="Enter your password">
                            <div
                    *ngIf="f.password.errors?.required && submitted"
                    class="invalid-feedback m-t d-block"
                  >
                    Password is required
                  </div>
                            <div
                    *ngIf="loginErrorMessage != null"
                    class="invalid-feedback m-t d-block"
                  >
                    {{loginErrorMessage}}
                  </div>
                         </div>
                          </form>
                        
                    </div>
                    <div class="sign-in-section mt-md-4 pt-md-2">
                        <div class="d-flex">
                           <div class="btn-signin">
                              <button class="pointer" (click)="login()"  type="submit">SIGN IN</button>
                           </div>
                            <div class="forgot-password pointer">
                                <h6><a (click)="forGotPassword()">Forgot password?</a></h6>
                            </div>
                        </div>
                    </div>

                    <div class='google-intergration'>
                         <p class="py-md-4">OR</p>
                          <div class=" google-btn">
   
  
  <button type="submit">
    <a [href]="url | safePipe">
    <img src="../../assets/images/google.png" class="google mr-3">Sign in via Google
  </a>
  </button>
 
</div>
  <h6>Don’t have an account? <span class="pointer" (click)="signUp()">Sign up for free</span></h6>
 

                    </div>
                </div>
                <div class="login-form-section" *ngIf="resetPassword">
                    <div class="form-section">
                         <h4>Reset your password</h4>
                          <form [formGroup]="forGotPasswordForm">
                              <div class="email-form-section mt-md-5">
                           <input (keydown.space)="$event.preventDefault();" class="form-control" formControlName="email" placeholder="Enter email address">
                            <div
                    *ngIf="r.email.errors?.required && submittedPassword"
                    class="invalid-feedback m-t d-block"
                  >
                    Email is required
                  </div>
                            <div
                    *ngIf="r.email.errors?.email && submittedPassword"
                    class="invalid-feedback m-t d-block"
                  >
                    Email is invalid
                  </div>
                            <div
                    *ngIf="errorMessage != null"
                    class="invalid-feedback m-t d-block"
                  >
                    {{ errorMessage}}
                  </div>
                         </div>
                          </form>
                         
                         
                    </div>
                    <div class="sign-in-section mt-md-4 pt-md-2">
                        <div class="d-flex">
                           <div class="btn-signin">
                              <button class="pointer"  type="submit" (click)="resetPasswordBtn()">Reset Password</button>
                           </div>
                             
                        </div>
                    </div>

                    <div class='google-intergration pt-md-3'>
                        
  <h6>Don’t have an account? <span class="pointer" (click)="signUp()">Sign up for free</span></h6>

                    </div>
                </div>
               

                <div class="password-changed mt-4" *ngIf="passwordChangedSuccesfully">
                      <img src="../../assets/images/check.png" class="success-img">
                       <p class="mt-4">Password reset link has been sent to your email. Please check your inbox.</p>
                       <h6>In case if you don’t see it consider checking your spam folder too, in case</h6>

                       <div class="d-flex after-success justify-content-center">
                           <a class="mr-4 pointer" (click)="loginNow()">Login now</a>
                           <a class='pointer' (click)="home()">Go to home</a>
                       </div>
                </div>
              </div>
          </div>
        </div>
    </div>
</div> -->
<app-loader *ngIf="loginLoader" ></app-loader>
<div class="main">
  <div *ngIf="loginPage">
           <div class="d-flex">
            <div class="signup_1">
              <h4>Login into your account 
            
              </h4>
            </div>
            <div class="close_img cursor" (click)="close()">
              <img src="../../../assets/images/close-m.svg">
            </div>
           </div>
           <div class="ml-md-4">
<div class="name_form_section" >

          <form [formGroup]="loginFormGroup">
                    <div class="email-form-section ">
                      <input (keydown.space)="$event.preventDefault();" class="form-control" formControlName="email" placeholder="Enter email address">
                      <div *ngIf="f.email.errors?.required && submitted" class="invalid-feedback m-t d-block"> Email is required </div>
                      <div *ngIf="f.email.errors?.email && submitted" class="invalid-feedback m-t d-block"> Email is invalid </div>
                    </div>
                     

                     <div class="password-form-section mt-md-4 pt-md-1">
                           <input  type="password" class="form-control" formControlName="password" placeholder="Enter your password">
                            <div
                    *ngIf="f.password.errors?.required && submitted"
                    class="invalid-feedback m-t d-block"
                  >
                    Password is required
                  </div>
                            <div
                    *ngIf="loginErrorMessage != null"
                    class="invalid-feedback m-t d-block"
                  >
                    {{loginErrorMessage}}
                  </div>
                         </div>
                  </form>
         </div>
          

         <div class="section_signUp">
             <!-- <p class="terms">By signup for summarizly.ai,i agree to the <span class="text-decoration-underline">terms and conditions</span></p> -->
 <!-- <div class="btn-signin"  *ngIf="signUpWithEmail">
                    <button class="pointer" (click)="signUp()" type="submit">Continue</button>
                  </div> -->
 <div class="btn-signin mt-4 d-flex"  >
                    <button class="pointer" (click)="login()" type="submit">Login</button>
                    <a (click)="forGotPassword()">Forgot Password</a>
                  </div>
                   <div class='google-intergration'>
                <p>OR</p>
                <div class="google-btn">
                  <button type="submit" class="text-decoration-underline">
                    <a [href]="url | safePipe">
                      Login via Google </a>
                  </button>
                </div>
                <h6>Don't have an account? <span class="pointer">
                    <a (click)="signUp()">Signup</a>
                  </span>
                </h6>
              </div>
         </div>
</div>
</div>
           <div class="login-form-section" *ngIf="resetPassword">
                    <div class="form-section">
                      <div class="d-flex">
            <div class="signup_2">
               <h4>Reset your password</h4>
            </div>
            <div class="close_img cursor" (click)="close()">
              <img src="../../../assets/images/close-m.svg">
            </div>
           </div>
                        
                          <form [formGroup]="forGotPasswordForm">
                              <div class="email-form-section ">
                           <input (keydown.space)="$event.preventDefault();" class="form-control" formControlName="email" placeholder="Enter email address">
                            <div
                    *ngIf="r.email.errors?.required && submittedPassword"
                    class="invalid-feedback m-t d-block"
                  >
                    Email is required
                  </div>
                            <div
                    *ngIf="r.email.errors?.email && submittedPassword"
                    class="invalid-feedback m-t d-block"
                  >
                    Email is invalid
                  </div>
                            <div
                    *ngIf="errorMessage != null"
                    class="invalid-feedback m-t d-block"
                  >
                    {{ errorMessage}}
                  </div>
                         </div>
                          </form>
                         
                         
                    </div>
                    <app-loader *ngIf="reset"></app-loader>
                    <div class="sign-in-section mt-md-4 pt-md-2">
                        <div class="d-md-flex">
                           <div class="btn-signin btn-reset">
                              <button class="pointer"  type="submit" (click)="resetPasswordBtn()">Reset Password</button>
                           </div>
                             
                        </div>
                    </div>

                    <div class='google-intergration pt-md-3'>
                        
  <h6>Don’t have an account? <span class="pointer" (click)="signUp()">Sign up for free</span></h6>

                    </div>
                </div>
                 <div class="password-changed " *ngIf="passwordChangedSuccesfully">
                     <div class="close_img cursor ps-img" (click)="close()">
              <img src="../../../assets/images/close-m.svg">
            </div>
            <div *ngIf="passwordReset">
                      <img src="../../assets/images/check.svg" class="success-img">
                       <p class="mt-4">Password reset link has been sent to your email. Please check your inbox.</p>
                       <h6>In case if you don’t see it consider checking your spam folder too, in case</h6>
                        <h5>Did not receive the forgot password email?  <a class="ml-md-1" (click)="resend()"> Resend</a></h5>
            </div>
            <div *ngIf="passwordResetLink">
                      <img src="../../assets/images/check.svg" class="success-img">
                       <p class="mt-4"> Password reset link has been sent to your email again. Please check your inbox.</p>
                       <h6>In case if you don’t see it consider checking your spam folder too, in case</h6>
                        <!-- <h5>Did not receive the forgot password email? <span>Resend</span></h5> -->
            </div>


                       <!-- 


                       <div class="d-flex after-success justify-content-center">
                           <a class="mr-4 pointer" (click)="loginNow()">Login now</a>
                           <a class='pointer' (click)="home()">Go to home</a>
                       </div> -->
                </div>
                </div>
                
         
 