import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { AdminService } from 'src/app/_services/admin.service';
import Swal from 'sweetalert2';
import { PublisherOnboardingDialogComponent } from '../publisher-onboarding-dialog/publisher-onboarding-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CollectionService } from 'src/app/_services/collection.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
export interface PeriodicElement {
  summaryid: any;
  summarytitle: any;
  Commentdetails: any;
  Spam: any;
  offensive: any;
  Commented_by: any;
  Publisher_id: any;
  Action: any;
}

@Component({
  selector: 'app-publisher-comments',
  templateUrl: './publisher-comments.component.html',
  styleUrls: ['./publisher-comments.component.css'],
})
export class PublisherCommentsComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = [
    'summaryid',
    'summarytitle',
    'Commentdetails',
    'Spam',
    'offensive',
    'Commented_by',
    'Publisher_id',
    'Action',
  ];
  Language: any = ['Spam', 'Offensive'];
  loader: boolean;
  userQuery: {};
  language: any = '';
  ELEMENT_DATA: Element[] = [];
  pageSize: any = 10;
  currentPage: any = 0;
  unchecked: boolean = false;
  checked: boolean = true;
  pageSizeOptions: number[] = [10, 20, 40, 100];
  totalSummary: any;
  totalBlogs: any;
  newUser: any;
  userDataCount: any;
  items: any;
  // dataSource = ELEMENT_DATA;
  dataSource = new MatTableDataSource(this.ELEMENT_DATA);
  userDelete: boolean;
  userData: any;
  totalCommentReplyCount: number;
  paramValue: boolean;
  SpamparamValue: boolean;
  offensiveparamValue: boolean;
  PublisherOnboarding: string | null;
  destroy$: Subject<any> = new Subject();
  constructor(
    private adminService: AdminService,
    private dialog: MatDialog,
    private service: CollectionService
  ) {}

  ngOnInit(): void {
    this.totalBlogs = localStorage.getItem('publisherBlogsCount');

    console.log(this.totalBlogs);

    this.userApisList();
  }

  userApisList() {
    this.loader = true;

    this.userQuery = {
      // spam: this.SpamparamValue,
      // offensive: this.offensiveparamValue,
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };

    this.adminService
      .PublisherComments(this.userQuery)
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        console.log(result);
        console.log('data', result);
        this.loader = false;

        this.ELEMENT_DATA = result.commentData;
        this.userData = result.userData;
        this.totalSummary = result.commentCount[0].totalCommentCount;
        this.newUser = result.commentCount[0].MarkAsSpamCount;
        this.userDataCount = result.commentCount[0].MarkAsOffensiveCount;
        this.totalCommentReplyCount = result.commentCount[0].commentReplyCount;
        this.items = [
          { name: 'Total Comments', number: this.totalSummary },
          { name: 'Spam', number: this.newUser },
          { name: 'Offensive', number: this.userDataCount },
          { name: 'Replies', number: this.totalCommentReplyCount },
        ];

        this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
      });
  }
  showFullText = false;

  toggleText(item: any) {
    // this.showFullText = !this.showFullText;
    Swal.fire({
      title: 'Comment',
      text: item,
      showConfirmButton: true, // Hide the "OK" button
    });
  }
  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;

    this.currentPage = event.pageIndex;
    let newCurrentPage = event.pageIndex + 1;
    console.log(this.currentPage);
    this.loader = true;
    this.adminService.adminCommentsList(this.userQuery).subscribe((result) => {
      console.log(result);
      console.log('data', result);
      this.loader = false;

      this.ELEMENT_DATA = result.data;
      this.userData = result.userData;
      this.totalSummary = result.totalComments;
      this.newUser = result.totalCounts[0].MarkAsSpamCount;
      this.userDataCount = result.totalCounts[0].OffensiveCount;
      this.totalCommentReplyCount = result.totalCounts[0].CommentReplyCount;
      this.items = [
        { name: 'Total Comments', number: this.totalSummary },
        { name: 'Spam', number: this.newUser },
        { name: 'Offensive', number: this.userDataCount },
        { name: 'Replies', number: this.totalCommentReplyCount },
      ];

      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    });
  }
  langugeChange(e: any) {
    this.language = e.target.value;
    console.log(this.language);
    if (this.language == 'Spam') {
      this.SpamparamValue = true;
      this.offensiveparamValue = false;
    } else if (this.language == 'Offensive') {
      this.offensiveparamValue = true;
      this.SpamparamValue = false;
    } else if (this.language == '') {
      this.offensiveparamValue = false;
      this.SpamparamValue = false;
    }
    this.userApisList();
  }
  toggleChanged(e: any) {
    console.log('toggl', e.target.checked);
  }
  deleteCommets(item: any) {
    console.log(item);
    let obj = {
      collectionId: item._id,
      commentId: item.collectionComments[0]._id,
    };
    this.service.publisherDeleteComment(obj).subscribe((response) => {
      this.userApisList();
      Swal.fire({
        text: 'Comment delete succefully',
        showConfirmButton: true, // Hide the "OK" button
        icon: 'success', // You can change the icon type
        timer: 2000, // Set the timer to 3000 milliseconds (3 seconds)
      });
    });
  }
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
