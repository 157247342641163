import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CollectionService } from 'src/app/_services/collection.service';

@Component({
  selector: 'app-edit-categories',
  templateUrl: './edit-categories.component.html',
  styleUrls: ['./edit-categories.component.css'],
})
export class EditCategoriesComponent implements OnInit {
  sessionType: any;
  catergoryList: any;
  searchTopic: any;
  letters: string[] = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
  ];
  searchByLetterName: any;
  topicName: string;
  optionReset: any = '';
  constructor(private service: CollectionService, private router: Router) {}

  ngOnInit(): void {
    if (this.service.getToken() != '') {
      this.service.loginUserDetails().subscribe((result) => {
        this.sessionType = result.data.userType;
      });
    }
    if (this.service.getToken() == '') {
      // console.log('not-login');
      this.sessionType = 'home';
    }

    this.service.allCatergory().subscribe((res: any) => {
      console.log(res);
      var feed = res.data;
      this.catergoryList = res.data;
    });
  }
  reset() {
    this.optionReset = '';
    let obj = {
      letter: (this.searchByLetterName = ''),
      Topic: (this.topicName = ''),
    };
    this.service.SearchTopic(obj).subscribe((res: any) => {
      console.log(res);
      var feed = res.data;
      this.catergoryList = res.data;
    });
  }
  seachTopic(e: any) {
    console.log(e.target.value);
    // return;
    this.topicName = e.target.value;
    this.searchByLetterName = '';
    let obj = {
      Topic: e.target.value,
    };
    this.service.SearchTopic(obj).subscribe((res: any) => {
      console.log(res);
      var feed = res.data;
      this.catergoryList = res.data;
    });
  }
  serachByLetter(e: any) {
    // console.log(e.target.value);
    // return;
    this.searchByLetterName = e;
    this.topicName = '';
    let obj = {
      letter: e,
    };
    this.service.SearchTopic(obj).subscribe((res: any) => {
      console.log(res);
      var feed = res.data;
      this.catergoryList = res.data;
    });
  }
  updateChannel(item: any) {
    const queryParams = { name: `${item.name}`, id: `${item._id}` };
    this.router.navigate(['updateCategories'], { queryParams });
  }
  activetoggleChanged(e: any, item: any) {
    console.log('activetoggle', item.subTopics);
    // return;
    const formData = new FormData();
    formData.append('subTopics', JSON.stringify(item.subTopics));

    formData.append('isAdded', 'false');

    this.service.editCategroy(formData, item._id).subscribe((res) => {
      console.log(res);
      this.service.allCatergory().subscribe((res: any) => {
        console.log(res);
        var feed = res.data;
        this.catergoryList = res.data;
      });
    });
  }
  inActivetoggleChanged(e: any, item: any) {
    const formData = new FormData();
    formData.append('subTopics', JSON.stringify(item.subTopics));

    formData.append('isAdded', 'true');

    this.service.editCategroy(formData, item._id).subscribe((res) => {
      console.log(res);
      this.service.allCatergory().subscribe((res: any) => {
        console.log(res);
        var feed = res.data;
        this.catergoryList = res.data;
      });
    });
  }
  getBackgroundColor(item: any) {
    return item.isAdded ? '#2196f3' : ''; // Change color as needed
  }
}
