import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'app-summaryfeed2',
  templateUrl: './summaryfeed2.component.html',
  styleUrls: ['./summaryfeed2.component.css'],
})
export class Summaryfeed2Component implements OnInit {
  caterorySection: any = [
    'Fashion',
    'beauty',
    'Books',
    'Articles',
    'Books',
    'Art',
    'Moblies',
    'Music',
    'Movies',
  ];
  summaryFeed: any = [
    "Sunscreen is the most important part of a skincare routine as it protects from the sun's harmful rays that can cause cancer or premature aging.",
    "Sunscreen is the most important part of a skincare routine as it protects from the sun's harmful rays that can cause cancer or premature aging.",
    "Sunscreen is the most important part of a skincare routine as it protects from the sun's harmful rays that can cause cancer or premature aging.",
    "Sunscreen is the most important part of a skincare routine as it protects from the sun's harmful rays that can cause cancer or premature aging.",
    "Sunscreen is the most important part of a skincare routine as it protects from the sun's harmful rays that can cause cancer or premature aging.",
  ];
  fullsummary: any = [
    {
      title:
        "Arbonne's Calm line cleansing milk is a natural and vegan product that works well for normal to oily skin types.",
      paragraph:
        'Use a little of the non-foaming On brand cleanser and exfoliate twice a week with The Ordinary peeling solution, but be careful as it can hurt.Use a little of the non-foaming On brand cleanser and exfoliate twice a week with The Ordinary peeling solution, but be careful as it can hurt.Use a little of the non-foaming On brand cleanser and exfoliate twice a week with The Ordinary peeling solution, but be careful as it can hurt.Use a little of the non-foaming On brand cleanser and exfoliate twice a week with The Ordinary peeling solution, but be careful as it can hurt.',
    },
    {
      title:
        "Arbonne's Calm line cleansing milk is a natural and vegan product that works well for normal to oily skin types.",
      paragraph:
        'Use a little of the non-foaming On brand cleanser and exfoliate twice a week with The Ordinary peeling solution, but be careful as it can hurt.Use a little of the non-foaming On brand cleanser and exfoliate twice a week with The Ordinary peeling solution, but be careful as it can hurt.',
    },
    {
      title:
        "Arbonne's Calm line cleansing milk is a natural and vegan product that works well for normal to oily skin types.",
      paragraph:
        'Use a little of the non-foaming On brand cleanser and exfoliate twice a week with The Ordinary peeling solution, but be careful as it can hurt.Use a little of the non-foaming On brand cleanser and exfoliate twice a week with The Ordinary peeling solution, but be careful as it can hurt.',
    },
    {
      title:
        "Arbonne's Calm line cleansing milk is a natural and vegan product that works well for normal to oily skin types.",
      paragraph:
        'Use a little of the non-foaming On brand cleanser and exfoliate twice a week with The Ordinary peeling solution, but be careful as it can hurt.Use a little of the non-foaming On brand cleanser and exfoliate twice a week with The Ordinary peeling solution, but be careful as it can hurt.',
    },
    {
      title:
        "Arbonne's Calm line cleansing milk is a natural and vegan product that works well for normal to oily skin types.",
      paragraph:
        'Use a little of the non-foaming On brand cleanser and exfoliate twice a week with The Ordinary peeling solution, but be careful as it can hurt.Use a little of the non-foaming On brand cleanser and exfoliate twice a week with The Ordinary peeling solution, but be careful as it can hurt.',
    },
    {
      title:
        "Arbonne's Calm line cleansing milk is a natural and vegan product that works well for normal to oily skin types.",
      paragraph:
        'Use a little of the non-foaming On brand cleanser and exfoliate twice a week with The Ordinary peeling solution, but be careful as it can hurt.Use a little of the non-foaming On brand cleanser and exfoliate twice a week with The Ordinary peeling solution, but be careful as it can hurt.',
    },
    {
      title:
        "Arbonne's Calm line cleansing milk is a natural and vegan product that works well for normal to oily skin types.",
      paragraph:
        'Use a little of the non-foaming On brand cleanser and exfoliate twice a week with The Ordinary peeling solution, but be careful as it can hurt.Use a little of the non-foaming On brand cleanser and exfoliate twice a week with The Ordinary peeling solution, but be careful as it can hurt.',
    },
  ];
  collectionRealtedData: any = [
    {
      thumbnail: 'https://img.youtube.com/vi/ilTgzCEjSMg/hqdefault.jpg',
      title: 'Arbonnes Calm line cleansing milk is a natural',
      channelname: 'Think School',
      count: '457k',
    },
    {
      thumbnail: 'https://img.youtube.com/vi/ilTgzCEjSMg/hqdefault.jpg',
      title: 'Arbonnes Calm line cleansing milk is a natural',
      channelname: 'Think School',
      count: '457k',
    },
    {
      thumbnail: 'https://img.youtube.com/vi/ilTgzCEjSMg/hqdefault.jpg',
      title: 'Arbonnes Calm line cleansing milk is a natural',
      channelname: 'Think School',
      count: '457k',
    },
    {
      thumbnail: 'https://img.youtube.com/vi/ilTgzCEjSMg/hqdefault.jpg',
      title: 'Arbonnes Calm line cleansing milk is a natural',
      channelname: 'Think School',
      count: '457k',
    },
  ];
  showtext: boolean = false;
  constructor(@Inject(DOCUMENT) document: Document) {}

  ngOnInit(): void {}
  readMore: boolean = true;
  readLess: boolean = false;
  readLessBtn() {
    this.showtext = false;
    this.readLess = false;
    this.readMore = true;
  }
  navClick(item: any) {
    console.log('button name', item);
  }

  readmore() {
    this.showtext = true;
    this.readLess = true;
    document.getElementById('fullSummary')!.style.display = 'none';
    this.readMore = false;
  }
  FollowChannelName(element: any, text: any) {
    element.textContent = text;
    if (text != null) {
      document.getElementById('upvote')!.textContent = text;
    }
    console.log(element);
    console.log(text);
  }
  upVotedBtn(e: any, text: any) {
    e.textContent = text;
    document.getElementById('upvote1')!.textContent = text;
  }
}
