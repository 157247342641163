import { Component, OnInit } from '@angular/core';
import { CollectionService } from '../_services/collection.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-userbookmarks',
  templateUrl: './userbookmarks.component.html',
  styleUrls: ['./userbookmarks.component.css'],
})
export class UserbookmarksComponent implements OnInit {
  params: any;
  userDetails: any;
  collectionList: any;
  userName: any;
  categoriesData: any;
  loader: boolean;
  collectionName: any = '';
  userNameRoute: any;
  activeState: any = 'All';
  constructor(
    private collectionService: CollectionService,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute
  ) {}
  slideConfig = {
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 2,
    centerMode: true,
    autoplay: false,
    autoplaySpeed: 2000,
    prevArrow:
      "<img class='a-left control-c prev slick-prev' src='../../assets/images/l-11.png'>",
    nextArrow:
      "<img class='a-right control-c next slick-next' src='../../assets/images/l-22.png'>",
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: true,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: true,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 1,
        },
      },
    ],
  };

  slickInit(e: any) {
    console.log('slick initialized');
  }
  ngOnInit(): void {
    this.userNameRoute = localStorage.getItem('ID');
    this.userProfileDetaails();
  }
  userProfileDetaails() {
    this.params = {
      _id: this.userNameRoute,
      categorieName: this.collectionName,
    };
    this.loader = true;
    this.collectionService.userProductGetCollection().subscribe((result) => {
      console.log('user', result);
      this.loader = false;

      this.userDetails = result.followedTopics;
      this.collectionList = result.data;

      // this.userName = result.data[0].name;
      this.categoriesData = result.followedTopics.sort((a: any, b: any) =>
        a.toLowerCase() < b.toLowerCase() ? -1 : 1
      );
      // // console.log(this.categoriesData);

      // // this.followChannel = result.data[0].followpublisher;
      // // this.categoryRecords = result.totalTopicRecord;
    });
  }
  navClick(item: any): void {
    // this.activeState = item.name;
    // return;
    console.log('navClick', item);
    if (item.name == 'All') {
      this.collectionName = '';
      this.activeState = 'All';

      console.log('click');
      this.params = {
        _id: this.userNameRoute,
        topicName: this.collectionName,
      };
      this.collectionService
        .userProfileDetails(this.params)
        .subscribe((response) => {
          this.collectionList = response.summaryData;
          // this.categoryRecords = response.totalCategoryRecord;
        });
    } else {
      // this.active = false;
      this.loader = true;
      // this.currentPage = 1;
      this.activeState = item.name;
      this.collectionName = item.name;
      this.params = {
        _id: this.userNameRoute,
        // visitorId: this.userId,
        topicName: this.collectionName,
      };
      this.collectionService
        .userProfileDetails(this.params)
        .subscribe((response) => {
          this.loader = false;

          this.collectionList = response.summaryData;
          // this.categoryRecords = response.totalCategoryRecord;

          console.log('click', response.totalDocument);
        });
    }
  }
  unfollowBookmark(item: any) {
    console.log(item);

    this.collectionService.unbookSummarty(item._id).subscribe((res) => {
      this.userProfileDetaails();
    });
  }
}
