import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidenavheader',
  templateUrl: './sidenavheader.component.html',
  styleUrls: ['./sidenavheader.component.css'],
})
export class SidenavheaderComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {}
  logout(): void {
    localStorage.clear();
    this.router.navigate(['lookup-admin/login']);
  }
}
