import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CollectionService } from 'src/app/_services/collection.service';

@Component({
  selector: 'app-viewproduct',
  templateUrl: './viewproduct.component.html',
  styleUrls: ['./viewproduct.component.css'],
})
export class ViewproductComponent implements OnInit {
  dressImagesCards = [
    {
      key: 'code1',
      img: '../../assets/images/code-1.png',
      name: 'Women top wear in red. Party wear',
      price: '$30',
    },
    {
      key: 'code2',
      img: '../../assets/images/code-2.png',
      name: 'Women top wear in red. Party wear',
      price: '$30',
    },
    {
      key: 'code3',
      img: '../../assets/images/code-3.png',
      name: 'Women top wear in red. Party wear',
      price: '$30',
    },
    {
      img: '../../assets/images/d-1.png',
      name: 'Women top wear in red. Party wear',
      price: '$30',
    },
    {
      key: 'code1',
      img: '../../assets/images/code-1.png',
      name: 'Women top wear in red. Party wear',
      price: '$30',
    },
    {
      key: 'code2',
      img: '../../assets/images/code-2.png',
      name: 'Women top wear in red. Party wear',
      price: '$30',
    },
    {
      key: 'code3',
      img: '../../assets/images/code-3.png',
      name: 'Women top wear in red. Party wear',
      price: '$30',
    },
    {
      img: '../../assets/images/d-1.png',
      name: 'Women top wear in red. Party wear',
      price: '$30',
    },
    {
      key: 'code1',
      img: '../../assets/images/code-1.png',
      name: 'Women top wear in red. Party wear',
      price: '$30',
    },
    {
      key: 'code2',
      img: '../../assets/images/code-2.png',
      name: 'Women top wear in red. Party wear',
      price: '$30',
    },
    {
      key: 'code3',
      img: '../../assets/images/code-3.png',
      name: 'Women top wear in red. Party wear',
      price: '$30',
    },
    {
      img: '../../assets/images/d-1.png',
      name: 'Women top wear in red. Party wear',
      price: '$30',
    },
  ];
  navItem: any = [
    { name: 'All', id: '87892748h79y3478h787w84' },
    { name: 'Beauty', id: 'hgiu7836487uhgr' },
    { name: 'Lipsticks', id: 'hbdsuh8y38thi' },
    { name: 'Clothes', id: 'snbfiye876t7834ytuhiury786348' },
    { name: 'Nails', id: 'smndbfih86438tgyir' },
    { name: 'Fashion', id: 'shdbfiuy38748' },
    // '',
    // 'Lipsticks',
    // '',
    // 'Hats',
    // 'Skin Care',
    // '',
    // '',
  ];
  productListView = [
    {
      img: '../../assets/images/code-1.png',
      brandImg: '../../assets/brands/logo-1.png',
      name: 'Women top wear in red. Party wear',
    },
    {
      img: '../../assets/images/code-1.png',
      brandImg: '../../assets/brands/logo-2.png',
      name: 'Women top wear in red. Party wear',
    },
    {
      img: '../../assets/images/code-1.png',
      brandImg: '../../assets/brands/logo-3.png',
      name: 'Women top wear in red. Party wear',
    },
    {
      img: '../../assets/images/code-1.png',
      brandImg: '../../assets/brands/logo-1.png',
      name: 'Women top wear in red. Party wear',
    },
    {
      img: '../../assets/images/code-1.png',
      brandImg: '../../assets/brands/logo-2.png',
      name: 'Women top wear in red. Party wear',
    },
    {
      img: '../../assets/images/code-1.png',
      name: 'Women top wear in red. Party wear',
    },
  ];
  hide: boolean = true;
  show: boolean = false;
  productList: any;
  userName: any;
  product_id: any;
  id: any;
  title: any;
  activeState: any = 'All';
  dataToggle: any = [
    { route: 'home', des: 'desc 1', name: 'Home' },
    { route: 'home1', des: 'desc 2', name: 'Home 2' },
    { route: 'home 2', des: 'desc 3', name: 'Home 3' },
    { route: 'home 3', des: 'desc 4', name: 'Home 4' },
  ];
  productParams: any = '';
  productName: any = '';
  categoryList: any;
  queryParamsDetails: any;
  username: any;
  queryParms: { channelName: any };
  channelName: any;
  buttonText: any = 'Follow';
  followBtnShow: boolean = true;
  unfollowBtnShow: boolean = false;
  caterogoryId: any;
  categoryListFollowList: any = [];
  categoryListFollowListLoop: any;
  categoryListLoop: any;
  categoryArray: any = [];
  followTab: boolean = true;
  followingTab: boolean = false;
  followcaterogoryids: any = [];
  unfollowcaterogoryId: any;
  userType: string | null;
  shareLink: string;
  sessionType: string;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private productService: CollectionService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.channelName = this.router.url.split('/').pop();
    this.route.queryParamMap.subscribe((params) => {
      this.queryParamsDetails = { ...params.keys, ...params };
      // console.log(this.queryParamsDetails);
    });

    this.productService.caterogoryList().subscribe((res) => {
      // console.log(res);
      this.categoryList = res.data;
      // console.log(this.categoryList);
      if (this.productService.getToken() != '') {
        this.getFollowingTab();
      }
      this.activeState = res.data[0].name;
      this.caterogoryId = res.data[0]._id;
    });
    this.productParams = {
      categorieName: this.productName,
    };
    this.productService
      .productListFliter(this.productParams)
      .subscribe((res) => {
        this.productList = res.data;
      });
    if (isPlatformBrowser(platformId)) {
      console.log('Collection Platform browser', platformId);
    }
    if (isPlatformServer(platformId)) {
      console.log('Collection Platform server', platformId);
    }
  }

  ngOnInit(): void {
    if (this.productService.getToken() == '') {
      this.sessionType = 'home';
    }
    if (this.productService.getToken() != '') {
      console.log('login sucess fully');
      this.productService.loginUserDetails().subscribe((result) => {
        this.sessionType = result.data.userType;

        console.log('product page', this.sessionType);
      });
    }
    if (isPlatformServer(this.platformId)) {
      this.userType = localStorage.getItem('userType');
      // console.log(this.productService.userName);
      // console.log(this.router.url.split('/').pop());
    }
  }
  navClick(item: any) {
    if (item.name == 'All') {
      this.productName = '';
      this.followTab = false;
      this.followingTab = false;
      this.activeState = 'All';

      console.log('click');
      this.productParams = {
        categorieName: this.productName,
      };
      this.productService
        .productListFliter(this.productParams)
        .subscribe((res) => {
          this.productList = res.data;
        });
    } else {
      this.followingTab = item.following;
      this.followTab = item.follow;
      this.activeState = item.name;

      this.caterogoryId = item._id;
      this.productName = item.name;
      this.productParams = {
        categorieName: this.productName,
      };
      this.productService
        .productListFliter(this.productParams)
        .subscribe((res) => {
          this.productList = res.data;
        });
    }
  }

  followBtn(item: boolean) {
    // console.log(item);
    if (item) {
      let obj = {
        categoryId: this.caterogoryId,
      };
      // console.log(obj);
      this.productService.caterogyFollow(obj).subscribe((res) => {
        // console.log(obj);
        this.getFollowingTab();
      });
    } else {
      let obj = {
        categoryId: this.caterogoryId,
      };
      // console.log(obj);
      this.productService
        .caterogyUnFollow(this.caterogoryId)
        .subscribe((res) => {
          // console.log(obj);
          this.followTab = true;
          this.followingTab = false;
        });
    }
  }
  productCard(title: any, id: any) {
    this.router.navigate([`p/${title.split(' ').join('-')}/id-${id}`]);
  }
  share(title: any, id: any) {
    // this.router.navigate([`p/${title.split(' ').join('-')}/id-${id}`]);
    this.shareLink = `p/${title.split(' ').join('-')}/id-${id}`;
    console.log(this.shareLink);
  }
  showCoupon() {
    // console.log(i);

    this.show = true;
    this.hide = false;
  }
  cardDetails() {
    this.router.navigate(['ProductsDetails']), { realtiveTo: this.route };
  }

  getFollowingTab() {
    this.followcaterogoryids = [];
    this.productService.caterogyFollowList().subscribe((result) => {
      // console.log(result);
      this.categoryListFollowListLoop = result.data;
      this.categoryListFollowListLoop.forEach((item: any) => {
        this.followcaterogoryids.push(item._id);
      });
      // console.log(this.followcaterogoryids);

      this.categoryList.forEach((citem: any) => {
        citem.follow = true;
        citem.following = false;
        this.followcaterogoryids.forEach((fitem: any) => {
          if (citem._id == fitem) {
            // console.log(citem._id);
            citem.following = true;
            citem.follow = false;
            this.followingTab = citem.following;
            this.followTab = citem.follow;
          }
        });
      });

      // console.log('caterogy-list', this.categoryList);
    });
  }
}
