<nav class="navbar navbar-expand-lg navbar-light  fixed-top nav">
  <div class="container">
    <a href="/"> 
    <div class="logo-class d-flex mr-md-5" >
      <!-- <img src="../../assets/images/link-cart-logo.svg" class="header-logo mr-1 " /> -->
       
        <p>Summarizly AI</p>
      
    </div>
    </a>
    <a href="/feed"> 
    <div class="logo-class d-flex ml-3 pointer">
     
        <img src="../../assets/images/feed.png" class="header-logo mr-2 mt-3" />
       
        <h2>Feed</h2>
      
    </div>
    </a>
    
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarNavAltMarkup"
      aria-controls="navbarNavAltMarkup"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
      <div class="navbar-nav ml-auto">
         <!-- <div class="collections">
            <button type="button" (click)="viewlinks()" class=" mr-md-2 mt-1 pointer" [disabled]="categoriesBtn">
              View my links</button>
         </div> -->
         <!-- <div class="collections">
            <button type="button" class=" mr-md-2 mt-1 pointer"  (click)="channelBtn()" [disabled]="categoriesBtn">
               Create channel</button>
         </div> -->
         <div class="link-btn mt-md-1" *ngIf="ChannelName != ''">
   <button class="linkProduct" (click)="linkProductBtn()" [disabled]="categoriesBtn">
        <!-- <img src="../../assets/images/link.svg" class="link-img mr-2" /> -->

           Publish
        </button>
         </div>


        
         <!-- <div class="" *ngIf="hideNotifications">
        <img src="../../assets/images/notifications.svg" class="link-img mx-4 mt-3" />
            
         </div> -->
         <div class="">
        <img src="../../assets/images/admin.png" class="admin-img ml-4 mr-2 mt-1" />
         <button mat-icon-button class="Administrator-btn" 
           
            mat-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
        <img src="../../assets/images/a-1.png" class="arrow-img mt-1 pointer" />

         
							<!-- <mat-icon> <img src="../../assets/images/panel_images/action-dot.svg" id="users-total" class="action-dots" /></mat-icon> -->
							<div #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu" place></div>
						</button>
						<mat-menu #menu="matMenu" xPosition="before">
							<!-- <button class="View" mat-menu-item value="Profile" routerLink="/Setting"> <img src="../../assets/images/panel_images/proflie-1.png" class="mr-3 logout" /> <span>Profile</span>
              </button> -->
							<button class="Deactivate" mat-menu-item value="Logout" (click)="profile()"><span>My profile</span></button>
							<!-- <button class="Deactivate" mat-menu-item value="Logout" (click)="collectionRouterCreator()"><span>Publish a collection</span></button> -->
							<!-- <button class="Deactivate" mat-menu-item value="Logout"  (click)="productRouteCreator()"><span>Publish a product</span></button> -->
							<button class="Deactivate" mat-menu-item value="Logout" (click)="myCollections()" ><span>My Summry</span></button>
							<!-- <button class="Deactivate" mat-menu-item value="Logout" (click)="myProducts()"><span>My Products</span></button> -->
							<button class="Deactivate" mat-menu-item value="Logout"(click)="myVideos()"><span>My videos</span></button>
							<!-- <button class="Deactivate" mat-menu-item value="Logout"(click)="publisherAnalatics()"><span>Anaytics</span></button> -->
							<!-- <button class="Deactivate" mat-menu-item value="Logout"><span>Delete account </span></button> -->
							<button class="Deactivate" mat-menu-item value="Logout" (click)="logout()"> <img src="../../assets/images/log-out.png" class="mr-3 logout" /> <span>Logout</span>
              </button>
						</mat-menu>
            
         </div>
     
        
      </div>
      
    </div>
  </div>
</nav>