import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { AdminService } from 'src/app/_services/admin.service';
import Swal from 'sweetalert2';
export interface PeriodicElement {
  sid: any;
  Language: any;
  totalSummaries: any;
  view: any;
  status: any;
  action: any;
}
@Component({
  selector: 'app-languages',
  templateUrl: './languages.component.html',
  styleUrls: ['./languages.component.css'],
})
export class LanguagesComponent implements OnInit {
  displayedColumns: string[] = [
    'sid',
    'Language',
    'totalSummaries',
    'view',
    'status',
    'action',
  ];
  Language: any = ['true', 'false'];
  loader: boolean;
  userQuery: {};
  language: any = true;
  ELEMENT_DATA: Element[] = [];
  pageSize: any = 10;
  currentPage: any = 0;
  unchecked: boolean = false;
  checked: boolean = true;
  pageSizeOptions: number[] = [10, 20, 40, 100];
  totalSummary: any;
  newUser: any;
  userDataCount: any;
  items: { name: string; number: number }[];
  dataSource = new MatTableDataSource(this.ELEMENT_DATA);
  userDelete: boolean;
  userData: any;
  obj: { action: string };
  constructor(private adminService: AdminService) {}

  ngOnInit(): void {
    this.userApisList();
  }

  userApisList() {
    this.loader = true;

    this.userQuery = {
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };

    this.adminService.adminSummariesHide(this.userQuery).subscribe((result) => {
      console.log(result);
      console.log('data', result);
      this.loader = false;

      this.ELEMENT_DATA = result.summaries;
      this.userData = result.topics;
      this.totalSummary = result.totalActive;
      this.newUser = result.totalCount;
      this.userDataCount = result.totalInactive;
      this.items = [
        { name: 'Total Count', number: this.newUser },
        { name: 'Total Active', number: this.totalSummary },
        { name: 'Total Inactive', number: this.userDataCount },
      ];

      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    });
  }

  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;

    this.currentPage = event.pageIndex;
    let newCurrentPage = event.pageIndex + 1;
    console.log(this.currentPage);
    this.loader = true;

    this.userQuery = {
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: newCurrentPage ? newCurrentPage : '',
    };

    this.adminService.adminSummariesHide(this.userQuery).subscribe((result) => {
      console.log(result);
      console.log('data', result);
      this.loader = false;

      this.ELEMENT_DATA = result.summaries;
      this.userData = result.topics;
      this.totalSummary = result.totalActive;
      this.newUser = result.totalCount;
      this.userDataCount = result.totalInactive;
      this.items = [
        { name: 'Total Count', number: this.newUser },
        { name: 'Total Active', number: this.totalSummary },
        { name: 'Total Inactive', number: this.userDataCount },
      ];

      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    });
  }
  langugeChange(e: any) {
    this.language = e.target.value;
    this.userApisList();
  }
  toggleChanged(e: any) {
    console.log('toggl', e.target.checked);
  }
  activetoggleChanged(e: any, item: any) {
    console.log('activetoggle', e, item);
    // return;
    if (e == 'open') {
      this.obj = {
        action: 'Make it active',
      };
    } else if (e == 'closed') {
      this.obj = {
        action: 'Make it inactive',
      };
    }

    this.adminService
      .reportStatusSummary(this.obj, item._id)
      .subscribe((res) => {
        this.userApisList();
        Swal.fire({
          title: 'status updated successfully',
          icon: 'success', // You can change the icon type
          timer: 2000, // Set the timer to 3000 milliseconds (3 seconds)
          showConfirmButton: false, // Hide the "OK" button
        });
      });
  }
}
