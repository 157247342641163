<div class="">
  <div class="close-btn">
    <button (click)="close()">
      <img src="../../assets/images/close.png" class="close-img" />
    </button>
  </div>
  <div *ngFor="let item of report">
    <div class="d-flex d-d">
      <div class="t-r">
        <h3>{{ item.name }}</h3>
        <p>{{ item.paragraph }}</p>
      </div>
      <div class="ml-auto mt-3 radio-button-section">
        <mat-radio-group
          aria-label="Select an option"
          (change)="reportBtn($event)"
        >
          <mat-radio-button [value]="item.tag"></mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
  </div>
</div>
