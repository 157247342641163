

<div class="main">
  <app-creatornavbar></app-creatornavbar>
    <div class="container-fluid">
        
        <div class="login-section mt-2">
          <div class="row flex-column-reverse flex-md-row">
              <div class="col-md-6 ">
                      <div class="login-section-common-data pl-md-4 pt-md-5" id="login-para">
                    <div class="common-data-login">

                      
                         <h4>Find all the product links shared, curated, promoted by top Youbube creators in youtube videos
in
                         <span class="fashion">fashion,</span> <span class="beauty"> beauty, </span> <span class="personal-care">personal care </span> all at one place 
                         </h4>

                         <h2 >As a bonus you can also see all creator discount codes shared by YouTube creators in 1000s of videos in one place</h2>
                     </div>
                </div>
                
              </div>
              <div class="col-md-6">
                 
                <div class="login-form-section mt-md-5" *ngIf="confirmPassword">
                    <div class="form-section">
                         <h4>Reset your password</h4>
                          
                         <form [formGroup]="resetPassword">

                         
                         <div class="email-form-section mt-md-3">
                           <input (keydown.space)="$event.preventDefault();" type="password" class="form-control" formControlName="password" placeholder="Enter new password">
                           <div
                    *ngIf="resetPasswordControl.password.errors?.required && submittedUser"
                    class="invalid-feedback m-t d-block"
                  >
                  Password is required
                  </div>
                         </div>
                         <div class="email-form-section mt-md-4">
                           <input type="password" class="form-control" formControlName="confirmPassword" placeholder="Confirm password">
                           <div
                    *ngIf="resetPasswordControl.password.errors?.required && submittedUser"
                    class="invalid-feedback m-t d-block"
                  >
                  Confirm password is required
                  </div>
                           <div
                    *ngIf="error != null"
                    class="invalid-feedback m-t d-block"
                  >
                  {{ this.error}}
                  </div>
                         </div>
                         </form>
                    </div>
                    <div class="sign-in-section mt-md-4 pt-md-2">
                        <div class="d-flex">
                           <div class="btn-signin">
                              <button class="pointer"  type="submit" (click)="resetPasswordBtn()">Reset Password</button>
                           </div>
                             
                        </div>
                    </div>

                    <div class='google-intergration pt-md-3'>
                        
  <h6>Need help?<span class="pointer"> Contact us</span></h6>

                    </div>
                </div>

                <div class="password-changed mt-md-5" *ngIf="passwordChangedSuccesfully">
                      <img src="../../assets/images/check.png" class="success-img">
                       <p class="mt-md-4">Password changed successfully</p>

                       <div class="d-flex after-success justify-content-center">
                           <a class="mr-md-4 pointer" (click)="loginNow()">Login now</a>
                           <a class='pointer' (click)="home()">Go to home</a>
                       </div>
                </div>
              </div>
          </div>
        </div>
    </div>
</div>
