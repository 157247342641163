<app-homenavbar *ngIf="sessionType == 'home'"></app-homenavbar>
<app-usernavbar *ngIf="sessionType == 'user'"></app-usernavbar>
<app-navbar *ngIf="sessionType == 'powerUser'"></app-navbar>
<app-creatornavbar *ngIf="sessionType == 'publisher'"></app-creatornavbar>
<app-loader *ngIf="loader"></app-loader>

<p *ngIf="succesfully" class="succesfully mt-md-4">Profile updated successfully.</p>
<div class="container">
    <h4 class="mt-4 up-p-header">Update profile</h4>
    <div class="profile-update">
        <form [formGroup]="profileUpdateFormData">
        <div class="d-md-flex">
            <div class="upload pt-4 pl-4 mt-5 mb-3">
      <img *ngIf="url != null" [src]="url" width=100 height=100 alt="" class="upload-img">
      <img *ngIf="profile != null" [src]="profile" width=100 height=100 alt="" class="upload-img">
      <img *ngIf="url == null && profile == null" src="../../assets/images/place-holder.jpg" width=100 height=100 alt="" class="upload-img">
      <div class="round">
        <input type="file" accept="image/*" formControlName="profile"  (change)="onFileSelected($event)">
        <img src="../../assets/images/camera.svg" width=100 height=100 alt="" class="camera-img">
      </div>
    </div>

    <div class="update-profile-section mt-md-5 ml-md-5">
       <div class="catergories-add-section mt-md-2">
          <input formControlName="name" type="text" placeholder=" Enter the name" class="form-control">
        </div>
         <select formControlName="gender">
                      <option value="" selected hidden disabled>Select gender</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </select>
       <div class="catergories-add-section mt-md-2">
          <input formControlName="email" type="text" placeholder=" Enter the email" class="form-control">
        </div>
        <div class="calendra-field">
                    <mat-form-field appearance="fill">
                      <div class="arrow-up"></div>
                      <span matPrefix>Select Language:&nbsp;</span>
                      <mat-select [(ngModel)]="userLanugages" [formControl]="languageControl" multiple disableOptionCentering panelClass="languageClass" #languageservice (selectionChange)="languageDropDown($event)">
                        
                        <mat-option class='services-view' *ngFor="let viewService of language" [value]="viewService.key">{{viewService.key}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
         <div class="d-flex">
            <button  class="cursor back-btn-edit-profile mr-auto">Back</button>
            <button  class="cursor save-btn-profile " (click)="update()">Save</button>
        </div>
    </div>
        
        </div>

   
        </form>
    </div>
</div>