import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EditCollectionComponent } from '../edit-collection/edit-collection.component';
import { AdminLoginGuard } from '../_services/admin-login.guard';
import { AdminHomeComponent } from './admin-home/admin-home.component';
import { AdminLoginComponent } from './admin-login/admin-login.component';
import { CommentsComponent } from './comments/comments.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PublishersComponent } from './publishers/publishers.component';
import { SummariesEditComponent } from './summaryies/summaries-edit/summaries-edit.component';
import { SummaryiesComponent } from './summaryies/summaryies.component';
import { UsersComponent } from './users/users.component';
import { AdminPowerUserComponent } from './admin-power-user/admin-power-user.component';
import { AdminTopicsComponent } from './admin-topics/admin-topics.component';
import { ReportsComponent } from './reports/reports.component';
import { LanguagesComponent } from './languages/languages.component';
import { ToogleComponent } from './toogle/toogle.component';
const routes: Routes = [
  { path: 'Home', component: AdminHomeComponent },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AdminLoginGuard],
  },
  {
    path: 'summaries',
    component: SummaryiesComponent,
    canActivate: [AdminLoginGuard],
  },
  {
    path: 'publishers',
    component: PublishersComponent,
    canActivate: [AdminLoginGuard],
  },
  {
    path: 'powerUser',
    component: AdminPowerUserComponent,
    canActivate: [AdminLoginGuard],
  },
  {
    path: 'topics',
    component: AdminTopicsComponent,
    canActivate: [AdminLoginGuard],
  },
  {
    path: 'toggle',
    component: ToogleComponent,
    canActivate: [AdminLoginGuard],
  },
  {
    path: 'comments',
    component: CommentsComponent,
    canActivate: [AdminLoginGuard],
  },
  {
    path: 'reports',
    component: ReportsComponent,
    canActivate: [AdminLoginGuard],
  },
  {
    path: 'languages',
    component: LanguagesComponent,
    canActivate: [AdminLoginGuard],
  },
  {
    path: 'summaries',
    children: [
      {
        path: 'Edit-Collection',
        component: SummariesEditComponent,
        canActivate: [AdminLoginGuard],
      },
    ],
  },
  { path: 'users', component: UsersComponent, canActivate: [AdminLoginGuard] },
  { path: 'login', component: AdminLoginComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdminRoutingModule {}
