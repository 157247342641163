<app-creatornavbar></app-creatornavbar>

<div class="container">
  <div class="publisher_video_section mt-md-5">
    <div class="sync_video_section">
      <div class="sync_card card">
        <div class="card-body">
          <div class="sync_youtube">
            <div class="d-flex">
              <div class="sync_heading">
                <h5>Sync your channel</h5>

                <h6>
                  When you sync all your show up here. With one click you can
                  turn your videos into summaries
                </h6>
                <form [formGroup]="channelForm">
                  <input
                    type="text"
                    formControlName="channelId"
                    placeholder="Enter YouTube Channel ID"
                    type="text"
                    class="channel_i"
                    placeholder="enter your channel id"
                  />

                  <div
                    class="invalid-feedback m-t d-block"
                    *ngIf="channelId.channelId.errors?.required && submitted"
                  >
                    Channel ID is required.
                  </div>
                  <div
                    class="invalid-feedback m-t d-block"
                    *ngIf="channelId.channelId.errors?.pattern && submitted"
                  >
                    Channel ID is invalid.
                  </div>
                </form>
              </div>
              <app-loader *ngIf="loader"></app-loader>
              <!-- <div *ngIf="account_id  == undefined" class="phyllo_button ml-auto mt-md-4">
                        <button class="cursor" (click)="GetPhyllo()">Connect youtube Account</button>
                    </div> -->
              <div class="phyllo_button ml-auto mt-md-4">
                <button class="cursor" (click)="FetchVideos()">
                  Fetch videos
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <app-loader *ngIf="loaderVideos"></app-loader>
      <mat-paginator
        (page)="pageChanged($event)"
        [pageIndex]="currentPage"
        [pageSize]="pageSize"
        [length]="totalItems"
        [pageSizeOptions]="pageSizeOptions"
        aria-label="Select page of users"
      ></mat-paginator>
      <div class="videos_sync_section mt-md-5">
        <div class="d-flex" *ngFor="let details of youtubeData">
          <div class="video_thumbnail">
            <img src="{{ details.snippet.thumbnails.high.url }}" />
          </div>
          <div class="video_details ml-md-4">
            <h6>{{ details.snippet.title }}</h6>
            <p>{{ details.snippet.description }}</p>
            <p>{{ details.snippet.channelTitle }}</p>
            <a
              href="https://www.youtube.com/watch?v={{ details.id.videoId }}"
              target="_blank"
              >https://www.youtube.com/watch?v={{ details.id.videoId }}</a
            >
          </div>
          <div
            class="video_publish_ablog mt-md-4 ml-auto"
            *ngIf="urls1?.includes(details.id.videoId)"
          >
            <button class="cursor">Published</button>
          </div>
          <div
            class="video_publish_ablog mt-md-4 ml-auto"
            *ngIf="!urls1?.includes(details.id.videoId)"
          >
            <button class="cursor" (click)="publishBlog(details.id.videoId)">
              Create AI Summary
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
