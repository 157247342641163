import { createDirectiveTypeParams } from '@angular/compiler/src/render3/view/compiler';
import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CollectionService } from 'src/app/_services/collection.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-publisheronboarding-model',
  templateUrl: './publisheronboarding-model.component.html',
  styleUrls: ['./publisheronboarding-model.component.css'],
})
export class PublisheronboardingModelComponent implements OnInit {
  progressPercentage: number = 100; // Set the initial percentage;
  PublisherForm: FormGroup;
  PublisherYoutubeForm: FormGroup;
  PublisherchannelForm: FormGroup;
  submittedUser: any = false;
  firstNameForm: any = false;
  URl: any = `${environment.URL}`;
  success: any = false;
  youtubeUrl: any = true;
  selectContent: any = false;
  channelName: any = false;
  topics: any = false;
  urlValid: any = true;
  options: string[] = ['MALE', 'FEMALE', 'BOTH'];
  selectedOption: any = '';
  categoryList: any;
  gender_select: boolean = true;
  url: any;
  selectedFile: File;
  selectedFileEvent: File;
  fileNameEvent: any;
  topicsSeleted: any = [];
  topicsErrorMessage: boolean = true;
  errorView: boolean;
  errorMesseage: string;
  hideComponent: boolean;
  fileName: any;
  constructor(
    private fb: FormBuilder,
    private collectionService: CollectionService,
    private router: Router,
    private dialog: MatDialog,
    private route: ActivatedRoute
  ) {
    this.PublisherForm = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
    });
    this.PublisherYoutubeForm = this.fb.group({
      url: ['', [Validators.required]],
      profile: ['', [Validators.required]],
    });
    this.PublisherchannelForm = this.fb.group({
      channelName: ['', [Validators.required, Validators.pattern(/^@.+/)]],
      description: [''],
    });
  }

  ngOnInit(): void {
    this.collectionService.caterogoryList().subscribe((res: any) => {
      this.categoryList = res.data;
      this.categoryList.forEach(
        (category: any) => {
          category.isButtonSelected = false;
        },
        (error: any) => {}
      );
    });
  }

  get publisher(): { [key: string]: AbstractControl } {
    return this.PublisherForm.controls;
  }
  get publisherUrl(): { [key: string]: AbstractControl } {
    return this.PublisherYoutubeForm.controls;
  }
  get Publisherchannel(): { [key: string]: AbstractControl } {
    return this.PublisherchannelForm.controls;
  }
  next() {
    this.youtubeUrl = true;
    this.progressPercentage = 200;
    this.firstNameForm = false;
  }
  youtubeUrlValidBtn() {
    if (this.PublisherYoutubeForm.invalid) {
      this.submittedUser = true;
    } else {
      this.channelName = true;
      this.progressPercentage = 300;
      this.youtubeUrl = false;
      this.firstNameForm = false;
    }
  }
  channelNameBtn() {
    if (this.PublisherchannelForm.invalid) {
      this.submittedUser = true;
    } else {
      this.progressPercentage = 400;

      this.selectContent = true;
      this.channelName = false;
      this.youtubeUrl = false;
      this.firstNameForm = false;
    }
  }

  GenderSelectBtn() {
    this.progressPercentage = 500;
    this.topics = true;
    this.selectContent = false;
    this.channelName = false;
    this.youtubeUrl = false;
    this.firstNameForm = false;
  }
  topicsSelectBtn() {
    const formData = new FormData();
    formData.append(
      'profile',
      this.PublisherYoutubeForm.controls['profile'].value
    );
    formData.append('topic', JSON.stringify(this.topicsSeleted));
    formData.append(
      'ownerName',
      this.PublisherchannelForm.value.channelName.trim()
    );
    formData.append('url', this.PublisherYoutubeForm.value.url);
    formData.append('name', this.PublisherchannelForm.value.channelName.trim());
    formData.append('targetAudience', this.selectedOption.trim());

    formData.append(
      'description',
      this.PublisherchannelForm.value.description.trim()
    );

    this.collectionService.addChannelName(formData).subscribe((result) => {
      console.log(result);
      console.log('success');
      this.progressPercentage = 600;
      this.success = true;
      this.topics = false;
      this.selectContent = false;
      this.channelName = false;
      this.youtubeUrl = false;
      this.firstNameForm = false;
    });
  }
  completed() {
    this.dialog.closeAll();
    const queryParams = { active: false };

    // Update the query parameters
    // this.router.navigate([], {
    //   relativeTo: this.route,
    //   queryParams: queryParams,
    //   queryParamsHandling: 'merge', // This ensures that existing query parameters are preserved
    // });
    // Trigger refresh in the parent component
    // this.collectionService.triggerRefresh();
    this.router.navigate(['/Publish']);
    this.progressPercentage = 600;
    this.success = false;
    this.topics = false;
    this.selectContent = false;
    this.channelName = false;
    this.youtubeUrl = false;
    this.firstNameForm = false;
  }
  toggleColor(item: any) {
    item.isButtonSelected = !item.isButtonSelected;
    console.log('toggleColor', item.isButtonSelected);

    const index = this.topicsSeleted.indexOf(item._id);
    if (index === -1) {
      this.topicsSeleted.push(item._id); // Add if not in the array
      console.log('onButtonDoubleClick', this.topicsSeleted);
    } else {
      this.topicsSeleted.splice(index, 1); // Remove if already in the array

      console.log('onButtonDoubleClick', this.topicsSeleted);
      if (this.topicsSeleted != '') {
        this.topicsErrorMessage = false;
      } else {
        this.topicsErrorMessage = true;
      }
    }
  }
  onButtonDoubleClick(id: number) {
    const index = this.topicsSeleted.indexOf(id);
    if (index !== -1) {
      this.topicsSeleted.splice(index, 1);
    }

    console.log('onButtonDoubleClick', this.topicsSeleted);
  }
  onInput(event: any) {
    const fixedPortion = 'www.youtube.com/';

    console.log('onInput', event.target.value);
    if (event.target.value != '') {
      this.urlValid = false;
    }
    if (event.target.value == '') {
      this.urlValid = true;
    }
    if (event.target.value.startsWith(fixedPortion)) {
    } else {
      event.target.value = fixedPortion;
    }
  }

  onFileSelected(event: any): void {
    const file: File = event.target.files[0];
    const maxSizeKB = 100; // Maximum file size in kilobytes
    if (file) {
      const fileSizeKB = file.size / 1024; // Calculate file size in kilobytes

      if (fileSizeKB > maxSizeKB) {
        // Show an error message when the file is too large
        this.errorView = true;
        this.errorMesseage = `File size should be less than ${maxSizeKB}KB. Selected file size is ${fileSizeKB.toFixed(
          2
        )}KB.`;
      } else {
        this.hideComponent = false;
        const file = event.target.files && event.target.files[0];
        this.selectedFile = <File>event.target.files[0];
        this.fileName = event.target.files[0].name;

        if (event.target.files.length > 0) {
          this.errorView = false;

          const file = event.target.files[0];
          this.PublisherYoutubeForm.controls['profile'].setValue(file);
        }
        var reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = (event) => {
          this.url = (<FileReader>event.target).result;
        };
      }
    }
  }
  selectOption(option: any) {
    this.selectedOption = option;

    if (this.selectedOption != '') {
      this.gender_select = false;
    }
  }
  // You can use the userInput as needed
}
