<div class="container">
  <div class="P_onboarding">
    <div class="progress">
      <div
        class="progress-bar custom-progress-bar"
        role="progressbar"
        [style.width.px]="progressPercentage"
      ></div>
    </div>

    <div class="publisher_form" *ngIf="firstNameForm">
      <form [formGroup]="PublisherForm">
        <div class="d-flex">
          <div class="email-form-section mt-md-2">
            <label for="" class="ml-md-3">First name</label>
            <input
              class="form-control"
              formControlName="firstName"
              placeholder="Enter your firstName"
            />
            <div
              *ngIf="publisher.firstName.errors?.required && submittedUser"
              class="invalid-feedback m-t d-block"
            >
              firstName is required
            </div>
          </div>
          <div class="email-form-section mt-md-2">
            <label for="" class="ml-md-3">Last name</label>
            <input
              class="form-control"
              formControlName="lastName"
              placeholder="Enter your lastName"
            />
            <div
              *ngIf="publisher.lastName.errors?.required && submittedUser"
              class="invalid-feedback m-t d-block"
            >
              lastName is required
            </div>
          </div>
        </div>
        <div class="blog_button next-Btn">
          <button
            class="cursor"
            [disabled]="PublisherForm.invalid"
            (click)="next()"
          >
            Next <img src="../../../assets/publisherassets/arrow-right.png" />
          </button>
        </div>
      </form>
    </div>
    <div class="publisher_form" *ngIf="youtubeUrl">
      <form [formGroup]="PublisherYoutubeForm">
        <p *ngIf="errorView == true" class="error_filesize mt-md-4">
          {{ errorMesseage }}
        </p>

        <div class="upload mt-md-3">
          <img
            *ngIf="url != null"
            [src]="url"
            width="100"
            height="100"
            alt=""
            class="upload-img"
          />
          <img
            *ngIf="url == null"
            src="../../assets/images/place-holder.jpg"
            width="100"
            height="100"
            alt=""
            class="upload-img"
          />
          <div class="round">
            <input
              type="file"
              accept="image/*"
              formControlName="profile"
              (change)="onFileSelected($event)"
            />
            <img
              src="../../assets/images/camera.svg"
              width="100"
              height="100"
              alt=""
              class="camera-img"
            />
          </div>
        </div>
        <div
          *ngIf="publisherUrl.profile.errors?.required && submittedUser"
          class="invalid-feedback mt-md-2 d-block"
        >
          Publisher image is required
        </div>

        <div class="email-form-section">
          <label for="" class="mt-md-3">Enter your youtube channel url</label>
          <input
            style="width: 450px"
            formControlName="url"
            placeholder="Enter your youtube channel url"
            type="text"
            class="form-control"
          />
          <p class="flag_text">Exaple:youtube.com/@tseries</p>
          <div
            *ngIf="publisherUrl.url.errors?.required && submittedUser"
            class="invalid-feedback m-t d-block"
          >
            Url is required
          </div>
        </div>

        <div class="blog_button next-Btn">
          <button class="cursor" (click)="youtubeUrlValidBtn()">
            Next <img src="../../../assets/publisherassets/arrow-right.png" />
          </button>
        </div>
      </form>
    </div>
    <div class="publisher_form" *ngIf="channelName">
      <form [formGroup]="PublisherchannelForm">
        <div class="">
          <div class="email-form-section mt-md-4">
            <label for="">Enter your Publisher name</label>
            <input
              style="width: 450px"
              placeholder="Enter your channel Name"
              type="text"
              class="form-control"
              formControlName="channelName"
            />
            <p class="flag_text">Exaple:T series</p>
            <div
              *ngIf="
                Publisherchannel.channelName.errors?.required && submittedUser
              "
              class="invalid-feedback m-t d-block"
            >
              Publisher Name is required
            </div>
            <div
              *ngIf="
                Publisherchannel.channelName.errors?.pattern && submittedUser
              "
              class="invalid-feedback m-t d-block"
            >
              Publisher Name must start with "@"
            </div>
          </div>
          <div class="email-form-section mt-md-4">
            <label for="">Enter your channel description</label>
            <input
              style="width: 450px"
              placeholder="Enter Description"
              type="text"
              class="form-control"
              formControlName="description"
            />
          </div>
        </div>
        <div class="blog_button next-Btn">
          <button class="cursor" (click)="channelNameBtn()">
            Next <img src="../../../assets/publisherassets/arrow-right.png" />
          </button>
        </div>
      </form>
    </div>
    <div class="publisher_form" *ngIf="selectContent">
      <h6>who gets value from your content?</h6>
      <div class="d-flex justify-content-left gender_select">
        <button
          *ngFor="let option of options"
          type="button"
          [ngClass]="{ selected: selectedOption === option }"
          (click)="selectOption(option)"
        >
          {{ option }}
        </button>
      </div>
      <div class="blog_button next-Btn">
        <button
          class="cursor"
          [disabled]="gender_select"
          (click)="GenderSelectBtn()"
        >
          Next <img src="../../../assets/publisherassets/arrow-right.png" />
        </button>
      </div>
    </div>
    <div class="publisher_form" *ngIf="topics">
      <div class="onboaring_topics">
        <h5 class="py-4">What type of content do you publish on youtube?</h5>
        <p>Select atleast one topic</p>
        <div class="row catergoy-buttons">
          <div
            class="col-md-4 col-sm-3 col-4"
            *ngFor="let item of categoryList?.slice(1); let i = index"
          >
            <button
              #btn
              (click)="toggleColor(item)"
              [class.follow-category]="item.isButtonSelected"
            >
              {{ item.name }}
            </button>
          </div>
        </div>
      </div>
      <div class="blog_button next-Btn">
        <button
          class="cursor"
          [disabled]="topicsSeleted == ''"
          (click)="topicsSelectBtn()"
        >
          Complete <img src="../../../assets/publisherassets/arrow-right.png" />
        </button>
      </div>
    </div>

    <div class="Publisher_onboarding_success" *ngIf="success">
      <h4 class="mt-md-4">Congratulations!</h4>
      <p>Your blog is set you now</p>
      <p>Here is your AI blog URL</p>

      <a href="{{ URl }}/{{ this.PublisherchannelForm.value.channelName }}">{{ URl }}/{{ this.PublisherchannelForm.value.channelName }}</a>

      <div class="blog_button next-Btn">
        <button class="cursor" (click)="completed()">
          Publish now
          <img src="../../../assets/publisherassets/arrow-right.png" />
        </button>
      </div>
    </div>
  </div>
</div>
