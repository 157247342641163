import { Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CollectionService } from 'src/app/_services/collection.service';

@Component({
  selector: 'app-subscribe-dialog',
  templateUrl: './subscribe-dialog.component.html',
  styleUrls: ['./subscribe-dialog.component.css'],
})
export class SubscribeDialogComponent implements OnInit {
  event: any;
  categoryList: any;
  category: string;
  subscribeSubmitted: boolean = false;
  subscribeEmail: boolean = true;
  subscribeSuccefully: boolean = false;
  subscribeForm: FormGroup;
  email: any;
  error: any;
  constructor(
    private serivce: CollectionService,
    private dilaog: MatDialog,
    private fb: FormBuilder,

    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.subscribeForm = this.fb.group({
      email: [''],
      name: ['', [Validators.required]],
      gender: ['', [Validators.required]],
      categoryId: ['', [Validators.required]],
    });
    console.log(this.data.email);
    this.email = this.data.email;
  }

  ngOnInit(): void {
    this.serivce.caterogoryList().subscribe((res) => {
      console.log(res);
      this.categoryList = res.data;
    });
  }

  get c(): { [key: string]: AbstractControl } {
    return this.subscribeForm.controls;
  }
  subscribe() {
    console.log(this.subscribeForm);
    if (this.subscribeForm.invalid) {
      this.subscribeSubmitted = true;
    } else {
      this.subscribeSubmitted = false;

      let obj = {
        email: this.email,
        name: this.subscribeForm.value.name,
        gender: this.event,
        categoryId: this.category,
      };
      this.serivce.subscribeLinkscart(obj).subscribe(
        (result) => {
          this.subscribeSuccefully = true;
          this.subscribeEmail = false;

          setTimeout(() => {
            this.close();
          }, 2000);
        },
        (error) => {
          this.error = error.error.message;
          console.log(this.error);
        }
      );
    }
  }
  close() {
    this.dilaog.closeAll();
  }
  change(e: any) {
    this.event = e.target.value.split(':')[1];
    console.log(this.event);
  }
  categoryEvent(event: any) {
    if (event.value != '') {
      this.category = event.value;
    } else {
      this.category = '';
    }
    console.log(event.value);
  }
}
