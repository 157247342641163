<div class="main">
  <div class="container">
    <div class="edit-collection mt-md-4 mb-md-3">
      <div *ngIf="collectionUpdate">
        <h4>Update Blog</h4>
        <form [formGroup]="editCollectionForm">
          <div class="row">
            <div class="col-md-4">
              <div class="youtube-thumbanils">
                <img
                  [src]="CImage"
                  width="100"
                  height="100"
                  alt=""
                  class="youtube-thumbnai-img"
                />
              </div>
              <div class=" ">
                <div class=" ">
                  <img
                    *ngIf="url != null"
                    [src]="url"
                    width="100"
                    height="100"
                    alt=""
                    class="img-upload-bg"
                  />
                </div>
              </div>
              <div class="upload-img d-flex mt-md-3">
                <input
                  style="display: none"
                  type="file"
                  accept="image/*"
                  (change)="onFileSelectedEvent($event)"
                  #fileInput
                />
                <a class="cursor" (click)="fileInput.click()"
                  >Upload Thumbnail</a
                >
                <!-- <button class="plus-btn ml-2" >+</button> -->
              </div>
            </div>
            <div class="col-md-8">
              <div class="d-md-flex">
                <div class="input">
                  <app-loader *ngIf="loaderPreview"></app-loader>

                  <p class="pb-md-1 mt-md-4">Enter YouTube video Link</p>
                  <input
                    class="form-control"
                    formControlName="sourceURL"
                    placeholder="Paste the youtube video link"
                  />
                  <div
                    *ngIf="c.sourceURL.errors?.required && submittedDetails"
                    class="invalid-feedback m-t d-block"
                  >
                    Source link is required
                  </div>
                </div>
              </div>
              <div class="details-section mt-3">
                <p>Select the Video count</p>
                <select formControlName="videoViewsCount" class="form-control">
                  <option value="" selected disabled hidden>Select</option>
                  <option *ngFor="let count of VideoCount" [value]="count">
                    {{ count }}
                  </option>
                </select>
                <div
                  *ngIf="c.videoViewsCount.errors?.required && submittedDetails"
                  class="invalid-feedback m-t d-block"
                >
                  Please select
                </div>
              </div>

              <div class="summary">
                <p>Summary</p>

                <button class="btn btn-dark" (click)="preview()">
                  Preview
                </button>
                <quill-editor
                  *ngIf="!!modules"
                  formControlName="Summery"
                  [styles]="{ height: '800px' }"
                  [modules]="modules"
                ></quill-editor>
              </div>
              <div class="addProcductDetails-input">
                <div class="details-section">
                  <p class="my-3">Blog title</p>
                  <div class="title">
                    <div>
                      <input
                        type="text"
                        formControlName="collectionName"
                        placeholder="Enter the Blog"
                      />
                      <div
                        *ngIf="
                          c.collectionName.errors?.required && submittedDetails
                        "
                        class="invalid-feedback m-t d-block"
                      >
                        SummaryName is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="loader" *ngIf="loadinSubTopics">
                  <div class="bar1"></div>
                  <div class="bar2"></div>
                  <div class="bar3"></div>
                  <div class="bar4"></div>
                  <div class="bar5"></div>
                  <div class="bar6"></div>
                  <div class="bar7"></div>
                  <div class="bar8"></div>
                  <div class="bar9"></div>
                  <div class="bar10"></div>
                  <div class="bar11"></div>
                  <div class="bar12"></div>
                </div>
                <div class="d-flex collection-select">
                  <div class="details-section mt-3">
                    <p>Publisher Name</p>
                    <div class="title">
                      <div>
                        <select
                          formControlName="channelName"
                          (change)="channelEvent($event)"
                          class="form-control"
                        >
                          <option value="" selected disabled hidden>
                            Select Publisher name
                          </option>
                          <option
                            *ngFor="let list of ChannelName"
                            [value]="list.name"
                          >
                            {{ list.name }}
                          </option>
                        </select>
                        <div
                          *ngIf="
                            c.channelName.errors?.required && submittedDetails
                          "
                          class="invalid-feedback m-t d-block"
                        >
                          PublisherName is required
                        </div>
                        <!-- <input type="text" formControlName="" placeholder="Enter the ChannelName"> -->
                      </div>
                      <!-- <div ><h6 class="ml-5">Add description ( optional)</h6></div> -->
                    </div>
                  </div>
                  <div class="details-section mt-2 ml-md-1">
                    <div class="catergories-add-section mt-md-2">
                      <p>Topics Name</p>
                      <mat-form-field appearance="fill">
                        <mat-select
                          formControlName="categoryId"
                          multiple
                          placeholder="Select Topics"
                          disableOptionCentering
                          panelClass="eligablePanelClass"
                          (selectionChange)="categoryEvent($event)"
                          #closeattendance
                        >
                          <!-- <mat-option class='services-view'  [value]=" ">Select Channel name</mat-option> -->
                          <mat-option
                            class="services-view"
                            *ngFor="let list of categoryList"
                            [value]="list._id"
                            >{{ list.name }}</mat-option
                          >
                        </mat-select>
                      </mat-form-field>
                      <div class="add_topics mt-2">
                        <a class="cursor p-2" (click)="addTopics()"
                          >Add topics</a
                        >
                      </div>
                      <div
                        *ngIf="errorMessage != ''"
                        class="invalid-feedback m-t d-block"
                      >
                        {{ errorMessage }}
                      </div>
                      <div
                        *ngIf="
                          c.categoryId.errors?.required && submittedDetails
                        "
                        class="invalid-feedback m-t d-block"
                      >
                        Please select the Topics
                      </div>
                    </div>
                  </div>

                  <div class="details-section mt-2 ml-md-1">
                    <div class="catergories-add-section mt-md-2">
                      <p>Subtopics Name</p>
                      <mat-form-field appearance="fill">
                        <mat-select
                          formControlName="subCategoryId"
                          multiple
                          placeholder="Select subtopics"
                          disableOptionCentering
                          panelClass="eligablePanelClass"
                          #closeattendance
                        >
                          <!-- <mat-option class='services-view'  [value]=" ">Select Channel name</mat-option> -->
                          <mat-option
                            class="services-view"
                            *ngFor="let list of subCaterogoryList"
                            [value]="list"
                            >{{ list }}</mat-option
                          >
                        </mat-select>
                      </mat-form-field>
                      <div class="add_topics mt-2">
                        <a class="cursor p-2" (click)="addsubtopics()"
                          >Add subtopics</a
                        >
                      </div>
                      <!-- <div *ngIf="c.subCategoryId.errors?.required && submittedDetails" class="invalid-feedback m-t d-block"> Please select the subtopics </div> -->
                    </div>
                  </div>
                </div>

                <div class="details-section mt-3 d-flex">
                  <div class="title">
                    <app-loader
                      [keywordsLoader]="keywordsLoaderPostionChange"
                      *ngIf="keywordsLoader"
                    ></app-loader>
                    <p>Keywords</p>
                    <input
                      type="text"
                      (keypress)="keyWords($event)"
                      formControlName="Keywords"
                      placeholder="Enter keywords"
                      class="brand-input"
                    />
                  </div>
                  <div class="get-keys">
                    <button class="btn" (click)="getkeyWords()">
                      Get keywords
                    </button>
                  </div>
                </div>
              </div>
              <div class="update-collection mt-md-4 mb-5">
                <button class="btn pointer" (click)="updateCollection()">
                  Update blog
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div *ngIf="updatedsuccefully">
        <div class="login-user-img mt-5">
          <img src="../../assets/images/check.png" class="success-img" />
          <div class="login-details-msg d-flex justify-content-center mt-md-4">
            <h5>Blog updated successfully</h5>
            <P class="ml-2 pointer">
              <a
                routerLink="/c/{{
                  this.CollectionTitle.split(' ')?.join('-')
                }}/id-{{ this.id }}"
                >View</a
              >
            </P>
          </div>
          <h4 class="mt-md-4">
            Share this product link with your friends and help them discover
          </h4>
          <div class="shareItBtnSection d-flex justify-content-center mb-3">
            <button type="button" class="link pointer">
              {{ this.categoryLink | slice : 0 : 50 }}
            </button>
            <button type="button" class="pointer" (click)="share()">
              Share it
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
