import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  PLATFORM_ID,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CollectionService } from 'src/app/_services/collection.service';
import html2canvas from 'html2canvas';
import { NoAuthSignupComponent } from 'src/app/no-auth-signup/no-auth-signup.component';
import { MatDialog } from '@angular/material/dialog';

import { Title, Meta, DomSanitizer, SafeHtml } from '@angular/platform-browser';
import * as htmlToImage from 'html-to-image';
import { ShareproductLinkDialogComponent } from 'src/app/shareproduct-link-dialog/shareproduct-link-dialog.component';
import { environment } from 'src/environments/environment';
import {
  NgIf,
  isPlatformBrowser,
  isPlatformServer,
  DatePipe,
} from '@angular/common';
import { DOCUMENT } from '@angular/common';
import { SharelinkComponent } from 'src/app/sharelink/sharelink.component';
import { MixPanelService } from 'src/app/_services/mix-panel.service';
import { NoAuthSignupFollowComponent } from 'src/app/no-auth-signup-follow/no-auth-signup-follow.component';
import { SubscribeDialogComponent } from '../subscribe-dialog/subscribe-dialog.component';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import Swal from 'sweetalert2';
import { takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { UseronboardingflowDialogComponent } from 'src/app/useronboardingflow-dialog/useronboardingflow-dialog.component';
import { DynamicShareButtonDialogComponent } from 'src/app/dynamic-share-button-dialog/dynamic-share-button-dialog.component';
import { CommentSectionDialogComponent } from 'src/app/homepage/comment-section-dialog/comment-section-dialog.component';
import { ReportDialogComponent } from 'src/app/homepage/report-dialog/report-dialog.component';
import { SuccessdialoghomeComponent } from 'src/app/homepage/successdialoghome/successdialoghome.component';
declare const swiper: any;
declare const moblieswiper: any;
declare const convertToImage: any;
declare const exportImage: any;
declare const clevertap: any;

@Component({
  selector: 'app-viewcollection',
  templateUrl: './viewcollection.component.html',
  styleUrls: ['./viewcollection.component.css'],
})
export class ViewcollectionComponent implements OnInit {
  hideComponent: boolean = false;
  hideNotifications: boolean = false;
  hideadmin: boolean = true;
  hideCollection: boolean = true;

  product_id: any;
  userDetailsParams: any;
  id: any;
  collectionDetails: any;
  Allcollection: any;
  userName: any;
  summary: any;
  collectionRealtedData: any;
  image: boolean = true;
  collectionCreatedByCreator: any;
  @ViewChild('screen') screen: ElementRef;
  @ViewChild('screen') screen1: ElementRef;
  @ViewChild('canvas') canvas: ElementRef;
  @ViewChild('canvas') canvas1: ElementRef;
  @ViewChild('downloadLink') downloadLink: ElementRef;
  @ViewChild('downloadLink1') downloadLink1: ElementRef;
  productTitle: any;
  productId: any;
  node: any;
  token: string | null;
  userType: string | null;
  title: any = 'Collections';
  CollectionError: any;
  bookSaved: boolean = false;
  Bookmark: boolean = true;
  shareLink: any;
  private _id: any;
  details: any = [];
  collectionImage: any;
  CImage: string;
  collectionName: any;
  metaDescription: any;
  metaImage: any;
  session: any;
  sessionType: any;
  prooductCount: any;
  params: {};
  prodcutImage: any;
  showIframeView: boolean = false;
  showThumbnailView: boolean = true;
  saved: boolean = false;
  productError: any;
  follow: any = true;
  following: any = false;
  channelFollowList: any;
  channeldata: any;
  channelProfile: any;
  channelname: any;
  channeluserId: any;
  keyWordsArray: any;
  thumbnailImage: any;
  like: boolean = false;
  button: any;
  likeParms: { collectionId: any };
  collecitonLiked: any;
  liketotalCount: any;
  channelNames: any = [];
  channelNamesOnpage: any = [];
  userTypeId: any = '';
  subscribeEmail: any;
  subscribeSubmitted: boolean = false;
  emailSubcribe: boolean = false;
  loaderPreview: boolean = false;
  subscribeForm: FormGroup;
  userId: any;
  error: any;
  editorView: boolean;
  exsitingCollection: boolean;
  summaryView: any;
  productList: any;
  pId: any;
  BPId: any;
  bookmarkList: any;
  htmlFormat: any;
  redirectionUrl: any;
  isBrowser: any;
  isServer: any;
  private destroy$ = new Subject<void>();
  userDialog: any;
  token1: any;
  routeIdentifier: any;
  pageId: string = '/post/123';
  routeIdentifierComment: string;
  attachement: string;
  linkTarget: string;
  attachementFile: any;
  keyWordsView: any;
  collectionIdParms: any;
  commentForm!: FormGroup;
  editCommentForm!: FormGroup;
  replyCommentForm!: FormGroup;
  replyEditCommentForm!: FormGroup;
  showButtons = false;
  inputActive: boolean = false;
  commentBtnEvent: boolean = true;
  commentBtnEventReply: boolean = true;
  readonly: boolean = true;
  replyCommentShow: boolean = false;
  showButtonsReply: boolean = false;
  inputValue: any = '';
  commentUser: any;
  commentsValue: any;
  errorLength: boolean = false;
  comment: any;
  ReplycommentUser: any = [];
  Replycomment: any;
  replyShow: boolean = false;
  commentId: any;
  showReplys: boolean = false;
  totalComments: any;
  showEditForm: boolean = false;
  showEditButtons: boolean = false;
  editActive: boolean = false;
  showReplyEditButtons: boolean = false;
  collectionUserId: any;
  CollectionID: any;
  shareDataLink: string;
  summaryId: any;
  showComments: boolean = false;
  summaryTranslate: any;
  recommendedData: any;
  recommendedPublishers: any;
  recommendedTopics: any;
  recommendedParams: {};
  sanitizedHtmlContent: SafeHtml;
  summaryEditorView: any;
  summartList: any;
  followPublisher: boolean = false;
  userTopicFollowedList: any;
  topicsFollowListArray: any = [];
  topicsActive: any;
  readerId: any;
  realatedLoader: boolean;
  refreshSubscription: Subscription;
  url: any = `${environment.URL}`;
  constructor(
    private router: Router,
    public service: CollectionService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private TitleService: Title,
    private metaTagService: Meta,
    private sanitizer: DomSanitizer,

    private elementRef: ElementRef,
    private renderer: Renderer2,
    @Inject(PLATFORM_ID) private platformId: Object,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document,
    private mixpanelService: MixPanelService
  ) {
    this.commentForm = this.fb.group({
      title: ['', [Validators.required, Validators.maxLength(1000)]],
    });
    this.replyCommentForm = this.fb.group({
      reply: ['', Validators.required],
    });
    this.editCommentForm = this.fb.group({
      edit: ['', Validators.required],
    });
    this.replyEditCommentForm = this.fb.group({
      replyEdit: ['', Validators.required],
    });
    this.isBrowser = isPlatformBrowser(platformId);
    this.isServer = isPlatformServer(platformId);
    // if (this.isBrowser) {
    this.subscribeForm = this.fb.group({
      email: ['', [Validators.required]],
    });

    this.product_id = this.activatedRoute.snapshot.params.id;
    this.id = this.product_id.split('-').pop().replace('', '');
    this._id = this.product_id?.split('-').pop();
    this.CollectionID = this._id;

    console.log(this.CollectionID);
    this.userDetailsParams = this.activatedRoute.snapshot.params.title;

    this.shareLink = `${environment.SHARE_URL}/c/${this.userDetailsParams}/id-${this._id}`;
    this.routeIdentifier = `/c/${this.userDetailsParams}/id-${this._id}`;
    this.routeIdentifierComment = `/c/${this.userDetailsParams}/${this._id}`;
    // console.log('route', this.routeIdentifierComment);
    this.redirectionUrl = `/c/${this.userDetailsParams}/${this.product_id}`;
    this.loaderPreview = true;

    // if (this.service.getToken() != '') {
    //   this.service.loginUserDetails().subscribe((result) => {
    //     this.readerId = {
    //       readerId: result.data._id,
    //     };
    //     this.service
    //       .CollectionListDetails(this.id, this.readerId)
    //       .pipe(takeUntil(this.destroy$))
    //       .subscribe((res) => {
    //         //collection details
    //         this.collectionDetails = res.data;
    //         this.liketotalCount = res.totalSummaryLike;
    //         this.loaderPreview = false;
    //         this.collectionUserId = res.data[0]?.reader[0]?._id;
    //         this.topicsActive = res.data[0]?.topics[0].name;
    //         this.channeldata = res.publisherData;
    //         this.channelProfile = res?.publisherData?.profile;
    //         this.channelname = res?.publisherData?.name;
    //         this.channeluserId = res?.publisherData?._id;
    //         // this.session = res.session.isLogedIn;
    //         // this.sessionType = res.session.userType;

    //         this.keyWordsArray = res.data[0].Keywords.toString().split('\n');
    //         const termsWithSpaces = this.keyWordsArray.map((term: any) =>
    //           term.replace(/-/g, '')
    //         );

    //         this.keyWordsView = termsWithSpaces;

    //         // console.log(termsWithSpaces);
    //         // console.log(this.keyWordsArray);

    //         this.thumbnailImage = res.data[0].thumbNail;
    //         this.attachementFile = res.data[0].attachement;

    //         // console.log('att', this.attachementFile);

    //         this.prooductCount = res?.data[0]?.product.length;
    //         // console.log('length', this.prooductCount);
    //         this.collectionName = res.data[0].summaryName;
    //         this.htmlFormat = res.data[0].Summary?.replace(/<[^>]+>/g, '');
    //         // console.log('html format', this.htmlFormat);
    //         this.metaDescription = this.htmlFormat;

    //         if (res.data[0].thumbNail == null) {
    //           this.metaImage = res.data[0].customThumbNail;
    //         } else {
    //           this.metaImage = res.data[0].thumbNail;
    //         }
    //         // console.log('title---------> ', this.metaDescription);
    //         this.metaTagService.updateTag({
    //           name: 'og:title',
    //           property: 'content',
    //           content: this.collectionName,
    //         });
    //         this.metaTagService.updateTag({
    //           name: 'og:keywords',
    //           property: 'content',
    //           content: this.keyWordsView,
    //         });

    //         this.TitleService.setTitle(this.collectionName);
    //         this.metaTagService.updateTag({
    //           name: 'og:description',
    //           property: 'content',
    //           content: this.metaDescription,
    //         });
    //         this.metaTagService.updateTag({
    //           name: 'og:image',
    //           property: 'content',
    //           content: this.metaImage,
    //         });
    //         this.metaTagService.updateTag({
    //           name: 'og:image:width',
    //           property: 'content',
    //           content: '250',
    //         });
    //         this.metaTagService.updateTag({
    //           name: 'og:image:height',
    //           property: 'content',
    //           content: '250',
    //         });
    //         this.metaTagService.updateTag({
    //           name: 'og:url',
    //           property: 'content',
    //           content: this.shareLink,
    //         });

    //         this.collectionImage = res.data[0].sourceURL.slice(
    //           res.data[0].sourceURL.indexOf('=') + 1
    //         );

    //         this.CImage = `https://img.youtube.com/vi/${this.collectionImage}/hqdefault.jpg`;
    //         // console.log(this.collectionImage);
    //         // console.log(this.CImage);

    //         //collection realted data
    //         this.collectionRealtedData = res?.summary;

    //         //creators product details
    //         this.collectionCreatedByCreator = res?.data[0]?.product;
    //         //summary
    //         this.summaryView = res.data[0]?.Summary;
    //         this.summaryEditorView = res.data[0]?.Summary;

    //         if (
    //           this.summaryView.includes('<ol') &&
    //           this.summaryView.includes('<li')
    //         ) {
    //           console.log('editoor view');
    //           this.summary = res.data[0]?.Summary;
    //           this.editorView = true;
    //           this.exsitingCollection = false;
    //         } else if (
    //           this.summaryView.includes('<ul') &&
    //           this.summaryView.includes('<li')
    //         ) {
    //           this.summary = res.data[0]?.Summary;
    //           this.editorView = true;
    //           this.exsitingCollection = false;
    //         } else {
    //           this.summary = res.data[0]?.Summary?.split(/\d+/);
    //           this.exsitingCollection = true;

    //           this.editorView = false;

    //           console.log('exisiting collection');
    //         }
    //       });
    //   });
    // } else {
    this.service
      .CollectionListDetails(this.id, this.collectionIdParms)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          //collection details
          this.collectionDetails = res.data;
          this.liketotalCount = res.totalSummaryLike;
          this.loaderPreview = false;
          this.collectionUserId = res.data[0]?.reader[0]?._id;
          this.topicsActive = res.data[0]?.topics[0].name;
          this.channeldata = res.channeldata;
          this.channelProfile = res?.channeldata?.profile;
          this.channelname = res?.channeldata?.name;
          this.channeluserId = res?.channeldata?._id;
          // this.session = res.session.isLogedIn;
          // this.sessionType = res.session.userType;

          this.keyWordsArray = res.data[0].Keywords.toString().split('\n');
          const termsWithSpaces = this.keyWordsArray.map((term: any) =>
            term.replace(/-/g, '')
          );

          this.keyWordsView = termsWithSpaces;

          // console.log(termsWithSpaces);
          // console.log(this.keyWordsArray);

          this.thumbnailImage = res.data[0].thumbNail;
          this.attachementFile = res.data[0].attachement;

          // console.log('att', this.attachementFile);

          this.prooductCount = res?.data[0]?.product.length;
          // console.log('length', this.prooductCount);
          this.collectionName = res.data[0].summaryName;
          this.htmlFormat = res.data[0].Summary?.replace(/<[^>]+>/g, '');
          // console.log('html format', this.htmlFormat);
          this.metaDescription = this.htmlFormat;

          if (res.data[0].thumbNail == null) {
            this.metaImage = res.data[0].customThumbNail;
          } else {
            this.metaImage = res.data[0].thumbNail;
          }
          // console.log('title---------> ', this.metaDescription);
          this.metaTagService.updateTag({
            name: 'og:title',
            property: 'content',
            content: this.collectionName,
          });
          this.metaTagService.updateTag({
            name: 'og:keywords',
            property: 'content',
            content: this.keyWordsView,
          });

          this.TitleService.setTitle(this.collectionName);
          this.metaTagService.updateTag({
            name: 'og:description',
            property: 'content',
            content: this.metaDescription,
          });
          this.metaTagService.updateTag({
            name: 'og:image',
            property: 'content',
            content: this.metaImage,
          });
          this.metaTagService.updateTag({
            name: 'og:image:width',
            property: 'content',
            content: '250',
          });
          this.metaTagService.updateTag({
            name: 'og:image:height',
            property: 'content',
            content: '250',
          });
          this.metaTagService.updateTag({
            name: 'og:url',
            property: 'content',
            content: this.shareLink,
          });

          this.collectionImage = res.data[0].sourceURL.slice(
            res.data[0].sourceURL.indexOf('=') + 1
          );

          this.CImage = `https://img.youtube.com/vi/${this.collectionImage}/hqdefault.jpg`;
          // console.log(this.collectionImage);
          // console.log(this.CImage);

          //collection realted data
          this.collectionRealtedData = res?.collections;

          //creators product details
          this.collectionCreatedByCreator = res?.data[0]?.product;
          //summary
          this.summaryView = res.data[0]?.Summary;
          this.summaryEditorView = res.data[0]?.Summary;

          if (
            this.summaryView.includes('<ol') &&
            this.summaryView.includes('<li')
          ) {
            console.log('editoor view');
            this.summary = res.data[0]?.Summary;
            this.editorView = true;
            this.exsitingCollection = false;
          } else if (
            this.summaryView.includes('<ul') &&
            this.summaryView.includes('<li')
          ) {
            this.summary = res.data[0]?.Summary;
            this.editorView = true;
            this.exsitingCollection = false;
          } else {
            this.summary = res.data[0]?.Summary?.split(/\d+/);
            this.exsitingCollection = true;

            this.editorView = false;

            console.log('exisiting collection');
          }
        },
        (error) => {
          this.router.navigate(['/404-page-not-found']);
        }
      );
    // }

    // }
    this.refreshSubscription = this.service.getRefreshSignal().subscribe(() => {
      this.maincomponent();
      // this.test = this.collectionService.refreshComponent();
      // console.log('test!', this.test);
      // if (this.test == null) {
      //   this.ngOnInit();
      //   localStorage.setItem('refresh', 'false');
      // }
    });
  }

  ngOnInit(): void {}

  maincomponent() {
    this.commentsGet();
    this.token1 = this.activatedRoute.snapshot.queryParams.token;
    if (this.token1) {
      localStorage.setItem('token', this.token1);
    }
    if (this.service.getToken() == '') {
      console.log('not-login');
      this.sessionType = 'home';
      this.recommendedParams = {};
      this.service
        .recommendedData(this.recommendedParams)
        .subscribe((recommendedData) => {
          this.recommendedData = recommendedData.data[0];
          this.recommendedPublishers =
            recommendedData.data[0].recommendedPublishers;
          this.recommendedTopics = recommendedData.data[0].recommendedTopics;
          console.log('recommendedPublishers: ', this.recommendedPublishers);
          console.log('recommendedTopics: ', this.recommendedTopics);
        });
    }
    if (this.service.getToken() != '') {
      this.service.loginUserDetails().subscribe((result) => {
        this.sessionType = result.data.userType;
        this.userTypeId = result.data._id;
        this.userDialog = result.data.onbording;
        if (this.userDialog == false && this.sessionType == 'user') {
          this.dialog.open(UseronboardingflowDialogComponent, {
            disableClose: true,
            data: {},
          });
        }
        this.recommendedParams = {
          readerId: this.userTypeId,
        };
        this.service
          .recommendedData(this.recommendedParams)
          .subscribe((recommendedData) => {
            this.recommendedData = recommendedData.data[0];
            this.recommendedPublishers =
              recommendedData.data[0].recommendedPublishers;
            this.recommendedTopics = recommendedData.data[0].recommendedTopics;
          });
        // console.log('collection page', this.sessionType);
      });
      this.service.summaryReadingTime(this.id).subscribe((summary) => {
        console.log('summary', summary);
      });
      this.service.userProductGet().subscribe((result) => {
        console.log(result);
        this.bookmarkList = result.data;
      });

      console.log('login sucess fully');
      this.service.userCountCollection().subscribe((res) => {
        this.collecitonLiked = res.data;
      });
      // this.service.CollectionListDetails(this.id).subscribe((res) => {
      //   //collection details
      //   this.channeldata = res.channeldata;
      //   this.channelProfile = res?.channeldata?.profile;
      //   this.channelname = res?.channeldata?.name;
      //   this.channeluserId = res?.channeldata?.userId;
      //   this.userId = res.data[0]?.userId;
      // });
      setTimeout(() => {
        this.service.caterogyFollowList().subscribe((result) => {
          this.userTopicFollowedList = result.data;

          this.userTopicFollowedList.forEach((item: any) => {
            this.topicsFollowListArray.push(item.name);
          });
          let topicsList = this.topicsFollowListArray.includes(
            this.channelname
          );
        });
        this.service.ChannelFollowList().subscribe((res: any) => {
          // console.log('followers', res);
          this.channelFollowList = res.data;
          this.channelFollowList.forEach((item: any) => {
            this.channelNames.push(item.publisherName);
            console.log('channelnames', this.channelNames);
          });
          let channel = this.channelNames.includes(this.channelname);
          // console.log('channel', channel);
          if (channel) {
            console.log('follow match');
            // this.following = true;
            // this.follow = false;
            this.followPublisher = true;
          } else {
            console.log(' follow not match');
            this.follow = true;
            this.following = false;
            this.followPublisher = false;
          }
        });
      }, 2000);
    }
  }
  writeComment(collections: any) {
    this.dialog.open(CommentSectionDialogComponent, {
      data: {
        link: `${environment.SHARE_URL}/c/${collections.summaryName
          .split(' ')
          .join('-')}/id-${collections._id}`,
        video: collections.video,
      },
    });
  }
  googleTranslateService(item: any) {
    let obj = {
      outputLanguage: 'te',
    };
    this.service.googleTranslate(item._id, obj).subscribe((res: any) => {
      this.summary = res.Summary;
      item.collectionName = res.collectionName;
      console.log(res.Summary);
    });
  }
  catergoryNavigate(item: any) {
    console.log('clickded');
    this.router.navigate([`topic/${item}`]);

    console.log('prod');

    if (environment.CLEVERTAP) {
      clevertap.event.push('TopicsProflieClicks', {
        TopicsProflieClicks: item,
      });
    }
  }
  unFollowCategories(categories: any, boolena: any, collections: any) {
    console.log(categories);
    if (this.service.getToken() == '') {
      this.dialog.open(NoAuthSignupFollowComponent, {
        data: {
          title: 'Follow this channel',
          ShareLink: `/c/${collections.summaryName.split(' ').join('-')}/id-${
            collections._id
          }`,
          disableClose: true,
        },
      });
    } else {
      this.service.caterogyUnFollow(categories._id).subscribe((response) => {
        categories.topicsFollow = false;
      });
    }
  }
  followPublisherBtn(item: any) {
    console.log(item);
    if (this.service.getToken() == '') {
      this.dialog.open(NoAuthSignupComponent, {
        data: {
          title: 'Follow this channel',
          disableClose: true,
        },
      });
    } else {
      this.params = {
        publisherName: item.name,
        publisherId: item._id,
      };
      this.service.followCreatorChannel(this.params).subscribe((res) => {
        console.log(res);
        item.followpublisher = true;
      });
    }
  }
  tweetEvent(item: any) {
    console.log('event clicks');

    if (environment.CLEVERTAP) {
      clevertap.event.push('Tweet', {
        Tweetthis: 'tweet',
        TweetthisSummaryName: item.summaryName,
        TweetthisSummaryId: item._id,
      });
    }
  }
  share(collections: any) {
    this.collectionsClick(collections._id);
    //clevertap intergation event

    if (environment.CLEVERTAP) {
      clevertap.event.push('summaryShare', {
        LoginshareSummaryName: collections.summaryName,
        LoginshareId: collections._id,
      });
    }

    //clevertap intergation event
    this.dialog.open(DynamicShareButtonDialogComponent, {
      data: {
        title: `${environment.SHARE_URL}/c/${collections.summaryName
          .split(' ')
          .join('-')}/id-${collections._id}`,
        collectionName: collections.summaryName.split(' ').join('-'),
        disableClose: true,
      },
    });
  }
  tweetBtn(item: any) {
    // this.collectionsClick(item._id);
    // console.log(item);
    this.shareDataLink = `${environment.SHARE_URL}/c/${item.summaryName
      .split(' ')
      .join('-')}/id-${item._id}`;
    return `https://www.shareaholic.com/api/share/?v=1&apitype=1&apikey=2ceb7e6973521a10a3e4b25ad56072e7
  &service=twitter&title=${item.summaryName}&link=${
      environment.SHARE_URL
    }/c/${item.summaryName.split(' ').join('-')}/id-${
      item._id
    }/&source=Shareaholic`;
  }
  followCollectionCatergory(
    item: any,
    catergoriesFollowed: any,
    collections: any
  ) {
    console.log(item);
    if (this.service.getToken() == '') {
      this.dialog.open(NoAuthSignupComponent, {
        data: {
          title: 'Join this community',
          ShareLink: `/c/${collections.summaryName.split(' ').join('-')}/id-${
            collections._id
          }`,
          disableClose: true,
        },
      });
    } else {
      if (item) {
        let obj = {
          topicId: item._id,
        };
        // console.log(obj);
        this.service.caterogyFollow(obj).subscribe((res) => {
          // console.log(obj);
          clevertap.event.push('TopicsProflieFollows', {
            TopicsProflieName: item.name,
            readerName: this.userName,
          });
          item.topicsFollow = true;
        });
      } else {
      }
    }
  }
  // comment ts file
  onSubmitComment(): void {
    console.log(this.commentForm.value);
    if (this.service.getToken() != '') {
      if (this.commentForm) {
        this.commentBtnEvent = false;
        let obj = {
          summaryId: this._id,
          comment: this.commentForm.value.title,
        };
        this.service.commentPost(obj).subscribe((response) => {
          console.log(response);
          // this.commentUser = response.data;
          this.commentsGet();
          this.cancelComment();
          // collection.collectionCommntCount;
        });
      }
    }
  }

  commentsGet() {
    this.service.commentGet(this._id).subscribe((response) => {
      this.commentUser = response.data;
      this.totalComments = response.totalComment;
      this.commentUser.forEach((comment: any) => {
        return (comment.showReplyForm = false), (comment.showReplys = false);
      });
      console.log(this.commentUser);
    });
  }
  get commentFormError(): { [key: string]: AbstractControl } {
    return this.commentForm.controls;
  }
  get replyCommentFormError(): { [key: string]: AbstractControl } {
    return this.replyCommentForm.controls;
  }
  // validations for AlphabetsOnly
  nameValidations(event: any, item: any) {
    if (item == 'title') {
      let newValue = event.target.value.replace(/[^a-zA-Z ]/g, '');
      console.log('alphabets', newValue);
      this.commentForm.controls['title'].setValue(newValue);
    } else if (item == 'edit') {
      let newValue = event.target.value.replace(/[^a-zA-Z ]/g, '');
      console.log('alphabets', newValue);
      this.editCommentForm.controls['edit'].setValue(newValue);
    } else if (item == 'reply') {
      let newValue = event.target.value.replace(/[^a-zA-Z ]/g, '');
      console.log('alphabets', newValue);
      this.replyCommentForm.controls['reply'].setValue(newValue);
    } else if (item == 'replyEdit') {
      let newValue = event.target.value.replace(/[^a-zA-Z ]/g, '');
      console.log('alphabets', newValue);
      this.replyEditCommentForm.controls['replyEdit'].setValue(newValue);
    }
  }
  showReplyBtn(item: any) {
    item.showReplys = !item.showReplys;
  }
  cancelComment() {
    this.inputActive = false;
    this.showButtons = false;
    this.commentForm.reset();
    this.editActive = false;
    this.showEditButtons = false;
  }
  spamComment(comment: any) {
    console.log(comment._id);
    if (this.service.getToken() == '') {
      this.readonly = true;
      this.dialog.open(NoAuthSignupComponent, {
        data: {
          title: 'to mark as spam',
          image: this.thumbnailImage,
          ShareLink: `/c/${this.userDetailsParams.split(' ').join('-')}/id-${
            this.product_id
          }`,
          disableClose: true,
        },
      });
    } else {
      let obj = {
        commentReplyId: '',
      };
      this.service.commentSpam(obj, comment._id).subscribe(
        (res) => {
          console.log(res);
          Swal.fire({
            title: 'successfully marked as spam',
            icon: 'success', // You can change the icon type
            timer: 2000, // Set the timer to 3000 milliseconds (3 seconds)
            showConfirmButton: false, // Hide the "OK" button
          });
        },
        (error) => {
          console.log(error);
          Swal.fire({
            title: error.error.message,
            icon: 'error', // You can change the icon type
            timer: 2000, // Set the timer to 3000 milliseconds (3 seconds)
            showConfirmButton: false, // Hide the "OK" button
          });
        }
      );
    }
  }
  spamReplyComment(comment: any, replyId: any) {
    console.log('commentId', comment._id);
    console.log('repltID', replyId);
    if (this.service.getToken() == '') {
      this.readonly = true;
      this.dialog.open(NoAuthSignupComponent, {
        data: {
          title: 'to mark as spam',
          image: this.thumbnailImage,
          ShareLink: `/c/${this.userDetailsParams.split(' ').join('-')}/id-${
            this.product_id
          }`,
          disableClose: true,
        },
      });
    } else {
      let obj = {
        commentReplyId: replyId,
      };
      this.service.commentSpam(obj, comment._id).subscribe(
        (res) => {
          console.log(res);
          Swal.fire({
            title: 'successfully marked as spam',
            icon: 'success', // You can change the icon type
            timer: 2000, // Set the timer to 3000 milliseconds (3 seconds)
            showConfirmButton: false, // Hide the "OK" button
          });
        },
        (error) => {
          Swal.fire({
            title: error.error.message,
            icon: 'error', // You can change the icon type
            timer: 2000, // Set the timer to 3000 milliseconds (3 seconds)
            showConfirmButton: false, // Hide the "OK" button
          });
        }
      );
    }
  }

  closePubliser(i: any) {
    // if (i > 1) {
    // }
    this.recommendedPublishers.splice(i, 1);
  }
  spamReplyOffensive(comment: any, replyId: any) {
    console.log('commentId', comment._id);
    console.log('repltID', replyId);

    if (this.service.getToken() == '') {
      this.readonly = true;
      this.dialog.open(NoAuthSignupComponent, {
        data: {
          title: 'to mark as offensive',
          image: this.thumbnailImage,
          ShareLink: `/c/${this.userDetailsParams.split(' ').join('-')}/id-${
            this.product_id
          }`,
          disableClose: true,
        },
      });
    } else {
      let obj = {
        commentReplyId: replyId,
      };
      this.service.commentOffensive(obj, comment._id).subscribe(
        (res) => {
          console.log(res);
          Swal.fire({
            title: 'successfully marked as spam',
            icon: 'success', // You can change the icon type
            timer: 2000, // Set the timer to 3000 milliseconds (3 seconds)
            showConfirmButton: false, // Hide the "OK" button
          });
        },
        (error) => {
          Swal.fire({
            title: error.error.message,
            icon: 'error', // You can change the icon type
            timer: 2000, // Set the timer to 3000 milliseconds (3 seconds)
            showConfirmButton: false, // Hide the "OK" button
          });
        }
      );
    }
  }
  spamOffensive(comment: any) {
    if (this.service.getToken() == '') {
      this.readonly = true;
      this.dialog.open(NoAuthSignupComponent, {
        data: {
          title: 'to mark as offensive',
          image: this.thumbnailImage,
          ShareLink: `/c/${this.userDetailsParams.split(' ').join('-')}/id-${
            this.product_id
          }`,
          disableClose: true,
        },
      });
    } else {
      let obj = {
        commentReplyId: '',
      };
      this.service.commentOffensive(obj, comment._id).subscribe(
        (res) => {
          console.log(res);
          Swal.fire({
            title: 'successfully marked as offensive',
            icon: 'success', // You can change the icon type
            timer: 2000, // Set the timer to 3000 milliseconds (3 seconds)
            showConfirmButton: false, // Hide the "OK" button
          });
        },
        (error) => {
          console.log(error);
          Swal.fire({
            title: error.error.message,
            icon: 'error', // You can change the icon type
            timer: 2000, // Set the timer to 3000 milliseconds (3 seconds)
            showConfirmButton: false, // Hide the "OK" button
          });
        }
      );
    }
  }
  deleteCommentPublisherandPowerUser(comments: any) {
    console.log('commentID', comments._id);
    console.log('collectionID', this.CollectionID);
    let obj = {
      summaryId: this.CollectionID,
      commentId: comments._id,
    };
    this.service.deleteCommentPublisher(obj).subscribe((res) => {
      this.commentsGet();
      Swal.fire({
        title: 'Comment delete successfully',
        icon: 'success', // You can change the icon type
        timer: 2000, // Set the timer to 3000 milliseconds (3 seconds)
        showConfirmButton: false, // Hide the "OK" button
      });
    });
  }
  deleteReplyCommentPublisherandPowerUser(comments: any, replyId: any) {
    console.log('commentID', comments._id);
    console.log('collectionID', this.CollectionID);
    let obj = {
      summaryId: this.CollectionID,
      commentId: comments._id,
      commentReplyId: replyId,
    };
    this.service.deleteCommentPublisher(obj).subscribe((res) => {
      this.commentsGet();
      Swal.fire({
        title: 'Comment delete successfully',
        icon: 'success', // You can change the icon type
        timer: 2000, // Set the timer to 3000 milliseconds (3 seconds)
        showConfirmButton: false, // Hide the "OK" button
      });
    });
  }
  // edit and delete comment buttons and api calls
  editMainComment(editData: any) {
    editData.isEdit = !editData.isEdit;
    console.log(editData);
    this.editCommentForm.controls['edit'].setValue(editData.comment);

    this.toggleEditButtons();
  }
  editReplyComment(editData: any) {
    editData.isEdit = !editData.isEdit;
    console.log(editData);
    this.replyEditCommentForm.controls['replyEdit'].setValue(editData.comment);

    this.toggleReplyEditButtons();
  }
  cancelEditComment(item: any) {
    item.isEdit = false;
    this.editActive = false;
    this.showEditButtons = false;
  }
  saveEditForm(editData: any) {
    console.log(this.editCommentForm.value.edit);
    let obj = {
      comment: this.editCommentForm.value.edit,
      commentReply: false,
    };
    this.service.commentUpdateReply(obj, editData._id).subscribe((response) => {
      this.commentsGet();
    });
  }
  deletComment(editData: any) {
    console.log(this.editCommentForm.value.edit);
    let obj = {
      commentReplyId: '',
    };
    this.service.commentDeleteReply(obj, editData._id).subscribe((response) => {
      this.commentsGet();
    });
  }
  deletReplyComment(editData: any, replyId: any) {
    console.log(this.editCommentForm.value.edit, replyId);
    let obj = {
      commentReplyId: replyId,
    };
    this.service.commentDeleteReply(obj, editData._id).subscribe((response) => {
      this.commentsGet();
    });
  }
  saveReplyEditForm(editData: any) {
    console.log(this.replyEditCommentForm.value.replyEdit);
    let obj = {
      comment: this.replyEditCommentForm.value.replyEdit,
      commentReply: true,
    };
    this.service.commentUpdateReply(obj, editData._id).subscribe((response) => {
      this.commentsGet();
    });
  }
  toggleEditButtons() {
    if (this.service.getToken() != '') {
      this.editActive = true;
      this.readonly = false;
      this.showEditButtons = true;
    }
  }
  toggleReplyEditButtons() {
    if (this.service.getToken() != '') {
      this.editActive = true;
      this.readonly = false;
      this.showReplyEditButtons = true;
    }
  }
  // edit and delete comment buttons and api calls
  commentEvent() {
    if (this.service.getToken() == '') {
      this.readonly = true;
      this.dialog.open(NoAuthSignupComponent, {
        data: {
          title: 'to write the comment',
          image: this.thumbnailImage,
          ShareLink: `/c/${this.userDetailsParams.split(' ').join('-')}/id-${
            this.product_id
          }`,
          disableClose: true,
        },
      });
    }
  }
  toggleButtons() {
    if (this.service.getToken() != '') {
      this.inputActive = true;
      this.readonly = false;
      this.showButtons = true;
    }
  }

  // reply comment ts file
  replyShowInput(comment: any) {
    if (this.service.getToken() == '') {
      this.readonly = true;
      this.dialog.open(NoAuthSignupComponent, {
        data: {
          title: 'to reply the comment',
          image: this.thumbnailImage,
          ShareLink: `/c/${this.userDetailsParams.split(' ').join('-')}/id-${
            this.product_id
          }`,
          disableClose: true,
        },
      });
    } else {
      console.log('replyId', comment._id);
      this.commentId = comment._id;
      comment.showReplyForm = !comment.showReplyForm;
    }
  }

  commentEventReply(e: any) {
    if (e.target.value != '') {
      this.commentBtnEventReply = false;
    } else {
      this.commentBtnEventReply = true;
    }
  }

  cancelCommentReply(comments: any) {
    this.inputActive = false;
    this.showButtonsReply = false;
    comments.showReplyForm = false;
    this.replyCommentForm.reset();
  }
  onReplyComment() {
    let obj = {
      comment: this.replyCommentForm.value.reply,
    };
    this.service.commentReply(obj, this.commentId).subscribe((response) => {
      this.replyCommentShow = false;
      this.commentsGet();
      this.inputActive = false;
      this.showButtonsReply = false;
      this.replyCommentForm.reset();
    });
    // this.Replycomment = this.replyCommentForm.value.reply;
    // this.ReplycommentUser.push({
    //   name: 'ram',
    //   comment: this.Replycomment,
    //   date: '2023-09-07T05:28:39.342Z',
    //   id: '3456',
    // });
  }

  toggleButtonsReply() {
    this.inputActive = true;
    this.showButtonsReply = true;
  }
  scrollToTarget() {
    const targetDiv = document.getElementById('targetDiv');
    if (targetDiv) {
      targetDiv.scrollIntoView({ behavior: 'smooth' });
    }
  }
  addTag() {}
  slides = [
    { img: 'https://via.placeholder.com/600.png/09f/fff' },
    { img: 'https://via.placeholder.com/600.png/021/fff' },
    { img: 'https://via.placeholder.com/600.png/321/fff' },
    { img: 'https://via.placeholder.com/600.png/422/fff' },
    { img: 'https://via.placeholder.com/600.png/654/fff' },
  ];
  slideConfig = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    centerMode: true,
    variableWidth: true,
  };
  slickInit(e: any) {
    console.log('slick initialized');
  }

  collectionsClick(item: any) {
    let click = {
      summaryId: item,
    };
    this.service.collectionClicks(click, item).subscribe((result) => {
      // console.log(result);
    });
  }
  comments(collections: any) {
    this.showComments = !this.showComments;
  }
  showIframe() {
    if (this.isBrowser) {
      this.showThumbnailView = false;
      this.showIframeView = true;
    }
  }

  channelName(title: any) {
    if (this.isBrowser) {
      this.router.navigate([`${title.split(' ')?.join('')}`]);
    }
  }
  channelNameRoute(data: any) {
    if (this.isBrowser) {
      this.router.navigate([`${data}`]);
    }
  }
  shareIt() {
    if (this.isBrowser) {
      // console.log();
      this.dialog.open(DynamicShareButtonDialogComponent, {
        data: {
          title: `${environment.SHARE_URL}${this.redirectionUrl}`,
          collectionName: this.userDetailsParams.split(' ').join('-'),
        },
      });
    }
  }
  shareUrl() {
    if (this.isBrowser) {
      this.dialog.open(SharelinkComponent, {
        data: {
          disableClose: true,
        },
      });
    }
  }

  productDetails(title: any, id: any) {
    this.productTitle = title;
    this.productId = id;
    this.router.navigate([
      `/p/${this.productTitle.split(' ').join('-')}/id-${this.productId}`,
    ]);
  }

  collectionChange(title: any, id: any) {
    this.realatedLoader = true;
    this.collectionsClick(id);
    if (this.service.getToken() != '') {
      this.collectionIdParms = {
        readerId: this.userTypeId,
      };
      this.service.summaryEndingTime(this.id).subscribe((summary) => {
        console.log('summary', summary);
        this.service.summaryReadingTime(id).subscribe((summary) => {
          console.log('summary', summary);
        });
      });
    }
    this.CollectionID = id;

    this.showThumbnailView = true;
    this.showIframeView = false;
    this.router.navigate([`/c/${title.split(' ').join('-')}/id-${id}`]);
    this.redirectionUrl = `/c/${title.split(' ').join('-')}/${id}`;
    this.userDetailsParams = title.split(' ').join('-');
    this.routeIdentifier = `/c/${title.split(' ').join('-')}/id-${id}`;
    this.routeIdentifierComment = `/c/${title.split(' ').join('-')}id-/${id}`;
    this.service.commentGet(id).subscribe((response) => {
      this.commentUser = response.data;
      this.totalComments = response.totalComment;
      this.commentUser.forEach((comment: any) => {
        return (comment.showReplyForm = false), (comment.showReplys = false);
      });
      console.log(this.commentUser);
    });
    this.service
      .CollectionListDetails(id, this.collectionIdParms)
      .subscribe((res) => {
        // console.log(res);
        this.realatedLoader = false;

        this.thumbnailImage = res.data[0].thumbNail;
        this.collectionCreatedByCreator = res?.data[0]?.product;
        this.collectionUserId = res.data[0].reader[0]._id;
        this.collectionDetails = res.data;
        this.attachementFile = res.data.attachement;
        if (res.data[0].thumbNail == null) {
          this.metaImage = res.data[0].customThumbNail;
        } else {
          this.metaImage = res.data[0].thumbNail;
        }
        this.liketotalCount = res.totalCollectionLike;

        if (this.service.getToken() == '') {
          this.channeldata = res.channeldata;
          this.channelProfile = res?.channeldata?.profile;
          this.channelname = res?.channeldata?.name;
          this.channeluserId = res?.channeldata?._id;
          this.collectionRealtedData = res?.collections;
        } else {
          this.channeldata = res.publisherData;
          this.channelProfile = res?.publisherData?.profile;
          this.channelname = res?.publisherData?.name;
          this.channeluserId = res?.publisherData?._id;
          this.collectionRealtedData = res?.summary;
        }

        this.prooductCount = res?.data[0]?.product.length;
        this.userId = res?.data[0]?.readerId;
        // console.log('length', this.prooductCount);
        // this.summaryView = res.data[0]?.Summary;

        // if (this.summaryView.includes('<li')) {
        //   // console.log('editoor view');
        //   this.summary = res.data[0]?.Summary;
        //   this.editorView = true;
        //   this.exsitingCollection = false;
        // } else {
        //   this.summary = res.data[0]?.Summary?.split(/\d+/);
        //   this.exsitingCollection = true;

        //   this.editorView = false;

        //   // console.log('exisiting collection');
        // }
        //summary
        this.summaryView = res.data[0]?.Summary;
        this.summaryEditorView = res.data[0]?.Summary;

        if (
          this.summaryView.includes('<ol') &&
          this.summaryView.includes('<li')
        ) {
          console.log('editoor view');
          this.summary = res.data[0]?.Summary;
          this.editorView = true;
          this.exsitingCollection = false;
        } else if (
          this.summaryView.includes('<ul') &&
          this.summaryView.includes('<li')
        ) {
          this.summary = res.data[0]?.Summary;
          this.editorView = true;
          this.exsitingCollection = false;
        } else {
          this.summary = res.data[0]?.Summary?.split(/\d+/);
          this.exsitingCollection = true;

          this.editorView = false;

          console.log('exisiting collection');
        }
        if (this.service.getToken() != '') {
          this.service.loginUserDetails().subscribe((result) => {
            this.sessionType = result.data.userType;
            this.userTypeId = result.data._id;

            // console.log('collection page', this.sessionType);
          });
          this.service.ChannelFollowList().subscribe((res: any) => {
            // console.log('followers', res);
            this.channelFollowList = res.data;
            this.channelFollowList.forEach((item: any) => {
              this.channelNamesOnpage.push(item.publisherName);
              // console.log('channelnames', this.channelNamesOnpage);
            });
            let channel = this.channelNamesOnpage.includes(this.channelname);
            // console.log('channel', channel);
            if (channel) {
              console.log('follow match');
              this.following = true;
              this.follow = false;
              this.followPublisher = true;
            } else {
              console.log(' follow not match');
              this.follow = true;
              this.following = false;
              this.followPublisher = false;
            }
          });
        }

        this.collectionImage = res.data[0].sourceURL.slice(
          res.data[0].sourceURL.indexOf('=') + 1
        );
        this.CImage = `https://img.youtube.com/vi/${this.collectionImage}/hqdefault.jpg`;
        // console.log(this.collectionImage);
        // console.log(this.CImage);
        this.collectionCreatedByCreator = res.data[0].product;
        this.shareLink = `${environment.SHARE_URL}/c/${title
          .split(' ')
          .join('-')}/id-${id}`;
        this.summary = res.data[0]?.Summary?.split(/\d+/);
      });
  }
  categoryNavigate() {
    console.log('clicked ks');
  }
  followBtn(collection: any) {
    this.collectionsClick(collection._id);

    if (this.service.getToken() == '') {
      this.dialog.open(NoAuthSignupFollowComponent, {
        data: {
          title: 'to follow  the channel',
          image: this.thumbnailImage,
          ShareLink: `/c/${collection.summaryName.split(' ').join('-')}/id-${
            collection._id
          }`,
          disableClose: true,
        },
      });
    } else {
      this.params = {
        publisherName: collection.publisherName,
        publisherId: collection.publisherId,
      };
      this.service.followCreatorChannel(this.params).subscribe((res) => {
        if (environment.CLEVERTAP) {
          clevertap.event.push('PublisherProflieFollows', {
            PublisherProflieName: collection.publisherName,
            readerName: this.userName,
          });
        }

        // this.followersAPI();
        collection.followPublisher = true;
        this.followPublisher = true;
      });
    }
  }
  UnfollowBtn(id: any, collection: any) {
    // console.log(id);
    if (this.service.getToken() == '') {
      this.dialog.open(NoAuthSignupFollowComponent, {
        data: {
          title: 'to follow  the channel',
          disableClose: true,
        },
      });
    } else {
      this.channelNames = [];
      let obj = {
        publisherId: id,
      };
      this.service.ChannelUnFollowList(id).subscribe((res) => {
        // this.following = false;
        // this.follow = true;
        // collection.followpublisher = false;
        this.followPublisher = false;
        this.service.ChannelFollowList().subscribe((res: any) => {
          // console.log('followers', res);
          this.channelFollowList = res.data;
          this.channelFollowList.forEach((item: any) => {
            this.channelNames.push(item.publisherName);
            console.log('channelnames', this.channelNames);
          });
          let channel = this.channelNames.includes(this.channelname);
          // console.log('channel', channel);
          if (channel) {
            console.log('follow match');
            // this.following = true;
            // this.follow = false;
            this.followPublisher = true;
            //  collection.followPublisher = false;
          } else {
            console.log(' follow not match');
            this.dialog.open(SuccessdialoghomeComponent, {
              data: {
                title: collection.name,
                view: collection,
                topic: false,
                publisher: true,
                bookmark: false,
                disableClose: true,
              },
            });
            this.followPublisher = false;
          }
        });
      });
    }
  }
  dislikeSummary(collections: any, likes: Boolean, id: any) {
    // console.log('clevertap succefully event', clevertap.event);

    if (this.service.getToken() == '') {
      //clevertap intergation event
      if (this.isBrowser) {
        if (environment.CLEVERTAP) {
          clevertap.event.push('PreLoginBlinks', {
            PreLoginBlinks: 'PreLogin Blink click',
            PreLoginBlinksSummaryName: collections.summaryName,
            PreLoginBlinksId: collections._id,
          });
        }
      }
      // this.collectionsClick(collections._id);
      //clevertap intergation event
      this.dialog.open(NoAuthSignupComponent, {
        data: {
          title: 'unBlinkcollection',
          ShareLink: `/c/${collections.summaryName.split(' ').join('-')}/id-${
            collections._id
          }`,
          disableClose: true,
        },
      });
    } else {
      this.service.userDisLikeCollections(collections._id).subscribe((res) => {
        //clevertap intergation event
        if (this.isBrowser) {
          if (environment.CLEVERTAP) {
            clevertap.event.push('PostLoginBlinks', {
              PostLoginBlinks: 'PostLogin Blink collections',
              PostLoginBlinksSummaryName: collections.summaryName,
              PostLoginBlinksId: collections._id,
            });
          }
        }
        //clevertap intergation event
        // this.lottieimage = true;
        // collections.summaryLikeCount++;
        // collections.summaryLike = true;
        this.dialog.open(SuccessdialoghomeComponent, {
          data: {
            title: collections.name,
            view: collections,
            topic: false,
            publisher: false,
            bookmark: false,
            downvote: true,
            disableClose: true,
          },
        });
        // setTimeout(() => {
        //   this.lottieimage = false;
        // }, 3000);
      });
    }
  }
  showCoupon(id: any, title: any) {
    if (this.service.getToken() == '') {
      this.dialog.open(NoAuthSignupComponent, {
        data: {
          title: 'to view the code',
          image: this.thumbnailImage,
          ShareLink: `/c/${title.split(' ').join('-')}/id-${id}`,
          disableClose: true,
        },
      });
    } else {
      this.router.navigate([
        `/p/${this.productTitle.split(' ').join('-')}/id-${this.productId}`,
      ]);
    }
  }
  edit(id: any) {
    this.router.navigate(['/Edit-Collection'], {
      queryParams: { id: id },
    });
  }
  editProduct(id: any) {
    this.router.navigate(['/Edit-Product'], {
      queryParams: { id: id },
    });
  }
  delete(id: any) {
    this.service.powerUserDeleteCollection(id).subscribe((res) => {
      // console.log(res);
      this.router.navigate(['/feed']);
    });
  }
  deleteProduct(id: any) {
    this.service.powerUserDeleteProduct(id).subscribe((res) => {
      // console.log(res);
      this.router.navigate(['/feed']);
    });
  }
  saveCollection(
    collections: any,
    bookmark: boolean,
    collectionId: any,
    title: any
  ) {
    this.collectionsClick(collections._id);
    if (this.service.getToken() == '') {
      //clevertap intergation event

      if (environment.CLEVERTAP) {
        clevertap.event.push('PreLoginBookmarks', {
          PreLoginBookmarks: 'Pre login bookmark click',
          PreLoginBookmarksSummarieName: collections.summaryName,
          PreLoginBookmarksSummarieId: collections._id,
        });
      }

      //clevertap intergation event
      this.dialog.open(NoAuthSignupComponent, {
        data: {
          title: 'Read later this collection',
          ShareLink: `/c/${title.split(' ').join('-')}/id-${collectionId}`,
          disableClose: true,
        },
      });
    } else {
      let obj = {
        summaryId: collectionId,
      };
      this.service.userCollectionGet(obj).subscribe(
        (res) => {
          //clevertap intergation event

          if (environment.CLEVERTAP) {
            clevertap.event.push('PostLoginBookmarks', {
              PostLoginBookmarksreadSummaryLater: 'Summary has been bookmarked',
              PostLoginBookmarksSummarieName: collections.summaryName,
              PostLoginBookmarksSummarieId: collections._id,
            });
          }

          //clevertap intergation event
          collections.bookmarkSummary = true;
        },
        (error) => {}
      );
    }
  }
  subscribeChannel(collections: any, subscribe: boolean) {
    if (this.service.getToken() == '') {
      if (environment.CLEVERTAP) {
        clevertap.event.push('PublisherProflieClicks', {
          PublisherProflieName: collections.publisherName,
        });
      }
      this.dialog.open(NoAuthSignupComponent, {
        data: {
          title: 'Follow this channel',
          ShareLink: `/c/${collections.summaryName.split(' ').join('-')}/id-${
            collections._id
          }`,
          disableClose: true,
        },
      });
    } else {
      this.params = {
        publisherName: collections.publisherName,
        publisherId: collections.publisherId,
      };
      this.service.followCreatorChannel(this.params).subscribe((res) => {
        if (environment.CLEVERTAP) {
          clevertap.event.push('PublisherProflieFollows', {
            PublisherProflieName: collections.publisherName,
            readerName: this.userName,
          });
        }

        // this.followersAPI();
        collections.followpublisher = true;
        this.followPublisher = true;
      });
    }
  }
  likeBtn(collections: any, likes: Boolean, id: any) {
    this.collectionsClick(id);
    this.collectionsClick(collections._id);
    if (this.service.getToken() == '') {
      this.dialog.open(NoAuthSignupComponent, {
        data: {
          title: 'Blink collection',
          ShareLink: `/c/${collections.summaryName.split(' ').join('-')}/id-${
            collections._id
          }`,
          disableClose: true,
        },
      });
    } else {
      this.service.userLikeCollections(collections._id).subscribe((res) => {
        // this.lottieimage = true;
        collections.collectionLikeCount++;
        collections.summaryLike = true;

        // setTimeout(() => {
        //   this.lottieimage = false;
        // }, 3000);
      });
    }
  }
  goBack() {
    if (this.service.getToken() == '') {
      this.router.navigate(['']);
    } else {
      this.router.navigate(['/feed']);
      this.service.summaryEndingTime(this.id).subscribe((summary) => {});
    }
  }
  link() {
    this.dialog.open(SuccessdialoghomeComponent, {
      data: {
        title: 'collection.name',
        view: 'collection',
        topic: false,
        link: true,
        publisher: false,
        bookmark: false,
        disableClose: true,
      },
    });
  }
  report(collections: any) {
    // console.log(text);
    if (this.service.getToken() == '') {
      this.readonly = true;
      this.dialog.open(NoAuthSignupComponent, {
        data: {
          title: `to report`,
          ShareLink: `/c/${collections.summaryName.split(' ').join('-')}/id-${
            collections._id
          }`,
          disableClose: true,
        },
      });
    } else {
      this.dialog.open(ReportDialogComponent, {
        data: {
          title: collections._id,
          disableClose: true,
        },
      });

      // let obj = {
      //   report_type: text,
      // };
      // this.collectionService
      //   .reportSummary(collections._id, obj)
      //   .subscribe((result) => {
      //     Swal.fire({
      //       title:
      //         'Thanks for reporting.Our team will review your feedback and take necessary actions',
      //       icon: 'success', // You can change the icon type
      //       timer: 4000, // Set the timer to 3000 milliseconds (3 seconds)
      //       showConfirmButton: false, // Hide the "OK" button
      //     });
      //     console.log(result);
      //   });
    }
  }
  get c(): { [key: string]: AbstractControl } {
    return this.subscribeForm.controls;
  }
  subscribe() {
    // this.dialog.open(SubscribeDialogComponent, {
    //   data: {
    //     email: this.subscribeForm.value.email,
    //     disableClose: true,
    //   },
    // });
    if (this.subscribeForm.invalid) {
      this.subscribeSubmitted = true;
    } else {
      this.subscribeSubmitted = false;
      this.emailSubcribe = true;

      let obj = {
        email: this.subscribeForm.value.email,
      };

      this.service.subscriberEmail(obj).subscribe(
        (res) => {
          // console.log(res);
          this.emailSubcribe = false;

          this.dialog.open(SubscribeDialogComponent, {
            data: {
              email: this.subscribeForm.value.email,
              disableClose: true,
            },
          });
          this.subscribeForm.reset();
        },
        (error) => {
          this.error = error.error.message;
        }
      );
    }
  }
  emailEvent(e: any) {
    console.log(e.target.value);
    this.subscribeEmail = e.target.value;
  }
  saveForlater(id: any, details: any, isbookmark: any) {
    console.log(details);
    console.log(isbookmark);
    console.log('p', id);

    if (this.service.getToken() == '') {
      this.dialog.open(NoAuthSignupComponent, {
        data: {
          title: 'Bookmark this product',
          ShareLink: this.redirectionUrl,
          disableClose: true,
          // image: details.image,
        },
      });
    } else {
      console.log('saveProductBookmark', id);
      let obj = {
        productId: id,
      };
      this.service.userProductBookmark(obj).subscribe(
        (result) => {
          if (isbookmark) {
            details.isBookmark = false;
          } else {
            details.isBookmark = true;
          }
        },
        (error) => {
          this.saved = false;
          this.productError = error.error.message;
          // setTimeout(() => {
          //   this.productError = '';
          // }, 3000);
        }
      );
    }
  }
  downloadSummary() {
    // this.router.navigate(['/''`${this.}`']);

    if (this.service.getToken() == '') {
      this.dialog.open(NoAuthSignupComponent, {
        data: {
          title: 'Bookmark this product',
          ShareLink: this.redirectionUrl,
          disableClose: true,
          // image: details.image,
        },
      });
    } else {
      this.attachement = this.attachementFile;
      const anchor = document.createElement('a');
      anchor.href = this.attachement;
      anchor.target = '_blank';
      anchor.click();
    }
  }
}
