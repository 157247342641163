import { Component, DoCheck, OnInit } from '@angular/core';
import { faLessThanEqual } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-admin-home',
  templateUrl: './admin-home.component.html',
  styleUrls: ['./admin-home.component.css'],
})
export class AdminHomeComponent implements OnInit, DoCheck {
  showLogin: boolean = true;
  sidebarOpen: boolean = true;
  notificationsOpen: boolean = false;
  showDashboard: boolean = false;
  constructor() {}

  ngOnInit(): void {}
  ngDoCheck(): void {
    if (localStorage.getItem('admin') == 'admin') {
      this.showDashboard = true;

      this.showLogin = false;
    } else {
      this.showLogin = true;
      this.showDashboard = false;
    }
  }
}
