
       <app-publisher-onboarding-dialog *ngIf="this.totalBlogs == 0"></app-publisher-onboarding-dialog>

<div class="sidenavbar-heade px-3" *ngIf="this.totalBlogs != 0">
<!-- <div class='row'>
    <div class="col-md-2">
    <app-sidenavbar></app-sidenavbar>

    </div> -->
    <div class="summary-header Your_blog ml-3 mt-md-3">
          <h6>Comments</h6>
     <!-- <div class="d-flex justify-content-end">
       <div class="d-flex   language-header">
            <p>spam and offensive</p>
            <select (change)="langugeChange($event)"  class="selectpicker" >
              <option value="">select</option>
                <option *ngFor="let item of Language" value="{{item}}">{{item}}</option>
            </select>
        </div>
     </div> -->

     <div class="card-section my-md-4 d-flex justify-content-between">
        <div class="card mr-md-5" *ngFor="let item of items">
            <div class="card-body">
                <p>{{item.name}}</p>
                <h3>{{item.number ? item.number : 0}}</h3>
            </div>
        </div>
     </div>
      <p class="succesfully" *ngIf="userDelete">User delete successfully</p>
     <div class="table-data">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

  <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

  <!-- Position Column -->

<!-- 
  <ng-container matColumnDef="commentid">
    <th mat-header-cell *matHeaderCellDef> Comment-id </th>
    <td mat-cell *matCellDef="let element" > 
        <p>{{element.summaryComments[0]?._id}}</p>
     </td>
  </ng-container> -->
  <ng-container matColumnDef="summaryid">
    <th mat-header-cell *matHeaderCellDef> Summary-id </th>
    <!-- <td mat-cell *matCellDef="let element"> {{element.collectionData[0]._id}} </td> -->
    <td mat-cell *matCellDef="let element">
         
        <p>{{element._id}}</p>

        </td>
  </ng-container>

  

  <!-- Weight Column -->
  <ng-container matColumnDef="summarytitle">
    <th mat-header-cell *matHeaderCellDef> Summary title </th>
    <td mat-cell *matCellDef="let element"> {{element.summaryName}} </td>
    <!-- <td mat-cell *matCellDef="let element"> {{element.summarytitle}} </td> -->
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="Commentdetails">
    <th mat-header-cell *matHeaderCellDef> Comment details </th>
    <td mat-cell *matCellDef="let element">
        
        <!-- {{element.comment}} -->
    <!-- <p class="comment">
  {{ showFullText ? element.summaryComments[0].comment : element.summaryComments[0].comment.slice(0, 30) }}
  <span *ngIf="!showFullText" (click)="toggleText(element.summaryComments[0].comment)">... <a>Show more</a></span>
   <span *ngIf="showFullText">
    <a (click)="toggleText(element.summaryComments[0].comment)">Show less</a>
  </span>
</p> -->
<p class="comment">
  {{element.summaryComments[0].comment }}
</p>
    </td>
  </ng-container>
  <!-- Weight Column -->
  <ng-container matColumnDef="Spam" class='ml-3'>
    <th mat-header-cell *matHeaderCellDef> Spam? </th>
    <td mat-cell *matCellDef="let element"> 
        {{element.summaryComments[0].MarkAsSpamCount}}
    </td>
  </ng-container>
<!-- Name Column -->
  <ng-container matColumnDef="offensive">
    <th mat-header-cell *matHeaderCellDef> offensive ? </th>
     <td mat-cell *matCellDef="let element"> 
        {{element.summaryComments[0].MarkAsOffensiveCount}}
    </td>
  </ng-container>
  <!-- Symbol Column -->
  <ng-container matColumnDef="Commented_by">
    <th mat-header-cell *matHeaderCellDef> Commented_by</th>
    <td mat-cell *matCellDef="let element"> {{element.summaryComments[0].name}} </td>
  </ng-container>
  <!-- Weight Column -->
  <ng-container matColumnDef="Publisher_id">
    <th mat-header-cell *matHeaderCellDef> Publisher_id </th>
    <td mat-cell *matCellDef="let element"> {{element.reader[0]?._id}} </td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="Action">
    <th mat-header-cell *matHeaderCellDef> Action </th>
    <td mat-cell *matCellDef="let element"> <div class="d-flex">
        <!-- <div class="edit-img">
           <img  src="../../../assets/images/e-3.png">
        </div>
        <div class="toggle">
          <label class="toggle">
  <input type="checkbox" id="toggleButton" (change)="toggleChanged($event)">
  <span class="slider"></span>
</label>
        </div> -->
         <div class="edit-img">
           <a (click)="deleteCommets(element)">
            <img class="cursor"  src="../../../assets/images/delete.png">
            </a>
           
        </div>
       
       </div></td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
  <p class="no-user" *ngIf="userData == ''">No users in {{language}} language</p>
<mat-paginator (page)="pageChanged($event)" [length]="totalSummary" [pageIndex]="currentPage" [pageSize]="pageSize"  [pageSizeOptions]="pageSizeOptions" aria-label="Select page of users"></mat-paginator>
     </div>
</div>
</div>
  