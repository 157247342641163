import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdminRoutingModule } from './admin-routing.module';
import { AdminLoginComponent } from './admin-login/admin-login.component';
import { MaterialModule } from '../material.module';
import { AdminHomeComponent } from './admin-home/admin-home.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SummaryiesComponent } from './summaryies/summaryies.component';
import { SidenavheaderComponent } from './sidenavbar/sidenavheader/sidenavheader.component';
import { UsersComponent } from './users/users.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SummariesEditComponent } from './summaryies/summaries-edit/summaries-edit.component';
import { UsernavbarModule } from '../usernavbar/usernavbar.module';
import { QuillModule } from 'ngx-quill';
import { AdminTopicsComponent } from './admin-topics/admin-topics.component';
import { AdminPowerUserComponent } from './admin-power-user/admin-power-user.component';
import { ReportsComponent } from './reports/reports.component';
import { LanguagesComponent } from './languages/languages.component';
import { ToogleComponent } from './toogle/toogle.component';
@NgModule({
  declarations: [
    AdminLoginComponent,
    AdminHomeComponent,
    DashboardComponent,
    SummaryiesComponent,
    SidenavheaderComponent,
    UsersComponent,
    SummariesEditComponent,
    AdminTopicsComponent,
    AdminPowerUserComponent,
    ReportsComponent,
    LanguagesComponent,
    ToogleComponent,
  ],
  imports: [
    CommonModule,
    AdminRoutingModule,
    MaterialModule,
    ReactiveFormsModule,
    UsernavbarModule,
    QuillModule.forRoot(),
  ],
})
export class AdminModule {}
