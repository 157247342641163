
<app-homenavbar *ngIf="sessionType == 'home'"></app-homenavbar>
<app-usernavbar *ngIf="sessionType == 'user'"></app-usernavbar>
<app-navbar *ngIf="sessionType == 'powerUser'"></app-navbar>
<app-creatornavbar *ngIf="sessionType == 'publisher'"></app-creatornavbar>
<div class=" landing-page mt-3">


<div class="main">
    <div class="container-fluid">
        <div class="information-section">
            <div class="d-md-flex">
                <div class="percentage">
                    <h5>90%</h5>
                </div>
                <div class="informmation">
                   <h6>Information and knowledge learned from videos is forgotten within 7 days</h6>
                   <a target="_blank" href="https://en.wikipedia.org/wiki/Forgetting_curve">https://en.wikipedia.org/wiki/Forgetting_curve</a>
                   <!-- <div class="exlpore-button ml-md-5">
                    <button class="cursor" (click)="feedRoute()">Explore</button>
                   </div> -->
                </div>
            </div>
        </div>
</div>
        <div class="scroll-section pt-md-5">

            <div class="day-1-scroll"  data-aos="fade-up"
     data-aos-duration="1000">
                <div class="d-flex justify-content-center">
                    <div class="img-day-1">
                        <img src="../../assets/images/day-1.png">
                    </div>
                    <div class="day-1data ml-md-5">
                        <h4>On Day 1, After you watch or learn from a videos</h4>
                        <p>You forget 10%</p>
                    </div>
                </div>
            </div>
            </div>
        <div class="scroll-section pt-md-5">

            <div class="day-2-scroll"  data-aos="fade-up"
     data-aos-duration="1000">
                <div class="d-flex justify-content-center">
                    <div class="img-day-1">
                        <img src="../../assets/images/day-1.png">
                    </div>
                    <div class="day-1data ml-md-5">
                        <h4>After 7 days</h4>
                        <p>You forget 90% </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="scroll-section pt-md-5">

            <div class="day-2-scroll"  data-aos="fade-up"
     data-aos-duration="1000">
                <div class="d-flex justify-content-center">
                    <div class="img-day-1">
                        <img src="../../assets/images/day-1.png">
                    </div>
                    <div class="day-1data ml-md-5">
                        <h4> After 30 days</h4>
                        <p>You forget 100%</p>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="loss-billions-section mt-md-5">
            <h3>That's a loss of billions of wasted hours for humans and 100s hours loss for you as an individuals</h3>
            <img src="../../assets/images/clock-1.png">
        </div> -->
<div class="AI_section">
 <div  class="introduction-section">
            <h5>Introducing Summarizly AI</h5>
            <p>Summarizly AI makes you never forget what you learned From Youtube Videos</p>

            <div class="d-flex justify-content-center mb-3" *ngFor="let items of videosArray">
                 <h6>{{items.count}}</h6>
                <h3>{{items.title}}</h3>
            </div>
            <div class="exlpore-button ml-md-5">
                    <button class="cursor" (click)="feedRoute()">Explore</button>
                   </div>
        </div>
</div>
       


        <div class="cards-swiper mb-5">
            <!-- <h4>Summarizly AI makes you remember the knowledge and information You gained from videos of any topic</h4> -->
             
              <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig"
                (init)="slickInit($event)">
                    <div class="slide" ngxSlickItem  *ngFor="let items of cards">
  <div  class="card card-view" >
                            <div class="card-body">
                                <div class="card-details-view">
                                    <h2>"Never forget those <span>{{items.category}}</span> </h2>
                                    <P>You learned from Youtube channels"</P>
                                    <div class="icons-img">
                                         <img src="{{items.img}}">

                                    </div>
                                    
                                    <!-- <a class="mt-3">www.summarizly.ai/beauty</a> -->
                                </div>
                            
                    </div>
                   </div>
                    </div>
                      
                </ngx-slick-carousel>
        </div>
        <div class="container-fluid">
<div class="retain-and-recall">
         
           <div class="row">
            <div class="col-md-6">
                <h6>Retain and Recall information from any videos you watched</h6>
                <div class="exlpore-button">
                    <button class="cursor" (click)="feedRoute()">Explore</button>
                   </div>
            </div>
            <div class="col-md-6">
              <img src="../../assets/images/moblie-1.png" class="image-1">
            </div> 
           </div>
        </div>
      
        
       
        </div>
          </div>

        
             <div class="Universally mt-md-4"  data-aos="fade-up"
     data-aos-duration="1000" >
           <h6>With AI,  knowledge is no longer limited to one language</h6>
           <div class="row" >
            <div class="col-md-6">
                <div class="ml-md-5 d-md-flex">
                    <div>
                <img src="../../assets/images/t-1.png" class="t-1-img">

                    </div>
                    <div class="summary-l">
                        <p>English</p>
                <img src="../../assets/images/p-a.png" class="t-2-img">

                    </div>

                </div>
            </div>
            <div class="col-md-6">
                <div class="d-summary-img-data">
              <img src="../../assets/images/m-2.png" class="image-2">

                </div>
            </div> 
           </div>
        </div>
             <div class="Universally"   data-aos="fade-up"
     data-aos-duration="1000" >
           <div class="row" >
            <div class="col-md-6">
               <div class="ml-md-5 d-md-flex">
                    <div>
                <img src="../../assets/images/t-1.png" class="t-1-img">

                    </div>
                    <div class="summary-l">
                        <p>Hindi</p>
                <img src="../../assets/images/p-a.png" class="t-2-img">

                    </div>

                </div>
            </div>
            <div class="col-md-6">
                <div class="d-summary-img-data">
              <img src="../../assets/images/h-2.png" class="image-2">

                </div>
            </div> 
           </div>
        </div>
             <div class="Universally"   data-aos="fade-up"
     data-aos-duration="1000" >
           <!-- <h6>With AI,  knowledge is no longer limited to one language</h6> -->
           <div class="row" >
            <div class="col-md-6">
               <div class="ml-md-5 d-md-flex">
                    <div>
                <img src="../../assets/images/t-1.png" class="t-1-img">

                    </div>
                    <div class="summary-l">
                        <p>Chinese</p>
                <img src="../../assets/images/p-a.png" class="t-2-img">

                    </div>

                </div>
            </div>
            <div class="col-md-6">
                <div class="d-summary-img-data">
              <img src="../../assets/images/ch-1.png" class="image-2">

                </div>
            </div> 
           </div>
        </div>
             <div class="Universally"   data-aos="fade-up"
     data-aos-duration="1000" >
           <!-- <h6>With AI,  knowledge is no longer limited to one language</h6> -->
           <div class="row" >
            <div class="col-md-6">
                <div class="ml-md-5 d-md-flex">
                    <div>
                <img src="../../assets/images/t-1.png" class="t-1-img">

                    </div>
                    <div class="summary-l">
                        <p>Spanish</p>
                <img src="../../assets/images/p-a.png" class="t-2-img">

                    </div>

                </div>
            </div>
            <div class="col-md-6">
                <div class="d-summary-img-data">
              <img src="../../assets/images/sp-1.png" class="image-2">

                </div>
            </div> 
           <h4>90+ other languages</h4>

           </div>
        </div>
            
        
</div>
    

        <div class="AI-video mt-md-5">
            <!-- <h2>AI Video Revolution</h2> -->
            <div class="course">
                <h5>"An AI revolution in the Information
 and Knowledge space"</h5>
            </div>
        </div>

        <!-- <div class="co-piliot-section my-md-5 px-4">
            <div class="row">
                <div class="col-md-6">
                     
                 <div class="AI-video-1 mt-md-3">
                    <h4>Summarizly AI becomes your copilot To make you smarter and retain Information you gained from videos</h4>
                 </div>
                </div>
                <div class="col-md-6">
                   <div>
                    <div class="d-flex mb-md-3">
                        <div class="ai-1">
                            <img src="../../assets/images/brain.png">
                        </div>
                        <div class="brain-section ml-md-3">
                           <h5 >Makes your brain smarter always,not just while you're watching videos</h5>
                        </div>
                    </div>
                    <div class="d-flex  mb-md-3">
                        <div class="ai-1">
                            <img src="../../assets/images/clock-23.png">
                        </div>
                        <div class="brain-section ml-md-3">
                           <h5 >Makes your brain smarter always,not just while you're watching videos</h5>
                        </div>
                    </div>
                    <div class="d-flex">
                        <div class="ai-1">
                            <img src="../../assets/images/smiley.png">
                        </div>
                        <div class="brain-section ml-md-3">
                           <h5 >Makes your brain smarter always,not just while you're watching videos</h5>
                        </div>
                    </div>
                   </div>
                </div>
            </div>
        </div> -->


        <div class="main">
  <div class="global-section mb-md-4">
            <div class="row">
                <div class="col-md-6">
                     <div class="loading ml-md-4">
<ng-lottie
  [options]="optionsW"
   
></ng-lottie>
      </div>
                </div>
                <div class="col-md-6">
                    <div class="global-data mt-md-4">
                        <h4>No matter, where you live 
what language you can 
speak, read and
 understand
With AI, an equal 
opportunity of information and
 knowledge for everyone around
 the world</h4>
                    </div>
                    <div class="exlpore-button ml-md-2">
                    <button class="cursor" (click)="feedRoute()">Explore</button>
                   </div>
                </div>
            </div>
         </div>
            </div>
       
 
 

        <div class="mission-section">
           <h4 class="mb-md-5">Our mission</h4>
           <p class="mb-md-3">Extract the knowledge and information from billions Of videos, make it accessibel, readable, rememberable By people in the form of text</p>
           <h6>Make people never forget  what they learned</h6>
           <div class="exlpore-button-2 ml-md-2">
                    <button class="cursor" (click)="feedRoute()">Explore</button>
                   </div>
        </div>
 <app-footer></app-footer>