import { Component, OnInit } from '@angular/core';
import { CollectionService } from '../_services/collection.service';

@Component({
  selector: 'app-usertopics',
  templateUrl: './usertopics.component.html',
  styleUrls: ['./usertopics.component.css'],
})
export class UsertopicsComponent implements OnInit {
  data: any;
  loader: boolean;

  constructor(private _userService: CollectionService) {}

  ngOnInit(): void {
    this.loader = true;
    this._userService.userFollowedApi().subscribe((user) => {
      this.loader = false;
      this.data = user.topics;
      console.log(this.data);
      this.data.forEach((topic: any) => {
        topic.followTopic = true;
      });
    });
  }
  unfollow(categories: any) {
    this._userService.caterogyUnFollow(categories._id).subscribe((response) => {
      categories.followTopic = false;
      this._userService.userFollowedApi().subscribe((user) => {
        this.data = user.topics;
        console.log(this.data);
      });
    });
  }
}
