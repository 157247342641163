   
   <div class="main">
<app-navbar *ngIf="userType == 'powerUser'"></app-navbar>
<app-creatornavbar *ngIf="userType == 'creator-onbording'"></app-creatornavbar>

    <div class="container">
         <div class="collectionall-header ml-md-3 mt-3">
           <h5>My Products</h5>
        </div>
  <div class="collection-header product_details_Paragraph">
      <div class="list-of-products mt-md-3 pt-md-3" id="product-related">
        <!-- <h1 *ngIf="collectionCreatedByCreator != ''" class="py-md-3">Products used in this video by the creator</h1>
        <div class="c-p mt-md-5" *ngIf="collectionCreatedByCreator == ''">
          <h4>No products found in this collection</h4>
        </div> -->
        <div class="row" *ngIf="collectionCreatedByCreator != ''">
          <div class="col-md-4" *ngFor="let cardDetails of collectionCreatedByCreator">
           
              <div class="card mb-md-4" >
                <div class="card-body pointer">
                  <div class="product_list">
                    <!-- <div class="d-flex list-P"></div> -->
                    <img *ngIf="cardDetails?.image == null && cardDetails?.productImage == ''" (click)="productDetails(cardDetails.productTitle,cardDetails._id)" src="../../../assets/images/n-image.png" class="code-1-img image">
                    <img *ngIf="cardDetails?.image != null " (click)="productDetails(cardDetails.productTitle,cardDetails._id)" src="{{cardDetails?.image}}" class="code-1-img image">
                    <img *ngIf="cardDetails?.productImage != ''" (click)="productDetails(cardDetails.productTitle,cardDetails._id)" src="{{cardDetails?.productImage[0]?.url}}" class="code-1-img">
                    <div class="d-flex">
                      <div class="mr-auto">
                        <p class="header-p pt-2">{{cardDetails.productTitle | slice:0:24}}</p>
                      </div>
                       
                    </div>
                      <div class="d-flex">
                         <div class="mr-auto collection-name-link ml-3">
                          <a routerLink="/p/{{cardDetails.productTitle.split(' ').join('-')}}/id-{{cardDetails._id}}">
                            <h5>View Product</h5>
                          </a>
                        </div>
                         <div class="save-for-later d-flex  mr-3">
                        <div>
                          <img src="../../../assets/images/heart.svg">
                        </div>
                       <div>
                          <a (click)="saveForlater(cardDetails._id)">
                            <h5>Bookmark</h5>
                           
                          </a>

                           
                        </div>
                      </div>
                          
                      </div>
                      
                  </div>
                  
                  <div class="product_list_details">
                    <div class="d-flex">
                      <!-- <div class=""><img src="../../../assets/images/heart.png"></div><div class="save-for-later"><a><h5>Save for later</h5></a></div> -->
                      <!-- <div class="list_price"><p>${{cardDetails.price}}</p></div> -->
                      <!-- <div class="product_list_sold_on ml-auto"><h4>Sold on</h4><p class="product-website-name">{{cardDetails.affiliatePartnerName ? cardDetails.affiliatePartnerName : 'N/A'}}</p></div> -->
                    </div>
                    <div class="details-List-view" *ngIf="cardDetails.couponCode != ''">
                      <p>Creator Discount Code Available</p>
                      <div class="creator-code">
                        <div class="d-flex code-d">
                          <div class="code-p">
                            <!-- <p>{{cardDetails.couponCode}}</p> -->
                            <p>******</p>
                          </div>
                          <div class="copy-btn ml-auto">
                            <button type="button" class="ml-auto" (click)="productDetails(cardDetails.productTitle,cardDetails._id)">Show</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <h5 class="mt-md-2 pointer">Share this product with friends</h5> -->
                  </div>
                </div>
              </div>
            <!-- <p *ngIf="saved"  class="succesfully-p mt-1">
                  Product Bookmarked successfully
                </p>
                 <p *ngIf="productError != null" class="mt-md-3 code-d-c">
            {{ productError }}
          </p> -->
          </div>
        </div>
      </div>
       
    </div>
        </div>
   </div>
   
 