<div class="main">
  <!-- <app-navbar></app-navbar> -->
  <app-homenavbar></app-homenavbar>
  <div class="container-fluid">
    <div class="login-section ">
      <div class="row">
        <div class="col-md-6 ">
          <div class="login-section-common-data pl-md-5 pt-md-3">
            <div class="common-data-login">
              <h4>Acquire knowledge on Startups, Entrepreneurship, Product and Growth Hacking</h4>
              <!-- <h5>One place where you </h5>
              <h4>discover, inspire products curated, promoted by top YouTube creators In</h4>
              <h3>In <span class="fashion">Fashion,</span>
                <span class="beauty"> beauty,</span>
                <span class="personal-care">personal care </span>
              </h3>
              <h2>Find, save all creator discount codes shared by YouTube creators in 1000s of videos in one place</h2> -->
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="login-form-section" *ngIf="loginPage">
            <div class="form-section">
              <h4>Sign in to your account</h4>
              <form [formGroup]="loginFormGroup">
                <div class="email-form-section mt-md-5">
                  <input class="form-control" formControlName="email" placeholder="Enter email address">
                  <div *ngIf="f.email.errors?.required && submitted" class="invalid-feedback m-t d-block"> Email is required </div>
                  <div *ngIf="f.email.errors?.pattern && submitted" class="invalid-feedback m-t d-block"> Email is invalid </div>
                </div>
                <div class="password-form-section mt-md-4 pt-md-1">
                  <input type="password" class="form-control" formControlName="password" placeholder="Enter your password">
                  <div *ngIf="f.password.errors?.required && submitted" class="invalid-feedback m-t d-block"> Password is required </div>
                  <div *ngIf="loginErrorMessage != null" class="invalid-feedback m-t d-block">
                    {{loginErrorMessage}}
                  </div>
                </div>
              </form>
            </div>
            <div class="sign-in-section mt-md-4 pt-md-2">
              <div class="d-flex">
                <div class="btn-signin">
                  <button class="pointer" (click)="login()" type="submit">SIGN IN</button>
                </div>
                <div class="forgot-password pointer">
                  <h6>
                    <a (click)="forGotPassword()">Forgot password?</a>
                  </h6>
                </div>
              </div>
            </div>
            <div class='google-intergration'>
              <p class="py-md-4">OR</p>
              <div class=" google-btn">
                <button type="submit">
                  <a [href]="url | safePipe">
                    <img src="../../assets/images/google.png" class="google mr-md-3">Sign in via Google </a>
                </button>
              </div>
              <h6>Don’t have an account? <span class="pointer" (click)="signUp()">Sign up for free</span>
              </h6>
            </div>
          </div>
          <div class="login-form-section" *ngIf="resetPassword">
            <div class="form-section">
              <h4>Reset your password</h4>
              <form [formGroup]="forGotPasswordForm">
                <div class="email-form-section mt-md-5">
                  <input class="form-control" formControlName="email" placeholder="Enter email address">
                  <div *ngIf="r.email.errors?.required && submittedPassword" class="invalid-feedback m-t d-block"> Email is required </div>
                  <div *ngIf="r.email.errors?.pattern && submittedPassword" class="invalid-feedback m-t d-block"> Email is invalid </div>
                  <div *ngIf="errorMessage != null" class="invalid-feedback m-t d-block">
                    {{ errorMessage}}
                  </div>
                </div>
              </form>
            </div>
            <div class="sign-in-section mt-md-4 pt-md-2">
              <div class="d-flex">
                <div class="btn-signin">
                  <button class="pointer" type="submit" (click)="resetPasswordBtn()">Reset Password</button>
                </div>
              </div>
            </div>
            <div class='google-intergration pt-md-3'>
              <h6>Don’t have an account? <span class="pointer" (click)="signUp()">Sign up for free</span>
              </h6>
            </div>
          </div>
          <!-- <div class="login-form-section" *ngIf="confirmPassword">
            <div class="form-section">
              <h4>Reset your password</h4>
              <div class="email-form-section mt-md-5">
                <input class="form-control" placeholder="Enter new password">
              </div>
              <div class="email-form-section mt-md-4">
                <input class="form-control" placeholder="Confirm password">
              </div>
            </div>
            <div class="sign-in-section mt-md-4 pt-md-2">
              <div class="d-flex">
                <div class="btn-signin">
                  <button class="pointer" type="submit" (click)="confirmPasswordBtn()">Reset Password</button>
                </div>
              </div>
            </div>
            <div class='google-intergration pt-md-3'>
              <h6>Need help? <span class="pointer"> Contact us</span>
              </h6>
            </div>
          </div> -->
            <div class="password-changed mt-4" *ngIf="passwordChangedSuccesfully">
                      <img src="../../assets/images/check.png" class="success-img">
                       <p class="mt-4">Password reset link has been sent to your email. Please check your inbox.</p>
                       <h6>In case if you don’t see it consider checking your spam folder too, in case</h6>

                       <div class="d-flex after-success justify-content-center">
                           <a class="mr-4 pointer" (click)="loginNow()">Login now</a>
                           <a class='pointer' (click)="home()">Go to home</a>
                       </div>
                </div>
        </div>
      </div>
    </div>
  </div>
</div>