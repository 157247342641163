<div class="container">
  <h4 class="succesfully mt-md-3" *ngIf="succeed">Subtopics added successfully</h4>
  <img src="../../../assets/images/close.png" (click)="close()" class="close-img cursor">
  <h3 class="topics">Update SubTopics</h3>
  
    
       <div class="d-flex" *ngFor="let item of filteredItemsArray">
      <div>
        <div class="catergories-add-section mt-md-2">
          <label>Add Topics</label>
          <input disabled  [(ngModel)]="item.name" placeholder="Add topic" type="text" class="form-control">
          <div *ngIf="c.categoryName.errors?.required && submiited" class="invalid-feedback m-t d-block">Topic is required </div>
        </div>
       
       
      </div>
       <div>
        <div class="catergories-add-section mt-md-2">
          <label>Add subtopics</label>
          <input (input)="subCategory($event)"  placeholder="Add subtopics" type="text" class="form-control">
          <div *ngIf="this.error != null" class="invalid-feedback m-t d-block">{{this.error }}</div>
          <div *ngIf="this.messeage != null" class="invalid-feedback m-t d-block">{{messeage}}</div>
        </div>
        </div>
         <div class="submit-btn">
          <button type="button" class="pointer" (click)="update(item)">Add</button>
        </div>
    </div>
    <!-- <div class="submit-btn d-flex justify-content-center">
          <button type="button" class="pointer"  (click)="close()">Close</button>
        </div> -->
     

    </div>

   