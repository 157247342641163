import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'datepipe',
})
export class DatepipePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value) {
      const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
      if (seconds < 29)
        // less than 30 seconds ago will show as 'Just now'
        return 'Just now';
      const intervals: any = {
        year: 31536000,
        month: 2592000,
        week: 604800,
        day: 86400,
        hour: 3600,
        minute: 60,
        second: 1,
      };
      let counter;
      for (const i in intervals) {
        counter = Math.floor(seconds / intervals[i]);
        if (counter > 0)
          if (counter === 1) {
            return counter + ' ' + i + ' ago'; // singular (1 day ago)
          } else {
            return counter + ' ' + i + 's ago'; // plural (2 days ago)
          }
      }
    }
    return value;
  }
}
//   transform(value: any) {
//     var _value = Number(value);

//     var dif = Math.floor((Date.now() - _value) / 1000 / 86400);

//     if (dif < 30) {
//       return convertToNiceDate(value);
//     } else {
//       var datePipe = new DatePipe('en-US');
//       value = datePipe.transform(value, 'MMM-dd-yyyy');
//       return value;
//     }
//   }
// }

// function convertToNiceDate(time: string) {
//   var date = new Date(time),
//     diff = (new Date().getTime() - date.getTime()) / 1000,
//     daydiff = Math.floor(diff / 86400);

//   if (isNaN(daydiff) || daydiff < 0 || daydiff >= 31) return '';

//   return (
//     (daydiff == 0 &&
//       ((diff < 60 && 'Just now') ||
//         (diff < 120 && '1 minute ago') ||
//         (diff < 3600 && Math.floor(diff / 60) + ' minutes ago') ||
//         (diff < 7200 && '1 hour ago') ||
//         (diff < 86400 && Math.floor(diff / 3600) + ' hours ago'))) ||
//     (daydiff == 1 && 'Yesterday') ||
//     (daydiff < 7 && daydiff + ' days ago') ||
//     (daydiff < 31 && Math.ceil(daydiff / 7) + ' week(s) ago')
//   );
// }
