<div class="main">
  <!-- <app-creatornavbar></app-creatornavbar> -->
<app-homenavbar></app-homenavbar>
    <div class="container-fluid">
             <app-loader *ngIf="loader"></app-loader>
        <div class="login-section ">
          <div class="row flex-column-reverse flex-md-row">
              <div class="col-md-6 ">
                <div class="login-section-common-data pl-md-5 pt-md-5" id="signup-para">
                    <h4 class="ml-md-2 mt-md-3 pb-md-1">Turn youtube videos into AI Blogs</h4>
<h6>Why Summarizly AI?</h6>

                       <div class="d-flex points mt-3">
                        <img src="../../assets/images/check-1.png" class="check-1">
                        <p class="ml-2">Create an AI blog in seconds and publish
</p>
                    </div>
                    <div class="d-flex points mt-2">
                        <img src="../../assets/images/check-1.png" class="check-1">
                        <p class="ml-2">Grow your audience and make money from each blog</p>
                    </div>
                      <!-- <div class="common-data-login">
            
              <h5>Publish all your affiliate links at one place, and share it across with
your audience anywhere</h5>
               
              <h2>Why?</h2>

              <ul>
                <li>
Your audience can find your links anytime at one single place

                </li>
                <li>
Publish custom links, for one product or many products

                </li>
              </ul>
            </div> -->
                </div>
              </div>
              <div class="col-md-6">
                <div class="login-form-section" >
                    <div class="name-section ml-md-4 mt-md-2">
                    
                    <div class="form-section ">
                      <h4 >Publisher signup</h4>
                          <form [formGroup]="SignUpUser">
                  <div class="email-form-section mt-md-2">
                           <input class="form-control" formControlName="firstName" placeholder="Enter your firstName">
                           <div
                    *ngIf="signUpNameError.firstName.errors?.required && submittedUser"
                    class="invalid-feedback m-t d-block"
                  >
                    firstName is required
                  </div>
                         </div>
               <div class="email-form-section mt-md-2">
                           <input class="form-control" formControlName="lastName" placeholder="Enter your lastName">
                           <div
                    *ngIf="signUpNameError.lastName.errors?.required && submittedUser"
                    class="invalid-feedback m-t d-block"
                  >
                    lastName is required
                  </div>
                         </div>
                           <div class="catergories-add-section mt-md-2">
      
        
      <mat-form-field appearance="fill"><mat-select formControlName = "categoryId" multiple placeholder="Select Category" disableOptionCentering panelClass="eligablePanelClass" (selectionChange)="categoryEvent($event)" #closeattendance >
                       
      <mat-option class='services-view' *ngFor="let list of categoryList" [value]="list._id">{{list.name}}</mat-option><div class="apply-btn-option pt-4 pb-3">
        
    </div></mat-select></mat-form-field>
        <div *ngIf="signUpNameError.categoryId.errors?.required && submittedUser" class="invalid-feedback m-t d-block">Category is required </div>

  </div>
                   <!-- <div class="email-form-section mt-md-2">
                           <input class="form-control" formControlName="phoneNumber" placeholder="Enter your phoneNumber">
                           <div
                    *ngIf="signUpNameError.phoneNumber.errors?.required && submittedUser"
                    class="invalid-feedback m-t d-block"
                  >
                    Phone Number is required
                  </div>
                         </div> -->
                  
                         <div class="password-form-section mt-md-2">
                           <input type="password" class="form-control" formControlName="password" placeholder="Enter your password">
                           <div
                    *ngIf="signUpNameError.password.errors?.required && submittedUser"
                    class="invalid-feedback m-t d-block"
                  >
                  Password is required
                  </div>
                         </div>
                          </form>
                           <p *ngIf="error != null"  class="invalid-feedback m-t d-block">{{error}}</p>

                       
                    </div>
                    <div class="sign-in-section mt-md-4 pt-md-2">
                        <div class="d-flex">
                           <div class="btn-signin">
                              <button class="pointer" (click)="login()"  type="submit">SIGN UP</button>
                           </div>
                            
                        </div>
                    </div>

                    <div class='google-intergration'>
                         <p class="py-md-4">OR</p>
                           <div class=" google-btn">
   <button type="submit">
    
    <a [href]="url | safePipe">
    <img src="../../assets/images/google.png" class="google mr-md-3">Sign up via Google
  </a>
  </button>
</div>
  <h6>Don’t have an account? <span class="pointer" ><a (click)="loginNow()">Login</a></span></h6>

                    </div>
                </div>
                </div>
               
              </div>
          </div>
        </div>
    </div>
</div>
