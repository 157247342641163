<div class="container">
  <h4 class="succesfully mt-md-3" *ngIf="succeed">Categories added successfully</h4>
  <img src="../../../assets/images/close.png" (click)="close()" class="close-img cursor">
  <h3 class="topics">Add Topics</h3>
  <form [formGroup]="catergoriesFormGroup">
    <div class="upload pt-4">
      <img *ngIf="url != null " [src]="url" width=100 height=100 alt="" class="upload-img">
      <img *ngIf="url == null " src="../../assets/images/place-holder.jpg" width=100 height=100 alt="" class="upload-img">
      <div class="round">
        <input type="file" accept="image/*" formControlName="categoryImage" (change)="onFileSelected($event)">
        <img src="../../assets/images/camera.svg" width=100 height=100 alt="" class="camera-img">
      </div>
    </div>
    <div *ngIf="c.categoryImage.errors?.required && submiited" class="invalid-feedback ml-md-2 pt-md-5 d-block">Category image is required </div>
       <div class="row">
      <div class="col-md-6">
        <div class="catergories-add-section mt-md-2">
          <label>Add Topics</label>
          <input formControlName="categoryName" placeholder="Add topic" type="text" class="form-control">
          <div *ngIf="c.categoryName.errors?.required && submiited" class="invalid-feedback m-t d-block">Categoryname is required </div>
        </div>
       
        <div class="submit-btn">
          <button type="button" class="mt-md-3 pointer" (click)="submit()">Submit</button>
        </div>
      </div>
       <div class="col-md-6">
        <div class="catergories-add-section mt-md-2">
          <label>Add subtopics</label>
          <input (input)="subCategory($event)" formControlName="subCategories" placeholder="Add subtopic" type="text" class="form-control">
          <div *ngIf="this.error != null" class="invalid-feedback m-t d-block">{{this.error }}</div>
        </div>
        </div>
    </div>
    </form>

    </div>